import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Toast from 'react-bootstrap/Toast'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Col from 'react-bootstrap/Col'

import { useParams, Link } from 'react-router-dom'
import { useContext, useEffect, useState} from 'react'

import { AuthContext } from '../contexts/AuthContext'
import { UserContext } from '../contexts/UserContext'
import { UserPageContext } from '../contexts/UserPageContext'
import { MenuContext } from '../contexts/MenuContext'
import { UserManagerContext } from '../contexts/UserManagerContext'

const Contact = () => {

	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)

	let UserID = _id;
				// <div style={{marginTop:'20px'}}>
				// 	QLHĐ cung cấp một nền tảng tổ chức, quản lý và theo dõi hợp đồng hiệu quả, giúp tăng cường hiệu suất làm việc, giảm thiểu rủi ro pháp lý và cải thiện quy trình quản lý hợp đồng.
				// </div>
	const {
		UserPageState: { UserPage, UserPages, UserPagesLoading },
		getAllUserPages
	} = useContext(UserPageContext)

	const {
	    MenuState: { Menu, Menus, MenusLoading },
	    getMenu,
	} = useContext(MenuContext);

	function FindArrayMenu(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return null;
		}
	}

	if (UserPagesLoading && MenusLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	}else {
		const result = UserPages.filter(obj => { return obj.user_id.some(id => id === UserID); });
		let menu_access = false
		if (result.length > 0) {
			for (var j = 0; j < result.length; j++) {
				let menuV1 = FindArrayMenu(Menus, result[j].page_id[0])
				if (menuV1 != null && menuV1.path == location.pathname) {
					// console.debug("menuV1 co quyen truy cap")
					menu_access = true
					break;
				}else {
					// console.debug("menuV1 khong quyen truy cap")
				}
			}
		} else {
			// console.debug("menu khong quyen truy cap")
		}

		if (menu_access) {
			return (
				<>
					<Card className='text-center mx-5 my-5' style={{border :'0px'}}>
						<Card.Body>
							<Card.Body style={{display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
								<Card style={{ width: '32%' }}>
									<Card.Body>
										<div className="wrapItem">
											<h3 className='headerAbout'>Trụ sở chính</h3>
											<ul className='fontSize' style={{listStyle: 'none'}}>
												<li class="local">Lô E2a-3 đường D1, Khu Công nghệ cao, P. Long Thạnh Mỹ, TP. Thủ Đức, TP. Hồ Chí Minh</li>
												<li class="phone">(+84) 28 38 266 206. Hotline: 18006686</li>
												<li class="fax">(+84) 28 54 108 801</li>
											</ul>
										</div>
									</Card.Body>
								</Card>
								<Card style={{ width: '32%' }}>
									<Card.Body>
										<div className="wrapItem">
											<h3 className='headerAbout'>Trung tâm Tích hợp hệ thống</h3>
											<ul className = 'fontSize' style={{listStyle: 'none'}}>
												<li class="local">Lô E2a-3 đường D1, Khu Công nghệ cao, P. Long Thạnh Mỹ, TP. Thủ Đức, TP. Hồ Chí Minh</li>
												<li class="phone">(+84) 28 38 266 206. Hotline: 18006686</li>
												<li class="fax">(+84) 28 54 108 801</li>
											</ul>
										</div>
									</Card.Body>
								</Card>
								<Card style={{ width: '32%' }}>
									<Card.Body>
										<div className="wrapItem">
											<h3 className='headerAbout'>Trung tâm dịch vụ khách hàng</h3>
											<ul className = 'fontSize' style={{listStyle: 'none'}}>
												<li class="local">37 Nguyễn Trường Tộ, Phường 12, Quận 4, Tp.Hồ Chí Minh</li>
												<li class="phone">(+84) 28 38 266 206. Hotline: 18006686</li>
												<li class="fax">(+84) 28 38 266 044</li>
											</ul>
										</div>
									</Card.Body>
								</Card>
								<Card style={{ width: '32%' }}>
									<Card.Body>
										<div className="wrapItem">
											<h3 className='headerAbout'>Chi nhánh Hà Nội</h3>
											<ul className = 'fontSize' style={{listStyle: 'none'}}>
												<li class="local">Tầng 11, tòa nhà Việt, số 1 Thái Hà, Phường Trung Liệt, Quận Đống Đa, Hà Nội</li>
												<li class="phone">(+84) 28 38 266 206. Hotline: 18006686</li>
											</ul>
										</div>
									</Card.Body>
								</Card>
								<Card style={{ width: '32%' }}>
									<Card.Body>
										<div className="wrapItem">
											<h3 className='headerAbout'>VPĐD tại Đà Nẵng</h3>
											<ul className = 'fontSize' style={{listStyle: 'none'}}>
												<li class="local">Khu H - Tầng 12 - Công viên Phần mềm Đà Nẵng, số 02 Quang Trung, Tp.Đà Nẵng</li>
												<li class="phone">(+84) 28 38 266 206. Hotline: 18006686</li>
												<li class="fax">(0511) 3 890 388</li>
											</ul>
										</div>
									</Card.Body>
								</Card>
								<Card style={{ width: '32%' }}>
									<Card.Body>
										<div className="wrapItem">
											<h3 className='headerAbout'>VPĐD tại Cambodia</h3>
											<ul className = 'fontSize' style={{listStyle: 'none'}}>
												<li class="local">3rd Floor #128J, Russian Federation Blvd (110), Sangkat Toek Laak 1, Khan Toul Kork, PhnomPenh, Cambodia</li>
											</ul>
										</div>
									</Card.Body>
								</Card>
							</Card.Body>
						</Card.Body>
					</Card>
				</>
			)
		}else {
			return (
				<>
					<div id="notfound">
						<div className="notfound">
							<h1>Oops!</h1>
							<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
								<br/>
								<Link style={{textDecoration: "none"}} to="/home">
									Trở về trang chủ
								</Link>
							</h6>
						</div>
					</div>
				</>
			)
		}
	}


}

export default Contact
