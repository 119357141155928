import {
	LOADED_SUCCESS,
	LOADED_FAIL,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from '../contexts/constants'

export const HistoryChangeReducer = (state, action) => {
	const { type, payload } = action
	switch (type) {
		case LOADED_SUCCESS:
			return {
				...state,
				historyChanges: payload,
				historyChangesLoading: false
			}

		case LOADED_FAIL:
			return {
				...state,
				historyChanges: [],
				historyChangesLoading: false
			}

		case ADD:
			return {
				...state,
				historyChanges: [...state.historyChanges, payload]
			}

		case DELETE:
			return {
				...state,
				historyChanges: state.historyChanges.filter(historyChange => historyChange._id !== payload)
			}

		case FIND:
			return { ...state, post: payload }

		case UPDATE:
			const newPosts = state.historyChanges.map(historyChange =>
				historyChange._id === payload._id ? payload : historyChange
			)

			return {
				...state,
				historyChanges: newHistoryChanges
			}

		default:
			return state
	}
}
