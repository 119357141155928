import { createContext, useReducer, useState } from 'react'
import { RoleReducer } from '../reducers/RoleReducer'//Note
import {
	apiUrl,
	LOADED_FAIL,
	LOADED_SUCCESS,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from './constants'//Note
import axios from 'axios'

export const RoleContext = createContext()

const RoleContextProvider = ({ children }) => {
	// State
	const [RoleState, dispatch] = useReducer(RoleReducer, {
		Role: null,
		Roles: [],
		RolesLoading: true
	})

	const [showAddRoleModal, setShowAddRoleModal] = useState(false)
	const [showUpdateRoleModal, setShowUpdateRoleModal] = useState(false)
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	})

	// Get all Roles
	const getRoles = async () => {
		try {
			const response = await axios.get(`${apiUrl}/api/forms/role`)//note
			if (response.data.success) {
				dispatch({ type: LOADED_SUCCESS, payload: response.data.Roles })//note
			}
		} catch (error) {
			dispatch({ type: LOADED_FAIL })
		}
	}

	// Role context data
	const RoleContextData = {
		RoleState,
		getRoles,
	}

	return (
		<RoleContext.Provider value={RoleContextData}>
			{children}
		</RoleContext.Provider>
	)
}

export default RoleContextProvider
