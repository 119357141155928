import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useContext, useState } from 'react'
import { LoanInterestContext } from '../../contexts/LoanInterestContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'
import { AuthContext } from '../../contexts/AuthContext'
import { useParams } from 'react-router-dom'
import '../App.css'
import * as XLSX from 'xlsx';
const ImportLoanInterestModal = () => {
	const [selectedFile, setSelectedFile] = useState(null);
    const handleFileUpload = (event) => {
    	setSelectedFile(event.target.files[0]);
    };
    const params = useParams();
    const {
	    LoanInterestState: { LoanInterest, LoanInterests, LoanInterestsLoading },
	    addLoanInterest,
	    getLoanInterest_byidContract,
	    showAddLoanInterestModal,
	    setShowAddLoanInterestModal,
	    showToast: { show, message, type },
	    setShowToast
	} = useContext(LoanInterestContext);

	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

    const [newLoanInterest, setNewLoanInterest] = useState({
	  	Dates: '',
	  	Explain: '',
	  	CostCustomerPaid: '',
	  	CostNTP: '',
	  	OutstandingGoods: '',
	  	NumberDate: '',
	  	InterestRate: '',
	  	InterestLoan: '',
	  	Note: ''
	});

	let {
		Dates,
	    Explain,
	    CostCustomerPaid,
	    CostNTP,
	    OutstandingGoods,
	    NumberDate,
	    InterestRate,
	    InterestLoan,
	    Note
	} = newLoanInterest
	newLoanInterest.ContractID = params.id;
    // const handleImport = () => {
	   //  const reader = new FileReader();
	   //  const rABS = !!reader.readAsBinaryString;
	   //  reader.onload = async e => {
	   //      const bstr = e.target.result;
	   //      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
	   //      const wsname = wb.SheetNames[0];
	   //      const ws = wb.Sheets[wsname];
	   //      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
	   //      const array_loan_interest = [];
	   //      for (let i = 1; i < data.length; i++) {
		  //       let value = data[i];
		  //       if(value != ''){
			 //        let date = value[0] != undefined ? ExcelDateToJSDate(value[0]) : getCurrentDate('-');
			 //        let explain = value[1] != undefined ? value[1] : '';
			 //        let cost_customer_paid = value[2] != undefined ? value[2] : '';
			 //        let cost_NTP = value[3] != undefined ? value[3] : '';
			 //        let outstanding_goods = value[4] != undefined ? value[4] : '';
			 //        let number_date = value[5] != undefined ? value[5] : '';
			 //        let interest_rate = value[6] != undefined ? value[6] : '';
		  //         	let interest_loan = value[7] != undefined ? value[7] : '';
		  //         	let note = value[8] != undefined ? value[8] : '';
		  //           let obj = {
			 //            date,
			 //            explain,
			 //            cost_customer_paid,
			 //            cost_NTP,
			 //            outstanding_goods,
			 //            number_date,
			 //            interest_rate,
			 //            interest_loan,
			 //            note
		  //           };
		  //         	array_loan_interest.push(obj);
		  //       }
	   //      }
	   //      for (var i = 0; i < array_loan_interest.length; i++) {
		  //       newLoanInterest['Dates'] = array_loan_interest[i]['date'];
		  //       newLoanInterest['Explain'] = array_loan_interest[i]['explain'];
		  //       newLoanInterest['CostCustomerPaid'] = array_loan_interest[i]['cost_customer_paid'];
		  //       newLoanInterest['CostNTP'] = array_loan_interest[i]['cost_NTP'];
		  //       newLoanInterest['OutstandingGoods'] = array_loan_interest[i]['outstanding_goods'];
		  //       newLoanInterest['NumberDate'] = array_loan_interest[i]['number_date'];
		  //       newLoanInterest['InterestRate'] = array_loan_interest[i]['interest_rate'];
		  //       newLoanInterest['InterestLoan'] = array_loan_interest[i]['interest_loan'];
		  //       newLoanInterest['Note'] = array_loan_interest[i]['note'];
		  //       console.log(newLoanInterest);
		  //       await addLoanInterest(newLoanInterest);
	   //      }

	   //      if(array_loan_interest.length > 0){
	   //      	setShowToast({ show: true, message :'Nhập file excel thành công', type:'success'})
	   //      }else{
	   //          setShowToast({ show: true, message :'Nhập file excel không thành công', type:'danger'})
	   //      }
		  //   // setTimeout(() => {
		  //   //     location.reload();
		  //   // },1000);
	       
		  //   // },10000);
		  //   // console.log(newLoanInterest,'newLoanInterestnewLoanInterest')
		  //   // console.log(aaa);
		  //   // // resetAddProductCostData()
	   //  };
	   //  if (rABS) reader.readAsBinaryString(selectedFile);
	   //  else reader.readAsArrayBuffer(selectedFile);
    // };

     const handleImport = () => {
	    const reader = new FileReader();
	    const rABS = !!reader.readAsBinaryString;
	    reader.onload = async e => {
	        const bstr = e.target.result;
	        const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
	        const wsname = wb.SheetNames[0];
	        const ws = wb.Sheets[wsname];
	        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
	        const array_loan_interest = [];
	        let TotalOutputIntoMoney = data[0][2];
	        let TotalInputIntoMoney = data[1][2];
	        for (let i = 4; i < data.length; i++) {
		        let value = data[i];
		        if(value != ''){
			        let date = value[0] != undefined ? ExcelDateToJSDate(value[0]) : getCurrentDate('-');
			        let explain = value[1] != undefined ? value[1] : '';
			        let cost_customer_paid = value[2] != undefined ? value[2] : '';
			        let cost_NTP = value[3] != undefined ? value[3] : '';
			        let outstanding_goods = value[4] != undefined ? value[4] : '';
			        let number_date = value[5] != undefined ? value[5] : '';
			        let interest_rate = value[6] != undefined ? value[6] : '';
		          	let interest_loan = value[7] != undefined ? value[7] : '';
		          	let note = value[8] != undefined ? value[8] : '';
		            let obj = {
			            date,
			            explain,
			            cost_customer_paid,
			            cost_NTP,
			            outstanding_goods,
			            number_date,
			            interest_rate,
			            interest_loan,
			            note
		            };
		          	array_loan_interest.push(obj);
		        }
	        }
	        for (var i = 0; i < (array_loan_interest.length - 1); i++) {
		        newLoanInterest['Dates'] = array_loan_interest[i]['date'];
		        newLoanInterest['Explain'] = array_loan_interest[i]['explain'];
		        newLoanInterest['CostCustomerPaid'] = array_loan_interest[i]['cost_customer_paid'] != '' ? removeSpecialCharacters(array_loan_interest[i]['cost_customer_paid']) : 0;
		        newLoanInterest['CostNTP'] = array_loan_interest[i]['cost_NTP'] != '' ? removeSpecialCharacters(array_loan_interest[i]['cost_NTP']) : 0;
		        newLoanInterest['OutstandingGoods'] = array_loan_interest[i]['outstanding_goods'] != '' ? removeSpecialCharacters(array_loan_interest[i]['outstanding_goods']) : 0;
		        newLoanInterest['NumberDate'] = array_loan_interest[i]['number_date'] != '' ? parseInt(array_loan_interest[i]['number_date']) : 0;
		        newLoanInterest['InterestRate'] = array_loan_interest[i]['interest_rate'] != '' ? removeSpecialCharacters(array_loan_interest[i]['interest_rate']) : 0;
		        newLoanInterest['InterestLoan'] = array_loan_interest[i]['interest_loan'] != '' ? removeSpecialCharacters(array_loan_interest[i]['interest_loan']) : 0;
		        newLoanInterest['Note'] = array_loan_interest[i]['note'];
	        // console.log(newLoanInterest,'112');
		        console.log(newLoanInterest,'newLoanInterest')
		        await addLoanInterest(newLoanInterest);
	        }

	        if(array_loan_interest.length > 0){
	        	setShowToast({ show: true, message :'Nhập file excel thành công', type:'success'})
	        }else{
	            setShowToast({ show: true, message :'Nhập file excel không thành công', type:'danger'})
	        }
	        const historyChangeProductCost = {
			    "requester": _id,
			    "contract": params.id,
			    "form": "loaninterest-cost",
			    "action": "import",
			    "data_change": "import loaninterest"
			}
			const { successHis, messageHis } = await addHistoryChange(historyChangeProductCost)
		    setTimeout(() => {
		        location.reload();
		    },1000);
	       
		    // },10000);
		    // console.log(newLoanInterest,'newLoanInterestnewLoanInterest')
		    // console.log(aaa);
		    // resetAddProductCostData()
	    };
	    if (rABS) reader.readAsBinaryString(selectedFile);
	    else reader.readAsArrayBuffer(selectedFile);
    };

    function removeSpecialCharacters(inputString) {
		  // Loại bỏ dấu phẩy , trong chuỗi
		  const step1 = inputString.replace(/,/g, '');

		  // Loại bỏ dấu ngoặc đơn () trong chuỗi
		  const step2 = step1.replace(/[()]/g, '');

		  // Loại bỏ dấu phần trăm % trong chuỗi
		  const result = step2.replace(/%/g, '');

		  return parseInt(result);
		}

    function getCurrentDate(separator){
	    let newDate = new Date()
	    let date = newDate.getDate();
	    let month = newDate.getMonth() + 1;
	    let year = newDate.getFullYear();
	    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }

    function ExcelDateToJSDate(serial) {
        var utc_days  = Math.floor(serial - 25569);
     	var utc_value = utc_days * 86400;                                        
     	var date_info = new Date(utc_value * 1000);
	    var fractional_day = serial - Math.floor(serial) + 0.0000001;
	    var total_seconds = Math.floor(86400 * fractional_day);
	    var seconds = total_seconds % 60;
	    total_seconds -= seconds;
	    var hours = Math.floor(total_seconds / (60 * 60));
	    var minutes = Math.floor(total_seconds / 60) % 60;
	    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    }

    function convert_time(date_time){
	    const date = new Date(date_time); // Replace this with your actual date object
	    const year = date.getFullYear();
	    const month = ('0' + (date.getMonth() + 1)).slice(-2);
	    const day = ('0' + date.getDate()).slice(-2);
	    const hours = ('0' + date.getHours()).slice(-2);
	    const minutes = ('0' + date.getMinutes()).slice(-2);
	    const seconds = ('0' + date.getSeconds()).slice(-2);
	    // const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	    const formattedDate = `${year}-${month}-${day}`;
	    return formattedDate;
	}
	// window.onbeforeunload = function() {
	//   return "Dude, are you sure you want to leave? Think of the kittens!";
	// }
	const closeDialog = () => {
	    resetImportLoanInterestData()
	}
    const resetImportLoanInterestData = () => {
	    setNewLoanInterest({
	      Dates: '',
	      Explain: '',
	      CostCustomerPaid: '',
	      CostNTP: '',
	      OutstandingGoods: '',
	      NumberDate: '',
	      InterestRate: '',
	      InterestLoan: '',
	      Note: ''
	    }) //note cần sửa
	    setShowAddLoanInterestModal(false)
    }
	return (
		<Modal show={showAddLoanInterestModal} onHide={() => setShowAddLoanInterestModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Nhập từ file excel</Modal.Title>
            </Modal.Header>
            <Form>
	            <Modal.Body>
	                <Form.Group>
		                <div>
		                    <input type="file" id="csvFileInput" style={{ display: 'none' }} onChange={handleFileUpload} />
		                    <label htmlFor="csvFileInput">
		                    <input type="file" accept=".xlsx" onChange={handleFileUpload} />
		                    </label>
		                </div>
	                </Form.Group>
	            </Modal.Body>
                <Modal.Footer>
		            <Button variant='secondary' onClick={closeDialog}>Hủy</Button>
           			<Button variant='primary' onClick={handleImport}>Import</Button>
           		</Modal.Footer>
            </Form>
        </Modal>
	)
}

export default ImportLoanInterestModal




