import {
	LOADED_SUCCESS,
	LOADED_FAIL,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from '../contexts/constants'

export const InvoiceReducer = (state, action) => {
	const { type, payload } = action
	switch (type) {
		case LOADED_SUCCESS:
			return {
				...state,
				Invoices: payload,
				InvoicesLoading: false
			}

		case LOADED_FAIL:
			return {
				...state,
				Invoices: [],
				InvoicesLoading: false
			}

		case ADD:
			return {
				...state,
				Invoices: [...state.Invoices, payload]
			}

		case DELETE:
			return {
				...state,
				Invoices: state.Invoices.filter(invoice => invoice._id !== payload)
			}

		case FIND:
			return { ...state, Invoice: payload }

		case UPDATE:
			
			const newInvoices = state.Invoices.map(invoice =>
				invoice._id === payload._id ? payload : invoice
			)

			return {
				...state,
				Invoices: newInvoices
			}

		default:
			return state
	}
}
