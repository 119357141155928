import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'

import logoutIcon from '../../assets/logout.svg'
import notificationIcon from '../../assets/icon-notification.png'
import redDotIcon from "../../assets/basic_red_dot.png"
import Select from 'react-select';
import { Link } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'
import { MenuContext } from '../../contexts/MenuContext'
import { RoleContext } from '../../contexts/RoleContext'
import { UserPageContext } from '../../contexts/UserPageContext'
import { useContext, useState, useEffect} from 'react'
// import ChangesPasswordUserModal from '../../components/users/ChangesPasswordUserModal'//Note

import { ContractContext } from '../../contexts/ContractContext'
import { NotificationContext } from '../../contexts/NotificationContext'
import { NotificationModal } from "./NotificationModal";

import { UserContext } from '../../contexts/UserContext'
import { UserManagerContext } from '../../contexts/UserManagerContext'

import axios from 'axios';
import {
	apiUrl,
	LOADED_FAIL,
	LOADED_SUCCESS,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from '../../contexts/constants'//Note

const NavbarMenu = () => {
	const {
		authState: {
			user: { username, roles,_id }
		},
		logoutUser
	} = useContext(AuthContext)

	const {
	    MenuState: { Menu, Menus, MenusLoading },
	    getMenu,
	} = useContext(MenuContext);
	const {
		RoleState: { Role, Roles, RolesLoading },
		getRoles,
	} = useContext(RoleContext)

	const {
		ContractState: { Contract, Contracts, ContractsLoading },
		getContracts,
		setShowAddContractModal,
		showToast: { show, message, type },
		setShowToast
	} = useContext(ContractContext)

	const {
		notificationState: { Notification, Notifications, NotificationsLoading },
		getNotification,
		isReadNotification
	} = useContext(NotificationContext)

	const {
		UserState: { User, Users, UsersLoading },
		getUsers
	} = useContext(UserContext)

	
	useEffect(() => getMenu(), []);
	useEffect(() => getRoles(), []);
	useEffect(() => getContracts(), [])
	useEffect(() => getNotification(), [])
	useEffect(() => getUsers(), [])
	
	let role_new = roles[0];
	let roleName = FindArray(Roles, role_new).name;
	let UserID = _id;
	let userLogin = FindArray(Users, UserID)
	
	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id == ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	function Find_refer(array, ID){
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x.refer_menu_id == ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	function find_userPage(array, ID){
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x.page_id == ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	function filter(array, ID, userPage){
		let arrayNew = [];
		if(array != ''){
			if(userPage != ''){
				for (var i = 0; i < array.length; i++) {
					if(array[i].refer_menu_id == ID){
						let arrayNew1 = find_userPage(userPage,array[i]._id);
						if(arrayNew1 != ''){
							arrayNew.push(array[i]);
						}
					}
				}
			}
		}
		arrayNew.sort(sortStringAsc);
		return arrayNew;
	}

	function findUsers(array, roleName, idUser) {
		let usersBelongCenter = []
		let filterListUsers = FindArray(array, _id)
		switch (roleName) {
			case "Admin":
				if (filterListUsers != undefined) {
					usersBelongCenter = array
				}
				break;
			case "BTGD":
				if (filterListUsers != undefined) {
					usersBelongCenter = filter_new_user(array, null, null, idUser, ["BGD", "KTT", "TPKD", "AM"])
				}
				break;
			case "BGD":
				if (filterListUsers != undefined) {
					usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["KTT", "TPKD", "AM"])
				}
				break;
			case "KTT":
				if (filterListUsers != undefined) {
					usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["TPKD", "AM"])
				}
				break;
			case "TPKD":
				if (filterListUsers != undefined) {
					usersBelongCenter = filter_new_user(array, filterListUsers.centers, filterListUsers.departments, idUser, ["AM"])
				}
				break;
		}
		// console.log("usersBelongCenter", usersBelongCenter)
		return usersBelongCenter
	}

	function findUsersNotification(array, roleName, idUser, listRole = null) {
		let usersBelongCenter = []
		let filterListUsers = FindArray(array, _id)
		switch (roleName) {
			case "Admin":
				if (filterListUsers != undefined) {
					usersBelongCenter = array
				}
				break;
			case "BTGD":
				if (filterListUsers != undefined) {
					if (listRole == 'BGD') {
						usersBelongCenter = filter_new_user(array, null, null, idUser, ["BTGD"])
					}else {
						usersBelongCenter = filter_new_user(array, null, null, idUser, ["BGD"])
					}
				}
				// if (filterListUsers != undefined) {
				// 	usersBelongCenter = filter_new_user(array, null, null, idUser, ["BGD"])
				// }
				break;
			case "BGD":
				if (filterListUsers != undefined) {
					if (listRole == 'BTGD') {
						usersBelongCenter = filter_new_user(array, null, null, idUser, ["BTGD"])
					}else {
						usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["KTT"])
					}
				}
				break;
			case "KTT":
				if (filterListUsers != undefined) {
					if (listRole == 'BGD') {
						usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["BGD"])
					}else {
						usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["TPKD"])
					}
				}
				break;
			case "TPKD":
				if (filterListUsers != undefined) {
					if (listRole == 'KTT') {
						usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["KTT"])
					}else {
						usersBelongCenter = filter_new_user(array, filterListUsers.centers, filterListUsers.departments, idUser, ["AM"])
					}
				}
				break;
		}
		// console.log("usersBelongCenter", usersBelongCenter)
		return usersBelongCenter
	}

	function filter_new_user(array, centers_id, department_id = null, idUser, listRole = null){
		let returnObject = [];
		if (centers_id !== null) {
			array.map(element => {
				if (element.centers) {
					if (element.centers[0] === centers_id[0] && element._id !== idUser && listRole.includes(element.role_details[0].name)) {
						returnObject.push(element)
					}
				}
			})
		}else {
			if (listRole != null) {
				array.map(element => {
					if (listRole.includes(element.role_details[0].name)) {
						returnObject.push(element)
					}
				})
			}else {
				array.map(element => {
					if (element.centers) {
						if (element._id !== idUser ) {
							returnObject.push(element)
						}
					}
				})
			}
		}
		return returnObject
	}

	const {
		UserPageState: { UserPage, UserPages, UserPagesLoading },
		getAllUserPages
	} = useContext(UserPageContext)
	

	// let userPage = [
	// 	{'user_id':'649e5813d4fb395d4e8f8e62','page_id':'64a795cbc0b92419c4d9f8b9'},
	// 	{'user_id':'649e5813d4fb395d4e8f8e62','page_id':'64a795ffc0b92419c4d9f8bb'}
	// ];
	
	function sortStringAsc(a, b) {
		let c = a['index'];
		let d = b['index'];
		return c > d ? 1 : d > c ? -1 : 0;
	}
	Menus.sort(sortStringAsc);
	// let UserPageNew = UserPages.filter((v) =>v.user_id == UserID);
	let UserPageNew = get_status_name(UserPages,UserID);

	function get_status_name(array,ContractID){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.user_id == ContractID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	// let UserPageNew = '';
	// if(UserPageNew1.length > 0 )  UserPageNew = UserPageNew1;
	let array_menu = [];
	for (var i = 0; i < Menus.length; i++) {
		let value_m = Menus[i];
		for (var j = 0; j < UserPageNew.length; j++) {
			let value_p = UserPageNew[j];
			if(value_m._id == value_p.page_id){
				array_menu.push(value_m);
			}
		}
	}


	/*const checkAccessPage = (path) => {
		let menuHome = [{
			"_id": "64a795cbc0b92419c4d9f8z9",
			"name": "Trang chủ",
			"refer_menu_id": null,
			"del": true,
			"inv": true,
			"path": "/home",
			"__v": 0,
			"index": 0
		}]
		let AccessPage = (path === "/home") ? menuHome : array_menu.filter((v) =>v.path === path);
		return AccessPage;
	}*/
	
	const Abc = (role) => {
		switch (role) {
			case "Admin":
				let tableRowsAdmin = Menus.map(
				(val) => {
					let name = val.name;
					let refer_menu_id = val.refer_menu_id;
					let path = val.path;
					return (
						<>
							{
								Find_refer(Menus,val._id) == '' && refer_menu_id == null
								?
								<Nav.Link
									className='text-white'
									to={path}
									as={Link}
								>
									{name}
								</Nav.Link>
								: 
								<>
									{
									refer_menu_id == null
									?
										<NavDropdown className='text-white' title={name} id="basic-nav-dropdown">
											{
												filter(Menus,val._id,UserPageNew).map(value => (
													<NavDropdown.Item className="dropdown-item-a">
														<Link style={{textDecoration: "none"}} to={value.path}>
															{value.name}
														</Link>
													</NavDropdown.Item>
												))
											}
										</NavDropdown>
									:
									<></>
									}
								</>
							}
							
						</>
					)
				})
				return(
					<>
					{tableRowsAdmin}
					</>
				)

				break;
			case "AM":
			case "TPKD":
			case "KTT":
			case "BGD":
			case "BTGD":
				let tableRows = array_menu.map( 
				(val) => {
					let name = val.name;
					let refer_menu_id = val.refer_menu_id;
					let path = val.path;
					let page_id = val._id;
						return (
							<>
								{
									Find_refer(Menus,page_id) == '' && refer_menu_id == null
									?
									<Nav.Link
										className='text-white'
										to={path}
										as={Link}
									>
										{name}
									</Nav.Link>
									:
									<>
										{
										refer_menu_id == null
										?
											<NavDropdown className='text-white' title={name} id="basic-nav-dropdown">
												{
													filter(Menus,page_id,UserPageNew).map(value => (
														<NavDropdown.Item className="dropdown-item-a">
															<Link style={{textDecoration: "none"}} to={value.path}>
																{value.name}
															</Link>
														</NavDropdown.Item>
													))
												}
											</NavDropdown>
										:
										<></>
										}
									</>
								}
							</>
						
						)
					}	
				)

				return(
					<>
					{tableRows}
					</>
				)

				break;
			default:
				return(
					<>
					</>
				)
				break;
		}
	}

	const logout = () => logoutUser()

	const [showNotification, setShowNotification] = useState(false);
	useEffect(() => setShowNotification(false), [UserID])

	const showHideNotification = (e) => {
		if (showNotification == true) {
			setShowNotification(false)
		} else {
			setShowNotification(true)
		}
	}

	////update 2024-02-06
	let arrNotification = null
	let is_read_all = true
	// console.debug("NavbarMenu getNotifications::::::::", Notifications)
	// console.log("----------------------------------------------------------------")
	for (var i = 0; i < Notifications.length; i++) {
		// console.debug(i, Notifications[i]._id, Notifications[i].sender, Notifications[i].receiver, Notifications[i].contract)
		// let strH4 = `Hợp đồng số ${Notifications[i].contract_details.length > 0 ? Notifications[i].contract_details[0].ContractID : ""}: người gửi:: ${Notifications[i].user_details[0].name} người nhận:: ${Notifications[i].receiver_details[0].name}. ${Notifications[i].message}`
		// console.debug(i, strH4)
	}
	if (userLogin) {
		let newList = filter_get_manager_details(userLogin, roleName)
		arrNotification = filter_get_notify(Notifications, newList, roleName)
		// console.debug("arrNotification:::", arrNotification, newList, roleName)
		arrNotification.sort((objA, objB) => new Date(objB.created_at) - new Date(objA.created_at))
		for (let i = 0; i < arrNotification.length; i++) { if (!arrNotification[i].is_read) { is_read_all = false; break; } }
	}

	function filter_get_manager_details(User, roleName) {
		let returnObject = []
		switch (roleName) {
			case "AM":
				for (var i = 0; i < Users.length; i++) {
					if (Users[i].manager_details.length > 0) {
						const user = Users[i].manager_details.find(o => o.user_id.includes(UserID));
						if (user != undefined) {
							let manager_details = Users[i].manager_details
							for (var j = 0; j < manager_details.length; j++) {
								returnObject.push(manager_details[j].manager_id[0])
							}
						}
					}
				}
			break;

			case "TPKD":
				//tìm AM
				let manager_details = User.manager_details
				for (var i = 0; i < manager_details.length; i++) {
					returnObject.push(manager_details[i].user_id[0])
				}

				// tìm KTT
				var list_user_ktt = findUsersNotification(Users, roleName, UserID, 'KTT')
				for (var i = 0; i < list_user_ktt.length; i++) {
					returnObject.push(list_user_ktt[i]._id)
				}
			break;

			case "KTT":
				var list_user_tpkd = findUsersNotification(Users, roleName, UserID, 'TPKD')
				for (var i = 0; i < list_user_tpkd.length; i++) {
					returnObject.push(list_user_tpkd[i]._id)
				}

				var list_user_bdg = findUsersNotification(Users, roleName, UserID, 'BGD')
				for (var i = 0; i < list_user_bdg.length; i++) {
					returnObject.push(list_user_bdg[i]._id)
				}
			break;

			case "BGD":
				var list_user_ktt = findUsersNotification(Users, roleName, UserID, 'KTT')
				for (var i = 0; i < list_user_ktt.length; i++) {
					returnObject.push(list_user_ktt[i]._id)
				}
				var list_user_btdg = findUsersNotification(Users, roleName, UserID, 'BTGD')
				for (var i = 0; i < list_user_btdg.length; i++) {
					returnObject.push(list_user_btdg[i]._id)
				}
				
			break;

			case "BTGD":
				var list_user_bgd = findUsersNotification(Users, roleName, UserID, 'BGD')
				for (var i = 0; i < list_user_bgd.length; i++) {
					returnObject.push(list_user_bgd[i]._id)
				}

				var list_user_btgd = findUsersNotification(Users, roleName, UserID, 'BTGD')
				for (var i = 0; i < list_user_btgd.length; i++) {
					returnObject.push(list_user_btgd[i]._id)
				}
			break;
		}

		return removeDuplicates(returnObject)
	}

	function filter_get_notify(newArrayNotify, list_user, roleName) {
		// console.debug(newArrayNotify, list_user, roleName)
		let returnObject = []
		
		switch (roleName) {
			case "BTGD":
				for (var i = 0; i < newArrayNotify.length; i++) {
					const includes = newArrayNotify[i].sender.every(id => list_user.includes(id));
					const receivers = newArrayNotify[i].receiver.every(id => id.includes(UserID));
					const checkRoleName = FindArray(Roles, newArrayNotify[i].user_details[0].roles[0]).name
					// const msg = ( newArrayNotify[i].message.includes("gửi HĐ lên") && checkRoleName == "BGD")
					let guilen = newArrayNotify[i].message.includes("gửi HĐ lên") && checkRoleName == "BGD"
					const roleCheckRemoveContract = ['TPKD', 'KTT', 'BGD'];
					let huyhd = newArrayNotify[i].message.includes("hủy HĐ") && roleCheckRemoveContract.includes(checkRoleName)
					const msg = (guilen || huyhd)
					
					if (msg && receivers) {
						returnObject.push(newArrayNotify[i])
					}
				}
				returnObject = returnObject
				break;
			case "BGD":
				for (var i = 0; i < newArrayNotify.length; i++) {
					const includes = newArrayNotify[i].sender.every(id => list_user.includes(id));
					const receivers = newArrayNotify[i].receiver.every(id => id.includes(UserID));
					const checkRoleName = FindArray(Roles, newArrayNotify[i].user_details[0].roles[0]).name
					// const msg = ( newArrayNotify[i].message.includes("gửi HĐ lên") && checkRoleName == "KTT") || ( newArrayNotify[i].message.includes("từ chối duyệt HĐ") && checkRoleName == "BTGD") || ( newArrayNotify[i].message.includes("duyệt HĐ") && checkRoleName == "BTGD")
					const roleCheck = ['BTGD'];
					const roleCheckRemoveContract = ['TPKD', 'KTT', 'BTGD'];
					let guilen = newArrayNotify[i].message.includes("gửi HĐ lên") && checkRoleName == "KTT"
					let tuchoi = newArrayNotify[i].message.includes("từ chối duyệt HĐ") && roleCheck.includes(checkRoleName)
					let duyet = newArrayNotify[i].message.includes("duyệt HĐ") && roleCheck.includes(checkRoleName)
					let huyhd = newArrayNotify[i].message.includes("hủy HĐ") && roleCheckRemoveContract.includes(checkRoleName)
					const msg = (guilen || tuchoi || duyet || huyhd)

					if (msg && receivers) {
						returnObject.push(newArrayNotify[i])
					}
				}
				returnObject = returnObject
				break;
			case "KTT":
				for (var i = 0; i < newArrayNotify.length; i++) {
					const includes = newArrayNotify[i].sender.every(id => list_user.includes(id));
					const receivers = newArrayNotify[i].receiver.every(id => id.includes(UserID));
					const checkRoleName = FindArray(Roles, newArrayNotify[i].user_details[0].roles[0]).name
					// console.debug("checkRoleName", checkRoleName)
					// const msg = ( newArrayNotify[i].message.includes("gửi HĐ lên") && checkRoleName == "TPKD") || ( newArrayNotify[i].message.includes("từ chối duyệt HĐ") && checkRoleName == "BGD") || ( newArrayNotify[i].message.includes("duyệt HĐ") && checkRoleName == "BGD")
					const roleCheck = ['BGD', 'BTGD'];
					const roleCheckRemoveContract = ['TPKD', 'BGD', 'BTGD'];
					let guilen = newArrayNotify[i].message.includes("gửi HĐ lên") && checkRoleName == "TPKD"
					let tuchoi = newArrayNotify[i].message.includes("từ chối duyệt HĐ") && roleCheck.includes(checkRoleName)
					let duyet = newArrayNotify[i].message.includes("duyệt HĐ") && roleCheck.includes(checkRoleName)
					let huyhd = newArrayNotify[i].message.includes("hủy HĐ") && roleCheckRemoveContract.includes(checkRoleName)
					const msg = (guilen || tuchoi || duyet || huyhd)
					if (msg && receivers) {
						returnObject.push(newArrayNotify[i])
					}
				}

				// console.debug("1",returnObject)
				let rs1 = removeDuplicatesNotification(returnObject)
				let rs2 = filterDuplicates(returnObject)
				returnObject = [...rs1, ...rs2];
				// console.debug("2",returnObject)
				break;
			case "TPKD":
				for (var i = 0; i < newArrayNotify.length; i++) {
					const includes = newArrayNotify[i].sender.every(id => list_user.includes(id));
					const receivers = newArrayNotify[i].receiver.every(id => id.includes(UserID));
					const checkRoleName = FindArray(Roles, newArrayNotify[i].user_details[0].roles[0]).name
					const roleCheck = ['KTT', 'BGD', 'BTGD'];
					let guilen = newArrayNotify[i].message.includes("gửi HĐ lên") && checkRoleName == "AM"
					let tuchoi = newArrayNotify[i].message.includes("từ chối duyệt HĐ") && roleCheck.includes(checkRoleName)
					let duyet = newArrayNotify[i].message.includes("duyệt HĐ") && roleCheck.includes(checkRoleName)
					let huyhd = newArrayNotify[i].message.includes("hủy HĐ") && roleCheck.includes(checkRoleName)
					const msg = (guilen || tuchoi || duyet || huyhd)
					
					if (msg && receivers) {
						returnObject.push(newArrayNotify[i])
					}
				}
				returnObject = removeDuplicatesNotification(returnObject)
				break;
				
			default: 
				for (var i = 0; i < newArrayNotify.length; i++) {
					// const includes = newArrayNotify[i].sender.every(id => list_user.includes(id));
					const receivers = newArrayNotify[i].receiver.every(id => id.includes(UserID));
					const checkRoleName = FindArray(Roles, newArrayNotify[i].user_details[0].roles[0]).name
					const roleCheck = ['KTT', 'BGD', 'BTGD', 'TPKD'];
					let tuchoi = newArrayNotify[i].message.includes("từ chối duyệt HĐ") && roleCheck.includes(checkRoleName)
					let duyet = newArrayNotify[i].message.includes("duyệt HĐ") && roleCheck.includes(checkRoleName)
					let huyhd = newArrayNotify[i].message.includes("hủy HĐ") && roleCheck.includes(checkRoleName)
					const msg = (tuchoi || duyet || huyhd)
					// if (includes && receivers) {
					if (msg && receivers) {
						returnObject.push(newArrayNotify[i])
					}
				}

				returnObject = removeDuplicatesNotification(returnObject)
		}

		return returnObject
	}

	function removeDuplicates(arr) {
	    return arr.filter((item,index) => arr.indexOf(item) === index);
	}
	//v1
	function removeDuplicatesNotification(arr) {
		let values = [];
		let result = [];
	
		arr.forEach((item) => {
			let key = item.sender + "|" + item.receiver + "|" + item.contract + "|" + item.message;
	
			if (!values.includes(key)) {
				result.push(item);
				values.push(key);
			}
		});
	
		return result;
	}

	function filterDuplicates(array) {
	    const uniqueObjects = {};
	    const firstDuplicate = [];

	    array.forEach((obj) => {
	        const key = obj.sender.join(",") + "|" + obj.receiver.join(",") + "|" + obj.contract.join(",") + "|" + obj.message;
	        if (!uniqueObjects[key]) {
	            uniqueObjects[key] = obj;
	        } else {
	            if (firstDuplicate.length === 0) {
	                firstDuplicate.push(obj);
	            }
	        }
	    });
	    firstDuplicate.sort((objA, objB) => new Date(objB.created_at) - new Date(objA.created_at));
	    return firstDuplicate;
	}



	////////
	
	function FindStatus(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x.StatusID === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	function convert_time(date_time){
		const date = new Date(date_time); // Replace this with your actual date object
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		const seconds = ('0' + date.getSeconds()).slice(-2);
		const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		return formattedDate;
	}

	useEffect(() => {
		const concernedElement = document.querySelector("#notification-modal");

		document.addEventListener("mousedown", (event) => {
			if (concernedElement.contains(event.target)) {
			} else {
				setShowNotification(false)
			}
		});
	}, [UserID])

	const makeReadNotification = async (e, contractID) => {
		const { success, message } = await isReadNotification(e)
		setTimeout(() => {
			window.location.href = window.location.origin + "/summary?cid=" + contractID
		}, 500);
	}



	return (
		<>
			<Navbar expand='sm' bg='info' variant='dark' className='shadow'>
				<Navbar.Toggle aria-controls='basic-navbar-nav' />

				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='mr-auto'>
					<Navbar.Brand href="/">
						<img
							alt=""
							src="/logo_hpt.png"
							width="100"
							height="30"
							className="d-inline-block align-top"
						/>{' '}
					</Navbar.Brand>
						{Abc(roleName)}
					</Nav>
					
					<span className="class-notification">
						<img onClick={(e) => showHideNotification(e)} style={{width: "35px", cursor: "pointer"}} className="d-inline-block align-top" src={notificationIcon}/>

						{
							is_read_all == false ? 
							<>
								<img className="d-inline-block align-top noti-dot" src={redDotIcon}/>
							</>
							:
							<></>
						}
						
					</span>

					<div id="notification-modal"className="noti-board is-on-menu" style={{ display: (showNotification) ? "block": "none"}}>
					    <div className="noti-board-top">
					    	<span>Thông báo</span>
					    	{/*<span style={{color: "rgb(0, 141, 242)", fontWeight: "normal", cursor: "pointer"}}>Đánh dấu đã xem tất cả</span>*/}
					    </div>
					    <div className="noti-board-middle">
					        <div className="tab-content">
					            <div className="items">
					                <div className="wrap">
										{
											arrNotification != null ? 
												arrNotification.map(function(element){
													// console.log("123", element)
													let strH4 = `Hợp đồng số ${(element.contract_details != "") ? element.contract_details[0].ContractID : ""}: ${element.user_details[0].name} ${element.message}`
												    return (
												    	<>
													    	<Link className={(element.is_read == true) ? 'noti-item read': 'noti-item'} onClick={(e) => makeReadNotification(element._id, element.contract_details[0]._id)}>
											                    <h4 title={strH4}>{strH4}</h4>
											                    <span className="post-time">{convert_time(element.created_at)}</span>
											                </Link>
													    </>
													)
												})
											:
											<></>
										}
									</div>
					            </div>
					            {/*<div className="read-all"><a aria-current="page" class="active" href="/notifications">Xem tất cả</a></div>*/}
					        </div>
					    </div>
					</div>

					{/*<NotificationModal onClose={handleClose} show={showModalNotification} />*/}

					<Dropdown>
						<Dropdown.Toggle id="dropdown-button-dark-example1" variant="info">
						Xin chào {username}
						</Dropdown.Toggle>
						<Dropdown.Menu variant="primary" id="dropdown-button-example2">
							<Dropdown.Item className="dropdown-item-id-a" to='/user-info' as={Link}>
								Thông tin tài khoản
							</Dropdown.Item>
							<Dropdown.Item className="dropdown-item-id-a" to="/change-password" as={Link}>Đổi mật khẩu</Dropdown.Item>
							<Dropdown.Divider />
							<Dropdown.Item className="dropdown-item-id-a" onClick={logout} active>
								<img
								src={logoutIcon}
								alt='logoutIcon'
								width='18'
								height='18'
								className='mr-2'
								/>
								Đăng xuất
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</Navbar.Collapse>
			</Navbar>
		</>
	)

}

export default NavbarMenu