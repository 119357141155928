import React from 'react';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    padding: 20,
  },
  header: {
    marginBottom: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  label: {
    fontWeight: 'bold',
    marginRight: 5,
  },
  value: {
    marginBottom: 10,
  },
});

const PDFInvoice = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.title}>Invoice</Text>
      </View>
      <View>
        <Text>
          <Text style={styles.label}>Customer Name:</Text>
          <Text style={styles.value}>{data.customerName}</Text>
        </Text>
        <Text>
          <Text style={styles.label}>Product Name:</Text>
          <Text style={styles.value}>{data.productName}</Text>
        </Text>
        <Text>
          <Text style={styles.label}>Quantity:</Text>
          <Text style={styles.value}>{data.quantity}</Text>
        </Text>
        <Text>
          <Text style={styles.label}>Total Amount:</Text>
          <Text style={styles.value}>{data.totalAmount}</Text>
        </Text>
      </View>
    </Page>
  </Document>
);

export default PDFInvoice;
