import { createContext, useReducer, useState } from 'react'
import { NotificationReducer } from '../reducers/NotificationReducer'
import {
	apiUrl,
	LOADED_FAIL,
	LOADED_SUCCESS,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from './constants'//Note
import axios from 'axios'

export const NotificationContext = createContext()

const NotificationContextProvider = ({ children }) => {
	const [notificationState,  dispatch] = useReducer(NotificationReducer, {
		Notification: null,
		Notifications: [],
		NotificationsLoading: true
	});

	// Get all Notification
	const getNotification = async () => {
		try {
			const response = await axios.get(`${apiUrl}/api/forms/notification`)
			if (response.data.success) {
				dispatch({ type: LOADED_SUCCESS, payload: response.data.Notification })	
			}
		} catch (error) {
			dispatch({ type: LOADED_FAIL })
		}
	}

	const addNotification = async newNotification => {
		try {
			// console.log(newNotification,'sdsadsad')
			const response = await axios.post(`${apiUrl}/api/forms/notification/post`, newNotification)//note Users
			if (response.data.success) {
				// dispatch({ type: ADD, payload: response.data.Contract }) //note Users
				getNotification();
				return response.data
			}

		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	const isReadNotification = async idNotification => {
		try {
			const response = await axios.put(`${apiUrl}/api/forms/notification/put/${idNotification}`)//note Users
			if (response.data.success) {
				console.log(`${apiUrl}/api/forms/notification/put/${idNotification}`, response)
				getNotification();
				return response.data
			}

		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	// Notification context data
	const NotificationContextData = {
		notificationState,
		getNotification,
		addNotification,
		isReadNotification
	}

	return (
		<NotificationContext.Provider value={NotificationContextData}>
			{children}
		</NotificationContext.Provider>
	)
}

export default NotificationContextProvider
