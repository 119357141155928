
import { useContext, useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Toast from 'react-bootstrap/Toast'
import Table from 'react-bootstrap/Table'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Badge from 'react-bootstrap/Badge';

//Contexts
import { AuthContext } from '../contexts/AuthContext'
import { ContractContext } from '../contexts/ContractContext'
import { ProductCostContext } from '../contexts/ProductCostContext'
import { MandayCostContext } from '../contexts/MandayCostContext'
import { GuaranteeLetterCostContext } from '../contexts/GuaranteeLetterCostContext'
import { MiscExpenseCostContext } from '../contexts/MiscExpenseContext'
import { CapitalExpenditureCostContext } from "../contexts/CapitalExpenditureCostContext"
import { LoanInterestContext } from "../contexts/LoanInterestContext"
import { AuxiliaryCostContext } from '../contexts/AuxiliaryCostContext'
import { ImplementationCostContext } from '../contexts/ImplementationCostContext'

//Components
import { ActionButtons_Update_Delete, ActionButtons_Update_Status} from '../components/contract/ActionButtons_Contract'
import AddContractModal from '../components/contract/AddContractModal'
import UpdateContractModal from '../components/contract/UpdateContractModal'
import download from '../assets/download.png'
import iconKeyboard from '../assets/icon-keyboard.svg'
import iconListContract from '../assets/icon-list-contract.svg'
import iconViewContract from '../assets/icon-view-contract.svg'
import iconInvoice from '../assets/icon-invoice.svg'
import { UserContext } from '../contexts/UserContext'
import { RoleContext } from '../contexts/RoleContext'
import { HistoryContext } from '../contexts/HistoryContext'
import { EventContext } from '../contexts/EventContext'
import { UserManagerContext } from '../contexts/UserManagerContext'
import { HistoryChangeContext } from '../contexts/HistoryChangeContext'

import { ConfigDepartmentContext } from '../contexts/ConfigDepartmentContext'
import { ConfigCustomerContext } from '../contexts/ConfigCustomerContext'
import { ConfigCenterContext } from '../contexts/ConfigCenterContext'
import { InvoiceContext } from '../contexts/InvoiceContext'

import { Tables } from '../components/layout/Tables'
import axios from 'axios';
//View list các Forms cần nhập
export const Summary = () => {
	const params = useParams();
	// Contexts
	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)
	let role_new = roles[0];
	let UserID = _id;

	const {
		RoleState: { Role, Roles, RolesLoading },
		getRoles,
	} = useContext(RoleContext)

	const {
		ContractState: { Contract, Contracts, ContractsLoading },
		getContracts,
		setShowAddContractModal,
		showToast: { show, message, type },
		setShowToast
	} = useContext(ContractContext)
	const {
		UserState: { User, Users, UsersLoading },
		getUsers
	} = useContext(UserContext)

	// Start: Get all Users
	useEffect(() => getUsers(), [])
	useEffect(() => getRoles(), [])
	// console.log(role_new,'UsersUsersUsers')
	// console.log(Roles,'RolesRolesRoles')
	let roleName = FindArray(Roles, role_new).name;
	// let nameRole = newRole['name'];
	// const {
	// 	UserState: {Users, UsersLoadingLoading},
	// 	getUsers_By_Id
	// } = useContext(UserContext)

	// Start: Get all Contracts
	useEffect(() => getContracts(), [])
	// console.log("Contracts::::::::", Contracts)

	const {
		ProductCostState: { ProductCost, ProductCosts, ProductCostsLoading },
		getProductCosts,
		setshowImportProductCostModal,
		setShowAddProductCostModal
	} = useContext(ProductCostContext)
	// console.log(ProductCosts,'ProductCostsProductCostsProductCosts')
	useEffect(() => getProductCosts(), [])

	////////get contract by history
	const {
		HistoryState: { History, Historys, HistorysLoading },
		getHistory
	} = useContext(HistoryContext)
	useEffect(() => getHistory(), [])

	const {
		EventState: { Event, Events, EventsLoading },
		getEvent
	} = useContext(EventContext)
	useEffect(() => getEvent(), [])

	const {
		UserManagerState: { UserManager, UserManagers, UserManagersLoading },
		getAllUserManagers
	} = useContext(UserManagerContext)
	useEffect(() => getAllUserManagers(), [])
	// console.log("Historys", Contracts) 
	// console.log("Historys", Historys) 
	/////////////////////////////
	
	// useEffect(() => getUsers_By_Id(params.id), [])
	// hàm convert to date time
	function convert_time(date_time){
		const date = new Date(date_time); // Replace this with your actual date object
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		const seconds = ('0' + date.getSeconds()).slice(-2);
		const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		return formattedDate;
	}
	// function getCurrentDate(separator){
	//     let newDate = new Date()
	//     let date = newDate.getDate();
	//     let month = newDate.getMonth() + 1;
	//     let year = newDate.getFullYear();
	//     return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
	// }

	function getCurrentDate(separator) {
	    let newDate = new Date();
	    let date = newDate.getDate();
	    let month = newDate.getMonth() + 1;
	    let year = newDate.getFullYear();
	    let hours = newDate.getHours();
	    let minutes = newDate.getMinutes();
	    let seconds = newDate.getSeconds();
	    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date} ${hours}:${minutes}:${seconds}`;
	}


	const {
		DepartmentState: { Department, Departments, DepartmentsLoading },
		getDepartment
	} = useContext(ConfigDepartmentContext)

	const {
		CustomerState: { Customer, Customers, CustomersLoading },
		get_All_Customer
	} = useContext(ConfigCustomerContext)

	const {
		CenterState: { Centers, CentersLoading },
		get_All_Center
	} = useContext(ConfigCenterContext)

	useEffect(() => getDepartment(), [])
	useEffect(() => get_All_Customer(), [])
	useEffect(() => get_All_Center(), [])

	// console.log(Departments,'DPDPD');
	const {
		historyChangeState: { historyChange, historyChanges, historyChangesLoading },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)
	useEffect(() => getHistoryChanges(), [])
	
	let array_historyChange = FindArrayUser(historyChanges, UserID, Users, roleName);
	let array_historyChangeNew = FindArrayUserNew(historyChanges, UserID, Users, roleName);
	let array_historyChangess = FindArrayUserss(historyChanges, UserID, Users, roleName);
	// console.log(array_historyChangeNew,'array_historyChangeNewarray_historyChangeNew')
	function FindArrayUser(array_history, UserID , array_user, roleName) {
		let returnObject = array_user.find(x => x._id == UserID);
		let filteredArray = [];
		switch(roleName){
			case 'AM':
				if(returnObject != undefined){
					filteredArray = array_user.filter(function(item) {
						if(item.centers != undefined){
					  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
					  	}
					});
					let manage_UserId = FindArrayUserManagers(UserManagers,UserID);
					if(manage_UserId.length > 0){
						let manager_id_new = manage_UserId[0]['manager_id'];
						let managerObject = array_user.find(x => x._id == manager_id_new);
						if(managerObject != undefined){
							filteredArray.push(managerObject);
						}
					}
				}
				break;
			case 'TPKD':
				if(returnObject != undefined){
					filteredArray = array_user.filter(function(item) {
						if(item.centers != undefined){
					  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
					  	}
					});
				}
				break;
			case 'KTT':
				if(returnObject != undefined){
					filteredArray = array_user.filter(function(item) {
						if(item.centers != undefined){
					  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD' && item.role_details[0].name != 'KTT';
					  	}
					});
				}
				break;
			case 'BGD':
				if(returnObject != undefined){
					filteredArray = array_user.filter(function(item) {
						if(item.centers != undefined){
					  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name == 'BTGD';
					  	}
					});
				}
				break;
			case 'BTGD':
			case 'Admin':
				break;
		}

		if(filteredArray.length > 0){
			let array_historyChange = null;
			if(historyChanges != undefined){
				if(historyChanges.length > 0){
					array_historyChange = FindArrayHistoryChange(historyChanges,filteredArray);
					return array_historyChange;
				}
			}
		}else{
			return null;
		}
	}
	function FindArrayUserNew(array_history, UserID , array_user, roleName) {
		let returnObject = array_user.find(x => x._id == UserID);
		let filteredArray = [];
		switch(roleName){
			case 'AM':
				if(returnObject != undefined){
					filteredArray = array_user.filter(function(item) {
						if(item.centers != undefined){
					  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
					  	}
					});
					let manage_UserId = FindArrayUserManagers(UserManagers,UserID);
					if(manage_UserId.length > 0){
						let manager_id_new = manage_UserId[0]['manager_id'];
						let managerObject = array_user.find(x => x._id == manager_id_new);
						if(managerObject != undefined){
							filteredArray.push(managerObject);
						}
					}
				}
				break;
			case 'TPKD':
				if(returnObject != undefined){
					filteredArray = array_user.filter(function(item) {
						if(item.centers != undefined){
					  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM';
					  	}
					});
				}
				break;
			case 'KTT':
				if(returnObject != undefined){
					filteredArray = array_user.filter(function(item) {
						if(item.centers != undefined){
					  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
					  	}
					});
				}
				break;
			case 'BGD':
				if(returnObject != undefined){
					filteredArray = array_user.filter(function(item) {
						if(item.centers != undefined){
					  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name == 'BTGD' && item.role_details[0].name == 'BGD';
					  	}
					});
				}
				break;
			case 'BTGD':
			case 'Admin':
				break;
		}

		if(filteredArray.length > 0){
			let array_historyChange = null;
			if(historyChanges != undefined){
				if(historyChanges.length > 0){
					array_historyChange = FindArrayHistoryChange(historyChanges,filteredArray);
					return array_historyChange;
				}
			}
		}else{
			return null;
		}
	}

	function FindArrayUserss(array_history, UserID , array_user, roleName) {
		let returnObject = array_user.find(x => x._id == UserID);
		let filteredArray = [];
		switch(roleName){
			case 'AM':
				if(returnObject != undefined){
					filteredArray = array_user.filter(function(item) {
						if(item.centers != undefined){
					  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
					  	}
					});
					let manage_UserId = FindArrayUserManagers(UserManagers,UserID);
					if(manage_UserId.length > 0){
						let manager_id_new = manage_UserId[0]['manager_id'];
						let managerObject = array_user.find(x => x._id == manager_id_new);
						if(managerObject != undefined){
							filteredArray.push(managerObject);
						}
					}
				}
				break;
			case 'TPKD':
				if(returnObject != undefined){
					filteredArray = array_user.filter(function(item) {
						if(item.centers != undefined){
					  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM';
					  	}
					});
				}
				// console.log('2')
				break;
			case 'KTT':
				if(returnObject != undefined){
					filteredArray = array_user.filter(function(item) {
						if(item.centers != undefined){
					  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
					  	}
					});
				}
				break;
			case 'BGD':
				if(returnObject != undefined){
					filteredArray = array_user.filter(function(item) {
						if(item.centers != undefined){
					  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name == 'BTGD';
					  	}
					});
				}
				// console.log('3')
				break;
			case 'BTGD':
			case 'Admin':
				break;
		}

		if(filteredArray.length > 0){
			let array_historyChange = null;
			if(historyChanges != undefined){
				if(historyChanges.length > 0){
					array_historyChange = FindArrayHistoryChange(historyChanges,filteredArray);
					return array_historyChange;
				}
			}
		}else{
			return null;
		}
	}
	// console.log(array_historyChange,'================')
	function FindArrayHistoryChange(array, array_filter) {
		let array_id = [];
		for (let i = 0; i < array_filter.length; i++) {
			let value = array_filter[i];
			array_id.push(value._id);
		}
		let result = array.filter(function(element) {
			if(element != undefined){
		  		return array_id.includes(element.requester);
		  	}
		});
		return result;
	}

	function FindArrayUserManagers(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.filter(x => x.user_id[0] === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}
	const Render_Button_Custom = () => {
		// if(roleName == 'Admin'){
			return (
				<>
					<Button
						variant='primary'
						onClick={setShowAddContractModal.bind(this, true)}
					>
						Thêm mới
					</Button>
				</>
			)
		// }else if(roleName == 'AM'){
		// 	return (
		// 		<>
		// 			<Button
		// 				variant='primary'
		// 				onClick={setShowAddContractModal.bind(this, true)}
		// 			>
		// 				Thêm mới
		// 			</Button>
		// 		</>
		// 	)
		// }else{
		// 	return (
		// 		<>
					
		// 		</>
		// 	)
		// }
	}

	const Render_Button_View_Contract = (_data) =>{
		_data = _data._data;
		return (
			<>
				{/*<a href={`/summary/${_data._id}`} style={{marginTop:"10px"}}>
					<Button >
						Xem PTHĐ
					</Button>
				</a>*/}
				<OverlayTrigger
					overlay={
						<Tooltip id="tooltip">
							Xem PTHĐ
						</Tooltip>
					}
				>
					<a href={`/summary/${_data._id}`}>
						<img src={iconViewContract}  width="36" height="28"></img>
					</a>
				</OverlayTrigger>
			</>
		)
	}

	const Render_Button_Keyboard = (_data) =>{
		_data = _data._data;
		return (
			<>
				{/*<a href={`/inputform/${_data._id}`} style={{marginTop:"10px"}}>
					<Button>
						Nhập liệu
					</Button>
				</a>*/}
				<OverlayTrigger
					overlay={
						<Tooltip id="tooltip">
							Nhập liệu
						</Tooltip>
					}
				>
					<a href={`/inputform/${_data._id}`} title="Nhập liệu">
						<img src={iconKeyboard}  width="36" height="36"></img>
					</a>
				</OverlayTrigger>
			</>
		)
	}

	const Render_Button_ViewDetail = (_data) =>{
		_data = _data._data;
		return (
			<>
				<OverlayTrigger
					overlay={
						<Tooltip id="tooltip">
							Xem chi tiết
						</Tooltip>
					}
				>
					<a href={`/inputform/${_data._id}`} title="Xem chi tiết">
						<img src={iconKeyboard}  width="36" height="36"></img>
					</a>
				</OverlayTrigger>
			</>
		)
	}

	const Render_Button_Invoice = (_data) =>{
		_data = _data._data;
		return (
			<>
				<OverlayTrigger
					overlay={
						<Tooltip id="tooltip">
							Xuất hóa đơn
						</Tooltip>
					}
				>
					<a href={`/invoiceForm/${_data._id}`}>
						<img src={iconInvoice}  width="36" height="28"></img>
					</a>
				</OverlayTrigger>
			</>
		)
	}

	function FindArrayTPKD(array, ID, Roles) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID._id);
		if(returnObject != undefined){
			return returnObject.role_details[0].name;
		}else{
			return '';
		}
	}

	function FindArrayRoleBTGD(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	const Render_Button_Custom1 = (_data) => {
		
		let statusID = _data.StatusID;
		let userContract = _data._UserContract;
		let usersarray = _data._Users;
		let rolearray = _data._Roles;
		let nameUserContract = FindArrayTPKD(usersarray,userContract,rolearray);
		let roleUserContract = userContract.roles[0];
		let nameRoleContract = FindArrayRoleBTGD(Roles,roleUserContract).name;

		// console.debug("_data:::Render_Button_Custom1:::", _data, UserID, userContract.username, username)

		const {
			InvoiceState: { Invoice, Invoices, InvoicesLoading },
			getInvoice_ContractID
		} = useContext(InvoiceContext)
		useEffect(() => getInvoice_ContractID(params.id), [])
		let checkStatusInvoices = Invoices.find(x => x.contract === _data._id);
		// console.log(checkStatusInvoices,'InvoicesInvoicesInvoices',Invoices, _data._id)
		// let checkStatusInvoices;
		// let checkStatusInvoices = 1;
		let historyContract = '';
		if(array_historyChange != null){
			if(array_historyChange.length > 0){
				historyContract = array_historyChange.filter(x => x.contract === _data._id && x.form != 'contract');
			}
		}

		let historyContractNew = '';
		if(array_historyChangeNew != null){
			if(array_historyChangeNew.length > 0){
				historyContractNew = array_historyChangeNew.filter(x => x.contract === _data._id && x.form != 'contract');
			}
		}

		let historyContractss = '';
		if(array_historyChangess != null){
			if(array_historyChangess.length > 0){
				historyContractss = array_historyChangess.filter(x => x.contract === _data._id && x.form != 'contract');
			}
		}

		let checkUpdateSoHopDong;
		if(historyChanges.length > 0){
			let abc = historyChanges.filter(x => x.action == 'update' && x.form == 'contract' && x.data_change.includes("updateContractID"));
			checkUpdateSoHopDong = abc.filter(x => x.contract === _data._id );
		}
		// console.log(_data._id, historyContract, historyContractNew, historyContractss,'11111111111111111111111checkUpdateSoHopDongcheckUpdateSoHopDong')
		if(roleName == 'Admin'){
			return (
				<>
					<Render_Button_View_Contract _data={_data}/>
					<Render_Button_ViewDetail _data={_data}/>
				</>
			)
		}else if(roleName == 'AM'){
			if(statusID == 1 || statusID == -1){
				return (
					<>
						<Render_Button_View_Contract _data={_data}/>
						<Render_Button_Keyboard _data={_data}/>
						<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID}/>
						<ActionButtons_Update_Delete _idContract={_data._id} StatusDB={statusID} checkUpdateSoHopDong={checkUpdateSoHopDong} nameRoleContract={nameRoleContract} role={roleName} historyContractNew={historyContractNew} checkStatusInvoices={checkStatusInvoices}/>
					</>
				)
			}else if(statusID == 2){
				if(historyContract != ''){
					return (<></>)
				}else{
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_ViewDetail _data={_data}/>
						</>
					)
				}
			}else if(statusID == 3 || statusID == 5 || statusID == 7 || statusID == -2 || statusID == -4 || statusID == -6 || statusID == 6 || statusID == 8 || statusID == 9 || statusID == 4){
				if(historyContract != ''){
					return (<></>)
				}else{
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_ViewDetail _data={_data}/>
							{
								checkStatusInvoices != undefined ?
								<></>
								:
								<>
									{
										(statusID == 5 || statusID == 7 || statusID == 9) && historyContractss.length == 0 ?
											<ActionButtons_Update_Delete _idContract={_data._id} StatusDB={statusID} checkUpdateSoHopDong={checkUpdateSoHopDong} nameRoleContract={nameRoleContract} role={roleName} historyContractNew={historyContractNew} checkStatusInvoices={checkStatusInvoices} />
										:
											<></>
									}
								</>
							}
						</>
					)
				}
			}else if(statusID == 10){
				return (<></>)
			}else{
				if (statusID == 0) {
					return (<></>)
				} else {
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_ViewDetail _data={_data}/>
						</>
						
					)	
				}
			}
		}else if(roleName == 'TPKD'){
			if(statusID == 2){
				return (
					<>
						<Render_Button_View_Contract _data={_data}/>
						{/*<Render_Button_ViewDetail _data={_data}/>*/}
						<Render_Button_Keyboard _data={_data}/>
						<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID} historyContractNew={historyContractNew}/>
					</>
				)
			}else if(statusID == 1){
				if(roleName == nameUserContract){
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_Keyboard _data={_data}/>
							<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID}/>
							<ActionButtons_Update_Delete _idContract={_data._id} StatusDB={statusID} checkUpdateSoHopDong={checkUpdateSoHopDong} nameRoleContract={nameRoleContract} role={roleName} historyContractNew={historyContractNew} checkStatusInvoices={checkStatusInvoices} />
						</>
					)
				}else{
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_ViewDetail _data={_data}/>
						</>
					)
				}
			}else if(statusID == -2){
				return (
					<>
						<Render_Button_View_Contract _data={_data}/>
						<Render_Button_Keyboard _data={_data}/>
						<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID} historyContractNew={historyContractNew}/>
						{/*<ActionButtons_Update_Delete _idContract={_data._id} StatusDB={statusID} checkUpdateSoHopDong={checkUpdateSoHopDong} nameRoleContract={nameRoleContract} role={roleName} historyContractNew={historyContractNew} checkStatusInvoices={checkStatusInvoices} />*/}
					</>
				)
			}else if(statusID == 10){
				return (
					<>
					</>
				)
			}else if(statusID == 5 || statusID == 7 || statusID == -4 || statusID == -6 || statusID == 6 || statusID == 8 || statusID == 9){
				if(historyContract != ""){
					return (<></>)
				}else{
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_ViewDetail _data={_data}/>
							{
								checkStatusInvoices != undefined ?
								<></>
								:
								<>
									{
										(statusID == 5 || statusID == 7 || statusID == 9) && userContract.username == username ? 
											<ActionButtons_Update_Delete _idContract={_data._id} StatusDB={statusID} checkUpdateSoHopDong={checkUpdateSoHopDong} nameRoleContract={nameRoleContract} role={roleName} historyContractNew={historyContractNew} checkStatusInvoices={checkStatusInvoices} />
										:
										<>
										{
											(statusID == 5 || statusID == 7 || statusID == 9) && historyContractss != "" ?
												<ActionButtons_Update_Delete _idContract={_data._id} StatusDB={statusID} checkUpdateSoHopDong={checkUpdateSoHopDong} nameRoleContract={nameRoleContract} role={roleName} historyContractNew={historyContractNew} checkStatusInvoices={checkStatusInvoices} />
											:
												<></>
										}
										</>
									}
								</>
							}
						</>
					)
				}
			}else if(statusID == 3){
				return (
					<>
						<Render_Button_View_Contract _data={_data}/>
						{/*<Render_Button_Keyboard _data={_data}/>*/}
						<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID} historyContractNew={historyContractNew}/>
						{
							historyContract.length > 0 ?
							<>
								<ActionButtons_Update_Delete _idContract={_data._id} StatusDB={statusID} checkUpdateSoHopDong={checkUpdateSoHopDong} nameRoleContract={nameRoleContract} role={roleName} historyContractNew={historyContractNew} checkStatusInvoices={checkStatusInvoices} />
							</>
							:
							<>
							</>
						}
					</>
				)
			}else{
				if(statusID == -1 || statusID == 4){
					if(historyContract != "" && statusID == 4){
						return (<></>)
					}else{
						return (
							<>
								<Render_Button_View_Contract _data={_data}/>
								<Render_Button_ViewDetail _data={_data}/>
							</>
						)
					}
				}else{
					return (
						<>
							
						</>
					)
				}
			}
		}else if(roleName == 'KTT'){
			if(statusID == 4){
				return (
					<>
						<Render_Button_View_Contract _data={_data}/>
						{/*<Render_Button_ViewDetail _data={_data}/>*/}
						<Render_Button_Keyboard _data={_data}/>
						<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID} historyContractNew={historyContractNew}/>
					</>
				)
			}else if(statusID == 1){
				if(roleName == nameUserContract){
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_Keyboard _data={_data}/>
							{
								checkStatusInvoices != undefined ?
								<></>
								:
								<>
									<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID} historyContractNew={historyContractNew} checkUpdateSoHopDong={checkUpdateSoHopDong}/>
								</>
							}
							<ActionButtons_Update_Delete _idContract={_data._id} StatusDB={statusID} checkUpdateSoHopDong={checkUpdateSoHopDong} nameRoleContract={nameRoleContract} role={roleName} historyContractNew={historyContractNew} checkStatusInvoices={checkStatusInvoices} />
							{
								checkUpdateSoHopDong.length > 0
								?
								<><Render_Button_Invoice _data={_data}/></>
								:
								<></>
							}
						</>
					)
				}else{
					// if(nameRoleContract == "BTGD"){
					// 	return (
					// 		<>
					// 			<Render_Button_View_Contract _data={_data}/>
					// 			<Render_Button_Invoice _data={_data}/>
					// 		</>
					// 	)
					// }else{
						return (
							<>
								<Render_Button_View_Contract _data={_data}/>
								<Render_Button_ViewDetail _data={_data}/>
								{
									checkUpdateSoHopDong.length > 0
									?
									<><Render_Button_Invoice _data={_data}/></>
									:
									<></>
								}
							</>
						)
					// }
				}
			}else if(statusID == 5){
				return (
					<>
						<Render_Button_View_Contract _data={_data}/>
						{
							checkStatusInvoices != undefined
							?
							<><Render_Button_ViewDetail _data={_data}/></>
							:
							<><Render_Button_Keyboard _data={_data}/></>
						}
						
						{
							checkStatusInvoices != undefined ?
							<></>
							:
							<>
								<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID} historyContractNew={historyContractNew} checkUpdateSoHopDong={checkUpdateSoHopDong}/>
							</>
						}
						{/*<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID} historyContractNew={historyContractNew}/>*/}

						<ActionButtons_Update_Delete _idContract={_data._id} StatusDB={statusID} checkUpdateSoHopDong={checkUpdateSoHopDong} nameRoleContract={nameRoleContract} role={roleName} historyContractNew={historyContractNew} checkStatusInvoices={checkStatusInvoices} />
						{
							checkUpdateSoHopDong.length > 0
							?
							<><Render_Button_Invoice _data={_data}/></>
							:
							<></>
						}
						
					</>
				)
			}else if(statusID == 9 || statusID == 7){
				// if(historyContract != ''){
				// 	return (
				// 		<>
				// 			<Render_Button_Invoice _data={_data}/>
				// 		</>
				// 	)
				// }else{
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_ViewDetail _data={_data}/>
							{
								checkStatusInvoices != undefined ?
								<></>
								:
								<>
									{
										(statusID == 7 || statusID == 9) && userContract.username == username ? 
											<ActionButtons_Update_Delete _idContract={_data._id} StatusDB={statusID} checkUpdateSoHopDong={checkUpdateSoHopDong} nameRoleContract={nameRoleContract} role={roleName} historyContractNew={historyContractNew} checkStatusInvoices={checkStatusInvoices} />
										:
										<> 
											{
												(statusID == 7 || statusID == 9) && historyContractss.length > 0 ?
													<ActionButtons_Update_Delete _idContract={_data._id} StatusDB={statusID} checkUpdateSoHopDong={checkUpdateSoHopDong} nameRoleContract={nameRoleContract} role={roleName} historyContractNew={historyContractNew} checkStatusInvoices={checkStatusInvoices} />
												:
												<></>
											}
										</>	
									}
								</>
							}
							{
								checkUpdateSoHopDong.length > 0
								?
								<><Render_Button_Invoice _data={_data}/></>
								:
								<></>
							}		
						</>
					)
				// }
			}else if(statusID == -4 || statusID == -6 || statusID == 8){
				// if(historyContract != ''){
				// 	console.log(historyContract,'historyContracthistoryContract')
				// 	return (<></>)
				// }else{
					// console.log('sadsadsadsadsa')
					if(statusID == -4){
						return (
							<>
								<Render_Button_View_Contract _data={_data}/>
								<Render_Button_Keyboard _data={_data}/>
								<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID} historyContractNew={historyContractNew}/>
								{/*<ActionButtons_Update_Delete _idContract={_data._id} StatusDB={statusID} checkUpdateSoHopDong={checkUpdateSoHopDong} nameRoleContract={nameRoleContract} role={roleName} historyContractNew={historyContractNew} checkStatusInvoices={checkStatusInvoices} />*/}
							</>
						)
					}else if(statusID == -6){
						return (
							<>
								<Render_Button_View_Contract _data={_data}/>
								<Render_Button_ViewDetail _data={_data}/>
							</>
						)
					}else{
						return (
							<>
								<Render_Button_View_Contract _data={_data}/>
								<Render_Button_ViewDetail _data={_data}/>
								{/*<Render_Button_Invoice _data={_data}/>*/}
							</>
						)
					}
				// }
			}else if(statusID == -1  || statusID == 2 ||  statusID == 3){
				return (
					<>
						<Render_Button_View_Contract _data={_data}/>
						<Render_Button_ViewDetail _data={_data}/>
					</>
				)
			}else if(statusID == 10){
				return (
					<>
					</>
				)
			}else{
				if(statusID == -2 ){
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_ViewDetail _data={_data}/>
						</>
					)
				}else if(statusID == 6){
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_ViewDetail _data={_data}/>
						</>
					)
				}else{
					if (statusID == 0) {
						return (<></>)
					} else {
						return (
							<>
								<Render_Button_View_Contract _data={_data}/>
								<Render_Button_ViewDetail _data={_data}/>
								{
									statusID < 7 
									?
									<>
										<Render_Button_Keyboard _data={_data}/>
										<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID}/>
										<ActionButtons_Update_Delete _idContract={_data._id} StatusDB={statusID} checkUpdateSoHopDong={checkUpdateSoHopDong} nameRoleContract={nameRoleContract} role={roleName} historyContractNew={historyContractNew} checkStatusInvoices={checkStatusInvoices} />
									</>
									:
									<></>
								}
							</>
						)
					}
				}
			}
		}else if(roleName == 'BGD'){
			if(statusID == 6 || statusID == -4){
				return (
					<>
						<Render_Button_View_Contract _data={_data}/>
						<Render_Button_ViewDetail _data={_data}/>
						<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID}/>
					</>
				)
			}else if(statusID == 1){
				if(roleName == nameUserContract){
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_Keyboard _data={_data}/>
							<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID} historyContractNew={historyContractNew} checkUpdateSoHopDong={checkUpdateSoHopDong}/>
							<ActionButtons_Update_Delete _idContract={_data._id} StatusDB={statusID} checkUpdateSoHopDong={checkUpdateSoHopDong} nameRoleContract={nameRoleContract} role={roleName} historyContractNew={historyContractNew} checkStatusInvoices={checkStatusInvoices} />
						</>
					)
				}else{
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_ViewDetail _data={_data}/>
						</>
					)
				}
			}else if(statusID == 7){
				return (
					<>
						<Render_Button_View_Contract _data={_data}/>
						<Render_Button_ViewDetail _data={_data}/>
						{
							checkStatusInvoices != undefined ?
							<></>
							:
							<>
								<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID}/>
							</>
						}
					</>
				)
			}else if(statusID == 8){
				return (
					<>
						<Render_Button_View_Contract _data={_data}/>
						<Render_Button_ViewDetail _data={_data}/>
					</>
				)
			}else if(statusID == -1 || statusID == 2 || statusID == -2 ||  statusID == 5 ||  statusID == 3 || statusID == 4){
				return (
					<>
						<Render_Button_View_Contract _data={_data}/>
						<Render_Button_ViewDetail _data={_data}/>

					</>
				)
			}else if(statusID == 10){
				return (
					<>
					</>
				)
			}else if(statusID == -6){
				if(roleName == nameUserContract){
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_Keyboard _data={_data}/>
							<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID}/>
							{/*<ActionButtons_Update_Delete _idContract={_data._id} StatusDB={statusID} checkUpdateSoHopDong={checkUpdateSoHopDong} nameRoleContract={nameRoleContract} role={roleName} historyContractNew={historyContractNew} checkStatusInvoices={checkStatusInvoices} />*/}
						</>
					)
				}else{
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_ViewDetail _data={_data}/>
							<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID}/>
						</>
					)
				}
			}else if(statusID == 9 ){
				if(historyContract != ''){
					return (<></>)
				}else{
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_ViewDetail _data={_data}/>
						</>
					)
				}
			}else{
				if (statusID == 0) {
					return (<></>)
				} else {
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_ViewDetail _data={_data}/>
							{
								statusID < 9 
								?
								<>
									{/*<Render_Button_Keyboard _data={_data}/>*/}
									<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID}/>
									{/*<ActionButtons_Update_Delete _idContract={_data._id} StatusDB={statusID} checkUpdateSoHopDong={checkUpdateSoHopDong} nameRoleContract={nameRoleContract} role={roleName} historyContractNew={historyContractNew} checkStatusInvoices={checkStatusInvoices} />*/}
								</>
								:
								<></>
							}
						</>
					)
				}
			}
		}else if(roleName == 'BTGD'){
			if(statusID == 1){
				if(roleName == nameUserContract){
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_Keyboard _data={_data}/>
							<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID}/>
							{
								checkStatusInvoices != undefined ?
								<>
									
								</>
								:
								<>
									<ActionButtons_Update_Delete _idContract={_data._id} StatusDB={statusID} checkUpdateSoHopDong={checkUpdateSoHopDong} nameRoleContract={nameRoleContract} role={roleName} historyContractNew={historyContractNew} checkStatusInvoices={checkStatusInvoices} />
								</>
							}
						</>
					)
				}else{
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_ViewDetail _data={_data}/>
						</>
					)
				}
			}else if(statusID == 10){
				return (
					<>
					</>
				)
			}else if(statusID == 9){
				if(roleName == nameUserContract){
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_ViewDetail _data={_data}/>
						</>
					)
				}else{
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_ViewDetail _data={_data}/>
							{
								checkStatusInvoices != undefined ?
								<></>
								:
								<>
									<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID}/>
								</>
							}
						</>
					)
				}
			}else{
				if (statusID == 0) {
					return (<></>)
				} else {
					return (
						<>
							<Render_Button_View_Contract _data={_data}/>
							<Render_Button_ViewDetail _data={_data}/>
							<ActionButtons_Update_Status _data={_data} _id={_data._id} UserID={UserID} role={roleName} StatusDB={statusID}/>
						</>
					)
				}
			}
		}else{
			return (<></>)
		}
	}

	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	function FindArrayHistory(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x.ContractID === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	function show_detail_contract_by_id(idContract){
		console.log(idContract,'idContract')
	}

	const Render_Status_Contract = (_Contract) => {
		let Contract = _Contract._Contract;
		let statusID = _Contract.StatusID;
		let Reason = _Contract.Reason;
		let historyContract = '';
		let historyUpdateSoHopDong = '';
		if(array_historyChange != null && array_historyChange != undefined){
			if(array_historyChange.length > 0){
				historyContract = array_historyChange.filter(x => x.contract === Contract._id && x.form != 'contract');
				historyUpdateSoHopDong = array_historyChange.filter(x => x.contract === Contract._id && x.action == 'update');
			}
		}
		if(historyChanges.length > 0){
			let abc = historyChanges.filter(x => x.action == 'update' && x.form == 'contract' && x.data_change.includes("updateContractID"));
			historyUpdateSoHopDong = abc.filter(x => x.contract === Contract._id );
		}
		if(roleName == 'AM'){
			if(statusID == '-1' || statusID == '1'){
				if(statusID == '-1'){
					return (
						<>
							<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events)+" ("+Reason+")" : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
						</>
					)
				}else{
					return (
						<>
							<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
						</>
					)	
				}
			}else if(statusID == '3' || statusID == '5' || statusID == '7' ||  statusID == '-2' || statusID == '-4' || statusID == '-6' || statusID == '6' || statusID == '8' || statusID == '9' || statusID == '4'){
				// if(historyContract != ''){
				// 	return (<></>)
				// }else{
					if(statusID == '-2' || statusID == '-4' || statusID == '-6'){
						return (
							<>
								<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events)+" ("+Reason+")" : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
							</>
						)
					}else if(statusID == '5' || statusID == '7' || statusID == '9'){
						console.log()
						return (
							<>
								<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? (historyUpdateSoHopDong.length > 0 ? get_status_name(Historys, Contract._id,Events)+'/Đã cập nhật số hợp đồng' : get_status_name(Historys, Contract._id,Events)): '' }</span>
							</>
						)
					}else{
						return (
							<>
								<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
							</>
						)
					}
				// }
			}else if(statusID == '2'){
				return (
					<>
						<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
					</>
				)
			}else if(statusID == '10'){
				return (
					<>
						<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
					</>
				)	
			}else{
				return (
					<>
						<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
					</>
				)
			}
		}else if(roleName == 'TPKD'){
			if(statusID == '-1' || statusID == '1' || statusID == '2' || statusID == '3'){
				if(statusID == '-1'){
					return (
						<>
							<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events)+" ("+Reason+")" : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
						</>
					)
				}else{
					return (
						<>
							<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
						</>
					)	
				}
			}else if(statusID == '5' || statusID == '7' || statusID == '-2' || statusID == '-4' || statusID == '-6' || statusID == '6' || statusID == '8' || statusID == '9'){
				// if(historyContract != ''){
				// 	return (<></>)
				// }else{
					if(statusID == '-2' || statusID == '-4' || statusID == '-6'){
						return (
							<>
								<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events)+" ("+Reason+")" : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
							</>
						)
					}else if(statusID == '5' || statusID == '7' || statusID == '9'){
						return (
							<>
								<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? (historyUpdateSoHopDong.length > 0 ? get_status_name(Historys, Contract._id,Events)+'/Đã cập nhật số hợp đồng' : get_status_name(Historys, Contract._id,Events)): '' }</span>
							</>
						)
					}else{
						return (
							<>
								<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
							</>
						)
					}
				// }
			}else if(statusID == '4'){
				return (
						<>
							<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
						</>
					)
			}else if(statusID == '10'){
				return (
					<>
						<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
					</>
				)
			}else{
				return (
					<>
						<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
					</>
				)
			}
		}else if(roleName == 'KTT'){
			if(statusID == '-1' || statusID == '1' || statusID == '-2' || statusID == '2' || statusID == '4' || statusID == '3'){
				if(statusID == '-1' || statusID == '-2'){
					return (
						<>
							<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events)+" ("+Reason+")" : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
						</>
					)
				}else{
					return (
						<>
							<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
						</>
					)	
				}
			}else if(statusID == '-4' || statusID == '-6'|| statusID == '8' || statusID == '7' || statusID == '9' || statusID == '5'){
				// if(historyContract != ''){
				// 	return (<></>)
				// }else{
					if(statusID == '-4' || statusID == '-6' ){
						return (
							<>
								<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events)+" ("+Reason+")" : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
							</>
						)
					}else if(statusID == '5' || statusID == '7' || statusID == '9'){
						return (
							<>
								<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? (historyUpdateSoHopDong.length > 0 ? get_status_name(Historys, Contract._id,Events)+'/Đã cập nhật số hợp đồng' : get_status_name(Historys, Contract._id,Events)): '' }</span>
							</>
						)
					}else{
						return (
							<>
								<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
							</>
						)
					}
				// }
			}else if(statusID == '6'){
				return (
						<>
							<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
						</>
					)
			}else if(statusID == '10'){
				return (
					<>
						<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events)+" ("+Reason+")" : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
					</>
				)
			}else{
				return (
					<>
						<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
					</>
				)
			}
		}else if(roleName == 'BGD'){
			if(statusID == '-1' || statusID == '1' || statusID == '-2' || statusID == '2' || statusID == '-4' || statusID == '4' || statusID == '3' || statusID == '5' || statusID == '6' || statusID == '7'){
				if(statusID == '-1' || statusID == '-2' || statusID == '-4'){
					return (
						<>
							<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events)+" ("+Reason+")" : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
						</>
					)
				}else if(statusID == '5' || statusID == '7' || statusID == '9'){
					return (
						<>
							<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? (historyUpdateSoHopDong.length > 0 ? get_status_name(Historys, Contract._id,Events)+'/Đã cập nhật số hợp đồng' : get_status_name(Historys, Contract._id,Events)): '' }</span>
						</>
					)
				}else{
					return (
						<>
							<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
						</>
					)	
				}
			}else if(statusID == '9' || statusID == '-6'){
				// if(historyContract != ''){
				// 	return (<></>)
				// }else{
					if(statusID == '-6'){
						return (
							<>
								<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events)+" ("+Reason+")" : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
							</>
						)
					}else{
						return (
							<>
								<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
							</>
						)
					}
				// }
			}else if(statusID == '8'){
				return (
						<>
							<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
						</>
					)
			}else if(statusID == '10'){
				return (
					<>
						<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events)+" ("+Reason+")" : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
					</>
				)	
			}else{
				return (
					<>
						<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
					</>
				)
			}
		}else if(roleName == 'BTGD'){
			if(statusID == '5' || statusID == '7' ||statusID == '9'){
				return (
					<>
						<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? (historyUpdateSoHopDong.length > 0 ? get_status_name(Historys, Contract._id,Events)+'/Đã cập nhật số hợp đồng' : get_status_name(Historys, Contract._id,Events)): '' }</span>
					</>
				)
			}else if(statusID == '1'){
				return (
					<>
						<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
					</>
				)
			}else{
				return (
					<>
						<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events)+" ("+Reason+")" : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
					</>
				)
			}
		}else{
			return (
				<>
					<span className='text' title={get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events)+" ("+Reason+")" : ''} onClick={() => {setshowImportProductCostModal(true);}}>{ get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys, Contract._id,Events) : '' }</span>
				</>
			)
		}
	}

	
	// let userManager = [
	// 	{'user_id':'649e5813d4fb395d4e8f8e62','manager_id':'649e58fdd4fb395d4e8f8f07'},
	// 	{'user_id':'649e58fdd4fb395d4e8f8f07','manager_id':'649e58c4d4fb395d4e8f8eee'},
	// 	{'user_id':'649e58c4d4fb395d4e8f8eee','manager_id':'649e5911d4fb395d4e8f8f10'}
	// ];
	function filter_new_contract(array,UserID){
		let returnObject = "";
		// if (UserID != undefined) returnObject = array.filter((element) => element.user.centers != null ? element.user.centers[0] : '' == UserID[0]);
		if (UserID != undefined) returnObject = array.filter((element) => element.CenterID == UserID[0]);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}
	let rows;
	let newContract;
	let newContract111 = [];
	if(roleName == 'Admin' || roleName == 'BTGD'){
		newContract = Contracts
	}else if(roleName == 'KTT' || roleName == 'BGD'){
		let centerOfUser = filter_Users(Users,UserID).centers;
		// console.log(centerOfUser,'====1111===')
		if (centerOfUser != undefined) {
			if(Contracts.length > 0){
				newContract111 = filter_new_contract(Contracts,centerOfUser);
			}
		}
		newContract = newContract111;
	}else{
		console.log(UserManagers,UserID,roleName,get_user_id(UserID,UserManagers),'sdsd')
		let newContract1 = Contracts.filter((v) => get_user_id(UserID,UserManagers).includes(v.user._id))
		if(roleName == 'AM'){
			newContract = newContract1;
		}else{
			// let centerOfUser = filter_Users(Users,UserID).centers;
			// // console.log(centerOfUser,'====1111===')
			// if (centerOfUser != undefined) {
			// 	if(Contracts.length > 0){
			// 		newContract111 = filter_new_contract(Contracts,centerOfUser);
			// 	}
			// }
			// newContract = newContract111;
			// console.log(newContract1,'///////////////')
			// newContract = newContract1;
			let contract_me = Contracts.filter((v) => v.user._id == UserID)

			let newContract2 = newContract1.concat(contract_me);
			newContract = newContract2;
			// // console.log(newContract2,'nguyen hoang sang')

			// let array_new = [];
			// // for (var j = 0; j < Historys.length; j++) {
			// // 	if(j = Historys.length - 1){
			// // 		for (var i = 0; i < newContract1.length; i++) {
			// // 			if(newContract1[i]._id == Historys[j].ContractID){
			// // 				console.log(Historys[j].StatusID,display_contract(roleName,Historys[j].StatusID),'=================')
			// // 				if(Historys[j].StatusID > display_contract(roleName,Historys[j].StatusID)){
			// // 					array_new.push(newContract1[i]);
			// // 				}
			// // 			}
			// // 		}		
			// // 	}
			// // }

			// for (var i = 0; i < newContract2.length; i++) {
			// 	let arr_history = filter_history(Historys,newContract2[i]._id);
			// 	console.log(arr_history,'nguyen hoang sang')
			// 	console.log('++++++++++++++++')
			// 	// if(arr_history.StatusID > display_contract(roleName,arr_history.StatusID)){
			// 		// array_new.push(newContract2[i]);
			// 	// }
			// }
			// newContract = array_new;
		}
	}

	// console.log("121312313131312", newContract)

	function sortDatetimeDESC(a, b) {
	    let datetimeA = new Date(a['Date']).getTime();
	    let datetimeB = new Date(b['Date']).getTime();
	    return datetimeA < datetimeB ? 1 : datetimeB < datetimeA ? -1 : 0;
	}
	// function sortDatetimeDESC(a, b) {
	//     let datetimeA = new Date(a['Date']).getTime();
	//     let datetimeB = new Date(b['Date']).getTime();

	//     let currentDate = new Date().setHours(0, 0, 0, 0); // Ngày hiện tại không có thời gian
	//     let timestampCurrentDate = new Date(currentDate).getTime();

	//     if (datetimeA === timestampCurrentDate && datetimeB !== timestampCurrentDate) {
	//         return -1; // Đưa datetimeA lên đầu nếu nó là ngày hiện tại
	//     } else if (datetimeA !== timestampCurrentDate && datetimeB === timestampCurrentDate) {
	//         return 1; // Đưa datetimeB lên đầu nếu nó là ngày hiện tại
	//     } else {
	//         return datetimeA < datetimeB ? 1 : datetimeB < datetimeA ? -1 : 0;
	//     }
	// }

	// function sortDatetimeDESC(a, b) {
	//     let datetimeA = a['Date'] ? new Date(a['Date']).getTime() : null;
	//     let datetimeB = b['Date'] ? new Date(b['Date']).getTime() : null;

	//     // Kiểm tra nếu datetimeA hoặc datetimeB là null, đưa các giá trị null xuống cuối danh sách
	//     if (datetimeA === null && datetimeB === null) {
	//         return 0; // Cả hai đều bằng null, không cần sắp xếp
	//     } else if (datetimeA === null) {
	//         return 1; // datetimeA là null, đưa a xuống cuối danh sách
	//     } else if (datetimeB === null) {
	//         return -1; // datetimeB là null, đưa b xuống cuối danh sách
	//     }

	//     // Tiếp tục so sánh datetimeA và datetimeB nếu không có giá trị null
	//     return datetimeA < datetimeB ? 1 : datetimeB < datetimeA ? -1 : 0;
	// }

	// function sortDatetimeDESC(a, b) {
	//     // Lấy giá trị datetime từ đối tượng a và b, nếu không có thì giả định là ngày hiện tại
	//     let datetimeA = a['Date'] ? new Date(a['Date']).getTime() : new Date().getTime();
	//     let datetimeB = b['Date'] ? new Date(b['Date']).getTime() : new Date().getTime();

	//     // So sánh datetimeA và datetimeB
	//     return datetimeA < datetimeB ? 1 : datetimeB < datetimeA ? -1 : 0;
	// }
	newContract.sort(sortDatetimeDESC);

	function filter_Users(array,UserID){
		let returnObject = "";
		if (UserID != undefined) returnObject = array.filter((element) => element._id === UserID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return '';
		}
	}


	function filter_history(array,ContractID){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return '';
		}
	}

	function get_user_id(userLogin, userManager) {
	  let managedUsers = [];
	  // managedUsers.push(userLogin)

	  function findManagedUsers(managerId) {
	    let users = userManager.filter(item => item.manager_id[0] === managerId);
	    if (users.length > 0) {
	      for (let user of users) {
	        findManagedUsers(user.user_id[0]);
	      }
	    } else {
	      managedUsers.push(managerId);
	    }
	  }

	  findManagedUsers(userLogin);
	  return managedUsers;
	}

	const {
		InvoiceState: { Invoice, Invoices, InvoicesLoading },
		getAllInvoice
	} = useContext(InvoiceContext)
	useEffect(() => getAllInvoice(), [])

	function getStatusInvoice(Contract,Invoices,Total_OutputIntoMoney){
		let arr = filter_invoice(Invoices,Contract._id);
		if(arr.length > 0){
			let Total_Invoice = Sum_Invoice(arr);
			let Total_PercentInvoice = Total_OutputIntoMoney > 0 ? formatPercentage(Total_Invoice*100/Total_OutputIntoMoney) : 0;
			return Total_PercentInvoice+" %";
		}else{
			return '0 %';
		}
	}

	function Sum_Invoice(Invoice) {
		let Total_Invoice = 0;
		Invoice.map(Invoices => {
			Total_Invoice += Invoices.InvoiceVal
		});
		return Total_Invoice;
	}

	function filter_invoice(array,ContractID){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.contract === ContractID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	function filter_productcost(array,ContractID){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.contract === ContractID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	const infoUserLogin = FindArray(Users, _id)
	// const CentersNew = filter_new_centers(Centers, infoUserLogin)
	// console.log("123123123", {infoUserLogin, CentersNew})
	//
	//
	let CentersNew;
	let DepartmentsNew;
	const departmentFillter = (Departments.length > 0 ) ? Departments.filter(x => x.TypeDepartment == "1") : []
	switch(roleName){
		case 'AM':
		case 'TPKD':
			CentersNew = filter_Users(Users, UserID).center_details;
			DepartmentsNew = filter_Users(Users, UserID).department_details;
			break;
		case 'KTT':
		case 'BGD':
			CentersNew = filter_Users(Users, UserID).center_details;
			DepartmentsNew = (roleName == "Admin" || roleName == "BTGD") ? departmentFillter : filter_department_by_center(departmentFillter, (typeof infoUserLogin != "string") ? infoUserLogin.centers[0] : "")
			// DepartmentsNew = Departments;
			break;
		case 'BTGD':
		case 'Admin':
			CentersNew = Centers;
			DepartmentsNew = Departments;
			break;
	}

	// console.log("123123123", {CentersNew, DepartmentsNew})

	let checkLoading = !ContractsLoading && !ProductCostsLoading && !HistorysLoading && !EventsLoading && !UserManagersLoading && !DepartmentsLoading && !CustomersLoading && !CentersLoading && !InvoicesLoading && !historyChangesLoading
	const newContracts = newContract.map((Contract, i) => ({
		...Contract,
		STT: parseInt(i+1),
		CenterID: FindArray(Centers, Contract.CenterID) != '' ? FindArray(Centers, Contract.CenterID).CenterName.trim() : Contract.Center, 
		DepartmentID: FindArray(Departments, Contract.DepartmentID) != '' ? FindArray(Departments, Contract.DepartmentID).DepartmentName : Contract.Deparment,
		CustomerID: FindArray(Customers, Contract.CustomerID) != '' ? FindArray(Customers, Contract.CustomerID).CustomerName : Contract.CustomerID,
		user: Contract.user.username,
		Date: convert_time(Contract.Date != null ? Contract.Date : getCurrentDate("-")),
		StatusID: get_status(Historys,Contract._id).StatusID,
		StatusName: 
		<>
			<Render_Status_Contract _Contract={Contract} StatusID={get_status(Historys,Contract._id).StatusID} Reason={get_status(Historys,Contract._id) != '' ? get_status(Historys,Contract._id).Reason : ''}/>
		</>,
		StatusInvoive: check_role_invoice() == 1 ? getStatusInvoice(Contract,Invoices,Sum_OutputIntoMoney(filter_productcost(ProductCosts,Contract._id))) : '',
		Action: <>
		{
			checkLoading ? 
			<Render_Button_Custom1 _loading={checkLoading} _id={Contract._id} StatusID={get_status(Historys,Contract._id).StatusID} _UserContract={Contract.user} _Users={Users} _Roles={Roles}/>
			:
			""
		}
		</>
	}))

	function check_role_invoice(){
		if(roleName == 'AM'){
			return 1;
		}else if(roleName == 'TPKD'){
			return 1;
		}else if(roleName == 'KTT'){
			return 1;
		}else if(roleName == 'BGD'){
			return 1;
		}else if(roleName == 'BTGD'){
			return 1;
		}else{
			return 1;
		}
	}

	function filter_department_by_center(array, center_id) {
		let rs = []
		if (array.length > 0 && center_id != "") {
			array.map(function (e, i) {
				if (e.centers.length > 0 ) {
					if (roleName == "TPKD" || roleName == "AM") {
						if (e.centers[0] == center_id && e._id == infoUserLogin.departments[0]) rs.push(e)
					} else{
						if (e.centers[0] == center_id) rs.push(e)
					}
				}
			})
		}

		return rs
	}

	// if (checkLoading) {
	// 	rows = newContracts;
	// } else {
	// 	rows = newContracts;
	// }
	rows = newContracts;
	// console.debug("newContract", checkLoading, newContracts)
	const columns = [
	    { accessor: "STT", label: "STT", isSearch: false, isSort: false},
	    { accessor: "ContractID", label: "Số hợp đồng/PO", isSearch: true, isSort: true},
	    { accessor: "CenterID", label: "Trung tâm", isSearch: true, isSort: true, isSelect: "Centers", jsonDataCenter: CentersNew},
	    { accessor: "DepartmentID", label: "Phòng", isSearch: true, isSort: true, isSelect: "Departments", jsonDataDepartment: DepartmentsNew},
	    { accessor: "CustomerID", label: "Khách hàng", isSearch: true, isSort: true},
	    { accessor: "user", label: "Tên AM", isSearch: true, isSort: true},
	    { accessor: "Date", label: "Ngày", isSearch: true, isSort: true},
	    { accessor: "StatusName", label: "Trạng thái", isSearch: true, isSort: true,type:1, isSelect: "Events", jsonDataEvent: Events},
	    { accessor: "StatusInvoive", label: "Trạng thái xuất hóa đơn", isSearch: false, isSort: false,width: "12%",jsonDataStatusInvoice: Events},
	    { accessor: "Action", label: "Thao tác", isSearch: false, isSort: false, width: "15%",type:1},
	    { accessor: "ActionFilter", label: "Lọc", isHidden: true, isSearch: false, isSort: false, width: "15%", filter:1},
	    { accessor: "StatusID", label: "Trạng thái hóa đơn", isHidden: true, isSearch: true, isSort: false, isSelect: "StatusID", jsonDataEvent: Events},
	];

	function formatPercentage(percentage) {
	    const formatted = percentage.toFixed(2); // Định dạng với 2 chữ số sau dấu phẩy
	    if (formatted.endsWith('.00') || formatted.endsWith('.0')) {
	    	return formatted.slice(0, -3); // Cắt bỏ ".00" nếu có
	    }
	    return formatted;
	}

	function Sum_OutputIntoMoney(ProductsCost) {
		let Total_OutputIntoMoney = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_OutputIntoMoney += ListProduct.OutputIntoMoney
			))
		)
		console.log(Total_OutputIntoMoney,'Total_OutputIntoMoneyTotal_OutputIntoMoney')
		return Total_OutputIntoMoney;
	}
	
	function display_contract(role,statusID){
		switch(role){
			case 'AM':
				return -7;
				break;
			case 'TPKD':
				if(statusID < 0){
					return -7;
				}else{
					return 1;
				}
				break;
			case 'KTT':
				if(statusID < 0 && statusID != -1){
					return -7;
				}else{
					return 3;
				}
				break;
			case 'BGD':
				if(statusID < 0 && statusID != -1 && statusID != -2){
					return -7;
				}else{
					return 5;
				}
				break;
			case 'BTGD':
				return -7;
				break;
		}
	}

	function get_status_name(array,ContractID,event_array){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			let statusID = lastElement.StatusID;
			for (var i = 0; i < event_array.length; i++) {
				let value = event_array[i];
				if (value.StatusID == statusID) {
					return value.name;
				}
			}
		}else{
			return '';
		}
	}

	function get_status(array,ContractID){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return {StatusID: 0,Reason:''};
		}
	}

	let body = null
	let stt = 1
	
	if (ContractsLoading) {
		body = (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	} else if (Contracts.length === 0) {
		body = (
			<>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h2'> QUẢN LÝ HỢP ĐỒNG</Card.Header>
					<Card.Body>
						<Card.Title>Chưa có hợp đồng</Card.Title>
						<Button
							variant='primary'
							onClick={setShowAddContractModal.bind(this, true)}
						>
							Thêm
						</Button>
					</Card.Body>
				</Card>
			</>
		)
	} else {

		body = (
			<>
				<Card className='text-center mx-5 my-5' aninmation={false.toLocaleString()}>
					<Card.Header as='h2'> QUẢN LÝ HỢP ĐỒNG</Card.Header>
					<Card.Body>
{/*						<Table striped bordered hover size="sm">
							<thead >
								<tr>
									<th>STT</th>
									<th>Số hợp đồng/PO</th>
									<th>Trung tâm</th>
									<th>Phòng</th>
									<th>Khách hàng</th>
									<th>Tên AM</th>
									<th>Ngày</th>
									<th>Thao tác</th>
								</tr>
							</thead>
							<tbody>
								{Contracts.map(Contract => (
									<tr key={Contract._id} >
										<td>{stt++}  </td>
										<td>{Contract.ContractID}</td>
										<td style={{ width: '200px' }}>{FindArray(Centers, Contract.CenterID) != '' ? FindArray(Centers, Contract.CenterID).CenterName : Contract.Center}</td>
										<td style={{ width: '200px' }}>{FindArray(Departments, Contract.DepartmentID) != '' ? FindArray(Departments, Contract.DepartmentID).DepartmentName : Contract.Deparment}</td>
										<td style={{ width: '200px' }}>{FindArray(Customers, Contract.CustomerID) != '' ? FindArray(Customers, Contract.CustomerID).CustomerName : Contract.CustomerID}</td>
										<td>{Contract.user.username}</td>
										<td>{convert_time(Contract.Date != null ? Contract.Date : getCurrentDate("-"))}</td>
										<td>
											<a href={`/summary/${Contract._id}`}>
												<Button >
													Xem PTHĐ
												</Button>
											</a>
											<ActionButtons_Update_Delete _id={Contract._id} />
											<a href={`/inputform/${Contract._id}`}>
												<Button>
													Nhập liệu
												</Button>
											</a>
											<Render_Button_Custom1 />
										</td>


									</tr>

								))
								}

							</tbody>
						</Table>*/}
						<Tables rows={rows} defaultRows={rows} checkLoading={checkLoading} columns={columns} />
						<Render_Button_Custom />
					</Card.Body>
				</Card>
			</>
		)
	}

	return (
		<>
			{body}
			<AddContractModal />

			{Contract !== null && <UpdateContractModal />}
			{/* After Contract is added, show toast */}
			<Toast
				show={show}
				style={{ position: 'fixed', top: '20%', right: '10px' }}
				className={`bg-${type} text-white`}
				onClose={setShowToast.bind(this, {
					show: false,
					message: '',
					type: null
				})}
				delay={3000}
				autohide
			>
				<Toast.Body>
					<strong>{message}</strong>
				</Toast.Body>
			</Toast>
		</>
	)
}

//View Contract_id Phân tích hợp đồng tổng thể
export const Summary_id = () => {
	const params = useParams();
	
	///////////////get role/////////////////
	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)
	const {
		RoleState: { Role, Roles, RolesLoading },
		getRoles,
	} = useContext(RoleContext)
	useEffect(() => getRoles(), [])
	let role_new = roles[0];
	let UserID = _id;
	let roleName = FindArray(Roles, role_new).name;
	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}
	//===Get data Contract with idContract
	const {
		ContractState: { Contract, Contracts, ContractsLoading },
		getContract_byid,
		showToast: { show, message, type },
		setShowToast
	} = useContext(ContractContext)
	// Start: Get  Contracts by ID
	useEffect(() => getContract_byid(params.id), [params.id])
	//===End Get data Contract with idContract
	// console.log("Contractsbyid::::::", Contract, Contracts)
	//console.log("adadadasdadasd::::::", download)
	//=== Get productcosts with idcontract
	const {
		ProductCostState: { ProductCost, ProductCosts, ProductCostsLoading },
		getProductCost_idContract,
		setShowAddProductCostModal
	} = useContext(ProductCostContext)
	// console.log(ProductCosts,'ProductCostsProductCostsProductCosts')
	// hàm tính tổng thành tiền
	function sumArray(mang) {
		let sum = 0;
		mang.map(function (value) {

			sum += value;
		});
		return sum;
	}
	// Start: Get ProductCosts by id Contract
	useEffect(() => getProductCost_idContract(params.id), [])

	//Ham checkbox Insurance
	const [stateInsurance, setStateInsurance] = useState(false)
	const toggleInsurance = (value) => {
		setStateInsurance(value);
	};
	//Ham tinh tong Phan tử trong kieu mang 
	function Sum_InputIntoMoney(ProductsCost) {
		let Total_InputIntoMoney = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_InputIntoMoney += ListProduct.InputIntoMoney
			)))

		return Total_InputIntoMoney;
	}
	//Ham tinh tong Phan tử trong kieu mang 
	function Sum_OutputIntoMoney(ProductsCost) {
		let Total_OutputIntoMoney = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_OutputIntoMoney += ListProduct.OutputIntoMoney
			)))

		return Total_OutputIntoMoney;
	}

	//Tinh tong thanh tien gia ban voi Insurance = true
	function Sum_Insurance(ProductsCost) {
		let Total_Insurance = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				ListProduct.Insurance === true ? Total_Insurance += ListProduct.OutputIntoMoney : 0
			)))

		return Total_Insurance;
	}

	function Sum_InsuranceHSE(ProductsCost) {
		let Total_InsuranceHSE = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				ListProduct.InsuranceHSE === true ? Total_InsuranceHSE += (ListProduct.OutputIntoMoney*((ListProduct.PercentHSE != null ? ListProduct.PercentHSE : 1) / 100)) : 0
			)))
		return Total_InsuranceHSE;
	}

	const TotalInsurance = 0.006 * Sum_Insurance(ProductCosts)
	const TotalInsuranceHSE = Sum_InsuranceHSE(ProductCosts)
	//console.log(ProductCosts,'------')

	const TotalInputIntoMoney = Sum_InputIntoMoney(ProductCosts)
	const TotalOutputIntoMoney = Sum_OutputIntoMoney(ProductCosts)

	let TotalIncentive = 0;
	if(ProductCosts[0] !== undefined){
	   TotalIncentive = sumArray(ProductCosts[0].ListProducts.map((ProductCost) => ProductCost.Incentive))//note
	}
	//console.log(TotalIncentive,'ProductCostsProductCostsProductCostsProductCosts')
	// 	const TotalIncentive = sumArray(ProductCosts.map((ProductCost) => ProductCost.Incentive))//note

	//=== End Get productcosts with idcontract

	//=== Get MandayCost 
	const {
		MandayCostState: { MandayCost, MandayCosts, MandayCostsLoading },
		getMandayCost_byidContract,
		setShowAddMandayCostModal
	} = useContext(MandayCostContext)
	// Start: Get all MandayCost by idContract
	useEffect(() => getMandayCost_byidContract(params.id), [])
	const TotalMandayCost = sumArray(MandayCosts.map((MandayCost) => typeof MandayCost.IntoMoney != "undefined" ? MandayCost.IntoMoney : 0));
	// const NoteMandayCost = MandayCosts.map((MandayCost) => MandayCost.Note)
	// console.log("TotalMandayCost===: ", TotalMandayCost)
	// console.log("NoteMandayCost===: ", NoteMandayCost)
	//=== End Get MandayCost

	//=== Get GuaranteeLetterCost
	const {
		GuaranteeLetterCostState: { GuaranteeLetterCost, GuaranteeLetterCosts, GuaranteeLetterCostsLoading },
		getGuaranteeLetterCost_byidContract,
		setShowAddGuaranteeLetterCostModal
	} = useContext(GuaranteeLetterCostContext)

	useEffect(() => getGuaranteeLetterCost_byidContract(params.id), [])

	const TotalGuaranteeLetterCost = sumArray(GuaranteeLetterCosts.map((GuaranteeLetterCost) => GuaranteeLetterCost.IntoMoney))
	//console.log("TotalGuaranteeLetterCost===: ", TotalGuaranteeLetterCost)
	//=== End Get GuaranteeLetterCost

	//=== Get MiscExpenseCosts
	const {
		MiscExpenseCostState: { MiscExpenseCost, MiscExpenseCosts, MiscExpenseCostsLoading },
		getMiscExpenseCost_byidContract,
		setShowAddMiscExpenseCostModal
	} = useContext(MiscExpenseCostContext)
	// Start: Get all MiscExpenseCosts
	useEffect(() => getMiscExpenseCost_byidContract(params.id), [])
	const TotalMiscExpenseCost = sumArray(MiscExpenseCosts.map((MiscExpenseCost) => MiscExpenseCost.Cost))
	//console.log("TotalMiscExpenseCost===: ", TotalMiscExpenseCost)
	//=== End Get MiscExpenseCosts

	//=== Get data CapitalExpenditureCost chi phi von
	const {
		CapitalExpenditureCostState: { CapitalExpenditureCost, CapitalExpenditureCosts, CapitalExpenditureCostsLoading },
		getCapitalExpenditureCosts_byidContract,
		getCapitalExpenditureCosts_byidContractNew
	} = useContext(CapitalExpenditureCostContext);

	// Start: Get all CapitalExpenditureCosts
	useEffect(() => getCapitalExpenditureCosts_byidContract(params.id), []);
	const TotalCapitalExpense = sumArray(CapitalExpenditureCosts.map((CapitalExpenditureCost) => CapitalExpenditureCost.CapitalExpense))//ch
	//=== End Get data CapitalExpenditureCost

	//=== Get data LoanInterest chi phi lãi vay
	const {
	    LoanInterestState: { LoanInterest, LoanInterests, LoanInterestsLoading },
	    getLoanInterest_byidContract
	} = useContext(LoanInterestContext);
	useEffect(() => getLoanInterest_byidContract(params.id), [])
	const TotalLoanInterest = sumArray(LoanInterests.map((LoanInterest ) => LoanInterest.InterestLoan))//ch
	//=== End Get data LoanInterest
	//console.log(CapitalExpenditureCosts,'TotalCapitalExpense')
	//console.log(TotalLoanInterest,'TotalLoanInterest')
	let chiPhi = 0;
	let namechiPhi = 0;
	if(TotalCapitalExpense != 0){
		chiPhi = TotalCapitalExpense;
		namechiPhi = 'Chi phí vốn';
	}else{
		if(TotalLoanInterest != 0){
			chiPhi = TotalLoanInterest;
			namechiPhi = 'Chi phí lãi vay';
		}
	}
	let htmlChiPhi = '';
	// console.debug("chiPhi", CapitalExpenditureCosts)
	if(chiPhi > 0){
		htmlChiPhi = 
		<tr>
			<td>1.2</td>
			<td colSpan={6} >{namechiPhi}</td>
			<td className='text-right'>{chiPhi.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
			<td></td>
			<td></td>
			<td></td>
			<td></td>
		</tr>;
	}
	//=== Get data AuxiliaryCost
	const {
		AuxiliaryCostState: { AuxiliaryCosts },
		getAuxiliaryCosts_byidContract
	} = useContext(AuxiliaryCostContext)
	// Start: Get AuxiliaryCosts by id Contract
	useEffect(() => getAuxiliaryCosts_byidContract(params.id), [])
	// hàm đổi giá trị chi phí % => giá trị tiền tuyệt đối
	function changeCost(Cost, TotalOutputIntoMoney) {
		let returnValue = Cost;
		if (Cost < 1)
			returnValue = Cost * TotalOutputIntoMoney;
		return returnValue
	}

	function SumListCost(Auxiliary, TotalOutputIntoMoney) {
		let kq = 0;
		Auxiliary.map(AuxiliaryCost =>
			AuxiliaryCost.ListCosts.map(ListCost => (
				kq += changeCost(ListCost.Cost, TotalOutputIntoMoney)
			)))

		return kq;
	}
	function FindPlan(Auxiliary) {
		let returnPlan = 0;
		Auxiliary.map(AuxiliaryCost => returnPlan = AuxiliaryCost.Plan
		)
		return returnPlan;
	}

	function convert_time(date_time){
		const date = new Date(date_time); // Replace this with your actual date object
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		const seconds = ('0' + date.getSeconds()).slice(-2);
		const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		return formattedDate;
	}

	const TotalCost = SumListCost(AuxiliaryCosts, TotalOutputIntoMoney);

	let CPXL = 0;
	let CPgross = 0;
	const Auxiliary_Plan = FindPlan(AuxiliaryCosts);
	if (Auxiliary_Plan === 1) {
		CPXL = TotalCost / 0.8 * 0.2;
		CPgross = CPXL + TotalCost;
	}
	else {
		CPXL = TotalCost / 0.7 * 0.25;
		CPgross = CPXL + TotalCost;
	}
	///console.log("CPgross=== : ", CPgross)
	const rsTotalAuxiliaryCost = totalAuxiliaryCost(AuxiliaryCosts, TotalOutputIntoMoney)
	// console.log("rsTotalAuxiliaryCost=== : ", AuxiliaryCosts)
	/// chi phi vat tu phu
	function totalAuxiliaryCost(AuxiliaryCosts, TotalOutputIntoMoney){
		let sumCostTaxA = 0;
		let sumCostTaxB = 0;
		
		let sumCostA = 0;
		let sumCostB = 0;
		
		let sumTaxA = 0;
		let sumTaxB = 0;
		
		AuxiliaryCosts.map(AuxiliaryCost => (
			AuxiliaryCost.ListCosts.map(function(ListCost, index) {
				var newCost = 0
				var newTax = 0
				var newCostTax = 0
				if (AuxiliaryCost.Plan == 1){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost * ListCost.TaxRate)/100).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}else if (AuxiliaryCost.Plan == 2){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost / (100 - ListCost.TaxRate)) * ListCost.TaxRate).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostA += parseInt(newCost);
				}else if (AuxiliaryCost.Plan == 2){
					sumCostB += parseInt(newCost);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumTaxA += parseInt(newTax);
				}else if (AuxiliaryCost.Plan == 2){
					sumTaxB += parseInt(newTax);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostTaxA += parseInt((newCost+newTax));
				}else if (AuxiliaryCost.Plan == 2){
					sumCostTaxB += parseInt((newCost+newTax));
				}
			})
		))
		let muc2_1 = parseInt((sumCostA + sumCostB)/10);
		let muc2_thue = parseInt((sumTaxA + sumTaxB)/10);
		let muc2 = parseInt(muc2_1 + muc2_thue);
		return {muc2, muc2_1, muc2_thue}
	}
	//=== End Get data AuxiliaryCost

	//=== Get data ImplementationCost
	const {
		ImplementationCostState: { ImplementationCosts },

		//17-3
		getImplementation_Costs_byidContract

	} = useContext(ImplementationCostContext)
	// 17-3
	useEffect(() => getImplementation_Costs_byidContract(params.id), [])
	//console.log("ImplementationCosts:::", ImplementationCosts)
	// Ham tinh tong tat ca giai doan trien khai
	function TotalStageImplementation(ImplementationCosts) {
		let All_Total = 0;
		ImplementationCosts.map((element) => (
			element.StagesImplementation.map((object) => (
				object.Costs.map((Costs) =>
					All_Total += Costs.IntoMoney
				)))))
		return All_Total;
	}
	const ImplementationCost = TotalStageImplementation(ImplementationCosts);
	// 1.1 Chi phí triển khai hợp đồng + Chi phi Bảo hiểm
	const TotalImplementationCost = ImplementationCost + TotalInsurance + TotalInsuranceHSE;
	//console.log(ImplementationCost,'ImplementationCost')
	//console.log(TotalInsurance,'TotalInsurance')
	//console.log(TotalInsuranceHSE,'TotalInsuranceHSE')

	///
	// function showListCosts(_ImplementationCosts) {
	// 	let sss =null
	// 	sss = _ImplementationCosts.map(function (_ImplementationCost) {
	// 		_ImplementationCost.StagesImplementation.map(function (_StagesImplementation) {
	// 			return (
	// 				_StagesImplementation.Costs.map(function (_Costs) {
	// 					<>
	// 						<tr>
	// 							<td colSpan="2">1</td>
	// 							<td colSpan="5">2</td>
	// 							<td>3</td>
	// 							<td></td>
	// 						</tr>
	// 					</>
	// 				})
	// 			)
	// 		})
	// 	})
	// 	return sss;
	// }
	//=== End Get data ImplementationCost


	//=== Data process
	// 1. chi phi phat sinh khi thuc hien du an
	const ExtraCost = (TotalImplementationCost + chiPhi + TotalMandayCost + TotalGuaranteeLetterCost + TotalMiscExpenseCost)
	// console.log("ExtraCost", TotalImplementationCost , chiPhi , TotalMandayCost , TotalGuaranteeLetterCost , TotalMiscExpenseCost)
	// 2.
	// console.log("AuxiliaryCosts::::::", AuxiliaryCosts)
	// console.log("TongCPAuxiliaryCosts::::::", TotalCostTaxAuxiliaryCosts(AuxiliaryCosts), TotalCostAuxiliaryCosts(AuxiliaryCosts), TotalTaxAuxiliaryCosts(AuxiliaryCosts))

	// function TotalCostTaxAuxiliaryCosts(AuxiliaryCosts) {
	// 	let All_Total = 0;
	// 	AuxiliaryCosts.map((element) => {
	// 		element.ListCosts.map((Costs) => {
	// 			All_Total += Costs.CostTax
	// 		})
	// 	})
	// 	return All_Total;
	// }

	function TotalCostAuxiliaryCosts(AuxiliaryCosts) {
		let All_Total = 0;
		AuxiliaryCosts.map((element) => {
			element.ListCosts.map((Costs) => {
				All_Total += Costs.Cost
			})
		})
		return All_Total;
	}

	function TotalTaxAuxiliaryCosts(AuxiliaryCosts) {
		let All_Total = 0;
		AuxiliaryCosts.map((element) => {
			element.ListCosts.map((Costs) => {
				All_Total += Costs.Tax
			})
		})
		return All_Total;
	}
	// 3.
	const hieuquaduan = (TotalOutputIntoMoney - TotalInputIntoMoney - ExtraCost - (rsTotalAuxiliaryCost.muc2*10))
	// console.log(TotalOutputIntoMoney,TotalInputIntoMoney,ExtraCost,(rsTotalAuxiliaryCost.muc2*10),hieuquaduan,'===============================================')

	// 0704 getUser
	const {
		UserState: { User, Users, UsersLoading },
		getUsers
	} = useContext(UserContext)

	// 07-04
	function findName(UserId) {
		return Users.length > 0 ? Users.find(User => User._id === UserId).username: UserId;
	}


	const {
		DepartmentState: { Department, Departments, DepartmentsLoading },
		getDepartment
	} = useContext(ConfigDepartmentContext)

	const {
		CustomerState: { Customer, Customers, CustomersLoading },
		get_All_Customer
	} = useContext(ConfigCustomerContext)

	const {
		CenterState: { Centers, CentersLoading },
		get_All_Center
	} = useContext(ConfigCenterContext)

	useEffect(() => getDepartment(), [])
	useEffect(() => get_All_Customer(), [])
	useEffect(() => get_All_Center(), [])
	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}
	///////////////////////
	const {
		HistoryState: { History, Historys, HistorysLoading },
		getHistory
	} = useContext(HistoryContext)
	useEffect(() => getHistory(), [])


	///UserManagers
	const {
		UserManagerState: { UserManager, UserManagers, UserManagersLoading },
		getAllUserManagers
	} = useContext(UserManagerContext)

	const {
		historyChangeState: { historyChange, historyChanges , historyChangesLoading},
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	useEffect(() => getAllUserManagers(), [])

	function get_status(array,ContractID){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return {StatusID: 0, Reason: ""};
		}
	}
	function check_display_button_by_role(ContractID,roleName,Historys,Users,Contract){
		let StatusID = get_status(Historys,ContractID).StatusID;
		let nameUserContract = FindArrayNameUserContract(Users,Contract[0].user);
		switch (roleName){
			case 'AM':
				if(StatusID != -1 && StatusID != 1){
					return 0; 
				}else{
					return 1; 
				}
			break;
			case 'TPKD':
				if( StatusID != 3 && StatusID != -2){
					return 0;
				}else{
					return 1;
				}
			break;
			case 'KTT':
				if( StatusID != 5 && StatusID != -4){
					return 0;
				}else{
					return 1;
				}
			case 'BGD':
				// if( StatusID != 7 && StatusID != -6){
				// 	return 0;
				// }else{
				// 	return 1;
				// }
				if( StatusID == 1 || StatusID == -6){
					if(roleName == nameUserContract){
						return 1;
					}
				}
				return 0;
			case 'BTGD':
				// if( StatusID != 9){
				// 	return 0;
				// }else{
				// 	return 1;
				// }
				if( StatusID == 1){
					if(roleName == nameUserContract){
						return 1;
					}
				}
				return 0;
			break;
		}
	}

	function FindArrayNameUserContract(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID._id);
		if(returnObject != undefined){
			return returnObject.role_details[0].name;
		}else{
			return '';
		}
	}

	///////////////////////
	let body = null
	let stt = 1
	let TT = 1
	
	if (ContractsLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	} else if (Contracts.length === 0) {
		// return (
		// 	<>
		// 		<Card className='text-left mx-5 my-5'>
		// 		<Card.Header as='h2' style={{textAlign: "center"}}>BẢNG PHÂN TÍCH HIỆU QUẢ HỢP ĐỒNG - DỰ ÁN </Card.Header>
		// 		<Card.Body>
		// 			<Table striped bordered hover size="sm">
		// 				<thead >
		// 					<tr>
		// 						<th>Số hợp đồng/PO: </th>
		// 					</tr>
		// 					<tr>
		// 						<th>Trung tâm: </th>
		// 					</tr>
		// 					<tr>
		// 						<th>Phòng: </th>
		// 					</tr>
		// 					<tr>
		// 						<th>Tên AM: </th>
		// 					</tr>
		// 					<tr>
		// 						<th>Khách hàng: </th>
		// 					</tr>
		// 					<tr>
		// 						<th>Ngày: </th>
		// 					</tr>
		// 				</thead>
		// 			</Table>
		// 		</Card.Body>
		// 		</Card>
		// 	</>
		// )
		return (
			<>
				<div id="notfound">
					<div className="notfound">
						<h1>Oops!</h1>
						<h6>Rất tiếc, Hợp đồng không được tìm thấy trong hệ thống. Vui lòng kiểm tra lại.
							<br/>
							<Link style={{textDecoration: "none"}} to="/home">
								Trở về trang chủ
							</Link>
						</h6>
					</div>
				</div>
			</>
		)
	} else {
		let newContracts = Contracts.filter((Contract) => Contract._id == params.id);
		let nameUser = ""
		newContracts.map(Contract => {
			nameUser = Contract.user.name != undefined ? Contract.user.name: Contract.user_details[0].name
		})

		console.debug("newContracts", newContracts)
		/////
		let rows;
		let newContract;
		let newContract111 = [];
		if (roleName == "Admin" || roleName == "BTGD") {
		    newContract = Contracts;
		} else if (roleName == "KTT" || roleName == "BGD") {
		    let centerOfUser = filter_Users(Users, UserID).centers;
		    // console.log(centerOfUser,'====1111===')
		    if (centerOfUser != undefined) {
		        if (Contracts.length > 0) {
		            newContract111 = filter_new_contract(Contracts, centerOfUser);
		        }
		    }
		    newContract = newContract111;
		} else {
		    console.debug(UserManagers,UserID,roleName,get_user_id(UserID,UserManagers),'sdsd')
		    let newContract1 = Contracts.filter((v) => get_user_id(UserID, UserManagers).includes(v.user._id));
		    if (roleName == "AM") {
		        newContract = newContract1;
		    } else {
		        let contract_me = Contracts.filter((v) => v.user._id == UserID);
		        let newContract2 = newContract1.concat(contract_me);
		        newContract = newContract2;
		    }
		}

				function get_status(array,ContractID){
			let returnObject = "";
			if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
			let lastElement = returnObject.pop();
			if(lastElement != undefined){
				return lastElement;
			}else{
				return {StatusID: 0, Reason: ""};
			}
		}

		function checkUsersAccessContact(history, roleName, UserID, statusID, filteredHistoryChangesCapTrenEdit, userContract, usersarray, rolearray) {
			let userContractAccess = (typeof userContract[0].user != "undefined" && typeof userContract[0].user != "string") ? userContract[0].user.roles[0] : (typeof userContract[0].user_details != "undefined" && typeof userContract[0].user_details == "object") ? userContract[0].user_details[0].roles[0] : ""
			let nameUserContract = FindArrayTPKD(usersarray, userContract[0].user, rolearray);
			let roleUserContract = userContractAccess
			let nameRoleContract = FindArrayRoleBTGD(Roles,roleUserContract).name;

			let access = false
			let historyContract = '';
			if(filteredHistoryChangesCapTrenEdit != null){
				if(filteredHistoryChangesCapTrenEdit.length > 0){
					historyContract = filteredHistoryChangesCapTrenEdit.filter(x => x.contract === params._id && x.form != 'contract');
				}
			}

			// console.debug(111, usersarray, userContract, rolearray)
			// usersarray.map(x=> console.debug(UserID, x.user))
			// console.debug(111, {roleName, statusID, nameUserContract})
			// console.debug(1111, history, roleName, UserID, statusID, filteredHistoryChangesCapTrenEdit, userContract, usersarray, rolearray)

			switch(roleName){
				case 'AM':			
					switch(statusID){
						case "1":
						case "-1":
							access = false
							break;

						case "2": 
							access = false
							break;

						case "3":
						case "5":
						case "7":
						case "-2":
						case "-4":
						case "-6":
						case "6":
						case "8":
						case "9":
						case "4":
							if (historyContract != "") {
								access = true
							}else {
								access = false
							}
							break;
						case "10": 
							access = true
							break;
					}
					break;
				case 'TPKD':
					switch(statusID){
						case "1":
							if (roleName == nameUserContract) {
								access = false
							}else {
								access = false //update 03062024
							}
							break;
						case "2":
							access = false // access = true
							break;
						case "-2":
							access = false
							break;
						case "5":
						case "7":
						case "-4":
						case "-6":
						case "6":
						case "8":
						case "9":
							if(historyContract != ''){
								access = true
							}else{
								access = false
							}
							break;
						case "10":
							access = true
							break;
						case "3":
							access = false
							break;
						case "-1":
						case "4": 
							access = false //update 03062024
							break;
					}
					break;
				case 'KTT':
					switch(statusID){
						case "4": 
							access = false //access = true
							break;
						case "1": 
							// if (roleName == nameUserContract) {
							// 	access = false
							// }else {
							// 	if(nameRoleContract == "BTGD"){
							// 		access = false
							// 	}else{
							// 		access = false //access = true
							// 	}
							// }
							access = false
							break;
						case "5":
							access = false
							break;
						case "7":
						case "9":
							if (historyContract != "") {
								access = true // false if page Render_Button_Invoice
							}else {
								access = false //access = true // false if page Render_Button_Invoice
							}
							break;
						case "-4":
						case "-6":
						case "8":
							if (historyContract != "") {
								access = true
							}else {
								if(statusID == -4){
									access = false
								}else if(statusID == -6){
									access = false //access = true
								}else{
									access = false // access = true // false if page Render_Button_Invoice
								}
							}
							break;
						case "-1":
						case "2":
						case "3":
							access = false //access = true
							break;
						case "10":
							access = true
							break;
						case "-2":
						case "6":
						case "7":
							access = false ///access = true
							break;
						}
					break;
				case 'BGD':
					switch(statusID){
						case "-4":
						case "6":
							access = false //access = true
							break;
						case "1": 
							// if (roleName == nameUserContract) {
							// 	access = false
							// }else {
							// 	access = true
							// }
							access = false
							break;
						case "7":
							access = false
							break;
						case "8":
							access = false//access = true
							break;
						case "-1":
						case "2":
						case "-2":
						case "5":
						case "3":
						case "4":
							access = false//access = true
							break;
						case "10":
							access = true
							break;
						case "-6":
							access = false
							break;
						case "9":
							if(historyContract != ''){
									access = true
								}else{
									access = false
								}
							break;
						default: 
							if (statusID < 9) {
								access = false
							}else {
								access = true
							}
							break;
					}
					break;
				case 'BTGD':
				case 'Admin':
					switch(statusID){
						case "1": 
							// if (roleName == nameUserContract) {
							// 	access = false
							// }else {
							// 	access = true
							// }
							access = false
							break;
						case "10":
							access = true
							break;
						default: 
							access = false ///access = true
							break;
					}
					break;
			}

			console.debug(roleName, "checkUsersAccessContact:::return access:::", access, historyContract)
			return access
		}

		function FindArrayTPKD(array, ID, Roles) {
			let returnObject = "";
			if (ID != undefined) returnObject = array.find(x => x._id === ID._id);
			if(returnObject != undefined){
				return returnObject.role_details[0].name;
			}else{
				return '';
			}
		}

		function FindArrayRoleBTGD(array, ID) {
			let returnObject = "";
			if (ID != undefined) returnObject = array.find(x => x._id === ID);
			if(returnObject != undefined){
				return returnObject;
			}else{
				return '';
			}
		}

		function filter_new_contract(array, UserID) {
		    let returnObject = "";
		    // if (UserID != undefined) returnObject = array.filter((element) => element.user.centers != null ? element.user.centers[0] : '' == UserID[0]);
		    if (UserID != undefined) returnObject = array.filter((element) => element.CenterID == UserID[0]);
		    if (returnObject != undefined) {
		        return returnObject;
		    } else {
		        return "";
		    }
		}

		function filter_Users(array, UserID) {
		    let returnObject = "";
		    if (UserID != undefined) returnObject = array.filter((element) => element._id === UserID);
		    let lastElement = returnObject.pop();
		    if (lastElement != undefined) {
		        return lastElement;
		    } else {
		        return "";
		    }
		}

		function filter_history(array, ContractID) {
		    let returnObject = "";
		    if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		    let lastElement = returnObject.pop();
		    if (lastElement != undefined) {
		        return lastElement;
		    } else {
		        return "";
		    }
		}

		function get_user_id(userLogin, userManager) {
		    let managedUsers = [];

		    function findManagedUsers(managerId) {
		        let users = userManager.filter((item) => item.manager_id[0] === managerId);
		        if (users.length > 0) {
		            for (let user of users) {
		                findManagedUsers(user.user_id[0]);
		            }
		        } else {
		            managedUsers.push(managerId);
		        }
		    }

		    findManagedUsers(userLogin);
		    return managedUsers;
		}

		function FindArrayUserManagers(array, ID) {
			let returnObject = "";
			if (ID != undefined) returnObject = array.filter(x => x.user_id[0] === ID);
			if(returnObject != undefined){
				return returnObject;
			}else{
				return '';
			}
		}

		function FindArrayUserCapCaoHon(UserID , array_user, roleName) {
			let returnObject = array_user.find(x => x._id == UserID);
			let rs = []
			let filteredArray = [];

			switch(roleName){
				case 'AM':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
						  	}
						});
						let manage_UserId = FindArrayUserManagers(UserManagers,UserID);
						if(manage_UserId.length > 0){
							let manager_id_new = manage_UserId[0]['manager_id'];
							let managerObject = array_user.find(x => x._id == manager_id_new);
							if(managerObject != undefined){
								filteredArray.push(managerObject);
							}
						}
					}
					break;
				case 'TPKD':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
						  	}else{
						  		return item.role_details[0].name == 'BTGD'
						  	}
						});
					}
					// console.log('2')
					break;
				case 'KTT':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD' && item.role_details[0].name != 'KTT';
						  	} else {
						  		return item.role_details[0].name == 'BTGD'
						  	}
						});
					}
					break;
				case 'BGD':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
						  	return item.role_details[0].name == 'BTGD';
						});
					}
					// console.log('3')
					break;
				case 'BTGD':
				case 'Admin':
					filteredArray = array_user
					break;
			}

			if (filteredArray.length > 0) {
				return filteredArray.map(item => item._id);
			}

			return filteredArray
		}

		const contract_me_access = FindArray(newContract, params.id)
		const contract_me_new_access = FindArray(Contracts, params.id)
		// console.debug("newContract", roleName, newContract, newContracts, ContractsLoading)
		// console.debug("contract_me_access", contract_me_access == "", get_user_id(UserID,UserManagers))
		// console.debug("contract_me_new_access", contract_me_new_access == "", get_user_id(UserID,UserManagers))

		//loc cha, loc con
		let user_access = get_user_id(UserID,UserManagers);
		if (roleName != "AM") user_access.push(UserID)
		const userNew = (_id == "65676f9ffaea227259381017") ? Users : findUsers(Users, roleName, UserID); //  || 63c0938fed60f11521b00875
		// console.debug("user_access", user_access, userNew)
		const uniqueUserNew = (userNew.length > 0 ) ? [...new Set(userNew.map(obj => obj._id))] : []
		let newUserManagers = []
		if (roleName == "AM" || roleName == "TPKD") {
			newUserManagers = user_access
		}else if (roleName == "KTT" || roleName == "BGD") {
			if (roleName == "KTT") {
				let listReceiverCaoHon = FindArrayUserCapCaoHon(UserID, Users, roleName)
				let newUserManagers1 = (user_access.length > 0 ) ? user_access.concat(uniqueUserNew) : (uniqueUserNew.length > 0 ) ? user_access.concat(uniqueUserNew) : []
				newUserManagers = (newUserManagers1.length > 0 ) ? newUserManagers1.concat(listReceiverCaoHon) : (listReceiverCaoHon.length > 0 ) ? listReceiverCaoHon.concat(newUserManagers1) : []
				// console.debug(1111111, newUserManagers) //  || 63c0938fed60f11521b00875)
			}else {
				newUserManagers = (user_access.length > 0 ) ? user_access.concat(uniqueUserNew) : (uniqueUserNew.length > 0 ) ? user_access.concat(uniqueUserNew) : []
			}
		}else if (roleName == "Admin" || roleName == "BTGD") {
			newUserManagers = uniqueUserNew
		}
		// userNew.map(x => { console.debug(x.centers, x.departments) }) 
				//////// new update 2024-03-16
		// console.debug("newUserManagers:::", user_access, Users.filter(x => x._id == user_access[0]))
		let filteredHistoryChangesCapTrenEdit = historyChanges.filter(x => x.contract == params.id && x.form != 'contract' && x.requester != UserID && x.requester.includes(user_access))
		let StatusID = get_status(Historys,params.id) != '' ? get_status(Historys,params.id).StatusID : '0'
		let userContact = Contracts.filter(x => x._id == params.id)
		let kiemTraChoPhepTruyCap = checkUsersAccessContact(Historys, roleName, UserID, StatusID, filteredHistoryChangesCapTrenEdit, userContact, Users, Roles)
		console.debug("123", kiemTraChoPhepTruyCap)
		function findUsers(array, roleName, idUser) {
			let usersBelongCenter = []
			let filterListUsers = FindArray(array, _id)
			switch (roleName) {
				case "Admin":
				case "BTGD":
					if (filterListUsers != undefined) {
						usersBelongCenter = array
					}
					break;
					// if (filterListUsers != undefined) {
					// 	usersBelongCenter = filter_new_user(array, null, null, idUser, ["BGD", "KTT", "TPKD", "AM"])
					// }
					// break;
				case "BGD":
					if (filterListUsers != undefined) {
						usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["KTT", "TPKD", "AM"])
					}
					break;
				case "KTT":
					if (filterListUsers != undefined) {
						usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["TPKD", "AM"])
					}
					break;
				case "TPKD":
					if (filterListUsers != undefined) {
						usersBelongCenter = filter_new_user(array, filterListUsers.centers, filterListUsers.departments, idUser, ["AM"])
					}
					break;
			}
			// console.log("usersBelongCenter", usersBelongCenter)
			return usersBelongCenter
		}

		function filter_new_user(array, centers_id, department_id = null, idUser, listRole = null){
			let returnObject = [];
			if (centers_id !== null) {
				array.map(element => {
					if (element.centers) {
						if (element.centers[0] === centers_id[0] && element._id !== idUser && listRole.includes(element.role_details[0].name)) {
							returnObject.push(element)
						}
					}
				})
			}else {
				array.map(element => {
					if (element.centers) {
						if (element._id !== idUser) {
							returnObject.push(element)
						}
					}
				})

			}
			return returnObject
		}

		///
		let check_access = contract_me_access == ""
		let check_access_new = contract_me_new_access == ""
		// console.debug(0, typeof contract_me_access, contract_me_access, typeof contract_me_new_access, contract_me_new_access)
		if (kiemTraChoPhepTruyCap) {
			return (
				<>
					<div id="notfound">
						<div className="notfound">
							<h1>Oops!</h1>
							<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng kiểm tra lại thông tin.
								<br/>
								<Link style={{textDecoration: "none"}} to="/home">
									Trở về trang chủ
								</Link>
							</h6>
						</div>
					</div>
				</>
			)
		}else {
			if ((check_access == true && check_access_new  == false) || (check_access == false && check_access_new  == true) || (check_access == false && check_access_new  == false)) {
				// console.debug(1, typeof contract_me_new_access.user, contract_me_new_access)
				if (typeof contract_me_new_access.user == "string") {
					// console.debug("1 thu gian: cho phep truy cap")
					if (newUserManagers.includes(contract_me_new_access.user) == true) {
						// console.debug("11 thu gian:: truy cap duoc", contract_me_new_access.user)
						return (
							<>
								<Card className='text-left mx-5 my-5'>
									<Card.Header as='h2' style={{textAlign: "center"}}>BẢNG PHÂN TÍCH HIỆU QUẢ HỢP ĐỒNG - DỰ ÁN </Card.Header>
									<Card.Body>
										<Table striped bordered hover size="sm">
											{
													newContracts.map(Contract => (
													<>
														<thead key={Contract._id} >
															<tr>
																<th>Số hợp đồng/PO: {Contract.ContractID}</th>
															</tr>
															<tr>
																<th>Trung tâm: {FindArray(Centers, Contract.CenterID).CenterName} </th>
															</tr>
															<tr>
																<th>Phòng: {FindArray(Departments, Contract.DepartmentID).DepartmentName} </th>
															</tr>
															<tr>
																<th>Tên AM: {nameUser}</th>
															</tr>
															<tr>
																<th>Khách hàng: {FindArray(Customers, Contract.CustomerID).CustomerName}</th>
															</tr>
															<tr>
																<th>Ngày: {convert_time(Contract.Date)}</th>
															</tr>
														</thead>
													</>
												))
											}
										</Table>


										<Table responsive="sm" striped bordered hover size="sm" >
											{ProductCosts.map(ProductCost => (
												<>
													<thead className='text-center'>
														<tr key={ProductCost._id} style={{textAlign: 'center'}}>
															<th rowSpan="2" style={{verticalAlign:'inherit'}}>STT</th>
															<th rowSpan="2" style={{verticalAlign:'inherit'}} width="15%">Tên hàng</th>
															<th rowSpan="2" style={{verticalAlign:'inherit'}} width="5%">Số lượng</th>
															<th colSpan="3">Giá vốn hàng bán Giá kho</th>
															<th colSpan="2 ">Doanh số Giá bán</th>
															<th rowSpan="2 " style={{verticalAlign:'inherit'}} width="8%">Có tính Chi Phí Bảo Hành kg</th>
															<th rowSpan="2 " style={{verticalAlign:'inherit'}} width="8%">Chi phí hỗ trợ kĩ thuật HSE</th>
															<th rowSpan="2 " style={{verticalAlign:'inherit'}} width="8%">Mua nội bộ</th>
															<th rowSpan="2 " style={{verticalAlign:'inherit'}} width="8%">Ghi chú</th>
														</tr>
														<tr style={{textAlign: 'center'}}>
															<th width='8%' as='pre'>Đơn giá FOB <br />
																(EX-W)</th>
															<th style={{verticalAlign:'inherit'}}>Đơn giá kho</th>
															<th style={{verticalAlign:'inherit'}}>Thành tiền giá kho</th>
															<th style={{verticalAlign:'inherit'}}>Đơn giá bán</th>
															<th style={{verticalAlign:'inherit'}}>Thành tiền giá bán</th>
														</tr>
													</thead>
													<tbody className='text-right'>
														{ProductCost.ListProducts.map(ListProduct => (
															<tr key={ListProduct._id} >
																<td>{stt++}  </td>
																<td>{ListProduct.ProductName}</td>
																<td>{ListProduct.Quantity.toLocaleString()}</td>
																<td>{ListProduct.FOBCost != null ? ListProduct.FOBCost.toLocaleString() : ""}</td>
																<td>{ListProduct.InputPrice != null ? ListProduct.InputPrice.toLocaleString() : ""}</td>
																<td>{ListProduct.InputIntoMoney != null ? ListProduct.InputIntoMoney.toLocaleString() : ""}</td>
																<td>{ListProduct.OutputPrice != null ? ListProduct.OutputPrice.toLocaleString() : ""}</td>
																<td>{ListProduct.OutputIntoMoney != null ? ListProduct.OutputIntoMoney.toLocaleString() : ""}</td>
																{/*<td>{ListProduct.Insurance}
																	<input
																		type='checkbox'
																		checked={ListProduct.Insurance}
																		onChange={(e) => toggleInsurance((e).target.checked)}
																	/>
																</td>*/}
																<td>{ListProduct.Insurance == true ? (<img src={download}  width="20" height="20"></img>) : ''}</td>
																<td>{ListProduct.InsuranceHSE == true ? (ListProduct.OutputIntoMoney * ((ListProduct.PercentHSE != null ? ListProduct.PercentHSE : 1) / 100)).toLocaleString() : '0'}</td>
																<td>{ListProduct.InternalPurchase == true ? (<img src={download}  width="20" height="20"></img>) : ''}</td>
																<td>{ListProduct.Note}</td>
															</tr>

														))
														}
														<tr>
															<td colSpan={5} >Tổng</td>
															<td>{Sum_InputIntoMoney(ProductCosts).toLocaleString()}</td>
															<td></td>
															<td>{Sum_OutputIntoMoney(ProductCosts).toLocaleString()}</td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
														</tr>
														{/* <tr>
															<td colSpan={7} > Incentive (nếu có)</td>
															<td>{ProductCost.Incentive == null ? 0 : ProductCost.Incentive.toLocaleString()}</td>
															<td></td>
														</tr> */}
													</tbody>
												</>
											))}
											<tr>
												<td>1</td>
												<td colSpan={6} >Chi phí phát sinh khi thực hiện Dự Án </td>
												<td className='text-right'>{Math.round(ExtraCost).toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr >
												<td>1.1</td>
												<td colSpan={6} >Chi phí triển khai hợp đồng </td>
												<td className='text-right'>{TotalImplementationCost.toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td colSpan={2} ></td>
												<td colSpan={5} >+ Chi phí bảo hành (phần cứng trích cho TT HSC 0.6%)</td>
												<td className='text-right'>{TotalInsurance.toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td colSpan={2} ></td>
												<td colSpan={5} >+ Chi phí hỗ trợ kĩ thuật HSE (1%)</td>
												<td className='text-right'>{TotalInsuranceHSE.toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											
											{
												ImplementationCosts.map(element => (
													element.StagesImplementation.map(stages => (
														stages.Costs.map(costs => (
															<>
																<tr>
																	<td colSpan={2}></td>
																	<td colSpan={5}>
																		+ {costs.NameCost}
																	</td>
																	<td className='text-right'>
																		{costs.IntoMoney.toLocaleString()}
																	</td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td>{costs.Note}</td>
																</tr>
															</>
														))
													))
												))
											}

											{
												CapitalExpenditureCosts.map((CapitalExpenditureCost) => (
													<>
														{/*<tr key={CapitalExpenditureCost._id}>
															<td>1.2</td>
															<td colSpan={6} > Chi phí vốn</td>
															<td className='text-right'>{Math.round(CapitalExpenditureCost.CapitalExpense).toLocaleString()}</td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>

														</tr>*/}
														{/*<tr>
															<td colSpan={2} ></td>
															<td colSpan={5} >+ Số ngày hàng tồn kho</td>
															<td className='text-right'>{Math.round(CapitalExpenditureCost.InventoryDays).toLocaleString()}</td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<td colSpan={2} ></td>
															<td colSpan={5} >+ Số ngày triển khai</td>
															<td className='text-right'>{Math.round(CapitalExpenditureCost.ImplementationDays).toLocaleString()}</td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<td colSpan={2} ></td>
															<td colSpan={5} >+ Số ngày công nợ nhà cung cấp</td>
															<td className='text-right'>{Math.round(CapitalExpenditureCost.BedtDays).toLocaleString()}</td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<td colSpan={2} ></td>
															<td colSpan={5} > + Số ngày thu nợ </td>
															<td className='text-right'>{Math.round(CapitalExpenditureCost.DebtCollectionDays).toLocaleString()}</td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<td colSpan={2} ></td>
															<td colSpan={5} >+ Khách hàng trả trước (đặt cọc)</td>
															<td className='text-right'>{Math.round(CapitalExpenditureCost.Deposits).toLocaleString()}</td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<td colSpan={2} ></td>
															<td colSpan={5} >+ Đặt cọc cho NTP </td>
															<td className='text-right'>{Math.round(CapitalExpenditureCost.DepositsNTP).toLocaleString()}</td>
															<td></td>
															<td></td>
														</tr>*/}
													</>
												))
											}
											{htmlChiPhi}
											< tr >
												<td>1.3</td>
												<td colSpan={6} >Manday thực hiện của kỹ sư HPT tham gia</td>
												<td className='text-right'>{Math.round(TotalMandayCost).toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr >
												<td>1.4</td>
												<td colSpan={6} >Chi phí làm thư bảo lãnh (BL dự thầu, BL thực hiện HĐ, BL BH)</td>
												<td className='text-right'>{Math.round(TotalGuaranteeLetterCost).toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr >
												<td>1.5</td>
												<td colSpan={6} > Chi phí khác</td>
												<td className='text-right'>{Math.round(TotalMiscExpenseCost).toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td>2</td>
												<td colSpan={6} >Chi phí mua vật tư phụ</td>
												<td className='text-right'>{rsTotalAuxiliaryCost.muc2.toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td>2.1</td>
												<td colSpan={6} >Chi phí mua vật tư phụ</td>
												<td className='text-right'>{rsTotalAuxiliaryCost.muc2_1.toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td>2.2</td>
												<td colSpan={6} >Thuế</td>
												<td className='text-right'>{rsTotalAuxiliaryCost.muc2_thue.toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td>3</td>
												<td colSpan={6} >Hiệu quả dự án (giá trị tuyệt đối)</td>
												<td className='text-right'>{hieuquaduan === null ? 0 : Math.round(hieuquaduan).toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td>4</td>
												<td colSpan={6} >Hiệu quả dự án (tỉ lệ trên doanh thu)</td>
												<td className='text-right'>{ !isNaN((hieuquaduan / TotalOutputIntoMoney)) && isFinite((hieuquaduan / TotalOutputIntoMoney)) ? ((hieuquaduan / TotalOutputIntoMoney) * 100).toFixed(2) + "%" : "0%"}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td></td>
												<td colSpan={6} >Hiệu quả dự án (chưa trừ  Manday của kỹ sư HPT)</td>
												<td className='text-right'>{Math.round((hieuquaduan + TotalMandayCost)).toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td></td>
												<td colSpan={6} >Hiệu quả dự án (tỉ lệ doanh thu) - chưa trừ  Manday của kỹ sư HPT</td>
												<td className='text-right'>{ !isNaN((hieuquaduan + TotalMandayCost) / TotalOutputIntoMoney) && isFinite((hieuquaduan + TotalMandayCost) / TotalOutputIntoMoney)? (((hieuquaduan + TotalMandayCost) / TotalOutputIntoMoney) * 100).toFixed(2) + "%" : "0%"}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td></td>
												<td colSpan={6} >Incentive :</td>
												<td className='text-right'>{TotalIncentive.toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td></td>
												<td colSpan={6} >Hiệu quả dự án (có Incentive):</td>
												{/*<td className='text-right'>{ !isNaN(((hieuquaduan + TotalIncentive + TotalMandayCost) / TotalOutputIntoMoney)) && isFinite(((hieuquaduan + TotalIncentive + TotalMandayCost) / TotalOutputIntoMoney)) ? (((hieuquaduan + TotalIncentive + TotalMandayCost) / TotalOutputIntoMoney) * 100).toFixed(2) + "%" : "0%"}</td>*/}
												<td className='text-right'>{ !isNaN(((hieuquaduan + TotalIncentive) / TotalOutputIntoMoney)) && isFinite(((hieuquaduan + TotalIncentive) / TotalOutputIntoMoney)) ? (((hieuquaduan + TotalIncentive) / TotalOutputIntoMoney) * 100).toFixed(2) + "%" : "0%"}</td>

												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr className='text-center'>
												<td colSpan={8}>
													<OverlayTrigger
														overlay={
															<Tooltip id="tooltip">
																Danh sách hợp đồng
															</Tooltip>
														}
													>
														<a href={`/summary`}>
															{/*<Button>
																Danh sách hợp đồng
															</Button>*/}
															<img src={iconListContract}  width="50" height="50"></img>
														</a>
													</OverlayTrigger>
													{
														roleName != 'Admin'
														?
														<>
															{
																check_display_button_by_role(params.id,roleName,Historys,Users,newContracts) == 1
																?
																<>
																	<OverlayTrigger
																		overlay={
																			<Tooltip id="tooltip">
																				Nhập liệu
																			</Tooltip>
																		}
																	>
																		<a href={`/inputform/${params.id}`} style={{'marginLeft':'20px'}} title="Nhập liệu">
																			{/*<Button>
																				Nhập liệu
																			</Button>*/}
																			<img src={iconKeyboard}  width="50" height="50"></img>
																		</a>
																	</OverlayTrigger>
																</>
																:
																<></>
															}
														</>
														:
														<></>
													}
													
												</td>
											</tr>
										</Table>
									</Card.Body>
								</Card>
							</>
						)
					}else {
						// console.debug("12 thu gian:: truy cap duoc", contract_me_new_access.user)
						return (
							<>
								<div id="notfound">
									<div className="notfound">
										<h1>Oops!</h1>
										<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
											<br/>
											<Link style={{textDecoration: "none"}} to="/home">
												Trở về trang chủ
											</Link>
										</h6>
									</div>
								</div>
							</>
						)
					}
				}else if (typeof contract_me_new_access.user == "object") {
					// console.debug("2 thu gian:: khong truy cap duoc", contract_me_new_access.user._id, newUserManagers)
					// console.debug(newUserManagers.includes(contract_me_new_access.user._id))
					if (newUserManagers.includes(contract_me_new_access.user._id) == true) {
						// console.debug("13 thu gian:: truy cap duoc", contract_me_new_access.user._id)
						return (
							<>
								<Card className='text-left mx-5 my-5'>
									<Card.Header as='h2' style={{textAlign: "center"}}>BẢNG PHÂN TÍCH HIỆU QUẢ HỢP ĐỒNG - DỰ ÁN </Card.Header>
									<Card.Body>
										<Table striped bordered hover size="sm">
											{
													newContracts.map(Contract => (
													<>
														<thead key={Contract._id} >
															<tr>
																<th>Số hợp đồng/PO: {Contract.ContractID}</th>
															</tr>
															<tr>
																<th>Trung tâm: {FindArray(Centers, Contract.CenterID).CenterName} </th>
															</tr>
															<tr>
																<th>Phòng: {FindArray(Departments, Contract.DepartmentID).DepartmentName} </th>
															</tr>
															<tr>
																<th>Tên AM: {nameUser}</th>
															</tr>
															<tr>
																<th>Khách hàng: {FindArray(Customers, Contract.CustomerID).CustomerName}</th>
															</tr>
															<tr>
																<th>Ngày: {convert_time(Contract.Date)}</th>
															</tr>
														</thead>
													</>
												))
											}
										</Table>


										<Table responsive="sm" striped bordered hover size="sm" >
											{ProductCosts.map(ProductCost => (
												<>
													<thead className='text-center'>
														<tr key={ProductCost._id} style={{textAlign: 'center'}}>
															<th rowSpan="2" style={{verticalAlign:'inherit'}}>STT</th>
															<th rowSpan="2" style={{verticalAlign:'inherit'}} width="15%">Tên hàng</th>
															<th rowSpan="2" style={{verticalAlign:'inherit'}} width="5%">Số lượng</th>
															<th colSpan="3">Giá vốn hàng bán Giá kho</th>
															<th colSpan="2 ">Doanh số Giá bán</th>
															<th rowSpan="2 " style={{verticalAlign:'inherit'}} width="8%">Có tính Chi Phí Bảo Hành kg</th>
															<th rowSpan="2 " style={{verticalAlign:'inherit'}} width="8%">Chi phí hỗ trợ kĩ thuật HSE</th>
															<th rowSpan="2 " style={{verticalAlign:'inherit'}} width="8%">Mua nội bộ</th>
															<th rowSpan="2 " style={{verticalAlign:'inherit'}} width="8%">Ghi chú</th>
														</tr>
														<tr style={{textAlign: 'center'}}>
															<th width='8%' as='pre'>Đơn giá FOB <br />
																(EX-W)</th>
															<th style={{verticalAlign:'inherit'}}>Đơn giá kho</th>
															<th style={{verticalAlign:'inherit'}}>Thành tiền giá kho</th>
															<th style={{verticalAlign:'inherit'}}>Đơn giá bán</th>
															<th style={{verticalAlign:'inherit'}}>Thành tiền giá bán</th>
														</tr>
													</thead>
													<tbody className='text-right'>
														{ProductCost.ListProducts.map(ListProduct => (
															<tr key={ListProduct._id} >
																<td>{stt++}  </td>
																<td>{ListProduct.ProductName}</td>
																<td>{ListProduct.Quantity.toLocaleString()}</td>
																<td>{ListProduct.FOBCost != null ? ListProduct.FOBCost.toLocaleString() : ""}</td>
																<td>{ListProduct.InputPrice != null ? ListProduct.InputPrice.toLocaleString() : ""}</td>
																<td>{ListProduct.InputIntoMoney != null ? ListProduct.InputIntoMoney.toLocaleString() : ""}</td>
																<td>{ListProduct.OutputPrice != null ? ListProduct.OutputPrice.toLocaleString() : ""}</td>
																<td>{ListProduct.OutputIntoMoney != null ? ListProduct.OutputIntoMoney.toLocaleString() : ""}</td>
																{/*<td>{ListProduct.Insurance}
																	<input
																		type='checkbox'
																		checked={ListProduct.Insurance}
																		onChange={(e) => toggleInsurance((e).target.checked)}
																	/>
																</td>*/}
																<td>{ListProduct.Insurance == true ? (<img src={download}  width="20" height="20"></img>) : ''}</td>
																<td>{ListProduct.InsuranceHSE == true ? (ListProduct.OutputIntoMoney * ((ListProduct.PercentHSE != null ? ListProduct.PercentHSE : 1) / 100)).toLocaleString() : '0'}</td>
																<td>{ListProduct.InternalPurchase == true ? (<img src={download}  width="20" height="20"></img>) : ''}</td>
																<td>{ListProduct.Note}</td>
															</tr>

														))
														}
														<tr>
															<td colSpan={5} >Tổng</td>
															<td>{Sum_InputIntoMoney(ProductCosts).toLocaleString()}</td>
															<td></td>
															<td>{Sum_OutputIntoMoney(ProductCosts).toLocaleString()}</td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
														</tr>
														{/* <tr>
															<td colSpan={7} > Incentive (nếu có)</td>
															<td>{ProductCost.Incentive == null ? 0 : ProductCost.Incentive.toLocaleString()}</td>
															<td></td>
														</tr> */}
													</tbody>
												</>
											))}
											<tr>
												<td>1</td>
												<td colSpan={6} >Chi phí phát sinh khi thực hiện Dự Án </td>
												<td className='text-right'>{Math.round(ExtraCost).toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr >
												<td>1.1</td>
												<td colSpan={6} >Chi phí triển khai hợp đồng </td>
												<td className='text-right'>{TotalImplementationCost.toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td colSpan={2} ></td>
												<td colSpan={5} >+ Chi phí bảo hành (phần cứng trích cho TT HSC 0.6%)</td>
												<td className='text-right'>{TotalInsurance.toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td colSpan={2} ></td>
												<td colSpan={5} >+ Chi phí hỗ trợ kĩ thuật HSE (1%)</td>
												<td className='text-right'>{TotalInsuranceHSE.toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											
											{
												ImplementationCosts.map(element => (
													element.StagesImplementation.map(stages => (
														stages.Costs.map(costs => (
															<>
																<tr>
																	<td colSpan={2}></td>
																	<td colSpan={5}>
																		+ {costs.NameCost}
																	</td>
																	<td className='text-right'>
																		{costs.IntoMoney.toLocaleString()}
																	</td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td>{costs.Note}</td>
																</tr>
															</>
														))
													))
												))
											}

											{
												CapitalExpenditureCosts.map((CapitalExpenditureCost) => (
													<>
														{/*<tr key={CapitalExpenditureCost._id}>
															<td>1.2</td>
															<td colSpan={6} > Chi phí vốn</td>
															<td className='text-right'>{Math.round(CapitalExpenditureCost.CapitalExpense).toLocaleString()}</td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>

														</tr>*/}
														{/*<tr>
															<td colSpan={2} ></td>
															<td colSpan={5} >+ Số ngày hàng tồn kho</td>
															<td className='text-right'>{Math.round(CapitalExpenditureCost.InventoryDays).toLocaleString()}</td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<td colSpan={2} ></td>
															<td colSpan={5} >+ Số ngày triển khai</td>
															<td className='text-right'>{Math.round(CapitalExpenditureCost.ImplementationDays).toLocaleString()}</td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<td colSpan={2} ></td>
															<td colSpan={5} >+ Số ngày công nợ nhà cung cấp</td>
															<td className='text-right'>{Math.round(CapitalExpenditureCost.BedtDays).toLocaleString()}</td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<td colSpan={2} ></td>
															<td colSpan={5} > + Số ngày thu nợ </td>
															<td className='text-right'>{Math.round(CapitalExpenditureCost.DebtCollectionDays).toLocaleString()}</td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<td colSpan={2} ></td>
															<td colSpan={5} >+ Khách hàng trả trước (đặt cọc)</td>
															<td className='text-right'>{Math.round(CapitalExpenditureCost.Deposits).toLocaleString()}</td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<td colSpan={2} ></td>
															<td colSpan={5} >+ Đặt cọc cho NTP </td>
															<td className='text-right'>{Math.round(CapitalExpenditureCost.DepositsNTP).toLocaleString()}</td>
															<td></td>
															<td></td>
														</tr>*/}
													</>
												))
											}
											{htmlChiPhi}
											< tr >
												<td>1.3</td>
												<td colSpan={6} >Manday thực hiện của kỹ sư HPT tham gia</td>
												<td className='text-right'>{Math.round(TotalMandayCost).toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr >
												<td>1.4</td>
												<td colSpan={6} >Chi phí làm thư bảo lãnh (BL dự thầu, BL thực hiện HĐ, BL BH)</td>
												<td className='text-right'>{Math.round(TotalGuaranteeLetterCost).toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr >
												<td>1.5</td>
												<td colSpan={6} > Chi phí khác</td>
												<td className='text-right'>{Math.round(TotalMiscExpenseCost).toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td>2</td>
												<td colSpan={6} >Chi phí mua vật tư phụ</td>
												<td className='text-right'>{rsTotalAuxiliaryCost.muc2.toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td>2.1</td>
												<td colSpan={6} >Chi phí mua vật tư phụ</td>
												<td className='text-right'>{rsTotalAuxiliaryCost.muc2_1.toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td>2.2</td>
												<td colSpan={6} >Thuế</td>
												<td className='text-right'>{rsTotalAuxiliaryCost.muc2_thue.toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td>3</td>
												<td colSpan={6} >Hiệu quả dự án (giá trị tuyệt đối)</td>
												<td className='text-right'>{hieuquaduan === null ? 0 : Math.round(hieuquaduan).toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td>4</td>
												<td colSpan={6} >Hiệu quả dự án (tỉ lệ trên doanh thu)</td>
												<td className='text-right'>{ !isNaN((hieuquaduan / TotalOutputIntoMoney)) && isFinite((hieuquaduan / TotalOutputIntoMoney)) ? ((hieuquaduan / TotalOutputIntoMoney) * 100).toFixed(2) + "%" : "0%"}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td></td>
												<td colSpan={6} >Hiệu quả dự án (chưa trừ  Manday của kỹ sư HPT)</td>
												<td className='text-right'>{Math.round((hieuquaduan + TotalMandayCost)).toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td></td>
												<td colSpan={6} >Hiệu quả dự án (tỉ lệ doanh thu) - chưa trừ  Manday của kỹ sư HPT</td>
												<td className='text-right'>{ !isNaN((hieuquaduan + TotalMandayCost) / TotalOutputIntoMoney) && isFinite((hieuquaduan + TotalMandayCost) / TotalOutputIntoMoney)? (((hieuquaduan + TotalMandayCost) / TotalOutputIntoMoney) * 100).toFixed(2) + "%" : "0%"}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td></td>
												<td colSpan={6} >Incentive :</td>
												<td className='text-right'>{TotalIncentive.toLocaleString()}</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td></td>
												<td colSpan={6} >Hiệu quả dự án (có Incentive):</td>
												{/*<td className='text-right'>{ !isNaN(((hieuquaduan + TotalIncentive + TotalMandayCost) / TotalOutputIntoMoney)) && isFinite(((hieuquaduan + TotalIncentive + TotalMandayCost) / TotalOutputIntoMoney)) ? (((hieuquaduan + TotalIncentive + TotalMandayCost) / TotalOutputIntoMoney) * 100).toFixed(2) + "%" : "0%"}</td>*/}
												<td className='text-right'>{ !isNaN(((hieuquaduan + TotalIncentive) / TotalOutputIntoMoney)) && isFinite(((hieuquaduan + TotalIncentive) / TotalOutputIntoMoney)) ? (((hieuquaduan + TotalIncentive) / TotalOutputIntoMoney) * 100).toFixed(2) + "%" : "0%"}</td>

												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr className='text-center'>
												<td colSpan={8}>
													<OverlayTrigger
														overlay={
															<Tooltip id="tooltip">
																Danh sách hợp đồng
															</Tooltip>
														}
													>
														<a href={`/summary`}>
															{/*<Button>
																Danh sách hợp đồng
															</Button>*/}
															<img src={iconListContract}  width="50" height="50"></img>
														</a>
													</OverlayTrigger>
													{
														roleName != 'Admin'
														?
														<>
															{
																check_display_button_by_role(params.id,roleName,Historys,Users,newContracts) == 1
																?
																<>
																	<OverlayTrigger
																		overlay={
																			<Tooltip id="tooltip">
																				Nhập liệu
																			</Tooltip>
																		}
																	>
																		<a href={`/inputform/${params.id}`} style={{'marginLeft':'20px'}} title="Nhập liệu">
																			{/*<Button>
																				Nhập liệu
																			</Button>*/}
																			<img src={iconKeyboard}  width="50" height="50"></img>
																		</a>
																	</OverlayTrigger>
																</>
																:
																<></>
															}
														</>
														:
														<></>
													}
													
												</td>
											</tr>
										</Table>
									</Card.Body>
								</Card>
							</>
						)
					}else {
						// console.debug("14 thu gian:: truy cap duoc", contract_me_new_access.user._id)
						return (
							<>
								<div id="notfound">
									<div className="notfound">
										<h1>Oops!</h1>
										<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
											<br/>
											<Link style={{textDecoration: "none"}} to="/home">
												Trở về trang chủ
											</Link>
										</h6>
									</div>
								</div>
							</>
						)
					}
				}
			}else {
				return (
					<>
						<div id="notfound">
							<div className="notfound">
								<h1>Oops!</h1>
								<h6>Rất tiếc, Hợp đồng không được tìm thấy trong hệ thống. Vui lòng kiểm tra lại.
									<br/>
									<Link style={{textDecoration: "none"}} to="/home">
										Trở về trang chủ
									</Link>
								</h6>
							</div>
						</div>
					</>
				)
			}
		}

		// if (contract_me_access == "" && contract_me_new_access != "") {
		// 	if (newContract.length == 0 && newContracts.length > 0) {
		// 		let contract_mev2 = newContracts.filter((v) => v.user == UserID);
		// 		if (contract_mev2.length > 0 ) {
		// 			console.debug("co quyen truy cap", contract_mev2)
		// 			return (
		// 				<>
		// 					<Card className='text-left mx-5 my-5'>
		// 						<Card.Header as='h2' style={{textAlign: "center"}}>BẢNG PHÂN TÍCH HIỆU QUẢ HỢP ĐỒNG - DỰ ÁN </Card.Header>
		// 						<Card.Body>
		// 							<Table striped bordered hover size="sm">
		// 								{
		// 										newContracts.map(Contract => (
		// 										<>
		// 											<thead key={Contract._id} >
		// 												<tr>
		// 													<th>Số hợp đồng/PO: {Contract.ContractID}</th>
		// 												</tr>
		// 												<tr>
		// 													<th>Trung tâm: {FindArray(Centers, Contract.CenterID).CenterName} </th>
		// 												</tr>
		// 												<tr>
		// 													<th>Phòng: {FindArray(Departments, Contract.DepartmentID).DepartmentName} </th>
		// 												</tr>
		// 												<tr>
		// 													<th>Tên AM: {nameUser}</th>
		// 												</tr>
		// 												<tr>
		// 													<th>Khách hàng: {FindArray(Customers, Contract.CustomerID).CustomerName}</th>
		// 												</tr>
		// 												<tr>
		// 													<th>Ngày: {convert_time(Contract.Date)}</th>
		// 												</tr>
		// 											</thead>
		// 										</>
		// 									))
		// 								}
		// 							</Table>


		// 							<Table responsive="sm" striped bordered hover size="sm" >
		// 								{ProductCosts.map(ProductCost => (
		// 									<>
		// 										<thead className='text-center'>
		// 											<tr key={ProductCost._id} style={{textAlign: 'center'}}>
		// 												<th rowSpan="2" style={{verticalAlign:'inherit'}}>STT</th>
		// 												<th rowSpan="2" style={{verticalAlign:'inherit'}} width="15%">Tên hàng</th>
		// 												<th rowSpan="2" style={{verticalAlign:'inherit'}} width="5%">Số lượng</th>
		// 												<th colSpan="3">Giá vốn hàng bán Giá kho</th>
		// 												<th colSpan="2 ">Doanh số Giá bán</th>
		// 												<th rowSpan="2 " style={{verticalAlign:'inherit'}} width="8%">Có tính Chi Phí Bảo Hành kg</th>
		// 												<th rowSpan="2 " style={{verticalAlign:'inherit'}} width="8%">Chi phí hỗ trợ kĩ thuật HSE</th>
		// 												<th rowSpan="2 " style={{verticalAlign:'inherit'}} width="8%">Mua nội bộ</th>
		// 												<th rowSpan="2 " style={{verticalAlign:'inherit'}} width="8%">Ghi chú</th>
		// 											</tr>
		// 											<tr style={{textAlign: 'center'}}>
		// 												<th width='8%' as='pre'>Đơn giá FOB <br />
		// 													(EX-W)</th>
		// 												<th style={{verticalAlign:'inherit'}}>Đơn giá kho</th>
		// 												<th style={{verticalAlign:'inherit'}}>Thành tiền giá kho</th>
		// 												<th style={{verticalAlign:'inherit'}}>Đơn giá bán</th>
		// 												<th style={{verticalAlign:'inherit'}}>Thành tiền giá bán</th>
		// 											</tr>
		// 										</thead>
		// 										<tbody className='text-right'>
		// 											{ProductCost.ListProducts.map(ListProduct => (
		// 												<tr key={ListProduct._id} >
		// 													<td>{stt++}  </td>
		// 													<td>{ListProduct.ProductName}</td>
		// 													<td>{ListProduct.Quantity.toLocaleString()}</td>
		// 													<td>{ListProduct.FOBCost != null ? ListProduct.FOBCost.toLocaleString() : ""}</td>
		// 													<td>{ListProduct.InputPrice != null ? ListProduct.InputPrice.toLocaleString() : ""}</td>
		// 													<td>{ListProduct.InputIntoMoney != null ? ListProduct.InputIntoMoney.toLocaleString() : ""}</td>
		// 													<td>{ListProduct.OutputPrice != null ? ListProduct.OutputPrice.toLocaleString() : ""}</td>
		// 													<td>{ListProduct.OutputIntoMoney != null ? ListProduct.OutputIntoMoney.toLocaleString() : ""}</td>
		// 													{/*<td>{ListProduct.Insurance}
		// 														<input
		// 															type='checkbox'
		// 															checked={ListProduct.Insurance}
		// 															onChange={(e) => toggleInsurance((e).target.checked)}
		// 														/>
		// 													</td>*/}
		// 													<td>{ListProduct.Insurance == true ? (<img src={download}  width="20" height="20"></img>) : ''}</td>
		// 													<td>{ListProduct.InsuranceHSE == true ? (ListProduct.OutputIntoMoney * ((ListProduct.PercentHSE != null ? ListProduct.PercentHSE : 1) / 100)).toLocaleString() : '0'}</td>
		// 													<td>{ListProduct.InternalPurchase == true ? (<img src={download}  width="20" height="20"></img>) : ''}</td>
		// 													<td>{ListProduct.Note}</td>
		// 												</tr>

		// 											))
		// 											}
		// 											<tr>
		// 												<td colSpan={5} >Tổng</td>
		// 												<td>{Sum_InputIntoMoney(ProductCosts).toLocaleString()}</td>
		// 												<td></td>
		// 												<td>{Sum_OutputIntoMoney(ProductCosts).toLocaleString()}</td>
		// 												<td></td>
		// 												<td></td>
		// 												<td></td>
		// 												<td></td>
		// 											</tr>
		// 											{/* <tr>
		// 												<td colSpan={7} > Incentive (nếu có)</td>
		// 												<td>{ProductCost.Incentive == null ? 0 : ProductCost.Incentive.toLocaleString()}</td>
		// 												<td></td>
		// 											</tr> */}
		// 										</tbody>
		// 									</>
		// 								))}
		// 								<tr>
		// 									<td>1</td>
		// 									<td colSpan={6} >Chi phí phát sinh khi thực hiện Dự Án </td>
		// 									<td className='text-right'>{Math.round(ExtraCost).toLocaleString()}</td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 								</tr>
		// 								<tr >
		// 									<td>1.1</td>
		// 									<td colSpan={6} >Chi phí triển khai hợp đồng </td>
		// 									<td className='text-right'>{TotalImplementationCost.toLocaleString()}</td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 								</tr>
		// 								<tr>
		// 									<td colSpan={2} ></td>
		// 									<td colSpan={5} >+ Chi phí bảo hành (phần cứng trích cho TT HSC 0.6%)</td>
		// 									<td className='text-right'>{TotalInsurance.toLocaleString()}</td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 								</tr>
		// 								<tr>
		// 									<td colSpan={2} ></td>
		// 									<td colSpan={5} >+ Chi phí hỗ trợ kĩ thuật HSE (1%)</td>
		// 									<td className='text-right'>{TotalInsuranceHSE.toLocaleString()}</td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 								</tr>
										
		// 								{
		// 									ImplementationCosts.map(element => (
		// 										element.StagesImplementation.map(stages => (
		// 											stages.Costs.map(costs => (
		// 												<>
		// 													<tr>
		// 														<td colSpan={2}></td>
		// 														<td colSpan={5}>
		// 															+ {costs.NameCost}
		// 														</td>
		// 														<td className='text-right'>
		// 															{costs.IntoMoney.toLocaleString()}
		// 														</td>
		// 														<td></td>
		// 														<td></td>
		// 														<td></td>
		// 														<td>{costs.Note}</td>
		// 													</tr>
		// 												</>
		// 											))
		// 										))
		// 									))
		// 								}

		// 								{
		// 									CapitalExpenditureCosts.map((CapitalExpenditureCost) => (
		// 										<>
		// 											{/*<tr key={CapitalExpenditureCost._id}>
		// 												<td>1.2</td>
		// 												<td colSpan={6} > Chi phí vốn</td>
		// 												<td className='text-right'>{Math.round(CapitalExpenditureCost.CapitalExpense).toLocaleString()}</td>
		// 												<td></td>
		// 												<td></td>
		// 												<td></td>
		// 												<td></td>

		// 											</tr>*/}
		// 											{/*<tr>
		// 												<td colSpan={2} ></td>
		// 												<td colSpan={5} >+ Số ngày hàng tồn kho</td>
		// 												<td className='text-right'>{Math.round(CapitalExpenditureCost.InventoryDays).toLocaleString()}</td>
		// 												<td></td>
		// 												<td></td>
		// 											</tr>
		// 											<tr>
		// 												<td colSpan={2} ></td>
		// 												<td colSpan={5} >+ Số ngày triển khai</td>
		// 												<td className='text-right'>{Math.round(CapitalExpenditureCost.ImplementationDays).toLocaleString()}</td>
		// 												<td></td>
		// 												<td></td>
		// 											</tr>
		// 											<tr>
		// 												<td colSpan={2} ></td>
		// 												<td colSpan={5} >+ Số ngày công nợ nhà cung cấp</td>
		// 												<td className='text-right'>{Math.round(CapitalExpenditureCost.BedtDays).toLocaleString()}</td>
		// 												<td></td>
		// 												<td></td>
		// 											</tr>
		// 											<tr>
		// 												<td colSpan={2} ></td>
		// 												<td colSpan={5} > + Số ngày thu nợ </td>
		// 												<td className='text-right'>{Math.round(CapitalExpenditureCost.DebtCollectionDays).toLocaleString()}</td>
		// 												<td></td>
		// 												<td></td>
		// 											</tr>
		// 											<tr>
		// 												<td colSpan={2} ></td>
		// 												<td colSpan={5} >+ Khách hàng trả trước (đặt cọc)</td>
		// 												<td className='text-right'>{Math.round(CapitalExpenditureCost.Deposits).toLocaleString()}</td>
		// 												<td></td>
		// 												<td></td>
		// 											</tr>
		// 											<tr>
		// 												<td colSpan={2} ></td>
		// 												<td colSpan={5} >+ Đặt cọc cho NTP </td>
		// 												<td className='text-right'>{Math.round(CapitalExpenditureCost.DepositsNTP).toLocaleString()}</td>
		// 												<td></td>
		// 												<td></td>
		// 											</tr>*/}
		// 										</>
		// 									))
		// 								}
		// 								{htmlChiPhi}
		// 								< tr >
		// 									<td>1.3</td>
		// 									<td colSpan={6} >Manday thực hiện của kỹ sư HPT tham gia</td>
		// 									<td className='text-right'>{Math.round(TotalMandayCost).toLocaleString()}</td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 								</tr>
		// 								<tr >
		// 									<td>1.4</td>
		// 									<td colSpan={6} >Chi phí làm thư bảo lãnh (BL dự thầu, BL thực hiện HĐ, BL BH)</td>
		// 									<td className='text-right'>{Math.round(TotalGuaranteeLetterCost).toLocaleString()}</td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 								</tr>
		// 								<tr >
		// 									<td>1.5</td>
		// 									<td colSpan={6} > Chi phí khác</td>
		// 									<td className='text-right'>{Math.round(TotalMiscExpenseCost).toLocaleString()}</td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 								</tr>
		// 								<tr>
		// 									<td>2</td>
		// 									<td colSpan={6} >Chi phí mua vật tư phụ</td>
		// 									<td className='text-right'>{rsTotalAuxiliaryCost.muc2.toLocaleString()}</td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 								</tr>
		// 								<tr>
		// 									<td>2.1</td>
		// 									<td colSpan={6} >Chi phí mua vật tư phụ</td>
		// 									<td className='text-right'>{rsTotalAuxiliaryCost.muc2_1.toLocaleString()}</td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 								</tr>
		// 								<tr>
		// 									<td>2.2</td>
		// 									<td colSpan={6} >Thuế</td>
		// 									<td className='text-right'>{rsTotalAuxiliaryCost.muc2_thue.toLocaleString()}</td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 								</tr>
		// 								<tr>
		// 									<td>3</td>
		// 									<td colSpan={6} >Hiệu quả dự án (giá trị tuyệt đối)</td>
		// 									<td className='text-right'>{hieuquaduan === null ? 0 : Math.round(hieuquaduan).toLocaleString()}</td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 								</tr>
		// 								<tr>
		// 									<td>4</td>
		// 									<td colSpan={6} >Hiệu quả dự án (tỉ lệ trên doanh thu)</td>
		// 									<td className='text-right'>{ !isNaN((hieuquaduan / TotalOutputIntoMoney)) && isFinite((hieuquaduan / TotalOutputIntoMoney)) ? ((hieuquaduan / TotalOutputIntoMoney) * 100).toFixed(2) + "%" : "0%"}</td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 								</tr>
		// 								<tr>
		// 									<td></td>
		// 									<td colSpan={6} >Hiệu quả dự án (chưa trừ  Manday của kỹ sư HPT)</td>
		// 									<td className='text-right'>{Math.round((hieuquaduan + TotalMandayCost)).toLocaleString()}</td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 								</tr>
		// 								<tr>
		// 									<td></td>
		// 									<td colSpan={6} >Hiệu quả dự án (tỉ lệ doanh thu) - chưa trừ  Manday của kỹ sư HPT</td>
		// 									<td className='text-right'>{ !isNaN((hieuquaduan + TotalMandayCost) / TotalOutputIntoMoney) && isFinite((hieuquaduan + TotalMandayCost) / TotalOutputIntoMoney)? (((hieuquaduan + TotalMandayCost) / TotalOutputIntoMoney) * 100).toFixed(2) + "%" : "0%"}</td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 								</tr>
		// 								<tr>
		// 									<td></td>
		// 									<td colSpan={6} >Incentive :</td>
		// 									<td className='text-right'>{TotalIncentive.toLocaleString()}</td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 								</tr>
		// 								<tr>
		// 									<td></td>
		// 									<td colSpan={6} >Hiệu quả dự án (có Incentive):</td>
		// 									{/*<td className='text-right'>{ !isNaN(((hieuquaduan + TotalIncentive + TotalMandayCost) / TotalOutputIntoMoney)) && isFinite(((hieuquaduan + TotalIncentive + TotalMandayCost) / TotalOutputIntoMoney)) ? (((hieuquaduan + TotalIncentive + TotalMandayCost) / TotalOutputIntoMoney) * 100).toFixed(2) + "%" : "0%"}</td>*/}
		// 									<td className='text-right'>{ !isNaN(((hieuquaduan + TotalIncentive) / TotalOutputIntoMoney)) && isFinite(((hieuquaduan + TotalIncentive) / TotalOutputIntoMoney)) ? (((hieuquaduan + TotalIncentive) / TotalOutputIntoMoney) * 100).toFixed(2) + "%" : "0%"}</td>

		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 									<td></td>
		// 								</tr>
		// 								<tr className='text-center'>
		// 									<td colSpan={8}>
		// 										<OverlayTrigger
		// 											overlay={
		// 												<Tooltip id="tooltip">
		// 													Danh sách hợp đồng
		// 												</Tooltip>
		// 											}
		// 										>
		// 											<a href={`/summary`}>
		// 												{/*<Button>
		// 													Danh sách hợp đồng
		// 												</Button>*/}
		// 												<img src={iconListContract}  width="50" height="50"></img>
		// 											</a>
		// 										</OverlayTrigger>
		// 										{
		// 											roleName != 'Admin' && roleName != 'BGD' && roleName != 'BTGD'
		// 											?
		// 											<>
		// 												{
		// 													check_display_button_by_role(params.id,roleName,Historys) == 1
		// 													?
		// 													<>
		// 														<OverlayTrigger
		// 															overlay={
		// 																<Tooltip id="tooltip">
		// 																	Nhập liệu
		// 																</Tooltip>
		// 															}
		// 														>
		// 															<a href={`/inputform/${params.id}`} style={{'marginLeft':'20px'}} title="Nhập liệu">
		// 																{/*<Button>
		// 																	Nhập liệu
		// 																</Button>*/}
		// 																<img src={iconKeyboard}  width="50" height="50"></img>
		// 															</a>
		// 														</OverlayTrigger>
		// 													</>
		// 													:
		// 													<></>
		// 												}
		// 											</>
		// 											:
		// 											<></>
		// 										}
												
		// 									</td>
		// 								</tr>
		// 							</Table>
		// 						</Card.Body>
		// 					</Card>
		// 				</>
		// 			)
		// 		}else {
		// 			console.debug("khong co quyen truy cap v2")
		// 			return (
		// 				<>
		// 					<div id="notfound">
		// 						<div className="notfound">
		// 							<h1>Oops!</h1>
		// 							<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
		// 								<br/>
		// 								<Link style={{textDecoration: "none"}} to="/home">
		// 									Trở về trang chủ
		// 								</Link>
		// 							</h6>
		// 						</div>
		// 					</div>
		// 				</>
		// 			)
		// 		}
		// 	}else if (newContract.length > 0 && newContracts.length == 0) {
		// 		console.debug("khong tim thay")
		// 		return (
		// 			<>
		// 				<div id="notfound">
		// 					<div className="notfound">
		// 						<h1>Oops!</h1>
		// 						<h6>Rất tiếc, Hợp đồng không được tìm thấy trong hệ thống. Vui lòng kiểm tra lại.
		// 							<br/>
		// 							<Link style={{textDecoration: "none"}} to="/home">
		// 								Trở về trang chủ
		// 							</Link>
		// 						</h6>
		// 					</div>
		// 				</div>
		// 			</>
		// 		)
		// 	}else if (newContract.length > 0 && newContracts.length > 0) {
		// 		console.debug("khong co quyen truy cap")
		// 		return (
		// 			<>
		// 				<div id="notfound">
		// 					<div className="notfound">
		// 						<h1>Oops!</h1>
		// 						<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
		// 							<br/>
		// 							<Link style={{textDecoration: "none"}} to="/home">
		// 								Trở về trang chủ
		// 							</Link>
		// 						</h6>
		// 					</div>
		// 				</div>
		// 			</>
		// 		)
		// 	}
		// }else{
		// 	return(<></>)
		// }

		return(<></>)
	}
}