import Button from 'react-bootstrap/Button'
import { useState } from 'react';
import Form from 'react-bootstrap/Form'

export const Pagination = ({ activePage, count, rowsPerPage, totalPages, setActivePage, setRowsPerPage}) => {
    const beginning = activePage === 1 ? 1 : rowsPerPage * (activePage - 1) + 1;
    const end = activePage === totalPages ? count : beginning + rowsPerPage - 1;

    const [message, setMessage] = useState(rowsPerPage)

    const PagiEle = () => {
        const checkRowsPerPage = (totalPages/rowsPerPage)*rowsPerPage;
        if (checkRowsPerPage == 1 ) {
            return (
                <>
                    <button className="page-active" onClick={() => setActivePage(activePage)}>
                        {activePage}
                    </button>
                </>
            )
        }else if (checkRowsPerPage == 2) {
            return (
                <>
                    {
                        (activePage >= 2 ) ? 
                        <>
                            <button onClick={() => setActivePage(activePage -1)}>
                                {activePage - 1 }
                            </button>
                            <button className="page-active" onClick={() => setActivePage(activePage)}>
                                {activePage}
                            </button>
                        </>
                        :
                        <>
                            <button className="page-active" onClick={() => setActivePage(activePage)}>
                                {activePage}
                            </button>
                            {
                                activePage === totalPages ? 
                                    <>
                                    </>
                                :
                                <>
                                    <button onClick={() => setActivePage(activePage + 1)}>
                                        {activePage + 1}
                                    </button>
                                </>
                            }
                        </>
                    }
                </>
            )
        }else{
            return (
                <>
                    {
                        (activePage >= 2 ) ? 
                        <>
                            <button onClick={() => setActivePage(activePage -1)}>
                                {activePage - 1 }
                            </button>
                            <button className="page-active" onClick={() => setActivePage(activePage)}>
                                {activePage}
                            </button>
                            {
                                activePage === totalPages ? 
                                    <>
                                    </>
                                :
                                <>
                                    <button onClick={() => setActivePage(activePage + 1)}>
                                        {activePage + 1}
                                    </button>
                                </>
                            }
                        </>
                        :
                        <>
                            <button className="page-active" onClick={() => setActivePage(activePage)}>
                                {activePage}
                            </button>
                            <button onClick={() => setActivePage(activePage + 1)}>
                                {activePage + 1}
                            </button>
                            <button onClick={() => setActivePage(activePage + 2)}>
                                {activePage + 2}
                            </button>
                        </>
                    }
                </>
            )
        }
    }


    const handleKeyDown = (event) => {
        if (event.keyCode == 13) {
            console.log("handleKeyDown", message)
            setRowsPerPage(parseInt(message));
        }
    };

    const handleChange = (event) => {
        console.log("handleChange", event.target.value)
        setMessage(event.target.value);
    };

    const selectedOptionPage = [5,10,15,20,50,100];

    function handleSelectChange(event) {
		let value = event.target.value;
		setRowsPerPage(parseInt(value));
		setActivePage(1);
        console.log(value,111111)
	}
    

    return (
        <>
            <div class="pagination">
                {/* <input 
                    type='text' 
                    className='form-control' 
                    value={message}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                /> */}
                <label>Hiển thị &nbsp;</label>
                <Form.Control
                    id = 'postPerPage-help'
                    as="select"
                    name='rowsPerPage'
                    aria-describedby='postPerPage-help'
                    value={rowsPerPage}
                    onChange={handleSelectChange}>
                    {
                        typeof selectedOptionPage != "undefined" ? 
                        selectedOptionPage.map((obj => {
                            return (
                                <>
                                    <option value={obj}>{obj}</option>
                                </>
                            )
                        }))
                        :
                        <></>
                    }
                    
                </Form.Control>
                <label>&nbsp; dòng</label>
            
                <button disabled={activePage === 1} onClick={() => setActivePage(1)}>
                    &#171;
                </button>
                <button disabled={activePage === 1} onClick={() => setActivePage(activePage - 1)}>
                    &#8249;
                </button>
                <PagiEle />
                <button disabled={activePage === totalPages} onClick={() => setActivePage(activePage + 1)}>
                    &#8250;
                </button>
                <button disabled={activePage === totalPages} onClick={() => setActivePage(totalPages)}>
                    &#187;
                </button>
            </div>
            <p>
                Trang {activePage} trên {totalPages} trang
                {/*Rows: {beginning === end ? end : `${beginning} - ${end}`} of {count}*/}
            </p>
        </>
    );
}; 
