import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { ConfigCustomerContext } from '../../../contexts/ConfigCustomerContext'
import { useParams } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'

const UpdateCustomerModal = () => {
	// Contexts 
	const {
		showUpdateCustomerModal,
		setShowUpdateCustomerModal,
		Data_update,
		UpdateCustomers,
		update_Customer,
		setShowToast,
	} = useContext(ConfigCustomerContext)

	// State
	const [updatedCustomer, setUpdatedCustomer] = useState(Data_update)
	useEffect(() => setUpdatedCustomer(Data_update),[Data_update])

	let {
		CustomerName,
		TaxCode,
	} = updatedCustomer
	const params = useParams();
	const onChangeUpdateCustomerForm = event =>{
		let value = event.target.value;
		setUpdatedCustomer({ ...updatedCustomer, [event.target.name]: event.target.value });
	}

	const closeDialog = () => {
		setUpdatedCustomer(updatedCustomer)
		setShowUpdateCustomerModal(false)
	}

	const onSubmit = async event => {
		event.preventDefault()
		const { success, message } = await update_Customer(updatedCustomer)
		setShowUpdateCustomerModal(false)
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
	}

	const isPatternNumber = userInput => {
		if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		return /^\d+(\.\d{0,2})?$/.test(userInput);
	}
	return (
		<Modal animation={false} show={showUpdateCustomerModal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>Chỉnh sửa khách hàng</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<Form.Group>
						<Form.Text id='tenkh-help' muted as='h6'>
							Tên khách hàng
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Tên khách hàng'
							name='CustomerName'
							required
							value={CustomerName}
							onChange={onChangeUpdateCustomerForm}
						/>
						<Form.Text id='masothue-help' muted as='h6'>
							Mã số thuế
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Mã số thuế'
							name='TaxCode'
							value={TaxCode}
							onChange={onChangeUpdateCustomerForm}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Cập nhật
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}



export default UpdateCustomerModal




