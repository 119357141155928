import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useContext, useState, useEffect } from 'react'
import { RemoveContractContext } from '../../contexts/RemoveContractContext'
import { ContractContext } from '../../contexts/ContractContext'
import { useParams } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert';

const RemoveContractModal = (_id) => {
	let arrayContract = _id._id;
	// Contexts 
	const {
		showAddRemoveContractModal,
		setShowAddRemoveContractModal,
		showAllRemoveContractModal,
		setShowAllRemoveContractModal,
		delete_RemoveContract,
		deleteAll_RemoveContract,
		setShowToast
	} = useContext(RemoveContractContext)

	const {
		ContractState: { Contract, Contracts, ContractsLoading },
		getContracts,
		setShowAddContractModal,
		// showToast: { show, message, type },
		// setShowToast
	} = useContext(ContractContext)

	useEffect(() => getContracts(), [])
	const closeDialog = () => {
		resetAddRemoveContractData()
	}
	const closeDialogNew = () => {
		resetAllRemoveContractData()
	}

	//capcha 
	const [input, setInput] = useState('');
	const [inputNew, setInputNew] = useState('');
	const generateRandomString = (length) => {
	    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	    const charactersLength = characters.length;
	    const randomValues = new Uint8Array(length);
	    crypto.getRandomValues(randomValues);
	    const result = Array.from(randomValues, (value) => characters[value % charactersLength]);
	    return result.join('');
	};
    const [captcha, setCaptcha] = useState(generateRandomString(7));
    const [data, setData] = useState(null);
    const [alertInputCaptchaVisible, setAlertInputCaptchaVisible] = useState(false);
    const [alertWrongCaptchaVisible, setAlertWrongCaptchaVisible] = useState(false);
    // const [captchaVerified, setCaptchaVerified] = useState(false); // Thêm state mới

    const [captchaNew, setCaptchaNew] = useState(generateRandomString(15));
	const [dataNew, setDataNew] = useState(null);
	const [alertInputCaptchaVisibleNew, setAlertInputCaptchaVisibleNew] = useState(false);
	const [alertWrongCaptchaVisibleNew, setAlertWrongCaptchaVisibleNew] = useState(false);

	const resetAddRemoveContractData = () => {
		setShowAddRemoveContractModal(false)
	}

	const resetAllRemoveContractData = () => {
		setShowAllRemoveContractModal(false)
	}

	const onSubmit = async event => {
		if (input === "") {
			setAlertWrongCaptchaVisible(false);
	      	setAlertInputCaptchaVisible(true);
	      	// setCaptchaVerified(false); 
	      	return;
	    } else {
	      	setAlertInputCaptchaVisible(false);
	    }

	    if (input !== captcha) {
	      	setAlertWrongCaptchaVisible(true);
	      	return;
	      	// setCaptchaVerified(false); 
	    } else {
	      	setAlertWrongCaptchaVisible(false);
	    }

	    if (input !== "" && input === captcha) {
	      	// setCaptchaVerified(true); // Xác minh Captcha thành công
	      	// Rest của xử lý sau xác minh Captcha
		    if(arrayContract.length > 0){
			    for (var i = 0; i < arrayContract.length; i++) {
			    	let value = arrayContract[i];
					await delete_RemoveContract(value['value']);
			    }
				setShowToast({ show: true, message:"Xóa thành công hợp đồng", type: "success"})
			}else{
				setShowToast({ show: true, message:"Chưa chọn hợp đồng cần xóa", type: "danger" })
			}
			location.reload();
	    }
	}

	const onSubmitAll = async event => {
		if (inputNew === "") {
			setAlertWrongCaptchaVisibleNew(false);
	      	setAlertInputCaptchaVisibleNew(true);
	      	// setCaptchaVerified(false); 
	      	return;
	    } else {
	      	setAlertInputCaptchaVisibleNew(false);
	    }

	    if (inputNew !== captchaNew) {
	      	setAlertWrongCaptchaVisibleNew(true);
	      	return;
	      	// setCaptchaVerified(false); 
	    } else {
	      	setAlertWrongCaptchaVisibleNew(false);
	    }

	    if (inputNew !== "" && inputNew === captchaNew) {
	      	// setCaptchaVerified(true); // Xác minh Captcha thành công
	      	// Rest của xử lý sau xác minh Captcha
	      	confirmAlert({
			  title: '',
			  message: 'Lưu ý: Toàn bộ dữ liệu sẽ xóa và không thể khôi phục lại. Bạn có chắc muốn xóa toàn bộ hợp đồng?',
			  buttons: [
				{
				  label: 'Có',
				  onClick: async () => {
				  	if(Contracts.length > 0){
			    		for (var i = 0; i < Contracts.length; i++) {
					    	let value = Contracts[i];
					    	let contract_id = value['_id'];
							await delete_RemoveContract(contract_id);
					    }
						setShowToast({ show: true, message:"Xóa thành công hợp đồng", type: "success"})
					}else{
						setShowToast({ show: true, message:"Chưa chọn hợp đồng cần xóa", type: "danger" })
					}
				  	location.reload();
				  }
				},
				{
				  label: 'Không',
				  onClick: () => { closeDialogNew();location.reload();}
				}
			  ]
			});
	    }
	}

	const showVerifyCaptcha = (data) => {
	    setInput('');
	    setData(data);
	    setCaptcha(generateRandomString(7));
	    // Hiển thị modal
	    // showModal();
	 };

	const isCaptchaCharacter = (e) => {
	    const charCode = e.which ? e.which : e.keyCode;
	    const tempChars = '1234567890qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM';
	    if (charCode > 13 && tempChars.indexOf(String.fromCharCode(charCode)) === -1) {
	      e.preventDefault();
	    }
	    return true;
	};

	const handleCopy = (e) => {
    	e.preventDefault();
    };

    const handleCut = (e) => {
	    e.preventDefault();
    };

    const handlePaste = (e) => {
    	e.preventDefault();
  	};

// 	const isPatternNumber = userInput => {
// 		if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
//   		return /^\d+(\.\d{0,2})?$/.test(userInput);
// 	}
	return (
		<>
			<Modal animation={false} show={showAddRemoveContractModal} onHide={closeDialog}>
				<Modal.Header closeButton>
					<Modal.Title>Xóa hợp đồng</Modal.Title>
				</Modal.Header>
					<Modal.Body>
						<div className="form-group">
			              	<div className="input-group" style={{ height: '80px', maxWidth: '500px' }}>
				                <input type="text" className="form-control" id="inputCaptcha" onKeyPress={isCaptchaCharacter} placeholder="Captcha" onPaste={handlePaste} maxLength="7" aria-describedby="unitCaptcha" style={{ textAlign: 'center', width: '200px', fontSize: '20px', border: '2px solid #000', borderRadius: '10px', height: '100%' }} value={input} onChange={(e) => setInput(e.target.value)} />
				                <div className="input-group-append" id="js-content-captcha" style={{ marginLeft: '20px' }}>
				                  <span className="input-group-text" id="generateCaptcha" onCopy={handleCopy} onCut={handleCut} style={{ width: '220px', display: 'inline-grid', fontSize: '20px', fontWeight: 'bold', backgroundColor: '#444', color: '#fff', border: '2px solid #000', borderRadius: '10px' }}>{captcha}</span>
				                </div>
			              	</div>
			              	{alertInputCaptchaVisible && <span style={{ color: 'red' }}><i className="fa fa-info-circle" aria-hidden="true"></i>&ensp; Chưa nhập mã captcha</span>}
			              	{alertWrongCaptchaVisible && <span style={{ color: 'red' }}><i className="fa fa-info-circle" aria-hidden="true"></i>&ensp; Sai mã captcha</span>}
			              	{/*{!alertWrongCaptchaVisible && <span style={{ color: 'green' }}><i className="fa fa-info-circle" aria-hidden="true"></i>&ensp; Success</span>}*/}
			              	{/*{captchaVerified && <span style={{ color: 'green' }}><i className="fa fa-info-circle" aria-hidden="true"></i>&ensp; Captcha verification successful!! </span>}*/}
			            </div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary' onClick={closeDialog}>
							Hủy
						</Button>
						<Button variant='primary' type='submit' onClick={onSubmit}>
							Xóa
						</Button>
					</Modal.Footer>
				
			</Modal>
			<Modal animation={false} show={showAllRemoveContractModal} onHide={closeDialogNew}>
				<Modal.Header closeButton>
					<Modal.Title>Xóa tất cả hợp đồng</Modal.Title>
				</Modal.Header>
					<Modal.Body>
						<div className="form-group">
			              	<div className="input-group" style={{ height: '80px', maxWidth: '500px' }}>
				                <input type="text" className="form-control" id="inputCaptcha" onKeyPress={isCaptchaCharacter} placeholder="Captcha" onPaste={handlePaste} maxLength="15" aria-describedby="unitCaptcha" style={{ textAlign: 'center', width: '200px', fontSize: '20px', border: '2px solid #000', borderRadius: '10px', height: '100%' }} value={inputNew} onChange={(e) => setInputNew(e.target.value)} />
				                <div className="input-group-append" id="js-content-captcha" style={{ marginLeft: '20px' }}>
				                  <span className="input-group-text" id="generateCaptcha" onCopy={handleCopy} onCut={handleCut} style={{ width: '220px', display: 'inline-grid', fontSize: '20px', fontWeight: 'bold', backgroundColor: '#444', color: '#fff', border: '2px solid #000', borderRadius: '10px' }}>{captchaNew}</span>
				                </div>
			              	</div>
			              	{alertInputCaptchaVisibleNew && <span style={{ color: 'red' }}><i className="fa fa-info-circle" aria-hidden="true"></i>&ensp; Chưa nhập mã captcha</span>}
			              	{alertWrongCaptchaVisibleNew && <span style={{ color: 'red' }}><i className="fa fa-info-circle" aria-hidden="true"></i>&ensp; Sai mã captcha</span>}
			              	{/*{!alertWrongCaptchaVisible && <span style={{ color: 'green' }}><i className="fa fa-info-circle" aria-hidden="true"></i>&ensp; Success</span>}*/}
			              	{/*{captchaVerified && <span style={{ color: 'green' }}><i className="fa fa-info-circle" aria-hidden="true"></i>&ensp; Captcha verification successful!! </span>}*/}
			            </div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary' onClick={closeDialogNew}>
							Hủy
						</Button>
						<Button variant='primary' type='submit' onClick={onSubmitAll}>
							Xóa
						</Button>
					</Modal.Footer>
			</Modal>
		</>
	)
}

export default RemoveContractModal




