import LoginForm from '../components/auth/LoginForm'
import RegisterForm from '../components/auth/RegisterForm'
import { AuthContext } from '../contexts/AuthContext'
import { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import LogoNew from './../logo-new.png'

const Auth = ({ authRoute }) => {
	const {
		authState: { authLoading, isAuthenticated }
	} = useContext(AuthContext)

	let body

	if (authLoading)
		body = (
			<div className='d-flex justify-content-center mt-2'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	else if (isAuthenticated) return <Redirect to='/home' />
	else
		body = (
			<>	
				<div className='landing'>
					<div className='dark-overlay'>
						<div className='landing-inner'>
							<img src={LogoNew}  width="200" height="100" style={{'marginBottom':'45px'}}></img>
							<h1>QUẢN LÝ HỢP ĐỒNG</h1>
							{authRoute === 'login' && <LoginForm />}
							{/*{authRoute === 'register' && <RegisterForm />}*/}
						</div>
					</div>
				</div>
			</>
		)

	return (
		<>	
			{body}
		</>
	)
}

export default Auth
