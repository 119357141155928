import { createContext, useReducer, useState } from 'react'
import { EventReducer } from '../reducers/EventReducer'
import {
	apiUrl,
	LOADED_FAIL,
	LOADED_SUCCESS,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from './constants'//Note
import axios from 'axios'

export const EventContext = createContext()

const EventContextProvider = ({ children }) => {
	const [EventState,  dispatch] = useReducer(EventReducer, {
		Event: null,
		Events: [],
		EventsLoading: true
	});

	// Get all Event
	const getEvent = async () => {
		try {
			const response = await axios.get(`${apiUrl}/api/forms/Event`)
			if (response.data.success) {
				dispatch({ type: LOADED_SUCCESS, payload: response.data.Event })	
			}
		} catch (error) {
			dispatch({ type: LOADED_FAIL })
		}
	}

	const updateStatusContract = async Contract => {
		try {
			console.log(Contract,'sdsadsad')
			const response = await axios.post(`${apiUrl}/api/forms/Event/post`, Contract)//note Users
			if (response.data.success) {
				// dispatch({ type: ADD, payload: response.data.Contract }) //note Users
				getEvent();
				return response.data
			}

		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}




	// Event context data
	const EventContextData = {
		EventState,
		getEvent,
		updateStatusContract
	}

	return (
		<EventContext.Provider value={EventContextData}>
			{children}
		</EventContext.Provider>
	)
}

export default EventContextProvider
