import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CapitalExpenditureCostContext } from '../../contexts/CapitalExpenditureCostContext'
import { AuthContext } from '../../contexts/AuthContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'

const UpdateCapitalExpenditureCostModal = () => {
	// Contexts
	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	const {
		CapitalExpenditureCostState: { CapitalExpenditureCost },
		showUpdateCapitalExpenditureCostModal,
		setShowUpdateCapitalExpenditureCostModal,
		updateCapitalExpenditureCost,
		setShowToast
	} = useContext(CapitalExpenditureCostContext)

	// State
	const [updatedCapitalExpenditureCost, setUpdatedCapitalExpenditureCost] = useState(CapitalExpenditureCost)
	useEffect(() => setUpdatedCapitalExpenditureCost(CapitalExpenditureCost), [CapitalExpenditureCost])

	const [beforeCapitalExpenditureCost, setBeforeCapitalExpenditureCost] = useState(CapitalExpenditureCost)
	useEffect(() => setBeforeCapitalExpenditureCost(CapitalExpenditureCost),[CapitalExpenditureCost])

	const {
		InventoryDays,
		ImplementationDays,
		BedtDays,
		DebtCollectionDays,
		PercentInterest,
		PercentDeposits,
		PercentDepositsNTP,
		Note
	} = updatedCapitalExpenditureCost

	//load idcontract
	const params = useParams();
	updatedCapitalExpenditureCost.ContractID = params.id;

	const onChangeUpdatedCapitalExpenditureCostForm = event =>{
		if(event.target.name != 'Note'){
			if (!isPatternNumber(event.target.value) &&  event.target.value != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số1! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				if(event.target.name == 'PercentDeposits' || event.target.name == 'PercentDepositsNTP' || event.target.name == 'PercentInterest'){
					if (parseInt(event.target.value) > 100 && parseFloat(event.target.value) > 100) {
						var message = 'Vui lòng nhập tỷ lệ không quá 100%'
						setShowToast({ show: true, message: message, type: 'danger' })
						event.target.value = validValueFee
						return false;
					}else{
						setUpdatedCapitalExpenditureCost({ ...updatedCapitalExpenditureCost, [event.target.name]: event.target.value })
					}
				}else{
					setUpdatedCapitalExpenditureCost({ ...updatedCapitalExpenditureCost, [event.target.name]: event.target.value })
				}
			}
		}else{
			setUpdatedCapitalExpenditureCost({ ...updatedCapitalExpenditureCost, [event.target.name]: event.target.value })
		}
	}

	const closeDialog = () => {
		setUpdatedCapitalExpenditureCost(CapitalExpenditureCost)
		setShowUpdateCapitalExpenditureCostModal(false)
	}

	const onSubmit = async event => {
		event.preventDefault()
		const { success, message } = await updateCapitalExpenditureCost(updatedCapitalExpenditureCost)
		const diff = findDiff(beforeCapitalExpenditureCost, updatedCapitalExpenditureCost);
		const historyChangeCapitalExpenditureCost = {
		    "requester": _id,
		    "contract": params.id,
		    "form": "capital-expenditure-cost",
		    "action": "update",
		    "data_change": "change " + JSON.stringify(diff)
		}

		const { successHis, messageHis } = await addHistoryChange(historyChangeCapitalExpenditureCost)
		setShowUpdateCapitalExpenditureCostModal(false)
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
	}

	function findDiff(obj1, obj2) {
	    const diff = {};

	    Object.keys(obj1).forEach((key) => {
	        if (obj1[key] !== obj2[key]) {
	            diff[key] = obj2[key];
	        }
	    });

	    return diff;
	}


	const isPatternNumber = userInput => {
		if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		return /^\d+$/.test(userInput);
	}
	console.log(updatedCapitalExpenditureCost,'updatedCapitalExpenditureCost')
	return (
		<Modal show={showUpdateCapitalExpenditureCostModal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>Cập nhật chi phí vốn ?</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					
					{/* <Form.Group>
						<Form.Text id='ContractID' muted as='h6'>
							Chọn hợp đồng
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Nhập chuỗi'
							name='ContractID'
							value={ContractID}
							onChange={onChangeUpdatedCapitalExpenditureCostForm}
						/>						
					</Form.Group> */}
					
					<Form.Group>
						<Form.Text id='InventoryDays' muted as='h6'>
							Số ngày hàng tồn kho
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='InventoryDays'
							name='InventoryDays'
							value={InventoryDays != null ? InventoryDays.toLocaleString() : 0}
							onChange={onChangeUpdatedCapitalExpenditureCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='ImplementationDays' muted as='h6'>
							Số ngày triển khai
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='ImplementationDays'
							name='ImplementationDays'
							value={ImplementationDays != null ? ImplementationDays.toLocaleString() : 0}
							onChange={onChangeUpdatedCapitalExpenditureCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='BedtDays' muted as='h6'>
							Số ngày công nợ nhà cung cấp
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='BedtDays'
							name='BedtDays'
							value={BedtDays != null ? BedtDays.toLocaleString() : 0}
							onChange={onChangeUpdatedCapitalExpenditureCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='DebtCollectionDays' muted as='h6'>
							Số ngày thu nợ
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='DebtCollectionDays'
							name='DebtCollectionDays'
							value={DebtCollectionDays != null ? DebtCollectionDays.toLocaleString() : 0}
							onChange={onChangeUpdatedCapitalExpenditureCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text muted as='h6'>
							Tỉ lệ lãi vay (%)
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='PercentInterest'
							name='PercentInterest'
							value={PercentInterest != null ? PercentInterest : 0}
							onChange={onChangeUpdatedCapitalExpenditureCostForm}
						/>						
					</Form.Group>
					<Form.Group>
						<Form.Text muted as='h6'>
							Tỉ lệ % khách hàng trả trước
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='PercentDeposits'
							name='PercentDeposits'
							value={PercentDeposits != undefined ? PercentDeposits : 20}
							onChange={onChangeUpdatedCapitalExpenditureCostForm}
						/>						
					</Form.Group>
					<Form.Group>
						<Form.Text muted as='h6'>
							Tỉ lệ % đặt cọc cho NTP
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='PercentDepositsNTP'
							name='PercentDepositsNTP'
							value={PercentDepositsNTP != undefined ? PercentDepositsNTP : 30}
							onChange={onChangeUpdatedCapitalExpenditureCostForm}
						/>						
					</Form.Group>
					<Form.Group>
						<Form.Text id='Note' muted as='h6'>
							Ghi chú
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Note'
							name='Note'
							value={Note}
							onChange={onChangeUpdatedCapitalExpenditureCostForm}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Cập nhật
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}

export default UpdateCapitalExpenditureCostModal
