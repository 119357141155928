import { createContext, useReducer, useState } from 'react'
import { CustomerReducer } from '../reducers/CustomerReducer'
import {
	apiUrl,
	LOADED_FAIL,
	LOADED_SUCCESS,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from './constants'//Note
import axios from 'axios'	

export const ConfigCustomerContext = createContext()

const ConfigCustomerContextProvider = ({ children }) => {
	//State
	const [CustomerState,  dispatch] = useReducer(CustomerReducer, {
		Customer: [],
		Customers: [],
		CustomersLoading: true
	});

	const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
	const [showUpdateCustomerModal, setShowUpdateCustomerModal] = useState(false)
	const [Data_update, setData_update] = useState([])
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	});
	const [showImportCustomerlModal,setshowImportCustomerlModal] = useState(false);

	// add Customer
	const add_Customer = async newCustomer => {
		try {
			const response = await axios.post(`${apiUrl}/api/forms/customer/post/`, newCustomer)
			if (response.data.success) {
				get_All_Customer();
				return response.data 
			}
			else
				return response.data //Message hop dong kg ton tai
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	// Update Customers
	const update_Customer = async Customers => {
		try {
			const response = await axios.put(
				`${apiUrl}/api/forms/customer/put/`, //note xem trong server
				Customers
			)
			if (response.data.success) {
				get_All_Customer()
				return response.data
			}
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	const delete_Customer = async Customers => {
		try {
			try {
			const response = await axios.delete(`${apiUrl}/api/forms/customer/delete/${Customers.id}`)//note
			if (response.data.success){
				// dispatch({ type: DELETE, payload: response.data.deletedProducts })
				get_All_Customer()
				return response.data
			}
		} catch (error) {
			console.log(error)
		}
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	// import Customer
	const import_Customer = async newCustomer => {
		try {
			const response = await axios.post(`${apiUrl}/api/forms/customer/post/`, newCustomer)
			if (response.data.success) {
				return response.data 
			}
			else
				return response.data //Message hop dong kg ton tai
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	const get_All_Customer = async () => {
		try {
			const response = await axios.get(`${apiUrl}/api/forms/customer`)
			if (response.data.success) {
				dispatch({ type: LOADED_SUCCESS, payload: response.data.Customer })
			}
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}


	// User context data
	const ConfigCustomerContextData = {
		CustomerState,
		add_Customer,
		update_Customer,
		delete_Customer,
		import_Customer,
		get_All_Customer,

		showAddCustomerModal,
		setShowAddCustomerModal,
		showUpdateCustomerModal,
		setShowUpdateCustomerModal,
		Data_update,
		setData_update,
		showToast,
		setShowToast,
		showImportCustomerlModal,
		setshowImportCustomerlModal
	}

	return (
		<ConfigCustomerContext.Provider value={ConfigCustomerContextData}>
			{children}
		</ConfigCustomerContext.Provider>
	)
}

export default ConfigCustomerContextProvider
