
import { useContext, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Toast from 'react-bootstrap/Toast'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import diacritics from 'diacritics';

// //Contexts
// import { AuthContext } from '../contexts/AuthContext'
// import { ContractContext } from '../contexts/ContractContext'
// import { ProductCostContext } from '../contexts/ProductCostContext'
// import { MandayCostContext } from '../contexts/MandayCostContext'
// import { GuaranteeLetterCostContext } from '../contexts/GuaranteeLetterCostContext'
// import { MiscExpenseCostContext } from '../contexts/MiscExpenseContext'
// import { CapitalExpenditureCostContext } from "../contexts/CapitalExpenditureCostContext"
// import { AuxiliaryCostContext } from '../contexts/AuxiliaryCostContext'
// import { ImplementationCostContext } from '../contexts/ImplementationCostContext'
// import { LoanInterestContext } from '../contexts/LoanInterestContext'

// //Components
// import { ActionButtons_Contract } from '../components/contract/ActionButtons_Contract'
// import AddContractModal from '../components/contract/AddContractModal'
// import UpdateContractModal from '../components/contract/UpdateContractModal'
//update
// import  {Two_Option_Cost_Modal}  from '../components/CapitalExpenditureCost/Two_Option_Cost_Modal'//Note
// import { TwoOptionCostContext } from '../contexts/TwoOptionCostContext'
// import { ActionButtons_Contract_copy } from '../components/contract/ActionButtons_Contract_copy'

import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import iconViewContract from '../assets/icon-view-contract.svg'
import iconKeyboard from '../assets/icon-keyboard.svg'
import iconBack from '../assets/icon-back.svg'
import iconPlus from '../assets/plus_circle_fill.svg'
//View list các Forms cần nhập


import PDFInvoice from '../components/invoice/export_invoice'
// import PDFViewerComponent from '../components/invoice/view_invoice';

import { AuthContext } from '../contexts/AuthContext'
import { InvoiceContext } from '../contexts/InvoiceContext'
import AddInvoiceModal from '../components/invoice/AddInvoiceModal'//Note
import UpdateInvoicesModal from '../components/invoice/UpdateInvoiceModal'//Note
import ImportInvoiceModal from '../components/invoice/ImportInvoiceModal'//Note
import ActionButtons_Invoice from '../components/invoice/ActionButtons_Invoice'

import { ContractContext } from '../contexts/ContractContext'
import { ConfigCustomerContext } from '../contexts/ConfigCustomerContext'
import { ProductCostContext } from '../contexts/ProductCostContext'

import { UserContext } from '../contexts/UserContext'
import { RoleContext } from '../contexts/RoleContext'
import axios from 'axios';
import { apiUrl } from '../contexts/constants'
// import { fs } from 'fs';

// import iconListContract from '../assets'
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    padding: 20,
  },
  header: {
    marginBottom: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  label: {
    fontWeight: 'bold',
    marginRight: 5,
  },
  value: {
    marginBottom: 10,
  },
});


export const Invoice = () => {
	const {
	    authState: {
	      user: { username ,roles, _id}
	    }
	} = useContext(AuthContext);
	// Contexts
	const params = useParams();

	const {
		InvoiceState: { Invoice, Invoices, InvoicesLoading },
		showAddInvoiceModal,
		setShowAddInvoiceModal,
		setShowImportInvoiceModal,
		getInvoice_ContractID,
		showToast: { show, message, type },
		setShowToast,
		_setInvoice
	} = useContext(InvoiceContext)
	useEffect(() => getInvoice_ContractID(params.id), [])

	const {
		UserState: { User, Users, UsersLoading },
		getUsers
	} = useContext(UserContext)
	useEffect(() => getUsers(), [])

	const {
		RoleState: { Role, Roles, RolesLoading },
		getRoles,
	} = useContext(RoleContext)
	useEffect(() => getRoles(), [])
	let role_new = roles[0];
	let UserID = _id;
	let roleName = FindArray(Roles, role_new).name;

	// let history = useHistory();
	// console.log(params.id,'sdsadsadsa')
	// const [customerName, setCustomerName] = useState('');
	// const [productName, setProductName] = useState('');
	// const [quantity, setQuantity] = useState(1);
	// const [totalAmount, setTotalAmount] = useState(0);

	// const [pdfVisible, setPdfVisible] = useState(false);

	function convert_time(date_time){
		const date = new Date(date_time); // Replace this with your actual date object
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		const seconds = ('0' + date.getSeconds()).slice(-2);
		const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		return formattedDate;
	}
	function getCurrentDate(separator){
	    let newDate = new Date()
	    let date = newDate.getDate();
	    let month = newDate.getMonth() + 1;
	    let year = newDate.getFullYear();
	    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
	}

	// return (
	// 	<>
	// 	{/*<button onClick={() => setPdfVisible(true)}>Preview PDF</button>
	// 	{pdfVisible && (
	// 	  <PDFViewerComponent pdfDocument={<PDFInvoice data={{ customerName, productName, quantity, totalAmount }} />} />
	// 	)}*/}

	// 	{/*<div>
	// 	  {/* ... */}
	// 	  <PDFDownloadLink document={<PDFInvoice data={{ customerName, productName, quantity, totalAmount }} />} fileName="invoice.pdf">
	// 	    {({ blob, url, loading, error }) => (loading ? 'Generating PDF...' : 'Download PDF')}
	// 	  </PDFDownloadLink>
	// 	</div>*/}
	// 	</>
	// )

	const {
		ContractState: {Contracts, ContractsLoading},
		getContract_byid
	} = useContext(ContractContext)

	const {
		CustomerState: { Customer, Customers, CustomersLoading },
		get_All_Customer
	} = useContext(ConfigCustomerContext)
	useEffect(() => get_All_Customer(), [])
	useEffect(() => getContract_byid(params.id), [])

	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	//=== Get ProductCosts 
	const {
		ProductCostState: { ProductCost, ProductCosts, ProductCostsLoading },
		getProductCost_idContract,
		setShowAddProductCostModal
	} = useContext(ProductCostContext)
	useEffect(() => getProductCost_idContract(params.id), [])
	//Ham tinh tong Phan tử trong kieu mang 
	function Sum_OutputIntoMoney(ProductsCost) {
		let Total_OutputIntoMoney = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_OutputIntoMoney += ListProduct.OutputIntoMoney
			)))

		return Total_OutputIntoMoney;
	}
	const TotalOutputIntoMoney = Sum_OutputIntoMoney(ProductCosts)
	let customer_name = '';
	if(Contracts.length > 0){
	   customer_name = FindArray(Customers, Contracts[0].CustomerID) != '' ? FindArray(Customers, Contracts[0].CustomerID).CustomerName : Contracts[0].CustomerID
	}

	function getMonth(dateString){
		let date = dateString.split('-');
		const month = parseInt(date[1]);
		return month;
	}

	function Sum_Invoice(Invoice) {
		let Total_Invoice = 0;
		Invoice.map(Invoices => {
			Total_Invoice += Invoices.InvoiceVal
		});
		return Total_Invoice;
	}
	const Total_Invoice = Sum_Invoice(Invoices);

	function Sum_PercentInvoice(Invoice) {
		let Total_Invoice = 0;
		Invoice.map(Invoices => {
			Total_Invoice += Invoices.PercentInvoice
		});
		return Total_Invoice;
	}
	const Total_PercentInvoice= Sum_PercentInvoice(Invoices);
	let body = null;
	let i = 1;

  const iconListContract = '../upload';

  function convert_date(date_time){
		const date = new Date(date_time); // Replace this with your actual date object
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		const seconds = ('0' + date.getSeconds()).slice(-2);
		const formattedDate = `${year}-${month}-${day}`;
		return formattedDate;
	}

	function getMonthNew(date_time){
		const date = new Date(date_time); // Replace this with your actual date object
		const month = (date.getMonth() + 1);
		const formattedDate = `${month}`;
		return formattedDate;
	}
	async function downLoadFile(apiUrl, Document, id){
		Document = removeVietnameseDiacritics(Document);
		const response = await axios.get(
				`${apiUrl}/files/${Document}`, //note xem trong server
				{responseType: 'arraybuffer'}
			);
		// console.log(response,'=======');
		// Xử lý dữ liệu tệp đã tải xuống
    // const blob = new Blob([response.data]);
    // const url = window.URL.createObjectURL(blob);

    // // Tạo một thẻ <a> để tạo liên kết tải xuống
    // const a = document.createElement('a');
    // a.style.display = 'none';
    // a.href = url;
		// // if (Document.includes(id)) {
		// // 	Document = Document.replace(id+'_','');
		// // }
    // a.download = format_string(Document,id); // Thay bằng tên tệp tải xuống

    // // Thêm thẻ <a> vào DOM và kích hoạt sự kiện click
    // document.body.appendChild(a);
    // a.click();

    // // Giải phóng tài nguyên
    // window.URL.revokeObjectURL(url);
    // document.body.removeChild(a);

      // Tạo Blob từ dữ liệu ArrayBuffer
    const blob = new Blob([response.data], { type: 'application/octet-stream' });

    // Tạo URL từ Blob
    const url = window.URL.createObjectURL(blob);

    // Tạo thẻ a để tạo liên kết tải xuống
    const link = document.createElement('a');
    link.href = url;
    let file_name = format_string(Document,id);
    file_name = encodeURIComponent(file_name); // Mã hoá tên tệp
		file_name = file_name.replace(/%20/g, '_');
    link.setAttribute('download', file_name); // Thay bằng tên tệp tải xuống
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    // Giải phóng tài nguyên
    window.URL.revokeObjectURL(url);
	}

	// const removeVietnameseDiacritics = (str) => {
	// 	let str_arr = str.split("_");
	// 	// let str_new = str_arr[1];
	// 	let str_new = "xin ";
	//   return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '');
	// };
	function removeVietnameseDiacritics(str) {
	  const cleanedStr = diacritics.remove(str);
	  return cleanedStr.replace(/\s/g, ''); // Loại bỏ khoảng trắng
	}

	function format_string(Document,id){
		if (Document.includes(id)) {
			Document = Document.replace(id+'_','');
		}
		return Document;
	}
	function convert_to_array(string){
		return string.split(",");
	}
	function formatPercentage(percentage) {
	  const formatted = percentage.toFixed(2); // Định dạng với 2 chữ số sau dấu phẩy

	  if (formatted.endsWith('.00') || formatted.endsWith('.0')) {
	    return formatted.slice(0, -3); // Cắt bỏ ".00" nếu có
	  }

	  return formatted;
	}

	console.log(Total_PercentInvoice,'Total_PercentInvoiceTotal_PercentInvoice')
	if(roleName == 'KTT'){
		if (InvoicesLoading) {
	    body = (
	      <div className='spinner-container'>
	        <Spinner animation='border' variant='info' />
	      </div>
	    )
	  } else if (Invoices.length === 0) {
	  	body = (
	  		<>
	  			<Card className='text-center mx-5 my-5'>
	          <Card.Header as='h1'>Quản lý xuất hóa đơn</Card.Header>
	          <Card.Body>
	            <Table responsive="lg" striped bordered hover size="lg" variant="" >
	              <thead>
	              	<tr style={{textAlign:'left'}}>
										<th colSpan={16}>Số hợp đồng/PO: {Contracts != '' ? Contracts[0].ContractID : ''}</th>
									</tr>
									<tr style={{textAlign:'left'}}>
										<th colSpan={16}>Khách hàng: {customer_name}</th>
									</tr>
									<tr style={{textAlign:'left'}}>
										<th colSpan={16}>Doanh thu: {TotalOutputIntoMoney.toLocaleString()}</th>
									</tr>
			            <tr style={{textAlign : 'center'}}>
			                <th style={{verticalAlign:'inherit'}}>STT</th>
			                <th style={{verticalAlign:'inherit'}}>Người thực hiện</th>
			                <th style={{verticalAlign:'inherit'}}>Ngày thực hiện</th>
			                <th style={{verticalAlign:'inherit'}}>Tỉ lệ xuất hóa đơn</th>
			                <th style={{verticalAlign:'inherit'}}>Giá trị xuất hóa đơn</th>
			                <th style={{verticalAlign:'inherit'}}>Ngày xuất hóa đơn</th>
			                <th style={{verticalAlign:'inherit'}}>Tháng xuất hóa đơn</th>
			                <th style={{verticalAlign:'inherit'}}>Chứng từ đính kèm</th>
			                <th style={{verticalAlign:'inherit'}}>Thao tác</th>
			            </tr>
				        </thead>
				       	<tbody>
				       		<tr>
										<td colSpan={10}>
											<Button
												variant='primary'
												onClick={Total_PercentInvoice > 99 || TotalOutputIntoMoney == 0 ? setShowAddInvoiceModal.bind(this, false) : setShowAddInvoiceModal.bind(this, true)} //update 03062024
												style={{ cursor: Total_PercentInvoice > 99 || TotalOutputIntoMoney == 0 ? 'not-allowed':'' }} //update 03062024
											>
												Xuất hóa đơn
											</Button>
										</td>
									</tr>
	          		</tbody>
	            </Table>
	          </Card.Body>
	        </Card>
	  		</>
	  	)
	  }else{
			body = (
			<>
			<Card className='text-center mx-5 my-5'>
	            <Card.Header as='h1'>Quản lý xuất hóa đơn</Card.Header>
	            <Card.Body>
		            <Table responsive="lg" striped bordered hover size="lg" variant="" >
		                <thead>
		                	<tr style={{textAlign:'left'}}>
								<th colSpan={16}>Số hợp đồng/PO: {Contracts != '' ? Contracts[0].ContractID : ''}</th>
							</tr>
							<tr style={{textAlign:'left'}}>
								<th colSpan={16}>Khách hàng: {customer_name}</th>
							</tr>
							<tr style={{textAlign:'left'}}>
								<th colSpan={16}>Doanh thu: {TotalOutputIntoMoney.toLocaleString()}</th>
							</tr>
			                <tr style={{textAlign : 'center'}}>
			                    <th style={{verticalAlign:'inherit'}}>STT</th>
			                    <th style={{verticalAlign:'inherit'}}>Người thực hiện</th>
			                    <th style={{verticalAlign:'inherit'}}>Ngày thực hiện</th>
			                    <th style={{verticalAlign:'inherit'}}>Tỉ lệ xuất hóa đơn</th>
			                    <th style={{verticalAlign:'inherit'}}>Giá trị xuất hóa đơn</th>
			                    <th style={{verticalAlign:'inherit'}}>Ngày xuất hóa đơn</th>
			                    <th style={{verticalAlign:'inherit'}}>Tháng xuất hóa đơn</th>
			                    <th style={{verticalAlign:'inherit'}}>Chứng từ đính kèm</th>
			                    <th style={{verticalAlign:'inherit'}}>Thao tác</th>
			                </tr>
		                </thead>
		                <tbody>
		                	{

		                		Invoices.map(Invoice => (
			                	<tr>
				                	<td>{i++}</td>
				                	<td>{FindArray(Users, Invoice.UserID) != '' ? FindArray(Users, Invoice.UserID).name.trim() : Invoice.UserID}</td>
				                	<td>{convert_time(Invoice.CreateDateOfInvoice != null ? Invoice.CreateDateOfInvoice : getCurrentDate("-"))}</td>
				                	<td>{Invoice.InvoiceVal != undefined ? formatPercentage((Invoice.InvoiceVal * 100 / TotalOutputIntoMoney)).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2}) : ''} %</td>
				                	<td>{Invoice.InvoiceVal != undefined ? Invoice.InvoiceVal.toLocaleString() : ''}</td>
				                	<td>{Invoice.DateOfInvoice != null ? convert_date(Invoice.DateOfInvoice) : convert_date(getCurrentDate("-"))}</td>
				                	<td>T{Invoice.DateOfInvoice != null ? getMonthNew(Invoice.DateOfInvoice) : getMonthNew(getCurrentDate("-"))}</td>
				                	<td>
				                		{
				                			Invoice.Document != ''
				                			?
				                				convert_to_array(Invoice.Document).map(InvoiceNew => (
				                					<button title="Tải File Xuống" style={{border:"none",background: "none",color:"blue",textDecoration:"underline"}} onClick={() => downLoadFile(apiUrl, InvoiceNew,Invoice._id)}>{format_string(InvoiceNew,Invoice._id)}</button>
				                				))			                				
				                			:
				                			<>
				                				<div>
						                			<a className="me-2 mb-2" onClick={() => {setShowImportInvoiceModal(true);_setInvoice(Invoice._id);}}>
						                				<img title="Tải File Lên" src={iconPlus}  width="20" height="20"></img>
						                			</a>
						                		</div>
				                			</>
				                		}
				                	</td>
				                	<td>
					                	<ActionButtons_Invoice 
					                		id={Invoice._id}
					                		ContractID={Invoice.contract}
					                		DateOfInvoice={Invoice.DateOfInvoice != null ? convert_date(Invoice.DateOfInvoice) : convert_date(getCurrentDate("-"))}
					                		MonthOfInvoice={Invoice.MonthOfInvoice}
					                		PercentInvoice={Invoice.PercentInvoice}
					                		InvoiceVal={Invoice.InvoiceVal}
					                		Document={Invoice.Document}
					                		UserID={FindArray(Users, Invoice.UserID) != '' ? FindArray(Users, Invoice.UserID).name.trim() : Invoice.UserID}
					                		CreateDateOfInvoice={convert_time(Invoice.CreateDateOfInvoice != null ? Invoice.CreateDateOfInvoice : getCurrentDate("-"))}
					                		checkBoxPercentInvoice={(Invoice.PercentInvoice != 0) ? true: false}
					                		checkBoxCost={(Invoice.PercentInvoice != 0) ? false: true}
					                	/>
				                	</td>
				                </tr>
				                ))
				            }
				            	<tr>
												<td colSpan={3} >Tổng</td>
												<td>{formatPercentage((Total_Invoice * 100 / TotalOutputIntoMoney)).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})} %</td>
												<td>{Total_Invoice.toLocaleString()}</td>
												<td colSpan={5}></td>
											</tr>
			                <tr>
												<td colSpan={10}>
													<Button
														variant='primary'
														onClick={Total_Invoice < TotalOutputIntoMoney ? setShowAddInvoiceModal.bind(this, true) : setShowAddInvoiceModal.bind(this, false)}
														style={{ cursor: Total_Invoice < TotalOutputIntoMoney ? '':'not-allowed'}}
													>
														Xuất hóa đơn
													</Button>
												</td>
											</tr>
		                </tbody>
		            </Table>
	            </Card.Body>
	            </Card>
	        </>
		)
		}
	}else{
		body = (
			<div class="mt-5 row" style={{marginRight:"0px"}}>
				<div class="text-center col">
					<h3 class="headerAbout">User không có quyền vào trang quản lý xuất hóa đơn</h3>
				</div>
			</div>
		)
	}

	return (
    	<>
    	{body}
    	<AddInvoiceModal />
    	<UpdateInvoicesModal />
    	<ImportInvoiceModal />
    	<Toast
				show={show}
				style={{ position: 'fixed', top: '20%', right: '10px', zIndex: '99999'}}
				className={`bg-${type} text-white`}
				onClose={setShowToast.bind(this, {
					show: false,
					message: '',
					type: null
				})}
				delay={3000}
				autohide
			>
				<Toast.Body>
					<strong>{message}</strong>
				</Toast.Body>
			</Toast>
    	</>
    )

}

