import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { useParams } from 'react-router-dom'
import { useContext, useState, useEffect} from 'react'
import { MandayCostContext } from '../../contexts/MandayCostContext'
import { ConfigDepartmentContext } from '../../contexts/ConfigDepartmentContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'
import { AuthContext } from '../../contexts/AuthContext'

const AddMandayCostModal = () => {
	// Contexts MandayCostContext
	const {
		showAddMandayCostModal,
		setShowAddMandayCostModal,
		addMandayCost,
		setShowToast,
		_radioUSD,
	} = useContext(MandayCostContext)

	const {
		DepartmentState: { Department, Departments, DepartmentsLoading },
		getDepartment
	} = useContext(ConfigDepartmentContext)

	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)
	
	// State
	const [newMandayCost, setNewMandayCost] = useState({
		RatioUSD: '',
		DepartmentID: '',
		Cost: '',
		StaffNumber: '',
		ImplementationDay: '',
		Note: '',
		IntoMoney: ''
	}) //note là các biến trong 

	const {
		RatioUSD,
		DepartmentID,
		Cost,
		StaffNumber,
		ImplementationDay,
		Note,
		IntoMoney
	} = newMandayCost //note
	//load idcontract
	const params = useParams();
	useEffect(() => getDepartment(2), [])
	newMandayCost.ContractID = params.id;
	newMandayCost.RatioUSD = _radioUSD;
	//===== Get RaitoUSD ====
	// Contexts
	/* const {
		ContractState: { Contracts },
		getContract_byid
	} = useContext(ContractContext)
	
	// Start: Get all Contracts by id contract
	useEffect(() => getContract_byid(params.id), [])
	console.log("Contract===",Contracts)
	newMandayCost.RatioUSD = Contracts[0].RatioUSD */
	//===== Get RaitoUSD ====

	const [inputMoneyDirectly, setInputMoneyDirectly] = useState(false);
	const [showHide, setshowHide] = useState(false);

	const onChangeNewMandayCostForm = event =>{
		let value = event.target.value;
		if(event.target.name != 'DepartmentID' && event.target.name != 'Note'){
			if (!isPatternNumber(value) &&  value != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				setNewMandayCost({ ...newMandayCost, [event.target.name]: event.target.value })
			}
		}else{
			if(event.target.name == 'DepartmentID'){
				let MandayCost = FindArray(Departments, value).MandayCost;
				if(MandayCost != null){
					setNewMandayCost({ ...newMandayCost, Cost: MandayCost , DepartmentID: value})
				}else{
					setNewMandayCost({ ...newMandayCost, Cost: '',DepartmentID: value })
				}
			}else{
				setNewMandayCost({ ...newMandayCost, [event.target.name]: event.target.value })
			}
		}
	}

	const toggleInputMoneyDirectly = event => {
		setInputMoneyDirectly(event)
		setshowHide(event)
	};

	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	const closeDialog = () => {
		resetAddMandayCostData()
	}

	const onSubmit = async event => {
		event.preventDefault()
		if (newMandayCost.IntoMoney != "") {
			newMandayCost.ImplementationDay = ''
			newMandayCost.StaffNumber = ''
		}

		const historyChangeProductCost = {
		    "requester": _id,
		    "contract": params.id,
		    "form": "manday-cost",
		    "action": "create",
		    "data_change": "create new manday"
		}
		const { success, message } = await addMandayCost(newMandayCost)
		const { successHis, messageHis } = await addHistoryChange(historyChangeProductCost)
		resetAddMandayCostData()
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
	}

	const isPatternNumber = userInput => {
  		return /^\d+$/.test(userInput);
	}

	// const isParentNumber = userInput => {
	// 	if (typeof userInput !== 'string') return false;
	// 	return /^\d+$/.test(userInput);
	// }


	const resetAddMandayCostData = () => {
		setNewMandayCost({
			RatioUSD: '',
			DepartmentID: '',
			Cost: '',
			StaffNumber: '',
			ImplementationDay: '',
			IntoMoney: '',
			Note: ''
		}) //note cần sửa
		setShowAddMandayCostModal(false)
		setInputMoneyDirectly(false)
		setshowHide(false)
	}

	return (
		<Modal show={showAddMandayCostModal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title as='h6'>Bạn muốn thêm Manday kỹ sư?</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>

					<Form.Group>
						<Form.Text id='ContentCost-help' muted as="h6">
							Phòng ban
						</Form.Text>
						<Form.Control
							id = 'noidung-help'
							as="select"
							name='DepartmentID'
							aria-describedby='noidung-help'
							onChange={onChangeNewMandayCostForm}>
							<option value=''>-----</option>
							{
								typeof Departments != "undefined" ? 
								Departments.map((obj => {
									return (
										<>
											<option value={obj._id}>{obj.DepartmentName}</option>
										</>
									)
								}))
								:
								<></>
							}
							
					    </Form.Control>
					</Form.Group>

					<Form.Group>
						<Form.Check
							type='checkbox'
							name='inputMoneyDirectly'
							checked={inputMoneyDirectly}
							value={inputMoneyDirectly}
							onChange={(e) => toggleInputMoneyDirectly(e.target.checked)}
							label="Nhập số tiền trực tiếp"
						/>
					</Form.Group>
					
					{
						showHide ?
						<>
							<Form.Group>
								<Form.Text id='title-help' muted as='h6'>
									Số tiền
								</Form.Text>
								<Form.Control
									type='text'
									placeholder='Nhập số'
									name='IntoMoney'
									required
									aria-describedby='IntoMoney-help'
									value={IntoMoney}
									onChange={onChangeNewMandayCostForm}
								/>
							</Form.Group>
						</>
						:
						<>
							<Form.Group>
								<Form.Text id='title-help' muted as='h6'>
									Manday chuẩn
								</Form.Text>
								<Form.Control
									type='text'
									placeholder='Nhập số'
									name='Cost'
									required
									aria-describedby='Cost-help'
									value={Cost}
									onChange={onChangeNewMandayCostForm}
								/>
							</Form.Group>

							<Form.Group>
								<Form.Text id='title-help' muted as='h6'>
									Số người tham gia dự án
								</Form.Text>
								<Form.Control
									type='text'
									placeholder='Nhập số'
									name='StaffNumber'
									required
									aria-describedby='songuoi-help'
									value={StaffNumber}
									onChange={onChangeNewMandayCostForm}
								/>
							</Form.Group>

							<Form.Group>
								<Form.Text id='title-help' muted as='h6'>
									Nhập số ngày thực hiện
								</Form.Text>
								<Form.Control
									type='text'
									placeholder='Nhập số'
									name='ImplementationDay'
									required
									aria-describedby='songaythuchien-help'
									value={ImplementationDay}
									onChange={onChangeNewMandayCostForm}
								/>
							</Form.Group>
						</>
					}

					<Form.Group>
						<Form.Text id='title-help' muted as='h6'>
							Ghi chú
						</Form.Text>
						<Form.Control
							as='textarea'
							rows={2}
							placeholder=''
							name='Note'
							aria-describedby='ghichu-help'
							value={Note}
							onChange={onChangeNewMandayCostForm}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Thêm
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}

export default AddMandayCostModal
