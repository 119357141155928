import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import { useParams, Link } from 'react-router-dom'
import { useContext, useEffect, useState} from 'react'
import Spinner from 'react-bootstrap/Spinner'

import { AuthContext } from '../contexts/AuthContext'
import { UserContext } from '../contexts/UserContext'
import { UserPageContext } from '../contexts/UserPageContext'
import { MenuContext } from '../contexts/MenuContext'
import { UserManagerContext } from '../contexts/UserManagerContext'

const About = () => {
	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)

	let UserID = _id;
				// <div style={{marginTop:'20px'}}>
				// 	QLHĐ cung cấp một nền tảng tổ chức, quản lý và theo dõi hợp đồng hiệu quả, giúp tăng cường hiệu suất làm việc, giảm thiểu rủi ro pháp lý và cải thiện quy trình quản lý hợp đồng.
				// </div>
	const {
		UserPageState: { UserPage, UserPages, UserPagesLoading },
		getAllUserPages
	} = useContext(UserPageContext)

	const {
	    MenuState: { Menu, Menus, MenusLoading },
	    getMenu,
	} = useContext(MenuContext);

	function FindArrayMenu(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return null;
		}
	}

	if (UserPagesLoading && MenusLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	}else {
		const result = UserPages.filter(obj => { return obj.user_id.some(id => id === UserID); });
		let menu_access = false
		if (result.length > 0) {
			for (var j = 0; j < result.length; j++) {
				let menuV1 = FindArrayMenu(Menus, result[j].page_id[0])
				if (menuV1 != null && menuV1.path == location.pathname) {
					// console.debug("menuV1 co quyen truy cap")
					menu_access = true
					break;
				}else {
					// console.debug("menuV1 khong quyen truy cap")
				}
			}
		} else {
			// console.debug("menu khong quyen truy cap")
		}

		if (menu_access) {
			return (
				<Row style={{ marginRight: 0 }}>
					<Col className='text-center'>
						<Card className='text-center mx-5 my-3'>
							<Card.Header as='h5' className='headerAbout'>Tổng quan</Card.Header>
							<Card.Body>
								<Card.Title>
									<h3 className='fontSize' style={{color:'#007bff','textAlign':'left'}}>Phần mềm Quản lý Hợp Đồng (QLHĐ)</h3>
									<p className='textAlignLeft fontSize'>Được thiết kế để giúp công ty quản lý và theo dõi hợp đồng một cách hiệu quả. QLHĐ cung cấp một hệ thống tổ chức thông tin hợp đồng, theo dõi thời hạn và tiến độ, quản lý tài liệu liên quan và tự động hóa quy trình quản lý hợp đồng.</p>
									<p className='textAlignLeft fontSize'>Cung cấp một nền tảng tổ chức, quản lý và theo dõi hợp đồng hiệu quả, giúp tăng cường hiệu suất làm việc, giảm thiểu rủi ro pháp lý và cải thiện quy trình quản lý hợp đồng.</p>
								</Card.Title>
							</Card.Body>
						</Card>
						<Card className='text-center mx-5 my-4'>
							<Card.Header as='h5' className='headerAbout'>Tính năng</Card.Header>
							<Card.Body>
								<Card.Title>
									<h3 className='fontSize' style={{color:'#007bff','textAlign':'left'}}>QLHĐ</h3>
									<p className='textAlignLeft fontSize'>Hệ thống cho phép lưu trữ và tổ chức thông tin liên quan đến hợp đồng, bao gồm các bên tham gia, điều khoản và điều kiện, ngày hết hạn, và thông tin liên quan khác.</p>
									<h3 className='fontSize' style={{color:'#007bff','textAlign':'left'}}>Quản lý thời hạn và tiến độ</h3>
									<p className='textAlignLeft fontSize'>Giúp theo dõi thời gian còn lại trên hợp đồng và đảm bảo tuân thủ các khâu, quy trình trong quá trình thực hiện hợp đồng, ngoài ra QLHĐ cung cấp cảnh báo cho những công việc gần đến hạn hoặc quá hạn.</p>
									<h3 className='fontSize' style={{color:'#007bff','textAlign':'left'}}>Quản lý tài liệu</h3>
									<p className='textAlignLeft fontSize'>Hệ thống cho phép tải lên, lưu trữ và quản lý tài liệu liên quan đến hợp đồng, bao gồm các phiên bản, biên bản họp, văn bản pháp lý và các tài liệu khác.</p>
									<h3 className='fontSize' style={{color:'#007bff','textAlign':'left'}}>Tự động hóa quy trình</h3>
									<p className='textAlignLeft fontSize'>Cung cấp khả năng tự động hóa các quy trình quản lý hợp đồng, giúp giảm thiểu công việc thủ công và tối ưu hóa hiệu suất làm việc, bao gồm thông báo, phê duyệt, báo cáo và quản lý luồng công việc.</p>
								</Card.Title>
							</Card.Body>
						</Card>
						<Card className='text-center mx-5 my-3'>
							<Card.Header as='h5' className='headerAbout'>Lợi ích</Card.Header>
							<Card.Body>
								<Card.Title>
									<h3 className='fontSize' style={{color:'#007bff','textAlign':'left'}}>Tăng hiệu suất làm việc</h3>
									<p className='textAlignLeft fontSize'>Giúp việc quản lý và thực hiện hợp đồng một cách nhanh chóng và hiệu quả hơn, giảm thiểu sự nhầm lẫn và lỗ hỏng thông tin.</p>
									<h3 className='fontSize' style={{color:'#007bff','textAlign':'left'}}>Giảm rủi ro pháp lý</h3>
									<p className='textAlignLeft fontSize'>Bằng cách theo dõi và tuân thủ các quy trình hợp đồng, giúp giảm thiểu rủi ro pháp lý và xử lý các vấn đề liên quan đến vi phạm hợp đồng.</p>
									<h3 className='fontSize' style={{color:'#007bff','textAlign':'left'}}>Nâng cao khả năng đàm phán</h3>
									<p className='textAlignLeft fontSize'>Cung cấp thông tin dễ dàng truy cập về các điều khoản và điều kiện của hợp đồng, giúp tăng cường khả năng đàm phán và thương lượng.</p>
									<h3 className='fontSize' style={{color:'#007bff','textAlign':'left'}}>Tối ưu hóa quản lý tài chính</h3>
									<p className='textAlignLeft fontSize'>Theo dõi và giám sát các giao dịch, thanh toán, và các khoản chi phí liên quan đến hợp đồng, giúp công ty tối ưu hóa quản lý tài chính.</p>
								</Card.Title>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			)
		}else {
			return (
				<>
					<div id="notfound">
						<div className="notfound">
							<h1>Oops!</h1>
							<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
								<br/>
								<Link style={{textDecoration: "none"}} to="/home">
									Trở về trang chủ
								</Link>
							</h6>
						</div>
					</div>
				</>
			)
		}
	}
}

export default About
