import Button from 'react-bootstrap/Button'
import editIcon from '../../assets/pencil.svg'
import deleteIcon from '../../assets/trash.svg'
import { UserContext } from '../../contexts/UserContext'
import { ContractContext } from '../../contexts/ContractContext'

import { useContext, useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


const ActionButtons_User = ({ _id, _userInfo}) => {
	// console.log("_userInfo", _userInfo)
	const {
		UserState: { User, Users },
		deleteUser,
		findUser,
		setShowUpdateUserModal,
		setShowToast
	} = useContext(
		UserContext
	) //goi ActionButtons cho component khác đc kg ???
	
	const {
		ContractState: { Contract, Contracts, ContractsLoading },
		getContracts
	} = useContext(ContractContext)
	useEffect(() => getContracts(), [])

	const chooseUser = UserId => {
		findUser(UserId)
		setShowUpdateUserModal(true)
	}

	function submit () {
		var userExitsContact = Contracts.find(x => x.user._id === _id);
		const userAssign = getUserAssign(Users);
		const userExitsUserManager = userAssign.includes(_id);

		if (userExitsContact != undefined) {
			setShowToast({ show: true, message: "Cảnh báo! Không thể xóa vì người đùng đã liên kết với hợp đồng.", type: 'warning' })
			return false;
		}

		if (userExitsUserManager) {
			setShowToast({ show: true, message: "Cảnh báo! Vui lòng xóa người đúng ra khỏi quản lý trước khi xóa.", type: 'warning' })
			return false;
		}

		confirmAlert({
			title: '',
			message: 'Xoá người dùng',
			buttons: [
				{
					label: 'Có',
					onClick: () => deleteUser(_id)
				},
				{
					label: 'Không',
					onClick: () => closeDialog()
				}
			]
		});
	};

	function getUserAssign(data) {
		var userAssigns = [];
		if (data.length>0) {
			data.map(function (e, i){
				if (e.manager_details.length > 0) {
					e.manager_details.map(function (em, im) {
						userAssigns.push(em.user_id[0]);
					})
				}
			})
		}
		return userAssigns;
	}
	
	const closeDialog = () => {
		setShowUpdateUserModal(false)
	}

	return (
		<>
			<Button className='post-button' onClick={chooseUser.bind(this, _id)}>
				<img src={editIcon} alt='edit' width='24' height='24' />
			</Button>
			<Button className='post-button' onClick={submit}>
				<img src={deleteIcon} alt='delete' width='24' height='24' />
			</Button>
		</>
	)
	// deleteUser.bind(this, _id)
}

export default ActionButtons_User
