import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useContext, useState } from 'react'
import { ConfigCenterContext } from '../../../contexts/ConfigCenterContext'
import { useParams } from 'react-router-dom'

const AddCenterModal = () => {
	// Contexts 
	const {
		showAddCenterModal,
		setShowAddCenterModal,
		add_Center,
		setShowToast,
	} = useContext(ConfigCenterContext)

	// State
	const [newCenter, setNewCenter] = useState({
		CenterName: '',
	})

	let {
		CenterName,
	} = newCenter

	const params = useParams();
	const onChangeNewCenterForm = event =>{
		let value = event.target.value;
		setNewCenter({ ...newCenter, [event.target.name]: event.target.value });
	}

	const closeDialog = () => {
		resetAddCenterData()
	}

	const onSubmit = async event => {
		event.preventDefault()
		const { success, message } = await add_Center(newCenter)
		resetAddCenterData()
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
	}

	const resetAddCenterData = () => {
		setNewCenter({
			CenterName: '',
			TaxCode: '',
		}) //note cần sửa
		setShowAddCenterModal(false)
	}


	const isPatternNumber = userInput => {
		if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		return /^\d+(\.\d{0,2})?$/.test(userInput);
	}
	return (

		<Modal animation={false} show={showAddCenterModal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>Thêm trung tâm</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<Form.Group>
						<Form.Text id='tenkh-help' muted as='h6'>
							Tên trung tâm
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Tên trung tâm'
							name='CenterName'
							required
							value={CenterName}
							onChange={onChangeNewCenterForm}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Thêm
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}

export default AddCenterModal