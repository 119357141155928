import { useContext, useEffect, useState} from 'react'
// import DataTable , { defaultThemes } from "react-data-table-component";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useParams, Link } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Toast from 'react-bootstrap/Toast'
// import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import axios from "axios";

import { Tables } from '../components/layout/TablesCustomer'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { AuthContext } from '../contexts/AuthContext'
import { ConfigCustomerContext } from '../contexts/ConfigCustomerContext'
import { UserContext } from '../contexts/UserContext'
import { UserPageContext } from '../contexts/UserPageContext'
import { MenuContext } from '../contexts/MenuContext'
import { ContractContext } from '../contexts/ContractContext'
import { RoleContext } from '../contexts/RoleContext'
import { UserManagerContext } from '../contexts/UserManagerContext'
import importExcel from "../assets/icon-upload.svg";
import downLoadExcel from "../assets/icon-download.svg";
import urlfileExcelCustomer from "./../assets/excel/Import_Customer_Template.xlsx";

import AddCustomerModal from '../components/Config/Customer/AddCustomerModal'//Note
import UpdateCustomerModal from '../components/Config/Customer/UpdateCustomerModal'//Note
import ImportCustomerlModal from '../components/Config/Customer/ImportCustomerlModal'
import ActionButtons_Customer from '../components/Config/Customer/ActionButtons_Customer'

const ConfigCustomer = () => {
	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)

	const params = useParams();
	const {
		CustomerState: { Customer, Customers, CustomersLoading },
		showAddCustomerModal,
		setShowAddCustomerModal,
		setshowImportCustomerlModal,
		get_All_Customer,
		showToast: { show, message, type },
		setShowToast
	} = useContext(ConfigCustomerContext)
	let UserID = _id;
	useEffect(() => get_All_Customer(), [])
	let body = null;
	let i = 1;
	

	const downloadFile = () => {
        const fileUrl = urlfileExcelCustomer; // Đường dẫn tới file cần tải xuống
        axios
            .get(fileUrl, { responseType: "blob" })
            .then((response) => {
                const blob = new Blob([response.data], { type: response.headers["content-type"] });
                // Lấy tên file từ header response hoặc tùy chỉnh tên file
                let fileName = response.headers["content-disposition"];
                if (!fileName) {
                    fileName = "Import_Customer_Template.xlsx"; // Tên file mặc định nếu không có thông tin từ header
                } else {
                    fileName = fileName.split(";")[1].trim().split("=")[1];
                }
                // Tải xuống file
                saveAs(blob, fileName);
            })
            .catch((error) => {
                console.error("Lỗi khi tải file:", error);
            });
    };

	if (CustomersLoading) {
		body = (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	} else if (Customers.length === 0) {
		body = (
			<>
		        <Card className='text-center mx-5 my-5'>
		            <Card.Header as='h1'>Danh sách khách hàng</Card.Header>
		            <Card.Body>
		            	{/*<Button
		            		style={{float: 'right', bottom: '10px', position: 'relative'}}
							variant='primary'
							onClick={() => { setshowImportCustomerlModal(true)}}
						>
							Nhập file excel
						</Button>*/}
						<OverlayTrigger overlay={<Tooltip id="tooltip">Nhập file excel</Tooltip>}>
                            <a
								style={{float: 'right', bottom: '0px', position: 'relative'}}
                                onClick={() => { setshowImportCustomerlModal(true);}}
                            >
                                <img src={importExcel} width="36" height="36"></img>
                            </a>
                        </OverlayTrigger>

		            	<OverlayTrigger overlay={<Tooltip id="tooltip">Tải file excel</Tooltip>}>
                            <a 
                            	style={{float: 'right', bottom: '0px', position: 'relative'}}
                            	onClick={downloadFile}>
                                <img src={downLoadExcel} width="36" height="36"></img>
                            </a>
                        </OverlayTrigger>


			            <Table responsive="lg" striped bordered hover size="lg" variant="" >
			                <thead>
				                <tr style={{textAlign : 'center'}}>
				                    <th style={{verticalAlign:'inherit'}}>STT</th>
				                    <th style={{verticalAlign:'inherit'}}>Tên khách hàng</th>
				                    <th style={{verticalAlign:'inherit'}}>Mã số thuế</th>
				                    <th style={{verticalAlign:'inherit'}}>Thao tác</th>
				                </tr>
			                </thead>
			                <tfoot>
				                <tr>
									<td colSpan={4}>
										<Button
											variant='primary'
											onClick={setShowAddCustomerModal.bind(this, true)}
										>
											Thêm khách hàng
										</Button>
									</td>
								</tr>
			                </tfoot>
			            </Table>
			            <a href={`/summary`}>
			              <Button
			                variant='primary'
			              >
			                Quay lại
			              </Button>
			            </a>
		            </Card.Body>
		        </Card>
		    </>
		)
	}else {
		const newCustomers = Customers.map((v, i) => ({
			...v,
			STT: parseInt(i+1),
			Action: <ActionButtons_Customer id={v._id}
        		CustomerName={v.CustomerName}
        		TaxCode={v.TaxCode}/>
		}))
	
		const columns = [
		    { accessor: "STT", label: "STT", isSearch: false, isSort: false},
		    { accessor: "CustomerName", label: "Tên khách hàng", isSearch: true, isSort: true},
		    { accessor: "TaxCode", label: "Mã số thuế", isSearch: true, isSort: true},
		    { accessor: "Action", label: "Thao tác", isSearch: false, isSort: false},
		    { accessor: "ActionFilter", label: "Lọc", isHidden: true, isSearch: false, isSelect: true, isSort: false, width: "15%", filter:1},
		];
		const rows = newCustomers


		body = (
			<>
		        <Card className='text-center mx-5 my-5'>
		            <Card.Header as='h1'>Danh sách khách hàng</Card.Header>
		            <Card.Body>
		            	{/*<Button
		            		style={{float: 'right', bottom: '0px', position: 'relative'}}
							variant='primary'
							onClick={() => { setshowImportCustomerlModal(true)}}
						>
							<img src={importExcel} width="36" height="36"></img>
						</Button>*/}

						<OverlayTrigger overlay={<Tooltip id="tooltip">Nhập file excel</Tooltip>}>
                            <a
								style={{float: 'right', bottom: '0px', position: 'relative'}}
                                onClick={() => { setshowImportCustomerlModal(true);}}
                            >
                                <img src={importExcel} width="36" height="36"></img>
                            </a>
                        </OverlayTrigger>

						<OverlayTrigger overlay={<Tooltip id="tooltip">Tải file excel</Tooltip>}>
                            <a 
                            	style={{float: 'right', bottom: '0px', right: '35px', position: 'relative'}}
                            	onClick={downloadFile}>
                                <img src={downLoadExcel} width="36" height="36"></img>
                            </a>
                        </OverlayTrigger>


			          	{/*<Table responsive="lg" striped bordered hover size="lg" variant="" >
			                <thead>
				                <tr style={{textAlign : 'center'}}>
				                    <th style={{verticalAlign:'inherit'}}>STT</th>
				                    <th style={{verticalAlign:'inherit'}}>Tên khách hàng</th>
				                    <th style={{verticalAlign:'inherit'}}>Mã số thuế</th>
				                    <th style={{verticalAlign:'inherit'}}>Thao tác</th>
				                </tr>
			                </thead>
			                <tbody>
			                	{
			                		Customers.map(Customer => (
			                			<>
						                	<tr key={Customer._id}>
							                	<td>{i++}</td>
							                	<td>{Customer.CustomerName}</td>
							                	<td>{Customer.TaxCode}</td>
							                	<td>
							                		<ActionButtons_Customer 
								                		id={Customer._id}
								                		CustomerName={Customer.CustomerName}
								                		TaxCode={Customer.TaxCode}
								                	/>
							                	</td>
							                </tr>
						                </>
					                ))
					            }
					        </tbody>
			            </Table>*/}
		            <Tables rows={rows} columns={columns} />
{/*
		            <DataTable
		            	className="bordereTable"
						data={newCustomers}
						columns={columns}
						pagination
						customStyles={customStyles}
						highlightOnHover
						dense
					/>*/}

			            <Button
			            	style={{top: '20px', position: 'relative'}}
							variant='primary'
							onClick={setShowAddCustomerModal.bind(this, true)}
						>
							Thêm khách hàng
						</Button>
						<br/>
						<br/>
			            <a href={`/summary`}>
			              <Button
			                variant='primary'
			              >
			                Quay lại
			              </Button>
			            </a>
		            </Card.Body>
		        </Card>
		    </>
		)
	}

	const {
		UserPageState: { UserPage, UserPages, UserPagesLoading },
		getAllUserPages
	} = useContext(UserPageContext)

	const {
	    MenuState: { Menu, Menus, MenusLoading },
	    getMenu,
	} = useContext(MenuContext);

	function FindArrayMenu(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return null;
		}
	}

	if (UserPagesLoading && MenusLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	}else {
		const result = UserPages.filter(obj => { return obj.user_id.some(id => id === UserID); });
		let menu_access = false
		if (result.length > 0) {
			for (var j = 0; j < result.length; j++) {
				let menuV1 = FindArrayMenu(Menus, result[j].page_id[0])
				if (menuV1 != null && menuV1.path == location.pathname) {
					// console.debug("menuV1 co quyen truy cap")
					menu_access = true
					break;
				}else {
					// console.debug("menuV1 khong quyen truy cap")
				}
			}
		} else {
			// console.debug("menu khong quyen truy cap")
		}

		if (menu_access) {
			return (
		    	<>
		    	{body}
		    	<AddCustomerModal />
		    	<UpdateCustomerModal />
		    	<ImportCustomerlModal />
		    	<Toast
						show={show}
						style={{ position: 'fixed', top: '20%', right: '10px' }}
						className={`bg-${type} text-white`}
						onClose={setShowToast.bind(this, {
							show: false,
							message: '',
							type: null
						})}
						delay={3000}
						autohide
					>
						<Toast.Body>
							<strong>{message}</strong>
						</Toast.Body>
					</Toast>
		    	</>
		    )
		}else {
			return (
				<>
					<div id="notfound">
						<div className="notfound">
							<h1>Oops!</h1>
							<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
								<br/>
								<Link style={{textDecoration: "none"}} to="/home">
									Trở về trang chủ
								</Link>
							</h6>
						</div>
					</div>
				</>
			)
		}
	}
}

export default ConfigCustomer

