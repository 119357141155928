import {
	LOADED_SUCCESS,
	LOADED_FAIL,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from '../contexts/constants'

export const CenterReducer = (state, action) => {
	const { type, payload } = action
	switch (type) {
		case LOADED_SUCCESS:
			return {
				...state,
				Centers: payload,
				CentersLoading: false
			}

		case LOADED_FAIL:
			return {
				...state,
				Centers: [],
				CentersLoading: false
			}

		case ADD:
			return {
				...state,
				Centers: [...state.Centers, payload]
			}

		case DELETE:
			return {
				...state,
				Centers: state.Centers.filter(Center => Center._id !== payload)
			}

		case FIND:
			return { ...state, Center: payload }

		case UPDATE:
			
			const newCenters = state.Centers.map(Center =>
				Center._id === payload._id ? payload : Center
			)

			return {
				...state,
				Centers: newCenters
			}

		default:
			return state
	}
}
