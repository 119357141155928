import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { MandayCostContext } from '../../contexts/MandayCostContext'
import { ConfigDepartmentContext } from '../../contexts/ConfigDepartmentContext'
import { AuthContext } from '../../contexts/AuthContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'

const UpdateMandayCostModal = () => {
	const params = useParams();
	
	// Contexts
	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	const {
		MandayCostState: { MandayCost },
		showUpdateMandayCostModal,
		setShowUpdateMandayCostModal,
		updateMandayCost,
		setShowToast
	} = useContext(MandayCostContext)

	const {
		DepartmentState: { Department, Departments, DepartmentsLoading },
		getDepartment
	} = useContext(ConfigDepartmentContext)

	// State
	const [updatedMandayCost, setUpdatedMandayCost] = useState(MandayCost)
	useEffect(() => setUpdatedMandayCost(MandayCost), [MandayCost])
	useEffect(() => getDepartment(2), [])
	const [beforeMandayCost, setBeforeMandayCost] = useState(MandayCost)
	useEffect(() => setBeforeMandayCost(MandayCost),[MandayCost])
	// console.log("MandayCost", MandayCost)

	const { RatioUSD,
        DepartmentID,
        Cost,
        StaffNumber,
        ImplementationDay,
        Note,
		IntoMoney
	} = updatedMandayCost //note
	
	const [inputMoneyDirectly, setInputMoneyDirectly] = useState(false);
	const [showHide, setshowHide] = useState(false);
	useEffect(() => {
		let _setTrueFalse = MandayCost.StaffNumber == null && MandayCost.ImplementationDay == null && MandayCost.IntoMoney >= 0 ? true : false
		setInputMoneyDirectly(_setTrueFalse)
		setshowHide(_setTrueFalse)
	}
	,[MandayCost])

	console.debug({inputMoneyDirectly, showHide}, 11111111)

	const onChangeUpdatedMandayCostForm = event =>{
		let value = event.target.value;
		if(event.target.name != 'DepartmentID' && event.target.name != 'Note'){
			if (!isPatternNumber(value) &&  value != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				setUpdatedMandayCost({ ...updatedMandayCost, [event.target.name]: event.target.value })
			}
		}else{
			if(event.target.name == 'DepartmentID'){
				let MandayCost = FindArray(Departments, value).MandayCost;
				if(MandayCost != null){
					setUpdatedMandayCost({ ...updatedMandayCost, Cost: MandayCost, DepartmentID: value })
				}else{
					setUpdatedMandayCost({ ...updatedMandayCost, Cost: '',  DepartmentID: value })
				}
			}else{
				setUpdatedMandayCost({ ...updatedMandayCost, [event.target.name]: event.target.value })
			}
		}
	}

	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	const closeDialog = () => {
		setUpdatedMandayCost(MandayCost)
		setShowUpdateMandayCostModal(false)
		setInputMoneyDirectly(false)
		setshowHide(false)
	}

	const onSubmit = async event => {
		event.preventDefault()
		if (inputMoneyDirectly) {
			updatedMandayCost.ImplementationDay = null
			updatedMandayCost.StaffNumber = null
		}else {
			updatedMandayCost.IntoMoney = 0
		}
		// console.debug(updatedMandayCost)
		const { success, message } = await updateMandayCost(updatedMandayCost)
		const diff = findDiff(beforeMandayCost, updatedMandayCost);
		const historyChangeMandayCost = {
		    "requester": _id,
		    "contract": params.id,
		    "form": "manday-cost",
		    "action": "update",
		    "data_change": "change " + JSON.stringify(diff)
		}

		const { successHis, messageHis } = await addHistoryChange(historyChangeMandayCost)
		setShowUpdateMandayCostModal(false)
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
		closeDialog()
	}

	function findDiff(obj1, obj2) {
	    const diff = {};

	    Object.keys(obj1).forEach((key) => {
	        if (obj1[key] !== obj2[key]) {
	            diff[key] = obj2[key];
	        }
	    });

	    return diff;
	}

	const isPatternNumber = userInput => {
  		return /^\d+$/.test(userInput);
	}

	const toggleInputMoneyDirectly = event => {
		console.debug(":toggleInputMoneyDirectly", event)
		if (event) {
			console.debug(":toggleInputMoneyDirectly:updatedMandayCost", updatedMandayCost)
			if (typeof updatedMandayCost.IntoMoney == "undefined") {
				console.debug(":toggleInputMoneyDirectly:setUpdatedMandayCost", updatedMandayCost)
				setUpdatedMandayCost({ ...updatedMandayCost, IntoMoney: ""})
			}
		}
		setInputMoneyDirectly(event)
		setshowHide(event)
	};

	// const resetAddMandayCostData = () => {
	// 	// setNewMandayCost({ title: '', description: '', url: '', status: 'TO LEARN' })
	// 	// setShowAddMandayCostModal(false)
	// }

	return (
		<Modal show={showUpdateMandayCostModal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>Cập nhật Manday kỹ sư?</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<Form.Group>
						<Form.Text id='ContentCost-help' muted as="h6">
							Phòng ban
						</Form.Text>
						<Form.Control
							id = 'noidung-help'
							as="select"
							name='DepartmentID'
							aria-describedby='noidung-help'
							value={DepartmentID}
							onChange={onChangeUpdatedMandayCostForm}>
							<option value=''>-----</option>
							{
								typeof Departments != "undefined" ? 
								Departments.map((obj => {
									return (
										<>
											<option value={obj._id}>{obj.DepartmentName}</option>
										</>
									)
								}))
								:
								<></>
							}
							
					    </Form.Control>
					</Form.Group>

					<Form.Group>
						<Form.Check
							type='checkbox'
							name='inputMoneyDirectly'
							checked={inputMoneyDirectly}
							value={inputMoneyDirectly}
							onChange={(e) => toggleInputMoneyDirectly(e.target.checked)}
							label="Nhập số tiền trực tiếp"
						/>
					</Form.Group>

					{
						showHide ? 
							<>
								<Form.Group>
									<Form.Text id='title-help' muted as='h6'>
										Số tiền
									</Form.Text>
									<Form.Control
										type='text'
										placeholder='Nhập số'
										name='IntoMoney'
										required
										aria-describedby='IntoMoney-help'
										value={IntoMoney}
										onChange={onChangeUpdatedMandayCostForm}
									/>
								</Form.Group>
							</>
						:
							<>
								<Form.Group>
									<Form.Text id='title-help' muted as='h6'>
										Manday chuẩn
									</Form.Text>
									<Form.Control
										type='text'
										placeholder='Nhập số'
										name='Cost'
										required
										aria-describedby='Cost-help'
										value={Cost}
										onChange={onChangeUpdatedMandayCostForm}
									/>
								</Form.Group>

								<Form.Group>
									<Form.Text id='title-help' muted as='h6'>
										Số người tham gia dự án
									</Form.Text>
									<Form.Control
										type='text'
										placeholder='Nhập số'
										name='StaffNumber'
										required
										aria-describedby='songuoi-help'
										value={StaffNumber}
										onChange={onChangeUpdatedMandayCostForm}
									/>
								</Form.Group>

								<Form.Group>
									<Form.Text id='title-help' muted as='h6'>
										Nhập số ngày thực hiện
									</Form.Text>
									<Form.Control
										type='text'
										placeholder='Nhập số'
										name='ImplementationDay'
										required
										aria-describedby='songaythuchien-help'
										value={ImplementationDay}
										onChange={onChangeUpdatedMandayCostForm}
									/>
								</Form.Group>
							</>
					}

					<Form.Group>
						<Form.Text id='title-help' muted as='h6'>
							Ghi chú
						</Form.Text>
						<Form.Control
							as='textarea'
							rows={2}
							placeholder=''
							name='Note'
							aria-describedby='ghichu-help'
							value={Note}
							onChange={onChangeUpdatedMandayCostForm}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Cập nhật
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}

export default UpdateMandayCostModal
