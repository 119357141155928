import { useContext, useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import hopdong from './../assets/hopdong.jpg'
import nguoi_dung from './../assets/nguoi_dung.jpeg'
import don_vi from './../assets/don_vi.png'
import trung_tam from './../assets/trung_tam.jpg'
import Spinner from 'react-bootstrap/Spinner'
//Compoments

//Contexts
import { ConfigCenterContext } from '../contexts/ConfigCenterContext'
import { ConfigDepartmentContext } from '../contexts/ConfigDepartmentContext'
import { ContractContext } from '../contexts/ContractContext'
import { UserContext } from '../contexts/UserContext'
import { HistoryContext } from '../contexts/HistoryContext'
import { EventContext } from '../contexts/EventContext'
import { ProductCostContext } from '../contexts/ProductCostContext'
import { MandayCostContext } from '../contexts/MandayCostContext'
import { GuaranteeLetterCostContext } from '../contexts/GuaranteeLetterCostContext'
import { MiscExpenseCostContext } from '../contexts/MiscExpenseContext'
import { CapitalExpenditureCostContext } from "../contexts/CapitalExpenditureCostContext"
import { LoanInterestContext } from "../contexts/LoanInterestContext"
import { AuxiliaryCostContext } from '../contexts/AuxiliaryCostContext'
import { ImplementationCostContext } from '../contexts/ImplementationCostContext'

import { AuthContext } from '../contexts/AuthContext'
import { UserPageContext } from '../contexts/UserPageContext'
import { MenuContext } from '../contexts/MenuContext'
import { RoleContext } from '../contexts/RoleContext'
import { UserManagerContext } from '../contexts/UserManagerContext'

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Line, Pie} from 'react-chartjs-2';

export const Dashboard = () => {

	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)
	
	const {
		CenterState: { Centers, CentersLoading },
		get_All_Center
	} = useContext(ConfigCenterContext)

	const {
		DepartmentState: { Department, Departments, DepartmentsLoading },
		getDepartment
	} = useContext(ConfigDepartmentContext)

	const {
		ContractState: { Contract, Contracts, ContractsLoading },
		getContracts
	} = useContext(ContractContext)

	const {
		UserState: { User, Users, UsersLoading },
		getUsers
	} = useContext(UserContext)

	const {
		HistoryState: { History, Historys, HistorysLoading },
		getHistory
	} = useContext(HistoryContext)

	const {
		EventState: { Event, Events, EventsLoading },
		getEvent
	} = useContext(EventContext)

	//=== Get All Productcosts
	const {
		ProductCostState: { ProductCost, ProductCosts, ProductCostsLoading },
		getProductCosts,
	} = useContext(ProductCostContext)

	//=== Get MandayCost 
	const {
		MandayCostState: { MandayCost, MandayCosts, MandayCostsLoading },
		getMandayCosts,
	} = useContext(MandayCostContext)

	//=== Get GuaranteeLetterCost
	const {
		GuaranteeLetterCostState: { GuaranteeLetterCost, GuaranteeLetterCosts, GuaranteeLetterCostsLoading },
		getGuaranteeLetterCosts,
	} = useContext(GuaranteeLetterCostContext)

	//=== Get MiscExpenseCosts
	const {
		MiscExpenseCostState: { MiscExpenseCost, MiscExpenseCosts, MiscExpenseCostsLoading },
		getMiscExpenseCosts,
	} = useContext(MiscExpenseCostContext)

	//=== Get data CapitalExpenditureCost chi phi von
	const {
		CapitalExpenditureCostState: { CapitalExpenditureCost, CapitalExpenditureCosts, CapitalExpenditureCostsLoading },
		getCapitalExpenditureCosts,
	} = useContext(CapitalExpenditureCostContext);

	//=== Get data LoanInterest chi phi lãi vay
	const {
	    LoanInterestState: { LoanInterest, LoanInterests, LoanInterestsLoading },
	    getLoanInterest
	} = useContext(LoanInterestContext);
	
	//=== Get data AuxiliaryCost
	const {
		AuxiliaryCostState: { AuxiliaryCosts },
		getAuxiliaryCosts
	} = useContext(AuxiliaryCostContext)

	//=== Get data ImplementationCost
	const {
		ImplementationCostState: { ImplementationCosts },
		getImplementationCost
	} = useContext(ImplementationCostContext)

	useEffect(() => get_All_Center(), [])
	useEffect(() => getDepartment(), [])
	useEffect(() => getContracts(), [])
	useEffect(() => getUsers(), [])
	useEffect(() => getHistory(), [])
	useEffect(() => getEvent(), [])
	useEffect(() => getProductCosts(), [])
	useEffect(() => getMandayCosts(), [])
	useEffect(() => getGuaranteeLetterCosts(), [])
	useEffect(() => getMiscExpenseCosts(), [])
	useEffect(() => getCapitalExpenditureCosts(), [])
	useEffect(() => getLoanInterest(), [])
	useEffect(() => getAuxiliaryCosts(), [])
	useEffect(() => getImplementationCost(), [])

	let UserID = _id
	
	let newContracts = Contracts.map((Contract, i) => ({
		...Contract,
		STT: parseInt(i+1),
		CenterID: Contract.CenterID, 
		DepartmentID: Contract.DepartmentID,
		CustomerID: Contract.CustomerID,
		user: Contract.user.username,
		Date: convert_time(Contract.Date != null ? Contract.Date : getCurrentDate("-")),
		StatusID: get_status(Historys,Contract._id) != '' ? get_status(Historys,Contract._id).StatusID : '0',
		StatusName: get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys,Contract._id,Events) : '',
	}))

	function totalCenter() {
		let totalCenter = 0;
		if (typeof Centers != undefined) totalCenter = Centers.length;
		return totalCenter;
	}

	function totalDepartment() {
		let totalDepartment = 0;
		if (typeof Departments != undefined) totalDepartment = Departments.length;
		return totalDepartment;
	}

	function totalContract() {
		let totalContract = 0;
		if (typeof Contracts != undefined) totalContract = Contracts.length;
		return totalContract;
	}

	function totalUser() {
		let totalUser = 0;
		if (typeof Users != undefined) totalUser = Users.length;
		return totalUser;
	}

	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	// table ranking
	//Ham tinh tong Phan tử trong kieu mang 
	function Sum_InputIntoMoney(ProductsCost) {
		let Total_InputIntoMoney = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_InputIntoMoney += ListProduct.InputIntoMoney
			)))

		return Total_InputIntoMoney;
	}
	//Ham tinh tong Phan tử trong kieu mang 
	function Sum_OutputIntoMoney(ProductsCost) {
		let Total_OutputIntoMoney = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_OutputIntoMoney += ListProduct.OutputIntoMoney
			)))

		return Total_OutputIntoMoney;
	}

	//Tinh tong thanh tien gia ban voi Insurance = true
	function Sum_Insurance(ProductsCost) {
		let Total_Insurance = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				ListProduct.Insurance === true ? Total_Insurance += ListProduct.OutputIntoMoney : 0
			)))

		return Total_Insurance;
	}

	function Sum_InsuranceHSE(ProductsCost) {
		let Total_InsuranceHSE = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				ListProduct.InsuranceHSE === true ? Total_InsuranceHSE += (ListProduct.OutputIntoMoney*((ListProduct.PercentHSE != null ? ListProduct.PercentHSE : 1) / 100)) : 0
			)))
		return Total_InsuranceHSE;
	}

	// hàm tính tổng thành tiền
	function sumArray(mang) {
		let sum = 0;
		mang.map(function (value) { sum += value; });
		return sum;
	}
	//hàm tìm data theo contract id
	function find_data_by_contract_id(data, idContract) {
		let rs = [];
		rs = data.filter(x => x.contract === idContract)
		return rs
	}

	// hàm đổi giá trị chi phí % => giá trị tiền tuyệt đối
	function changeCost(Cost, TotalOutputIntoMoney) {
		let returnValue = Cost;
		if (Cost < 1)
			returnValue = Cost * TotalOutputIntoMoney;
		return returnValue
	}

	function SumListCost(Auxiliary, TotalOutputIntoMoney) {
		let kq = 0;
		Auxiliary.map(AuxiliaryCost =>
			AuxiliaryCost.ListCosts.map(ListCost => (
				kq += changeCost(ListCost.Cost, TotalOutputIntoMoney)
			)))

		return kq;
	}
	
	function FindPlan(Auxiliary) {
		let returnPlan = 0;
		Auxiliary.map(AuxiliaryCost => returnPlan = AuxiliaryCost.Plan
		)
		return returnPlan;
	}

	function totalAuxiliaryCost(AuxiliaryCosts, TotalOutputIntoMoney){
		let sumCostTaxA = 0;
		let sumCostTaxB = 0;
		
		let sumCostA = 0;
		let sumCostB = 0;
		
		let sumTaxA = 0;
		let sumTaxB = 0;
		
		AuxiliaryCosts.map(AuxiliaryCost => (
			AuxiliaryCost.ListCosts.map(function(ListCost, index) {
				var newCost = 0
				var newTax = 0
				var newCostTax = 0
				if (AuxiliaryCost.Plan == 1){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost * ListCost.TaxRate)/100).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}else if (AuxiliaryCost.Plan == 2){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost / (100 - ListCost.TaxRate)) * ListCost.TaxRate).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostA += parseInt(newCost);
				}else if (AuxiliaryCost.Plan == 2){
					sumCostB += parseInt(newCost);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumTaxA += parseInt(newTax);
				}else if (AuxiliaryCost.Plan == 2){
					sumTaxB += parseInt(newTax);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostTaxA += parseInt((newCost+newTax));
				}else if (AuxiliaryCost.Plan == 2){
					sumCostTaxB += parseInt((newCost+newTax));
				}
			})
		))
		let muc2_1 = parseInt((sumCostA + sumCostB)/10);
		let muc2_thue = parseInt((sumTaxA + sumTaxB)/10);
		let muc2 = parseInt(muc2_1 + muc2_thue);
		return {muc2, muc2_1, muc2_thue}
	}

	function TotalStageImplementation(ImplementationCosts) {
		let All_Total = 0;
		ImplementationCosts.map((element) => (
			element.StagesImplementation.map((object) => (
				object.Costs.map((Costs) =>
					All_Total += Costs.IntoMoney
				)))))
		return All_Total;
	}

	function TotalCostAuxiliaryCosts(AuxiliaryCosts) {
		let All_Total = 0;
		AuxiliaryCosts.map((element) => {
			element.ListCosts.map((Costs) => {
				All_Total += Costs.Cost
			})
		})
		return All_Total;
	}

	function TotalTaxAuxiliaryCosts(AuxiliaryCosts) {
		let All_Total = 0;
		AuxiliaryCosts.map((element) => {
			element.ListCosts.map((Costs) => {
				All_Total += Costs.Tax
			})
		})
		return All_Total;
	}

	// newContracts = newContracts.slice(0,1); //limit 1

	newContracts.map(function (newContract, i){
		//ProductCost
		let ProductCost_idContract = find_data_by_contract_id(ProductCosts, newContract._id);
		const TotalInsurance = 0.006 * Sum_Insurance(ProductCost_idContract)
		const TotalInsuranceHSE = Sum_InsuranceHSE(ProductCost_idContract)
		const TotalInputIntoMoney = Sum_InputIntoMoney(ProductCost_idContract)
		const TotalOutputIntoMoney = Sum_OutputIntoMoney(ProductCost_idContract)
		let TotalIncentive = 0;
		if(ProductCost_idContract[0] !== undefined){
			TotalIncentive = sumArray(ProductCost_idContract[0].ListProducts.map((ProductCost) => ProductCost.Incentive))//note
		}

		// MandayCost
		let MandayCost_idContract = find_data_by_contract_id(MandayCosts, newContract._id);
		const TotalMandayCost = sumArray(MandayCost_idContract.map((MandayCost) => MandayCost.IntoMoney))
		//
		
		//GuaranteeLetterCost
		let GuaranteeLetterCost_idContract = find_data_by_contract_id(GuaranteeLetterCosts, newContract._id);
		const TotalGuaranteeLetterCost = sumArray(GuaranteeLetterCost_idContract.map((GuaranteeLetterCost) => GuaranteeLetterCost.IntoMoney))
		
		//MiscExpenseCosts
		let MiscExpenseCost_idContract = find_data_by_contract_id(MiscExpenseCosts, newContract._id);
		const TotalMiscExpenseCost = sumArray(MiscExpenseCost_idContract.map((MiscExpenseCost) => MiscExpenseCost.Cost))

		//CapitalExpenditureCosts
		let CapitalExpenditureCost_idContract = find_data_by_contract_id(CapitalExpenditureCosts, newContract._id);
		const TotalCapitalExpense = sumArray(CapitalExpenditureCost_idContract.map((CapitalExpenditureCost) => CapitalExpenditureCost.CapitalExpense))//ch
		
		//LoanInterests
		let LoanInterests_idContract = find_data_by_contract_id(LoanInterests, newContract._id);
		const TotalLoanInterest = sumArray(LoanInterests_idContract.map((LoanInterest ) => LoanInterest.InterestLoan))

		let chiPhi = 0;
		if(TotalCapitalExpense != 0){
			chiPhi = TotalCapitalExpense;
		}else{
			if(TotalLoanInterest != 0){
				chiPhi = TotalLoanInterest;
			}
		}

		//AuxiliaryCosts
		let AuxiliaryCost_idContract = find_data_by_contract_id(AuxiliaryCosts, newContract._id);
		const TotalCost = SumListCost(AuxiliaryCost_idContract, TotalOutputIntoMoney);
		const rsTotalAuxiliaryCost = totalAuxiliaryCost(AuxiliaryCost_idContract, TotalOutputIntoMoney)

		//ImplementationCost
		let ImplementationCost_idContract = find_data_by_contract_id(ImplementationCosts, newContract._id);
		const ImplementationCost = TotalStageImplementation(ImplementationCost_idContract);

		// 1.1 Chi phí triển khai hợp đồng + Chi phi Bảo hiểm
		const TotalImplementationCost = ImplementationCost + TotalInsurance + TotalInsuranceHSE;
		// 1. chi phi phat sinh khi thuc hien du an
		const ExtraCost = (TotalImplementationCost + chiPhi + TotalMandayCost + TotalGuaranteeLetterCost + TotalMiscExpenseCost)
		// 2.
		// 3.
		const projectEfficiency = (TotalOutputIntoMoney - TotalInputIntoMoney - ExtraCost - (rsTotalAuxiliaryCost.muc2*10))
		// console.log(i, newContract._id, hieuquaduan, TotalOutputIntoMoney, TotalInputIntoMoney)
		newContracts[i].TotalInputIntoMoney = TotalInputIntoMoney
		newContracts[i].projectEfficiency = Math.round(projectEfficiency)
		newContracts[i].TotalOutputIntoMoney = TotalOutputIntoMoney
	})

	// hàm convert to date time
	function convert_time(date_time){
		const date = new Date(date_time); // Replace this with your actual date object
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		const seconds = ('0' + date.getSeconds()).slice(-2);
		const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		return formattedDate;
	}

	function getCurrentDate(separator){
	    let newDate = new Date()
	    let date = newDate.getDate();
	    let month = newDate.getMonth() + 1;
	    let year = newDate.getFullYear();
	    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
	}

	function get_status_name(array,ContractID,event_array){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			let statusID = lastElement.StatusID;
			for (var i = 0; i < event_array.length; i++) {
				let value = event_array[i];
				if (value.statusID == statusID) {
					// return value.name+" ("+statusID+")";
					return value.name;
				}
			}
		}else{
			return '';
		}
	}

	function get_status(array,ContractID){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return '';
		}
	}

	// setup data
	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Legend,
		ArcElement
	);
		  
	const data_chart = getLabels(contactByDate(newContracts));
	
	const labels = data_chart['labels'];
	const datasets = data_chart['datasets'];  

	const data = {
		labels,
		datasets: [
			{
				label: 'Hợp đồng',
				data: datasets,
				borderColor: 'rgb(255, 99, 132)',
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
			}
		],
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: 'top'
			},
			title: {
				display: false,
				text: 'Chart.js Line Chart'
			},
		},
		scales: {
			y: {
			min: 0,
			max: (2 * Math.round(Math.max(...datasets) / 2) + 10),
			ticks: {
					stepSize: (Math.max(...datasets) > 50 ) ? 20 : 5 
 				}
			}
		},
	};
	  
	/// pie 
	const data_pie = {
		labels: ['Mới', 'Hoạt động', 'Đóng'],
		datasets: [
			{
				label: '# hợp đồng',
				data: getDataPie(),
				backgroundColor: [
					'rgb(30,144,255)', //moi
					'rgb(255,234,0)', //hoatdong
					'rgb(255,127,80)', //dong
				],
				borderColor: [
					'rgba(255, 99, 132, 1)',
					'rgba(54, 162, 235, 1)',
					'rgba(255, 206, 86, 1)',
				],
				borderWidth: 1,
			},
		],
	};
	
	function getDataPie() {
		let statusNew = ["1"];
		let statusActive = ["1","7","8","9"];
		let statusClose = ["7","8","9"];
		let contactNew = newContracts.filter((x) => (statusNew.includes(x.StatusID) == true));
		let contactActive = newContracts.filter((x) => (statusActive.includes(x.StatusID) == false));
		let contactClose = newContracts.filter((x) => (statusClose.includes(x.StatusID) == true));
		// console.log("dataa", newContracts, contactNew, contactActive, contactClose)
		return [contactNew.length, contactActive.length, contactClose.length]
	}

	function getLabels(data) {
		let labels = [];
		let datasets = [];

		data.map(function(e){
			labels.push(e.date_time);
			datasets.push(e.data.length);
		})

		return {labels, datasets}
	}
	
	function contactByDate(data){
		var array_result = [];
		let i_date=0;
		while(i_date < data.length) {
			var tepm_date_time = data[i_date].Date.split(" ");
			var index = (array_result.map(function(x){ return x.date_time; })).indexOf(tepm_date_time[0]);
			if (index != -1) {
				array_result[index].data.push(data[i_date]);
			}else{
				array_result = [...array_result, {"date_time" : tepm_date_time[0],  "data" : new Array(data[i_date])}];
			}
			i_date++;
		}
		return array_result;
	}

	function getRandomInt(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
	}

	const RenderingLineChart = () => {
		return (
		<>
			<Line width={1000} height={600} options={options} data={data} />;
		</>
		)
	}

	const RenderingPieChart = () =>  {
		return (
			<>
				<Pie data={data_pie} />
			</>
		)
	}
	////

	const RenderingContactRank = () => {
		newContracts.sort(function(a, b) {
			var c = a.TotalInputIntoMoney;
			var d = b.TotalInputIntoMoney;
			return d-c;
		});

		let tableRank = newContracts.slice(0,10)

		return(
			<>
				<Table className='text-center' responsive="lg" striped bordered hover size="lg" variant="" >
					<thead>
						<tr>
							<th>Số HĐ/PO</th>
							<th>Thời gian</th>
							<th>Chi phí</th>
							<th>Hiệu quả</th>
							<th>Doanh thu</th>
						</tr>
					</thead>
					<tbody>
						{
							tableRank.map(object => (
								<tr>
									<td>
										<a title="Xem chi tiết" href={`/summary/${object._id}`}>
											{object.ContractID}
										</a>
									</td>
									<td>{object.Date}</td>
									<td>{object.TotalInputIntoMoney.toLocaleString()}</td>
									<td style={{color: 'rgb(30,144,255)'}}>{object.projectEfficiency.toLocaleString()}</td>
									<td>{object.TotalOutputIntoMoney.toLocaleString()}</td>
								</tr>
							))
						}
					</tbody>
				</Table>
			</>
		)
	}
	
	const RenderingContactNew = () => {
		let contactNew = newContracts.filter(x => x.StatusID == "1");
		contactNew.sort(function(a, b) {
			var c = new Date(a.Date);
			var d = new Date(b.Date);
			return d-c;
		});

		contactNew = contactNew.slice(0,10)

		return(
			<>
				<Table style={{fontSize: "13px"}} className='text-center' responsive="lg" striped bordered hover size="lg" variant="" >
					<thead>
						<tr>
							<th width="50px">Số HĐ/PO</th>
							<th>Thời gian</th>
						</tr>
					</thead>
					<tbody>
						{
							contactNew.map(object => (
								<tr>
									<td>
										<a title="Xem chi tiết" href={`/summary/${object._id}`}>
											{object.ContractID}
										</a>
									</td>
									<td>{object.Date}</td>
								</tr>
							))
						}
					</tbody>
				</Table>
			</>
		)
	}

	const {
		UserPageState: { UserPage, UserPages, UserPagesLoading },
		getAllUserPages
	} = useContext(UserPageContext)

	const {
	    MenuState: { Menu, Menus, MenusLoading },
	    getMenu,
	} = useContext(MenuContext);

	function FindArrayMenu(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return null;
		}
	}

	if (UserPagesLoading && MenusLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	}else {
		const result = UserPages.filter(obj => { return obj.user_id.some(id => id === UserID); });
		let menu_access = false
		if (result.length > 0) {
			for (var j = 0; j < result.length; j++) {
				let menuV1 = FindArrayMenu(Menus, result[j].page_id[0])
				if (menuV1 != null && menuV1.path == location.pathname) {
					// console.debug("menuV1 co quyen truy cap")
					menu_access = true
					break;
				}else {
					// console.debug("menuV1 khong quyen truy cap")
				}
			}
		} else {
			// console.debug("menu khong quyen truy cap")
		}

		if (menu_access) {
			return (
				<>
					<Card className='text-left mx-3 my-3' aninmation={false}>
						<Card.Body style={{display: 'flex', flexWrap: 'wrap', gap: '20px', margin: '0 5px 5px 0' }}>
							
							<Card className="custom-card" >
								<a title="Xem chi tiết" className="normal-text" href="/summary">
									<Card.Body>
										<Row className='mt-2'>
											<Col className='text-center'>
												<img src={hopdong} width="64" height="64" />
											</Col>
											<Col className='text-center'>
												<Card.Title>{totalContract()}</Card.Title>
												<Card.Title>Hợp đồng</Card.Title>
											</Col>
										</Row>
									</Card.Body>
								</a>
							</Card>
							

							<Card className="custom-card">
								<a title="Xem chi tiết" className="normal-text" href="/user">
									<Card.Body>
										<Row className='mt-2'>
											<Col className='text-center'>
												<img src={nguoi_dung} width="64" height="64" />
											</Col>
											<Col className='text-center'>
												<Card.Title>{totalUser()}</Card.Title>
												<Card.Title>Người dùng</Card.Title>
											</Col>
										</Row>
									</Card.Body>
								</a>
							</Card>

							<Card className="custom-card">
								<a title="Xem chi tiết" className="normal-text" href="/config/department">
									<Card.Body>
										<Row className='mt-2'>
											<Col className='text-center'>
												<img src={don_vi} width="64" height="64" />
											</Col>
											<Col className='text-center'>
												<Card.Title>{totalDepartment()}</Card.Title>
												<Card.Title>Đơn vị</Card.Title>
											</Col>
										</Row>
									</Card.Body>
								</a>
							</Card>

							<Card className="custom-card">
								<a title="Xem chi tiết" className="normal-text" href="/config/center">
									<Card.Body>
										<Row className='mt-2'>
											<Col className='text-center'>
												<img src={trung_tam} width="64" height="64" />
											</Col>
											<Col className='text-center'>
												<Card.Title>{totalCenter()}</Card.Title>
												<Card.Title>Trung tâm</Card.Title>
											</Col>
										</Row>
									</Card.Body>
								</a>
							</Card>
						</Card.Body>

						<Card.Body style={{display: 'flex', flexWrap: 'wrap', gap: '20px', margin: '0 5px 5px 0' }}>
							<Card style={{ width: '74%', height: '500px'}}>
								<h4 style={{ padding: '10px 30px 0px 30px', fontWeight: '600'}}>Thống kê số lượng hợp đồng theo thời gian</h4>
								<Card.Body>
									<RenderingLineChart />
								</Card.Body>
							</Card>

							<Card style={{ width: '23%', height: '500px'}}>
								<h4 style={{ padding: '10px 30px 0px 30px', fontWeight: '600'}}>Trạng thái hợp đồng</h4>
								<Card.Body style={{textAlign: 'center'}}>
									<RenderingPieChart />
								</Card.Body>
							</Card>
						</Card.Body>

						<Card.Body style={{display: 'flex', flexWrap: 'wrap', gap: '20px', margin: '0 5px 5px 0' }}>
							<Card style={{ width: '74%'}}>
								<h4 style={{ padding: '10px 30px 0px 30px', fontWeight: '600'}}>Xếp hạng</h4>
								<Card.Body style={{overflowY: "auto"}}>
									<RenderingContactRank />
								</Card.Body>
							</Card>

							<Card style={{ width: '23%'}}>
								<h4 style={{ padding: '10px 30px 0px 30px', fontWeight: '600'}}>Hợp đồng mới nhất</h4>
								<Card.Body style={{height: "500px", overflowY: "auto"}}>
									<RenderingContactNew />
								</Card.Body>
							</Card>
						</Card.Body>				
					</Card>
				</>
			)
		}else {
			return (
				<>
					<div id="notfound">
						<div className="notfound">
							<h1>Oops!</h1>
							<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
								<br/>
								<Link style={{textDecoration: "none"}} to="/home">
									Trở về trang chủ
								</Link>
							</h6>
						</div>
					</div>
				</>
			)
		}
	}
	

}