import { ProductCostContext } from "../contexts/ProductCostContext";
import { AuthContext } from "../contexts/AuthContext";
import { useContext, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

/* import { useState } from 'react' */
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import download from "../assets/download.png";
import downLoadExcel from "../assets/icon-download.svg";
import importExcel from "../assets/icon-upload.svg";

/* import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Col from 'react-bootstrap/Col' 
import addIcon from '../assets/plus-circle-fill.svg'*/

import AddProductCostModal from "../components/ProductCost/AddProductModal"; //Note
import UpdateProductCostModal from "../components/ProductCost/UpdateProductModal"; //Note
import ActionButtons_ProductCost from "../components/ProductCost/ActionButtons_ProductCost";
import Table from "react-bootstrap/Table";
import { ContractContext } from "../contexts/ContractContext";
import { ConfigCustomerContext } from "../contexts/ConfigCustomerContext";
import { UserContext } from "../contexts/UserContext";
import { RoleContext } from "../contexts/RoleContext";
import { HistoryContext } from "../contexts/HistoryContext";
import { EventContext } from "../contexts/EventContext";
import { UserManagerContext } from "../contexts/UserManagerContext";
import { HistoryChangeContext } from "../contexts/HistoryChangeContext";

//new 12/3
import Add_Incentive from "../components/ProductCost/Add_Incentive";
import ImportProductCostModal from "../components/ProductCost/ImportProductCostModal"; //Note
import urlfileExcelProductCost from "./../assets/excel/ChiTietHangHoa_Template.xlsx";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import axios from "axios";
import { apiUrl } from "../contexts/constants";

//View all product cost
export const ProductCost_all = () => {
    // Contexts
    const {
        authState: {
            user: { username },
        },
    } = useContext(AuthContext);

    const {
        ProductCostState: { ProductCost, ProductCosts, ProductCostsLoading },

        getProductCosts,
        setShowAddProductCostModal,
        showToast: { show, message, type },
        setShowToast,
    } = useContext(ProductCostContext);

    // hàm tính tổng thành tiền
    function sumArray(mang) {
        let sum = 0;
        mang.map(function (value) {
            sum += value;
        });
        return sum;
    }

    // Start: Get all ProductCosts
    useEffect(() => getProductCosts(), []);

    const [stateInsurance, setStateInsurance] = useState(false);
    const toggleInsurance = (value) => {
        setStateInsurance(value);
    };

    let body = null;
    let stt = 1;
    const TotalInputIntoMoney = sumArray(ProductCosts.map((ProductCost) => ProductCost.InputIntoMoney)); //note
    const TotalOutputIntoMoney = sumArray(ProductCosts.map((ProductCost) => ProductCost.OutputIntoMoney)); //note
    const TotalIncentive = sumArray(ProductCosts.map((ProductCost) => ProductCost.Incentive)); //note
    if (ProductCostsLoading) {
        body = (
            <div className="spinner-container">
                <Spinner animation="border" variant="info" />
            </div>
        );
    } else if (ProductCosts.length === 0) {
        body = (
            <>
                <Card className="text-center mx-5 my-5">
                    <Card.Header as="h1">CHI TIẾT HÀNG HÓA</Card.Header>
                    <Card.Body>
                        <Card.Title>Chưa có dữ liệu</Card.Title>
                        {/* <Button
							variant='primary'
							onClick={setShowAddProductCostModal.bind(this, true)}
						>
							Thêm!
						</Button> */}
                    </Card.Body>
                </Card>
            </>
        );
    } else {
        body = (
            <>
                <Card className="text-center mx-5 my-5" animation={false}>
                    <Card.Header as="h1">CHI TIẾT HÀNG HÓA</Card.Header>
                    <Card.Body>
                        <Table responsive="sm" striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th rowSpan="2">STT</th>
                                    <th rowSpan="2" width="15%">
                                        Tên hàng
                                    </th>
                                    <th rowSpan="2" width="5%">
                                        Số lượng{" "}
                                    </th>
                                    <th colSpan="3">Giá vốn hàng bán Giá kho</th>
                                    <th colSpan="2 ">Doanh số Giá bán</th>
                                    <th rowSpan="2 " width="8%">
                                        Có tính Chi Phí Bảo Hiểm{" "}
                                    </th>
                                    <th rowSpan="2">Incentive</th>
                                    <th rowSpan="2">Tỷ giá USD</th>
                                    <th rowSpan="2">Ghi chú</th>
                                    <th rowSpan="2">Thao tác</th>
                                </tr>
                                <tr>
                                    <th width="8%" as="pre">
                                        Đơn giá FOB <br />
                                        (EX-W)
                                    </th>
                                    <th>Đơn giá kho</th>
                                    <th>Thành tiền giá kho</th>
                                    <th>Đơn giá bán</th>
                                    <th>Thành tiền giá bán</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ProductCosts.map((ProductCost) => (
                                    <tr key={ProductCost._id}>
                                        <td>{stt++} </td>
                                        <td>{ProductCost.ProductName}</td>
                                        <td>{ProductCost.Quantity.toLocaleString()}</td>
                                        <td>{ProductCost.FOBCost.toLocaleString()}</td>
                                        <td>{ProductCost.InputPrice.toLocaleString()}</td>
                                        <td>{ProductCost.InputIntoMoney.toLocaleString()}</td>
                                        <td>{ProductCost.OutputPrice.toLocaleString()}</td>
                                        <td>{ProductCost.OutputIntoMoney.toLocaleString()}</td>
                                        <td>
                                            <input type="checkbox" checked={ProductCost.Insurance} onChange={(e) => toggleInsurance(e.target.checked)} />
                                        </td>
                                        <td>{ProductCost.Incentive.toLocaleString()} </td>
                                        <td>{ProductCost.RatioUSD.toLocaleString()}</td>
                                        <td>{ProductCost.Note} </td>

                                        <td>
                                            <ActionButtons_ProductCost _id={ProductCost._id} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {/* <Button
							variant='primary'
							onClick={setShowAddProductCostModal.bind(this, true)}
						>
							Thêm mới
						</Button> */}
                    </Card.Body>
                </Card>
            </>
        );
    }

    return (
        <>
            {body}
            <AddProductCostModal />
            {ProductCost !== null && <UpdateProductCostModal />}
            {/* After ProductCost is added, show toast */}
            <Toast
                show={show}
                style={{ position: "fixed", top: "20%", right: "10px" }}
                className={`bg-${type} text-white`}
                onClose={setShowToast.bind(this, {
                    show: false,
                    message: "",
                    type: null,
                })}
                delay={3000}
                autohide
            >
                <Toast.Body>
                    <strong>{message}</strong>
                </Toast.Body>
            </Toast>
        </>
    );
};

//View product cost by id
export const ProductCost_idContract = () => {
    const params = useParams();
    // Contexts

    const {
        ProductCostState: { ProductCost, ProductCosts, ProductCostsLoading },
        getProductCost_byidContract,
        setShowAddProductCostModal,
        showToast: { show, message, type },
        setShowToast,
    } = useContext(ProductCostContext);

    // hàm tính tổng thành tiền
    function sumArray(mang) {
        let sum = 0;
        mang.map(function (value) {
            sum += value;
        });
        return sum;
    }

    // Start: Get ProductCosts by id Contract

    useEffect(() => getProductCost_byidContract(params.id), []);
    // console.log("Test===>ProductCostView review useEffect: ", ProductCosts)
    // console.log("Test===>ProductCostView review useEffect: ", params.id)

    //Ham checkbox Insurance
    const [stateInsurance, setStateInsurance] = useState(false);
    const toggleInsurance = (value) => {
        setStateInsurance(value);
    };
    let body = null;
    let stt = 1;
    const TotalInputIntoMoney = sumArray(ProductCosts.map((ProductCost) => ProductCost.InputIntoMoney)); //note
    const TotalOutputIntoMoney = sumArray(ProductCosts.map((ProductCost) => ProductCost.OutputIntoMoney)); //note
    const TotalIncentive = sumArray(ProductCosts.map((ProductCost) => ProductCost.Incentive)); //note

    if (ProductCostsLoading) {
        body = (
            <div className="spinner-container">
                <Spinner animation="border" variant="info" />
            </div>
        );
    } else if (ProductCosts.length === 0) {
        body = (
            <>
                <Card className="text-center mx-5 my-5">
                    <Card.Header as="h1">CHI TIẾT HÀNG HÓA</Card.Header>
                    <Card.Body>
                        <Card.Title>Chưa có dữ liệu</Card.Title>
                        <Button variant="primary" onClick={setShowAddProductCostModal.bind(this, true)} style={{ marginRight: "5px" }}>
                            Thêm mới
                        </Button>
                        <a href={`/inputform/${params.params.idcontract}`}>
                            <Button variant="primary">Quay lại</Button>
                        </a>
                    </Card.Body>
                </Card>
            </>
        );
    } else {
        body = (
            <>
                <Card className="text-center mx-5 my-5">
                    <Card.Header as="h1">CHI TIẾT HÀNG HÓA</Card.Header>
                    <Card.Body>
                        <Table responsive="sm" striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th rowSpan="2">STT</th>
                                    <th rowSpan="2" width="15%">
                                        Tên hàng
                                    </th>
                                    <th rowSpan="2" width="5%">
                                        Số lượng{" "}
                                    </th>
                                    <th colSpan="3">Giá vốn hàng bán Giá kho</th>
                                    <th colSpan="2 ">Doanh số Giá bán</th>
                                    <th rowSpan="2 " width="8%">
                                        Có tính Chi Phí Bảo Hiểm{" "}
                                    </th>
                                    <th rowSpan="2">Incentive</th>
                                    <th rowSpan="2">Tỷ giá USD</th>
                                    <th rowSpan="2">Ghi chú</th>
                                    <th rowSpan="2">Thao tác</th>
                                </tr>
                                <tr>
                                    <th width="8%" as="pre">
                                        Đơn giá FOB <br />
                                        (EX-W)
                                    </th>
                                    <th>Đơn giá kho</th>
                                    <th>Thành tiền giá kho</th>
                                    <th>Đơn giá bán</th>
                                    <th>Thành tiền giá bán</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ProductCosts.map((ProductCost) => (
                                    <tr key={ProductCost._id}>
                                        <td>{stt++} </td>
                                        <td>{ProductCost.ProductName}</td>
                                        <td>{ProductCost.Quantity.toLocaleString()}</td>
                                        <td>{ProductCost.FOBCost.toLocaleString()}</td>
                                        <td>{ProductCost.InputPrice.toLocaleString()}</td>
                                        <td>{ProductCost.InputIntoMoney.toLocaleString()}</td>
                                        <td>{ProductCost.OutputPrice.toLocaleString()}</td>
                                        <td>{ProductCost.OutputIntoMoney.toLocaleString()}</td>
                                        <td>
                                            {" "}
                                            {ProductCost.Insurance}
                                            <input type="checkbox" checked={ProductCost.Insurance} onChange={(e) => toggleInsurance(e.target.checked)} />
                                        </td>
                                        <td>{ProductCost.Incentive.toLocaleString()} </td>
                                        <td>{ProductCost.RatioUSD.toLocaleString()}</td>
                                        <td>
                                            {ProductCost.Note}
                                            <input type="checkbox" checked={ProductCost.EX_W} onChange={(e) => toggleInsurance(e.target.checked)} />
                                        </td>

                                        <td>
                                            <ActionButtons_ProductCost _id={ProductCost._id} />
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={5}>Tổng</td>
                                    <td>{TotalInputIntoMoney.toLocaleString()}</td>
                                    <td></td>
                                    <td>{TotalOutputIntoMoney.toLocaleString()}</td>
                                    <td></td>
                                    <td>{TotalIncentive.toLocaleString()}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan={13}>
                                        <Button variant="primary" onClick={setShowAddProductCostModal.bind(this, true)}>
                                            Thêm mới
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <a href={`/summary/${params.id}`}>
                            <Button variant="primary">Xem PTHĐ</Button>
                        </a>
                        <span> </span>
                        <a href={`/inputform/${params.id}`}>
                            <Button variant="primary">Kết thúc</Button>
                        </a>
                    </Card.Body>
                </Card>
            </>
        );
    }

    return (
        <>
            {body}
            <AddProductCostModal />
            {ProductCost !== null && <UpdateProductCostModal />}
            {/* After ProductCost is added, show toast */}
            <Toast
                show={show}
                style={{ position: "fixed", top: "20%", right: "10px" }}
                className={`bg-${type} text-white`}
                onClose={setShowToast.bind(this, {
                    show: false,
                    message: "",
                    type: null,
                })}
                delay={3000}
                autohide
            >
                <Toast.Body>
                    <strong>{message}</strong>
                </Toast.Body>
            </Toast>
        </>
    );
};

//View product cost by id
export const Products_idContract = () => {
    const params = useParams();
    // Contexts

    const {
        ProductCostState: { ProductCosts, ProductCostsLoading },
        getProductCost_idContract,

        setShowAddProductCostModal,
        showToast: { show, message, type },
        setShowToast,
        showToast,
        setShowAddIncentive_Modal,
        setshowImportProductCostModal,
        _setRadioUSD,
    } = useContext(ProductCostContext);

    const {
        authState: {
            user: { username, roles, _id },
        },
    } = useContext(AuthContext);

    const {
        UserState: { User, Users, UsersLoading },
        getUsers,
    } = useContext(UserContext);
    useEffect(() => getUsers(), []);

    const {
        RoleState: { Role, Roles, RolesLoading },
        getRoles,
    } = useContext(RoleContext);
    useEffect(() => getRoles(), []);

    const {
        historyChangeState: { historyChange, historyChanges },
        getHistoryChanges,
        addHistoryChange
    } = useContext(HistoryChangeContext)

    let role_new = roles[0];
    let roleName = FindArray(Roles, role_new).name;

    const {
        ContractState: { Contracts, ContractsLoading },
        getContract_byid,
    } = useContext(ContractContext);

    // hàm tính tổng thành tiền
    function sumArray(mang) {
        let sum = 0;
        mang.map(function (value) {
            sum += value;
        });
        return sum;
    }

    // Start: Get ProductCosts by id Contract

    // useEffect(() => getProductCost_idContract(params.idcontract))//, [showToast])
    // console.log("Test===>ProductCostView review useEffect: ", ProductCosts)

    // console.log(historyChanges,'==============================vvvvvvv');
    useEffect(() => {
        getProductCost_idContract(params.idcontract);
        // getContract_byid(params.idcontract)
        // do something else
    }, []);
    useEffect(() => {
        getContract_byid(params.idcontract);
        // do something else
    }, []);

    function FindArray(array, ID) {
        let returnObject = "";
        if (ID != undefined) returnObject = array.find((x) => x._id === ID);
        if (returnObject != undefined) {
            return returnObject;
        } else {
            return "";
        }
    }

    //Ham tinh tong Phan tử trong kieu mang
    function Sum_InputIntoMoney(ProductsCost) {
        let Total_InputIntoMoney = 0;
        ProductsCost.map((Products) => Products.ListProducts.map((ListProduct) => (Total_InputIntoMoney += ListProduct.InputIntoMoney)));

        return Total_InputIntoMoney;
    }
    //Ham tinh tong Phan tử trong kieu mang
    function Sum_OutputIntoMoney(ProductsCost) {
        let Total_OutputIntoMoney = 0;
        ProductsCost.map((Products) => Products.ListProducts.map((ListProduct) => (Total_OutputIntoMoney += ListProduct.OutputIntoMoney)));

        return Total_OutputIntoMoney;
    }

    function Sum_Incentive(ProductsCost) {
        let Total_Incentive = 0;
        ProductsCost.map((Products) => Products.ListProducts.map((ListProduct) => (Total_Incentive += ListProduct.Incentive)));

        return Total_Incentive;
    }

    //Ham checkbox Insurance
    const [stateInsurance, setStateInsurance] = useState(false);
    const toggleInsurance = (value) => {
        setStateInsurance(value);
    };
    let body = null;
    let stt = 1;
    //const TotalInputIntoMoney = Sum_InputIntoMoney(ProductCosts)
    //const TotalOutputIntoMoney = Sum_OutputIntoMoney(ProductCosts)
    //const TotalIncentive = sumArray(ProductCosts.map((ProductCost) => ProductCost.Incentive))//note

    //download file excel
    const downloadFile = () => {
        const fileUrl = urlfileExcelProductCost; // Đường dẫn tới file cần tải xuống
        axios
            .get(fileUrl, { responseType: "blob" })
            .then((response) => {
                const blob = new Blob([response.data], { type: response.headers["content-type"] });
                // Lấy tên file từ header response hoặc tùy chỉnh tên file
                let fileName = response.headers["content-disposition"];
                if (!fileName) {
                    fileName = "ChiTietHangHoa_Template.xlsx"; // Tên file mặc định nếu không có thông tin từ header
                } else {
                    fileName = fileName.split(";")[1].trim().split("=")[1];
                }
                // Tải xuống file
                saveAs(blob, fileName);
            })
            .catch((error) => {
                console.error("Lỗi khi tải file:", error);
            });
    };

    const {
        CustomerState: { Customer, Customers, CustomersLoading },
        get_All_Customer,
    } = useContext(ConfigCustomerContext);
    useEffect(() => get_All_Customer(), []);
    // console.log(Customers,'=============Customersa')
    // console.log(Contracts,'=============Contracts')
    function FindArray(array, ID) {
        let returnObject = "";
        if (ID != undefined) returnObject = array.find((x) => x._id === ID);
        if (returnObject != undefined) {
            return returnObject;
        } else {
            return "";
        }
    }

    //new
    const [checkList, setCheckList] = useState(false);
    const toggleEX_W = (event, id) => {
        // setCheckList(event);
        let newCheckList = {
            contract: params.idcontract,
            CheckList: event,
            idProduct: id,
        };
        // Gọi hàm fetchData
        fetchData(newCheckList);
    };
    const [dataUploadNew, setDataUploadNew] = useState(null);
    // State để theo dõi việc loading
    const [loading, setLoadingNew] = useState(true);
    // State để theo dõi lỗi (nếu có)
    const [error, setErrorNew] = useState(null);
    const fetchData = async (newCheckList) => {
        try {
            // Thực hiện GET request với Axios
            const response = await axios.put(
                `${apiUrl}/api/forms/detailproduct/checklist/put/`, //note xem trong server
                newCheckList
            );
            window.location.reload();
            // Lưu trữ dữ liệu từ response vào state
            setDataUploadNew(response.data);
        } catch (error) {
            // Nếu có lỗi, lưu trữ lỗi vào state
            setErrorNew(error);
        } finally {
            // Đánh dấu việc loading đã hoàn thành
            setLoadingNew(false);
        }
    };


    ///
    //get cha chinh sua hd
    function FindArrayHistoryCapCaoHon(UserID , array_user, roleName) {
        let returnObject = array_user.find(x => x._id == UserID);
        let rs = []
        let filteredArray = [];

        switch(roleName){
            case 'AM':
                if(returnObject != undefined){
                    filteredArray = array_user.filter(function(item) {
                        if(item.centers != undefined){
                            return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
                        }
                    });
                    let manage_UserId = FindArrayUserManagers(UserManagers,UserID);
                    if(manage_UserId.length > 0){
                        let manager_id_new = manage_UserId[0]['manager_id'];
                        let managerObject = array_user.find(x => x._id == manager_id_new);
                        if(managerObject != undefined){
                            filteredArray.push(managerObject);
                        }
                    }
                }
                break;
            case 'TPKD':
                if(returnObject != undefined){
                    filteredArray = array_user.filter(function(item) {
                        if(item.centers != undefined){
                            return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM'; //&& item.role_details[0].name != 'TPKD';
                        }else{
                            return item.role_details[0].name == 'BTGD'
                        }
                    });
                }
                // console.log('2')
                break;
            case 'KTT':
                if(returnObject != undefined){
                    filteredArray = array_user.filter(function(item) {
                        if(item.centers != undefined){
                            return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM';/// && item.role_details[0].name != 'TPKD';// && item.role_details[0].name != 'KTT';
                        } else {
                            return item.role_details[0].name == 'BTGD'
                        }
                    });
                }
                break;
            case 'BGD':
                if(returnObject != undefined){
                    filteredArray = array_user.filter(function(item) {
                        if(item.centers != undefined){
                            return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';// && item.role_details[0].name != 'KTT';
                        } else {
                            return item.role_details[0].name == 'BTGD'
                        }
                    });
                }
                // console.log('3')
                break;
            case 'BTGD':
            case 'Admin':
                filteredArray = array_user
                break;
        }

        if (filteredArray.length > 0) {
            return filteredArray.map(item => item._id);
        }

        return filteredArray
    }

    function FindArrayUserManagers(array, ID) {
        let returnObject = "";
        if (ID != undefined) returnObject = array.filter(x => x.user_id[0] === ID);
        if(returnObject != undefined){
            return returnObject;
        }else{
            return '';
        }
    }
    //

    let idcontractNew = "";
    let customer_name = "";
    if (Contracts.length > 0) {
        var filterContact = FindArray(Contracts, params.idcontract);
        customer_name = FindArray(Customers, filterContact.CustomerID) != "" ? FindArray(Customers, filterContact.CustomerID).CustomerName : filterContact.CustomerID;
        idcontractNew = filterContact != "" ? filterContact.ContractID : "";
    }

    ///////////////////////
    const {
        HistoryState: { History, Historys, HistorysLoading },
        getHistory
    } = useContext(HistoryContext)
    useEffect(() => getHistory(), [])

    ///UserManagers
    const {
        UserManagerState: { UserManager, UserManagers, UserManagersLoading },
        getAllUserManagers
    } = useContext(UserManagerContext)
    useEffect(() => getAllUserManagers(), [])

    // console.debug("ContractsLoading", ContractsLoading, Contracts)

    if (ContractsLoading) {
        return <></>;
    } else {
        let role_new = roles[0];
        let UserID = _id;
        let roleName = FindArray(Roles, role_new).name;

        let rows;
        let newContract;
        let newContract111 = [];
        if (roleName == "Admin" || roleName == "BTGD") {
            newContract = Contracts;
        } else if (roleName == "KTT" || roleName == "BGD") {
            let centerOfUser = filter_Users(Users, UserID).centers;
            // console.log(centerOfUser,'====1111===')
            if (centerOfUser != undefined) {
                if (Contracts.length > 0) {
                    newContract111 = filter_new_contract(Contracts, centerOfUser);
                }
            }
            newContract = newContract111;
        } else {
            // console.debug(UserManagers,UserID,roleName,get_user_id(UserID, UserManagers),'sdsd')
            let newContract1 = Contracts.filter((v) => get_user_id(UserID, UserManagers).includes(v.user._id));
            if (roleName == "AM") {
                newContract = newContract1;
            } else {
                let contract_me = Contracts.filter((v) => v.user._id == UserID);
                let newContract2 = newContract1.concat(contract_me);
                newContract = newContract2;
            }
        }

        function FindArrayUserCapCaoHon(UserID , array_user, roleName) {
			let returnObject = array_user.find(x => x._id == UserID);
			let rs = []
			let filteredArray = [];

			switch(roleName){
				case 'AM':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
						  	}
						});
						let manage_UserId = FindArrayUserManagers(UserManagers,UserID);
						if(manage_UserId.length > 0){
							let manager_id_new = manage_UserId[0]['manager_id'];
							let managerObject = array_user.find(x => x._id == manager_id_new);
							if(managerObject != undefined){
								filteredArray.push(managerObject);
							}
						}
					}
					break;
				case 'TPKD':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
						  	}else{
						  		return item.role_details[0].name == 'BTGD'
						  	}
						});
					}
					// console.log('2')
					break;
				case 'KTT':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD' && item.role_details[0].name != 'KTT';
						  	} else {
						  		return item.role_details[0].name == 'BTGD'
						  	}
						});
					}
					break;
				case 'BGD':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
						  	return item.role_details[0].name == 'BTGD';
						});
					}
					// console.log('3')
					break;
				case 'BTGD':
				case 'Admin':
					filteredArray = array_user
					break;
			}

			if (filteredArray.length > 0) {
				return filteredArray.map(item => item._id);
			}

			return filteredArray
		}

        // let filteredHistoryChanges = historyChanges.filter(x => x.contract == params.idcontract && x.form != 'contract')
        // let requesterList = FindArrayHistoryCapCaoHon(UserID , Users, roleName)
        // let filteredHistoryChangesCapCaoHon = filteredHistoryChanges.filter(item => {
        //     return requesterList.includes(item.requester); 
        // });
        //////// new update 2024-03-16
        let filteredHistoryChangesCapTrenEdit = historyChanges.filter(x => x.contract == params.idcontract && x.form != 'contract' && x.requester != UserID)
        let StatusID = get_status(Historys,params.idcontract) != '' ? get_status(Historys,params.idcontract).StatusID : '0'
        let userContact = Contracts.filter(x => x._id == params.idcontract)
        let kiemTraChoPhepTruyCap = checkUsersAccessContact(Historys, roleName, UserID, StatusID, filteredHistoryChangesCapTrenEdit, userContact, Users, Roles)
        let checkEditOrView = checkEditOrViewDetail(Historys, roleName, UserID, StatusID, filteredHistoryChangesCapTrenEdit, userContact, Users, Roles)

        const contract_me_access = FindArray(newContract, params.idcontract)
        const contract_me_new_access = FindArray(Contracts, params.idcontract)
        console.debug("StatusID", StatusID)
        // console.debug("contract_me_access", contract_me_access == "", get_user_id(UserID,UserManagers))
        // console.debug("contract_me_new_access", contract_me_new_access == "", get_user_id(UserID,UserManagers))

        //loc cha, loc con
        let user_access = get_user_id(UserID,UserManagers)
        if (roleName != "AM") user_access.push(UserID)
        const userNew = (_id == "65676f9ffaea227259381017") ? Users : findUsers(Users, roleName, UserID); //  || 63c0938fed60f11521b00875
        const uniqueUserNew = (userNew.length > 0 ) ? [...new Set(userNew.map(obj => obj._id))] : []
        let newUserManagers = []
        if (roleName == "AM" || roleName == "TPKD") {
            newUserManagers = user_access
        }else if (roleName == "KTT" || roleName == "BGD") {
            if (roleName == "KTT") {
				let listReceiverCaoHon = FindArrayUserCapCaoHon(UserID, Users, roleName)
				let newUserManagers1 = (user_access.length > 0 ) ? user_access.concat(uniqueUserNew) : (uniqueUserNew.length > 0 ) ? user_access.concat(uniqueUserNew) : []
				newUserManagers = (newUserManagers1.length > 0 ) ? newUserManagers1.concat(listReceiverCaoHon) : (listReceiverCaoHon.length > 0 ) ? listReceiverCaoHon.concat(newUserManagers1) : []
				// console.debug(1111111, newUserManagers) //  || 63c0938fed60f11521b00875)
			}else {
				newUserManagers = (user_access.length > 0 ) ? user_access.concat(uniqueUserNew) : (uniqueUserNew.length > 0 ) ? user_access.concat(uniqueUserNew) : []
			}
            // newUserManagers = (user_access.length > 0 ) ? user_access.concat(uniqueUserNew) : (uniqueUserNew.length > 0 ) ? user_access.concat(uniqueUserNew) : []
        }else if (roleName == "Admin" || roleName == "BTGD") {
            newUserManagers = uniqueUserNew
        }

        //
        function filter_new_contract(array, UserID) {
            let returnObject = "";
            // if (UserID != undefined) returnObject = array.filter((element) => element.user.centers != null ? element.user.centers[0] : '' == UserID[0]);
            if (UserID != undefined) returnObject = array.filter((element) => element.CenterID == UserID[0]);
            if (returnObject != undefined) {
                return returnObject;
            } else {
                return "";
            }
        }

        function filter_Users(array, UserID) {
            let returnObject = "";
            if (UserID != undefined) returnObject = array.filter((element) => element._id === UserID);
            let lastElement = returnObject.pop();
            if (lastElement != undefined) {
                return lastElement;
            } else {
                return "";
            }
        }

        function filter_history(array, ContractID) {
            let returnObject = "";
            if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
            let lastElement = returnObject.pop();
            if (lastElement != undefined) {
                return lastElement;
            } else {
                return "";
            }
        }

        function get_user_id(userLogin, userManager) {
            let managedUsers = [];

            function findManagedUsers(managerId) {
                let users = userManager.filter((item) => item.manager_id[0] === managerId);
                if (users.length > 0) {
                    for (let user of users) {
                        findManagedUsers(user.user_id[0]);
                    }
                } else {
                    managedUsers.push(managerId);
                }
            }

            findManagedUsers(userLogin);
            return managedUsers;
        }

        function findUsers(array, roleName, idUser) {
            let usersBelongCenter = []
            let filterListUsers = FindArray(array, _id)
            switch (roleName) {
                case "Admin":
                case "BTGD":
                    if (filterListUsers != undefined) {
                        usersBelongCenter = array
                    }
                    break;
                    // if (filterListUsers != undefined) {
                    //     usersBelongCenter = filter_new_user(array, null, null, idUser, ["BGD", "KTT", "TPKD", "AM"])
                    // }
                    // break;
                case "BGD":
                    if (filterListUsers != undefined) {
                        usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["KTT", "TPKD", "AM"])
                    }
                    break;
                case "KTT":
                    if (filterListUsers != undefined) {
                        usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["TPKD", "AM"])
                    }
                    break;
                case "TPKD":
                    if (filterListUsers != undefined) {
                        usersBelongCenter = filter_new_user(array, filterListUsers.centers, filterListUsers.departments, idUser, ["AM"])
                    }
                    break;
            }
            // console.log("usersBelongCenter", usersBelongCenter)
            return usersBelongCenter
        }

        function filter_new_user(array, centers_id, department_id = null, idUser, listRole = null){
            let returnObject = [];
            if (centers_id !== null) {
                array.map(element => {
                    if (element.centers) {
                        if (element.centers[0] === centers_id[0] && element._id !== idUser && listRole.includes(element.role_details[0].name)) {
                            returnObject.push(element)
                        }
                    }
                })
            }else {
                array.map(element => {
                    if (element.centers) {
                        if (element._id !== idUser) {
                            returnObject.push(element)
                        }
                    }
                })

            }
            return returnObject
        }

        function get_status(array,ContractID){
            let returnObject = "";
            if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
            let lastElement = returnObject.pop();
            if(lastElement != undefined){
                return lastElement;
            }else{
                return '';
            }
        }

        function checkUsersAccessContact(history, roleName, UserID, statusID, filteredHistoryChangesCapTrenEdit, userContract, usersarray, rolearray) {
            let userContractAccess = (typeof userContract[0].user != "undefined" && typeof userContract[0].user != "string") ? userContract[0].user.roles[0] : (typeof userContract[0].user_details != "undefined" && typeof userContract[0].user_details == "object") ? userContract[0].user_details[0].roles[0] : ""
            let nameUserContract = FindArrayTPKD(usersarray, userContract[0].user, rolearray);
            let roleUserContract = userContractAccess
            let nameRoleContract = FindArrayRoleBTGD(Roles,roleUserContract).name;

            let access = false
            let historyContract = '';
            if(filteredHistoryChangesCapTrenEdit != null){
                if(filteredHistoryChangesCapTrenEdit.length > 0){
                    historyContract = filteredHistoryChangesCapTrenEdit.filter(x => x.contract === params.id && x.form != 'contract');
                }
            }

            // console.debug(111, usersarray, userContract, rolearray)
            // usersarray.map(x=> console.debug(UserID, x.user))
            // console.debug(111, {roleName, statusID, nameUserContract})
            // console.debug(1111, history, roleName, UserID, statusID, filteredHistoryChangesCapTrenEdit, userContract, usersarray, rolearray)

            switch(roleName){
                case 'AM':
                    switch(statusID){
                        case "1":
                        case "-1":
                            access = false
                            break;

                        case "2": 
                            access = false
                            break;

                        case "3":
                        case "5":
                        case "7":
                        case "-2":
                        case "-4":
                        case "-6":
                        case "6":
                        case "8":
                        case "9":
                        case "4":
                            if (historyContract != "") {
                                access = true
                            }else {
                                access = false
                            }
                            break;
                        case '10': 
                            access = true
                            break;
                    }
                    break;
                case 'TPKD':
                    switch(statusID){
                        case "1":
                            if (roleName == nameRoleContract) {
                                access = false
                            }else {
                                access = false
                            }
                            break;
                        case "2":
                            access = false
                            break;
                        case "-2":
                            access = false
                            break;
                        case "5":
                        case "7":
                        case "-4":
                        case "-6":
                        case "6":
                        case "8":
                        case "9":
                            if(historyContract != ''){
                                access = true
                            }else{
                                access = false
                            }
                            break;
                        case "10":
                            access = true
                            break;
                        case "3":
                            access = false
                            break;
                        case "-1":
                        case "4": 
                            access = false
                            break;
                    }
                    break;
                case 'KTT':
					switch(statusID){
						case "4": 
							access = false
							break;
						case "1": 
							if (roleName == nameUserContract) {
								access = false
							}else {
								if(nameRoleContract == "BTGD"){
									access = false
								}else{
									access = false
								}
							}
							break;
						case "5":
							access = false
							break;
						case "7":
						case "9":
							if (historyContract != "") {
								access = false // false if page Render_Button_Invoice
							}else {
								access = false // false if page Render_Button_Invoice
							}
							break;
						case "-4":
						case "-6":
						case "8":
							if (historyContract != "") {
								access = false
							}else {
								if(statusID == -4){
									access = false
								}else if(statusID == -6){
									access = false
								}else{
									access = false // false if page Render_Button_Invoice
								}
							}
							break;
						case "-1":
						case "2":
						case "3":
							access = false
							break;
						case "10":
							access = true
							break;
						case "-2":
						case "6":
						case "7":
							access = false
							break;
						}
                    break;
                case 'BGD':
                    switch(statusID){
						case "4":
						case "6":
							access = false
							break;
						case "1": 
							if (roleName == nameRoleContract) {
								access = false
							}else {
								access = false
							}
							break;
						case "7":
							access = false
							break;
						case "8":
							access = false
							break;
						case "-1":
						case "2":
						case "-2":
						case "5":
						case "3":
						case "4":
							access = false
							break;
						case "10":
							access = true
							break;
						case "-6":
							access = false
							break;
						case "9":
							if(historyContract != ''){
									access = true
								}else{
									access = false
								}
							break;
						default: 
							if (statusID < 9) {
								access = false
							}else {
								access = false
							}
							break;
					}
                    break;
                case 'BTGD':
                case 'Admin':
                    switch(statusID){
                        case "1": 
                            if (roleName == nameRoleContract) {
                                access = false
                            }else {
                                access = false
                            }
                            break;
                        case "10":
                            access = true
                            break;
                        default: 
                            access = false
                            break;
                    }
                    break;
            }

            console.debug(roleName, "checkUsersAccessContact:::return access:::", access)
            return access
        }

        function checkEditOrViewDetail(history, roleName, UserID, statusID, filteredHistoryChangesCapTrenEdit, userContract, usersarray, rolearray) {
			let userContractAccess = (typeof userContract[0].user != "undefined" && typeof userContract[0].user != "string") ? userContract[0].user.roles[0] : (typeof userContract[0].user_details != "undefined" && typeof userContract[0].user_details == "object") ? userContract[0].user_details[0].roles[0] : ""
            let nameUserContract = FindArrayTPKD(usersarray, userContract[0].user, rolearray);
            let roleUserContract = userContractAccess
            let nameRoleContract = FindArrayRoleBTGD(Roles,roleUserContract).name;

			let access = "none"
			let historyContract = '';
			if(filteredHistoryChangesCapTrenEdit != null){
				if(filteredHistoryChangesCapTrenEdit.length > 0){
					historyContract = filteredHistoryChangesCapTrenEdit.filter(x => x.contract === params.id && x.form != 'contract');
				}
			}

			switch(roleName){
				case 'AM':			
					switch(statusID){
						case "1":
						case "-1":
							access = "edit"
							break;

						case "2": 
							access = "view"
							break;

						case "3":
						case "5":
						case "7":
						case "-2":
						case "-4":
						case "-6":
						case "6":
						case "8":
						case "9":
						case "4":
							if (historyContract != "") {
								access = "none"
							}else {
								access = 'view'
							}
							break;
						case "10": 
							access = "none"
							break;
					}
					break;
				case 'TPKD':
					switch(statusID){
						case "1":
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
						case "2":
							access = "edit"
							break;
						case "-2":
							access = "edit"
							break;
						case "5":
						case "7":
						case "-4":
						case "-6":
						case "6":
						case "8":
						case "9":
							if(historyContract != ''){
								access = "none"
							}else{
								access = "view"
							}
							break;
						case "10":
							access = "none"
							break;
						case "3":
							access = "edit"
							break;
						case "-1":
						case "4": 
							access = "view"
							break;
					}
					break;
				case 'KTT':
					switch(statusID){
						case "4": 
							access = "edit"
							break;
						case "1": 
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								if(nameRoleContract == "BTGD"){
									access = "edit"
								}else{
									access = "view"
								}
							}
							break;
						case "5":
							access = "edit"
							break;
						case "7":
						case "9":
							if (historyContract != "") {
								access = "view" // false if page Render_Button_Invoice
							}else {
								access = "view" // false if page Render_Button_Invoice
							}
							break;
						case "-4":
						case "-6":
						case "8":
							if (historyContract != "") {
								access = "none"
							}else {
								if(statusID == -4){
									access = "edit"
								}else if(statusID == -6){
									access = "view"
								}else{
									access = "view" // false if page Render_Button_Invoice
								}
							}
							break;
						case "-1":
						case "2":
						case "3":
							access = "view"
							break;
						case "10":
							access = "none"
							break;
						case "-2":
						case "6":
						case "7":
							access = "view"
							break;
						}
					break;
				case 'BGD':
					switch(statusID){
						case "4":
						case "6":
							access = "view"
							break;
						case "1": 
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
						case "7":
							access = "view"
							break;
						case "8":
							access = "view"
							break;
						case "-1":
						case "2":
						case "-2":
						case "5":
						case "3":
						case "4":
							access = "view"
							break;
						case "10":
							access = "none"
							break;
						case "-6":
							access = "edit"
							break;
						case "9":
							if(historyContract != ''){
									access = "none"
								}else{
									access = "view"
								}
							break;
						default: 
							if (statusID < 9) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
					}
					break;
				case 'BTGD':
				case 'Admin':
					switch(statusID){
						case "1": 
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
						case "10":
							access = "none"
							break;
						default: 
							access = "view"
							break;
					}
					break;
			}

			console.debug(roleName, "checkEditOrView:::return access:::", access)
			return access
		}

        function FindArrayTPKD(array, ID, Roles) {
            let returnObject = "";
            if (ID != undefined) returnObject = array.find(x => x._id === ID._id);
            if(returnObject != undefined){
                return returnObject.role_details[0].name;
            }else{
                return '';
            }
        }

        function FindArrayRoleBTGD(array, ID) {
            let returnObject = "";
            if (ID != undefined) returnObject = array.find(x => x._id === ID);
            if(returnObject != undefined){
                return returnObject;
            }else{
                return '';
            }
        }

        //////

        if (ProductCostsLoading) {
            body = (
                <div className="spinner-container">
                    <Spinner animation="border" variant="info" />
                </div>
            );
        } else if (ProductCosts.length === 0 || ProductCosts[0].ListProducts.length === 0) {
            body = (
                <>
                    <Card className="text-center mx-5 my-5">
                        <Card.Header as="h1">CHI TIẾT HÀNG HÓA</Card.Header>
                        <Card.Body>
                            <Table responsive="sm" striped bordered hover size="sm">
                                <thead>
                                    <tr style={{ textAlign: "left" }}>
                                        <th colSpan={16}>Số hợp đồng/PO: {idcontractNew}</th>
                                    </tr>
                                    <tr style={{ textAlign: "left" }}>
                                        <th colSpan={16}>Khách hàng: {customer_name}</th>
                                    </tr>
                                    <tr>
                                        <th colSpan={8}></th>
                                        <th>Tỷ Giá</th>
                                        <th>{Contracts != "" ? Contracts[0].RatioUSD.toLocaleString("vi-VN", { style: "decimal", decimal: "VND" }) : 0}</th>
                                        <th colSpan={4}></th>
                                        <th colSpan={1}>
                                            {
                                                checkEditOrView == "none" || checkEditOrView == "view" ? 
                                                <>
                                                </>
                                                :
                                                <>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip">Tải file excel</Tooltip>}>
                                                        <a className="me-2 mb-2" onClick={downloadFile}>
                                                            <img src={downLoadExcel} width="36" height="36"></img>
                                                        </a>
                                                    </OverlayTrigger>
                                                </>
                                            } 
                                        </th>
                                        <th colSpan={1}>
                                            {
                                                checkEditOrView == "none" || checkEditOrView == "view" ? 
                                                <>
                                                </>
                                                :
                                                <>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip">Nhập file excel</Tooltip>}>
                                                        <a
                                                            className="me-2 mb-2"
                                                            onClick={() => {
                                                                setshowImportProductCostModal(true);
                                                                _setRadioUSD(Contracts[0].RatioUSD);
                                                            }}
                                                        >
                                                            <img src={importExcel} width="36" height="36"></img>
                                                        </a>
                                                    </OverlayTrigger>
                                                </>
                                            }
                                        </th>
                                    </tr>
                                    <tr style={{ textAlign: "center" }}>
                                        <th rowSpan="2" style={{ verticalAlign: "inherit" }}>
                                            STT
                                        </th>
                                        <th rowSpan="2" width="15%" style={{ verticalAlign: "inherit" }}>
                                            Tên hàng
                                        </th>
                                        <th rowSpan="2" width="5%" style={{ verticalAlign: "inherit" }}>
                                            Số lượng
                                        </th>
                                        <th colSpan="5" style={{ verticalAlign: "inherit" }}>
                                            Giá vốn hàng bán Giá kho
                                        </th>
                                        <th colSpan="2" style={{ verticalAlign: "inherit" }}>
                                            Doanh số Giá bán
                                        </th>
                                        <th rowSpan="2" style={{ verticalAlign: "inherit" }}>
                                            Giá trị Incentive (nếu có)
                                        </th>
                                        <th rowSpan="2" width="8%" style={{ verticalAlign: "inherit" }}>
                                            Có tính Chi Phí Bảo Hành kg
                                        </th>
                                        <th rowSpan="2" width="8%" style={{ verticalAlign: "inherit" }}>
                                            Chi phí hỗ trợ kĩ thuật HSE
                                        </th>
                                        <th rowSpan="2" width="8%" style={{ verticalAlign: "inherit" }}>
                                            Mua nội bộ
                                        </th>
                                        <th rowSpan="2" width="8%" style={{ verticalAlign: "inherit" }}>
                                            Ghi chú
                                        </th>
                                        <th rowSpan="2" colSpan="2" width="8%" style={{ verticalAlign: "inherit" }}>
                                            Thao tác
                                        </th>
                                    </tr>
                                    <tr>
                                        <th width="8%" as="pre">
                                            Đơn giá FOB <br />
                                            (EX-W)
                                        </th>
                                        <th>Thuế/ Phí</th>
                                        <th>Giá Kho FOB</th>
                                        <th>Đơn giá kho</th>
                                        <th>Thành tiền giá kho</th>
                                        <th>Đơn giá bán</th>
                                        <th>Thành tiền giá bán</th>
                                    </tr>
                                </thead>
                            </Table>
                            {
                                checkEditOrView == "none" || checkEditOrView == "view" ? 
                                <>
                                </>
                                :
                                <>
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            setShowAddProductCostModal(true);
                                            _setRadioUSD(Contracts[0].RatioUSD);
                                        }}
                                        style={{ marginRight: "5px" }}
                                    >
                                        Thêm mới
                                    </Button>
                                </>
                            }
                            
                            <a href={`/inputform/${params.idcontract}`}>
                                <Button variant="primary">Quay lại</Button>
                            </a>
                        </Card.Body>
                    </Card>
                </>
            );
        } else {
            console.debug("abc", {typeof: typeof checkEditOrView, checkEditOrView, check: checkEditOrView == 'view' })
            body = (
                <>
                    <Card className="text-center mx-5 my-5">
                        <Card.Header as="h1">CHI TIẾT HÀNG HÓA</Card.Header>
                        <Card.Body>
                            <Table responsive="sm" striped bordered hover size="sm">
                                {ProductCosts.map((ProductCost) => (
                                    <>
                                        <thead>
                                            <tr style={{ textAlign: "left" }}>
                                                <th colSpan={17}>Số hợp đồng/PO: {Contracts != "" ? Contracts[0].ContractID : ""}</th>
                                            </tr>
                                            <tr style={{ textAlign: "left" }}>
                                                <th colSpan={17}>Khách hàng: {customer_name}</th>
                                            </tr>
                                            <tr>
                                                <th colSpan={8}></th>
                                                <th>Tỷ Giá</th>
                                                <th>{Contracts != "" ? Contracts[0].RatioUSD.toLocaleString("vi-VN", { style: "decimal", decimal: "VND" }) : 0}</th>
                                                <th colSpan={5}></th>
                                                <th colSpan={1}>
                                                    {
                                                        checkEditOrView == "none" || checkEditOrView == "view" ? 
                                                        <>
                                                        </>
                                                        :
                                                        <>
                                                            <OverlayTrigger overlay={<Tooltip id="tooltip">Tải file excel</Tooltip>}>
                                                                <a className="me-2 mb-2" onClick={downloadFile}>
                                                                    <img src={downLoadExcel} width="36" height="36"></img>
                                                                </a>
                                                            </OverlayTrigger>
                                                        </>
                                                    } 
                                                </th>
                                                <th colSpan={1}>
                                                    {
                                                        checkEditOrView == "none" || checkEditOrView == "view" ? 
                                                        <>
                                                        </>
                                                        :
                                                        <>
                                                            <OverlayTrigger overlay={<Tooltip id="tooltip">Nhập file excel</Tooltip>}>
                                                                <a
                                                                    className="me-2 mb-2"
                                                                    onClick={() => {
                                                                        setshowImportProductCostModal(true);
                                                                        _setRadioUSD(Contracts[0].RatioUSD);
                                                                    }}
                                                                >
                                                                    <img src={importExcel} width="36" height="36"></img>
                                                                </a>
                                                            </OverlayTrigger>
                                                        </>
                                                    }
                                                </th>
                                            </tr>
                                            <tr key={ProductCost._id} style={{ textAlign: "center" }}>
                                                <th rowSpan="2" style={{ verticalAlign: "inherit" }}>
                                                    STT
                                                </th>
                                                <th rowSpan="2" width="15%" style={{ verticalAlign: "inherit" }}>
                                                    Tên hàng
                                                </th>
                                                <th rowSpan="2" width="5%" style={{ verticalAlign: "inherit" }}>
                                                    Số lượng
                                                </th>
                                                <th colSpan="5" style={{ verticalAlign: "inherit" }}>
                                                    Giá vốn hàng bán Giá kho
                                                </th>
                                                <th colSpan="2" style={{ verticalAlign: "inherit" }}>
                                                    Doanh số Giá bán
                                                </th>
                                                <th rowSpan="2" style={{ verticalAlign: "inherit" }}>
                                                    Giá trị Incentive (nếu có)
                                                </th>
                                                <th rowSpan="2" width="8%" style={{ verticalAlign: "inherit" }}>
                                                    Có tính Chi Phí Bảo Hành kg
                                                </th>
                                                <th rowSpan="2" width="8%" style={{ verticalAlign: "inherit" }}>
                                                    Chi phí hỗ trợ kĩ thuật HSE
                                                </th>
                                                <th rowSpan="2" width="8%" style={{ verticalAlign: "inherit" }}>
                                                    Mua nội bộ
                                                </th>
                                                {roleName == "KTT" ? (
                                                    <>
                                                        <th rowSpan="2" width="8%" style={{ verticalAlign: "inherit" }}>
                                                            Check List
                                                        </th>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
    
                                                <th rowSpan="2" width="8%" style={{ verticalAlign: "inherit" }}>
                                                    Ghi chú
                                                </th>
                                                <th rowSpan="2" colSpan="2" width="8%" style={{ verticalAlign: "inherit" }}>
                                                    Thao tác
                                                </th>
                                            </tr>
                                            <tr>
                                                <th width="8%" as="pre">
                                                    Đơn giá FOB <br />
                                                    (EX-W)
                                                </th>
                                                <th>Thuế/ Phí</th>
                                                <th>Giá Kho FOB</th>
                                                <th>Đơn giá kho</th>
                                                <th>Thành tiền giá kho</th>
                                                <th>Đơn giá bán</th>
                                                <th>Thành tiền giá bán</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ProductCost.ListProducts.map((ListProduct) => (
                                                <tr key={ListProduct._id}>
                                                    <td>{stt++} </td>
                                                    <td>{ListProduct.ProductName}</td>
                                                    <td>{ListProduct.Quantity.toLocaleString()}</td>
                                                    <td>{ListProduct.FOBCost != null ? ListProduct.FOBCost.toLocaleString() : 0}</td>
                                                    <td>{ListProduct.Tax != null ? ListProduct.Tax.toLocaleString() : 0}%</td>
                                                    <td>{ListProduct.StorageFOBCost != null ? ListProduct.StorageFOBCost.toLocaleString() : 0}</td>
                                                    <td>{ListProduct.InputPrice != null ? ListProduct.InputPrice.toLocaleString() : 0}</td>
                                                    <td>{ListProduct.InputIntoMoney != null ? ListProduct.InputIntoMoney.toLocaleString() : 0}</td>
                                                    <td>{ListProduct.OutputPrice != null ? ListProduct.OutputPrice.toLocaleString() : 0}</td>
                                                    <td>{ListProduct.OutputIntoMoney != null ? ListProduct.OutputIntoMoney.toLocaleString() : 0}</td>
                                                    <td>{ListProduct.Incentive != null ? ListProduct.Incentive.toLocaleString() : 0}</td>
                                                    <td>{ListProduct.Insurance == true ? <img src={download} width="20" height="20"></img> : ""}</td>
                                                    <td>{ListProduct.InsuranceHSE == true ? (ListProduct.OutputIntoMoney * ((ListProduct.PercentHSE != null ? ListProduct.PercentHSE : 1) / 100)).toLocaleString() : "0"}</td>
                                                    <td>{ListProduct.InternalPurchase == true ? <img src={download} width="20" height="20"></img> : ""}</td>
                                                    {roleName == "KTT" ? (
                                                        <>
                                                            <td>
                                                                <Form.Group>
                                                                    <Form.Check type="checkbox" checked={ListProduct.CheckList} onChange={(e) => toggleEX_W(e.target.checked, ListProduct._id)} />
                                                                </Form.Group>
                                                            </td>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <td>{ListProduct.Note}</td>
                                                    <td colSpan="2">
                                                        {
                                                            checkEditOrView == "none" || checkEditOrView == "view" ? 
                                                            <>
                                                            </>
                                                            :
                                                            <>
                                                                <ActionButtons_ProductCost
                                                                    contract={ProductCost.contract}
                                                                    idProduct={ListProduct._id}
                                                                    ProductName={ListProduct.ProductName}
                                                                    Quantity={ListProduct.Quantity}
                                                                    FOBCost={ListProduct.FOBCost}
                                                                    InputPrice={ListProduct.InputPrice}
                                                                    InputIntoMoney={ListProduct.InputIntoMoney}
                                                                    OutputPrice={ListProduct.OutputPrice}
                                                                    OutputIntoMoney={ListProduct.OutputIntoMoney}
                                                                    Insurance={ListProduct.Insurance}
                                                                    InsuranceHSE={ListProduct.InsuranceHSE}
                                                                    RatioUSD={Contracts != "" ? Contracts[0].RatioUSD : 0}
                                                                    Note={ListProduct.Note}
                                                                    EX_W={ListProduct.EX_W}
                                                                    StorageFOBCost={ListProduct.StorageFOBCost}
                                                                    Tax={ListProduct.Tax}
                                                                    Incentive={ListProduct.Incentive}
                                                                    PercentHSE={ListProduct.PercentHSE}
                                                                    InternalPurchase={ListProduct.InternalPurchase}
                                                                    CheckList={ListProduct.CheckList}
                                                                />
                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td colSpan={7}>Tổng</td>
                                                <td>{Sum_InputIntoMoney(ProductCosts).toLocaleString()}</td>
                                                <td></td>
                                                <td>{Sum_OutputIntoMoney(ProductCosts).toLocaleString()}</td>
                                                <td>{Sum_Incentive(ProductCosts).toLocaleString()}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            {/*<tr>
                                                <td colSpan={7} > Incentive (nếu có)</td>
                                                <td>{ProductCost.Incentive == null ? 0 : ProductCost.Incentive.toLocaleString()}</td>
                                                <td></td>
                                                <td></td>
                                                <td colSpan={2}>
                                                    <Button
                                                        variant='primary'
                                                        onClick={setShowAddIncentive_Modal.bind(this, true)}
                                                    >
                                                        Nhập Incentive
                                                    </Button></td>
                                            </tr>*/}
                                            <tr>
                                                <td colSpan={17}>
                                                    {
                                                        checkEditOrView == "none" || checkEditOrView == "view" ? 
                                                        <>
                                                        </>
                                                        :
                                                        <>
                                                            <Button
                                                                variant="primary"
                                                                onClick={() => {
                                                                    setShowAddProductCostModal(true);
                                                                    _setRadioUSD(Contracts[0].RatioUSD);
                                                                }}
                                                            >
                                                                Thêm hàng hoá
                                                            </Button>
                                                        </>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </>
                                ))}
                            </Table>
                            <a href={`/summary/${params.idcontract}`}>
                                <Button variant="primary">Xem PTHĐ</Button>
                            </a>
                            <span> </span>
                            <a href={`/inputform/${params.idcontract}`}>
                                <Button variant="primary">Quay lại</Button>
                            </a>
                        </Card.Body>
                    </Card>
                </>
            );
        }

        let check_access = contract_me_access == ""
        let check_access_new = contract_me_new_access == ""
        console.debug("ProductCostsLoading", ProductCostsLoading)

        if (kiemTraChoPhepTruyCap) {
            // if (ProductCostsLoading) {
            //     return (
            //         <>
            //             <div className="spinner-container">
            //                 <Spinner animation="border" variant="info" />
            //             </div>
            //         </>
            //     )
            // }else {
                return (
                    <>
                        <div id="notfound">
                            <div className="notfound">
                                <h1>Oops!</h1>
                                <h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng kiểm tra lại thông tin.
                                    <br/>
                                    <Link style={{textDecoration: "none"}} to="/home">
                                        Trở về trang chủ
                                    </Link>
                                </h6>
                            </div>
                        </div>
                    </>
                )
            // }
        }else {
            if ((check_access == true && check_access_new  == false) || (check_access == false && check_access_new  == true) || (check_access == false && check_access_new  == false)) {
                // console.debug(1, typeof contract_me_new_access.user, contract_me_new_access)
                if (typeof contract_me_new_access.user == "string") {
                    // console.debug("1 thu gian: cho phep truy cap")
                    if (newUserManagers.includes(contract_me_new_access.user) == true) {
                        // console.debug("11 thu gian:: truy cap duoc", contract_me_new_access.user)
                        return (
                            <>
                                {body}
                                <AddProductCostModal />
                                <Add_Incentive />
                                <UpdateProductCostModal />
                                <ImportProductCostModal />
                                <Toast
                                    show={show}
                                    style={{ position: "fixed", top: "20%", right: "10px", zIndex: "99999" }}
                                    className={`bg-${type} text-white`}
                                    onClose={setShowToast.bind(this, {
                                        show: false,
                                        message: "",
                                        type: null,
                                    })}
                                    delay={3000}
                                    autohide
                                >
                                    <Toast.Body>
                                        <strong>{message}</strong>
                                    </Toast.Body>
                                </Toast>
                            </>
                        )
                    }else {
                        // console.debug("12 thu gian:: truy cap duoc", contract_me_new_access.user)
                        return (
                            <>
                                <div id="notfound">
                                    <div className="notfound">
                                        <h1>Oops!</h1>
                                        <h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
                                            <br/>
                                            <Link style={{textDecoration: "none"}} to="/home">
                                                Trở về trang chủ
                                            </Link>
                                        </h6>
                                    </div>
                                </div>
                            </>
                        )
                    }
                }else if (typeof contract_me_new_access.user == "object") {
                    // console.debug("2 thu gian:: khong truy cap duoc", contract_me_new_access.user._id)
                    // console.debug(newUserManagers.includes(contract_me_new_access.user._id))
                    if (newUserManagers.includes(contract_me_new_access.user._id) == true) {
                        // console.debug("13 thu gian:: truy cap duoc", contract_me_new_access.user._id)
                        return (
                            <>
                                {body}
                                <AddProductCostModal />
                                <Add_Incentive />
                                <UpdateProductCostModal />
                                <ImportProductCostModal />
                                <Toast
                                    show={show}
                                    style={{ position: "fixed", top: "20%", right: "10px", zIndex: "99999" }}
                                    className={`bg-${type} text-white`}
                                    onClose={setShowToast.bind(this, {
                                        show: false,
                                        message: "",
                                        type: null,
                                    })}
                                    delay={3000}
                                    autohide
                                >
                                    <Toast.Body>
                                        <strong>{message}</strong>
                                    </Toast.Body>
                                </Toast>
                            </>
                        )
                    }else {
                        // console.debug("14 thu gian:: truy cap duoc", contract_me_new_access.user._id)
                        if (ProductCostsLoading) {
                            return (
                                <>
                                    <div className="spinner-container">
                                        <Spinner animation="border" variant="info" />
                                    </div>
                                </>
                            )
                        }else {
                            return (
                                <>
                                    <div id="notfound">
                                        <div className="notfound">
                                            <h1>Oops!</h1>
                                            <h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
                                                <br/>
                                                <Link style={{textDecoration: "none"}} to="/home">
                                                    Trở về trang chủ
                                                </Link>
                                            </h6>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    }
                }
            }else {
                return (
                    <>
                        <div id="notfound">
                            <div className="notfound">
                                <h1>Oops!</h1>
                                <h6>Rất tiếc, Hợp đồng không được tìm thấy trong hệ thống. Vui lòng kiểm tra lại.
                                    <br/>
                                    <Link style={{textDecoration: "none"}} to="/home">
                                        Trở về trang chủ
                                    </Link>
                                </h6>
                            </div>
                        </div>
                    </>
                )
            }
        }
    }
}