import { createContext, useReducer, useState } from 'react'
import { HistoryChangeReducer } from '../reducers/HistoryChangeReducer'
import {
	apiUrl,
	LOADED_FAIL,
	LOADED_SUCCESS,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from './constants'
import axios from 'axios'

export const HistoryChangeContext = createContext()

const HistoryChangeContextProvider = ({ children }) => {
	// State
	const [historyChangeState, dispatch] = useReducer(HistoryChangeReducer, {
		historyChange: null,
		historyChanges: [],
		historyChangesLoading: true
	})

	const [showAddHistoryChangeModal, setShowAddHistoryChangeModal] = useState(false)
	const [showUpdateHistoryChangeModal, setShowUpdateHistoryChangeModal] = useState(false)
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	})

	// Get all posts
	const getHistoryChanges = async () => {
		try {
			const response = await axios.get(`${apiUrl}/api/forms/history_change`)
			if (response.data.success) {
				dispatch({ type: LOADED_SUCCESS, payload: response.data.HistoryChange })
			}
		} catch (error) {
			dispatch({ type: LOADED_FAIL })
		}
	}

	// Add post
	const addHistoryChange = async newHistoryChange => {
		console.log(newHistoryChange)
		try {
			const response = await axios.post(`${apiUrl}/api/forms/history_change/post`, newHistoryChange)
			if (response.data.success) {
				// dispatch({ type: ADD, payload: response.data.HistoryChanges })
				getHistoryChanges()
				return response.data
			}
		} catch (error) {
			console.log(error)
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	// Delete post
	const deletePost = async postId => {
		try {
			const response = await axios.delete(`${apiUrl}/posts/${postId}`)
			if (response.data.success)
				// dispatch({ type: DELETE, payload: postId })
				getHistoryChanges()
		} catch (error) {
			console.log(error)
		}
	}

	// Find post when user is updating post
	const findPost = postId => {
		const post = postState.posts.find(post => post._id === postId)
		dispatch({ type: FIND, payload: post })
	}

	// Update post
	const updatePost = async updatedPost => {
		try {
			const response = await axios.put(
				`${apiUrl}/posts/${updatedPost._id}`,
				updatedPost
			)
			if (response.data.success) {
				// dispatch({ type: UPDATE, payload: response.data.post })
				getHistoryChanges()
				return response.data
			}
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	// Post context data
	const HistoryChangeContextData = {
		historyChangeState,
		getHistoryChanges,
		showAddHistoryChangeModal,
		setShowAddHistoryChangeModal,
		showUpdateHistoryChangeModal,
		setShowUpdateHistoryChangeModal,
		addHistoryChange,
		showToast,
		setShowToast,
		deletePost,
		findPost,
		updatePost
	}

	return (
		<HistoryChangeContext.Provider value={HistoryChangeContextData}>
			{children}
		</HistoryChangeContext.Provider>
	)
}

export default HistoryChangeContextProvider
