import { createContext, useReducer, useState } from 'react'
import { DepartmentReducer } from '../reducers/DepartmentReducer'
import {
	apiUrl,
	LOADED_FAIL,
	LOADED_SUCCESS,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from './constants'//Note
import axios from 'axios'

export const ConfigDepartmentContext = createContext()

const ConfigDepartmentContextProvider = ({ children }) => {
	// User context data
	const [DepartmentState,  dispatch] = useReducer(DepartmentReducer, {
		Department: [],
		Departments: [],
		DepartmentsLoading: true
	});

	const [showAddDepartmentModal, setShowAddDepartmentModal] = useState(false);
	const [showUpdateDepartmentModal, setShowUpdateDepartmentModal] = useState(false)
	const [Data_update, setData_update] = useState([])
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	});



	const addDepartments = async newDepartment => {
		try {
			const response = await axios.post(`${apiUrl}/api/forms/department/post/`, newDepartment)
			if (response.data.success) {
				// dispatch({ type: ADD, payload: response.data.LoanInterest })
				getDepartment();
				return response.data 
			}
			else
				return response.data //Message hop dong kg ton tai
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	// Delete Department
	const delete_Department = async DepartmentId => {
		try {
			const response = await axios.delete(`${apiUrl}/api/forms/department/delete/${DepartmentId.id}`)//note
			if (response.data.success){
				// dispatch({ type: DELETE, payload: response.data.deletedProducts })
				getDepartment()
				return response.data
			}
		} catch (error) {
			console.log(error)
		}
	}

	// Update Department
	const update_Department = async Departments => {
		try {
			const response = await axios.put(
				`${apiUrl}/api/forms/department/put/`, //note xem trong server
				Departments
			)
			if (response.data.success) {
				// dispatch({ type: UPDATE, payload: response.data.updateProduct }) //note MandayKysu biến trả về từ server
				getDepartment()
				return response.data
			}
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	const getDepartment = async newDepartment => {
		try {
			const response = await axios.get(`${apiUrl}/api/forms/department/${newDepartment}`)
			if (response.data.success) {
				dispatch({ type: LOADED_SUCCESS, payload: response.data.Department })
				// getLoanInterest_byidContract(newLoanInterest['contract'])
			}
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	// const getDepartmentDefault = async newDepartment => {
	// 	try {
	// 		const response = await axios.get(`${apiUrl}/api/forms/department`)
	// 		if (response.data.success) {
	// 			dispatch({ type: LOADED_SUCCESS, payload: response.data.Department })
	// 			// console.log(response.data);
	// 			// getLoanInterest_byidContract(newLoanInterest['contract'])
	// 		}
	// 	} catch (error) {
	// 		return error.response.data
	// 			? error.response.data
	// 			: { success: false, message: 'Server error' }
	// 	}
	// }

	// const getDepartmentManday = async newDepartment => {
	// 	try {
	// 		const response = await axios.get(`${apiUrl}/api/forms/department`)
	// 		if (response.data.success) {
	// 			dispatch({ type: LOADED_SUCCESS, payload: response.data.Department })
	// 			// console.log(response.data);
	// 			// getLoanInterest_byidContract(newLoanInterest['contract'])
	// 		}
	// 	} catch (error) {
	// 		return error.response.data
	// 			? error.response.data
	// 			: { success: false, message: 'Server error' }
	// 	}
	// }

	const ConfigDepartmentContextData= {
		DepartmentState,
		addDepartments,
		getDepartment,
		// getDepartmentManday,
		showAddDepartmentModal,
		setShowAddDepartmentModal,
		showToast,
		setShowToast,

		showUpdateDepartmentModal,
		setShowUpdateDepartmentModal,

		Data_update,
		setData_update,
		update_Department,

		delete_Department
	}
	return (
		<ConfigDepartmentContext.Provider value={ConfigDepartmentContextData}>
			{children}
		</ConfigDepartmentContext.Provider>
	)
}

export default ConfigDepartmentContextProvider
