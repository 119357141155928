import {
	LOADED_SUCCESS,
	LOADED_FAIL,
	ADD,
	DELETE,
	UPDATE,
	FIND,
	ADDSTAGE,
} from '../contexts/constants'

export const ImplementationCostReducer = (state, action) => {
	const { type, payload } = action
	switch (type) {
		case ADDSTAGE:
			return {
				...state,
				ImplementationCosts: [...state.StageImplementation, payload]
			}
		case LOADED_SUCCESS:
			return {
				...state,
				ImplementationCosts: payload,
				ImplementationCostsLoading: false
			}

		case LOADED_FAIL:
			return {
				...state,
				ImplementationCosts: [],
				ImplementationCostsLoading: false
			}

		case ADD:
			// return {
			// 	state,
			// 	ImplementationCosts: [...state.ImplementationCosts, payload]
			// }
			return {
				...state,
				ImplementationCosts: [payload]
			}

		case DELETE:
			if (payload.hasOwnProperty("idCost")) {
				state.ImplementationCosts.map(function (_ImplementationCosts, _i1) {
					_ImplementationCosts.StagesImplementation.map(function (_StagesImplementation, _i2) {
						_StagesImplementation.Costs.map(function (_Costs, _i3) {
							if (_Costs._id == payload.idCost) {
								state.ImplementationCosts[_i1].StagesImplementation[_i2].Costs.splice(_i3, 1);
							}
						});
					})
				});
			}else if (payload.hasOwnProperty("idContentCost")) {
				state.ImplementationCosts.map(function (_ImplementationCosts, _i1) {
					_ImplementationCosts.StagesImplementation.map(function (_StagesImplementation, _i2) {
						if (_StagesImplementation._id == payload.idContentCost) {
							state.ImplementationCosts[_i1].StagesImplementation.splice(_i2, 1);
						}
					})
				});
			}

			return {
				...state,
				ImplementationCosts: state.ImplementationCosts
			}

		case FIND:
			return { ...state, ImplementationCost: payload}

		case UPDATE:
			// console.log("UPDATE payload", payload);
			const newImplementationCosts = state.ImplementationCosts.map(ImplementationCost =>
				ImplementationCost._id === payload._id ? payload : ImplementationCost
			)

			return {
				...state,
				ImplementationCosts: newImplementationCosts
			}

		default:
			return state
	}
}
