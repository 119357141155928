import { createContext, useReducer, useState } from 'react'
import { RemoveContractReducer } from '../reducers/RemoveContractReducer'
import {
	apiUrl,
	LOADED_FAIL,
	LOADED_SUCCESS,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from './constants'//Note
import axios from 'axios'

export const RemoveContractContext = createContext()

const RemoveContractContextProvider = ({ children }) => {
	// User context data
	const [RemoveContractState,  dispatch] = useReducer(RemoveContractReducer, {
		RemoveContract: [],
		RemoveContracts: [],
		RemoveContractsLoading: true
	});

	const [showAddRemoveContractModal, setShowAddRemoveContractModal] = useState(false);
	const [showAllRemoveContractModal, setShowAllRemoveContractModal] = useState(false);
	const [showUpdateRemoveContractModal, setShowUpdateRemoveContractModal] = useState(false);
	const [Data_update, setData_update] = useState([])
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	});


	// Delete RemoveContract
	const delete_RemoveContract = async RemoveContractId => {
		try {
			// console.log(RemoveContractId,'RemoveContractIdRemoveContractIdRemoveContractId')
			const response = await axios.delete(`${apiUrl}/api/forms/RemoveContract/delete/${RemoveContractId}`)//note
			if (response.data.success){
				return response.data
			}
			// return response.data.success;
		} catch (error) {
			console.log(error)
		}
	}

	const deleteAll_RemoveContract = async RemoveContractId => {
		try {
			// console.log(RemoveContractId,'RemoveContractIdRemoveContractIdRemoveContractId')
			const response = await axios.delete(`${apiUrl}/api/forms/RemoveContract/delete/${RemoveContractId}`)//note
			if (response.data.success){
				return response.data
			}
			// return response.data.success;
		} catch (error) {
			console.log(error)
		}
	}

	const RemoveContractContextData= {
		RemoveContractState,
		showAddRemoveContractModal,
		setShowAddRemoveContractModal,
		showAllRemoveContractModal,
		setShowAllRemoveContractModal,
		showToast,
		setShowToast,
		delete_RemoveContract,
		deleteAll_RemoveContract
	}
	return (
		<RemoveContractContext.Provider value={RemoveContractContextData}>
			{children}
		</RemoveContractContext.Provider>
	)
}

export default RemoveContractContextProvider
