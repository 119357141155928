import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MiscExpenseCostContext } from '../../contexts/MiscExpenseContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'
import { AuthContext } from '../../contexts/AuthContext'

const AddMiscExpenseCostModal = () => {
	// Contexts
	const {
		showAddMiscExpenseCostModal,
		setShowAddMiscExpenseCostModal,
		addMiscExpenseCost,
		setShowToast
	} = useContext(MiscExpenseCostContext)

	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	// State
	const [newMiscExpenseCost, setNewMiscExpenseCost] = useState({
		Content: '',
		Cost: '',
		Note: '',
		ContractID:''
	})

	const { Content, Cost, Note } = newMiscExpenseCost
	//load idcontract
	const params = useParams();
	newMiscExpenseCost.ContractID = params.id;

	const onChangeNewMiscExpenseCostForm = event =>{
		if(event.target.name != 'Content' && event.target.name != 'Note'){
			if (!isPatternNumber(event.target.value) &&  event.target.value != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				setNewMiscExpenseCost({ ...newMiscExpenseCost, [event.target.name]: event.target.value})
			}
		}else{
			setNewMiscExpenseCost({ ...newMiscExpenseCost, [event.target.name]: event.target.value})
		}
	}

	const closeDialog = () => {
		resetAddMiscExpenseCostData()
	}

	const onSubmit = async event => {
		event.preventDefault()

		if (newMiscExpenseCost.Content.trim() == '') {
			var message1 = 'Dữ liệu nhập vào sai định dạng! Vui lòng kiểm tra lại.'
			setShowToast({ show: true, message: message1, type: 'danger' })
			return false;
		}

		if (newMiscExpenseCost.Cost.trim() == '') {
			var message2 = 'Dữ liệu không được để trống! Vui lòng kiểm tra lại.'
			setShowToast({ show: true, message: message2, type: 'danger' })
			return false;
		}

		newMiscExpenseCost.Content = newMiscExpenseCost.Content.trim()
		newMiscExpenseCost.Cost = newMiscExpenseCost.Cost.trim()

		const historyChangeProductCost = {
		    "requester": _id,
		    "contract": params.id,
		    "form": "miscexpense-cost",
		    "action": "create",
		    "data_change": "create new miscexpense " +newMiscExpenseCost.Content
		}
		
		const { success, message } = await addMiscExpenseCost(newMiscExpenseCost)//newMiscExpenseCost
		const { successHis, messageHis } = await addHistoryChange(historyChangeProductCost)
		resetAddMiscExpenseCostData()
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
	}

	const resetAddMiscExpenseCostData = () => {
		setNewMiscExpenseCost({ Content: '', Cost: '', Note: '', ContractID: '' })
		setShowAddMiscExpenseCostModal(false)
	}

	const isPatternNumber = userInput => {
		if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		return /^\d+$/.test(userInput);
	}

	return (
		<Modal show={showAddMiscExpenseCostModal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>Bạn muốn thêm chi phí khác?</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					{/* <Form.Group>
						<Form.Text id='noidung-help' muted as="h6">
							Chọn Hợp đồng
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Nhập chuỗi'
							name='ContractID'
							required
							aria-describedby='noidung-help'
							value={ContractID}
							onChange={onChangeNewMiscExpenseCostForm}
						/>						
					</Form.Group> */}
					<Form.Group>
						<Form.Text id='noidung-help' muted as="h6">
							Nội dung chi phí
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Nhập chuỗi'
							name='Content'
							required
							aria-describedby='noidung-help'
							value={Content}
							onChange={onChangeNewMiscExpenseCostForm}
						/>						
					</Form.Group>
					<Form.Group>
						<Form.Text id='sotien-help' muted  as="h6">
							Số tiền
						</Form.Text>
						<Form.Control
							tpye='text'
							placeholder='Nhập số'
							name='Cost'
							value={Cost} /* tạo ràn buộc số */
							onChange={onChangeNewMiscExpenseCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='ghichu-help' muted as="h6">
							Ghi chú
						</Form.Text>
						<Form.Control
							as='textarea'
							rows={3}
							placeholder='Nhập chuỗi'
							name='Note'
							value={Note}
							onChange={onChangeNewMiscExpenseCostForm}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Thêm
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}

export default AddMiscExpenseCostModal
