import {
	LOADED_SUCCESS,
	LOADED_FAIL,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from '../contexts/constants'

export const HistoryReducer = (state, action) => {
	const { type, payload } = action
	switch (type) {
		case LOADED_SUCCESS:
			return {
				...state,
				Historys: payload,
				HistorysLoading: false
			}

		case LOADED_FAIL:
			return {
				...state,
				Historys: [],
				HistorysLoading: false
			}

		case ADD:
			return {
				...state,
				Historys: [...state.Historys, payload]
			}

		case DELETE:
			return {
				...state,
				Historys: state.Historys.filter(History => History._id !== payload)
			}

		case FIND:
			return { ...state, History: payload }

		case UPDATE:
			
			const newHistorys = state.Historys.map(History =>
				History._id === payload._id ? payload : History
			)

			return {
				...state,
				Historys: newHistorys
			}

		default:
			return state
	}
}
