import { UserContext } from '../contexts/UserContext'//Note GET DELETE
import { AuthContext } from '../contexts/AuthContext'
import { RoleContext } from '../contexts/RoleContext'//Note GET DELETE
import { MenuContext } from '../contexts/MenuContext'//Note GET DELETE
import { ConfigCenterContext } from '../contexts/ConfigCenterContext'
import { ConfigDepartmentContext } from '../contexts/ConfigDepartmentContext'
import { HistoryChangeContext } from '../contexts/HistoryChangeContext'
import { UserManagerContext } from '../contexts/UserManagerContext'


import { useContext, useEffect } from 'react'
import { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Toast from 'react-bootstrap/Toast'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'

import ActionButtons_User from '../components/users/ActionButtons_User'
import AddUserModal from '../components/users/AddUserModal'//Note
import UpdateUserModal from '../components/users/UpdateUserModal'//Note

import addIcon from '../assets/plus_circle_fill.svg'
import Table from 'react-bootstrap/Table'
import iconViewReport from '../assets/icon-view-report.svg'
import iconFilterReset from '../assets/icon-filter-reset.svg'

import { sortRows, filterRows, paginateRows } from "../components/layout/helpers";
import { Pagination } from "../components/layout/PaginationUsers";


export const User = () => {
	// Contexts
	const {
		authState: {
			user: { username, roles, _id }
		}
	} = useContext(AuthContext)

	const {
		UserState: { User, Users, UsersLoading },
		getUsers,
		setShowAddUserModal,
		showToast: { show, message, type },
		setShowToast
	} = useContext(UserContext)

	const {
		RoleState: { Role, Roles, RolesLoading },
		getRoles,
	} = useContext(RoleContext)

	const {
		MenuState: { Menu, Menus, MenusLoading },
		getMenu,
	} = useContext(MenuContext)

	const {
		CenterState: { Centers, CentersLoading },
		get_All_Center
	} = useContext(ConfigCenterContext)

	const {
		DepartmentState: {  Departments, DepartmentsLoading },
		getDepartment
	} = useContext(ConfigDepartmentContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	const {
		UserManagerState: { UserManager, UserManagers, UserManagersLoading },
		getAllUserManagers
	} = useContext(UserManagerContext)

	// hàm tính tổng 
	function sumArray(mang){
    let sum = 0;
    mang.map(function(value){
        sum += value;
    });
    return sum;
	}
	//Định dạng hiển thị số
	function formatCash(str) {
	 	return str.split('').reverse().reduce((prev, next, index) => {
	 		return ((index % 3) ? next : (next + ',')) + prev
	 	})
	}

	// Start: Get all Users
	useEffect(() => getUsers(), [])
	// Start: Get all Role
	useEffect(() => getRoles(), [])
	useEffect(() => getMenu(), [])
	useEffect(() => get_All_Center(), [])
	useEffect(() => getDepartment(), [])
	useEffect(() => getHistoryChanges(), [])
	
	let role_new = roles[0]
	let roleName = FindArray(Roles, role_new).name;
	const departmentFillter = (Departments.length > 0 ) ? Departments.filter(x => x.TypeDepartment == "1") : []
	const menuAllow = findMenuAllow(Users, Menus, _id)
	const RolesNew = filter_new_roles(Roles, roleName)
	const infoUserLogin = FindArray(Users, _id)
	// const userNew = (_id == "65676f9ffaea227259381017") ? Users : findUsers(Users, roleName, _id); //  || 63c0938fed60f11521b00875
	const CentersNew = filter_new_centers(Centers, infoUserLogin)
	const DepartmentNew = (roleName == "Admin" || roleName == "BTGD") ? departmentFillter : filter_department_by_center(departmentFillter, (typeof infoUserLogin != "string") ? infoUserLogin.centers[0] : "")
	let userNew = (_id == "65676f9ffaea227259381017") ? Users :  FindArrayUser(historyChanges, _id, Users, roleName);
	// console.debug("userNew:", userNew)

	// table
	const [activePage, setActivePage] = useState(1);
    const [filters, setFilters] = useState({});
    const [sort, setSort] = useState({ order: "asc", orderBy: "id" });
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
	const [paginationUsers, setPaginationUsers] = useState({});
	const [newDepartmentSelect, setNewDepartmentSelect] = useState(null);

	useEffect(() => {
		let newSet = null
		if (roleName != "Admin") {
			newSet = DepartmentNew
		}
		setNewDepartmentSelect(newSet)
	}, [Users])
	
	let filteredRows = useMemo(() => filterRows(userNew, filters), [userNew, filters]);
    let sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort]);
    let userPagination = paginateRows(sortedRows, activePage, rowsPerPage);
	let totalPagess = Math.ceil(sortedRows.length / rowsPerPage);
	
	//
	function FindArrayUser(array_history, UserID , array_user, roleName) {
		// console.debug(123, array_history, UserID , array_user, roleName)
		let returnObject = array_user.find(x => x._id == UserID);
		let filteredArray = [];
		switch(roleName){
			case 'AM':
				if(returnObject != undefined){
					filteredArray.push(returnObject);
					// filteredArray = array_user.filter(function(item) {
					// 	if(item.centers != undefined){
					//   		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
					//   	}
					// });
					// let manage_UserId = FindArrayUserManagers(UserManagers,UserID);
					// if(manage_UserId.length > 0){
					// 	let manager_id_new = manage_UserId[0]['manager_id'];
					// 	let managerObject = array_user.find(x => x._id == manager_id_new);
					// 	if(managerObject != undefined){
					// 		filteredArray.push(managerObject);
					// 	}
					// }
				}
				break;
			case 'TPKD':
				if(returnObject != undefined){
					filteredArray = array_user.filter(function(item) {
						if(item.centers != undefined && item.departments != undefined){
					  		return item.centers.includes(returnObject.centers[0]) && item.departments.includes(returnObject.departments[0]) && item.role_details[0].name == 'AM' && item.role_details[0].name != 'TPKD';
					  	}

					  	// if(item.centers != undefined){
					  	// 	return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name == 'KTT' ;
					  	// }
					});
				}
				break;
			case 'KTT':
				if(returnObject != undefined){
					filteredArray = array_user.filter(function(item) {
						if(item.centers != undefined){
							// console.debug("array_user", item.centers.includes(returnObject.centers[0]) ? item : 1)
					  		return item.centers.includes(returnObject.centers[0]) && (item.role_details[0].name == 'AM' || item.role_details[0].name == 'TPKD');
					  	}
					});
				}
				break;
			case 'BGD':
				if(returnObject != undefined){
					filteredArray = array_user.filter(function(item) {
						if(item.centers != undefined){
							return item.centers.includes(returnObject.centers[0]) && (item.role_details[0].name == 'AM' || item.role_details[0].name == 'TPKD' || item.role_details[0].name == 'KTT');
					  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name == 'BTGD';
					  	}
					});
				}
				break;
			case 'BTGD':
			case 'Admin':
				filteredArray = array_user
				break;
		}

		// console.debug({filteredArray})

		return filteredArray;

		// if(filteredArray.length > 0){
		// 	let array_historyChange = null;
		// 	if(historyChanges != undefined){
		// 		if(historyChanges.length > 0){
		// 			array_historyChange = FindArrayHistoryChange(historyChanges,filteredArray);
		// 			return array_historyChange;
		// 		}
		// 	}
		// }else{
		// 	return null;
		// }
	}
	
	function FindArrayHistoryChange(array, array_filter) {
		let array_id = [];
		for (let i = 0; i < array_filter.length; i++) {
			let value = array_filter[i];
			array_id.push(value._id);
		}
		let result = array.filter(function(element) {
			if(element != undefined){
		  		return array_id.includes(element.requester);
		  	}
		});
		return result;
	}

	function FindArrayUserManagers(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.filter(x => x.user_id[0] === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}
	//
	
	function findUsers(array, roleName, idUser) {
		let usersBelongCenter = []
		let filterListUsers = FindArray(array, _id)
		switch (roleName) {
			case "Admin":
				if (filterListUsers != undefined) {
					usersBelongCenter = array
				}
				break;
			case "BTGD":
				if (filterListUsers != undefined) {
					usersBelongCenter = filter_new_user(array, null, null, idUser, ["BGD", "KTT", "TPKD", "AM"])
				}
				break;
			case "BGD":
				if (filterListUsers != undefined) {
					usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["KTT", "TPKD", "AM"])
				}
				break;
			case "KTT":
				if (filterListUsers != undefined) {
					usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["TPKD", "AM"])
				}
				break;
			case "TPKD":
				if (filterListUsers != undefined) {
					usersBelongCenter = filter_new_user(array, filterListUsers.centers, filterListUsers.departments, idUser, ["AM"])
				}
				break;
		}
		// console.debug("usersBelongCenter", filterListUsers, usersBelongCenter)
		return usersBelongCenter
	}

	function findMenuAllow(array, arr_menu, _id) {
		let usersBelongCenter = []
		let allow = false
		let pageExists = ""
		if (array.length > 0 ) {
			let filterInfoUsers = FindArray(array, _id).page_details
			if (filterInfoUsers.length > 0) {
				const pageId = "64a7a090c0b92419c4d9fa5d";
				allow = filterInfoUsers.some(item => {
					return item.page_id.includes(pageId); 
				});
			}
		}
		return allow
	}

	function filter_new_user(array, centers_id, department_id = null, idUser, listRole = null){
		let returnObject = [];
		if (centers_id !== null) {
			array.map(element => {
				if (element.centers) {
					if (element.centers[0] === centers_id[0] && element._id !== idUser && listRole.includes(element.role_details[0].name)) {
						returnObject.push(element)
					}
				}
			})
		}else {
			array.map(element => {
				if (element.centers) {
					if (element._id !== idUser) {
						returnObject.push(element)
					}
				}
			})

		}
		return returnObject
	}

	function filter_new_roles(array, roleName) {
		let usersBelongCenter
		switch (roleName) {
			case "Admin":
				if (roleName != undefined) {
					usersBelongCenter = array
				}
				break;
			case "BTGD":
				if (roleName != undefined) {
					var condition = ["BGD", "KTT", "TPKD", "AM"]
					usersBelongCenter = array.filter(role => condition.includes(role.name));
				}
				break;
			case "BGD":
				if (roleName != undefined) {
					var condition = ["KTT", "TPKD", "AM"]
					usersBelongCenter = array.filter(role => condition.includes(role.name));
				}
				break;
			case "KTT":
				if (roleName != undefined) {
					// var condition = ["KTT", "TPKD", "AM"]
					var condition = ["TPKD", "AM"]
					usersBelongCenter = array.filter(role => condition.includes(role.name));
				}
				break;
			case "TPKD":
				if (roleName != undefined) {
					// var condition = ["TPKD", "AM"]
					var condition = ["AM"]
					usersBelongCenter = array.filter(role => condition.includes(role.name));
				}
				break;
			case "AM":
				if (roleName != undefined) {
					// var condition = ["TPKD", "AM"]
					var condition = ["AM"]
					usersBelongCenter = array.filter(role => condition.includes(role.name));
				}
				break;
		}

		return usersBelongCenter
	}

	function filter_new_centers(array, user) {
		let rs
		if (array.length > 0 && user != "") {
			let centers_id = (user.centers != null ) ? user.centers[0]: ''
			if (centers_id != "") {
				rs = array.filter(x => x._id === centers_id)
			} else {
				rs = array
			}
		}

		return rs
	}

	const [newUsers, setNewUsers] = useState(userPagination)

	const [newFilter, setNewFilter] = useState({
		infoUsername: '',
		nameUser: '',
		emailUser: '',
		departmentUser: '',
		centerUser: '',
		roleUser: '',
		managerUser: '',
		menuUser: '',
	})
	
	const {
		infoUsername,
		nameUser,
		emailUser,
		departmentUser,
		centerUser,
		roleUser,
		managerUser,
		menuUser,
	} = newFilter


	useEffect(() => {
		setNewUsers(userPagination)
		setCount(sortedRows.length)
		setTotalPages(totalPagess)
		setNewFilter({ ...newFilter, centerUser: (infoUserLogin.centers != undefined && infoUserLogin.centers != null) ? infoUserLogin.centers[0] : "" })
	}, [Users]); // Users

	const resetFilter = () => {
		setNewFilter({ 
			infoUsername: '',
			nameUser: '',
			emailUser: '',
			departmentUser: '',
			centerUser: (infoUserLogin.centers != undefined && infoUserLogin.centers != null) ? infoUserLogin.centers[0] : "" ,
			roleUser: '',
			managerUser: '',
			menuUser: '',
	 	})
		setActivePage(1);
		setRowsPerPage(10);
		let filteredRowss = filterRows(userNew, filters)
		let sortedRowss =  sortRows(filteredRowss, sort)
		let filteredUserPaginations = paginateRows(sortedRowss, 1, 10);
		let counts = sortedRowss.length;
		let totalPagess = Math.ceil(counts / rowsPerPage);
		setCount(counts);
		setTotalPages(totalPagess)
		setNewUsers(filteredUserPaginations)
	}

	function getUserAssign(data, id) {
		var userAssigns = [];
		if (data.length>0) {
			let e = data.find(c => c._id == id)
			if (e != undefined){
				if (e.manager_details.length > 0) {
					e.manager_details.map(function (em, im) {
						let _id = em.user_id[0];
						let c = data.find(c => c._id == _id).username;
						userAssigns.push(c);
					})
				}
			}
		}
		return (userAssigns.length > 0) ? userAssigns.join(","): "" ;
	}

	function getMenuAssign(data, id) {
		var menuAssigns = [];
		if (data.length>0) {
			let e = data.find(c => c._id == id)
			if (e != undefined){
				if (e.page_details.length > 0) {
					e.page_details.map(function (em, im) {
						let _id = em.page_id[0];
						let c = Menus.find(c => c._id == _id);
						if (c != undefined){
							menuAssigns.push(c.name);
						}
					})
				}
			}
		}
		return (menuAssigns.length > 0) ? menuAssigns.join(","): "" ;
	}

	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}
	
	const onChangeNewFilter = event => {
		let name = event.target.name;
		let val = event.target.value
		if (name == "centerUser") {
			let newDepartment = filter_department_by_center(departmentFillter, val)
			setNewDepartmentSelect(newDepartment)
		}
		setNewFilter({ ...newFilter, [name]: val.toLowerCase() })
	}

	function filter_department_by_center(array, center_id) {
		let rs = []
		if (array.length > 0 && center_id != "") {
			array.map(function (e, i) {
				if (e.centers.length > 0 ) {
					if (roleName == "TPKD" || roleName == "AM") {
						if (e.centers[0] == center_id && e._id == infoUserLogin.departments[0]) rs.push(e)
					} else{
						if (e.centers[0] == center_id) rs.push(e)
					}
				}
			})
		}

		return rs
	}

	function filterUsers(products, conditions) {
	    return products.filter((user) => {
	        // Lọc theo roles
	        if (conditions.roleUser) {
	            if (!user.role_details.some((c) => c._id === conditions.roleUser)) return false;
	        }

	        // Lọc theo name
	        if (conditions.nameUser) {
	            if (!user.name || user.name.trim() === '' || !normalize(user.name).includes(normalize(conditions.nameUser))) return false;
	        }

	        // Lọc theo name
	        if (conditions.emailUser) {
	            if (!normalize(user.email).includes(normalize(conditions.emailUser))) return false;
	        }

	        // Lọc theo username
	        if (conditions.infoUsername) {
	            if (!normalize(user.username).includes(normalize(conditions.infoUsername))) return false;
	        }

	        // Lọc theo centerName
	        if (conditions.centerUser) {
	            if (!user.center_details.some((c) => c._id === conditions.centerUser)) return false;
	        }

	        // Lọc theo centerName
	        if (conditions.departmentUser) {
	            if (!user.department_details.some((c) => c._id === conditions.departmentUser)) return false;
	        }
	        return true;
	    });
	}

	function normalize(str) {
	    return str.toLowerCase().trim();
	}

	const handleFilterChange = () => {
		let condition = newFilter
		setActivePage(1);
		setRowsPerPage(10);
		let filteredRowss = filterUsers(userNew, condition)
		let sortedRowss = sortRows(filteredRowss, sort)
		let filteredUserPaginations = paginateRows(sortedRowss, 1, 10);
		let counts = sortedRowss.length;
		let totalPagess = Math.ceil(counts / rowsPerPage);
		setCount(counts);
		setTotalPages(totalPagess)
		setNewUsers(filteredUserPaginations)
	}

	let body = null
	let stt = 1
	
	if (UsersLoading) {
		body = (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	} else if (Users.length === 0) {
		body = (
			<>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h2'>Thông tin người dùng</Card.Header>
					<Card.Body>
						<Card.Title>Chưa có dữ liệu</Card.Title>
						<Button
							variant='primary'
							onClick={setShowAddUserModal.bind(this, true)}
						>
							Thêm
						</Button>
					</Card.Body>
				</Card>
			</>
		)
	} else {
		if (menuAllow) {
			body = (
				<>
					<Card className='text-center mx-5 my-5'>
						<Card.Header as='h2'>Thông tin người dùng </Card.Header>
						<Card.Body>
							<Form>
								<Form.Row className="form-row-custom">
									<Form.Group as={Col} className="form-group-custom" controlId="formGridEmail">
										<Form.Label>Tên tài khoản</Form.Label>
										<Form.Control
											type='text'
											placeholder=""
											name='infoUsername'
											value={infoUsername}
											onChange={onChangeNewFilter}
										/>
									</Form.Group>

									<Form.Group as={Col} className="form-group-custom" controlId="formGridPassword">
										<Form.Label>Họ tên</Form.Label>
										<Form.Control
											type='text'
											placeholder=""
											name='nameUser'
											value={nameUser}
											onChange={onChangeNewFilter}
										/>
									</Form.Group>

									<Form.Group as={Col} controlId="formGridEmail">
										<Form.Label>Email</Form.Label>
										<Form.Control
											type='email'
											placeholder=""
											name='emailUser'
											value={emailUser}
											onChange={onChangeNewFilter}
										/>
									</Form.Group>

									<Form.Group as={Col}>
										<Form.Label>Trung tâm</Form.Label>
										<Form.Control
											id = 'centerUser-help'
											as="select"
											name='centerUser'
											aria-describedby='centerUser-help'
											value={centerUser}
											onChange={onChangeNewFilter}
											disabled={ (typeof CentersNew != "undefined" && CentersNew.length == 1) ? true : null}
										>
											<option value=''>-----</option>
											{
												typeof CentersNew != "undefined" ? 
												CentersNew.map((obj => {
													return (
														<>
															<option value={obj._id.trim()} >{obj.CenterName.trim()}</option>
														</>
													)
												}))
												:
												<></>
											}
										</Form.Control>
									</Form.Group>

									<Form.Group as={Col}>
										<Form.Label>Phòng ban</Form.Label>
										<Form.Control
											id = 'departmentUser-help'
											as="select"
											name='departmentUser'
											aria-describedby='departmentUser-help'
											value={departmentUser}
											onChange={onChangeNewFilter}
											disabled={ (typeof newDepartmentSelect != "undefined" && newDepartmentSelect != null) ? newDepartmentSelect.length == 1 ? true : null : null}
										>
											{
												typeof newDepartmentSelect != "undefined" && newDepartmentSelect != null ? 
												<>
													{
														newDepartmentSelect.length == 1 ? 
														<>
															<option value={newDepartmentSelect[0]._id}>{newDepartmentSelect[0].CodeDepartment}</option>
														</>
														:
														<>
															{
																typeof newDepartmentSelect != "undefined" && newDepartmentSelect != null ?
																	<option value=''>----------</option>
																:
																<option value=''>--chọn trung tâm trước---</option>
															}
															
															{
																typeof newDepartmentSelect != "undefined" && newDepartmentSelect != null ? 
																newDepartmentSelect.map((obj => {
																	return (
																		<>
																			<option value={obj._id}>{obj.CodeDepartment}</option>
																		</>
																	)
																}))
																:
																<></>
															}
														</>	
													}
												</>
												:
												<></>
											}
										</Form.Control>
									</Form.Group>

									<Form.Group as={Col}>
										<Form.Label>Vai trò</Form.Label>
										<Form.Control
											id = 'roleUser-help'
											as="select"
											name='roleUser'
											aria-describedby='roleUser-help'
											value={roleUser}
											onChange={onChangeNewFilter}
											disabled={ (typeof RolesNew != "undefined" && RolesNew.length == 1) ? true : null}
										>
											{ 
												typeof RolesNew != "undefined" ? 
												<>
													{
														RolesNew.length == 1 ? 
														<>
															<option value={RolesNew[0]._id}>{RolesNew[0].name}</option>
														</>
														:
														<>
															<option value=''>-----</option>
															{
																typeof RolesNew != "undefined" ? 
																RolesNew.map((obj => {
																	return (
																		<>
																			<option value={obj._id}>{obj.name}</option>
																		</>
																	)
																}))
																:
																<></>
															}
														</>
													}
												</>
												:
												<>
												</>
											}

										</Form.Control>
									</Form.Group>

									<Form.Group style={{top: "35px"}} as={Col} className="form-group-custom" controlId="formGridEmail">
										<Form.Label>&nbsp;</Form.Label>
										<OverlayTrigger
											overlay={
												<Tooltip id="tooltip">
													Tìm kiếm
												</Tooltip>
											}
										>
										<a onClick={handleFilterChange}>
											<img src={iconViewReport} width="35" height="35"></img>
										</a>
										</OverlayTrigger>
										&nbsp;&nbsp;&nbsp;
										<OverlayTrigger
											overlay={
												<Tooltip id="tooltip">
													Xóa tìm kiếm
												</Tooltip>
											}
										>
										<a onClick={resetFilter}>
											<img src={iconFilterReset} width="35" height="35"></img>
										</a>
										</OverlayTrigger>
									</Form.Group>
								</Form.Row>
							</Form> 

							<Table striped bordered hover size="sm">
								<thead >
									<tr>
										<th>STT</th>
										<th>Tên Tài Khoản</th>
										<th>Họ Tên</th>
										<th>Email</th>
										<th>Trung tâm</th>
										<th>Phòng ban</th>
										<th width="5%">Vai Trò</th>
										<th>Quản lý</th>
										<th width="25%">Quản lý menu</th>
									</tr>
								</thead>
								<tbody>
									{
										newUsers.map((User, i) => (
											User._id != "65676f9ffaea227259381017" ? 
											<>
												<tr key={User._id} >
													<td>{((activePage - 1) * rowsPerPage + i) + 1}</td>
													<td>{User.username}</td>
													<td>{User.name != null ? User.name: ""}</td>
													<td>{User.email}</td>
													<td>{(User.center_details[0] != null) ? User.center_details[0].CenterName: ""}</td>
													<td>{(User.department_details[0] != null) ? User.department_details[0].CodeDepartment: ""}</td>
													<td>{(User.role_details[0] != null) ? User.role_details[0].name: ""}</td>
													<td>{getUserAssign(userNew, User._id)}</td>
													<td>
														<span className='text' title={getMenuAssign(userNew, User._id)}>{getMenuAssign(userNew, User._id)}</span>
													</td>
													<td>
													{
														(roleName == "Admin") ?
															<ActionButtons_User _id={User._id} _userInfo={User}/>
														:
															<></>
													}
													</td>
												</tr>
											</>
											:
											<></>
										))
									}

									{
										newUsers.map((User, i) => (
											<>
												
											</>
										))
									}
									
								</tbody>
							</Table>
							{
								count > 0 ? <Pagination
									dataUser={userNew}
									activePage={activePage} 
									count={count} 
									rowsPerPage={rowsPerPage} 
									setRowsPerPage={setRowsPerPage}
									totalPages={totalPages} 
									setActivePage={setActivePage}
									setNewUsers={setNewUsers}
									setCount={setCount}
									setTotalPages={setTotalPages}
									newFilter={newFilter}
									setNewFilter={setNewFilter}
									filterUsers={filterUsers}
								/> 
								:
								<p>Không có dữ liệu</p>
							}

							{
								(roleName === "Admin") ?
								<>
									<Button
										variant='primary'
										onClick={setShowAddUserModal.bind(this, true)}
									>
										Thêm mới
									</Button>
								</>
								:
								<></>
							}
						</Card.Body>
					</Card>
				</>
			)
		}else {
			body = (
				<>
					<div id="notfound">
						<div className="notfound">
							<h1>Oops!</h1>
							<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
								<br/>
								<Link style={{textDecoration: "none"}} to="/home">
									Trở về trang chủ
								</Link>
							</h6>
						</div>
					</div>
				</>
			)
		}
		// }
	}

	return (
		<>
			{body}
			<AddUserModal />

			{User !== null && <UpdateUserModal />}
			{/* After User is added, show toast */}
			<Toast
				show={show}
				style={{ position: 'fixed', top: '20%', right: '10px', zIndex:'99999'}}
				className={`bg-${type} text-white`}
				onClose={setShowToast.bind(this, {
					show: false,
					message: '',
					type: null
				})}
				delay={3000}
				autohide
			>
				<Toast.Body>
					<strong>{message}</strong>
				</Toast.Body>
			</Toast>
		</>
	)
}

export const UserInfo = () => {
	// Contexts
	const {
		authState: {
			user: { username, roles, _id }
		}
	} = useContext(AuthContext)

	const {
		UserState: { User, Users, UsersLoading },
		getUsers,
		setShowAddUserModal,
		showToast: { show, message, type },
		setShowToast
	} = useContext(UserContext)

	const {
		RoleState: { Role, Roles, RolesLoading },
		getRoles,
	} = useContext(RoleContext)

	const {
		MenuState: { Menu, Menus, MenusLoading },
		getMenu,
	} = useContext(MenuContext)

	const {
		CenterState: { Centers, CentersLoading },
		get_All_Center
	} = useContext(ConfigCenterContext)

	const {
		DepartmentState: {  Departments, DepartmentsLoading },
		getDepartment
	} = useContext(ConfigDepartmentContext)

	// Start: Get all Users
	useEffect(() => getUsers(), [])
	// Start: Get all Role
	useEffect(() => getRoles(), [])
	useEffect(() => getMenu(), [])
	useEffect(() => get_All_Center(), [])
	useEffect(() => getDepartment(), [])
	console.log("userinfo", Users, username, roles, FindArray(Users, username))
	let role_new = roles[0]
	let roleName = FindArray(Roles, role_new).name;
	const userNew = (_id == "65676f9ffaea227259381017") ? Users : findUsers(Users, roleName, _id); //  || 63c0938fed60f11521b00875
	const UserInfo = FindArray(Users, username);
	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x.username === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	function getMenuAssign(data, id) {
		var menuAssigns = [];
		if (data.length>0) {
			let e = data.find(c => c._id == id)
			if (e != undefined){
				if (e.page_details.length > 0) {
					e.page_details.map(function (em, im) {
						let _id = em.page_id[0];
						let c = Menus.find(c => c._id == _id);
						if (c != undefined){
							menuAssigns.push(c.name);
						}
					})
				}
			}
		}
		return (menuAssigns.length > 0) ? menuAssigns.join(","): "" ;
	}

	function getUserAssign(data, id) {
		var userAssigns = [];
		if (data.length>0) {
			let e = data.find(c => c._id == id)
			if (e != undefined){
				if (e.manager_details.length > 0) {
					e.manager_details.map(function (em, im) {
						let _id = em.user_id[0];
						let c = data.find(c => c._id == _id).username;
						userAssigns.push(c);
					})
				}
			}
		}
		return (userAssigns.length > 0) ? userAssigns.join(","): "" ;
	}

	function findUsers(array, roleName, idUser) {
		let usersBelongCenter = []
		let filterListUsers = FindArray(array, _id)
		switch (roleName) {
			case "Admin":
				if (filterListUsers != undefined) {
					usersBelongCenter = array
				}
				break;
			case "BGD":
			case "KTT":
				if (filterListUsers != undefined) {
					usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser)
				}
				break;
			case "TPKD":
				if (filterListUsers != undefined) {
					usersBelongCenter = filter_new_user(array, filterListUsers.centers, filterListUsers.departments, idUser)
				}
				break;
		}

		return usersBelongCenter
	}

	function filter_new_user(array, centers_id, department_id = null, idUser){
		let returnObject = [];
		array.map(element => {
			if (element.centers) {
				if (element.centers[0] === centers_id[0] && element._id !== idUser) {
					returnObject.push(element)
				}
			}
		})
		return returnObject
	}

	let body = null
	if (UsersLoading) {
		body = (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	} else if (Users.length === 0) {
		body = (
			<>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h2'>Thông tin người dùng</Card.Header>
					<Card.Body>
						<Card.Title>Chưa có dữ liệu</Card.Title>
					</Card.Body>
				</Card>
			</>
		)
	} else {
		
		body = (
			<>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h2'>Thông tin người dùng </Card.Header>
					<Card.Body>
					<Table  striped bordered hover size="sm">
						<thead >
							<tr>
								<th>Tên Tài Khoản</th>
								<th>Họ Tên</th>
								<th>Email</th>
								<th>Trung tâm</th>
								<th>Phòng ban</th>
								<th width="5%">Vai Trò</th>
								<th>Quản lý</th>
								<th width="25%">Quản lý menu</th>
							</tr>
						</thead>
						<tbody>
							{
								<tr key={UserInfo._id} >
									<td>{UserInfo.username}</td>
									<td>{UserInfo.name != null ? UserInfo.name: ""}</td>
									<td>{UserInfo.email}</td>
									<td>{(UserInfo.center_details[0] != null) ? UserInfo.center_details[0].CenterName: ""}</td>
									<td>{(UserInfo.department_details[0] != null) ? UserInfo.department_details[0].CodeDepartment: ""}</td>
									<td>{(UserInfo.role_details[0] != null) ? UserInfo.role_details[0].name: ""}</td>
									<td>{getUserAssign(userNew, UserInfo._id)}</td>
									<td>
										<span className='text' title={getMenuAssign(userNew, UserInfo._id)}>{getMenuAssign(userNew, UserInfo._id)}</span>
									</td>
									<td>
										{/*<ActionButtons_User _id={User._id} />*/}
									</td>
								</tr>
							}
						</tbody>
					</Table>
					</Card.Body>
				</Card>
			</>
		)
	}

	return (
		<>
			{body}
			<Toast
				show={show}
				style={{ position: 'fixed', top: '20%', right: '10px' }}
				className={`bg-${type} text-white`}
				onClose={setShowToast.bind(this, {
					show: false,
					message: '',
					type: null
				})}
				delay={3000}
				autohide
			>
				<Toast.Body>
					<strong>{message}</strong>
				</Toast.Body>
			</Toast>
		</>
	)
}

export const ChangePassword = () => {
	// State

	const {
		authState: {
			user: { username, roles, _id }
		}
	} = useContext(AuthContext)

	const {
		UserState: { User, Users, UsersLoading },
		changePassword,
		showToast: { show, message, type },
		setShowToast
	} = useContext(UserContext)


	const [newUserPass, setNewUserPass] = useState({
		newPassword: '',
		confirmPassword: ''
	})

	const { newPassword, confirmPassword } = newUserPass

	function redirectToRoot() {
		setTimeout(function () {
			window.location.href = "/";
		}, 1250);
	}

	const onSubmit = async event => {
		event.preventDefault()
		if (newUserPass.newPassword == "" || newUserPass.confirmPassword == "") {
			setShowToast({ show: true, message: "Vui lòng không để trống", type: 'danger' })
			return false;
		}

		if (newUserPass.newPassword.length < 6) {
			setShowToast({ show: true, message: "Mật khẩu ít nhất 6 ký tự", type: 'danger' })
			return false;
		}

		if (newUserPass.confirmPassword.length < 6) {
			setShowToast({ show: true, message: "Nhập lại mật khẩu ít nhất 6 ký tự", type: 'danger' })
			return false;
		}

		if (newUserPass.newPassword === newUserPass.confirmPassword) {
			newUserPass._id = _id
			const { success, message } = await changePassword(newUserPass)
			// console.log(newUserPass, { success, message } )
			setShowToast({ show: true, message: "Đổi mật khẩu thành công", type: 'success' })
			redirectToRoot()
		}else{
			setShowToast({ show: true, message: "Mật khẩu không trùng nhau", type: 'danger' })
			return false;
		}
	}

	const closeDialog = () => {
		setNewUserPass({
			newPassword: '',
			confirmPassword: ''
		})
	}

	const onChangeNewPasswordForm = event => {
		setNewUserPass({ ...newUserPass, [event.target.name]: event.target.value })
	}

	let body = (
		<>
			<Card style={{ width: '37%', marginLeft: "auto", marginRight: "auto", marginTop: "40px"}}>
				<Card.Header as='h2' className='text-center' >Đặt lại mật khẩu</Card.Header>
				<Card.Body>
					<Form onSubmit={onSubmit} autoComplete="off">
					<Modal.Body>
						<Form.Group>
							<Form.Text id='newPassword-help' muted as="h6" >
								Mật khẩu mới
							</Form.Text>
							<Form.Control
								type='password'
								placeholder='Mật khẩu mới'
								name='newPassword'
								required
								aria-describedby='newPassword-help'
								value={newPassword}
								autoComplete="new-password"
								onChange={onChangeNewPasswordForm}
							/>						
						</Form.Group>

						<Form.Group>
							<Form.Text id='confirmPassword-help' muted as="h6">
								Nhập lại mật khẩu mới
							</Form.Text>
							<Form.Control
								type='password'
								placeholder='Nhập lại mật khẩu mới'
								name='confirmPassword'
								required
								aria-describedby='confirmPassword-help'
								value={confirmPassword}
								autoComplete="confirm-password"
								onChange={onChangeNewPasswordForm}
							/>						
						</Form.Group>
					</Modal.Body>

					<Modal.Footer>
						<Link style={{textDecoration: "none"}} to="/home">
							<Button variant='secondary'>
								Hủy
							</Button>
						</Link>
						<Button variant='info' type='submit'>
							Đổi mật khẩu
						</Button>
					</Modal.Footer>
					</Form>
				</Card.Body>
			</Card>
		</>
	)

	
	return (
		<>
			{body}
			<Toast
				show={show}
				style={{ position: 'fixed', top: '20%', right: '10px' }}
				className={`bg-${type} text-white`}
				onClose={setShowToast.bind(this, {
					show: false,
					message: '',
					type: null
				})}
				delay={3000}
				autohide
			>
				<Toast.Body>
					<strong>{message}</strong>
				</Toast.Body>
			</Toast>
		</>
	)
}