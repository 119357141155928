import { createContext, useReducer, useState } from 'react'
import {
	apiUrl,
	LOADED_FAIL,
	LOADED_SUCCESS,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from './constants'//Note
import axios from 'axios'

export const TwoOptionCostContext = createContext()

const TwoOptionCostContextProvider = ({ children }) => {
	// State
	const [show_Two_Option_Cost_Modal, setshow_Two_Option_Cost_Modal] = useState(false)
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	})

	// TwoOptionCost context data
	const TwoOptionCostContextData = {
		// Two_Option_Cost_Modal,//update
		show_Two_Option_Cost_Modal,
		setshow_Two_Option_Cost_Modal
	}

	return (
		<TwoOptionCostContext.Provider value={TwoOptionCostContextData}>
			{children}
		</TwoOptionCostContext.Provider>
	)
}

export default TwoOptionCostContextProvider
