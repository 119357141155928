import {
	LOADED_SUCCESS,
	LOADED_FAIL,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from '../contexts/constants'

export const MenuReducer = (state, action) => {
	const { type, payload } = action
	switch (type) {
		case LOADED_SUCCESS:
			return {
				...state,
				Menus: payload,
				MenusLoading: false
			}

		case LOADED_FAIL:
			return {
				...state,
				Menus: [],
				MenusLoading: false
			}

		case ADD:
			return {
				...state,
				Menus: [...state.Menus, payload]
			}

		case DELETE:
			return {
				...state,
				Menus: state.Menus.filter(Menu => Menu._id !== payload)
			}

		case FIND:
			return { ...state, Menu: payload }

		case UPDATE:
			
			const newMenus = state.Menus.map(Menu =>
				Menu._id === payload._id ? payload : Menu
			)

			return {
				...state,
				Menus: newMenus
			}

		default:
			return state
	}
}
