import { useContext, useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useParams, Link} from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Toast from 'react-bootstrap/Toast'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Select from 'react-select';
import { Tables } from '../components/layout/TablesRemoveContact'

import { CancelContractContext } from '../contexts/CancelContractContext'
import { ContractContext } from '../contexts/ContractContext'
import { AuthContext } from '../contexts/AuthContext'
import { HistoryChangeContext } from '../contexts/HistoryChangeContext'
import { UserContext } from '../contexts/UserContext'
import { HistoryContext } from '../contexts/HistoryContext'
import { UserPageContext } from '../contexts/UserPageContext'
import { MenuContext } from '../contexts/MenuContext'
import { RoleContext } from '../contexts/RoleContext'
import { UserManagerContext } from '../contexts/UserManagerContext'

// import CancelContractModal from '../components/CancelContract/CancelContractModal'

// import AddDepartmentModal from '../components/Config/Department/AddDepartmentModal'//Note
// import UpdateDepartmentModal from '../components/Config/Department/UpdateDepartmentModal'//Note
// import ActionButtons_Department from '../components/Config/Department/ActionButtons_Department'
const CancelContract = () => {
const params = useParams();
	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges, historyChangesLoading },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	const {
		HistoryState: { History, Historys, HistorysLoading },
		getHistory
	} = useContext(HistoryContext)
	
	const {
		ContractState: {Contracts, ContractsLoading},
		getContract_byid
	} = useContext(ContractContext)

	const {
		UserState: { User, Users, UsersLoading },
		getUsers
	} = useContext(UserContext)

	useEffect(() => getHistoryChanges(), [])
	useEffect(() => getContract_byid(params.id), []);
	useEffect(() => getUsers(), [])
	useEffect(() => getHistory(), [])

	function filter(array,UserID){
		// console.log(array,UserID,'array,UserIDarray,UserID')
		if(array.length > 0){
			let returnObject = "";
			if (UserID != undefined) returnObject = array.find((element) => element._id == UserID);
			if(returnObject != undefined){
				return returnObject;
			}else{
				return '';
			}
		}else{
			return '';
		}
	}

	function filter_history(array,UserID){
		let returnObject = "";
		if (UserID != undefined) returnObject = array.filter((element) => element.ContractID == UserID);
		const filterArray = (arr,condition) => {
			const dataMap = new Map();
		  arr.map(x=>dataMap.set(x[condition],x));
		  const rs = Array.from(dataMap.values());
		  return rs;
		}
		let last_arr = filterArray(returnObject,'ContractID');
		if(last_arr[0] != undefined){
			return last_arr[0];
		}else{
			return '';
		}
	}

	function filter_new(array){
		// console.log(UserID,'array,UserIDarray,UserID')
		let returnObject = "";
		// if (UserID != undefined) returnObject = array.filter((element) => element.user.centers != null ? element.user.centers[0] : '' == UserID[0]);
		returnObject = array.filter((element) => element.action == 'remove');
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	function convert_time(date_time){
		const date = new Date(date_time); // Replace this with your actual date object
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		const seconds = ('0' + date.getSeconds()).slice(-2);
		const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		return formattedDate;
	}

	let historyChangesNew = filter_new(historyChanges);
	const newhistoryChanges= historyChangesNew.map((v, i) => ({
		...v,
		STT: parseInt(i+1),
		NameContract: filter(Contracts,v.contract).ContractID,
		Date: convert_time(v.created_date),
		UserRemove: filter(Users,v.requester).name,
		Reason: filter_history(Historys,v.contract) != undefined ? filter_history(Historys,v.contract).Reason : ''
	}))

	const columns = [
	    { accessor: "STT", label: "STT", isSearch: false, isSort: false},
	    { accessor: "NameContract", label: "Tên hợp đồng", isSearch: true, isSort: true},
	    { accessor: "Date", label: "Ngày tạo", isSearch: true, isSort: true},
	    { accessor: "UserRemove", label: "Người hủy HĐ", isSearch: true, isSort: false},
	    { accessor: "Reason", label: "Lý do", isSearch: false, isSort: false},
	    { accessor: "ActionFilter", label: "Lọc", isHidden: true, isSearch: false, isSelect: true, isSort: false, width: "15%", filter:1},
	];
	const rows = newhistoryChanges
	let UserID = _id;
	let body = null;
	body = (
			<>
		        <Card className='text-center mx-5 my-5'>
		            <Card.Header as='h1'>Lịch sử hủy hợp đồng</Card.Header>
		            <Card.Body>
					<Tables rows={rows} columns={columns} />

				   </Card.Body>
		        </Card>
		    </>
		   );
	// console.log(historyChanges,'sdsadsad')
	// let body = null;
	// body = (
	// 		<>
		        // <Card className='text-center mx-5 my-5'>
		        //     <Card.Header as='h1'>Lịch sử hủy hợp đồng</Card.Header>
		        //     <Card.Body>
			    //         <Table responsive="lg" striped bordered hover size="lg" variant="" >
			    //             <thead>
				//                 <tr style={{textAlign : 'center'}}>
				//                     <th style={{verticalAlign:'inherit'}}>Tên hợp đồng</th>
				//                     <th style={{verticalAlign:'inherit'}}>Ngày tạo</th>
				//                     <th style={{verticalAlign:'inherit'}}>Người hủy HĐ</th>
				//                     <th style={{verticalAlign:'inherit'}}>Lý do</th>
				//                 </tr>
			    //             </thead>
			    //             <tbody>
			    //             	{
			    //             		historyChanges.map(History => (
			    //             			History.action == 'remove'
			    //             			?
			    //             				<>
				// 			                	<tr key={History._id}>
				// 				                	<td>{filter(Contracts,History.contract).ContractID}</td>
				// 				                	<td>{History.created_date}</td>
				// 				                	<td>{filter(Users,History.requester).name}</td>
				// 				                	<td>{filter(Users,History.requester).name}</td>

				// 				                </tr>
				// 			                </>
			    //             			:
			    //             			<></>
			                			
				// 	                ))
				// 	            }
				// 	        </tbody>
			    //         </Table>
		        //     </Card.Body>
		        // </Card>
	// 	    </>
	// 	)
	if (historyChangesLoading) {
		return(
		    body = (
		      <div className='spinner-container'>
		        <Spinner animation='border' variant='info' />
		      </div>
		    )
		);
	}else if(historyChanges.length == 0){
		return (<></>);
	}else{
		const {
			UserPageState: { UserPage, UserPages, UserPagesLoading },
			getAllUserPages
		} = useContext(UserPageContext)

		const {
		    MenuState: { Menu, Menus, MenusLoading },
		    getMenu,
		} = useContext(MenuContext);

		function FindArrayMenu(array, ID) {
			let returnObject = "";
			if (ID != undefined) returnObject = array.find(x => x._id === ID);
			if(returnObject != undefined){
				return returnObject;
			}else{
				return null;
			}
		}

		if (UserPagesLoading && MenusLoading) {
			return (
				<div className='spinner-container'>
					<Spinner animation='border' variant='info' />
				</div>
			)
		}else {
			const result = UserPages.filter(obj => { return obj.user_id.some(id => id === UserID); });
			let menu_access = false
			if (result.length > 0) {
				for (var j = 0; j < result.length; j++) {
					let menuV1 = FindArrayMenu(Menus, result[j].page_id[0])
					if (menuV1 != null && menuV1.path == location.pathname) {
						// console.debug("menuV1 co quyen truy cap")
						menu_access = true
						break;
					}else {
						// console.debug("menuV1 khong quyen truy cap")
					}
				}
			} else {
				// console.debug("menu khong quyen truy cap")
			}

			if (menu_access) {
				return (
					<>
						{body}
					</>
				);
			}else {
				return (
					<>
						<div id="notfound">
							<div className="notfound">
								<h1>Oops!</h1>
								<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
									<br/>
									<Link style={{textDecoration: "none"}} to="/home">
										Trở về trang chủ
									</Link>
								</h6>
							</div>
						</div>
					</>
				)
			}
		}
	}
// 	const {
// 		authState: {
// 			user: { username, roles,_id }
// 		},
// 		logoutUser
// 	} = useContext(AuthContext)
// 	const {
// 		showAddCancelContractModal,
// 		setShowAddCancelContractModal,
// 		delete_CancelContract,
// 		showToast: { show, message, type },
// 		setShowToast
// 	} = useContext(CancelContractContext)
// 	const {
// 		ContractState: { Contract, Contracts, ContractsLoading },
// 		getContracts,
// 		setShowAddContractModal,
// 		// showToast: { show, message, type },
// 		// setShowToast
// 	} = useContext(ContractContext)

// 	useEffect(() => getContracts(), [])

// 	const [selectedOption, setSelectedOption] = useState('');
//     const handleChange = (e) => {
//     	setSelectedOption(e.target.value);
//     };

//     function pushKeyAndVal(customers) {
// 		var customer = [];
// 		customers.map(function (e, i) {
// 			customer.push({ value: e._id, label: e.ContractID});
// 		})
// 		return customer;
// 	}


//     //fixed
//     const [stateMenu, setStateMenu] = useState({ value: [] });
//     const [state, setState] = useState({ value: [] });
//     var colourOptions = pushKeyAndVal(Contracts);

// 	const styles = {
// 		valueContainer: (base) => ({
// 			...base,
// 			overflowY: "auto"
// 		}),
// 		multiValue: (base, state) => {
// 			return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
// 		},
// 		multiValueLabel: (base, state) => {
// 			return state.data.isFixed
// 			? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
// 			: base;
// 			},
// 		multiValueRemove: (base, state) => {
// 			return state.data.isFixed ? { ...base, display: "none" } : base;
// 		}
// 	};

// 	const orderOptions = (values) => {
// 		return values
// 		.filter((v) => v.isFixed)
// 		.concat(values.filter((v) => !v.isFixed));
// 	};

// 	const onClickNew = () => {
// 		if(state.value.length > 0){
// 			setShowAddCancelContractModal(true)
// 		}else{
// 			setShowToast({ show: true, message:"Vui lòng chọn hợp đồng!!!", type: "danger" });
// 		}
// 	};

//     const FixedOptions = () => {
// 		// const [state, setState] = useState({ value: [] });

// 		function onChange(value, { action, removedValue }) {
// 			switch (action) {
// 				case "remove-value":
// 				case "pop-value":
// 					if (removedValue.isFixed) {
// 					  return;
// 					}
// 				break;
// 				case "clear":
// 					value = colourOptions.filter((v) => v.isFixed);
// 				break;
// 				default:
// 				break;
// 			}
// 			value = orderOptions(value);
// 			setState({ value: value });
// 		}

// 		return (
// 			<Select
// 				value={state.value}
// 				isMulti
// 				styles={styles}
// 				isClearable={state.value.some((v) => !v.isFixed)}
// 				name="colors"
// 				className="basic-multi-select"
// 				classNamePrefix="select"
// 				onChange={onChange}
// 				options={colourOptions}
// 			/>
// 		);
// 	};
// 	if(_id == '65676f9ffaea227259381017'){
// 		return (
//     	<>
//     		<div className="mutyl-content">
// 		        <h2>Chọn hợp đồng cần xóa</h2>
// 		        <FixedOptions />
// 		        <Button
// 	            	style={{top: '20px', marginBottom: '20px', position: 'relative'}}
// 					variant='primary'
// 					onClick={ ()=> onClickNew()}
// 				>
// 					Xóa hợp đồng
// 				</Button>
// 		    </div>
// 		    <CancelContractModal _id={state.value} />
// 		    <Toast
// 				show={show}
// 				style={{ position: 'fixed', top: '20%', right: '10px' }}
// 				className={`bg-${type} text-white`}
// 				onClose={setShowToast.bind(this, {
// 					show: false,
// 					message: '',
// 					type: null
// 				})}
// 				delay={3000}
// 				autohide
// 			>
// 				<Toast.Body>
// 					<strong>{message}</strong>
// 				</Toast.Body>
// 			</Toast>



//     		{/*<div className="mutyl-content">
// 		        <h2>Multi-Select View</h2>
// 		        {options.map((option) => (
// 			        <label key={option.id}>
// 			          <input
// 			            type="checkbox"
// 			            value={option.id}
// 			            checked={selectedOptions.includes(option.id)}
// 			            onChange={handleOptionChange}
// 			          />
// 			          {option.label}
// 			        </label>
// 		        ))}
// 		        <div className="selected-options">
// 				  	Selected Options: {selectedOptions.join(', ')}
// 				</div>
// 		    </div>*/}
// {/*    	{body}
//     	<AddDepartmentModal />
//     	<UpdateDepartmentModal />
//     	<Toast
// 				show={show}
// 				style={{ position: 'fixed', top: '20%', right: '10px' }}
// 				className={`bg-${type} text-white`}
// 				onClose={setShowToast.bind(this, {
// 					show: false,
// 					message: '',
// 					type: null
// 				})}
// 				delay={3000}
// 				autohide
// 			>
// 				<Toast.Body>
// 					<strong>{message}</strong>
// 				</Toast.Body>
// 			</Toast>*/}
//     	</>
//     )
// 	}else{
// 		return (
// 			<>
// 				<Card className='text-center mx-5 my-5'>
// 					<Card.Header as='h2'>Tài khoản không có quyền truy cập menu này.</Card.Header>
// 				</Card>
// 			</>
// 		)
// 	}
}

export default CancelContract
