 import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useContext, useState } from 'react'
import { ImplementationCostContext } from '../../contexts/ImplementationCostContext'
import { useParams } from 'react-router-dom'

//Ham them giai doan chi phi vao chi phi chung
const Add_CostDetail_Modal = (_id) => {
	const params = useParams();
	//Contexts
	
	const {
		
		showAdd_CostDetail_Modal,
		setshowAdd_CostDetail_Modal,     //Goi modal
		Data_Click_On_Button,            //Get Params truyen cho Url
		add_CostDetail_Function,         //Hàm thêm
		setShowToast

	} = useContext(ImplementationCostContext)
	//State luu thong tin 
	const [newStageImplementation, setnewStageImplementation] = useState({
		NameCost: '',
		Units: '',
		UnitPrice: '',
		Quantity_days: '',
		Quantity_times: '',
		IntoMoney: '',
		Note: '',
		ImplementationCost_Id: '',
		ContentCostId: '',
		ContentCost:''
	})
	const { 
		NameCost,
		Units,
		UnitPrice,
		Quantity_days,
		Quantity_times,
		IntoMoney,
		Note,
		Implementation_Cost_Id,
		ContentCostId,
		ContentCost
	} = newStageImplementation
	//Load id Implementation
	newStageImplementation.Implementation_Cost_Id = Data_Click_On_Button.Implementation_Cost_Id
	// newStageImplementation.ContentCostId = '';
	newStageImplementation.ContentCost = Data_Click_On_Button.ContentCost
	var element = Data_Click_On_Button.idContentCost
	
	const onChangeNewImplementationCostForm = event => {
		var field_name = event.target.name;
		var val = event.target.value;
		// console.log("onChangeNewImplementationCostForm", newStageImplementation)
		if (field_name == "UnitPrice") {
			if (!isPatternNumber(val) && val != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				setnewStageImplementation({ ...newStageImplementation, [event.target.name]: event.target.value })
			}
		}else if (field_name == "Quantity_times") {
			if (!isPatternNumber(val) && val != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				setnewStageImplementation({ ...newStageImplementation, [event.target.name]: event.target.value })
			}
		}else if (field_name == "Quantity_days") {
			if (!isPatternNumber(val) && val != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				setnewStageImplementation({ ...newStageImplementation, [event.target.name]: event.target.value })
			}
		}else{
			setnewStageImplementation({ ...newStageImplementation, [event.target.name]: event.target.value })
		}
	}

	let [selectedValue, setSelectedValue] = useState('');
	function handleSelectChange(event) {
		let value = event.target.value;
		// console.debug("handleSelectChange", value)
		setSelectedValue(value);
		setnewStageImplementation({ ...newStageImplementation, [event.target.name]: value })
	}

	const resetAddStageImplementationData = () => {
		setnewStageImplementation({
			NameCost: '',
			Units: '',
			UnitPrice: '',
			Quantity_days: '',
			Quantity_times: '',
			IntoMoney: '',
			Note: '',
			Implementation_Cost_Id: '',
			ContentCostId: '',
			ContentCost:''
		})
		setSelectedValue('');
		setshowAdd_CostDetail_Modal(false)
	}

	const onSubmit = async event => {
		event.preventDefault()
		newStageImplementation.ContentCostId = (newStageImplementation.ContentCostId == "") ? findID_CPChung() : newStageImplementation.ContentCostId;
		const { success, message } = await add_CostDetail_Function(newStageImplementation, params.id)//note
		resetAddStageImplementationData()
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
	}

	const findID_CPChung = () => {
		let _idCPChung = "";
		element.map(function (value) {
			if (value.Content == '') {
				_idCPChung = value._id
			}
		})

		return _idCPChung
	}

	const closeDialog = () => {
		resetAddStageImplementationData()
	}

	const isPatternNumber = userInput => {
  		return /^\d+$/.test(userInput);
	}

	return (
		<Modal show={showAdd_CostDetail_Modal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title as="h5">Thêm chi phí</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<Form.Group>
						<Form.Text id='ContentCost-help' muted as="h6">
							Nhập chi phí vào
						</Form.Text>
						<Form.Control
							id = 'noidung-help'
							as="select"
							name='ContentCostId'
							aria-describedby='noidung-help'
							value={selectedValue}
							onChange={handleSelectChange}>
							{/*<option value="">--------------------------</option>*/}
							{
								typeof element != "undefined" ? 
								element.map((obj => {
									return (
										<>
											<option value={obj._id}>{obj.Content != '' ? obj.Content : '-----'}</option>
										</>
									)
								}))
								:
								<></>
							}
							
					    </Form.Control>
					</Form.Group>
					<Form.Group>
						<Form.Text id='NameCost-help' muted as="h6">
							Nội dung dự trù
						</Form.Text>
						<Form.Control
							type='text'
							placeholder=''
							name='NameCost'
							required
							aria-describedby='noidung-help'
							value={NameCost}
							onChange={onChangeNewImplementationCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='NameCost-help' muted as="h6">
							Đơn vị
						</Form.Text>
						<Form.Control
							type='text'
							placeholder=''
							name='Units'
							aria-describedby='noidung-help'
							value={Units}
							onChange={onChangeNewImplementationCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='NameCost-help' muted as="h6">
							Đơn giá
						</Form.Text>
						<Form.Control
							type='text'
							placeholder=''
							name='UnitPrice'
							required
							aria-describedby='noidung-help'
							value={UnitPrice}
							onChange={onChangeNewImplementationCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='NameCost-help' muted as="h6">
							Ngày/Lượt
						</Form.Text>
						<Form.Control
							type='text'
							placeholder=''
							name='Quantity_times'
							required
							aria-describedby='noidung-help'
							value={Quantity_times}
							onChange={onChangeNewImplementationCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='NameCost-help' muted as="h6">
							Số người
						</Form.Text>
						<Form.Control
							type='text'
							placeholder=''
							name='Quantity_days'
							required
							aria-describedby='noidung-help'
							value={Quantity_days}
							onChange={onChangeNewImplementationCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='NameCost-help' muted as="h6">
							Ghi chú
						</Form.Text>
						<Form.Control
							type='text'
							placeholder=''
							name='Note'
							aria-describedby='noidung-help'
							value={Note}
							onChange={onChangeNewImplementationCostForm}
						/>
					</Form.Group>
					</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Thêm
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}
export default Add_CostDetail_Modal