import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useContext, useState } from 'react'
import { ProductCostContext } from '../../contexts/ProductCostContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'
import { AuthContext } from '../../contexts/AuthContext'
import { useParams } from 'react-router-dom'
import '../App.css'
const AddProductCostModal = () => {
	// Contexts 
	const {
		showAddProductCostModal,
		setShowAddProductCostModal,
		addProducts,
		setShowToast,
		_radioUSD,
	} = useContext(ProductCostContext)

	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	// console.log("AddProductCostModal", _radioUSD)
	// State
	const [newProductCost, setNewProductCost] = useState({
		ProductName: '',
		Quantity: '',
		EX_W: false, // nhap tu nuoc ngoai = true
		FOBCost: '', //if(EX_W = =true, req.body.FOBCost, 0)
		RatioUSD: '', //if(EX_W = =true, req.body.RatioUSD, 0)
		InputPrice: '', // = if(EX_W == true, FOBCost * RatioUSD , req.body.InputPrice)
		OutputPrice: '', // Nhap
		InputIntoMoney: '', // Can tinh  = Quantity * InputPrice
		OutputIntoMoney: '', //Can tinh =  Quantity * OutputPrice
		Insurance: false,
		InsuranceHSE: false,
		Note: '',
		ContractID: '',
		Tax: '',
		StorageFOBCost: '',
		Incentive: '',
		PercentHSE: '',
		InternalPurchase: false
	})

	let {
		ProductName,
		Quantity,
		EX_W,
		FOBCost,
		RatioUSD,
		InputPrice,
		OutputPrice,
		Insurance,
		InsuranceHSE,
		Note,
		ContractID,
		Tax,
		StorageFOBCost,
		Incentive,
		PercentHSE,
		InternalPurchase
	} = newProductCost

	const params = useParams();
	newProductCost.ContractID = params.idcontract;
	newProductCost.RatioUSD = _radioUSD;
	//Checkbox nguon nhap
	const toggleEX_W = event => {
		setNewProductCost({ ...newProductCost, EX_W: event })

	};

	//Ham checkbox Insurance
	const toggleInsurance = event => {
		setNewProductCost({ ...newProductCost, Insurance: event })
	};

	const toggleInsuranceHSE = event => {
		setNewProductCost({ ...newProductCost, InsuranceHSE: event })
	};

	const toggleInternalPurchase = event => {
		setNewProductCost({ ...newProductCost, InternalPurchase: event })
	};

	const [validValueFOBCost, setValidValueFOBCost] = useState('');
	const onChangeNewProductCostForm = event =>{
		let value = event.target.value;
		if(event.target.name != 'ProductName' && event.target.name != 'Note'){
			if (!isPatternNumber(value) &&  value != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				event.target.value = validValueFOBCost;
				return false;
			}else{
				if(event.target.name == 'FOBCost'){
					if(Tax == '') Tax = 1;
					let StorageFOBCostNew = parseInt(value) + (parseInt(value) * (parseInt(Tax) / 100));
					if(value == '') StorageFOBCostNew = '';
					let InputPriceNew = StorageFOBCostNew * RatioUSD;
					setNewProductCost({ ...newProductCost, 'InputPrice': InputPriceNew, 'StorageFOBCost': StorageFOBCostNew, 'FOBCost' : value})
				}else{
					setNewProductCost({ ...newProductCost, [event.target.name]: event.target.value });
				}
			}
			setValidValueFOBCost(value);
		}else{
			setNewProductCost({ ...newProductCost, [event.target.name]: event.target.value });
		}
	}

	const closeDialog = () => {
		resetAddProductCostData()
	}

	const onSubmit = async event => {
		event.preventDefault()
		if(newProductCost.Tax == '') newProductCost.Tax = 1;
		const historyChangeProductCost = {
		    "requester": _id,
		    "contract": params.idcontract,
		    "form": "product-cost",
		    "action": "create",
		    "data_change": "create new product" +newProductCost.ProductName
		}
		const { success, message } = await addProducts(params.idcontract, newProductCost)
		const { successHis, messageHis } = await addHistoryChange(historyChangeProductCost)
		resetAddProductCostData()
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
	}

	const resetAddProductCostData = () => {
		setNewProductCost({
			ProductName: '',
			Quantity: '',
			EX_W: false, // nhap tu nuoc ngoai = true
			FOBCost: '', //if(EX_W = =true, req.body.FOBCost, 0)
			RatioUSD: '', //if(EX_W = =true, req.body.RatioUSD, 0)
			InputPrice: '', // = if(EX_W == true, FOBCost * RatioUSD , req.body.InputPrice)
			OutputPrice: '', // Nhap
			InputIntoMoney: '', // Can tinh  = Quantity * InputPrice
			OutputIntoMoney: '', //Can tinh =  Quantity * OutputPrice
			Insurance: false,
			InsuranceHSE: false,
			Note: '',
			ContractID: '',
			Tax: '',
			StorageFOBCost: '',
			Incentive: '',
			PercentHSE: '',
			InternalPurchase: false
		}) //note cần sửa
		setShowAddProductCostModal(false)
	}

	let [selectedValue, setSelectedValue] = useState('');
	const [showInput, setShowInput] = useState(false);
	function handleSelectChange(event) {
		let value = event.target.value;
		setSelectedValue(value);
		if (value === 'others') {
			// if(selectedValue != 1 && selectedValue != 2 && selectedValue != 5 && selectedValue != 10 ){
	        	setShowInput(true);
	        // }
	        // setNewProductCost({ ...newProductCost, [event.target.name]: event.target.value })
	    } else {
	    	if(FOBCost != ''){
		    	let StorageFOBCostNew = parseInt(FOBCost) + (parseInt(FOBCost) * (parseInt(value) / 100));
		    	let InputPriceNew = StorageFOBCostNew * RatioUSD;
		    	// setNewProductCost({ ...newProductCost, [event.target.name]: event.target.value })
		    	setNewProductCost({ ...newProductCost, 'InputPrice': InputPriceNew, 'StorageFOBCost': StorageFOBCostNew, 'Tax':value})
		        setShowInput(false);
		    }
	    }
	}

	const [validValueTax, setValidValueTax] = useState('');
	function handleInputChange(event) {
	    const value = event.target.value;
	    if (!isPatternNumber(value) &&  value != '') {
			var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
			setShowToast({ show: true, message: message, type: 'danger' })
			event.target.value = validValueTax;
			return false;
		}else{
		    const name = event.target.name;
		    if(name == 'Tax'){
		    	const InputPrice = value * RatioUSD;
		    	let StorageFOBCostNew = parseInt(FOBCost) + (parseInt(FOBCost) * (parseInt(value) / 100));
		    	if(value != ''){
		    		setNewProductCost({ ...newProductCost, 'InputPrice': InputPrice ,'StorageFOBCost': StorageFOBCostNew})
		    	}else{
		    		setNewProductCost({ ...newProductCost, 'InputPrice': 0 ,'StorageFOBCost': 0})
		    	}
			}else{
		    	setNewProductCost({ ...newProductCost, [event.target.name]: event.target.value })
		    }
		}
		setValidValueTax(value);
	}

	// const [validValuePercent, setValidValuePercent] = useState('');
	// function handleSelectChangePercent(event) {
	// 	const value = event.target.value;
	// 	if (!isPatternNumber(value) &&  value != '') {
	// 		var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
	// 		setShowToast({ show: true, message: message, type: 'danger' })
	// 		event.target.value = validValuePercent;
	// 		return false;
	// 	}else{
	// 		if(value != ''){
	// 			let IncentiveNew = '';
	// 			if(!EX_W){
	// 				IncentiveNew = (InputPrice * Quantity) * (value /100);
	// 			}else{
	// 				IncentiveNew = InputPrice * (parseInt(value) /100);
	// 			}
	// 			document.getElementById("Incentive").setAttribute("readonly","readonly");
	// 			setNewProductCost({ ...newProductCost, 'Incentive': IncentiveNew })
	// 		}else{
	// 			document.getElementById("Incentive").removeAttribute("readonly");
	// 		}
	// 	}
	// 	setValidValuePercent(value);
	// }

	const isPatternNumber = userInput => {
		if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		return /^\d+(\.\d{0,2})?$/.test(userInput);
	}

	return (

		<Modal animation={false} show={showAddProductCostModal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>Thêm Hàng Hóa</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					{/* <Form.Group>
						<Form.Text id='contract-help' muted as='h6'>
							Chọn Hợp đồng
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Contract_id'
							name='ContractID'
							required
							aria-describedby='contract-help'
							value={ContractID}
							onChange={onChangeNewProductCostForm}
						/>
					</Form.Group> */}
					<Form.Group>
						<Form.Text id='xuatxu-help' muted as='h6'>
							Nguồn nhập hàng
						</Form.Text>
						<Form.Check
							type='checkbox'
							checked={EX_W}
							value={EX_W}
							onChange={(e) => toggleEX_W(e.target.checked)}
							label="Nhập từ nước ngoài"
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='tenhang-help' muted as='h6'>
							Nhập tên hàng
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Tên hàng'
							name='ProductName'
							required
							aria-describedby='tenhang-help'
							value={ProductName}
							onChange={onChangeNewProductCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='soluong-help' muted as='h6'>
							Số lượng
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Số lượng'
							name='Quantity'
							required
							aria-describedby='soluong-help'
							value={Quantity}
							onChange={onChangeNewProductCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='title-help' muted as='h6'>
							Đơn giá FOB
						</Form.Text>
						<Form.Control
							type='text'
							placeholder=''
							name='FOBCost'
							readOnly={!EX_W}
							aria-describedby='dongiaFOB-help'
							onChange={onChangeNewProductCostForm}
						/>
					</Form.Group>
					<Form.Group style={{'height':'60px'}}>
						<Form.Text id='title-help' muted as='h6'>Thuế/ Phí</Form.Text>
							<select class='form-control' disabled={!EX_W} id="changeTax" onChange={handleSelectChange} name='Tax' value={selectedValue} style={{'float':'left','width':'48%'}} > 
								<option value ='1'>1%</option>
								<option value ='2'>2%</option>
								<option value ='5'>5%</option>
								<option value ='10'>10%</option>
								<option value='others'>Khác</option>
							</select>
							{showInput && (
						        <Form.Control
								type='text'	
								name='Tax'
								onChange={handleInputChange}
								disabled={!EX_W}
								style={{'float':'right','width':'48%'}}
							/>
						      )}
					</Form.Group>
					<Form.Group>
						<Form.Text id='dongiakho-help' muted as='h6'>
							Giá kho FOB
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Giá Kho FOB'
							name='StorageFOBCost'
							readOnly
							value={StorageFOBCost}
							onChange={onChangeNewProductCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='dongiakho-help' muted as='h6'>
							Đơn giá kho
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Đơn giá kho'
							name='InputPrice'
							readOnly={EX_W}
							value={InputPrice}
							onChange={onChangeNewProductCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='dongiaban-help' muted as='h6'>
							Đơn giá bán
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Đơn giá bán'
							name='OutputPrice'
							required
							aria-describedby='dongiaban-help'
							value={OutputPrice}
							onChange={onChangeNewProductCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='dongiaban-help' muted as='h6'>
							Giá trị Incentive
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Giá trị Incentive'
							name='Incentive'
							id='Incentive'
							onChange={onChangeNewProductCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Check
							type='checkbox'
							checked={Insurance}
							value={Insurance}
							onChange={(e) => toggleInsurance(e.target.checked)}
							label="Hàng hoá có tính chi phí bảo hành kg"
						/>
					</Form.Group>
					<Form.Group style={{'height':'60px'}}>
						<Form.Check
							type='checkbox'
							checked={InsuranceHSE}
							value={InsuranceHSE}
							onChange={(e) => toggleInsuranceHSE(e.target.checked)}
							label="Hàng hoá có hỗ trợ kĩ thuật HSE"
							style={{'marginBottom':'8px'}}
						/>
						<Form.Control
							type='text'
							placeholder='Tỉ lệ HSE'
							name='PercentHSE'
							readOnly={!InsuranceHSE}
							style={{'float':'left','width':'22%'}}
							onChange={onChangeNewProductCostForm}
						/>
						<Form.Text muted as='h7' style={{'float':'left','marginLeft':'-20px','marginTop':'7px'}}>%</Form.Text>
					</Form.Group>
					<Form.Group>
						<Form.Check
							type='checkbox'
							checked={InternalPurchase}
							value={InternalPurchase}
							onChange={(e) => toggleInternalPurchase(e.target.checked)}
							label="Mua nội bộ"
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='Note-help' muted as='h6'>
							Ghi chú
						</Form.Text>
						<Form.Control
							as='textarea'
							rows={3}
							placeholder='Ghi chú'
							name='Note'
							aria-describedby='Note-help'
							value={Note}
							onChange={onChangeNewProductCostForm}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Thêm
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}



export default AddProductCostModal




