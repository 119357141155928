import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useContext, useState } from 'react'
import { ProductCostContext } from '../../contexts/ProductCostContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'
import { AuthContext } from '../../contexts/AuthContext'
import { useParams } from 'react-router-dom'
import '../App.css'
import * as XLSX from 'xlsx';
import { FileReaderInput } from 'react-file-reader';

const ImportProductCostModal = () => {
	const [selectedFile, setSelectedFile] = useState(null);
    const handleFileUpload = (event) => {
    	setSelectedFile(event.target.files[0]);
    };
    const params = useParams();
    const {
	    ProductCostState: { ProductCost, ProductCosts, ProductCostsLoading },
	    addProductCost,
	    getProductCost_byidContract,
	    showAddProductCostModal,
	    setShowAddProductCostModal,
	    showImportProductCostModal,
	    setshowImportProductCostModal,
	    showToast: { show, message, type },
	    setShowToast,
	    _radioUSD,
	    addProducts,

	} = useContext(ProductCostContext);

	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

    const [importProductCost, setImportProductCost] = useState({
		ProductName: '',
		Quantity: '',
		EX_W: false, // nhap tu nuoc ngoai = true
		FOBCost: '', //if(EX_W = =true, req.body.FOBCost, 0)
		RatioUSD: '', //if(EX_W = =true, req.body.RatioUSD, 0)
		InputPrice: '', // = if(EX_W == true, FOBCost * RatioUSD , req.body.InputPrice)
		OutputPrice: '', // Nhap
		InputIntoMoney: '', // Can tinh  = Quantity * InputPrice
		OutputIntoMoney: '', //Can tinh =  Quantity * OutputPrice
		Insurance: false,
		InsuranceHSE: false,
		Note: '',
		ContractID: '',
		Tax: '',
		StorageFOBCost: '',
		Incentive: '',
		PercentHSE: '',
		InternalPurchase: false
	});

	// let {
	// 	ProductName,
	// 	Quantity,
	// 	EX_W,
	// 	FOBCost,
	// 	RatioUSD,
	// 	InputPrice,
	// 	OutputPrice,
	// 	Insurance,
	// 	InsuranceHSE,
	// 	Note,
	// 	ContractID,
	// 	Tax,
	// 	StorageFOBCost,
	// 	Incentive,
	// 	PercentHSE,
	// 	InternalPurchase
	// } = importProductCost
	// importProductCost.ContractID = params.id;
    const handleImport = () => {
	    const reader = new FileReader();
	    const rABS = !!reader.readAsBinaryString;
	    reader.onload = async e => {
	        const bstr = e.target.result;
	        const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
	        const wsname = wb.SheetNames[0];
	        const ws = wb.Sheets[wsname];
	        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
	        let flag = false;
	        for (let i = 2; i < data.length; i++) {
		        let value = data[i];
		        if(value != ''){
			        let ProductName = value[0] != undefined ? value[0] : '';
			        let Quantity = value[1] != undefined ? isNumericString(value[1]) : 0;
			        let FOBCost = value[2] != undefined ? isNumericString(value[2]) : 0;
			        let Tax = value[3] != undefined ? ( isNumericString(value[3]) != 0 ? isNumericString(value[3]) : 1) : 1;
			        let RatioUSD = _radioUSD;
			        let InputPrice = 0;
			        if(value[4] != undefined){
			        	if(FOBCost != 0 && Tax != 0){
			        		InputPrice =  (parseInt(FOBCost) + (parseInt(FOBCost) * (parseInt(Tax) / 100))) * parseInt(RatioUSD);
			        	}else{
			        		InputPrice = isNumericString(value[4]);
			        	}
			        }else{
			        	if(FOBCost != 0 && Tax != 0){
			        		InputPrice =  (parseInt(FOBCost) + (parseInt(FOBCost) * (parseInt(Tax) / 100))) * parseInt(RatioUSD);
			        	}else{
			        		InputPrice = 0;
			        	}
			        }
			        let StorageFOBCost = parseInt(FOBCost) + (parseInt(FOBCost) * (parseInt(Tax) / 100));
			        let OutputPrice = value[5] != undefined ? isNumericString(value[5]) : 0;
			        let Incentive = value[6] != undefined ? isNumericString(value[6]) : 0;
		          	let Insurance = value[7] != undefined ? ((value[7] == 1 || value[7] == 0) ? value[7] : 0) : 0;
		          	let PercentHSE = value[8] != undefined ? isNumericString(value[8]) : 0;
		          	let InternalPurchase = value[9] != undefined ? ((value[9] == 1 || value[9] == 0) ? value[9] : 0) : 0;
		          	let Note = value[10] != undefined ? value[10] : '';
		          	let ContractID = params.idcontract;
		          	let EX_W = (FOBCost != 0) ? true : false;
		          	let InsuranceHSE = (PercentHSE == 0) ? false : true;
		     		let importProductCost = {
		     			ProductName,
						Quantity,
						EX_W,
						FOBCost,
						RatioUSD,
						InputPrice,
						OutputPrice,
						Insurance,
						InsuranceHSE,
						Note,
						ContractID,
						Tax,
						StorageFOBCost,
						Incentive,
						PercentHSE,
						InternalPurchase
		     		}
		     		const products = await addProducts(ContractID, importProductCost);
		     		if(products.success == true){
		     			flag = true;
		     		}
		        }
	        }
	        if(flag == true){
	        	setShowToast({ show: true, message :'Nhập file excel thành công', type:'success'})
	        }else{
	            setShowToast({ show: true, message :'Nhập file excel không thành công', type:'danger'})
	        }
	       	const historyChangeProductCost = {
			    "requester": _id,
			    "contract": params.idcontract,
			    "form": "product-cost",
			    "action": "import",
			    "data_change": "import product"
			}
			const { successHis, messageHis } = await addHistoryChange(historyChangeProductCost)
	        setTimeout(() => {location.reload();},1000);
	    };
	    if (rABS) reader.readAsBinaryString(selectedFile);
	    else reader.readAsArrayBuffer(selectedFile);
    };

    function isNumericString(str) {
	    // Kiểm tra nếu chuỗi không tồn tại hoặc rỗng
	    if (!str || str.length === 0) {
	    	return 0;
	    }
	    // Kiểm tra xem chuỗi có chỉ chứa số hay không
	    let regrex = /^\d+$/.test(str);
	    if(regrex == true){
	    	return str;
	    }else{
	    	return 0;
	    }
	}


	// const [selectedSheet, setSelectedSheet] = useState(null);
	// const handleSheetChange = (e) => {
	//     const sheetName = e.target.value;
	//     setSelectedSheet(sheetName);
	// };


 //    function getCurrentDate(separator){
	//     let newDate = new Date()
	//     let date = newDate.getDate();
	//     let month = newDate.getMonth() + 1;
	//     let year = newDate.getFullYear();
	//     return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
 //    }

 //    function ExcelDateToJSDate(serial) {
 //        var utc_days  = Math.floor(serial - 25569);
 //     	var utc_value = utc_days * 86400;                                        
 //     	var date_info = new Date(utc_value * 1000);
	//     var fractional_day = serial - Math.floor(serial) + 0.0000001;
	//     var total_seconds = Math.floor(86400 * fractional_day);
	//     var seconds = total_seconds % 60;
	//     total_seconds -= seconds;
	//     var hours = Math.floor(total_seconds / (60 * 60));
	//     var minutes = Math.floor(total_seconds / 60) % 60;
	//     return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
 //    }

 //    function convert_time(date_time){
	//     const date = new Date(date_time); // Replace this with your actual date object
	//     const year = date.getFullYear();
	//     const month = ('0' + (date.getMonth() + 1)).slice(-2);
	//     const day = ('0' + date.getDate()).slice(-2);
	//     const hours = ('0' + date.getHours()).slice(-2);
	//     const minutes = ('0' + date.getMinutes()).slice(-2);
	//     const seconds = ('0' + date.getSeconds()).slice(-2);
	//     // const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	//     const formattedDate = `${year}-${month}-${day}`;
	//     return formattedDate;
	// }
	// window.onbeforeunload = function() {
	//   return "Dude, are you sure you want to leave? Think of the kittens!";
	// }
	const closeDialog = () => {
	    resetImportProductCostData()
	    setshowImportProductCostModal(false)
	}
    const resetImportProductCostData = () => {
	    // setNewProductCost({
	    //   Dates: '',
	    //   Explain: '',
	    //   CostCustomerPaid: '',
	    //   CostNTP: '',
	    //   OutstandingGoods: '',
	    //   NumberDate: '',
	    //   InterestRate: '',
	    //   InterestLoan: '',
	    //   Note: ''
	    // }) //note cần sửa
	    setShowAddProductCostModal(false)
    }
	return (
		<Modal show={showImportProductCostModal} onHide={() => setshowImportProductCostModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Nhập từ file excel</Modal.Title>
            </Modal.Header>
            <Form>
	            <Modal.Body>
	                <Form.Group>
		                <div>
		                    <input type="file" id="csvFileInput" style={{ display: 'none' }} onChange={handleFileUpload} />
		                   {/* <select onChange={handleSheetChange}>
						        <option value="">-- Chọn sheet --</option>
						        <option value="hanghoa">hanghoasss</option>
						        <option value="chitiet">chi tietsss</option>*/}
						        {/* Thêm các option cho các sheet khác nếu cần */}
						    {/*</select>*/}
		                    <label htmlFor="csvFileInput">
		                    <input type="file" accept=".xlsx" onChange={handleFileUpload} />
		                    </label>
		                </div>
	                </Form.Group>
	            </Modal.Body>
                <Modal.Footer>
		            <Button variant='secondary' onClick={closeDialog}>Hủy</Button>
           			<Button variant='primary' onClick={handleImport}>Import</Button>
           		</Modal.Footer>
            </Form>
        </Modal>
	)
}

export default ImportProductCostModal




