import { useContext, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Toast from "react-bootstrap/Toast";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import axios from "axios";
import urlfileExcelLoanInterest from "./../assets/excel/template_CP_Lai_Vay.xlsx";

import { LoanInterestContext } from "../contexts/LoanInterestContext";
import { ProductCostContext } from "../contexts/ProductCostContext";
import { AuthContext } from "../contexts/AuthContext";
import { ContractContext } from "../contexts/ContractContext";
import { ConfigCustomerContext } from "../contexts/ConfigCustomerContext";
import { UserContext } from '../contexts/UserContext'
import { RoleContext } from '../contexts/RoleContext'
import { HistoryContext } from '../contexts/HistoryContext'
import { EventContext } from '../contexts/EventContext'
import { UserManagerContext } from '../contexts/UserManagerContext'
import { HistoryChangeContext } from '../contexts/HistoryChangeContext'

import ImportLoanInterestModal from "../components/LoanInterest/ImportLoanInterestModal"; //Note
import ActionButtons_LoanInterest from "../components/LoanInterest/ActionButtons_LoanInterest";

import downLoadExcel from "../assets/icon-download.svg";
import importExcel from "../assets/icon-upload.svg";

//View all LoanInterest by idContract
export const LoanInterest_idContract = () => {
    const params = useParams();
    // Contexts
    const {
        authState: {
            user: { username, _id, roles }
        }
    } = useContext(AuthContext)

    const {
        RoleState: { Role, Roles, RolesLoading },
        getRoles,
    } = useContext(RoleContext)
    useEffect(() => getRoles(), [])

    const {
        LoanInterestState: { LoanInterest, LoanInterests, LoanInterestsLoading },
        addLoanInterest,
        getLoanInterest_byidContract,
        showAddLoanInterestModal,
        setShowAddLoanInterestModal,
        showToast: { show, message, type },
        setShowToast,
    } = useContext(LoanInterestContext);

    const {
        ProductCostState: { ProductCosts, ProductCostsLoading },
        getProductCost_idContract,
    } = useContext(ProductCostContext);

    const {
        ContractState: { Contracts, ContractsLoading },
        getContract_byid,
    } = useContext(ContractContext);

    const {
        historyChangeState: { historyChange, historyChanges },
        getHistoryChanges,
        addHistoryChange
    } = useContext(HistoryChangeContext)

    useEffect(() => getLoanInterest_byidContract(params.id), []);
    useEffect(() => getProductCost_idContract(params.id), []);
    useEffect(() => getContract_byid(params.id), []);
    let body = null;
    //

    const downloadFile = () => {
        const fileUrl = urlfileExcelLoanInterest; // Đường dẫn tới file cần tải xuống
        axios
            .get(fileUrl, { responseType: "blob" })
            .then((response) => {
                const blob = new Blob([response.data], { type: response.headers["content-type"] });
                // Lấy tên file từ header response hoặc tùy chỉnh tên file
                let fileName = response.headers["content-disposition"];
                if (!fileName) {
                    fileName = "template_CP_Lai_Vay.xlsx"; // Tên file mặc định nếu không có thông tin từ header
                } else {
                    fileName = fileName.split(";")[1].trim().split("=")[1];
                }
                // Tải xuống file
                saveAs(blob, fileName);
            })
            .catch((error) => {
                console.error("Lỗi khi tải file:", error);
            });
    };

    function convert_time(date_time) {
        const date = new Date(date_time); // Replace this with your actual date object
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        const hours = ("0" + date.getHours()).slice(-2);
        const minutes = ("0" + date.getMinutes()).slice(-2);
        const seconds = ("0" + date.getSeconds()).slice(-2);
        // const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }
    //
    //tinh tong
    function sumArray(mang) {
        let sum = 0;
        mang.map(function (value) {
            sum += value;
        });
        return sum;
    }
    const TotalCostCustomerPaid = sumArray(LoanInterests.map((LoanInterest) => LoanInterest.CostCustomerPaid));
    const TotalCostNTP = sumArray(LoanInterests.map((LoanInterest) => LoanInterest.CostNTP));
    const TotalInterestLoan = sumArray(LoanInterests.map((LoanInterest) => LoanInterest.InterestLoan));
    //Ham tinh tong Phan tử trong kieu mang
    function Sum_InputIntoMoney(ProductsCost) {
        let Total_InputIntoMoney = 0;
        ProductsCost.map((Products) => Products.ListProducts.map((ListProduct) => (Total_InputIntoMoney += ListProduct.InputIntoMoney)));

        return Total_InputIntoMoney;
    }
    //Ham tinh tong Phan tử trong kieu mang
    function Sum_OutputIntoMoney(ProductsCost) {
        let Total_OutputIntoMoney = 0;
        ProductsCost.map((Products) => Products.ListProducts.map((ListProduct) => (Total_OutputIntoMoney += ListProduct.OutputIntoMoney)));

        return Total_OutputIntoMoney;
    }
    let TotalInputIntoMoney = Sum_InputIntoMoney(ProductCosts);
    let TotalOutputIntoMoney = Sum_OutputIntoMoney(ProductCosts);
    if (Contracts.length > 0) {
        if (Contracts[0].VAT != undefined) {
            TotalInputIntoMoney = (TotalInputIntoMoney + TotalInputIntoMoney * Contracts[0].VAT) / 100;
            TotalOutputIntoMoney = (TotalOutputIntoMoney + TotalOutputIntoMoney * Contracts[0].VAT) / 100;
        }
    }
    const {
        CustomerState: { Customer, Customers, CustomersLoading },
        get_All_Customer,
    } = useContext(ConfigCustomerContext);
    useEffect(() => get_All_Customer(), []);
    // console.log(Customers,'=============Customersa')
    // console.log(Contracts,'=============Contracts')
    function FindArray(array, ID) {
        let returnObject = "";
        if (ID != undefined) returnObject = array.find((x) => x._id === ID);
        if (returnObject != undefined) {
            return returnObject;
        } else {
            return "";
        }
    }

    let idcontractNew = "";
    let customer_name = "";
    if (Contracts.length > 0) {
        var filterContact = FindArray(Contracts, params.id);
        customer_name = FindArray(Customers, filterContact.CustomerID) != "" ? FindArray(Customers, filterContact.CustomerID).CustomerName : filterContact.CustomerID;
        idcontractNew = filterContact != "" ? filterContact.ContractID : "";
    }

    ///////////////////////
    const {
        HistoryState: { History, Historys, HistorysLoading },
        getHistory
    } = useContext(HistoryContext)
    useEffect(() => getHistory(), [])

    ///UserManagers
    const {
        UserManagerState: { UserManager, UserManagers, UserManagersLoading },
        getAllUserManagers
    } = useContext(UserManagerContext)
    useEffect(() => getAllUserManagers(), [])

    const {
        UserState: { User, Users, UsersLoading },
        getUsers
    } = useContext(UserContext)

        if (ContractsLoading) {
        return (<></>)
    }else {
        let role_new = roles[0];
        let UserID = _id;
        let roleName = FindArray(Roles, role_new).name;

        let rows;
        let newContract;
        let newContract111 = [];
        if (roleName == "Admin" || roleName == "BTGD") {
            newContract = Contracts;
        } else if (roleName == "KTT" || roleName == "BGD") {
            let centerOfUser = filter_Users(Users, UserID).centers;
            // console.log(centerOfUser,'====1111===')
            if (centerOfUser != undefined) {
                if (Contracts.length > 0) {
                    newContract111 = filter_new_contract(Contracts, centerOfUser);
                }
            }
            newContract = newContract111;
        } else {
            // console.debug(UserManagers,UserID,roleName,get_user_id(UserID, UserManagers),'sdsd')
            let newContract1 = Contracts.filter((v) => get_user_id(UserID, UserManagers).includes(v.user._id));
            if (roleName == "AM") {
                newContract = newContract1;
            } else {
                let contract_me = Contracts.filter((v) => v.user._id == UserID);
                let newContract2 = newContract1.concat(contract_me);
                newContract = newContract2;
            }
        }

        // console.debug(newContract,Contracts, '=======newContract=========')

        const contract_me_access = FindArray(newContract, params.id)
        const contract_me_new_access = FindArray(Contracts, params.id)
        // console.debug("newContract", roleName, newContract, newContracts, ContractsLoading)
        // console.debug("contract_me_access", contract_me_access == "", get_user_id(UserID,UserManagers))
        // console.debug("contract_me_new_access", contract_me_new_access == "", get_user_id(UserID,UserManagers))

        //loc cha, loc con
        let user_access = get_user_id(UserID,UserManagers)
        if (roleName != "AM") user_access.push(UserID)
        const userNew = (_id == "65676f9ffaea227259381017") ? Users : findUsers(Users, roleName, UserID); //  || 63c0938fed60f11521b00875
        const uniqueUserNew = (userNew.length > 0 ) ? [...new Set(userNew.map(obj => obj._id))] : []
        let newUserManagers = []
        if (roleName == "AM" || roleName == "TPKD") {
            newUserManagers = user_access
        }else if (roleName == "KTT" || roleName == "BGD") {
            // newUserManagers = (user_access.length > 0 ) ? user_access.concat(uniqueUserNew) : (uniqueUserNew.length > 0 ) ? user_access.concat(uniqueUserNew) : []
            if (roleName == "KTT") {
                let listReceiverCaoHon = FindArrayUserCapCaoHon(UserID, Users, roleName)
                let newUserManagers1 = (user_access.length > 0 ) ? user_access.concat(uniqueUserNew) : (uniqueUserNew.length > 0 ) ? user_access.concat(uniqueUserNew) : []
                newUserManagers = (newUserManagers1.length > 0 ) ? newUserManagers1.concat(listReceiverCaoHon) : (listReceiverCaoHon.length > 0 ) ? listReceiverCaoHon.concat(newUserManagers1) : []
                // console.debug(1111111, newUserManagers) //  || 63c0938fed60f11521b00875)
            }else {
                newUserManagers = (user_access.length > 0 ) ? user_access.concat(uniqueUserNew) : (uniqueUserNew.length > 0 ) ? user_access.concat(uniqueUserNew) : []
            }
        }else if (roleName == "Admin" || roleName == "BTGD") {
            newUserManagers = uniqueUserNew
        }

        //
        function filter_new_contract(array, UserID) {
            let returnObject = "";
            // if (UserID != undefined) returnObject = array.filter((element) => element.user.centers != null ? element.user.centers[0] : '' == UserID[0]);
            if (UserID != undefined) returnObject = array.filter((element) => element.CenterID == UserID[0]);
            if (returnObject != undefined) {
                return returnObject;
            } else {
                return "";
            }
        }

        function filter_Users(array, UserID) {
            let returnObject = "";
            if (UserID != undefined) returnObject = array.filter((element) => element._id === UserID);
            let lastElement = returnObject.pop();
            if (lastElement != undefined) {
                return lastElement;
            } else {
                return "";
            }
        }

        function filter_history(array, ContractID) {
            let returnObject = "";
            if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
            let lastElement = returnObject.pop();
            if (lastElement != undefined) {
                return lastElement;
            } else {
                return "";
            }
        }

        function get_user_id(userLogin, userManager) {
            let managedUsers = [];

            function findManagedUsers(managerId) {
                let users = userManager.filter((item) => item.manager_id[0] === managerId);
                if (users.length > 0) {
                    for (let user of users) {
                        findManagedUsers(user.user_id[0]);
                    }
                } else {
                    managedUsers.push(managerId);
                }
            }

            findManagedUsers(userLogin);
            return managedUsers;
        }

        function findUsers(array, roleName, idUser) {
            let usersBelongCenter = []
            let filterListUsers = FindArray(array, _id)
            switch (roleName) {
                case "Admin":
                case "BTGD":
                    if (filterListUsers != undefined) {
                        usersBelongCenter = array
                    }
                    break;
                    // if (filterListUsers != undefined) {
                    //     usersBelongCenter = filter_new_user(array, null, null, idUser, ["BGD", "KTT", "TPKD", "AM"])
                    // }
                    // break;
                case "BGD":
                    if (filterListUsers != undefined) {
                        usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["KTT", "TPKD", "AM"])
                    }
                    break;
                case "KTT":
                    if (filterListUsers != undefined) {
                        usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["TPKD", "AM"])
                    }
                    break;
                case "TPKD":
                    if (filterListUsers != undefined) {
                        usersBelongCenter = filter_new_user(array, filterListUsers.centers, filterListUsers.departments, idUser, ["AM"])
                    }
                    break;
            }
            // console.log("usersBelongCenter", usersBelongCenter)
            return usersBelongCenter
        }

        function filter_new_user(array, centers_id, department_id = null, idUser, listRole = null){
            let returnObject = [];
            if (centers_id !== null) {
                array.map(element => {
                    if (element.centers) {
                        if (element.centers[0] === centers_id[0] && element._id !== idUser && listRole.includes(element.role_details[0].name)) {
                            returnObject.push(element)
                        }
                    }
                })
            }else {
                array.map(element => {
                    if (element.centers) {
                        if (element._id !== idUser) {
                            returnObject.push(element)
                        }
                    }
                })

            }
            return returnObject
        }

        //////
        ///
        let check_access = contract_me_access == ""
        let check_access_new = contract_me_new_access == ""

                function get_status(array,ContractID){
            let returnObject = "";
            if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
            let lastElement = returnObject.pop();
            if(lastElement != undefined){
                return lastElement;
            }else{
                return '';
            }
        }

        function checkUsersAccessContact(history, roleName, UserID, statusID, filteredHistoryChangesCapTrenEdit, userContract, usersarray, rolearray) {
            let userContractAccess = (typeof userContract[0].user != "undefined" && typeof userContract[0].user != "string") ? userContract[0].user.roles[0] : (typeof userContract[0].user_details != "undefined" && typeof userContract[0].user_details == "object") ? userContract[0].user_details[0].roles[0] : ""
            let nameUserContract = FindArrayTPKD(usersarray, userContract[0].user, rolearray);
            let roleUserContract = userContractAccess
            let nameRoleContract = FindArrayRoleBTGD(Roles,roleUserContract).name;

            let access = false
            let historyContract = '';
            if(filteredHistoryChangesCapTrenEdit != null){
                if(filteredHistoryChangesCapTrenEdit.length > 0){
                    historyContract = filteredHistoryChangesCapTrenEdit.filter(x => x.contract === params.id && x.form != 'contract');
                }
            }

            // console.debug(111, usersarray, userContract, rolearray)
            // usersarray.map(x=> console.debug(UserID, x.user))
            // console.debug(111, {roleName, statusID, nameUserContract})
            // console.debug(1111, history, roleName, UserID, statusID, filteredHistoryChangesCapTrenEdit, userContract, usersarray, rolearray)

            switch(roleName){
                case 'AM':
                    switch(statusID){
                        case "1":
                        case "-1":
                            access = false
                            break;

                        case "2": 
                            access = false
                            break;

                        case "3":
                        case "5":
                        case "7":
                        case "-2":
                        case "-4":
                        case "-6":
                        case "6":
                        case "8":
                        case "9":
                        case "4":
                            if (historyContract != "") {
                                access = true
                            }else {
                                access = false
                            }
                            break;
                        case '10': 
                            access = true
                            break;
                    }
                    break;
                case 'TPKD':
                    switch(statusID){
                        case "1":
                            if (roleName == nameRoleContract) {
                                access = false
                            }else {
                                access = false
                            }
                            break;
                        case "2":
                            access = false
                            break;
                        case "-2":
                            access = false
                            break;
                        case "5":
                        case "7":
                        case "-4":
                        case "-6":
                        case "6":
                        case "8":
                        case "9":
                            if(historyContract != ''){
                                access = true
                            }else{
                                access = false
                            }
                            break;
                        case "10":
                            access = true
                            break;
                        case "3":
                            access = false
                            break;
                        case "-1":
                        case "4": 
                            access = false
                            break;
                    }
                    break;
                case 'KTT':
					switch(statusID){
						case "4": 
							access = false
							break;
						case "1": 
							if (roleName == nameUserContract) {
								access = false
							}else {
								if(nameRoleContract == "BTGD"){
									access = false
								}else{
									access = false
								}
							}
							break;
						case "5":
							access = false
							break;
						case "7":
						case "9":
							if (historyContract != "") {
								access = false // false if page Render_Button_Invoice
							}else {
								access = false // false if page Render_Button_Invoice
							}
							break;
						case "-4":
						case "-6":
						case "8":
							if (historyContract != "") {
								access = false
							}else {
								if(statusID == -4){
									access = false
								}else if(statusID == -6){
									access = false
								}else{
									access = false // false if page Render_Button_Invoice
								}
							}
							break;
						case "-1":
						case "2":
						case "3":
							access = false
							break;
						case "10":
							access = true
							break;
						case "-2":
						case "6":
						case "7":
							access = false
							break;
						}
                    break;
                case 'BGD':
                    switch(statusID){
						case "4":
						case "6":
							access = false
							break;
						case "1": 
							if (roleName == nameRoleContract) {
								access = false
							}else {
								access = false
							}
							break;
						case "7":
							access = false
							break;
						case "8":
							access = false
							break;
						case "-1":
						case "2":
						case "-2":
						case "5":
						case "3":
						case "4":
							access = false
							break;
						case "10":
							access = true
							break;
						case "-6":
							access = false
							break;
						case "9":
							if(historyContract != ''){
									access = true
								}else{
									access = false
								}
							break;
						default: 
							if (statusID < 9) {
								access = false
							}else {
								access = false
							}
							break;
					}
                    break;
                case 'BTGD':
                case 'Admin':
                    switch(statusID){
                        case "1": 
                            if (roleName == nameRoleContract) {
                                access = false
                            }else {
                                access = false
                            }
                            break;
                        case "10":
                            access = true
                            break;
                        default: 
                            access = false
                            break;
                    }
                    break;
            }

            console.debug(roleName, "checkUsersAccessContact:::return access:::", access)
            return access
        }

        function checkEditOrViewDetail(history, roleName, UserID, statusID, filteredHistoryChangesCapTrenEdit, userContract, usersarray, rolearray) {
			let userContractAccess = (typeof userContract[0].user != "undefined" && typeof userContract[0].user != "string") ? userContract[0].user.roles[0] : (typeof userContract[0].user_details != "undefined" && typeof userContract[0].user_details == "object") ? userContract[0].user_details[0].roles[0] : ""
            let nameUserContract = FindArrayTPKD(usersarray, userContract[0].user, rolearray);
            let roleUserContract = userContractAccess
            let nameRoleContract = FindArrayRoleBTGD(Roles,roleUserContract).name;

			let access = "none"
			let historyContract = '';
			if(filteredHistoryChangesCapTrenEdit != null){
				if(filteredHistoryChangesCapTrenEdit.length > 0){
					historyContract = filteredHistoryChangesCapTrenEdit.filter(x => x.contract === params.id && x.form != 'contract');
				}
			}

			switch(roleName){
				case 'AM':			
					switch(statusID){
						case "1":
						case "-1":
							access = "edit"
							break;

						case "2": 
							access = "view"
							break;

						case "3":
						case "5":
						case "7":
						case "-2":
						case "-4":
						case "-6":
						case "6":
						case "8":
						case "9":
						case "4":
							if (historyContract != "") {
								access = "none"
							}else {
								access = "view"
							}
							break;
						case "10": 
							access = "none"
							break;
					}
					break;
				case 'TPKD':
					switch(statusID){
						case "1":
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
						case "2":
							access = "edit"
							break;
						case "-2":
							access = "edit"
							break;
						case "5":
						case "7":
						case "-4":
						case "-6":
						case "6":
						case "8":
						case "9":
							if(historyContract != ''){
								access = "none"
							}else{
								access = "view"
							}
							break;
						case "10":
							access = "none"
							break;
						case "3":
							access = "edit"
							break;
						case "-1":
						case "4": 
							access = "view"
							break;
					}
					break;
				case 'KTT':
					switch(statusID){
						case "4": 
							access = "edit"
							break;
						case "1": 
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								if(nameRoleContract == "BTGD"){
									access = "edit"
								}else{
									access = "view"
								}
							}
							break;
						case "5":
							access = "edit"
							break;
						case "7":
						case "9":
							if (historyContract != "") {
								access = "view" // false if page Render_Button_Invoice
							}else {
								access = "view" // false if page Render_Button_Invoice
							}
							break;
						case "-4":
						case "-6":
						case "8":
							if (historyContract != "") {
								access = "none"
							}else {
								if(statusID == -4){
									access = "edit"
								}else if(statusID == -6){
									access = "view"
								}else{
									access = "view" // false if page Render_Button_Invoice
								}
							}
							break;
						case "-1":
						case "2":
						case "3":
							access = "view"
							break;
						case "10":
							access = "none"
							break;
						case "-2":
						case "6":
						case "7":
							access = "view"
							break;
						}
					break;
				case 'BGD':
					switch(statusID){
						case "4":
						case "6":
							access = "view"
							break;
						case "1": 
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
						case "7":
							access = "view"
							break;
						case "8":
							access = "view"
							break;
						case "-1":
						case "2":
						case "-2":
						case "5":
						case "3":
						case "4":
							access = "view"
							break;
						case "10":
							access = "none"
							break;
						case "-6":
							access = "edit"
							break;
						case "9":
							if(historyContract != ''){
									access = "none"
								}else{
									access = "view"
								}
							break;
						default: 
							if (statusID < 9) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
					}
					break;
				case 'BTGD':
				case 'Admin':
					switch(statusID){
						case "1": 
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
						case "10":
							access = "none"
							break;
						default: 
							access = "view"
							break;
					}
					break;
			}

			console.debug(roleName, "checkEditOrView:::return access:::", access)
			return access
		}

        function FindArrayTPKD(array, ID, Roles) {
            let returnObject = "";
            if (ID != undefined) returnObject = array.find(x => x._id === ID._id);
            if(returnObject != undefined){
                return returnObject.role_details[0].name;
            }else{
                return '';
            }
        }

        function FindArrayRoleBTGD(array, ID) {
            let returnObject = "";
            if (ID != undefined) returnObject = array.find(x => x._id === ID);
            if(returnObject != undefined){
                return returnObject;
            }else{
                return '';
            }
        }

        function FindArrayUserManagers(array, ID) {
			let returnObject = "";
			if (ID != undefined) returnObject = array.filter(x => x.user_id[0] === ID);
			if(returnObject != undefined){
				return returnObject;
			}else{
				return '';
			}
		}

        function FindArrayUserCapCaoHon(UserID , array_user, roleName) {
            let returnObject = array_user.find(x => x._id == UserID);
            let rs = []
            let filteredArray = [];

            switch(roleName){
                case 'AM':
                    if(returnObject != undefined){
                        filteredArray = array_user.filter(function(item) {
                            if(item.centers != undefined){
                                return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
                            }
                        });
                        let manage_UserId = FindArrayUserManagers(UserManagers,UserID);
                        if(manage_UserId.length > 0){
                            let manager_id_new = manage_UserId[0]['manager_id'];
                            let managerObject = array_user.find(x => x._id == manager_id_new);
                            if(managerObject != undefined){
                                filteredArray.push(managerObject);
                            }
                        }
                    }
                    break;
                case 'TPKD':
                    if(returnObject != undefined){
                        filteredArray = array_user.filter(function(item) {
                            if(item.centers != undefined){
                                return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
                            }else{
                                return item.role_details[0].name == 'BTGD'
                            }
                        });
                    }
                    // console.log('2')
                    break;
                case 'KTT':
                    if(returnObject != undefined){
                        filteredArray = array_user.filter(function(item) {
                            if(item.centers != undefined){
                                return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD' && item.role_details[0].name != 'KTT';
                            } else {
                                return item.role_details[0].name == 'BTGD'
                            }
                        });
                    }
                    break;
                case 'BGD':
                    if(returnObject != undefined){
                        filteredArray = array_user.filter(function(item) {
                            return item.role_details[0].name == 'BTGD';
                        });
                    }
                    // console.log('3')
                    break;
                case 'BTGD':
                case 'Admin':
                    filteredArray = array_user
                    break;
            }

            if (filteredArray.length > 0) {
                return filteredArray.map(item => item._id);
            }

            return filteredArray
        }

        //////// new update 2024-03-16
        let filteredHistoryChangesCapTrenEdit = historyChanges.filter(x => x.contract == params.id && x.form != 'contract' && x.requester != UserID)
        let StatusID = get_status(Historys,params.id) != '' ? get_status(Historys,params.id).StatusID : '0'
        let userContact = Contracts.filter(x => x._id == params.id)
        let kiemTraChoPhepTruyCap = checkUsersAccessContact(Historys, roleName, UserID, StatusID, filteredHistoryChangesCapTrenEdit, userContact, Users, Roles)
        let checkEditOrView = checkEditOrViewDetail(Historys, roleName, UserID, StatusID, filteredHistoryChangesCapTrenEdit, userContact, Users, Roles);

        if (LoanInterestsLoading) {
            body = (
                <div className="spinner-container">
                    <Spinner animation="border" variant="info" />
                </div>
            );
        } else if (LoanInterests.length === 0) {
            body = (
                <>
                    <Card className="text-center mx-5 my-5">
                        <Card.Header as="h1">CHI PHÍ LÃI VAY</Card.Header>
                        <Card.Body>
                            <Table responsive="lg" striped bordered hover size="lg" variant="">
                                <thead>
                                    <tr style={{ textAlign: "left" }}>
                                        <th colSpan={9}>Số hợp đồng/PO: {idcontractNew}</th>
                                    </tr>
                                    <tr style={{ textAlign: "left" }}>
                                        <th colSpan={9}>Khách hàng: {customer_name}</th>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>Doanh thu (có VAT)</th>
                                        <th>{TotalCostCustomerPaid.toLocaleString()}</th>
                                        <th colSpan={5}></th>
                                        <th colSpan={1}>
                                            {
                                                checkEditOrView == "none" || checkEditOrView == "view" ? 
                                                <>
                                                </>
                                                :
                                                <>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip">Nhập file excel</Tooltip>}>
                                                        <a
                                                            className="me-2 mb-2"
                                                            onClick={() => {
                                                                setShowAddLoanInterestModal(true);
                                                            }}
                                                        >
                                                            <img src={importExcel} width="36" height="36"></img>
                                                        </a>
                                                    </OverlayTrigger>
                                                </>
                                            }
                                        </th>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>Giá vốn (có VAT)</th>
                                        <th>{TotalCostNTP.toLocaleString()}</th>
                                        <th colSpan={5}></th>
                                        <th colSpan={1}>
                                            {
                                                checkEditOrView == "none" || checkEditOrView == "view" ? 
                                                <>
                                                </>
                                                :
                                                <>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip">Tải file excel</Tooltip>}>
                                                        <a className="me-2 mb-2" onClick={downloadFile}>
                                                            <img src={downLoadExcel} width="36" height="36"></img>
                                                        </a>
                                                    </OverlayTrigger>
                                                 </>
                                            }
                                        </th>
                                    </tr>
                                    <tr style={{ textAlign: "center" }}>
                                        <th style={{ verticalAlign: "inherit" }}>Ngày</th>
                                        <th style={{ verticalAlign: "inherit" }}>Diễn giải</th>
                                        <th style={{ verticalAlign: "inherit" }}>Số tiền KH trả</th>
                                        <th style={{ verticalAlign: "inherit" }}>Số tiền TT NTP</th>
                                        <th style={{ verticalAlign: "inherit" }}>Số tiền hàng còn nợ</th>
                                        <th style={{ verticalAlign: "inherit" }}>Số ngày</th>
                                        <th style={{ verticalAlign: "inherit" }}>Lãi suất</th>
                                        <th style={{ verticalAlign: "inherit" }}>Chi phí lãi vay</th>
                                        <th style={{ verticalAlign: "inherit" }}>Ghi chú</th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </Table>
                            <a href={`/summary/${params.id}`}>
                                <Button variant="primary">Xem PTHĐ</Button>
                            </a>
                            <span> </span>
                            <a href={`/inputform/${params.id}`}>
                                <Button variant="primary">Quay lại</Button>
                            </a>
                        </Card.Body>
                    </Card>
                </>
            );
        } else {
            body = (
                <>
                    <Card className="text-center mx-5 my-5">
                        <Card.Header as="h1">CHI PHÍ LÃI VAY</Card.Header>
                        <Card.Body>
                            <Table responsive="lg" striped bordered hover size="lg" variant="">
                                <thead>
                                    <tr style={{ textAlign: "left" }}>
                                        <th colSpan={9}>Số hợp đồng/PO: {idcontractNew}</th>
                                    </tr>
                                    <tr style={{ textAlign: "left" }}>
                                        <th colSpan={9}>Khách hàng: {customer_name}</th>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>Doanh thu (có VAT)</th>
                                        <th>{TotalCostCustomerPaid.toLocaleString()}</th>
                                        <th colSpan={5}></th>
                                        <th>
                                            {
                                                checkEditOrView == "none" || checkEditOrView == "view" ? 
                                                <>
                                                </>
                                                :
                                                <>
                                                    <ActionButtons_LoanInterest _idLoanInterest={params.id} />
                                                </>
                                            }
                                        </th>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>Giá vốn (có VAT)</th>
                                        <th>{TotalCostNTP.toLocaleString()}</th>
                                        <th colSpan={5}></th>
                                        <th colSpan={1}>
                                            <Button className="me-2 mb-2" onClick={downloadFile}>
                                                Tải file excel mẫu
                                            </Button>
                                        </th>
                                    </tr>
                                    <tr style={{ textAlign: "center" }}>
                                        <th style={{ verticalAlign: "inherit" }}>Ngày</th>
                                        <th style={{ verticalAlign: "inherit" }}>Diễn giải</th>
                                        <th style={{ verticalAlign: "inherit" }}>Số tiền KH trả</th>
                                        <th style={{ verticalAlign: "inherit" }}>Số tiền TT NTP</th>
                                        <th style={{ verticalAlign: "inherit" }}>Số tiền hàng còn nợ</th>
                                        <th style={{ verticalAlign: "inherit" }}>Số ngày</th>
                                        <th style={{ verticalAlign: "inherit" }}>Lãi suất</th>
                                        <th style={{ verticalAlign: "inherit" }}>Chi phí lãi vay</th>
                                        <th style={{ verticalAlign: "inherit" }}>Ghi chú</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {LoanInterests.map((val) => (
                                        <tr>
                                            <td>{convert_time(val.Dates)}</td>
                                            <td>{val.Explain}</td>
                                            <td>{val.CostCustomerPaid != null ? val.CostCustomerPaid.toLocaleString() : ""}</td>
                                            <td>{val.CostNTP != null ? val.CostNTP.toLocaleString() : ""}</td>
                                            <td>{val.OutstandingGoods != null ? val.OutstandingGoods.toLocaleString() : ""}</td>
                                            <td>{val.NumberDate}</td>
                                            <td>{(val.InterestRate * 100).toLocaleString()}%</td>
                                            <td>{val.InterestLoan != null ? val.InterestLoan.toLocaleString() : ""}</td>
                                            <td>{val.Note}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <th></th>
                                        <th>Tổng cộng</th>
                                        <th>{TotalCostCustomerPaid.toLocaleString()}</th>
                                        <th>{TotalCostNTP.toLocaleString()}</th>
                                        <th colSpan={3}></th>
                                        <th>{TotalInterestLoan.toLocaleString()}</th>
                                        <th></th>
                                    </tr>
                                </tbody>
                            </Table>
                            <a href={`/summary/${params.id}`}>
                                <Button variant="primary">Xem PTHĐ</Button>
                            </a>
                            <span> </span>
                            <a href={`/inputform/${params.id}`}>
                                <Button variant="primary">Quay lại</Button>
                            </a>
                        </Card.Body>
                    </Card>
                </>
            );
        }

        if (kiemTraChoPhepTruyCap) {
            return (
                <>
                    <div id="notfound">
                        <div className="notfound">
                            <h1>Oops!</h1>
                            <h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng kiểm tra lại thông tin.
                                <br/>
                                <Link style={{textDecoration: "none"}} to="/home">
                                    Trở về trang chủ
                                </Link>
                            </h6>
                        </div>
                    </div>
                </>
            )
        }else {
            if ((check_access == true && check_access_new  == false) || (check_access == false && check_access_new  == true) || (check_access == false && check_access_new  == false)) {
                // console.debug(1, typeof contract_me_new_access.user, contract_me_new_access)
                if (typeof contract_me_new_access.user == "string") {
                    // console.debug("1 thu gian: cho phep truy cap")
                    if (newUserManagers.includes(contract_me_new_access.user) == true) {
                        // console.debug("11 thu gian:: truy cap duoc", contract_me_new_access.user)
                        return (
                            <>
                                {body}
                                <ImportLoanInterestModal />
                                <Toast
                                    show={show}
                                    style={{ position: "fixed", top: "20%", right: "10px", zIndex: "999999" }}
                                    className={`bg-${type} text-white`}
                                    onClose={setShowToast.bind(this, {
                                        show: false,
                                        message: "",
                                        type: null,
                                    })}
                                    delay={3000}
                                    autohide
                                >
                                    <Toast.Body>
                                        <strong>{message}</strong>
                                    </Toast.Body>
                                </Toast>
                            </>
                        )
                    }else {
                        // console.debug("12 thu gian:: truy cap duoc", contract_me_new_access.user)
                        return (
                            <>
                                <div id="notfound">
                                    <div className="notfound">
                                        <h1>Oops!</h1>
                                        <h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
                                            <br/>
                                            <Link style={{textDecoration: "none"}} to="/home">
                                                Trở về trang chủ
                                            </Link>
                                        </h6>
                                    </div>
                                </div>
                            </>
                        )
                    }
                }else if (typeof contract_me_new_access.user == "object") {
                    // console.debug("2 thu gian:: khong truy cap duoc", contract_me_new_access.user._id)
                    // console.debug(newUserManagers.includes(contract_me_new_access.user._id))
                    if (newUserManagers.includes(contract_me_new_access.user._id) == true) {
                        // console.debug("13 thu gian:: truy cap duoc", contract_me_new_access.user._id)
                        return (
                            <>
                                {body}
                                <ImportLoanInterestModal />
                                <Toast
                                    show={show}
                                    style={{ position: "fixed", top: "20%", right: "10px", zIndex: "999999" }}
                                    className={`bg-${type} text-white`}
                                    onClose={setShowToast.bind(this, {
                                        show: false,
                                        message: "",
                                        type: null,
                                    })}
                                    delay={3000}
                                    autohide
                                >
                                    <Toast.Body>
                                        <strong>{message}</strong>
                                    </Toast.Body>
                                </Toast>
                            </>
                        )
                    }else {
                        // console.debug("14 thu gian:: truy cap duoc", contract_me_new_access.user._id)
                        return (
                            <>
                                <div id="notfound">
                                    <div className="notfound">
                                        <h1>Oops!</h1>
                                        <h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
                                            <br/>
                                            <Link style={{textDecoration: "none"}} to="/home">
                                                Trở về trang chủ
                                            </Link>
                                        </h6>
                                    </div>
                                </div>
                            </>
                        )
                    }
                }
            }else {
                return (
                    <>
                        <div id="notfound">
                            <div className="notfound">
                                <h1>Oops!</h1>
                                <h6>Rất tiếc, Hợp đồng không được tìm thấy trong hệ thống. Vui lòng kiểm tra lại.
                                    <br/>
                                    <Link style={{textDecoration: "none"}} to="/home">
                                        Trở về trang chủ
                                    </Link>
                                </h6>
                            </div>
                        </div>
                    </>
                )
            }
        }
    }
}
