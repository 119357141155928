import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { ConfigCenterContext } from '../../../contexts/ConfigCenterContext'
import { useParams } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'

const UpdateCenterModal = () => {
	// Contexts 
	const {
		showUpdateCenterModal,
		setShowUpdateCenterModal,
		Data_update,
		UpdateCenters,
		update_Center,
		setShowToast,
	} = useContext(ConfigCenterContext)

	// State
	const [updatedCenter, setUpdatedCenter] = useState(Data_update)
	useEffect(() => setUpdatedCenter(Data_update),[Data_update])

	let {
		CenterName,
	} = updatedCenter
	const params = useParams();
	const onChangeUpdateCenterForm = event =>{
		let value = event.target.value;
		setUpdatedCenter({ ...updatedCenter, [event.target.name]: event.target.value });
	}

	const closeDialog = () => {
		setUpdatedCenter(updatedCenter)
		setShowUpdateCenterModal(false)
	}

	const onSubmit = async event => {
		event.preventDefault()
		const { success, message } = await update_Center(updatedCenter)
		setShowUpdateCenterModal(false)
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
	}

	const isPatternNumber = userInput => {
		if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		return /^\d+(\.\d{0,2})?$/.test(userInput);
	}
	return (
		<Modal animation={false} show={showUpdateCenterModal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>Chỉnh sửa trung tâm</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<Form.Group>
						<Form.Text id='tenkh-help' muted as='h6'>
							Tên trung tâm
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Tên trung tâm'
							name='CenterName'
							required
							value={CenterName}
							onChange={onChangeUpdateCenterForm}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Cập nhật
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}



export default UpdateCenterModal




