import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


import { useContext, useState, useEffect } from 'react'
import { AuxiliaryCostContext } from '../../contexts/AuxiliaryCostContext'
import { useParams } from 'react-router-dom'

import { ProductCostContext } from '../../contexts/ProductCostContext'
import { AuthContext } from '../../contexts/AuthContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'

import axios from 'axios';
import { FileReaderInput } from 'react-file-reader';
import { apiUrl } from '../../contexts/constants'
import diacritics from 'diacritics';
const ImportAuxiliaryCostModal = () => {
	const params = useParams();
	// Contexts 
	const {
		AuxiliaryCostState: { AuxiliaryCosts, AuxiliaryCostsLoading },
		getAuxiliaryCosts_byidContract,
		setshowcreate_AuxiliaryCost_Modal,
		setshowadd_AuxiliaryCost_Cost_Modal,
		showToast: { show, message, type },
		setShowToast,
		showToast,
		_setValTotalOutputIntoMoney,
		setShowImportAuxiliaryCostModal,
		showImportAuxiliaryCostModal,
		insertAuxiliaryCostFile
	} = useContext(AuxiliaryCostContext)
	
	useEffect(() => getAuxiliaryCosts_byidContract(params.id), [])
	const [invalidFiles, setInvalidFiles] = useState([])
	const [selectedFiles, setSelectedFiles] = useState([]);

	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)
	let id_user = _id;

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	const handleFileChangeNew = (event) => {
	    // setSelectedFiles([...selectedFiles, ...event.target.files]);
	    const files = event.target.files;
	    const validFormats = ['jpg', 'jpeg', 'png', 'pdf', 'xlsx', 'docx']; // Định dạng tệp cho phép
	    const validFiles = [];
	    const invalidFiles = [];

	    for (let i = 0; i < files.length; i++) {
	      const file = files[i];
	      const fileExtension = file.name.split('.').pop().toLowerCase();

	      if (validFormats.includes(fileExtension)) {
	        validFiles.push(file);
	      } else {
	        invalidFiles.push(file.name);
	      }
	    }
	    if(invalidFiles.length > 0) setShowToast({ show: true, message:"File "+invalidFiles.join(", ")+" is wrong format", type: 'danger' })
	    setSelectedFiles([...selectedFiles, ...validFiles]);
	    setInvalidFiles([...invalidFiles])
	};

	const handleUploadNew = async (e) => {
		let btnUploadAuxiliaryCosts = document.getElementById("uploadAuxiliaryCosts");
		btnUploadAuxiliaryCosts.title = "Đang xử lý...";
		btnUploadAuxiliaryCosts.classList.add("disabled-upload-handle");
		e.currentTarget.disabled = true;
		
	    if (selectedFiles.length > 0) {
	        const formData = new FormData();
	        let string_file = '';
	        let type_file = '';
	        selectedFiles.forEach(file=>{
	        	let isValidUtf8 = checkUtf8Validity(file.name);
				let fileName = '';
				if(isValidUtf8){
					fileName = file.name;
				}else{
					fileName = removeVietnameseDiacritics(file.name);
				}
	        	formData.append('file', file,params.id+"_"+fileName);
	        	string_file += params.id+"_"+file.name+",";
	        	type_file = file.type;
	        })
	        string_file = string_file.substring(0,string_file.length -1);
	        
        	let newImportAuxiliary = {
        		CreateDateUpload: convert_time(),
        		UserUpload: username,
        		Document: string_file,
        		TypeFile: type_file,
        		TypeForm: "Auxiliary",
        		UserID: id_user,
        		ContractID: params.id
        	};

	        try {
		        const response = await axios.post(`${apiUrl}/upload/`, formData, {
		           headers: {
		             'Content-Type': 'multipart/form-data',
		           },
		        });

	        	const { success, message } = await insertAuxiliaryCostFile(newImportAuxiliary);
	        	const historyAttactFile = {
				    "requester": _id,
				    "contract": params.id,
				    "form": "upload-auxiliary",
				    "action": "create",
				    "data_change": "Attact File"
				}
				const { successHis, messageHis } = await addHistoryChange(historyAttactFile)
	        	setTimeout(function(){
	        		setShowToast({ show: true, message, type: success ? 'success' : 'danger' });
	        		location.reload();
	        	},2000);
	     	} catch (error) {
	        	let message = error.response.data.message;
		        setTimeout(function(){
		        	setShowToast({ show: true, message, type: 'danger'});
		        },1000);
	      	}
	    }
	};

	function removeVietnameseDiacritics(str) {
	  const cleanedStr = diacritics.remove(str);
	  return cleanedStr.replace(/\s/g, ''); // Loại bỏ khoảng trắng
	}

	function checkUtf8Validity(input) {
	  for (let i = 0; i < input.length; ) {
	    const byte = input.charCodeAt(i);
	    let bytesToRead;

	    if (byte < 0x80) {
	      bytesToRead = 1;
	    } else if (byte < 0xE0) {
	      bytesToRead = 2;
	    } else if (byte < 0xF0) {
	      bytesToRead = 3;
	    } else if (byte < 0xF8) {
	      bytesToRead = 4;
	    } else {
	      // Invalid UTF-8 byte
	      return false;
	    }

	    for (let j = 1; j < bytesToRead; j++) {
	      if (i + j >= input.length || (input.charCodeAt(i + j) & 0xC0) !== 0x80) {
	        // Invalid UTF-8 sequence
	        return false;
	      }
	    }

	    i += bytesToRead;
	  }

	  return true;
	}

	const closeDialog = () => {
	    resetImportAuxiliaryData()
	}
    const resetImportAuxiliaryData = () => {
	    setShowImportAuxiliaryCostModal(false)
    }
    function convert_time(){
		var date = new Date(); // Replace this with your actual date object
		var year = date.getFullYear();
		var month = ('0' + (date.getMonth() + 1)).slice(-2);
		var day = ('0' + date.getDate()).slice(-2);
		var hours = ('0' + date.getHours()).slice(-2);
		var minutes = ('0' + date.getMinutes()).slice(-2);
		var seconds = ('0' + date.getSeconds()).slice(-2);
		var formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		return formattedDate;
	}
	return (

		<Modal animation={false} show={showImportAuxiliaryCostModal} onHide={() => setShowImportAuxiliaryCostModal(false)}>
			<Modal.Header closeButton>
				<Modal.Title>Nhập chứng từ</Modal.Title>
			</Modal.Header>
			<Form>
				<Modal.Body>
					<div>
						<Form.Text id='Datetime-help' muted  as="h6">
							Chỉ chấp nhận file có định dạng .JPEG, .PNG, .JPG, .PDF, .XLSX, .DOCX
						</Form.Text>
						<input type="file" onChange={handleFileChangeNew} multiple/>
	      			</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' id="uploadAuxiliaryCosts" onClick={handleUploadNew}>
						Cập nhật
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}



export default ImportAuxiliaryCostModal




