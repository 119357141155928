import { createContext, useReducer, useState } from 'react'
import { UserManagerReducer } from '../reducers/UserManagerReducer'//Note
import {
	apiUrl,
	LOADED_FAIL,
	LOADED_SUCCESS,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from './constants'//Note
import axios from 'axios'

export const UserManagerContext = createContext()

const UserManagerContextProvider = ({ children }) => {
	// State
	const [UserManagerState, dispatch] = useReducer(UserManagerReducer, {
		UserManager: null,
		UserManagers: [],
		UserManagersLoading: true
	})

	// const [showAddUserModal, setShowAddUserModal] = useState(false)
	// const [showUpdateUserModal, setShowUpdateUserModal] = useState(false)
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	})

	// Get all Users
	const getAllUserManagers = async () => {
		try {
			const response = await axios.get(`${apiUrl}/api/forms/usermanager`)//note
			if (response.data.success) {
				dispatch({ type: LOADED_SUCCESS, payload: response.data.UserManager })//note
			}
		} catch (error) {
			dispatch({ type: LOADED_FAIL })
		}
	} 
	// User context data
	const UserManagerContextData = {
		UserManagerState,
		getAllUserManagers
	}

	return (
		<UserManagerContext.Provider value={UserManagerContextData}>
			{children}
		</UserManagerContext.Provider>
	)
}

export default UserManagerContextProvider
