import { Link } from 'react-router-dom'

const AccessDenied = () => {
	return (
		<div id="notfound">
			<div className="notfound">
				<h1>Oops!</h1>
				<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
					<br/>
					<Link style={{textDecoration: "none"}} to="/home">
						Trở về trang chủ
					</Link>
				</h6>
			</div>
		</div>
	)
}

export default AccessDenied
