import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { ConfigDepartmentContext } from '../../../contexts/ConfigDepartmentContext'
import { ConfigCenterContext } from '../../../contexts/ConfigCenterContext'
import { useParams } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'

const UpdateDepartmentModal = () => {
	// Contexts 
	const {
		showUpdateDepartmentModal,
		setShowUpdateDepartmentModal,
		Data_update,
		// UpdateDepartments,
		update_Department,
		setShowToast,
	} = useContext(ConfigDepartmentContext)

	const {
		CenterState: { Centers, CentersLoading },
		get_All_Center
	} = useContext(ConfigCenterContext)

	// State
	const [updatedDepartment, setUpdatedDepartment] = useState(Data_update)
	useEffect(() => setUpdatedDepartment(Data_update),[Data_update])
	useEffect(() => get_All_Center(), [])
	let {
		CodeDepartment,
		DepartmentName,
		TypeDepartment,
		MandayCost,
		CentersID
	} = updatedDepartment

	console.log("updatedDepartment", updatedDepartment)

	const params = useParams();
	const onChangeUpdateDepartmentForm = event =>{
		let value = event.target.value;
		// if (!isPatternNumber(value) &&  value != '') {
		// 	var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
		// 	setShowToast({ show: true, message: message, type: 'danger' })
		// 	return false;
		// }else{
			if (event.target.name == 'TypeDepartment') {
				if(value == 2){
					setShowMandayCostUpdate(1);
				}else{
					setShowMandayCostUpdate('');
				}
			}	
			setUpdatedDepartment({ ...updatedDepartment, [event.target.name]: event.target.value });
			
		// }
	}

	const closeDialog = () => {
		setUpdatedDepartment(updatedDepartment)
		setShowUpdateDepartmentModal(false)
	}

	const onSubmit = async event => {
		event.preventDefault()
		if(updatedDepartment.TypeDepartment == 1) updatedDepartment.MandayCost = null;
		const { success, message } = await update_Department(updatedDepartment)
		setShowUpdateDepartmentModal(false)
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
	}
	let [showMandayCostUpdate, setShowMandayCostUpdate] = useState()
	if(TypeDepartment != undefined) {
		if(TypeDepartment == 2){
			showMandayCostUpdate = 1;
		}else{
			showMandayCostUpdate = '';
		}
	}
	const isPatternNumber = userInput => {
		if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		return /^\d+(\.\d{0,2})?$/.test(userInput);
	}
	return (
		<Modal animation={false} show={showUpdateDepartmentModal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>Chỉnh sửa phòng ban</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<Form.Group>
						<Form.Text id='ContentCost-help' muted as="h6">
							Chọn loại phòng ban
						</Form.Text>
						<Form.Control
							id = 'noidung-help'
							as="select"
							name='TypeDepartment'
							required
							aria-describedby='noidung-help'
							value={TypeDepartment}
							onChange={onChangeUpdateDepartmentForm}
							>
							<option value="">--------------------------</option>
							<option value='1'>Mặc định</option>
							<option value='2'>Manday</option>
					    </Form.Control>

					    {(showMandayCostUpdate != "") && (
					    	<>
							    <Form.Text id='tenhang-help' muted as='h6'>
									Manday chuẩn
								</Form.Text>
								<Form.Control
									type='text'
									placeholder='Manday chuẩn'
									name='MandayCost'
									value={MandayCost}
									onChange={onChangeUpdateDepartmentForm}
								/>
							</>
						)}

						<Form.Text id='tenhang-help' muted as='h6'>
							Trung tâm
						</Form.Text>
						<Form.Control
							id = 'noidung-help'
							as="select"
							name='CentersID'
							required
							aria-describedby='noidung-help'
							value={CentersID}
							onChange={onChangeUpdateDepartmentForm}
							>
							<option value="">--------------------------</option>
							{
								typeof Centers != "undefined" ? 
								Centers.map((obj => {
									return (
										<>
											<option value={obj._id.trim()} >{obj.CenterName.trim()}</option>
										</>
									)
								}))
								:
								<></>
							}
					    </Form.Control>

						<Form.Text id='tenhang-help' muted as='h6'>
							Mã phòng
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Mã phòng'
							name='CodeDepartment'
							required
							value={CodeDepartment}
							onChange={onChangeUpdateDepartmentForm}
						/>

						<Form.Text id='tenhang-help' muted as='h6'>
							Tên phòng KD
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Tên phòng KD'
							name='DepartmentName'
							required
							value={DepartmentName}
							onChange={onChangeUpdateDepartmentForm}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Cập nhật
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}



export default UpdateDepartmentModal




