import Button from 'react-bootstrap/Button'
import playIcon from '../../assets/play-btn.svg'
import editIcon from '../../assets/pencil.svg'
import deleteIcon from '../../assets/trash.svg'
import { GuaranteeLetterCostContext } from '../../contexts/GuaranteeLetterCostContext'
import { useContext } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import iconEdit from '../../assets/icon-edit.svg'
import iconDelete from '../../assets/icon-delete.svg'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useParams } from 'react-router-dom'

import { AuthContext } from '../../contexts/AuthContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'

const ActionButtons_GuaranteeLetterCost = ({ _idGuaranteeLetterCost }) => {
	const params = useParams();
	// Contexts
	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)
	
	const { deleteGuaranteeLetterCost, findGuaranteeLetterCost, setShowUpdateGuaranteeLetterCostModal } = useContext(
		GuaranteeLetterCostContext
	) //goi ActionButtons cho component khác đc kg ???

	const chooseGuaranteeLetterCost = GuaranteeLetterCostId => {
		findGuaranteeLetterCost(GuaranteeLetterCostId)
		setShowUpdateGuaranteeLetterCostModal(true)
	}
	function submit () {
		confirmAlert({
		  title: '',
		  message: 'Xoá chi phí thư bảo lãnh',
		  buttons: [
			{
			  label: 'Có',
			  onClick: async () => { 
			  	deleteGuaranteeLetterCost(_idGuaranteeLetterCost)
			  	const historyChangeGuaranteeLetterCost = {
				    "requester": _id,
				    "contract": params.id,
				    "form": "guarantee-letter-cost",
				    "action": "delete",
				    "data_change": "delete " + JSON.stringify(_idGuaranteeLetterCost)
				}

				const { successHis, messageHis } = await addHistoryChange(historyChangeGuaranteeLetterCost)
			  }
			},
			{
			  label: 'Không',
			  onClick: () => closeDialog()
			}
		  ]
		});
	  };
	  const closeDialog = () => {
		setShowUpdateGuaranteeLetterCostModal(false)
	}
	return (
		<>
			<OverlayTrigger
				overlay={
					<Tooltip id="tooltip">
						Cập nhật
					</Tooltip>
				}
			>
				<Button className='post-button' onClick={chooseGuaranteeLetterCost.bind(this, _idGuaranteeLetterCost)}>
					<img src={iconEdit} alt='edit' width='24' height='24' />
				</Button>
			</OverlayTrigger>
			<OverlayTrigger
				overlay={
					<Tooltip id="tooltip">
						Xóa
					</Tooltip>
				}
			>
				<Button className='post-button' onClick={submit}>
					<img src={iconDelete} alt='delete' width='24' height='24' />
				</Button>
			</OverlayTrigger>
		</>
	)
}

export default ActionButtons_GuaranteeLetterCost
