import { AuthContext } from '../contexts/AuthContext'
import { AuxiliaryCostContext } from '../contexts/AuxiliaryCostContext'
import { ProductCostContext } from '../contexts/ProductCostContext'
import { MandayCostContext } from '../contexts/MandayCostContext'
import { GuaranteeLetterCostContext } from '../contexts/GuaranteeLetterCostContext'
import { MiscExpenseCostContext } from '../contexts/MiscExpenseContext'
import { CapitalExpenditureCostContext } from "../contexts/CapitalExpenditureCostContext"
import { LoanInterestContext } from "../contexts/LoanInterestContext"
import { ImplementationCostContext } from '../contexts/ImplementationCostContext'
import { ContractContext } from '../contexts/ContractContext'
import { ConfigCustomerContext } from '../contexts/ConfigCustomerContext'

import { useContext, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card'
import Toast from 'react-bootstrap/Toast'
import Create_AuxiliaryCost_Modal from '../components/AuxiliaryCost/Create_AuxiliaryCost_Modal'//Note
import Add_AuxiliaryCost_Cost_Modal from '../components/AuxiliaryCost/Add_AuxiliaryCost_Cost_Modal'
import Update_AuxiliaryCost_Cost_Modal from '../components/AuxiliaryCost/Update_AuxiliaryCost_Cost_Modal'//Note

import { UserContext } from '../contexts/UserContext'
import { RoleContext } from '../contexts/RoleContext'
import { HistoryContext } from '../contexts/HistoryContext'
import { EventContext } from '../contexts/EventContext'
import { UserManagerContext } from '../contexts/UserManagerContext'
import { HistoryChangeContext } from '../contexts/HistoryChangeContext'
import { InvoiceContext } from '../contexts/InvoiceContext'

import Table from 'react-bootstrap/Table'
//import ActionButtons from '../components/posts/ActionButtons'
import ActionButtons_AuxiliaryCost from '../components/AuxiliaryCost/ActionButtons_AuxiliaryCost'
//new
import iconPlus from '../assets/plus_circle_fill.svg'
import ImportAuxiliaryCostModal from '../components/AuxiliaryCost/ImportAuxiliaryCostModal'//Note
import { apiUrl } from '../contexts/constants'
import axios from 'axios';
import diacritics from 'diacritics';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import iconEdit from '../assets/icon-edit.svg'
import iconDelete from '../assets/icon-delete.svg'
import { confirmAlert } from 'react-confirm-alert';

//View AuxiliaryCost by idContract
//Execute: running
export const AuxiliaryCost_byidContract = () => {
	const params = useParams()
	// Contexts
	const {
		authState: {
			user: { username, _id, roles }
		}
	} = useContext(AuthContext)

	const {
		RoleState: { Role, Roles, RolesLoading },
		getRoles,
	} = useContext(RoleContext)
	useEffect(() => getRoles(), [])

	const {
		AuxiliaryCostState: { AuxiliaryCosts, AuxiliaryCostsLoading },
		getAuxiliaryCosts_byidContract,
		setshowcreate_AuxiliaryCost_Modal,
		setshowadd_AuxiliaryCost_Cost_Modal,
		showToast: { show, message, type },
		setShowToast,
		showToast,
		_setValTotalOutputIntoMoney, //new
		setShowImportAuxiliaryCostModal //new
	} = useContext(AuxiliaryCostContext)
	// Start: Get AuxiliaryCosts by id Contract
	useEffect(() => getAuxiliaryCosts_byidContract(params.id), [])//,[showToast])

	//console.log("AuxiliaryCost_byidContract======>>>>>")
	///// kcuf game 
	//=== Get data ImplementationCost
	const {
		ImplementationCostState: { ImplementationCosts },
		getImplementation_Costs_byidContract
	} = useContext(ImplementationCostContext)
	useEffect(() => getImplementation_Costs_byidContract(params.id), [])
	//console.log("ImplementationCosts:::", ImplementationCosts)

	//=== Get ProductCosts 
	const {
		ProductCostState: { ProductCost, ProductCosts, ProductCostsLoading },
		getProductCost_idContract,
		setShowAddProductCostModal
	} = useContext(ProductCostContext)
	useEffect(() => getProductCost_idContract(params.id), [])
	//console.log("ProductCosts:::::", ProductCosts)

	//=== Get MandayCost 
	const {
		MandayCostState: { MandayCost, MandayCosts, MandayCostsLoading },
		getMandayCost_byidContract,
		setShowAddMandayCostModal
	} = useContext(MandayCostContext)
	// Start: Get all MandayCost by idContract
	useEffect(() => getMandayCost_byidContract(params.id), [])
	//console.log("MandayCosts===: ", MandayCosts)

	//=== Get data CapitalExpenditureCost chi phi von
	const {
		CapitalExpenditureCostState: { CapitalExpenditureCost, CapitalExpenditureCosts, CapitalExpenditureCostsLoading },
		getCapitalExpenditureCosts_byidContract
	} = useContext(CapitalExpenditureCostContext);
	// Start: Get all CapitalExpenditureCosts
	useEffect(() => getCapitalExpenditureCosts_byidContract(params.id), []);
	// console.log("CapitalExpenditureCosts===: ", CapitalExpenditureCosts)

	//=== Get data LoanInterest chi phi lãi vay
	const {
	    LoanInterestState: { LoanInterest, LoanInterests, LoanInterestsLoading },
	    getLoanInterest_byidContract
	} = useContext(LoanInterestContext);
	useEffect(() => getLoanInterest_byidContract(params.id), [])
	const TotalLoanInterest = sumArray(LoanInterests.map((LoanInterest ) => LoanInterest.InterestLoan))//ch
	//console.log(LoanInterests,'LoanInterests')
	//=== End Get data LoanInterest

	//=== Get GuaranteeLetterCost
	const {
		GuaranteeLetterCostState: { GuaranteeLetterCost, GuaranteeLetterCosts, GuaranteeLetterCostsLoading },
		getGuaranteeLetterCost_byidContract,
		setShowAddGuaranteeLetterCostModal
	} = useContext(GuaranteeLetterCostContext)
	useEffect(() => getGuaranteeLetterCost_byidContract(params.id), [])
	// console.log("GuaranteeLetterCosts===: ", GuaranteeLetterCosts)
	//=== End Get GuaranteeLetterCost

	//=== Get MiscExpenseCosts
	const {
		MiscExpenseCostState: { MiscExpenseCost, MiscExpenseCosts, MiscExpenseCostsLoading },
		getMiscExpenseCost_byidContract,
		setShowAddMiscExpenseCostModal
	} = useContext(MiscExpenseCostContext)
	// Start: Get all MiscExpenseCosts
	useEffect(() => getMiscExpenseCost_byidContract(params.id), [])
	// console.log("MiscExpenseCosts===: ", MiscExpenseCosts)
	//=== End Get MiscExpenseCosts

	//=== Get Invoices
	const {
        InvoiceState: { Invoice, Invoices, InvoicesLoading },
        getInvoice_ContractID
    } = useContext(InvoiceContext)
    useEffect(() => getInvoice_ContractID(params.id), [params.id])
    // console.log("Invoices===: ", Invoices)
	//=== End Get GuaranteeLetterCost

	const {
		ContractState: {Contracts, ContractsLoading},
		getContract_byid
	} = useContext(ContractContext)

	useEffect(() => getContract_byid(params.id), []);
	//=== End Get Contract

	const TotalInputIntoMoney = Sum_InputIntoMoney(ProductCosts) // ProductCosts = useEffect(() => getContract_byid(params.id), [])
	const TotalOutputIntoMoney = Sum_OutputIntoMoney(ProductCosts) // ProductCosts = useEffect(() => getContract_byid(params.id), [])
	const ImplementationCost = TotalStageImplementation(ImplementationCosts); // ImplementationCosts = useEffect(() => getImplementation_Costs_byidContract(params.id), [])
	let TotalIncentive = 0;
	if(ProductCosts[0] !== undefined) TotalIncentive = sumArray(ProductCosts[0].ListProducts.map((ProductCost) => ProductCost.Incentive))//note
	// 1.1 Chi phí triển khai hợp đồng + Chi phi Bảo hiểm
	const TotalInsurance = 0.006 * Sum_Insurance(ProductCosts) //ProductCosts = useEffect(() => getContract_byid(params.id), [])
	const TotalInsuranceHSE = Sum_InsuranceHSE(ProductCosts)
	const TotalImplementationCost = ImplementationCost + TotalInsurance + TotalInsuranceHSE;
	const TotalCapitalExpense = sumArray(CapitalExpenditureCosts.map((CapitalExpenditureCost) => CapitalExpenditureCost.CapitalExpense))
	// => CapitalExpenditureCost = useEffect(() => getCapitalExpenditureCosts_byidContract(params.id), []);
	const TotalMandayCost = sumArray(MandayCosts.map((MandayCost) => MandayCost.IntoMoney)) // MandayCost => useEffect(() => getMandayCost_byidContract(params.id), [])
	const TotalGuaranteeLetterCost = sumArray(GuaranteeLetterCosts.map((GuaranteeLetterCost) => GuaranteeLetterCost.IntoMoney)) // GuaranteeLetterCost = useEffect(() => getGuaranteeLetterCost_byidContract(params.id), [])
	const TotalMiscExpenseCost = sumArray(MiscExpenseCosts.map((MiscExpenseCost) => MiscExpenseCost.Cost)) // MiscExpenseCost = useEffect(() => getMiscExpenseCost_byidContract(params.id), [])
	// //
	let chiPhi = 0;
	let namechiPhi = 0;
	if(TotalCapitalExpense != 0){
		chiPhi = TotalCapitalExpense;
		namechiPhi = 'Chi phí vốn';
	}else{
		if(TotalLoanInterest != 0){
			chiPhi = TotalLoanInterest;
			namechiPhi = 'Chi phí lãi vay';
		}
	}
	const ExtraCost = (TotalImplementationCost + chiPhi + TotalMandayCost + TotalGuaranteeLetterCost + TotalMiscExpenseCost)
	// 
	const TotalCost = SumListCost(AuxiliaryCosts, TotalOutputIntoMoney);
	// //
	const rsTotalAuxiliaryCost = totalAuxiliaryCost(AuxiliaryCosts, TotalOutputIntoMoney)
	//console.log("rsTotalAuxiliaryCost=== : ", rsTotalAuxiliaryCost.muc2.toLocaleString())
	/// chi phi vat tu phu
	function totalAuxiliaryCost(AuxiliaryCosts, TotalOutputIntoMoney){
		let sumCostTaxA = 0;
		let sumCostTaxB = 0;
		
		let sumCostA = 0;
		let sumCostB = 0;
		
		let sumTaxA = 0;
		let sumTaxB = 0;
		
		AuxiliaryCosts.map(AuxiliaryCost => (
			AuxiliaryCost.ListCosts.map(function(ListCost, index) {
				var newCost = 0
				var newTax = 0
				var newCostTax = 0
				if (AuxiliaryCost.Plan == 1){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost * ListCost.TaxRate)/100).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}else if (AuxiliaryCost.Plan == 2){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost / (100 - ListCost.TaxRate)) * ListCost.TaxRate).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostA += parseInt(newCost);
				}else if (AuxiliaryCost.Plan == 2){
					sumCostB += parseInt(newCost);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumTaxA += parseInt(newTax);
				}else if (AuxiliaryCost.Plan == 2){
					sumTaxB += parseInt(newTax);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostTaxA += parseInt((newCost+newTax));
				}else if (AuxiliaryCost.Plan == 2){
					sumCostTaxB += parseInt((newCost+newTax));
				}
			})
		))
		let muc2_1 = parseInt((sumCostA + sumCostB)/10);
		let muc2_thue = parseInt((sumTaxA + sumTaxB)/10);
		let muc2 = parseInt(muc2_1 + muc2_thue);
		return {muc2, muc2_1, muc2_thue}
	}

	const TotalCostTaxTrongChuanA = TotalCostTaxAuxiliaryCosts(AuxiliaryCosts)

	// //
	//
	const hieuquaduan = (TotalOutputIntoMoney - TotalInputIntoMoney - ExtraCost - (rsTotalAuxiliaryCost.muc2*10))

	const percentTrongA_QHDU = (isNaN(TotalCostTaxTrongChuanA/hieuquaduan)) ? 0 : ((TotalCostTaxTrongChuanA/hieuquaduan)*100).toFixed(2)
	const alertOverPercent30 = (Math.abs(percentTrongA_QHDU) > 30) ? "alertOverPercent30": "";
	const titleOverPercent30 = (Math.abs(percentTrongA_QHDU) > 30) ? "Sổ tổng A bắt buộc nhỏ hơn hoặc bằng 30% mục 3 của PTHD Tổng thể" : "";
	//console.log(TotalOutputIntoMoney,TotalInputIntoMoney,ExtraCost,(rsTotalAuxiliaryCost.muc2/10),hieuquaduan,'===============================================')
	//129078000 123967000 2468000 26075 2616925 '==============================================='
	//129078000 123967000 0 26075 5084925 '==============================================='
	//console.log("percentTrongA_QHDU", Math.abs(percentTrongA_QHDU), isNaN(percentTrongA_QHDU))
	//func 
	//Ham tinh tong Phan tử trong kieu mang 
	function Sum_InputIntoMoney(ProductsCost) {
		let Total_InputIntoMoney = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_InputIntoMoney += ListProduct.InputIntoMoney
			)))

		return Total_InputIntoMoney;
	}
	//Ham tinh tong Phan tử trong kieu mang 
	function Sum_OutputIntoMoney(ProductsCost) {
		let Total_OutputIntoMoney = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_OutputIntoMoney += ListProduct.OutputIntoMoney
			)))

		return Total_OutputIntoMoney;
	}
	// Ham tinh tong tat ca giai doan trien khai
	function TotalStageImplementation(ImplementationCosts) {
		let All_Total = 0;
		ImplementationCosts.map((element) => (
			element.StagesImplementation.map((object) => (
				object.Costs.map((Costs) =>
					All_Total += Costs.IntoMoney
				)))))
		return All_Total;
	}
	//Tinh tong thanh tien gia ban voi Insurance = true
	function Sum_Insurance(ProductsCost) {
		let Total_Insurance = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				ListProduct.Insurance === true ? Total_Insurance += ListProduct.OutputIntoMoney : 0
			)))

		return Total_Insurance;
	}
	//Tinh tong thanh tien gia ban voi InsuranceHSE
	function Sum_InsuranceHSE(ProductsCost) {
		let Total_InsuranceHSE = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				ListProduct.InsuranceHSE === true ? Total_InsuranceHSE += (ListProduct.OutputIntoMoney*(ListProduct.PercentHSE / 100)) : 0
			)))
		return Total_InsuranceHSE;
	}
	// hàm tính tổng thành tiền
	function sumArray(mang) {
		let sum = 0;
		mang.map(function (value) {
			sum += value;
		});
		return sum;
	}
	function SumListCost(Auxiliary, TotalOutputIntoMoney) {
		let kq = 0;
		Auxiliary.map(AuxiliaryCost =>
			AuxiliaryCost.ListCosts.map(ListCost => (
				kq += changeCost(ListCost.Cost, TotalOutputIntoMoney)
			)))

		return kq;
	}
	function FindPlan(Auxiliary) {
		let returnPlan = 0;
		Auxiliary.map(AuxiliaryCost => returnPlan = AuxiliaryCost.Plan
		)
		return returnPlan;
	}

	function TotalCostTaxAuxiliaryCosts(AuxiliaryCosts) {
		let All_Total = 0;
		AuxiliaryCosts.map((element) => {
			if (element.Plan == 1) {
				element.ListCosts.map((Costs) => {
					All_Total += Costs.CostTax
				})
			}
		})
		return All_Total;
	}
	
	function Sum_InsuranceHSE(ProductsCost) {
		let Total_InsuranceHSE = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				ListProduct.InsuranceHSE === true ? Total_InsuranceHSE += (ListProduct.OutputIntoMoney*((ListProduct.PercentHSE != null ? ListProduct.PercentHSE : 1) / 100)) : 0
			)))
		return Total_InsuranceHSE;
	}
	
	////
	// Start: Get ProductCosts by id Contract ==>> Load doanh thu
	function changeCost(value, heso) {
		let kq = value;
		if (value < 1)
			kq = value * heso;
		return kq
	}

	//Hàm tính tổng mang có chuyển giá trị %
	function sumTotal(mang, heso) {
		let sum = 0;
		mang.map(function (value) {
			sum += changeCost(value, heso);
		});
		return sum;
	}

	//Ham tinh tong Phan tử trong kieu mang
	function SumListCost(Auxiliary) {
		let kq = 0;
		Auxiliary.map(AuxiliaryCost =>
			AuxiliaryCost.ListCosts.map(ListCost => (
				kq += ListCost.Cost
			)))

		return kq;
	}

	function SumListTax(Auxiliary) {
		let kq = 0;
		Auxiliary.map(AuxiliaryCost =>
			AuxiliaryCost.ListCosts.map(ListCost => (
				kq += ListCost.Tax
			)))

		return kq;
	}

	function SumListCostTax(Auxiliary) {
		let kq = 0;
		Auxiliary.map(AuxiliaryCost =>
			AuxiliaryCost.ListCosts.map(ListCost => (
				kq += ListCost.CostTax
			)))

		return kq;
	}
	function FindPlan(Auxiliary) {
		let kq = 0;
		Auxiliary.map(AuxiliaryCost => kq = AuxiliaryCost.Plan
		)

		return kq;
	}
	function FindRevenue(Auxiliary) {
		let kq = 0;
		Auxiliary.map(AuxiliaryCost => kq = AuxiliaryCost.Revenue
		)

		return kq;
	}
	//Execute: Not run
	function FindCPGross(Auxiliary) {
		let CPXL = 0;
		let CPgross = 0;
		const phuongan = FindPlan(AuxiliaryCosts);
		let TotalCost = SumListCost(AuxiliaryCosts) * FindRevenue(AuxiliaryCosts);
		if (phuongan === 1) {
			CPXL = TotalCost / 0.8 * 0.2;
			CPgross = CPXL + TotalCost;
			console.log("Phuong an 1========", CPXL)
		}
		else {
			CPXL = TotalCost / 0.7 * 0.25;
			CPgross = CPXL + TotalCost;
		}
		return CPgross
	}
	//Execute: Running
	function Find_CPXL(Totalcost, plan) {
		let CPXL = 0;
		if (plan === 1) {
			CPXL = Totalcost / 0.8 * 0.2;
		}
		else {
			CPXL = Totalcost / 0.7 * 0.25;
		}
		return CPXL
	}
	//=== End Get productcosts with idcontract
	let body = null
	let stt = 1

	const {
		CustomerState: { Customer, Customers, CustomersLoading },
		get_All_Customer
	} = useContext(ConfigCustomerContext)
	useEffect(() => get_All_Customer(), [])
	// console.log(Customers,'=============Customersa')
	// console.log(Contracts,'=============Contracts')
	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}
	
	let idcontractNew = '';
	let customer_name = '';
	if(Contracts.length > 0){
		var filterContact = FindArray(Contracts, params.id)
		customer_name = FindArray(Customers, filterContact.CustomerID) != '' ? FindArray(Customers, filterContact.CustomerID).CustomerName : filterContact.CustomerID
		idcontractNew = filterContact != '' ? filterContact.ContractID : ''
	}

	//new
	async function downLoadFile(apiUrl, Document, id){
		Document = removeVietnameseDiacritics(Document);
		const response = await axios.get(
			`${apiUrl}/files/${Document}`, //note xem trong server
			{responseType: 'arraybuffer'}
		);

        // Tạo Blob từ dữ liệu ArrayBuffer
	    const blob = new Blob([response.data], { type: 'application/octet-stream' });

	    // Tạo URL từ Blob
	    const url = window.URL.createObjectURL(blob);

	    // Tạo thẻ a để tạo liên kết tải xuống
	    const link = document.createElement('a');
	    link.href = url;
	    let file_name = format_string(Document,id);
	    file_name = encodeURIComponent(file_name); // Mã hoá tên tệp
			file_name = file_name.replace(/%20/g, '_');
	    link.setAttribute('download', file_name); // Thay bằng tên tệp tải xuống
	    document.body.appendChild(link);
	    link.click();
	    link.parentNode.removeChild(link);

	    // Giải phóng tài nguyên
	    window.URL.revokeObjectURL(url);
	}

	function removeVietnameseDiacritics(str) {
	  const cleanedStr = diacritics.remove(str);
	  return cleanedStr.replace(/\s/g, ''); // Loại bỏ khoảng trắng
	}

	function format_string(Document,id){
		if (Document.includes(id)) {
			Document = Document.replace(id+'_','');
		}
		return Document;
	}
	function convert_to_array(string){
		return string.split(",");
	}
	//new
	const [dataUploadNew, setDataUploadNew] = useState(null);
  	// State để theo dõi việc loading
  	const [loading, setLoadingNew] = useState(true);
  	// State để theo dõi lỗi (nếu có)
  	const [error, setErrorNew] = useState(null);
	useEffect(() => {
    // Hàm fetchData để gọi API
	    const fetchData = async () => {
	    	console.log('sdsadsadsadsadsadsad')
	      try {
	        // Thực hiện GET request với Axios
	        const response = await axios.get(`${apiUrl}/api/upload/auxiliary-cost/${params.id}`)
	        // Lưu trữ dữ liệu từ response vào state
	        setDataUploadNew(response.data);
	      } catch (error) {
	        // Nếu có lỗi, lưu trữ lỗi vào state
	        setErrorNew(error);
	      } finally {
	        // Đánh dấu việc loading đã hoàn thành
	        setLoadingNew(false);
	      }
	    };

	    // Gọi hàm fetchData
	    fetchData();
	  }, []); 

	async function downLoadFile(apiUrl, Document, id) {
        try{
            let DocumentNew = removeVietnameseDiacritics(Document);
            const response = await axios.get(
                `${apiUrl}/files/${DocumentNew}`, //note xem trong server
                { responseType: "arraybuffer" }
            );
             // Tạo Blob từ dữ liệu ArrayBuffer
            const blob = new Blob([response.data], { type: "application/octet-stream" });

            // Tạo URL từ Blob
            const url = window.URL.createObjectURL(blob);

            // Tạo thẻ a để tạo liên kết tải xuống
            const link = document.createElement("a");
            link.href = url;
            let file_name = format_string(Document, id);
            // file_name = encodeURIComponent(file_name); // Mã hoá tên tệp
            // file_name = file_name.replace(/%20/g, "_");
            link.setAttribute("download", file_name); // Thay bằng tên tệp tải xuống
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            // Giải phóng tài nguyên
            window.URL.revokeObjectURL(url);
        }
        catch(error){
            if(error.response.status > 200){
                const response = await axios.get(
                    `${apiUrl}/files/${Document}`, //note xem trong server
                    { responseType: "arraybuffer" }
                );
                 // Tạo Blob từ dữ liệu ArrayBuffer
                const blob = new Blob([response.data], { type: "application/octet-stream" });

                // Tạo URL từ Blob
                const url = window.URL.createObjectURL(blob);

                // Tạo thẻ a để tạo liên kết tải xuống
                const link = document.createElement("a");
                link.href = url;
                let file_name = format_string(Document, id);
                // file_name = encodeURIComponent(file_name); // Mã hoá tên tệp
                // file_name = file_name.replace(/%20/g, "_");
                link.setAttribute("download", file_name); // Thay bằng tên tệp tải xuống
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);

                // Giải phóng tài nguyên
                window.URL.revokeObjectURL(url);
            }
        }
    }

    //function delete file attracted
    async function deleteFileAttached(apiUrl, id, Document) {
        confirmAlert({
          title: 'Xóa file đính kèm Chi phí vật tư phụ?',
          message: '',
          buttons: [
            {
              label: 'Có',
                onClick: async () => {
	                const response = await axios.delete(`${apiUrl}/api/upload/file-upload/delete/${id}`);
	                const historyChangeUploadFileAuxiliaryCost = {
	                    "requester": _id,
	                    "contract": params.id,
	                    "form": "upload-auxiliary",
	                    "action": "delete",
	                    "data_change": "delete "+ id
	                }

	                const { successHis, messageHis } = await addHistoryChange(historyChangeUploadFileAuxiliaryCost)
	                // console.debug("deleteFileAttached::response", response)
	                if (response.data.success) {
	                	setShowToast({ show: true, message: response.data.message, type: response.data.success ? 'success' : 'danger' })
	                	setTimeout(() => {
	                		window.location.reload();
						}, 1000);
	                }

	                const responsefilesNew = await axios.get(
	                    `${apiUrl}/filesNew/${Document}`, //note xem trong server
	                    { responseType: "arraybuffer" }
	                );
	                // console.debug("deleteFileAttached::responsefilesNew", responsefilesNew)
	                // console.debug("deleteFileAttached::successHis, messageHis", successHis, messageHis)
            	}
            },
            {
              label: 'Không',
              // onClick: () => closeDialog()
            }
          ]
        });
    }

    function removeVietnameseDiacritics(str) {
        const cleanedStr = diacritics.remove(str);
        return cleanedStr.replace(/\s/g, ""); // Loại bỏ khoảng trắng
    }

    function format_string(Document, id) {
        if (Document.includes(id)) {
            Document = Document.replace(id + "_", "");
        }
        return Document;
    }
    function convert_to_array(string) {
        return string.split(",");
    }

	let dataUploadAuxiliaryCostFile = '';	
	if(dataUploadNew != null)  dataUploadAuxiliaryCostFile = dataUploadNew.Upload;


		///////////////////////
	const {
		HistoryState: { History, Historys, HistorysLoading },
		getHistory
	} = useContext(HistoryContext)
	useEffect(() => getHistory(), [])

	///UserManagers
	const {
		UserManagerState: { UserManager, UserManagers, UserManagersLoading },
		getAllUserManagers
	} = useContext(UserManagerContext)
	useEffect(() => getAllUserManagers(), [])

	const {
		UserState: { User, Users, UsersLoading },
		getUsers
	} = useContext(UserContext)

	const {
        historyChangeState: { historyChange, historyChanges },
        getHistoryChanges,
        addHistoryChange
    } = useContext(HistoryChangeContext)


	if (ContractsLoading) {
		return (<></>)
	}else {
		let role_new = roles[0];
		let UserID = _id;
		let roleName = FindArray(Roles, role_new).name;

		let rows;
		let newContract;
		let newContract111 = [];
		if (roleName == "Admin" || roleName == "BTGD") {
		    newContract = Contracts;
		} else if (roleName == "KTT" || roleName == "BGD") {
		    let centerOfUser = filter_Users(Users, UserID).centers;
		    // console.log(centerOfUser,'====1111===')
		    if (centerOfUser != undefined) {
		        if (Contracts.length > 0) {
		            newContract111 = filter_new_contract(Contracts, centerOfUser);
		        }
		    }
		    newContract = newContract111;
		} else {
		    // console.debug(UserManagers,UserID,roleName,get_user_id(UserID, UserManagers),'sdsd')
		    let newContract1 = Contracts.filter((v) => get_user_id(UserID, UserManagers).includes(v.user._id));
		    if (roleName == "AM") {
		        newContract = newContract1;
		    } else {
		        let contract_me = Contracts.filter((v) => v.user._id == UserID);
		        let newContract2 = newContract1.concat(contract_me);
		        newContract = newContract2;
		    }
		}

				function get_status(array,ContractID){
			let returnObject = "";
			if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
			let lastElement = returnObject.pop();
			if(lastElement != undefined){
				return lastElement;
			}else{
				return '';
			}
		}

        function checkUsersAccessContact(history, roleName, UserID, statusID, filteredHistoryChangesCapTrenEdit, userContract, usersarray, rolearray) {
            let userContractAccess = (typeof userContract[0].user != "undefined" && typeof userContract[0].user != "string") ? userContract[0].user.roles[0] : (typeof userContract[0].user_details != "undefined" && typeof userContract[0].user_details == "object") ? userContract[0].user_details[0].roles[0] : ""
            let nameUserContract = FindArrayTPKD(usersarray, userContract[0].user, rolearray);
            let roleUserContract = userContractAccess
            let nameRoleContract = FindArrayRoleBTGD(Roles,roleUserContract).name;

            let access = false
            let historyContract = '';
            if(filteredHistoryChangesCapTrenEdit != null){
                if(filteredHistoryChangesCapTrenEdit.length > 0){
                    historyContract = filteredHistoryChangesCapTrenEdit.filter(x => x.contract === params.id && x.form != 'contract');
                }
            }

            // console.debug(111, usersarray, userContract, rolearray)
            // usersarray.map(x=> console.debug(UserID, x.user))
            // console.debug(111, {roleName, statusID, nameUserContract})
            // console.debug(1111, history, roleName, UserID, statusID, filteredHistoryChangesCapTrenEdit, userContract, usersarray, rolearray)

            switch(roleName){
                case 'AM':
                    switch(statusID){
                        case "1":
                        case "-1":
                            access = false
                            break;

                        case "2": 
                            access = false
                            break;

                        case "3":
                        case "5":
                        case "7":
                        case "-2":
                        case "-4":
                        case "-6":
                        case "6":
                        case "8":
                        case "9":
                        case "4":
                            if (historyContract != "") {
                                access = true
                            }else {
                                access = false
                            }
                            break;
                        case '10': 
                            access = true
                            break;
                    }
                    break;
                case 'TPKD':
                    switch(statusID){
                        case "1":
                            if (roleName == nameRoleContract) {
                                access = false
                            }else {
                                access = false
                            }
                            break;
                        case "2":
                            access = false
                            break;
                        case "-2":
                            access = false
                            break;
                        case "5":
                        case "7":
                        case "-4":
                        case "-6":
                        case "6":
                        case "8":
                        case "9":
                            if(historyContract != ''){
                                access = true
                            }else{
                                access = false
                            }
                            break;
                        case "10":
                            access = true
                            break;
                        case "3":
                            access = false
                            break;
                        case "-1":
                        case "4": 
                            access = false
                            break;
                    }
                    break;
                case 'KTT':
					switch(statusID){
						case "4": 
							access = false
							break;
						case "1": 
							if (roleName == nameUserContract) {
								access = false
							}else {
								if(nameRoleContract == "BTGD"){
									access = false
								}else{
									access = false
								}
							}
							break;
						case "5":
							access = false
							break;
						case "7":
						case "9":
							if (historyContract != "") {
								access = false // false if page Render_Button_Invoice
							}else {
								access = false // false if page Render_Button_Invoice
							}
							break;
						case "-4":
						case "-6":
						case "8":
							if (historyContract != "") {
								access = false
							}else {
								if(statusID == -4){
									access = false
								}else if(statusID == -6){
									access = false
								}else{
									access = false // false if page Render_Button_Invoice
								}
							}
							break;
						case "-1":
						case "2":
						case "3":
							access = false
							break;
						case "10":
							access = true
							break;
						case "-2":
						case "6":
						case "7":
							access = false
							break;
						}
                    break;
                case 'BGD':
                    switch(statusID){
						case "4":
						case "6":
							access = false
							break;
						case "1": 
							if (roleName == nameRoleContract) {
								access = false
							}else {
								access = false
							}
							break;
						case "7":
							access = false
							break;
						case "8":
							access = false
							break;
						case "-1":
						case "2":
						case "-2":
						case "5":
						case "3":
						case "4":
							access = false
							break;
						case "10":
							access = true
							break;
						case "-6":
							access = false
							break;
						case "9":
							if(historyContract != ''){
									access = true
								}else{
									access = false
								}
							break;
						default: 
							if (statusID < 9) {
								access = false
							}else {
								access = false
							}
							break;
					}
                    break;
                case 'BTGD':
                case 'Admin':
                    switch(statusID){
                        case "1": 
                            if (roleName == nameRoleContract) {
                                access = false
                            }else {
                                access = false
                            }
                            break;
                        case "10":
                            access = true
                            break;
                        default: 
                            access = false
                            break;
                    }
                    break;
            }

            console.debug(roleName, "checkUsersAccessContact:::return access:::", access)
            return access
        }

        function checkEditOrViewDetail(history, roleName, UserID, statusID, filteredHistoryChangesCapTrenEdit, userContract, usersarray, rolearray) {
			let userContractAccess = (typeof userContract[0].user != "undefined" && typeof userContract[0].user != "string") ? userContract[0].user.roles[0] : (typeof userContract[0].user_details != "undefined" && typeof userContract[0].user_details == "object") ? userContract[0].user_details[0].roles[0] : ""
            let nameUserContract = FindArrayTPKD(usersarray, userContract[0].user, rolearray);
            let roleUserContract = userContractAccess
            let nameRoleContract = FindArrayRoleBTGD(Roles,roleUserContract).name;

			let access = "none"
			let historyContract = '';
			if(filteredHistoryChangesCapTrenEdit != null){
				if(filteredHistoryChangesCapTrenEdit.length > 0){
					historyContract = filteredHistoryChangesCapTrenEdit.filter(x => x.contract === params.id && x.form != 'contract');
				}
			}

			switch(roleName){
				case 'AM':			
					switch(statusID){
						case "1":
						case "-1":
							access = "edit"
							break;

						case "2": 
							access = "view"
							break;

						case "3":
						case "5":
						case "7":
						case "-2":
						case "-4":
						case "-6":
						case "6":
						case "8":
						case "9":
						case "4":
							if (historyContract != "") {
								access = "none"
							}else {
								access = "view"
							}
							break;
						case "10": 
							access = "none"
							break;
					}
					break;
				case 'TPKD':
					switch(statusID){
						case "1":
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
						case "2":
							access = "edit"
							break;
						case "-2":
							access = "edit"
							break;
						case "5":
						case "7":
						case "-4":
						case "-6":
						case "6":
						case "8":
						case "9":
							if(historyContract != ''){
								access = "none"
							}else{
								access = "view"
							}
							break;
						case "10":
							access = "none"
							break;
						case "3":
							access = "edit"
							break;
						case "-1":
						case "4": 
							access = "view"
							break;
					}
					break;
				case 'KTT':
					switch(statusID){
						case "4": 
							access = "edit"
							break;
						case "1": 
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								if(nameRoleContract == "BTGD"){
									access = "edit"
								}else{
									access = "view"
								}
							}
							break;
						case "5":
							access = "edit"
							break;
						case "7":
						case "9":
							if (historyContract != "") {
								access = "view" // false if page Render_Button_Invoice
							}else {
								access = "view" // false if page Render_Button_Invoice
							}
							break;
						case "-4":
						case "-6":
						case "8":
							if (historyContract != "") {
								access = "none"
							}else {
								if(statusID == -4){
									access = "edit"
								}else if(statusID == -6){
									access = "view"
								}else{
									access = "view" // false if page Render_Button_Invoice
								}
							}
							break;
						case "-1":
						case "2":
						case "3":
							access = "view"
							break;
						case "10":
							access = "none"
							break;
						case "-2":
						case "6":
						case "7":
							access = "view"
							break;
						}
					break;
				case 'BGD':
					switch(statusID){
						case "4":
						case "6":
							access = "view"
							break;
						case "1": 
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
						case "7":
							access = "view"
							break;
						case "8":
							access = "view"
							break;
						case "-1":
						case "2":
						case "-2":
						case "5":
						case "3":
						case "4":
							access = "view"
							break;
						case "10":
							access = "none"
							break;
						case "-6":
							access = "edit"
							break;
						case "9":
							if(historyContract != ''){
									access = "none"
								}else{
									access = "view"
								}
							break;
						default: 
							if (statusID < 9) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
					}
					break;
				case 'BTGD':
				case 'Admin':
					switch(statusID){
						case "1": 
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
						case "10":
							access = "none"
							break;
						default: 
							access = "view"
							break;
					}
					break;
			}

			console.debug(roleName, "checkEditOrView:::return access:::", access)
			return access
		}

		function FindArrayTPKD(array, ID, Roles) {
			let returnObject = "";
			if (ID != undefined) returnObject = array.find(x => x._id === ID._id);
			if(returnObject != undefined){
				return returnObject.role_details[0].name;
			}else{
				return '';
			}
		}

		function FindArrayRoleBTGD(array, ID) {
			let returnObject = "";
			if (ID != undefined) returnObject = array.find(x => x._id === ID);
			if(returnObject != undefined){
				return returnObject;
			}else{
				return '';
			}
		}

		function FindArrayUserManagers(array, ID) {
			let returnObject = "";
			if (ID != undefined) returnObject = array.filter(x => x.user_id[0] === ID);
			if(returnObject != undefined){
				return returnObject;
			}else{
				return '';
			}
		}

        function FindArrayUserCapCaoHon(UserID , array_user, roleName) {
			let returnObject = array_user.find(x => x._id == UserID);
			let rs = []
			let filteredArray = [];

			switch(roleName){
				case 'AM':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
						  	}
						});
						let manage_UserId = FindArrayUserManagers(UserManagers,UserID);
						if(manage_UserId.length > 0){
							let manager_id_new = manage_UserId[0]['manager_id'];
							let managerObject = array_user.find(x => x._id == manager_id_new);
							if(managerObject != undefined){
								filteredArray.push(managerObject);
							}
						}
					}
					break;
				case 'TPKD':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
						  	}else{
						  		return item.role_details[0].name == 'BTGD'
						  	}
						});
					}
					// console.log('2')
					break;
				case 'KTT':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD' && item.role_details[0].name != 'KTT';
						  	} else {
						  		return item.role_details[0].name == 'BTGD'
						  	}
						});
					}
					break;
				case 'BGD':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
						  	return item.role_details[0].name == 'BTGD';
						});
					}
					// console.log('3')
					break;
				case 'BTGD':
				case 'Admin':
					filteredArray = array_user
					break;
			}

			if (filteredArray.length > 0) {
				return filteredArray.map(item => item._id);
			}

			return filteredArray
		}

		//////// new update 2024-03-16
		let filteredHistoryChangesCapTrenEdit = historyChanges.filter(x => x.contract == params.id && x.form != 'contract' && x.requester != UserID)
		let StatusID = get_status(Historys,params.id) != '' ? get_status(Historys,params.id).StatusID : '0'
		let userContact = Contracts.filter(x => x._id == params.id)
		let kiemTraChoPhepTruyCap = checkUsersAccessContact(Historys, roleName, UserID, StatusID, filteredHistoryChangesCapTrenEdit, userContact, Users, Roles)
		let checkEditOrView = checkEditOrViewDetail(Historys, roleName, UserID, StatusID, filteredHistoryChangesCapTrenEdit, userContact, Users, Roles)

		const contract_me_access = FindArray(newContract, params.id)
		const contract_me_new_access = FindArray(Contracts, params.id)
		// console.debug("newContract", roleName, newContract, newContracts, ContractsLoading)
		// console.debug("contract_me_access", contract_me_access == "", get_user_id(UserID,UserManagers))
		// console.debug("contract_me_new_access", contract_me_new_access == "", get_user_id(UserID,UserManagers))

		//loc cha, loc con
		let user_access = get_user_id(UserID,UserManagers)
		if (roleName != "AM") user_access.push(UserID)
		const userNew = (_id == "65676f9ffaea227259381017") ? Users : findUsers(Users, roleName, UserID); //  || 63c0938fed60f11521b00875
		const uniqueUserNew = (userNew.length > 0 ) ? [...new Set(userNew.map(obj => obj._id))] : []
		let newUserManagers = []
		if (roleName == "AM" || roleName == "TPKD") {
			newUserManagers = user_access
		}else if (roleName == "KTT" || roleName == "BGD") {
			if (roleName == "KTT") {
				let listReceiverCaoHon = FindArrayUserCapCaoHon(UserID, Users, roleName)
				let newUserManagers1 = (user_access.length > 0 ) ? user_access.concat(uniqueUserNew) : (uniqueUserNew.length > 0 ) ? user_access.concat(uniqueUserNew) : []
				newUserManagers = (newUserManagers1.length > 0 ) ? newUserManagers1.concat(listReceiverCaoHon) : (listReceiverCaoHon.length > 0 ) ? listReceiverCaoHon.concat(newUserManagers1) : []
				// console.debug(1111111, newUserManagers) //  || 63c0938fed60f11521b00875)
			}else {
				newUserManagers = (user_access.length > 0 ) ? user_access.concat(uniqueUserNew) : (uniqueUserNew.length > 0 ) ? user_access.concat(uniqueUserNew) : []
			}
			// newUserManagers = (user_access.length > 0 ) ? user_access.concat(uniqueUserNew) : (uniqueUserNew.length > 0 ) ? user_access.concat(uniqueUserNew) : []
		}else if (roleName == "Admin" || roleName == "BTGD") {
			newUserManagers = uniqueUserNew
		}

		//
		function filter_new_contract(array, UserID) {
		    let returnObject = "";
		    // if (UserID != undefined) returnObject = array.filter((element) => element.user.centers != null ? element.user.centers[0] : '' == UserID[0]);
		    if (UserID != undefined) returnObject = array.filter((element) => element.CenterID == UserID[0]);
		    if (returnObject != undefined) {
		        return returnObject;
		    } else {
		        return "";
		    }
		}

		function filter_Users(array, UserID) {
		    let returnObject = "";
		    if (UserID != undefined) returnObject = array.filter((element) => element._id === UserID);
		    let lastElement = returnObject.pop();
		    if (lastElement != undefined) {
		        return lastElement;
		    } else {
		        return "";
		    }
		}

		function filter_history(array, ContractID) {
		    let returnObject = "";
		    if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		    let lastElement = returnObject.pop();
		    if (lastElement != undefined) {
		        return lastElement;
		    } else {
		        return "";
		    }
		}

		function get_user_id(userLogin, userManager) {
		    let managedUsers = [];

		    function findManagedUsers(managerId) {
		        let users = userManager.filter((item) => item.manager_id[0] === managerId);
		        if (users.length > 0) {
		            for (let user of users) {
		                findManagedUsers(user.user_id[0]);
		            }
		        } else {
		            managedUsers.push(managerId);
		        }
		    }

		    findManagedUsers(userLogin);
		    return managedUsers;
		}

		function findUsers(array, roleName, idUser) {
			let usersBelongCenter = []
			let filterListUsers = FindArray(array, _id)
			switch (roleName) {
				case "Admin":
				case "BTGD":
					if (filterListUsers != undefined) {
						usersBelongCenter = array
					}
					break;
					// if (filterListUsers != undefined) {
					// 	usersBelongCenter = filter_new_user(array, null, null, idUser, ["BGD", "KTT", "TPKD", "AM"])
					// }
					// break;
				case "BGD":
					if (filterListUsers != undefined) {
						usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["KTT", "TPKD", "AM"])
					}
					break;
				case "KTT":
					if (filterListUsers != undefined) {
						usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["TPKD", "AM"])
					}
					break;
				case "TPKD":
					if (filterListUsers != undefined) {
						usersBelongCenter = filter_new_user(array, filterListUsers.centers, filterListUsers.departments, idUser, ["AM"])
					}
					break;
			}
			// console.log("usersBelongCenter", usersBelongCenter)
			return usersBelongCenter
		}

		function filter_new_user(array, centers_id, department_id = null, idUser, listRole = null){
			let returnObject = [];
			if (centers_id !== null) {
				array.map(element => {
					if (element.centers) {
						if (element.centers[0] === centers_id[0] && element._id !== idUser && listRole.includes(element.role_details[0].name)) {
							returnObject.push(element)
						}
					}
				})
			}else {
				array.map(element => {
					if (element.centers) {
						if (element._id !== idUser) {
							returnObject.push(element)
						}
					}
				})

			}
			return returnObject
		}

		//////
		///
		let check_access = contract_me_access == ""
		let check_access_new = contract_me_new_access == ""

		//view file dinh kem
		let dataUploadAuxiliaryCostsFile = "";
    	if (dataUploadNew != null) dataUploadAuxiliaryCostsFile = dataUploadNew.Upload;

		let body_file_attached = null;
        let stt_upload = 1;
        body_file_attached = (
            <>
                <Card className="text-center mx-5 my-5">
                    <Card.Header as="h4">FILE ĐÍNH KÈM</Card.Header>
                    <Card.Body>
                        <Table responsive="lg" striped bordered hover size="lg" variant="">
                            <thead>
                            	<tr>
                            		<th colSpan={4}></th>
                            		{
										roleName != 'BGD' && roleName != 'BTGD' ? 
										<>
											{
	                                    		checkEditOrView == "none" || checkEditOrView == "view" ? 
	                                    		<>
	                                    		</>
	                                    		:
	                                    		<>
	                                    			{
                                            			Invoices.length > 0 ? 
                                            			<></>
                                            			:
                                            			<>
                                            				<th>File đính kèm</th>
															<th colSpan={1}>
					                                            <div>
					                                                <a
					                                                    className="me-2 mb-2"
					                                                    onClick={() => {
					                                                        setShowImportAuxiliaryCostModal(true);
					                                                    }}
					                                                >
					                                                    <img title="Tải File Lên" src={iconPlus} width="20" height="20"></img>
					                                                </a>
					                                            </div>
					                                        </th>
                                            			</>
                                            		}
	                                    		</>
	                                    	}
										</>
										:
										<></>
									}
                            	</tr>
                                <tr style={{ textAlign: "center" }}>
                                    <th>STT</th>
                                    <th>Tên File</th>
                                    <th>Ngày Upload</th>
                                    <th>Người Upload</th>
                                    <th>Download</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataUploadAuxiliaryCostsFile.length > 0 ?
                                        dataUploadNew.Upload.map((uploadfile) => (
                                        <tr key={uploadfile._id}>
                                            <td>{stt_upload++} </td>
                                            <td>{format_string(uploadfile.Document,uploadfile.contract)}</td>
                                            <td>{uploadfile.CreateDateUpload}</td>
                                            <td>{uploadfile.UserUpload}</td>
                                            <td>
                                            	{
                                        			Invoices.length > 0 ? 
                                        			<></>
                                        			:
                                        			<>
                                        				<button
	                                                        title="Tải File Xuống"
	                                                        style={{ border: "none", background: "none", color: "blue", textDecoration: "underline" }}
	                                                        onClick={() => downLoadFile(apiUrl, uploadfile.Document, uploadfile.contract)}
	                                                    >
	                                                        {format_string(uploadfile.Document, uploadfile.contract)}
	                                                	</button>
                                        			</>
                                            	}
                                            </td>
                                            <td>
                                            	{
                                            		checkEditOrView == "none" || checkEditOrView == "view" ? 
                                            		<>
                                            		</>
                                            		:
                                            		<>
                                            			{
	                                            			Invoices.length > 0 ? 
	                                            			<></>
	                                            			:
	                                            			<>
	                                            				<OverlayTrigger
				                                                    overlay={
				                                                        <Tooltip id="tooltip">
				                                                            Xóa
				                                                        </Tooltip>
				                                                    }
				                                                >
				                                                    <Button className='post-button'>
				                                                        <img src={iconDelete} alt='delete' width='24' height='24' onClick={() => deleteFileAttached(apiUrl, uploadfile._id, uploadfile.Document)}/>
				                                                    </Button>
				                                                </OverlayTrigger>
	                                            			</>
	                                            		}
                                            		</>
                                            	}
                                            </td>
                                        </tr>
                                        ))
                                    :
                                    <></>
                                }         
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        );
		//

		if (AuxiliaryCostsLoading) {
			body = (
				<div className='spinner-container'>
					<Spinner animation='border' variant='info' />
				</div>
			)
		} else if (AuxiliaryCosts.length === 0) {
			body = (
				<>
					<div id="notfound">
						<div className="notfound">
							<h1>Oops!</h1>
							<h6>Rất tiếc, Hợp đồng không được tìm thấy trong hệ thống. Vui lòng kiểm tra lại.
								<br/>
								<Link style={{textDecoration: "none"}} to="/home">
									Trở về trang chủ
								</Link>
							</h6>
						</div>
					</div>
				</>
			)
		} else {
			let stt = 1
			let sumCostTaxA = 0;
			let sumCostTaxB = 0;
			
			let sumCostA = 0;
			let sumCostB = 0;
			
			let sumTaxA = 0;
			let sumTaxB = 0;
			
			let sumCostAB = 0;
			let sumTaxAB = 0;
			let sumCostTaxAB = 0;
			
			body = (
				<>
					<Card className='text-center mx-5 my-5'>
						<Card.Header as='h2'>CHI PHÍ VẬT TƯ PHỤ</Card.Header>
						<Card.Body>
	
							<Table striped bordered hover size="sm">
								<thead >
									<tr style={{textAlign:'left'}}>
										<th colSpan={7}>Số hợp đồng/PO: {idcontractNew}</th>
										<th colSpan={2}></th>
									</tr>
									<tr style={{textAlign:'left'}}>
										<th colSpan={9}>Khách hàng: {customer_name}</th>
									</tr>
									<tr>
										<th colSpan={8} style={{textAlign: 'left'}} align={'left'}>
											Doanh thu: {TotalOutputIntoMoney.toLocaleString()}
											</th>
										<th>
											{
												checkEditOrView == "none" || checkEditOrView == "view" ? 
												<>
												</>
												:
												<>
													<Button
														variant='primary'
														onClick={ () => { setshowadd_AuxiliaryCost_Cost_Modal(true); _setValTotalOutputIntoMoney(TotalOutputIntoMoney);}}
													>
														Thêm chi phí
													</Button>
												</>
											}
											
										</th>
									</tr>
								</thead>
								{AuxiliaryCosts.map(AuxiliaryCost => (
									<>
										<thead>
											<tr style={{backgroundColor:'#ffc61a', fontWeight:'bold', color:'#000'}}>
												<th>STT</th>
												<th>{AuxiliaryCost.Plan == 1 ? "Trong chuẩn (A)" : "Ngoài chuẩn (B)"}</th>
												<th>Phí</th>
												<th>Số Tiền</th>
												<th>Tỷ lệ</th>
												<th>Thuế</th>
												<th>Tổng</th>
												<th width = '20%'>Ghi chú</th>
												<th width = '10%'>Thao tác</th>
											</tr>
										</thead>
										<tbody>
										{
											AuxiliaryCost.ListCosts.map(function(ListCost, index) {
												var newCost = 0
												var newTax = 0
												var newCostTax = 0
												if (AuxiliaryCost.Plan == 1){
													newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
													newTax = (ListCost.TaxRate != null) ? parseInt(((newCost * ListCost.TaxRate)/100).toFixed(0)): ListCost.Tax;
													newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
												}else if (AuxiliaryCost.Plan == 2){
													newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
													newTax = (ListCost.TaxRate != null) ? parseInt(((newCost / (100 - ListCost.TaxRate)) * ListCost.TaxRate).toFixed(0)): ListCost.Tax;
													newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
												}
												
												if (AuxiliaryCost.Plan == 1){
													sumCostA += parseInt(newCost);
												}else if (AuxiliaryCost.Plan == 2){
													sumCostB += parseInt(newCost);
												}
												
												if (AuxiliaryCost.Plan == 1){
													sumTaxA += parseInt(newTax);
												}else if (AuxiliaryCost.Plan == 2){
													sumTaxB += parseInt(newTax);
												}
												
												if (AuxiliaryCost.Plan == 1){
													sumCostTaxA += parseInt((newCost+newTax));
												}else if (AuxiliaryCost.Plan == 2){
													sumCostTaxB += parseInt((newCost+newTax));
												}
												
												sumCostAB = parseInt(sumCostA + sumCostB);
												sumTaxAB = parseInt(sumTaxA + sumTaxB);
												sumCostTaxAB = parseInt(sumCostTaxA + sumCostTaxB);
												//console.log("aaaaaa", newCost, newTax, newCostTax, index)
												//sumTotal(AuxiliaryCost.ListCosts.map(ListCost => (ListCost.CostTax)), TotalOutputIntoMoney).toLocaleString()
												return (
													<>
														<tr>
															<td>{(index+1)}</td>
															<td>{ListCost.Content}</td>
															<td>{(ListCost.Fee != null) ? ListCost.Fee+"%": ""}</td>
															<td>{(newCost != null) ? newCost.toLocaleString(): ""}</td>
															<td>{(ListCost.TaxRate != null) ? ListCost.TaxRate + "%" : ""}</td>
															<td>{(newTax != null) ? newTax.toLocaleString() : ""}</td>
															<td>{newCostTax.toLocaleString()}</td>
															<td>{ListCost.Note}</td>
															<td>
																{
																	checkEditOrView == "none" || checkEditOrView == "view" ? 
																	<>
																	</>
																	:
																	<>
																		<ActionButtons_AuxiliaryCost 
																			idcontract={params.id} 
																			idPLan={AuxiliaryCost._id}
																			idCost={ListCost._id}
																			PLan={AuxiliaryCost.Plan}
																			Content={ListCost.Content}
																			Cost={newCost}
																			Note={ListCost.Note}
																			Fee={ListCost.Fee}
																			Tax={newTax}
																			TaxRate={ListCost.TaxRate}
																			CostTax={newCostTax}
																			checkBoxFee={(ListCost.Fee != null) ? true: false}
																			checkBoxCost={(ListCost.Fee != null) ? false: true}
																			checkBoxTaxRate={(ListCost.TaxRate != null) ? true: false}
																			checkBoxTax={(ListCost.TaxRate != null) ? false: true}
																			Renevue={TotalOutputIntoMoney}
																		/>
																	</>
																}
															</td>
														</tr>
													</>
												)
											})
										}
										</tbody>
										<tr>
											<td colSpan={2}> Tổng {AuxiliaryCost.Plan == 1 ? "(A)" : "(B)"}</td>
											<td></td>
											<td>{(AuxiliaryCost.Plan == 1) ? sumCostA.toLocaleString() : sumCostB.toLocaleString()}</td>
											<td></td>
											<td>{(AuxiliaryCost.Plan == 1) ? sumTaxA.toLocaleString() : sumTaxB.toLocaleString()}</td>
											<td>
												<span>{(AuxiliaryCost.Plan == 1) ? sumCostTaxA.toLocaleString() : sumCostTaxB.toLocaleString()}</span>
											</td>
											<td className={(AuxiliaryCost.Plan == 1) ? alertOverPercent30 : ""}
												title={(AuxiliaryCost.Plan == 1) ? titleOverPercent30 : ""}>
												<span>{(AuxiliaryCost.Plan == 1) ? '('+percentTrongA_QHDU+'%)': ""}</span>
											</td>
											<td></td>
										</tr>
									</>
								))}
								<tr>
									<td colSpan={2}>Tổng (A) + (B)</td>
									<td></td>
									<td>{sumCostAB.toLocaleString()}</td>
									<td></td>
									<td>{sumTaxAB.toLocaleString()}</td>
									<td>{sumCostTaxAB.toLocaleString()}</td>
									<td></td>
									<td></td>
								</tr>
							</Table>
							<a href={`/summary/${params.id}`}>
								<Button
									variant='primary'
								>
									Xem PTHĐ
								</Button>
							</a>
							<span> </span>
							<a href={`/inputform/${params.id}`}>
								<Button
									variant='primary'
								>
									Quay lại
								</Button>
							</a>
						</Card.Body>
					</Card>
	
				</>
			)
		}

		if (kiemTraChoPhepTruyCap) {
			return (
				<>
					<div id="notfound">
						<div className="notfound">
							<h1>Oops!</h1>
							<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng kiểm tra lại thông tin.
								<br/>
								<Link style={{textDecoration: "none"}} to="/home">
									Trở về trang chủ
								</Link>
							</h6>
						</div>
					</div>
				</>
			)
		}else {
			if ((check_access == true && check_access_new  == false) || (check_access == false && check_access_new  == true) || (check_access == false && check_access_new  == false)) {
				// console.debug(1, typeof contract_me_new_access.user, contract_me_new_access)
				if (typeof contract_me_new_access.user == "string") {
					// console.debug("1 thu gian: cho phep truy cap")
					if (newUserManagers.includes(contract_me_new_access.user) == true) {
						// console.debug("11 thu gian:: truy cap duoc", contract_me_new_access.user)
						return (
							<>
								{body}
								{body_file_attached}
								<Create_AuxiliaryCost_Modal />
								<Add_AuxiliaryCost_Cost_Modal />
								<Update_AuxiliaryCost_Cost_Modal />
								<ImportAuxiliaryCostModal />

								{/* <UpdateAuxiliaryCostModal /> */}
								{/* {AuxiliaryCost !== null && <UpdateAuxiliaryCostModal />} */}
								{/* After AuxiliaryCost is added, show toast */}
								<Toast
									show={show}
									style={{ position: 'fixed', top: '20%', right: '10px', zIndex: '999999'}}
									className={`bg-${type} text-white`}
									onClose={setShowToast.bind(this, {
										show: false,
										message: '',
										type: null
									})}
									delay={3000}
									autohide
								>
									<Toast.Body>
										<strong>{message}</strong>
									</Toast.Body>
								</Toast>
							</>
						)
					}else {
						// console.debug("12 thu gian:: truy cap duoc", contract_me_new_access.user)
						return (
							<>
								<div id="notfound">
									<div className="notfound">
										<h1>Oops!</h1>
										<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
											<br/>
											<Link style={{textDecoration: "none"}} to="/home">
												Trở về trang chủ
											</Link>
										</h6>
									</div>
								</div>
							</>
						)
					}
				}else if (typeof contract_me_new_access.user == "object") {
					// console.debug("2 thu gian:: khong truy cap duoc", contract_me_new_access.user._id)
					// console.debug(newUserManagers.includes(contract_me_new_access.user._id))
					if (newUserManagers.includes(contract_me_new_access.user._id) == true) {
						// console.debug("13 thu gian:: truy cap duoc", contract_me_new_access.user._id)
						return (
							<>
								{body}
								{body_file_attached}
								<Create_AuxiliaryCost_Modal />
								<Add_AuxiliaryCost_Cost_Modal />
								<Update_AuxiliaryCost_Cost_Modal />
								<ImportAuxiliaryCostModal />

								{/* <UpdateAuxiliaryCostModal /> */}
								{/* {AuxiliaryCost !== null && <UpdateAuxiliaryCostModal />} */}
								{/* After AuxiliaryCost is added, show toast */}
								<Toast
									show={show}
									style={{ position: 'fixed', top: '20%', right: '10px', zIndex: '999999'}}
									className={`bg-${type} text-white`}
									onClose={setShowToast.bind(this, {
										show: false,
										message: '',
										type: null
									})}
									delay={3000}
									autohide
								>
									<Toast.Body>
										<strong>{message}</strong>
									</Toast.Body>
								</Toast>
							</>
						)
					}else {
						// console.debug("14 thu gian:: truy cap duoc", contract_me_new_access.user._id)
						return (
							<>
								<div id="notfound">
									<div className="notfound">
										<h1>Oops!</h1>
										<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
											<br/>
											<Link style={{textDecoration: "none"}} to="/home">
												Trở về trang chủ
											</Link>
										</h6>
									</div>
								</div>
							</>
						)
					}
				}
			}else {
				return (
					<>
						<div id="notfound">
							<div className="notfound">
								<h1>Oops!</h1>
								<h6>Rất tiếc, Hợp đồng không được tìm thấy trong hệ thống. Vui lòng kiểm tra lại.
									<br/>
									<Link style={{textDecoration: "none"}} to="/home">
										Trở về trang chủ
									</Link>
								</h6>
							</div>
						</div>
					</>
				)
			}
		}
	}
}

//View So HD , CPgross , Plan , Note
//View
export const AuxiliaryCost_Contract = () => {
	const params = useParams()
	// Contexts
	/* const {
		authState: {
			user: { username }
		}
	} = useContext(AuthContext) */

	const {
		AuxiliaryCostState: { AuxiliaryCosts, Contracts, AuxiliaryCostsLoading },
		getAuxiliaryCosts,
		getContract_All
	} = useContext(AuxiliaryCostContext)
	useEffect(() => getAuxiliaryCosts(), [])
	console.log("AuxiliaryCosts======>>>>>", AuxiliaryCosts)
	// hàm đổi giá trị chi phí % => giá trị tiền tuyệt đối
	function changeCost(value, revenue) {
		let kq = value;
		if (value < 1)
			kq = value * revenue;
		return kq
	}
	//Ham tinh tong phuong an Có chuyển đổi giá trị < 1
	function sumTotal(mang, heso) {
		let sum = 0;
		mang.map(function (value) {
			sum += changeCost(value, heso);
		});
		return sum;
	}
	function SumListCost(Auxiliary) {
		let kq = 0;
		Auxiliary.map(AuxiliaryCost =>
			AuxiliaryCost.ListCosts.map(ListCost => (
				kq += ListCost.Cost
			)))

		return kq;
	}
	function FindPlan(Auxiliary) {
		let kq = 0;
		Auxiliary.map(AuxiliaryCost => kq = AuxiliaryCost.Plan
		)

		return kq;
	}
	//Hàm trả về phần tử 
	function FindRevenue(Auxiliary) {
		let kq = 0;
		Auxiliary.map(AuxiliaryCost => kq = AuxiliaryCost.Revenue
		)

		return kq;
	}
	
	//Execute: Running
	function Find_CPXL(Totalcost, plan) {
		let CPXL = 0;
		if (plan === 1) {
			CPXL = Totalcost / 0.8 * 0.2;
		}
		else {
			CPXL = Totalcost / 0.7 * 0.25;
		}
		return CPXL
	}
	//Execute: Not use
	function FindCPGross(Auxiliary) {
		let CPXL = 0;
		let CPgross = 0;
		const phuongan = FindPlan(AuxiliaryCosts);
		let TotalCost = SumListCost(AuxiliaryCosts) * FindRevenue(AuxiliaryCosts);
		if (phuongan === 1) {
			CPXL = TotalCost / 0.8 * 0.2;
			CPgross = CPXL + TotalCost;
			console.log("Phuong an 1========", CPXL)
		}
		else {
			CPXL = TotalCost / 0.7 * 0.25;
			CPgross = CPXL + TotalCost;
		}
		return CPgross
	}

	//=== End Get productcosts with idcontract
	let body = null
	let stt = 1
	if (AuxiliaryCosts.length === 0) {
		body = (
			<>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h2'>Danh sách Chi phí Vật tư phụ</Card.Header>
					<Card.Body>
						<Card.Title>CHƯA CÓ DỮ LIỆU</Card.Title>
					</Card.Body>
				</Card>
			</>
		)
	} else {

		body = (
			<>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h2'>Danh sách Chi phí Vật tư phụ</Card.Header>
					<Card.Body>

						<Table striped bordered hover size="sm">
							<thead >
								<tr>
									<th>STT</th>
									<th>Số Hợp đồng</th>
									{/* <th>contract ID Chi phí</th> */}
									<th>Doanh thu</th>
									<th>Phương án chi</th>
									<th>Tổng chi phí</th>
									<th width='15%'>CPXL</th>
									<th width='15%'>CPgross</th>
									<th width='20%'> Thao tác </th>
								</tr>
							</thead>
							<tbody>
								{AuxiliaryCosts.map(AuxiliaryCost => (
									< tr key={AuxiliaryCost._id} >
										<td>{stt++}</td>
										<td>{AuxiliaryCost.contract.ContractID}</td>
										{/* <td>{AuxiliaryCost._id}</td> */}
										<td>{AuxiliaryCost.Renevue.toLocaleString()}</td>
										<td>{AuxiliaryCost.Plan}</td>
										<td>{sumTotal(AuxiliaryCost.ListCosts.map(ListCost => (ListCost.Cost)), AuxiliaryCost.Renevue).toLocaleString()}</td>
										<td>{Math.round(Find_CPXL(sumTotal(AuxiliaryCost.ListCosts.map(ListCost => (ListCost.Cost)), AuxiliaryCost.Renevue), AuxiliaryCost.Renevue)).toLocaleString()}</td>
										<td>{Math.round(((sumTotal(AuxiliaryCost.ListCosts.map(ListCost => (ListCost.Cost)), AuxiliaryCost.Renevue) + Find_CPXL(sumTotal(AuxiliaryCost.ListCosts.map(ListCost => (ListCost.Cost)), AuxiliaryCost.Renevue), AuxiliaryCost.Renevue)) / 10)).toLocaleString()}</td>
										<th width='25%'><a href={`/AuxiliaryCost/contract/${AuxiliaryCost.contract._id}`}>
											<Button
												variant='primary'
											>
												Xem chi tiết
											</Button>
										</a></th>
									</tr>
								))}

							</tbody>

						</Table>
					</Card.Body>
				</Card>

			</>
		)
	}

	return (
		<>
			{body}
		</>
	)
}