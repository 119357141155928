import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'

import { useContext, useState, useEffect } from 'react'
import { UserContext } from '../../contexts/UserContext'
import { RoleContext } from '../../contexts/RoleContext'
import { MenuContext } from '../../contexts/MenuContext'
import { ConfigCenterContext } from '../../contexts/ConfigCenterContext'
import { ConfigDepartmentContext } from '../../contexts/ConfigDepartmentContext'
// import { UserRoleContext} from '../../contexts/UserRoleContext'

const UpdateUserModal = ({ _userInfo }) => {
	
	// Contexts
	const {
		UserState: { User, Users},
		getUsers,
		showUpdateUserModal,
		setShowUpdateUserModal,
		updateUser,
		setShowToast
	} = useContext(UserContext)

	// State
	const [updatedUser, setUpdatedUser] = useState(User)
	useEffect(() => setUpdatedUser(User), [User])
	const {
		RoleState: { Role, Roles, RolesLoading },
		getRoles,
	} = useContext(RoleContext)

	const {
		MenuState: { Menu, Menus, MenusLoading },
		getMenu,
	} = useContext(MenuContext)

	const {
		CenterState: { Centers, CentersLoading },
		get_All_Center
	} = useContext(ConfigCenterContext)

	const {
		DepartmentState: { Departments, DepartmentsLoading },
		getDepartment
	} = useContext(ConfigDepartmentContext)

	useEffect(() => getRoles(), [])
	// useEffect(() => getUsers(), [])
	useEffect(() => getMenu(), [])
	useEffect(() => get_All_Center(), [])
	useEffect(() => getDepartment(), [])

	//state 
	const userAssign = getUserAssign(Users);
	const userAssignsss = (User.departments != null ) ? getUserAssigns(Users, User.roles[0], User.centers[0], User.departments[0]) : getUserAssigns(Users, User.roles[0], User.centers, User.departments)
	const userIsAssign = getUserIsAssign(User)
	const [state, setState] = useState({ value: userIsAssign})
	const [userAssignsssUpdate, setUserAssignsssUpdate] = useState(userAssignsss)
	useEffect(() => setState({ value: userIsAssign}), [User._id])
	useEffect(() => setUserAssignsssUpdate(userAssignsss), [User._id])
	//menu 
	const menuAssign = pushKeyAndVal(Menus);
	const menuIsAssign = getMenuIsAssign(Menus, User.page_details);
	const [stateMenu, setStateMenu] = useState({ value: menuIsAssign });
	useEffect(() => setStateMenu({ value: menuIsAssign}), [User._id])
	const departmentFillter = (Departments.length > 0 ) ? Departments.filter(x => x.TypeDepartment == "1") : [];
	const updatedUserDefault = updatedUser
	const centerss = updatedUser.centers
	const { username, email, name, password, roles, usermanagers, userpages, role_details, centers, center_details, departments, department_details } = updatedUser
	
	const departmentFillters = filter_department_by_center(departmentFillter, (centers != null) ? centers[0] : [])
	const updatedDepartmentDefault = departmentFillters
	const [newDepartmentSelect, setNewDepartmentSelect] = useState([]);
	const updatedDepartmentDefaults = newDepartmentSelect

	useEffect(() => setNewDepartmentSelect(departmentFillters), [updatedUser])

	const [newUserPass, setNewUserPass] = useState({
		newPassword: '',
		confirmPassword: ''
	})

	const { newPassword, confirmPassword } = newUserPass

	function filter_department_by_center(array, center_id) {
		let rs = []
		if (array.length > 0 && center_id != "") {
			array.map(function (e, i) {
				if (e.centers.length > 0 ) {
					if (e.centers[0] == center_id) rs.push(e)
				}
			})
		}

		return rs
	}

	const onChangeUpdatedUserForm = event => {
		if (event.target.name == "password") {
			setNewUserPass({ ...newUserPass, "newPassword": event.target.value})
			setUpdatedUser({ ...updatedUser, "password": ""})
		} else if (event.target.name == "centers") {
			setUpdatedUser({ ...updatedUser, centers: event.target.value })
			let departmentFillterNew = filter_department_by_center(departmentFillter, event.target.value)
			setNewDepartmentSelect(departmentFillterNew)
		}else {
			setUpdatedUser({ ...updatedUser, [event.target.name]: (event.target.name == "name") ? event.target.value : event.target.value.toLowerCase() })
		}
	}

	const closeDialog = () => {
		updatedUserDefault.centers = centerss
		setUpdatedUser(updatedUserDefault)
		setState({ value: []})
		setShowUpdateUserModal(false)
		setNewUserPass({
			newPassword: '',
			confirmPassword: ''
		})
	}

	const onSubmit = async event => {
		event.preventDefault()

		if (stateMenu.value.length == 0) {
			setShowToast({ show: true, message: "Vui lòng cấu hình 1 menu", type: 'danger' })
			return false;
		}

		if (updatedUser.centers != "649bb33b5f819d033f8535a0") {
			let nameRole = FindArray(Roles, updatedUser.roleusers).name;
			if(nameRole == "KTT" || nameRole == "BGD" || nameRole == "BTGD") {
			}else {
				if (updatedUser.departments == "") {
					setShowToast({ show: true, message: "Vui lòng chọn phòng ban", type: 'danger' })
					return false;
				}
			}
		}

		updatedUser.usermanagers = (state.value.length > 0) ? mapRoleWithUser(updatedUser.roles, state.value) : [];
		updatedUser.userpages = (stateMenu.value.length > 0) ? mapUserWithMenu(stateMenu.value) : [];
		updatedUser.newPassword = newUserPass.newPassword
		// console.log("updatedUser", updatedUser, updatedUserDefault)
		
		const { success, message } = await updateUser(updatedUser)
		setShowUpdateUserModal(false)
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
	}

	function getUserAssign(data) {
		var userAssigns = [];
		if (data.length>0) {
			data.map(function (e, i){
				if (e.manager_details.length > 0) {
					e.manager_details.map(function (em, im) {
						userAssigns.push(em.user_id[0]);
					})
				}
			})
		}
		return userAssigns;
	}

	function getUserAssigns(data, role_id, center_id, department_id){
		var customers = [];
		if (center_id != "" && department_id != "" && department_id != null) {
			
			customers = filterByRolesCentersDepartments(data, role_id, center_id, department_id)
		} else if (center_id != "" && department_id == null) {
			
			customers = filterByRolesCenters(data, role_id, center_id)
		}
		return customers;
	}

	function filterByRolesCenters(data, role_id, center_id) {
		if (typeof role_id == "undefined") return [];
		if (typeof center_id == "undefined") return [];
		var customer = [];
		data.map(function (e, i) {
			if (userAssign.length > 0 && userAssign.includes(e._id)) {
			}else {
				let nameRole = FindArray(Roles, role_id).name;
				switch (nameRole) {
					case "TPKD":
						if (e.center_details != "" && e.department_details != "") {
							if (e.role_details[0].name == "AM" && e.center_details[0]._id == center_id) customer.push({ value: e._id, label: e.username});
						}
						break;
					case "KTT":
						if (e.center_details != "") {
							if (e.role_details[0].name == "TPKD" && e.center_details[0]._id == center_id) customer.push({ value: e._id, label: e.username});
						}
						break;
					case "BGD":
						if (e.center_details != "") {
							if (e.role_details[0].name == "KTT" && e.center_details[0]._id == center_id) customer.push({ value: e._id, label: e.username});
						}
						break;
				}
			}
		})
		return customer;
	}

	function filterByRolesCentersDepartments(data, role_id, center_id, department_id) {
		if (typeof role_id == "undefined") return [];
		if (typeof center_id == "undefined") return [];
		if (typeof department_id == "undefined") return [];
		var customer = [];
		data.map(function (e, i) {
			if (userAssign.length > 0 && userAssign.includes(e._id)) {
			}else {
				let nameRole = FindArray(Roles, role_id).name;
				switch (nameRole) {
					case "TPKD":
						if (e.center_details != "" && e.department_details != "") {
							if (e.role_details[0].name == "AM" && e.center_details[0]._id == center_id && e.department_details[0]._id == department_id) customer.push({ value: e._id, label: e.username});
						}
						break;
					case "KTT":
						if (e.center_details != "" && e.department_details != "") {
							if (e.role_details[0].name == "TPKD" && e.center_details[0]._id == center_id && e.department_details[0]._id == department_id) customer.push({ value: e._id, label: e.username});
						}
						// if (e.role_details[0].name == "TPKD" ) customer.push({ value: e._id, label: e.username});
						break;
					case "BGD":
						if (e.center_details != "" && e.department_details != "") {
							if (e.role_details[0].name == "KTT" && e.center_details[0]._id == center_id && e.department_details[0]._id == department_id) customer.push({ value: e._id, label: e.username});
						}
						break;
				}
			}
		})
		return customer;
	}

	function getUserIsAssign(data){
		var userAssigns = []
		if (data.manager_details.length > 0) {
			data.manager_details.map(function (e) {
				let infoUser = Users.find(x => x._id == e.user_id[0]);
				if (infoUser != null) userAssigns.push({ value: infoUser._id, label: infoUser.username});
			})
		}
		return userAssigns;
	}

	function filterByRoles(data, role_id) {
		if (typeof role_id == "undefined") return [];
		var customer = [];
		data.map(function (e, i) {
			if (userAssign.length > 0 && userAssign.includes(e._id)) {
			}else {
				let nameRole = FindArray(Roles, role_id).name;
				switch (nameRole) {
					case "TPKD":
						if (e.role_details[0].name == "AM") customer.push({ value: e._id, label: e.username});
						break;
					case "KTT":
						if (e.role_details[0].name == "TPKD") customer.push({ value: e._id, label: e.username});
						break;
					case "BGD":
						if (e.role_details[0].name == "KTT") customer.push({ value: e._id, label: e.username});
						break;
					// case "BTGD":
					// 	if (e.role_details[0].name == "BGD") customer.push({ value: e._id, label: e.username});
					// 	break;
				}
			}
		})
		return customer;
	}

	function pushKeyAndVal(customers) {
		var customer = [];
		customers.map(function (e, i) {
			customer.push({ value: e._id, label: e.name});
		})
		return customer;
	}

	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	function getMenuIsAssign(data, page){
		if (page.length == 0 ) return [];
		var menuAssign = [];
		page.map(function (e) {
			let infoPage = data.find(x => x._id === e.page_id[0]);
			if (infoPage != undefined) menuAssign.push({value: infoPage._id, label: infoPage.name})
		})
		return menuAssign
	}

	function mapRoleWithUser(role_id, array_user) {
		var usermanagers = [];
		array_user.map(function (e, i) {
			usermanagers.push({ user_id: e.value, manager_id: role_id})
		})
		return usermanagers;
	}

	function mapUserWithMenu(array_user) {
		var userpages = [];
		array_user.map(function (e, i) {
			userpages.push({ user_id: "", page_id: e.value})
		})
		return userpages;
	}

	// user managers
	var colourOptions = userAssignsssUpdate;
	
	const styles = {
		valueContainer: (base) => ({
			...base,
			maxHeight: 50,
			overflowY: "auto"
		}),
		multiValue: (base, state) => {
			return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
		},
		multiValueLabel: (base, state) => {
			return state.data.isFixed
			? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
			: base;
			},
		multiValueRemove: (base, state) => {
			return state.data.isFixed ? { ...base, display: "none" } : base;
		}
	};

	const orderOptions = (values) => {
		return values
		.filter((v) => v.isFixed)
		.concat(values.filter((v) => !v.isFixed));
	};

	const FixedOptions = () => {
		// const [state, setState] = useState({ value: [] });
		function onChange(value, { action, removedValue }) {
			switch (action) {
				case "remove-value":
				case "pop-value":
					if (removedValue.isFixed) {
					  return;
					}
				break;
				case "clear":
					value = colourOptions.filter((v) => v.isFixed);
				break;
				default:
				break;
			}
			value = orderOptions(value);
			setState({ value: value });
		}

		return (
			<Select
				value={state.value}
				isMulti
				styles={styles}
				isClearable={state.value.some((v) => !v.isFixed)}
				name="colors"
				className="basic-multi-select"
				classNamePrefix="select"
				onChange={onChange}
				options={colourOptions}
			/>
		);
	};

	//menu
	//////menu
	var menuOptions = menuAssign;

	const stylesMenu = {
		valueContainer: (base) => ({
			...base,
			maxHeight: 50,
			overflowY: "auto"
		}),
		multiValue: (base, stateMenu) => {
			return stateMenu.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
		},
		multiValueLabel: (base, stateMenu) => {
			return stateMenu.data.isFixed
			? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
			: base;
			},
		multiValueRemove: (base, stateMenu) => {
			return stateMenu.data.isFixed ? { ...base, display: "none" } : base;
		}
	};

	const orderMenuOptions = (values) => {
		return values
		.filter((v) => v.isFixed)
		.concat(values.filter((v) => !v.isFixed));
	};

	const FixedMenuOptions = () => {

		function onChangeMenu(value, { action, removedValue }) {
			switch (action) {
				case "remove-value":
				case "pop-value":
					if (removedValue.isFixed) {
					  return;
					}
				break;
				case "clear":
					value = menuOptions.filter((v) => v.isFixed);
				break;
				default:
				break;
			}
			value = orderMenuOptions(value);
			setStateMenu({ value: value });
		}

		return (
			<Select
				value={stateMenu.value}
				isMulti
				styles={stylesMenu}
				isClearable={stateMenu.value.some((v) => !v.isFixed)}
				name="menus"
				className="basic-multi-select"
				classNamePrefix="select"
				onChange={onChangeMenu}
				options={menuOptions}
			/>
		);
	};

	return (
		<Modal show={showUpdateUserModal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>Cập nhật người dùng ?</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<Form.Group>
						<Form.Text id='username-help' muted as="h6">
							Tên đăng nhập
						</Form.Text>
						<Form.Control
							type='text'
							placeholder=''
							name='username'
							disabled='disabled'
							aria-describedby='username-help'
							value={username}
							onChange={onChangeUpdatedUserForm}
						/>						
					</Form.Group>

					<Form.Group>
						<Form.Text id='name-help' muted as="h6">
							Họ và tên
						</Form.Text>
						<Form.Control
							type='text'
							placeholder=''
							name='name'
							aria-describedby='name-help'
							value={name}
							onChange={onChangeUpdatedUserForm}
						/>						
					</Form.Group>

					<Form.Group>
						<Form.Text id='Email-help' muted  as="h6">
							Email
						</Form.Text>
						<Form.Control
							type='email'
							placeholder=''
							name='email'
							value={email} /* tạo ràn buộc số */
							onChange={onChangeUpdatedUserForm}
						/>
					</Form.Group>

					<Form.Group>
						<Form.Text id='Password-help' muted  as="h6">
							Mật khẩu
						</Form.Text>
						<Form.Control
							type='password'
							placeholder='Cập nhật mật khẩu mới'
							name='password'
							value={newPassword}
							autoComplete="new-password"
							onChange={onChangeUpdatedUserForm}
						/>
					</Form.Group>

					<Form.Group>
						<Form.Text id='roleId-help' muted as="h6">
							Vai trò
						</Form.Text>
						<Form.Control
							id='roleId-help'
							as="select"
							name='roles'
							aria-describedby='roleId-help'
							value={roles}
							disabled='disabled'
							onChange={onChangeUpdatedUserForm}>
							<option value=''>-----</option>
							{
								typeof Roles != "undefined" ? 
								Roles.map((obj => {
									return (
										<>
											<option value={obj._id}>{obj.name}</option>
										</>
									)
								}))
								:
								<></>
							}
							
					    </Form.Control>
					</Form.Group>

					{
						(role_details[0].name == "BTGD" || role_details[0].name == "Admin")
						? 
						<></>
						:
						<>
							<Form.Group>
								<Form.Text id='trungtam-help' muted as="h6">
									Đơn vị
								</Form.Text>
								<Form.Control
									id = 'trungtam-help'
									as="select"
									name='centers'
									aria-describedby='trungtam-help'
									value={centers}
									required
									onChange={onChangeUpdatedUserForm}>
									<option value=''>-----</option>
									{
										typeof Centers != "undefined" ? 
										Centers.map((obj => {
											return (
												<>
													<option value={obj._id}>{obj.CenterName}</option>
												</>
											)
										}))
										:
										<></>
									}
									
								</Form.Control>
							</Form.Group>
						</>
					}

					{
						(role_details[0].name== "KTT" || role_details[0].name == "BGD" || role_details[0].name == "BTGD" || role_details[0].name == "Admin")
						? 
						<></>
						:
						<>
							<Form.Group>
								<Form.Text id='phongban-help' muted as="h6">
									Phòng ban
								</Form.Text>
								<Form.Control
									id = 'phongban-help'
									as="select"
									name='departments'
									aria-describedby='phongban-help'
									value={departments}
									onChange={onChangeUpdatedUserForm}>
									<option value=''>-----</option>
									{
										newDepartmentSelect.length > 0 ? 
										newDepartmentSelect.map((obj => {
											return (
												<>
													<option value={obj._id}>{obj.CodeDepartment}</option>
												</>
											)
										}))
										:
										<></>
									}
									
								</Form.Control>
							</Form.Group>
						</>
					}

					{
						(role_details[0].name == "AM" || role_details[0].name == "BTGD" || role_details[0].name == "Admin" || role_details[0].name == "KTT" || role_details[0].name == "BGD")
						? 
							<>
							</>
						: 
							<>
								<Form.Group>
									<Form.Text id='roleId-help' muted as="h6">
										Phân quyền quản lý người dùng
									</Form.Text>
									<FixedOptions />
								</Form.Group>
							</>
					}

					<Form.Group>
						<Form.Text id='menuId-help' muted as="h6">
							Phân quyền quản lý menu
						</Form.Text>
						<FixedMenuOptions />
					</Form.Group>
					
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Cập nhật
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}

export default UpdateUserModal
