import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import '../App.css'
import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AuxiliaryCostContext } from '../../contexts/AuxiliaryCostContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'
import { AuthContext } from '../../contexts/AuthContext'

const Add_AuxiliaryCost_Cost_Modal = () => {
	// Contexts
	const {
		AuxiliaryCostState: { AuxiliaryCosts, AuxiliaryCostsLoading },

		add_AuxiliaryCost_Cost,
		showadd_AuxiliaryCost_Cost_Modal,
		setshowadd_AuxiliaryCost_Cost_Modal,
		setShowToast,
		valTotalOutputIntoMoney
	} = useContext(AuxiliaryCostContext);

	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	// State
	const [newAuxiliaryCost, setNewAuxiliaryCost] = useState({
		contractID:"",
		idPlan: "",
		Content: "",
		Cost: 0,
		Note: "",
		Fee: "",
		TaxRate: "",
		CostTax: 0,
		Tax: 0,
	})

	const [newCheckBox, setCheckBox] = useState({
		checkBoxFee: true,
		checkBoxCost: false,
		checkBoxTaxRate: true,
		checkBoxTax: false,
	})

	const {
		contractID,
		idPlan,
		Content,
		Cost,
		Note,
		Fee,
		TaxRate,
		CostTax,
		Tax,
	} = newAuxiliaryCost

	const {
		checkBoxFee,
		checkBoxCost,
		checkBoxTaxRate,
		checkBoxTax,
	} = newCheckBox

	//load idcontract
	const params = useParams();
	newAuxiliaryCost.contractID = params.id;
	//const ids = AuxiliaryCosts.map(object => { return object.Renevue; });
	//const Renevue = Math.max(...ids);
	const Renevue = valTotalOutputIntoMoney;
	const [validValueFee, setValidValueFee] = useState('');
	const [validValueTaxRate, setValidValueTaxRate] = useState('');
	const [valNewCost, setNewCost] = useState(0);
	const [valNewTax, setNewTax] = useState(0);



	const onChangeNewAuxiliaryCostForm = event =>{
		const name = event.target.name;
		const val = event.target.value

		if (name == "Fee") {
			if (newAuxiliaryCost.idPlan == "") {
				var message = 'Vui lòng chọn nhập chi vào Trong Chuẩn (A) hoặc Ngoài Chuẩn (B)'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				if (!isNumberOrDecimal(val) && val != '') {
					var message = 'Vui lòng nhập tỉ lệ % không quá 2 số thập phận'
					setShowToast({ show: true, message: message, type: 'danger' })
					event.target.value = validValueFee
					return false;
				}else{
					var value = (event.target.value != "") ? event.target.value : 0;
					if (parseInt(event.target.value) > 100 && parseFloat(event.target.value) > 100) {
						var message = 'Vui lòng nhập tỷ lệ không quá 100%'
						setShowToast({ show: true, message: message, type: 'danger' })
						event.target.value = validValueFee
						return false;
					}
					const newCost = ((Renevue * value)/100).toFixed(0);
					setNewCost(newCost)
					var newCostTax = parseInt(newCost) + parseInt(valNewTax);
					let newTax = 0;
					if (newAuxiliaryCost.TaxRate != "") {
						if (FindPlan(newAuxiliaryCost.idPlan) == 1) {
							newTax = ((newCost * newAuxiliaryCost.TaxRate)/100).toFixed(0);
						}else if (FindPlan(newAuxiliaryCost.idPlan) == 2) {
							newTax = ((newCost / (100 - newAuxiliaryCost.TaxRate)) * newAuxiliaryCost.TaxRate).toFixed(0);
						}
						setNewTax(newTax)
					}
					setNewAuxiliaryCost({ ...newAuxiliaryCost, 'Cost': newCost, 'Fee': event.target.value, 'Tax': newTax, 'CostTax': newCostTax})
				}
			}
			setValidValueFee(event.target.value);
		}else if (name == "Cost") {
			if (!isParentNumber(val) && val != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				setNewCost(event.target.value)
				//var newCostTax = parseInt(event.target.value) + parseInt(valNewTax);
				var newCostTax = parseInt(event.target.value) + parseInt(valNewTax);
				let newTax = valNewTax;
				if (newAuxiliaryCost.TaxRate != "") {
					if (FindPlan(newAuxiliaryCost.idPlan) == 1) {
						newTax = ((event.target.value * newAuxiliaryCost.TaxRate)/100).toFixed(0);
					}else if (FindPlan(newAuxiliaryCost.idPlan) == 2) {
						newTax = ((event.target.value / (100 - newAuxiliaryCost.TaxRate)) * newAuxiliaryCost.TaxRate).toFixed(0);
					}
					setNewTax(newTax)
				}
				setNewAuxiliaryCost({ ...newAuxiliaryCost, 'Cost': event.target.value, 'Tax': newTax, 'CostTax': newCostTax})
				//setNewAuxiliaryCost({ ...newAuxiliaryCost, [event.target.name]: event.target.value, 'CostTax': newCostTax})
			}
		}else if (name == "TaxRate") {
			if (newAuxiliaryCost.idPlan == "") {
				var message = 'Vui lòng chọn nhập chi vào Trong Chuẩn (A) hoặc Ngoài Chuẩn (B)'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				if (!isNumberOrDecimal(val) && val != '') {
					var message = 'Vui lòng nhập tỉ lệ % không quá 2 số thập phận'
					setShowToast({ show: true, message: message, type: 'danger' })
					event.target.value = validValueTaxRate
					return false;
				}else{
					let newTax = valNewTax;
					var value = (event.target.value != "") ? event.target.value : 0;
					if (parseInt(event.target.value) > 100 && parseFloat(event.target.value) > 100) {
						var message = 'Vui lòng nhập tỷ lệ không quá 100%'
						setShowToast({ show: true, message: message, type: 'danger' })
						event.target.value = validValueTaxRate
						return false;
					}
					if (FindPlan(newAuxiliaryCost.idPlan) == 1) {
						newTax = ((valNewCost * value)/100).toFixed(0);
					}else if (FindPlan(newAuxiliaryCost.idPlan) == 2) {
						newTax = ((valNewCost / (100 - value)) * value).toFixed(0);
					}
					setNewTax(newTax)
					var newCostTax = parseInt(valNewCost) + parseInt(newTax);
					setNewAuxiliaryCost({ ...newAuxiliaryCost, 'Tax': newTax, 'TaxRate': event.target.value, 'CostTax': newCostTax})
				}
			}
			setValidValueTaxRate(event.target.value);
		}else if (name == "Tax") {
			if (!isParentNumber(val) && val != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				setNewTax(event.target.value)
				var newCostTax = parseInt(valNewCost) + parseInt(event.target.value);
				setNewAuxiliaryCost({ ...newAuxiliaryCost, [event.target.name]: event.target.value, 'CostTax': newCostTax})
			}
		}else {
			setNewAuxiliaryCost({ ...newAuxiliaryCost, [event.target.name]: event.target.value })
		}
	}

	const onChangeCheckBox = event => {
		const name = event.target.name;
		const val = event.target.value
		if (name == "checkBoxFee" && val == "on") {
			const newCost = (Renevue * validValueFee)/100;
			setCheckBox({ ...newCheckBox, 'checkBoxFee': true, 'checkBoxCost': false})
			setNewAuxiliaryCost({ ...newAuxiliaryCost, 'Fee': validValueFee, 'Cost': newCost })
		}else if (name == "checkBoxCost" && val == "on") {
			setCheckBox({ ...newCheckBox, 'checkBoxFee': false, 'checkBoxCost': true, })
			setNewAuxiliaryCost({ ...newAuxiliaryCost, 'Fee': '' })
		}else if (name == "checkBoxTaxRate" && val == "on") {
			const newTax = (Renevue * validValueTaxRate)/100;
			setCheckBox({ ...newCheckBox, 'checkBoxTaxRate': true, 'checkBoxTax': false})
			setNewAuxiliaryCost({ ...newAuxiliaryCost, 'TaxRate': validValueTaxRate, 'Tax': newTax })
		}else if (name == "checkBoxTax" && val == "on") {
			setCheckBox({ ...newCheckBox, 'checkBoxTaxRate': false, 'checkBoxTax': true })
			setNewAuxiliaryCost({ ...newAuxiliaryCost, 'TaxRate': '' })
		}
	}

	const closeDialog = () => {
		resetAddAuxiliaryCostData()
	}

	const onSubmit = async event => {
		event.preventDefault()
		console.debug("submit", newAuxiliaryCost)
		const historyChangeProductCost = {
		    "requester": _id,
		    "contract": params.id,
		    "form": "auxiliary-cost",
		    "action": "create",
		    "data_change": "create new auxiliary " +newAuxiliaryCost.Content
		}
		const { success, message } = await add_AuxiliaryCost_Cost(newAuxiliaryCost, newAuxiliaryCost.contractID)//newAuxiliaryCost
		const { successHis, messageHis } = await addHistoryChange(historyChangeProductCost)
		resetAddAuxiliaryCostData()
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
	}

	const resetAddAuxiliaryCostData = () => {
		setNewAuxiliaryCost({
			contractID:"",
			idPlan: "",
			Content: "",
			Cost: "",
			Note: "",
			Fee: "",
			TaxRate: "",
			CostTax: "",
			Tax: "",
		})
		setCheckBox({
			checkBoxFee: true,
			checkBoxCost: false,
			checkBoxTaxRate: true,
			checkBoxTax: false,
		})
		setshowadd_AuxiliaryCost_Cost_Modal(false)
		setNewCost(0)
		setNewTax(0)
	}

	function isInt(n){
	    return Number(n) === n && n % 1 === 0;
	}

	function isFloat(n){
	    return Number(n) === n && n % 1 !== 0;
	}

	const isNumberOrDecimal = userInput => {
		// if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		// return /^\d+$/.test(userInput); ok
  		// return /((?:\d+\.\d*)|(?:\d*\.?\d+))$/.test(userInput); ok
  		// return /^\d*\.?\d*$/.test(userInput) //ok
  		return /^\d+(\.\d{0,2})?$/.test(userInput)
	}

	const isParentNumber = userInput => {
		if (typeof userInput !== 'string') return false;
		return /^\d+$/.test(userInput);
	}

	function FindPlan(_id) {
		let returnPlan = "";
		AuxiliaryCosts.map(function (obj) {
			if (obj._id == _id) returnPlan = obj.Plan;
		})
		return returnPlan;
	}

	return (
		<Modal show={showadd_AuxiliaryCost_Cost_Modal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>THÊM CHI PHÍ</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<Form.Group>
						<Form.Text id='ContentCost-help' muted as="h6">
							Nhập chi phí vào
						</Form.Text>
						<Form.Control
							id = 'noidung-help'
							as="select"
							name='idPlan'
							required
							aria-describedby='noidung-help'
							value={idPlan}
							onChange={onChangeNewAuxiliaryCostForm}
							>
							<option value="">--------------------------</option>
							{
								typeof AuxiliaryCosts != "undefined" ? 
								AuxiliaryCosts.map((obj => {
									return (
										<>
											<option value={obj._id}>{obj.Plan == 1 ? "Trong Chuẩn (A)" : 'Ngoài Chuẩn (B)'}</option>
										</>
									)
								}))
								:
								<></>
							}
					    </Form.Control>
					</Form.Group>

					<Form.Group>
						<Form.Text id='noidung-help' muted as="h6">
							Nội dung chi phí
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Nhập chuỗi'
							name='Content'
							required
							aria-describedby='noidung-help'
							value={Content}
							onChange={onChangeNewAuxiliaryCostForm}
						/>
					</Form.Group>

					<Form.Row>
						<Form.Group as={Col} controlId="formGridFee">
							<Form.Check
								type="radio"
								label="Phí(%)"
								name="checkBoxFee"
								id="formHorizontalRadios1"
								checked={checkBoxFee}
								onChange={onChangeCheckBox}
							/>
							<Form.Control
								tpye='text'
								placeholder='Nhập tỉ lệ %'
								name='Fee'
								value={Fee}
								min={1}
								readOnly={checkBoxCost}
								onChange={onChangeNewAuxiliaryCostForm}
							/>
						</Form.Group>

						<Form.Group as={Col} controlId="formGridCost">
							<Form.Check
								type="radio"
								label="Số tiền"
								name="checkBoxCost"
								id="formHorizontalRadios2"
								checked={checkBoxCost}
								onChange={onChangeCheckBox}
							/>
							<Form.Control
								tpye='text'
								placeholder=''
								name='Cost'
								value={Cost}
								readOnly={checkBoxFee}
								onChange={onChangeNewAuxiliaryCostForm}
							/>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} controlId="formGridTaxRate">
							<Form.Check
								type="radio"
								label="Tỷ lệ(%)"
								name="checkBoxTaxRate"
								id="formHorizontalRadios3"
								checked={checkBoxTaxRate}
								onChange={onChangeCheckBox}
							/>
							<Form.Control
								tpye='text'
								placeholder='Nhập tỷ lệ %'
								name='TaxRate'
								value={TaxRate}
								readOnly={checkBoxTax}
								onChange={onChangeNewAuxiliaryCostForm}
							/>
						</Form.Group>

						<Form.Group as={Col} controlId="formGridTax">
							<Form.Check
								type="radio"
								label="Thuế"
								name="checkBoxTax"
								id="formHorizontalRadios4"
								checked={checkBoxTax}
								onChange={onChangeCheckBox}
							/>
							<Form.Control
								tpye='text'
								placeholder=''
								name='Tax'
								value={Tax}
								readOnly={checkBoxTaxRate}
								onChange={onChangeNewAuxiliaryCostForm}
							/>
						</Form.Group>
					</Form.Row>

					<Form.Group>
						<Form.Text id='sotien-help' muted as="h6">
							Tổng
						</Form.Text>
						<Form.Control
							tpye='text'
							placeholder=''
							name='CostTax'
							value={CostTax}
							readOnly
							onChange={onChangeNewAuxiliaryCostForm}
						/>
					</Form.Group>

					<Form.Group>
						<Form.Text id='ghichu-help' muted as="h6">
							Ghi chú
						</Form.Text>
						<Form.Control
							as='textarea'
							rows={2}
							placeholder='Nhập chuỗi'
							name='Note'
							value={Note}
							onChange={onChangeNewAuxiliaryCostForm}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Thêm
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}

export default Add_AuxiliaryCost_Cost_Modal
