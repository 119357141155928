import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Select from 'react-select';

import { AuthContext } from '../../contexts/AuthContext'
import { ContractContext } from '../../contexts/ContractContext'
import { ConfigDepartmentContext } from '../../contexts/ConfigDepartmentContext'
import { ConfigCustomerContext } from '../../contexts/ConfigCustomerContext'
import { ConfigCenterContext } from '../../contexts/ConfigCenterContext'
import { UserContext } from '../../contexts/UserContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'
import { InvoiceContext } from '../../contexts/InvoiceContext'
import { RoleContext } from '../../contexts/RoleContext'
import { HistoryContext } from '../../contexts/HistoryContext'

const UpdateContractModal = () => {
	// Contexts
	const params = useParams();
	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)
	let UserID = _id;
	const {
		ContractState: { Contract, Contracts, ContractsLoading },
		showUpdateContractModal,
		setShowUpdateContractModal,
		updateContract,
		setShowToast,
		getContracts
	} = useContext(ContractContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	const {
		HistoryState: { History, Historys, HistorysLoading },
		getHistory
	} = useContext(HistoryContext)

	// State
	const [updatedContract, setUpdatedContract] = useState(Contract)
	useEffect(() => setUpdatedContract(Contract), [Contract])
	const [beforeContract, setBeforeContract] = useState(Contract)
	useEffect(() => setBeforeContract(Contract),[Contract])
	useEffect(() => getContracts(), [])

	const {
		CenterID,
        DepartmentID,
        CustomerID,
        ContractID,
		RatioUSD,
		VAT,
		Datetime
	} = updatedContract //note``

	function get_status(array,ContractID){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return '';
		}
	}

	function doesNotHaveLength(obj) {
		return !Array.isArray(obj) && obj.length === undefined;
	}

	let statusID = 0;
	let filteredHistoryChangesCapTrenEdit
	let historyContract = ''
	if (doesNotHaveLength(updatedContract)) {
		statusID = get_status(Historys, updatedContract._id).StatusID
		filteredHistoryChangesCapTrenEdit = historyChanges.filter(x => x.contract == updatedContract._id && x.form != 'contract' && x.requester != UserID)
		if(filteredHistoryChangesCapTrenEdit != null){
			if(filteredHistoryChangesCapTrenEdit.length > 0){
				historyContract = filteredHistoryChangesCapTrenEdit.filter(x => x.contract === updatedContract._id && x.form != 'contract');
			}
		}
	}

	const onChangeUpdatedContractForm = event => {
		setUpdatedContract({ ...updatedContract, [event.target.name]: event.target.value })
	}

	const closeDialog = () => {
		setUpdatedContract(Contract)
		setShowUpdateContractModal(false)
		setSelectedOption(beforeCloseSelectedValue)
	}

	const onSubmit = async event => {
		event.preventDefault()
		updatedContract.CustomerID = selectedOption;
		if(updatedContract.Datetime != ''){
			updatedContract.Date = convert_date(updatedContract.Datetime) + " " + convert_time();
		}else{
			updatedContract.Date = '';
		}
		delete updatedContract.Datetime;
		const { success, message } = await updateContract(updatedContract)

		const diff = findDiff(beforeContract, updatedContract);
		let historyChangeContract
		if (allowEdit) {
			historyChangeContract = {
				"requester": _id,
				"contract": updatedContract._id,
				"form": "contract",
				"action": "update",
				"data_change": "updateContractID " + JSON.stringify(diff)
			}
		} else {
			if(roleName == 'KTT' || roleName == 'BGD' || roleName == 'BTGD'){
				historyChangeContract = {
					"requester": _id,
					"contract": updatedContract._id,
					"form": "contract",
					"action": "update",
					"data_change": "updateContractID " + JSON.stringify(diff)
				}
			}else{
				historyChangeContract = {
					"requester": _id,
					"contract": updatedContract._id,
					"form": "contract",
					"action": "update",
					"data_change": "change " + JSON.stringify(diff)
				}

			}
		}



		const { successHis, messageHis } = await addHistoryChange(historyChangeContract)
		// console.debug({ successHis, messageHis })
		setShowUpdateContractModal(false)
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
	}

	function findDiff(obj1, obj2) {
	    const diff = {};

	    Object.keys(obj1).forEach((key) => {
	        if (obj1[key] !== obj2[key]) {
	            diff[key] = obj2[key];
	        }
	    });

	    return diff;
	}

	let [beforeCloseSelectedValue, setBeforeCloseSelectedValue] = useState(CustomerID);
	useEffect(() => setBeforeCloseSelectedValue(CustomerID), [CustomerID]);

	let [selectedValue, setSelectedValue] = useState('');
	const {
		DepartmentState: { Department, Departments, DepartmentsLoading },
		getDepartment
	} = useContext(ConfigDepartmentContext)

	const {
		CustomerState: { Customer, Customers, CustomersLoading },
		get_All_Customer
	} = useContext(ConfigCustomerContext)

	const {
		CenterState: { Centers, CentersLoading },
		get_All_Center
	} = useContext(ConfigCenterContext)

	const {
		UserState: { User, Users, UsersLoading },
		getUsers
	} = useContext(UserContext)

	useEffect(() => getDepartment(1), [])
	useEffect(() => get_All_Customer(), [])
	useEffect(() => get_All_Center(), [])
	//
	// console.log(convert_date(Datetime), convert_time())
	//
	const customersSelect = typeof Customers != "undefined" ? pushKeyAndVal(Customers) : { value: "", label: "-----" };
	const defaultValueSelectTemp = typeof Customers != "undefined" ? setDefaultValueSelectTemp(Customers, Contract.CustomerID) : { value: "", label: "-----" };
	const [selectedOption, setSelectedOption] = useState(defaultValueSelectTemp.value);
	useEffect(() => setSelectedOption(defaultValueSelectTemp.value), [defaultValueSelectTemp.value])
	// if (typeof Contract.CustomerID == "undefined") {
	// 	useEffect(() => setSelectedOption(defaultValueSelectTemp.value), [defaultValueSelectTemp.value])
	// } else {
	// 	useEffect(() => setSelectedOption(defaultValueSelectTemp.value), [defaultValueSelectTemp.value])
	// }

	const {
		RoleState: { Role, Roles, RolesLoading },
		getRoles,
	} = useContext(RoleContext)
	useEffect(() => getRoles(), [])
	let role_new = roles[0];
	let roleName = FindArray(Roles, role_new).name;

	// let CentersNew = FindArray(Users, UserID).center_details;
	// let DepartmentsNew = FindArray(Users, UserID).department_details;

	// console.debug("aaa", updatedContract, Centers)

	let allowEdit = null
	switch (roleName) {
		case "AM":
			// if (statusID == 3 || statusID == 5 || statusID == 7 || statusID == -2 || statusID == -4 || statusID == -6 || statusID == 6 || statusID == 8 || statusID == 9 || statusID == 4) {
			if (statusID == 3 || statusID == 5 || statusID == 7 || statusID == 9) {
				allowEdit = true
			}
			break;
		case "TPKD":
			// if (statusID == 5 || statusID == 7 || statusID == -4 || statusID == -6 || statusID == 6 || statusID == 8 || statusID == 9) {
			if (statusID == 5 || statusID == 7 || statusID == 9) {
				allowEdit = true
			}
			break;
		case "KTT":
			if (statusID == 7 || statusID == 9) {
				allowEdit = true
			}
			break;
		case "BGD":
			if (statusID == 9) {
				allowEdit = true
			}
			break;
	}


	let CentersNew = '';
	let DepartmentsNew = '';
	if(roleName == 'BTGD'){
		CentersNew = Centers;
		DepartmentsNew = Departments;
	}else{
		let check = Contracts.filter(x => x._id === updatedContract._id);
		CentersNew = FindArray(Users, UserID).center_details;
		DepartmentsNew = FindArray(Users, UserID).department_details;
		if (typeof DepartmentsNew != "undefined" && DepartmentsNew.length > 0 ) {
			DepartmentsNew = DepartmentsNew
		} else {
			if (typeof check != "undefined" && check.length > 0 ) {
				let check2 = Departments.filter(x => x._id === check[0].DepartmentID);
				DepartmentsNew = check2
			}
		}
	}

	const handleTypeSelect = (e) => {
		setSelectedOption(e.value);
	};

	function FindArray(array, ID) {
		let returnObject = "";
		// console.log(array, ID,'sdsdsds')
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	function pushKeyAndVal(customers) {
		var customer = [{ value: "", label: "-----" }];
		customers.map(function (e, i) {
			customer.push({ value: e._id, label: e.CustomerName});
		})
		return customer;
	}

	function setDefaultValueSelectTemp(customers, id) {
		if (typeof id == "undefined") return { value: "", label: "-----" };
		var customer = [];
		customers.map(function (e, i) {
			if (e._id == id) customer = { value: e._id, label: e.CustomerName};
		})
		return customer;
	}

	function convert_date(date_time){
		const date = new Date(date_time); // Replace this with your actual date object
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		const seconds = ('0' + date.getSeconds()).slice(-2);
		const formattedDate = `${year}-${month}-${day}`;
		return formattedDate;
	}

	function convert_time(){
		const date = new Date(); // Replace this with your actual date object
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		const seconds = ('0' + date.getSeconds()).slice(-2);
		const formattedDate = `${hours}:${minutes}:${seconds}`;
		return formattedDate;
	}
	function filter_new_contract(array,UserID){
		let returnObject = "";
		if (UserID != undefined) returnObject = array.filter((element) => element._id == UserID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	function filter_new_contract_1(array,UserID){
		let returnObject = "";
		if (UserID != undefined) returnObject = array.filter((element) => element.contract == UserID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	const {
		InvoiceState: { Invoice, Invoices, InvoicesLoading },
		getInvoice_ContractID,
	} = useContext(InvoiceContext)
	useEffect(() => getInvoice_ContractID(updatedContract._id), [])
	// console.debug("aaaaaa", Invoices)
	// console.debug(11111111111, updatedContract._id, filter_new_contract(Contracts,updatedContract._id) ,filter_new_contract_1(Invoices,updatedContract._id), '=============');
	return (
		<Modal show={showUpdateContractModal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>Chỉnh sửa thông tin hợp đồng {Contract.ContractID}</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
			<Modal.Body>
					<Form.Group>
						<Form.Text id='ContentCost-help' muted as="h6">
							Trung tâm phụ trách hợp đồng
						</Form.Text>
						<Form.Control
							id = 'noidung-help'
							as="select"
							name='CenterID'
							aria-describedby='noidung-help'
							value={CenterID}
							onChange={onChangeUpdatedContractForm}
							disabled={ (typeof CentersNew != "undefined" && CentersNew.length == 1) ? true : null}
							>
							{
								(typeof CentersNew != "undefined" && CentersNew.length == 1) ?
								<>
									<option value={CentersNew[0]._id}>{CentersNew[0].CenterName}</option>
								</>
								: 
								<>
									<option value=''>-----</option>
									{
										typeof CentersNew != "undefined" ? 
										CentersNew.map((obj => {
											return (
												<>
													<option value={obj._id}>{obj.CenterName}</option>
												</>
											)
										}))
										:
										<></>
									}
								</>
							}
					    </Form.Control>
					</Form.Group>

					<Form.Group>
						<Form.Text id='ContentCost-help' muted as="h6">
							Phòng ban phụ trách hợp đồng 
						</Form.Text>
						<Form.Control
							id = 'noidung-help'
							as="select"
							name='DepartmentID'
							aria-describedby='noidung-help'
							value={DepartmentID}
							disabled={ (typeof DepartmentsNew != "undefined" && DepartmentsNew.length == 1) ? true : null}
							onChange={onChangeUpdatedContractForm}>
							{
								(typeof DepartmentsNew != "undefined" && DepartmentsNew.length == 1) ?
								<>
									<option value={DepartmentsNew[0]._id}>{DepartmentsNew[0].DepartmentName}</option>
								</>
								: 
								<>
									<option value=''>-----</option>
									{
										typeof DepartmentsNew != "undefined" ? 
										DepartmentsNew.map((obj => {
											return (
												<>
													<option value={obj._id}>{obj.DepartmentName}</option>
												</>
											)
										}))
										:
										<></>
									}
								</>
							}
							
					    </Form.Control>
					</Form.Group>

					<Form.Group>
						<Form.Text id='ContentCost-help' muted as="h6">
							Thông tin khách hàng
						</Form.Text>
						<Select
							isDisabled={allowEdit}
							aria-describedby='noidung-help'
							name='CustomerID'
					        options={customersSelect}
					        onChange={handleTypeSelect}
					        value={customersSelect.filter(function (option) {
					          return option.value === selectedOption;
					        })}
					        placeholder=''
					     />
					</Form.Group>

					<Form.Group>
						<Form.Text id='ContractID-help' muted  as="h6">
							Số hợp đồng/PO 
						</Form.Text>
						<Form.Control
							type='text'
							placeholder=''
							name='ContractID'
							value={ContractID}
							disabled={(filter_new_contract(Contracts,UserID) != '' && filter_new_contract_1(Invoices,UserID)) == '' ? false : true}
							onChange={onChangeUpdatedContractForm}
						/>
					</Form.Group>
					<Form.Row>
						<Form.Group as={Col} controlId="formGridTaxRate">
							<Form.Text id='RatioUSD-help' muted  as="h6">
								Tỷ giá USD
							</Form.Text>
							<Form.Control
								type='text'
								placeholder='24000'
								name='RatioUSD'
								value={RatioUSD}
								disabled={allowEdit}
								onChange={onChangeUpdatedContractForm}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId="formGridTaxRate">
							<Form.Text muted as="h6">
								VAT
							</Form.Text>
							<Form.Control
								type='text'
								placeholder='10'
								name='VAT'
								value={VAT}
								style={{'float':'left'}}
								disabled={allowEdit}
								onChange={onChangeUpdatedContractForm}
							/>
							<Form.Text muted as='h7' style={{'float':'left','marginLeft':'-20px','marginTop':'7px'}}>%</Form.Text>
						</Form.Group>
					</Form.Row>

					<Form.Group>
						<Form.Text id='Datetime-help' muted  as="h6">
						Ngày… Tháng… Năm…  của hợp đồng 
						</Form.Text>
						<Form.Control
							type='date'
							placeholder=''
							name='Datetime'
							value={convert_date(Datetime)}
							disabled={allowEdit}
							onChange={onChangeUpdatedContractForm}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Cập nhật
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}

export default UpdateContractModal
