import {
	LOADED_SUCCESS,
	LOADED_FAIL,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from '../contexts/constants'

export const CustomerReducer = (state, action) => {
	const { type, payload } = action
	switch (type) {
		case LOADED_SUCCESS:
			return {
				...state,
				Customers: payload,
				CustomersLoading: false
			}

		case LOADED_FAIL:
			return {
				...state,
				Customers: [],
				CustomersLoading: false
			}

		case ADD:
			return {
				...state,
				Customers: [...state.Customers, payload]
			}

		case DELETE:
			return {
				...state,
				Customers: state.Customers.filter(Customer => Customer._id !== payload)
			}

		case FIND:
			return { ...state, Customer: payload }

		case UPDATE:
			
			const newCustomers = state.Customers.map(Customer =>
				Customer._id === payload._id ? payload : Customer
			)

			return {
				...state,
				Customers: newCustomers
			}

		default:
			return state
	}
}
