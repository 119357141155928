import Button from 'react-bootstrap/Button'
import playIcon from '../../assets/play-btn.svg'
import editIcon from '../../assets/pencil.svg'
import deleteIcon from '../../assets/trash.svg'
import { CapitalExpenditureCostContext } from '../../contexts/CapitalExpenditureCostContext'
import { useContext } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { useHistory } from 'react-router-dom'

import { AuthContext } from '../../contexts/AuthContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'

const ActionButtons_CapitalExpenditureCost = ({ _idContract ,idContract}) => {
	
	// Contexts
	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	const { deleteCapitalExpenditureCost, findCapitalExpenditureCost, setShowUpdateCapitalExpenditureCostModal } = useContext(
		CapitalExpenditureCostContext
	) //goi ActionButtons cho component khác đc kg ???

	const chooseCapitalExpenditureCost = CapitalExpenditureCostId => {
		findCapitalExpenditureCost(CapitalExpenditureCostId)
		setShowUpdateCapitalExpenditureCostModal(true)
	}
	let history = useHistory();
	function redirec(idContract){
		let url = '/inputform/'+idContract;
		history.push(url);
		location.reload();
	}
	function submit () {
		confirmAlert({
			  title: 'Xoá chi phí vốn',
			  message: '',
			  buttons: [
				{
				  label: 'Có',
				  onClick: async () => {
				  	deleteCapitalExpenditureCost(_idContract);
				  	const historyChangeCapitalExpenditureCost = {
					    "requester": _id,
					    "contract": idContract,
					    "form": "capital-expenditure-cost",
					    "action": "delete",
					    "data_change": "delete " + JSON.stringify(_idContract)
					}
					console.log(historyChangeCapitalExpenditureCost)
					const { successHis, messageHis } = await addHistoryChange(historyChangeCapitalExpenditureCost)
					setTimeout(function () {
						redirec(idContract);
					}, 1250)
					
				  }
				},
				{
				  label: 'Không',
				  onClick: () => closeDialog()
				}
			  ]
			});
		};
	  	const closeDialog = () => {
			setShowUpdateCapitalExpenditureCostModal(false)
		}
	
	return (
		<>
			
			<Button variant="primary" onClick={chooseCapitalExpenditureCost.bind(this, _idContract)}>
				{/* <img src={editIcon} alt='edit' width='24' height='24' /> */}
				Cập nhật
			</Button>
			<span> </span>
			<Button variant="primary" onClick={submit}>
				{/* <img src={deleteIcon} alt='delete' width='24' height='24' /> */}
				Xoá
			</Button>
		</>
	)
}

export default ActionButtons_CapitalExpenditureCost
