import {
	LOADED_SUCCESS,
	LOADED_FAIL,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from '../contexts/constants'

export const NotificationReducer = (state, action) => {
	const { type, payload } = action
	switch (type) {
		case LOADED_SUCCESS:
			return {
				...state,
				Notifications: payload,
				NotificationsLoading: false
			}

		case LOADED_FAIL:
			return {
				...state,
				Notifications: [],
				NotificationsLoading: false
			}

		case ADD:
			return {
				...state,
				Notifications: [...state.Notifications, payload]
			}

		case DELETE:
			return {
				...state,
				Notifications: state.Notifications.filter(Notification => Notification._id !== payload)
			}

		case FIND:
			return { ...state, Notification: payload }

		case UPDATE:
			
			const newNotifications = state.Notifications.map(Notification =>
				Notification._id === payload._id ? payload : Notification
			)

			return {
				...state,
				Notifications: newNotifications
			}

		default:
			return state
	}
}
