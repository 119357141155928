import { createContext, useReducer, useState } from 'react'
import { InvoiceReducer } from '../reducers/InvoiceReducer'//Note
import {
	apiUrl,
	LOADED_FAIL,
	LOADED_SUCCESS,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from './constants'//Note
import axios from 'axios'

export const InvoiceContext = createContext()

const InvoiceContextProvider = ({ children }) => {
	//State
	const [InvoiceState,  dispatch] = useReducer(InvoiceReducer, {
		Invoice: [],
		Invoices: [],
		InvoicesLoading: true
	});

	const [showAddInvoiceModal, setShowAddInvoiceModal] = useState(false);
	const [showUpdateInvoiceModal, setShowUpdateInvoiceModal] = useState(false)
	const [showImportInvoiceModal, setShowImportInvoiceModal] = useState(false)
	const [Data_update, setData_update] = useState([])
	const [editInvoiveVal, seteditInvoiveVal] = useState(0)
	const [_invoice, _setInvoice] = useState(0);
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	});

	// add Center
	const addInvoice = async newInvoice => {
		try {
			const response = await axios.post(`${apiUrl}/api/forms/invoice/post/`, newInvoice)
				console.log(response,'=======')
			if (response.data.success) {
				getInvoice_ContractID(newInvoice['ContractID']);
				return response.data 
			}
			else
				return response.data //Message hop dong kg ton tai
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	// Update Invoice
	const updateInvoice = async Invoices => {
		console.log(Invoices['ContractID'],'=====================')
		try {
			const response = await axios.put(
				`${apiUrl}/api/forms/invoice/put/`, //note xem trong server
				Invoices
			)
			if (response.data.success) {
				getInvoice_ContractID(Invoices['ContractID'])
				return response.data
			}
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	const updateInvoiceNew = async Invoices => {
		try {
			const response = await axios.patch(
				`${apiUrl}/api/forms/invoice/patch/`, //note xem trong server
				Invoices
			)
			if (response.data.success) {
				getInvoice_ContractID(Invoices['ContractID'])
				return response.data
			}
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	const deleteInvoice = async Invoices => {
		try {
			try {
			const response = await axios.delete(`${apiUrl}/api/forms/invoice/delete/${Invoices.id}`)//note
			if (response.data.success){
				// dispatch({ type: DELETE, payload: response.data.deletedProducts })
				getInvoice_ContractID(Invoices.ContractID)
				return response.data
			}
		} catch (error) {
			console.log(error)
		}
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	// // import Center
	// const import_Center = async newCenter => {
	// 	try {
	// 		const response = await axios.post(`${apiUrl}/api/forms/center/post/`, newCenter)
	// 		if (response.data.success) {
	// 			return response.data 
	// 		}
	// 		else
	// 			return response.data //Message hop dong kg ton tai
	// 	} catch (error) {
	// 		return error.response.data
	// 			? error.response.data
	// 			: { success: false, message: 'Server error' }
	// 	}
	// }

	const getAllInvoice = async () => {
		try {
			const response = await axios.get(`${apiUrl}/api/forms/invoice`)
			if (response.data.success) {
				dispatch({ type: LOADED_SUCCESS, payload: response.data.Invoice })
			}
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	const getInvoice_ContractID = async (ContractID) => {
		try {
			const response = await axios.get(`${apiUrl}/api/forms/invoice/contract/${ContractID}`)
			if (response.data.success) {
				dispatch({ type: LOADED_SUCCESS, payload: response.data.Invoice })
			}
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	// User context data
	const InvoiceContextData = {
		InvoiceState,
		addInvoice,
		updateInvoice,
		deleteInvoice,
		// import_Center,
		getAllInvoice,
		getInvoice_ContractID,
		showAddInvoiceModal,
		setShowAddInvoiceModal,
		showUpdateInvoiceModal,
		setShowUpdateInvoiceModal,
		showImportInvoiceModal,
		setShowImportInvoiceModal,
		Data_update,
		setData_update,
		showToast,
		setShowToast,
		_invoice,
		_setInvoice,
		updateInvoiceNew,
		editInvoiveVal,
		seteditInvoiveVal
	}

	return (
		<InvoiceContext.Provider value={InvoiceContextData}>
			{children}
		</InvoiceContext.Provider>
	)
}

export default InvoiceContextProvider
