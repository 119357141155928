
import { useContext, useEffect, useState } from 'react'
import { useParams, useHistory, Link} from 'react-router-dom'

import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Toast from 'react-bootstrap/Toast'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

//Contexts
import { AuthContext } from '../contexts/AuthContext'
import { ContractContext } from '../contexts/ContractContext'
import { ProductCostContext } from '../contexts/ProductCostContext'
import { MandayCostContext } from '../contexts/MandayCostContext'
import { GuaranteeLetterCostContext } from '../contexts/GuaranteeLetterCostContext'
import { MiscExpenseCostContext } from '../contexts/MiscExpenseContext'
import { CapitalExpenditureCostContext } from "../contexts/CapitalExpenditureCostContext"
import { AuxiliaryCostContext } from '../contexts/AuxiliaryCostContext'
import { ImplementationCostContext } from '../contexts/ImplementationCostContext'
import { LoanInterestContext } from '../contexts/LoanInterestContext'

//Components
import { ActionButtons_Contract } from '../components/contract/ActionButtons_Contract'
import AddContractModal from '../components/contract/AddContractModal'
import UpdateContractModal from '../components/contract/UpdateContractModal'
import { UserContext } from '../contexts/UserContext'
import { RoleContext } from '../contexts/RoleContext'
import { HistoryContext } from '../contexts/HistoryContext'
import { EventContext } from '../contexts/EventContext'
import { UserManagerContext } from '../contexts/UserManagerContext'
import { HistoryChangeContext } from '../contexts/HistoryChangeContext'


//update
// import  {Two_Option_Cost_Modal}  from '../components/CapitalExpenditureCost/Two_Option_Cost_Modal'//Note
// import { TwoOptionCostContext } from '../contexts/TwoOptionCostContext'
// import { ActionButtons_Contract_copy } from '../components/contract/ActionButtons_Contract_copy'

import iconViewContract from '../assets/icon-view-contract.svg'
import iconKeyboard from '../assets/icon-keyboard.svg'
import iconBack from '../assets/icon-back.svg'
import { fa0 } from '@fortawesome/free-solid-svg-icons'
//View list các Forms cần nhập
export const InputForm = () => {
	// Contexts
	const params = useParams()
	let history = useHistory();
	const [show, setShow] = useState(false);
    const {
	    LoanInterestState: { LoanInterest, LoanInterests, LoanInterestsLoading },
	    getLoanInterest_byidContract
	} = useContext(LoanInterestContext);
	const {
	    CapitalExpenditureCostState: { CapitalExpenditureCost, CapitalExpenditureCosts, CapitalExpenditureCostsLoading },
	    getCapitalExpenditureCosts_byidContract,
	    addCapitalExpenditureCost,
	    getCapitalExpenditureCosts_byidContractNew,
	} = useContext(CapitalExpenditureCostContext);

	const {
		ContractState: { Contract, Contracts, ContractsLoading },
	} = useContext(ContractContext)

		// Contexts
	const {
		authState: {
			user: { username, _id, roles }
		}
	} = useContext(AuthContext)

	const {
		RoleState: { Role, Roles, RolesLoading },
		getRoles,
	} = useContext(RoleContext)

	useEffect(() => getRoles(), [])

	useEffect(() => getLoanInterest_byidContract(params.id), [])
	// useEffect(() => getCapitalExpenditureCosts_byidContract(params.id), []);
	useEffect(() => getCapitalExpenditureCosts_byidContract(params.id), []);

	let chiPhi = '';
	let chiPhiNoForm = false
	if(CapitalExpenditureCosts[0] != null){
		chiPhi = 'Chi phi vốn';
		chiPhiNoForm = true
		// setShow(false);
	}else{
		if(LoanInterests[0] != null){
			chiPhi = 'Chi phi lãi vay';
			chiPhiNoForm = true
			// setShow(false);
		}else{
			chiPhi = 'Chi phí';
			chiPhiNoForm = false
			// setShow(true);
		}
	}

	const handleshowModal = event => {
		// setNewProductCost({ ...newProductCost, InternalPurchase: event })
		if(chiPhi == 'Chi phí'){
			setShow(true);
		}else{
			let url = '';
			if(chiPhi == 'Chi phi vốn'){
				url = '/CapitalExpenditureCost/contract/'+params.id;
				history.push(url);
			}else{
				url = '/LoanInterest/contract/'+params.id;
				history.push(url);
			}
		}
	};

	// const {
	// 	show_Two_Option_Cost_Modal,
	// 	setshow_Two_Option_Cost_Modal
	// } = useContext(TwoOptionCostContext)
	const onSubmit = async event => {
		// event.preventDefault()
		let url = '';
		if(type == 1){
			// window.location.href = url;
			// gotonavigator("/CapitalExpenditureCost/contract/646ed821ff92e442b33932fb")
			// console.log(LoanInterests,CapitalExpenditureCosts,'==========')
			// if(LoanInterests[0] != null){
			// 	// setShowToast({ show: true, message : 'sadjsadsja', type: 'danger' })
			// 	console.log('sadsadsad');
			// }else{
				url = '/CapitalExpenditureCost/contract/'+params.id;
				history.push(url);
			// }
		}else{
			// if(CapitalExpenditureCosts[0] != null){
			// 	// setShowToast({ show: true, message : 'sadjsadsja', type: 'danger' })
			// 	console.log('eeeee');
			// }else{
				url = '/LoanInterest/contract/'+params.id;
				history.push(url);
			// }
			// history.push('google.com.vn');
			// window.location.href = '/product-cost/contract/'+${params.id};
		}
		// console.log('sdsadsadsa');
		// resetAddProductCostData()
		// setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
	}
		 // window.onbeforeunload = function() {
   //      return "Dude, are you sure you want to leave? Think of the kittens!";
   //   }
	const [typeCost, setTypeCost] = useState({
		'type' : ''
	});

	let {
		type
	} = typeCost;
	const onChangeGotoPage = event =>{
		setTypeCost({ ...typeCost, type: event.target.value })
	}

	const closeDialog = () => {
    	resetExpenseData()
	}
	const resetExpenseData = () => {
	 //    setNewLoanInterest({
	 //      Dates: '',
	 //      Explain: '',
	 //      CostCustomerPaid: '',
	 //      CostNTP: '',
	 //      OutstandingGoods: '',
	 //      NumberDate: '',
	 //      InterestRate: '',
	 //      InterestLoan: '',
	 //      Note: ''
		// }) //note cần sửa
	    setShow(false)
	}

	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	///////////////////////
	const {
		HistoryState: { History, Historys, HistorysLoading },
		getHistory
	} = useContext(HistoryContext)
	useEffect(() => getHistory(), [])

	///UserManagers
	const {
		UserManagerState: { UserManager, UserManagers, UserManagersLoading },
		getAllUserManagers
	} = useContext(UserManagerContext)
	useEffect(() => getAllUserManagers(), [])

	const {
		UserState: { User, Users, UsersLoading },
		getUsers
	} = useContext(UserContext)

	const {
		historyChangeState: { historyChange, historyChanges, historyChangesLoading },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)
	useEffect(() => getHistoryChanges(), [])

	if (ContractsLoading) {
		return (<></>)
	}else {
		let role_new = roles[0];
		let UserID = _id;
		let roleName = FindArray(Roles, role_new).name;

		let rows;
		let newContract;
		let newContract111 = [];
		if (roleName == "Admin" || roleName == "BTGD") {
		    newContract = Contracts;
		} else if (roleName == "KTT" || roleName == "BGD") {
		    let centerOfUser = filter_Users(Users, UserID).centers;
		    // console.log(centerOfUser,'====1111===')
		    if (centerOfUser != undefined) {
		        if (Contracts.length > 0) {
		            newContract111 = filter_new_contract(Contracts, centerOfUser);
		        }
		    }
		    newContract = newContract111;
		} else {
		    // console.debug(UserManagers,UserID,roleName,get_user_id(UserID, UserManagers),'sdsd')
		    let newContract1 = Contracts.filter((v) => get_user_id(UserID, UserManagers).includes(v.user._id));
		    if (roleName == "AM") {
		        newContract = newContract1;
		    } else {
		        let contract_me = Contracts.filter((v) => v.user._id == UserID);
		        let newContract2 = newContract1.concat(contract_me);
		        newContract = newContract2;
		    }
		}

		//////
		//get cha chinh sua hd
		function FindArrayHistoryCapCaoHon(UserID , array_user, roleName) {
			let returnObject = array_user.find(x => x._id == UserID);
			let rs = []
			let filteredArray = [];

			switch(roleName){
				case 'AM':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
						  	}
						});
						let manage_UserId = FindArrayUserManagers(UserManagers,UserID);
						if(manage_UserId.length > 0){
							let manager_id_new = manage_UserId[0]['manager_id'];
							let managerObject = array_user.find(x => x._id == manager_id_new);
							if(managerObject != undefined){
								filteredArray.push(managerObject);
							}
						}

						filteredArray.push(returnObject);
					}
					break;
				case 'TPKD':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM'; //&& item.role_details[0].name != 'TPKD';
						  	}else{
						  		return item.role_details[0].name == 'BTGD'
						  	}
						});
					}
					// console.log('2')
					break;
				case 'KTT':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';/// && item.role_details[0].name != 'TPKD';// && item.role_details[0].name != 'KTT';
						  	} else {
						  		return item.role_details[0].name == 'BTGD'
						  	}
						});
					}
					break;
				case 'BGD':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
						  	if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD'; // && item.role_details[0].name != 'KTT';
						  	} else {
						  		return item.role_details[0].name == 'BTGD'
						  	}
						});
					}
					// console.log('3')
					break;
				case 'BTGD':
				case 'Admin':
					filteredArray = array_user
					break;
			}

			if (filteredArray.length > 0) {
				return filteredArray.map(item => item._id);
			}

			return filteredArray
		}

		function FindArrayUserManagers(array, ID) {
			let returnObject = "";
			if (ID != undefined) returnObject = array.filter(x => x.user_id[0] === ID);
			if(returnObject != undefined){
				return returnObject;
			}else{
				return '';
			}
		}

		function checkUsersAccessContact(history, roleName, UserID, statusID, filteredHistoryChangesCapTrenEdit, userContract, usersarray, rolearray) {
			let userContractAccess = (typeof userContract[0].user != "undefined" && typeof userContract[0].user != "string") ? userContract[0].user.roles[0] : (typeof userContract[0].user_details != "undefined" && typeof userContract[0].user_details == "object") ? userContract[0].user_details[0].roles[0] : ""
            let nameUserContract = FindArrayTPKD(usersarray, userContract[0].user, rolearray);
            let roleUserContract = userContractAccess
            let nameRoleContract = FindArrayRoleBTGD(Roles,roleUserContract).name;

			let access = false
			let historyContract = '';
			if(filteredHistoryChangesCapTrenEdit != null){
				if(filteredHistoryChangesCapTrenEdit.length > 0){
					historyContract = filteredHistoryChangesCapTrenEdit.filter(x => x.contract === params.id && x.form != 'contract');
				}
			}

			switch(roleName){
				case 'AM':			
					switch(statusID){
						case "1":
						case "-1":
							access = false
							break;

						case "2": 
							access = false
							break;

						case "3":
						case "5":
						case "7":
						case "-2":
						case "-4":
						case "-6":
						case "6":
						case "8":
						case "9":
						case "4":
							if (historyContract != "") {
								access = true
							}else {
								access = false
							}
							break;
						case "10": 
							access = true
							break;
					}
					break;
				case 'TPKD':
					switch(statusID){
						case "1":
							if (roleName == nameUserContract) {
								access = false
							}else {
								access = false
							}
							break;
						case "2":
							access = false
							break;
						case "-2":
							access = false
							break;
						case "5":
						case "7":
						case "-4":
						case "-6":
						case "6":
						case "8":
						case "9":
							if(historyContract != ''){
								access = true
							}else{
								access = false
							}
							break;
						case "10":
							access = true
							break;
						case "3":
							access = false
							break;
						case "-1":
						case "4": 
							access = false
							break;
					}
					break;
				case 'KTT':
					switch(statusID){
						case "4": 
							access = false
							break;
						case "1": 
							if (roleName == nameUserContract) {
								access = false
							}else {
								if(nameRoleContract == "BTGD"){
									access = false
								}else{
									access = false
								}
							}
							break;
						case "5":
							access = false
							break;
						case "7":
						case "9":
							if (historyContract != "") {
								access = false // false if page Render_Button_Invoice
							}else {
								access = false // false if page Render_Button_Invoice
							}
							break;
						case "-4":
						case "-6":
						case "8":
							if (historyContract != "") {
								access = false
							}else {
								if(statusID == -4){
									access = false
								}else if(statusID == -6){
									access = false
								}else{
									access = false // false if page Render_Button_Invoice
								}
							}
							break;
						case "-1":
						case "2":
						case "3":
							access = false
							break;
						case "10":
							access = true
							break;
						case "-2":
						case "6":
						case "7":
							access = false
							break;
						}
					break;
				case 'BGD':
					switch(statusID){
						case "4":
						case "6":
							access = false
							break;
						case "1": 
							if (roleName == nameUserContract) {
								access = false
							}else {
								access = false
							}
							break;
						case "7":
							access = false
							break;
						case "8":
							access = false
							break;
						case "-1":
						case "2":
						case "-2":
						case "5":
						case "3":
						case "4":
							access = false
							break;
						case "10":
							access = true
							break;
						case "-6":
							access = false
							break;
						case "9":
							if(historyContract != ''){
									access = true
								}else{
									access = false
								}
							break;
						default: 
							if (statusID < 9) {
								access = false
							}else {
								access = false
							}
							break;
					}
					break;
				case 'BTGD':
				case 'Admin':
					switch(statusID){
						case "1": 
							if (roleName == nameUserContract) {
								access = false
							}else {
								access = false
							}
							break;
						case "10":
							access = true
							break;
						default: 
							access = false
							break;
					}
					break;
			}

			console.debug(roleName, "checkUsersAccessContact:::return access:::", access, userContractAccess, historyContract, userContract)
			return access
		}

		function checkEditOrViewDetail(history, roleName, UserID, statusID, filteredHistoryChangesCapTrenEdit, userContract, usersarray, rolearray) {
			let nameUserContract = FindArrayTPKD(usersarray, userContract[0].user, rolearray);
			let roleUserContract = userContract[0].user.roles[0]
			let nameRoleContract = FindArrayRoleBTGD(Roles,roleUserContract).name;

			let access = "none"
			let historyContract = '';
			if(filteredHistoryChangesCapTrenEdit != null){
				if(filteredHistoryChangesCapTrenEdit.length > 0){
					historyContract = filteredHistoryChangesCapTrenEdit.filter(x => x.contract === params.id && x.form != 'contract');
				}
			}
			// console.debug("checkEditOrViewDetail:::", roleName, statusID, historyContract)
			switch(roleName){
				case 'AM':			
					switch(statusID){
						case "1":
						case "-1":
							access = "edit"
							break;

						case "2": 
							access = "view"
							break;

						case "3":
						case "5":
						case "7":
						case "-2":
						case "-4":
						case "-6":
						case "6":
						case "8":
						case "9":
						case "4":
							if (historyContract != "") {
								access = "none"
							}else {
								access = "view"
							}
							break;
						case "10": 
							access = "none"
							break;
					}
					break;
				case 'TPKD':
					switch(statusID){
						case "1":
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
						case "2":
							access = "edit"
							break;
						case "-2":
							access = "edit"
							break;
						case "5":
						case "7":
						case "-4":
						case "-6":
						case "6":
						case "8":
						case "9":
							if(historyContract != ''){
								access = "none"
							}else{
								access = "view"
							}
							break;
						case "10":
							access = "none"
							break;
						case "3":
							access = "edit"
							break;
						case "-1":
						case "4": 
							access = "view"
							break;
					}
					break;
				case 'KTT':
					switch(statusID){
						case "4": 
							access = "edit"
							break;
						case "1": 
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								if(nameRoleContract == "BTGD"){
									access = "edit"
								}else{
									access = "view"
								}
							}
							break;
						case "5":
							access = "edit"
							break;
						case "7":
						case "9":
							if (historyContract != "") {
								access = "view" // false if page Render_Button_Invoice
							}else {
								access = "view" // false if page Render_Button_Invoice
							}
							break;
						case "-4":
						case "-6":
						case "8":
							if (historyContract != "") {
								access = "none"
							}else {
								if(statusID == -4){
									access = "edit"
								}else if(statusID == -6){
									access = "view"
								}else{
									access = "view" // false if page Render_Button_Invoice
								}
							}
							break;
						case "-1":
						case "2":
						case "3":
							access = "view"
							break;
						case "10":
							access = "none"
							break;
						case "-2":
						case "6":
						case "7":
							access = "view"
							break;
						}
					break;
				case 'BGD':
					switch(statusID){
						case "4":
						case "6":
							access = "view"
							break;
						case "1": 
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
						case "7":
							access = "view"
							break;
						case "8":
							access = "view"
							break;
						case "-1":
						case "2":
						case "-2":
						case "5":
						case "3":
						case "4":
							access = "view"
							break;
						case "10":
							access = "none"
							break;
						case "-6":
							access = "edit"
							break;
						case "9":
							if(historyContract != ''){
									access = "none"
								}else{
									access = "view"
								}
							break;
						default: 
							if (statusID < 9) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
					}
					break;
				case 'BTGD':
				case 'Admin':
					switch(statusID){
						case "1": 
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
						case "10":
							access = "none"
							break;
						default: 
							access = "view"
							break;
					}
					break;
			}

			console.debug(roleName, "checkEditOrView:::return access:::", statusID, access)
			return access
		}

		function FindArrayTPKD(array, ID, Roles) {
			let returnObject = "";
			if (ID != undefined) returnObject = array.find(x => x._id === ID._id);
			if(returnObject != undefined){
				return returnObject.role_details[0].name;
			}else{
				return '';
			}
		}

		function FindArrayRoleBTGD(array, ID) {
			let returnObject = "";
			if (ID != undefined) returnObject = array.find(x => x._id === ID);
			if(returnObject != undefined){
				return returnObject;
			}else{
				return '';
			}
		}

		function FindArrayUserCapCaoHon(UserID , array_user, roleName) {
			let returnObject = array_user.find(x => x._id == UserID);
			let rs = []
			let filteredArray = [];

			switch(roleName){
				case 'AM':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
						  	}
						});
						let manage_UserId = FindArrayUserManagers(UserManagers,UserID);
						if(manage_UserId.length > 0){
							let manager_id_new = manage_UserId[0]['manager_id'];
							let managerObject = array_user.find(x => x._id == manager_id_new);
							if(managerObject != undefined){
								filteredArray.push(managerObject);
							}
						}
					}
					break;
				case 'TPKD':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
						  	}else{
						  		return item.role_details[0].name == 'BTGD'
						  	}
						});
					}
					// console.log('2')
					break;
				case 'KTT':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD' && item.role_details[0].name != 'KTT';
						  	} else {
						  		return item.role_details[0].name == 'BTGD'
						  	}
						});
					}
					break;
				case 'BGD':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
						  	return item.role_details[0].name == 'BTGD';
						});
					}
					// console.log('3')
					break;
				case 'BTGD':
				case 'Admin':
					filteredArray = array_user
					break;
			}

			if (filteredArray.length > 0) {
				return filteredArray.map(item => item._id);
			}

			return filteredArray
		}
		// console.debug(params.id, newContract, Contracts, '=======newContract=========')

		const contract_me_access = FindArray(newContract, params.id)
		const contract_me_new_access = FindArray(Contracts, params.id)
		// console.debug("newContract", roleName, newContract, ContractsLoading, newContract.find(x => x._id == params.id))
		// console.debug("contract_me_access", contract_me_access == "", get_user_id(UserID,UserManagers))
		// console.debug("contract_me_new_access", contract_me_new_access == "", get_user_id(UserID,UserManagers))
		// console.debug(newContract,Contracts, contract_me_access, contract_me_new_access, '=======newContract=========')
		//loc cha, loc con
		let user_access = get_user_id(UserID,UserManagers)
		if (roleName != "AM") user_access.push(UserID)
		const userNew = (_id == "65676f9ffaea227259381017") ? Users : findUsers(Users, roleName, UserID); //  || 63c0938fed60f11521b00875
		const uniqueUserNew = (userNew.length > 0 ) ? [...new Set(userNew.map(obj => obj._id))] : []
		let newUserManagers = []
		if (roleName == "AM" || roleName == "TPKD") {
			newUserManagers = user_access
		}else if (roleName == "KTT" || roleName == "BGD") {
			// newUserManagers = (user_access.length > 0 ) ? user_access.concat(uniqueUserNew) : (uniqueUserNew.length > 0 ) ? user_access.concat(uniqueUserNew) : []
			if (roleName == "KTT") {
				let listReceiverCaoHon = FindArrayUserCapCaoHon(UserID, Users, roleName)
				let newUserManagers1 = (user_access.length > 0 ) ? user_access.concat(uniqueUserNew) : (uniqueUserNew.length > 0 ) ? user_access.concat(uniqueUserNew) : []
				newUserManagers = (newUserManagers1.length > 0 ) ? newUserManagers1.concat(listReceiverCaoHon) : (listReceiverCaoHon.length > 0 ) ? listReceiverCaoHon.concat(newUserManagers1) : []
				// console.debug(1111111, newUserManagers) //  || 63c0938fed60f11521b00875)
			}else {
				newUserManagers = (user_access.length > 0 ) ? user_access.concat(uniqueUserNew) : (uniqueUserNew.length > 0 ) ? user_access.concat(uniqueUserNew) : []
			}
		}else if (roleName == "Admin" || roleName == "BTGD") {
			newUserManagers = uniqueUserNew
		}
		
		// cap tren edit
		console.debug("newUserManagers:::", user_access, Users.filter(x => x._id == user_access[0]))
		let filteredHistoryChangesCapTrenEdit = historyChanges.filter(x => x.contract == params.id && x.form != 'contract' && x.requester != UserID && x.requester.includes(user_access))
		//gui len || duyet || huy
		// let filteredHistoryChanges = historyChanges.filter(x => x.contract == params.id && x.form == 'contract' && (x.action == "submit-request" || x.action == "submit-confirm" || x.action == "submit-remove" || x.action == "submit-refuse" || x.action == "remove"))
		// let mergeFilteredHistoryChanges = filteredHistoryChangesCapTrenEdit.concat(filteredHistoryChanges)

		// let requesterList = FindArrayHistoryCapCaoHon(UserID , Users, roleName)
		// let filteredHistoryChangesCapCaoHon = mergeFilteredHistoryChanges.filter(item => {
		// 	return requesterList.includes(item.requester); 
		// });


		//////// new update 2024-03-16
		let StatusID = get_status(Historys,params.id) != '' ? get_status(Historys,params.id).StatusID : '0'
		let userContact = Contracts.filter(x => x._id == params.id)
		let kiemTraChoPhepTruyCap = checkUsersAccessContact(Historys, roleName, UserID, StatusID, filteredHistoryChangesCapTrenEdit, userContact, Users, Roles)
		let checkEditOrView = checkEditOrViewDetail(Historys, roleName, UserID, StatusID, filteredHistoryChangesCapTrenEdit, userContact, Users, Roles)
		// console.log("kiemTraChoPhepTruyCap:::", Historys, roleName, UserID, StatusID, filteredHistoryChangesCapTrenEdit, userContact, Users, Roles)

		// console.debug("Historys", Historys, Historys.filter(x => x.contract == params.id))
		
		// console.debug("requesterList", {UserID, mergeFilteredHistoryChanges, requesterList, filteredHistoryChangesCapCaoHon})

		////////2024-03-16
		// let newuniqueArray = newUserManagers.filter(function(item, pos) {
    	// 	return newUserManagers.indexOf(item) == pos;
		// })
		// console.debug(newUserManagers, 'sdsadsadsadsa')
		//
		function filter_new_contract(array, UserID) {
		    let returnObject = "";
		    // if (UserID != undefined) returnObject = array.filter((element) => element.user.centers != null ? element.user.centers[0] : '' == UserID[0]);
		    if (UserID != undefined) returnObject = array.filter((element) => element.CenterID == UserID[0]);
		    if (returnObject != undefined) {
		        return returnObject;
		    } else {
		        return "";
		    }
		}

		function filter_Users(array, UserID) {
		    let returnObject = "";
		    if (UserID != undefined) returnObject = array.filter((element) => element._id === UserID);
		    let lastElement = returnObject.pop();
		    if (lastElement != undefined) {
		        return lastElement;
		    } else {
		        return "";
		    }
		}

		function filter_history(array, ContractID) {
		    let returnObject = "";
		    if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		    let lastElement = returnObject.pop();
		    if (lastElement != undefined) {
		        return lastElement;
		    } else {
		        return "";
		    }
		}

		function get_user_id(userLogin, userManager) {
		    let managedUsers = [];

		    function findManagedUsers(managerId) {
		        let users = userManager.filter((item) => item.manager_id[0] === managerId);
		        // console.log(users)
		        if (users.length > 0) {
		            for (let user of users) {
		                findManagedUsers(user.user_id[0]);
		            }
		        } else {
		            managedUsers.push(managerId);
		        }
		    }

		    findManagedUsers(userLogin);

		    return managedUsers;
		}

		function findUsers(array, roleName, idUser) {
			let usersBelongCenter = []
			let filterListUsers = FindArray(array, _id)
			switch (roleName) {
				case "Admin":
				case "BTGD":
					if (filterListUsers != undefined) {
						usersBelongCenter = array
					}
					break;
				// case "BTGD":
				// 	if (filterListUsers != undefined) {
				// 		usersBelongCenter = filter_new_user(array, null, null, idUser, ["BGD", "KTT", "TPKD", "AM"])
				// 	}
				// 	break;
				case "BGD":
					if (filterListUsers != undefined) {
						usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["KTT", "TPKD", "AM"])
					}
					break;
				case "KTT":
					if (filterListUsers != undefined) {
						usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["TPKD", "AM"])
					}
					break;
				case "TPKD":
					if (filterListUsers != undefined) {
						usersBelongCenter = filter_new_user(array, filterListUsers.centers, filterListUsers.departments, idUser, ["AM"])
					}
					break;
			}
			// console.log("usersBelongCenter", usersBelongCenter)
			return usersBelongCenter
		}

		function filter_new_user(array, centers_id, department_id = null, idUser, listRole = null){
			let returnObject = [];
			if (centers_id !== null) {
				array.map(element => {
					if (element.centers) {
						if (element.centers[0] === centers_id[0] && element._id !== idUser && listRole.includes(element.role_details[0].name)) {
							returnObject.push(element)
						}
					}
				})
			}else {
				array.map(element => {
					if (element.centers) {
						if (element._id !== idUser) {
							returnObject.push(element)
						}
					}
				})

			}
			return returnObject
		}

		function get_status(array,ContractID){
			let returnObject = "";
			if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
			let lastElement = returnObject.pop();
			if(lastElement != undefined){
				return lastElement;
			}else{
				return '';
			}
		}

		//////
		///
		let check_access = contract_me_access == ""
		let check_access_new = contract_me_new_access == ""

		if (kiemTraChoPhepTruyCap) {
			return (
				<>
					<div id="notfound">
						<div className="notfound">
							<h1>Oops!</h1>
							<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng kiểm tra lại thông tin.
								<br/>
								<Link style={{textDecoration: "none"}} to="/home">
									Trở về trang chủ
								</Link>
							</h6>
						</div>
					</div>
				</>
			)
		}else {
			// console.debug("StatusID", StatusID)
			if ((check_access == true && check_access_new  == false) || (check_access == false && check_access_new  == true) || (check_access == false && check_access_new  == false)) {
				// console.debug(1, typeof contract_me_new_access.user, contract_me_new_access)
				if (typeof contract_me_new_access.user == "string") {
					// console.debug("1 thu gian: cho phep truy cap")
					if (newUserManagers.includes(contract_me_new_access.user) == true) {
						// console.debug("11 thu gian:: truy cap duoc", contract_me_new_access.user)
						return (
							<>
								<Card className='text-center mx-5 my-5'>
									<Card.Header as='h2'> NHẬP LIỆU HỢP ĐỒNG</Card.Header>
									<Card.Body>
										<Table striped bordered hover size="sm">
											<thead >
												<tr>
													<th>STT</th>
													<th>Tên Form </th>
													{/* <th>ID Hợp đồng</th> */}
													<th>Thao tác</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>1</td>
													<td> Chi tiết hàng hoá</td>
													{/* <td>{params.id}</td> */}
													<td>
														{
															checkEditOrView == "none" ? 
															<>
															</>
															:
															<>
																{
																	checkEditOrView == "view" ? 
																	<>
																		<a href={`/product-cost/contract/${params.id}`}>
																		<Button
																			variant='primary'
																		>
																			Xem chi tiết
																		</Button>
																	</a>
																	</>
																	:
																	<>
																		<a href={`/product-cost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Chỉnh sửa
																			</Button>
																		</a>
																	</>
																}
															</>
														}
													</td>
												</tr>
												<tr>
													<td>2</td>
													<td> Chi phí triển khai</td>
													{/* <td>{params.id}</td> */}
													<td>
														{
															checkEditOrView == "none" ? 
															<>
															</>
															:
															<>
																{
																	checkEditOrView == "view" ? 
																	<>
																		<a href={`/implementation-cost/contract/${params.id}`}>
																		<Button
																			variant='primary'
																		>
																			Xem chi tiết
																		</Button>
																	</a>
																	</>
																	:
																	<>
																		<a href={`/implementation-cost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Chỉnh sửa
																			</Button>
																		</a>
																	</>
																}
															</>
														}
													</td>
												</tr>
												{/*{<tr>
													<td>3</td>
													<td> Chi phí vốn</td>
													<td>
														<a href={`/CapitalExpenditureCost/contract/${params.id}`}>
															<Button
																variant='primary'
															>
																Chỉnh sửa
															</Button>
														</a>
													</td>
												</tr>}*/}

												{<tr>
													<td>3</td>
													<td>{chiPhi}</td>
													<td>
													    {/* <Button onClick={handleshowModal}>
														    Chỉnh sửa
													    </Button> */}

														{
															checkEditOrView == "none" ?
															<>
															</>
															:
															<>
																{
																	chiPhiNoForm ?
																	<>
																		{
																			checkEditOrView == "view" ? 
																			<>
																				<Button onClick={handleshowModal}>
																					Xem chi tiết
																				</Button>
																			</>
																			:
																			<>
																				<Button onClick={handleshowModal}>
																					Chỉnh sửa
																				</Button>
																			</>
																		}
																	</>
																	:
																	<>
																		{
																			checkEditOrView == "edit" ? 
																			<>
																				<Button onClick={handleshowModal}>
																					Chỉnh sửa
																				</Button>
																			</>
																			:
																			<>
																				<div>Chưa chọn chi phí</div>
																			</>
																		}
																	</>
																}
															</>
														}
													    
												        <Modal show={show} onHide={() => setShow(false)}>
													        <Modal.Header closeButton>
													          	<Modal.Title>Chọn Chi Phí</Modal.Title>
													        </Modal.Header>
													        <Form onSubmit={() => onSubmit()}>
														        <Modal.Body>
														        	<Form.Group>
																		<Form.Text id='ContentCost-help' muted as="h6">
																			Chọn
																		</Form.Text>
																		<Form.Control
																			id = 'noidung-help'
																			as="select"
																			required
																			aria-describedby='noidung-help'
																			onChange={onChangeGotoPage}
																			>
																			<option value="">--------------------------</option>
																			<option value="1">Chi phí vốn</option>
																			<option value="2">Chi phí lãi vay</option>
																	    </Form.Control>
																	</Form.Group>
														        </Modal.Body>
														        <Modal.Footer>
																	<Button variant='secondary' onClick={closeDialog}>
																		Hủy
																	</Button>
																	<Button variant='primary' type='submit'>
																		Đi tới trang
																	</Button>
																</Modal.Footer>
														    </Form>
												      	</Modal>
													   
														    
													{/*<ActionButtons_Contract_copy />*/}

														{/*<Button
															variant='primary'
															// onClick={setshow_Two_Option_Cost_Modal.bind(this, true)}
															onClick={() => { setshow_Two_Option_Cost_Modal(true);}}
														>
															Chỉnh sửa
														</Button>*/}
													</td>
												</tr>}


												<tr>
													<td>4</td>
													<td> Manday kỹ sư</td>
													{/* <td>{params.id}</td> */}
													<td>
														{/* <a href={`/manday-cost/contract/${params.id}`}>
															<Button
																variant='primary'
															>
																Chỉnh sửa
															</Button>
														</a> */}
														{
															checkEditOrView == "none" ? 
															<>
															</>
															:
															<>
																{
																	checkEditOrView == "view" ? 
																	<>
																		<a href={`/manday-cost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Xem chi tiết
																			</Button>
																		</a>
																	</>
																	:
																	<>
																		<a href={`/manday-cost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Chỉnh sửa
																			</Button>
																		</a>
																	</>
																}
															</>
														}
													</td>
												</tr>
												<tr>
													<td>5</td>
													<td> Chi phí làm thư bảo lãnh</td>
													{/* <td>{params.id}</td> */}
													<td>
														{/* <a href={`/guarantee-letter-cost/contract/${params.id}`}>
															<Button
																variant='primary'
															>
																Chỉnh sửa
															</Button>
														</a> */}
														{
															checkEditOrView == "none" ? 
															<>
															</>
															:
															<>
																{
																	checkEditOrView == "view" ? 
																	<>
																		<a href={`/guarantee-letter-cost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Xem chi tiết
																			</Button>
																		</a>
																	</>
																	:
																	<>
																		<a href={`/guarantee-letter-cost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Chỉnh sửa
																			</Button>
																		</a>
																	</>
																}
															</>
														}
													</td>
												</tr>
												<tr>
													<td>6</td>
													<td> Chi phí khác</td>
													{/* <td>{params.id}</td> */}
													<td>
														{/* <a href={`/MiscExpenseCost/contract/${params.id}`}>
															<Button
																variant='primary'
															>
																Chỉnh sửa
															</Button>
														</a> */}

														{
															checkEditOrView == "none" ? 
															<>
															</>
															:
															<>
																{
																	checkEditOrView == "view" ? 
																	<>
																		<a href={`/MiscExpenseCost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Xem chi tiết
																			</Button>
																		</a>
																	</>
																	:
																	<>
																		<a href={`/MiscExpenseCost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Chỉnh sửa
																			</Button>
																		</a>
																	</>
																}
															</>
														}
													</td>
												</tr>
												<tr>
													<td>7</td>
													<td> Chi phí vật tư phụ</td>
													{/* <td>{params.id}</td> */}
													<td>
														{/* <a href={`/AuxiliaryCost/contract/${params.id}`}>
															<Button
																variant='primary'
															>
																Chỉnh sửa
															</Button>
														</a> */}

														{
															checkEditOrView == "none" ? 
															<>
															</>
															:
															<>
																{
																	checkEditOrView == "view" ? 
																	<>
																		<a href={`/AuxiliaryCost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Xem chi tiết
																			</Button>
																		</a>
																	</>
																	:
																	<>
																		<a href={`/AuxiliaryCost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Chỉnh sửa
																			</Button>
																		</a>
																	</>
																}
															</>
														}
													</td>
												</tr>
												<tr>

													<td colSpan={4}>
														{/*<a href={`/summary/${params.id}`}>
															<Button
																variant='primary'
															>
																Xem PTHĐ
															</Button>
														</a>*/}
														<OverlayTrigger
															overlay={
																<Tooltip id="tooltip">
																	Xem PTHĐ
																</Tooltip>
															}
														>
															<a href={`/summary/${params.id}`}>
																{/*<Button >
																	Xem PTHĐ
																</Button>*/}
																<img src={iconViewContract}  width="36" height="36"></img>
															</a>
														</OverlayTrigger>
														<span> </span>
														<OverlayTrigger
															overlay={
																<Tooltip id="tooltip">
																	Quay lại
																</Tooltip>
															}
														>
															<a href={`/summary`} style={{marginLeft:"20px"}}>
																{/*<Button >
																	Xem PTHĐ
																</Button>*/}
																<img src={iconBack}  width="36" height="36"></img>
															</a>
														</OverlayTrigger>
													</td>
												</tr>
											</tbody>
										</Table>
									</Card.Body>
								</Card>
							</>
						)
					}else {
						// console.debug("12 thu gian:: truy cap duoc", contract_me_new_access.user)
						return (
							<>
								<div id="notfound">
									<div className="notfound">
										<h1>Oops!</h1>
										<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng kiểm tra lại thông tin.
											<br/>
											<Link style={{textDecoration: "none"}} to="/home">
												Trở về trang chủ
											</Link>
										</h6>
									</div>
								</div>
							</>
						)
					}
				}else if (typeof contract_me_new_access.user == "object") {
					// console.debug("2 thu gian:: khong truy cap duoc", contract_me_new_access.user._id)
					// console.debug(newUserManagers.includes(contract_me_new_access.user._id))
					if (newUserManagers.includes(contract_me_new_access.user._id) == true) {
						// console.debug("13 thu gian:: truy cap duoc", contract_me_new_access.user._id)
						return (
							<>
								<Card className='text-center mx-5 my-5'>
									<Card.Header as='h2'> NHẬP LIỆU HỢP ĐỒNG</Card.Header>
									<Card.Body>
										<Table striped bordered hover size="sm">
											<thead >
												<tr>
													<th>STT</th>
													<th>Tên Form </th>
													{/* <th>ID Hợp đồng</th> */}
													<th>Thao tác</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>1</td>
													<td> Chi tiết hàng hoá</td>
													{/* <td>{params.id}</td> */}
													<td>
														{
															checkEditOrView == "none" ? 
															<>
															</>
															:
															<>
																{
																	checkEditOrView == "view" ? 
																	<>
																		<a href={`/product-cost/contract/${params.id}`}>
																		<Button
																			variant='primary'
																		>
																			Xem chi tiết
																		</Button>
																	</a>
																	</>
																	:
																	<>
																		<a href={`/product-cost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Chỉnh sửa
																			</Button>
																		</a>
																	</>
																}
															</>
														}
													</td>
												</tr>
												<tr>
													<td>2</td>
													<td> Chi phí triển khai</td>
													{/* <td>{params.id}</td> */}
													<td>
														{
															checkEditOrView == "none" ? 
															<>
															</>
															:
															<>
																{
																	checkEditOrView == "view" ? 
																	<>
																		<a href={`/implementation-cost/contract/${params.id}`}>
																		<Button
																			variant='primary'
																		>
																			Xem chi tiết
																		</Button>
																	</a>
																	</>
																	:
																	<>
																		<a href={`/implementation-cost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Chỉnh sửa
																			</Button>
																		</a>
																	</>
																}
															</>
														}
													</td>
												</tr>
												{/*{<tr>
													<td>3</td>
													<td> Chi phí vốn</td>
													<td>
														<a href={`/CapitalExpenditureCost/contract/${params.id}`}>
															<Button
																variant='primary'
															>
																Chỉnh sửa
															</Button>
														</a>
													</td>
												</tr>}*/}

												{<tr>
													<td>3</td>
													<td>{chiPhi}</td>
													<td>
													    {/* <Button onClick={handleshowModal}>
														    Chỉnh sửa
													    </Button> */}

														{
															checkEditOrView == "none" ?
															<>
															</>
															:
															<>
																{
																	chiPhiNoForm ?
																	<>
																		{
																			checkEditOrView == "view" ? 
																			<>
																				<Button onClick={handleshowModal}>
																					Xem chi tiết
																				</Button>
																			</>
																			:
																			<>
																				<Button onClick={handleshowModal}>
																					Chỉnh sửa
																				</Button>
																			</>
																		}
																	</>
																	:
																	<>
																		{
																			checkEditOrView == "edit" ? 
																			<>
																				<Button onClick={handleshowModal}>
																					Chỉnh sửa
																				</Button>
																			</>
																			:
																			<>
																			<div>Chưa chọn chi phí</div>
																			</>
																		}
																	</>
																}
															</>
														}

													    
												        <Modal show={show} onHide={() => setShow(false)}>
													        <Modal.Header closeButton>
													          	<Modal.Title>Chọn Chi Phí</Modal.Title>
													        </Modal.Header>
													        <Form onSubmit={() => onSubmit()}>
														        <Modal.Body>
														        	<Form.Group>
																		<Form.Text id='ContentCost-help' muted as="h6">
																			Chọn
																		</Form.Text>
																		<Form.Control
																			id = 'noidung-help'
																			as="select"
																			required
																			aria-describedby='noidung-help'
																			onChange={onChangeGotoPage}
																			>
																			<option value="">--------------------------</option>
																			<option value="1">Chi phí vốn</option>
																			<option value="2">Chi phí lãi vay</option>
																	    </Form.Control>
																	</Form.Group>
														        </Modal.Body>
														        <Modal.Footer>
																	<Button variant='secondary' onClick={closeDialog}>
																		Hủy
																	</Button>
																	<Button variant='primary' type='submit'>
																		Đi tới trang
																	</Button>
																</Modal.Footer>
														    </Form>
												      	</Modal>
													   
														    
													{/*<ActionButtons_Contract_copy />*/}

														{/*<Button
															variant='primary'
															// onClick={setshow_Two_Option_Cost_Modal.bind(this, true)}
															onClick={() => { setshow_Two_Option_Cost_Modal(true);}}
														>
															Chỉnh sửa
														</Button>*/}
													</td>
												</tr>}


												<tr>
													<td>4</td>
													<td> Manday kỹ sư</td>
													{/* <td>{params.id}</td> */}
													<td>
														{/* <a href={`/manday-cost/contract/${params.id}`}>
															<Button
																variant='primary'
															>
																Chỉnh sửa
															</Button>
														</a> */}
														{
															checkEditOrView == "none" ? 
															<>
															</>
															:
															<>
																{
																	checkEditOrView == "view" ? 
																	<>
																		<a href={`/manday-cost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Xem chi tiết
																			</Button>
																		</a>
																	</>
																	:
																	<>
																		<a href={`/manday-cost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Chỉnh sửa
																			</Button>
																		</a>
																	</>
																}
															</>
														}
													</td>
												</tr>
												<tr>
													<td>5</td>
													<td> Chi phí làm thư bảo lãnh</td>
													{/* <td>{params.id}</td> */}
													<td>
														{/* <a href={`/guarantee-letter-cost/contract/${params.id}`}>
															<Button
																variant='primary'
															>
																Chỉnh sửa
															</Button>
														</a> */}
														{
															checkEditOrView == "none" ? 
															<>
															</>
															:
															<>
																{
																	checkEditOrView == "view" ? 
																	<>
																		<a href={`/guarantee-letter-cost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Xem chi tiết
																			</Button>
																		</a>
																	</>
																	:
																	<>
																		<a href={`/guarantee-letter-cost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Chỉnh sửa
																			</Button>
																		</a>
																	</>
																}
															</>
														}
													</td>
												</tr>
												<tr>
													<td>6</td>
													<td> Chi phí khác</td>
													{/* <td>{params.id}</td> */}
													<td>
														{/* <a href={`/MiscExpenseCost/contract/${params.id}`}>
															<Button
																variant='primary'
															>
																Chỉnh sửa
															</Button>
														</a> */}

														{
															checkEditOrView == "none" ? 
															<>
															</>
															:
															<>
																{
																	checkEditOrView == "view" ? 
																	<>
																		<a href={`/MiscExpenseCost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Xem chi tiết
																			</Button>
																		</a>
																	</>
																	:
																	<>
																		<a href={`/MiscExpenseCost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Chỉnh sửa
																			</Button>
																		</a>
																	</>
																}
															</>
														}
													</td>
												</tr>
												<tr>
													<td>7</td>
													<td> Chi phí vật tư phụ</td>
													{/* <td>{params.id}</td> */}
													<td>
														{/* <a href={`/AuxiliaryCost/contract/${params.id}`}>
															<Button
																variant='primary'
															>
																Chỉnh sửa
															</Button>
														</a> */}

														{
															checkEditOrView == "none" ? 
															<>
															</>
															:
															<>
																{
																	checkEditOrView == "view" ? 
																	<>
																		<a href={`/AuxiliaryCost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Xem chi tiết
																			</Button>
																		</a>
																	</>
																	:
																	<>
																		<a href={`/AuxiliaryCost/contract/${params.id}`}>
																			<Button
																				variant='primary'
																			>
																				Chỉnh sửa
																			</Button>
																		</a>
																	</>
																}
															</>
														}
													</td>
												</tr>
												<tr>

													<td colSpan={4}>
														{/*<a href={`/summary/${params.id}`}>
															<Button
																variant='primary'
															>
																Xem PTHĐ
															</Button>
														</a>*/}
														<OverlayTrigger
															overlay={
																<Tooltip id="tooltip">
																	Xem PTHĐ
																</Tooltip>
															}
														>
															<a href={`/summary/${params.id}`}>
																{/*<Button >
																	Xem PTHĐ
																</Button>*/}
																<img src={iconViewContract}  width="36" height="36"></img>
															</a>
														</OverlayTrigger>
														<span> </span>
														<OverlayTrigger
															overlay={
																<Tooltip id="tooltip">
																	Quay lại
																</Tooltip>
															}
														>
															<a href={`/summary`} style={{marginLeft:"20px"}}>
																{/*<Button >
																	Xem PTHĐ
																</Button>*/}
																<img src={iconBack}  width="36" height="36"></img>
															</a>
														</OverlayTrigger>
													</td>
												</tr>
											</tbody>
										</Table>
									</Card.Body>
								</Card>
							</>
						)
					}else {
						// console.debug("14 thu gian:: truy cap duoc", contract_me_new_access.user._id)
						return (
							<>
								<div id="notfound">
									<div className="notfound">
										<h1>Oops!</h1>
										<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng kiểm tra lại thông tin.
											<br/>
											<Link style={{textDecoration: "none"}} to="/home">
												Trở về trang chủ
											</Link>
										</h6>
									</div>
								</div>
							</>
						)
					}
				}
			}else {
				return (
					<>
						<div id="notfound">
							<div className="notfound">
								<h1>Oops!</h1>
								<h6>Rất tiếc, Hợp đồng không được tìm thấy trong hệ thống. Vui lòng kiểm tra lại.
									<br/>
									<Link style={{textDecoration: "none"}} to="/home">
										Trở về trang chủ
									</Link>
								</h6>
							</div>
						</div>
					</>
				)
			}
		}

	}
}

