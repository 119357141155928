import { createContext, useReducer, useState } from 'react'
import { UserPageReducer } from '../reducers/UserPageReducer'//Note
import {
	apiUrl,
	LOADED_FAIL,
	LOADED_SUCCESS,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from './constants'//Note
import axios from 'axios'

export const UserPageContext = createContext()

const UserPageContextProvider = ({ children }) => {
	// State
	const [UserPageState, dispatch] = useReducer(UserPageReducer, {
		UserPage: null,
		UserPages: [],
		UserPagesLoading: true
	})
	
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	})

	// Get all Users
	const getAllUserPages = async () => {
		try {
			const response = await axios.get(`${apiUrl}/api/forms/userpage`)//note
			if (response.data.success) {
				dispatch({ type: LOADED_SUCCESS, payload: response.data.UserPage })//note
			}
		} catch (error) {
			dispatch({ type: LOADED_FAIL })
		}
	} 
	// User context data
	const UserPageContextData = {
		UserPageState,
		getAllUserPages
	}

	return (
		<UserPageContext.Provider value={UserPageContextData}>
			{children}
		</UserPageContext.Provider>
	)
}

export default UserPageContextProvider
