import { createContext, useReducer, useState } from 'react'
import { LoanInterestReducer } from '../reducers/LoanInterestReducer'
import { ProductCostReducer } from '../reducers/ProductCostReducer'
import {
	apiUrl,
	LOADED_FAIL,
	LOADED_SUCCESS,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from './constants'//Note
import axios from 'axios'

export const LoanInterestContext = createContext()

const LoanInterestContextProvider = ({ children }) => {
	const [LoanInterestState,  dispatch] = useReducer(LoanInterestReducer, {
		LoanInterest: null,
		LoanInterests: [],
		LoanInterestsLoading: true
	});
	const [ProductCostState, dispatchProductCost] = useReducer(ProductCostReducer, {
		ProductCost: null,
		ProductCosts: [],
		ProductCostsLoading: true
	});
	const [showAddLoanInterestModal, setShowAddLoanInterestModal] = useState(false);
	const [showUpdateLoanInterestModal, setShowUpdateLoanInterestModal] = useState(false);
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	});

	// Get all LoanInterest
	const getLoanInterest = async () => {
		try {
			const response = await axios.get(`${apiUrl}/api/forms/loan-interest`)
			if (response.data.success) {
				dispatch({ type: LOADED_SUCCESS, payload: response.data.LoanInterest })
				
			}
		} catch (error) {
			dispatch({ type: LOADED_FAIL })
		}
	}
	// Get  MandayCost by idcontract
	const getLoanInterest_byidContract = async (ContractId) => {
		try {
			const response = await axios.get(`${apiUrl}/api/forms/loan-interest/contract/${ContractId}`)
			console.log(response,'responseresponse')
			if (response.data.success) {
				dispatch({ type: LOADED_SUCCESS, payload: (response.data.LoanInterest) ? response.data.LoanInterest : []})
				
			}
		} catch (error) {
			dispatch({ type: LOADED_FAIL })
		}
	}

	const addLoanInterest = async newLoanInterest => {
		try {
			const response = await axios.post(`${apiUrl}/api/forms/loan-interest/post/`, newLoanInterest)
			if (response.data.success) {
				// dispatch({ type: ADD, payload: response.data.LoanInterest })
				getLoanInterest_byidContract(newLoanInterest['contract'])
				return response.data 
			}
			else
				return response.data //Message hop dong kg ton tai
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	const deleteLoanInterest = async LoanInterestId => {
		try {
			console.log(LoanInterestId,'LoanInterestId')
			const response = await axios.delete(`${apiUrl}/api/forms/loan-interest/delete/${LoanInterestId}`)
			if (response.data.success)
				// dispatch({ type: DELETE, payload: LoanInterestId })
				getLoanInterest_byidContract(LoanInterestId)
		} catch (error) {
			console.log(error)
		}
	}

	const findLoanInterest = LoanInterestId => {
		const LoanInterest = LoanInterestState.LoanInterests.find(LoanInterest => LoanInterest._id === LoanInterestId)
		dispatch({ type: FIND, payload: LoanInterest })
	}

	// LoanInterest context data
	const LoanInterestContextData = {
		// Two_Option_LoanInterest_Modal,//update
		// show_Two_Option_LoanInterest_Modal,
		// setshow_Two_Option_LoanInterest_Modal
		LoanInterestState,
		getLoanInterest,
		showAddLoanInterestModal,
		setShowAddLoanInterestModal,
		showUpdateLoanInterestModal,
		setShowUpdateLoanInterestModal,
		addLoanInterest,
		showToast,
		setShowToast,
		deleteLoanInterest,
		// findLoanInterest,
		// updateLoanInterest,
		getLoanInterest_byidContract,
	}

	return (
		<LoanInterestContext.Provider value={LoanInterestContextData}>
			{children}
		</LoanInterestContext.Provider>
	)
}

export default LoanInterestContextProvider
