
import Button from 'react-bootstrap/Button'
import playIcon from '../../assets/play-btn.svg'
import editIcon from '../../assets/pencil.svg'
import deleteIcon from '../../assets/trash.svg'
import { ProductCostContext } from '../../contexts/ProductCostContext'
import { useContext } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import iconEdit from '../../assets/icon-edit.svg'
import iconDelete from '../../assets/icon-delete.svg'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { AuthContext } from '../../contexts/AuthContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'

const ActionButtons_ProductCost = (_idProductCost) => {
	// Contexts
	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const { delete_ProductCost,
			Data_update,
			setData_update,
			setshowUpdateProductCostModal
	} = useContext(ProductCostContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	const chooseProductCost = _idProductCost => {
		//findProductCost(ProductCostId)
		setData_update(_idProductCost)
		console.log("Data in button", Data_update)
		setshowUpdateProductCostModal(true)
	}

	function submit () {
		confirmAlert({
		  title: 'Xoá hàng hoá',
		  message: '',
		  buttons: [
			{
			  label: 'Có',
			  onClick: async () => {
			  	delete_ProductCost(_idProductCost)
			  	const historyChangeProductCost = {
				    "requester": _id,
				    "contract": _idProductCost.contract,
				    "form": "product-cost",
				    "action": "delete",
				    "data_change": "delete " + _idProductCost.idProduct
				}
				const { successHis, messageHis } = await addHistoryChange(historyChangeProductCost)
			  }
			},
			{
			  label: 'Không',
			  onClick: () => closeDialog()
			}
		  ]
		});
	  };
	  const closeDialog = () => {
		setshowUpdateProductCostModal(false)
	}
	return (
		<>
			<OverlayTrigger
				overlay={
					<Tooltip id="tooltip">
						Cập nhật
					</Tooltip>
				}
			>
				<Button className='post-button' onClick={chooseProductCost.bind(this, _idProductCost)}>
					<img src={iconEdit} alt='edit' width='24' height='24' />
				</Button>
			</OverlayTrigger>
			<OverlayTrigger
				overlay={
					<Tooltip id="tooltip">
						Xóa
					</Tooltip>
				}
			>
				<Button className='post-button' onClick={submit}>
					<img src={iconDelete} alt='delete' width='24' height='24' />
				</Button>
			</OverlayTrigger>
		</>
	)
}

export default ActionButtons_ProductCost
