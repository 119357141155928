import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useParams } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'
import { ProductCostContext } from '../../contexts/ProductCostContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'
import { AuthContext } from '../../contexts/AuthContext'

const UpdateProductCostModal = () => {
	// Contexts
	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		ProductCostState: { ProductCost,ProductCosts },
		showUpdateProductCostModal,
		setshowUpdateProductCostModal,
		Data_update,
		update_ProductCost,
		setShowToast
	} = useContext(ProductCostContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	useEffect(() => {
	    getHistoryChanges()
	}, []);

	// console.log("historyChanges", historyChanges);

	// State
	const [updatedProductCost, setUpdatedProductCost] = useState(Data_update)
	const [beforeProductCost, setBeforeProductCost] = useState(Data_update)
	useEffect(() => setUpdatedProductCost(Data_update),[Data_update])
	useEffect(() => setBeforeProductCost(Data_update),[Data_update])
	
	const [selectedValue, setSelectedValue] = useState('');	
	const [showInput, setShowInput] = useState(false);
	var Tax_default = null;
	ProductCosts.map(function (_ListProducts) {
		_ListProducts.ListProducts.map(function (_Products) {
			if (_Products._id == updatedProductCost.idProduct) Tax_default = _Products.Tax
		})
	})
	// const [Tax, setInputValue] = useState('')
	function handleSelectChange(event) {
		const value = event.target.value;
		if(value != 'other'){
			if (!isPatternNumber(value) &&  value != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				if(event.target.name == 'FOBCost'){
					// setSelectedValue(value);
					if(Tax == '') Tax = 1;
					let StorageFOBCostNew = parseInt(value) + (parseInt(value) * (parseInt(Tax) / 100));
					if(value == '') StorageFOBCostNew = '';
					let InputPriceNew = StorageFOBCostNew * RatioUSD;
					setUpdatedProductCost({ ...updatedProductCost, 'InputPrice': InputPriceNew, 'StorageFOBCost': StorageFOBCostNew, 'FOBCost' : value})
				}else if(event.target.name == 'Tax'){
					if(FOBCost != ''){
						if(value != 'other'){
							let StorageFOBCostNew = parseInt(FOBCost) + (parseInt(FOBCost) * (parseInt(value) / 100));
					    	let InputPriceNew = StorageFOBCostNew * RatioUSD;
					    	setUpdatedProductCost({ ...updatedProductCost, 'InputPrice': InputPriceNew, 'StorageFOBCost': StorageFOBCostNew, 'Tax':value})
					    }else{
					    	let Tax = 0;
					    	let StorageFOBCostNew = parseInt(FOBCost) + (parseInt(FOBCost) * (parseInt(Tax) / 100));
					    	let InputPriceNew = StorageFOBCostNew * RatioUSD;
					    	setUpdatedProductCost({ ...updatedProductCost, 'InputPrice': InputPriceNew, 'StorageFOBCost': StorageFOBCostNew, 'Tax':Tax})
					    }
				    }else{
				    	setUpdatedProductCost({ ...updatedProductCost, 'InputPrice': 0, 'StorageFOBCost': 0, 'Tax':value})
				    }
				}else{
					if (value === 100) {
				 		setUpdatedProductCost({ ...updatedProductCost, 'Tax': value })
				    } else {
				    	setUpdatedProductCost({ ...updatedProductCost, [event.target.name]: event.target.value })
				    }
				}
			}
		}else{
			if(event.target.name == 'FOBCost'){
				// setSelectedValue(value);
				if(Tax == '') Tax = 1;
				let StorageFOBCostNew = parseInt(value) + (parseInt(value) * (parseInt(Tax) / 100));
				if(value == '') StorageFOBCostNew = '';
				let InputPriceNew = StorageFOBCostNew * RatioUSD;
				setUpdatedProductCost({ ...updatedProductCost, 'InputPrice': InputPriceNew, 'StorageFOBCost': StorageFOBCostNew, 'FOBCost' : value})
			}else if(event.target.name == 'Tax'){
				if(FOBCost != ''){
					if(value != 'other'){
						let StorageFOBCostNew = parseInt(FOBCost) + (parseInt(FOBCost) * (parseInt(value) / 100));
				    	let InputPriceNew = StorageFOBCostNew * RatioUSD;
				    	setUpdatedProductCost({ ...updatedProductCost, 'InputPrice': InputPriceNew, 'StorageFOBCost': StorageFOBCostNew, 'Tax':value})
				    }else{
				    	let Tax = 0;
				    	let StorageFOBCostNew = parseInt(FOBCost) + (parseInt(FOBCost) * (parseInt(Tax) / 100));
				    	let InputPriceNew = StorageFOBCostNew * RatioUSD;
				    	setUpdatedProductCost({ ...updatedProductCost, 'InputPrice': InputPriceNew, 'StorageFOBCost': StorageFOBCostNew, 'Tax':Tax})
				    }
			    }else{
			    	setUpdatedProductCost({ ...updatedProductCost, 'InputPrice': 0, 'StorageFOBCost': 0, 'Tax':value})
			    }
			}else{
				if (value === 100) {
			 		setUpdatedProductCost({ ...updatedProductCost, 'Tax': value })
			    } else {
			    	setUpdatedProductCost({ ...updatedProductCost, [event.target.name]: event.target.value })
			    }
			}
		}
	}

	const [validValueTax, setValidValueTax] = useState('');
	function handleInputChange(event) {
		const value = event.target.value;
		if (!isPatternNumber(value) &&  value != '') {
			var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
			setShowToast({ show: true, message: message, type: 'danger' })
			event.target.value = validValueTax;
			return false;
		}else{
			let StorageFOBCostNew = parseInt(FOBCost) + (parseInt(FOBCost) * (parseInt(value) / 100));
	    	let InputPriceNew = StorageFOBCostNew * RatioUSD;
	    	setUpdatedProductCost({ ...updatedProductCost, 'InputPrice': InputPriceNew, 'StorageFOBCost': StorageFOBCostNew, 'Tax':value})
	    	// setUpdatedProductCost({ ...updatedProductCost, [event.target.name]: event.target.value })
	    }
	    setValidValueTax(value);
	}
	// }
	const isPatternNumber = userInput => {
		if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		return /^\d+(\.\d{0,2})?$/.test(userInput);
	}

	const { 
		ProductName,
		Quantity,
		EX_W,
		FOBCost,
		RatioUSD,
		InputPrice,
		OutputPrice,
		InputIntoMoney,
		OutputIntoMoney,
		Insurance,
		InsuranceHSE,
		Note,
		contract,
		idProduct,
		Tax,
		StorageFOBCost,
		Incentive,
		PercentHSE,
		InternalPurchase,
		CheckList
		} = updatedProductCost //note
	const params = useParams();
	//Ham Load id contract
	setUpdatedProductCost.contract = params.id;
	//Checkbox nguon nhap
	const toggleEX_W = event => {
		setUpdatedProductCost({ ...updatedProductCost, EX_W: event, FOBCost:FOBCost, StorageFOBCost:StorageFOBCost, Tax: 1})	
	};

	//Ham checkbox Insurance
	const toggleInsurance = event => {
		setUpdatedProductCost({ ...updatedProductCost, Insurance: event })
	};

	const toggleInsuranceHSE = event => {
		setUpdatedProductCost({ ...updatedProductCost, InsuranceHSE: event })
	};

	const toggleInternalPurchase = event => {
		setUpdatedProductCost({ ...updatedProductCost, InternalPurchase: event })
	};

	const onChangeUpdatedProductCostForm = event =>{
		let value = event.target.value;
		if(event.target.name != 'ProductName' && event.target.name != 'Note'){
			if (!isPatternNumber(value) &&  value != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				setUpdatedProductCost({ ...updatedProductCost, [event.target.name]: event.target.value })
			}
		}else{
			setUpdatedProductCost({ ...updatedProductCost, [event.target.name]: event.target.value })
		}
	}

	const closeDialog = () => {
		setUpdatedProductCost(updatedProductCost)
		setshowUpdateProductCostModal(false)
	}

	const onSubmit = async event => {
		event.preventDefault()
		const diff = findDiff(beforeProductCost, updatedProductCost);
		const historyChangeProductCost = {
		    "requester": _id,
		    "contract": contract,
		    "form": "product-cost",
		    "action": "update",
		    "data_change": "change " + JSON.stringify(diff)
		}

		const { success, message } = await update_ProductCost(updatedProductCost)
		const { successHis, messageHis } = await addHistoryChange(historyChangeProductCost)
		setshowUpdateProductCostModal(false)
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
	}

	function findDiff(obj1, obj2) {
	    const diff = {};

	    Object.keys(obj1).forEach((key) => {
	        if (obj1[key] !== obj2[key]) {
	            diff[key] = obj2[key];
	        }
	    });

	    return diff;
	}

	const [validValuePercent, setValidValuePercent] = useState('');
	function handleSelectChangePercent(event) {
		const value = event.target.value;
		if (!isPatternNumber(value) &&  value != '') {
			var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
			setShowToast({ show: true, message: message, type: 'danger' })
			event.target.value = validValuePercent;
			return false;
		}else{
			const value = event.target.value;
			const IncentiveNew = InputIntoMoney * (value / 100);
			setUpdatedProductCost({ ...updatedProductCost, 'Incentive': IncentiveNew })
		}
	}

	function filter_Tax(Tax){
		var arr_tax = [1,2,5,10];
		if(!arr_tax.includes(parseInt(Tax))){
			return 'other';
		}else{
			if(arr_tax.includes(parseInt(Tax))){
				return Tax;
			}else{
				return 'other';
			}
		}
	}

	function show_Input(Tax, handleInputChange, EX_W){
		var arr_tax = [1,2,5,10];
		var html = ""
		if(!arr_tax.includes(parseInt(Tax))){
			html = <Form.Control
				        id='tax-input'
						type='text'	
						placeholder={Tax_default}
						name='Tax'
						onChange={handleInputChange}
						disabled={!EX_W}
						style={{'float':'right','width':'48%'}} />

		}
		else{

				html = <Form.Control
				        id='tax-input'
						type='text'	
						name='Tax'
						value={Tax}
						onChange={handleInputChange}
						disabled
						style={{'float':'right','width':'48%'}} />
			
		}
		return html;
	}

	return (
		<Modal show={showUpdateProductCostModal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>Cập nhật Hàng Hóa ?</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
			<Modal.Body>
					{/* <Form.Group>
						<Form.Text id='contract-help' muted as='h6'>
							Chọn Hợp đồng
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Contract_id'
							name='ContractID'
							required
							aria-describedby='contract-help'
							value={ContractID}
							onChange={onChangeUpdatedProductCostForm}
						/>
					</Form.Group> */}
					<Form.Group>
						<Form.Text id='tenhang-help' muted as='h6'>
							Nhập tên hàng
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Tên hàng'
							name='ProductName'
							required
							aria-describedby='tenhang-help'
							value={ProductName}
							onChange={onChangeUpdatedProductCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='soluong-help' muted as='h6'>
							Số lượng
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Số lượng'
							name='Quantity'
							required
							aria-describedby='soluong-help'
							value={Quantity}
							onChange={onChangeUpdatedProductCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='xuatxu-help' muted as='h6'>
							Nguồn nhập hàng
						</Form.Text>
						<Form.Check
								type='checkbox'
								checked={EX_W}
								value={EX_W}
								onChange={(e) => toggleEX_W(e.target.checked)}
								label={'Nhập từ nước ngoài'}
							/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='title-help' muted as='h6'>
							Đơn giá FOB
						</Form.Text>
						<Form.Control
							type='text'
							placeholder=''
							name='FOBCost'
							readOnly={!EX_W}
							value={FOBCost}
							aria-describedby='dongiaFOB-help'
							onChange={handleSelectChange}
						/>
					</Form.Group>
					<Form.Group style={{'height':'60px'}}>
						<Form.Text id='title-help' muted as='h6'>Thuế/ Phí</Form.Text>
							<select class='form-control' disabled={!EX_W} id="changeTax" onChange={handleSelectChange} name='Tax' value={filter_Tax(Tax)} style={{'float':'left','width':'48%'}}> 
								<option value ='1'>1%</option>
								<option value ='2'>2%</option>
								<option value ='5'>5%</option>
								<option value ='10'>10%</option>
								<option value = 'other'>Khác</option>
							</select>
							{show_Input(Tax, handleInputChange, EX_W)}
					</Form.Group>
					<Form.Group>
						<Form.Text id='dongiakho-help' muted as='h6'>
							Giá kho FOB
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Giá Kho FOB'
							name='StorageFOBCost'
							readOnly
							value={StorageFOBCost}
							onChange={onChangeUpdatedProductCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='dongiakho-help' muted as='h6'>
							Đơn giá kho
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Đơn giá kho'
							name='InputPrice'
							value={InputPrice}
							readOnly={EX_W}
							onChange={onChangeUpdatedProductCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='dongiaban-help' muted as='h6'>
							Đơn giá bán
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Đơn giá bán'
							name='OutputPrice'
							required
							aria-describedby='dongiaban-help'
							value={OutputPrice}
							onChange={onChangeUpdatedProductCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='dongiaban-help' muted as='h6'>
							Giá trị Incentive
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Giá trị Incentive'
							name='Incentive'
							value={Incentive}
							onChange={onChangeUpdatedProductCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Check
							type='checkbox'
							checked={Insurance}
							value={Insurance}
							onChange={(e) => toggleInsurance(e.target.checked)}
							label={'Hàng hoá có tính chi phí bảo hành kg'}
						/>
					</Form.Group>
					<Form.Group style={{'height':'60px'}}>
						<Form.Check
							type='checkbox'
							checked={InsuranceHSE}
							value={InsuranceHSE}
							onChange={(e) => toggleInsuranceHSE(e.target.checked)}
							label="Hàng hoá có hỗ trợ kĩ thuật HSE"
							style={{'marginBottom':'8px'}}
						/>
						<Form.Control
							type='text'
							placeholder='Tỉ lệ HSE'
							name='PercentHSE'
							readOnly={!InsuranceHSE}
							value={PercentHSE}
							style={{'float':'left','width':'22%'}}
							onChange={onChangeUpdatedProductCostForm}
						/>
						<Form.Text muted as='h7' style={{'float':'left','marginLeft':'-20px','marginTop':'7px'}}>%</Form.Text>
					</Form.Group>
					<Form.Group>
						<Form.Check
							type='checkbox'
							checked={InternalPurchase}
							value={InternalPurchase}
							onChange={(e) => toggleInternalPurchase(e.target.checked)}
							label={'Mua nội bộ'}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='Note-help' muted as='h6'>
							Ghi chú
						</Form.Text>
						<Form.Control
							as='textarea'
							rows={3}
							placeholder='Ghi chú'
							name='Note'
							aria-describedby='Note-help'
							value={Note}
							onChange={onChangeUpdatedProductCostForm}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Cập nhật
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}

export default UpdateProductCostModal
