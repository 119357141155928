import { useContext, useState, useEffect } from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useParams } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Toast from 'react-bootstrap/Toast'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Select from 'react-select';

import { AuthContext } from '../contexts/AuthContext'
import { RemoveContractContext } from '../contexts/RemoveContractContext'
import { ContractContext } from '../contexts/ContractContext'
import RemoveContractModal from '../components/RemoveContract/RemoveContractModal'

import AddDepartmentModal from '../components/Config/Department/AddDepartmentModal'//Note
import UpdateDepartmentModal from '../components/Config/Department/UpdateDepartmentModal'//Note
import ActionButtons_Department from '../components/Config/Department/ActionButtons_Department'
const RemoveContract = () => {
	const {
		authState: {
			user: { username, roles,_id }
		},
		logoutUser
	} = useContext(AuthContext)
	const {
		showAddRemoveContractModal,
		setShowAddRemoveContractModal,
		showAllRemoveContractModal,
		setShowAllRemoveContractModal,
		delete_RemoveContract,
		deleteAll_RemoveContract,
		showToast: { show, message, type },
		setShowToast
	} = useContext(RemoveContractContext)
	const {
		ContractState: { Contract, Contracts, ContractsLoading },
		getContracts,
		setShowAddContractModal,
		// showToast: { show, message, type },
		// setShowToast
	} = useContext(ContractContext)

	useEffect(() => getContracts(), [])

	const [selectedOption, setSelectedOption] = useState('');
    const handleChange = (e) => {
    	setSelectedOption(e.target.value);
    };

    function pushKeyAndVal(customers) {
		var customer = [];
		customers.map(function (e, i) {
			customer.push({ value: e._id, label: e.ContractID});
		})
		return customer;
	}


    //fixed
    const [stateMenu, setStateMenu] = useState({ value: [] });
    const [state, setState] = useState({ value: [] });
    var colourOptions = pushKeyAndVal(Contracts);

	const styles = {
		valueContainer: (base) => ({
			...base,
			overflowY: "auto"
		}),
		multiValue: (base, state) => {
			return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
		},
		multiValueLabel: (base, state) => {
			return state.data.isFixed
			? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
			: base;
			},
		multiValueRemove: (base, state) => {
			return state.data.isFixed ? { ...base, display: "none" } : base;
		}
	};

	const orderOptions = (values) => {
		return values
		.filter((v) => v.isFixed)
		.concat(values.filter((v) => !v.isFixed));
	};

	const onClickNew = () => {
		if(state.value.length > 0){
			setShowAddRemoveContractModal(true)
		}else{
			setShowToast({ show: true, message:"Vui lòng chọn hợp đồng!!!", type: "danger" });
		}
	};

	const onClickAllNew = () => {
		setShowAllRemoveContractModal(true)
	};

    const FixedOptions = () => {
		// const [state, setState] = useState({ value: [] });

		function onChange(value, { action, removedValue }) {
			switch (action) {
				case "remove-value":
				case "pop-value":
					if (removedValue.isFixed) {
					  return;
					}
				break;
				case "clear":
					value = colourOptions.filter((v) => v.isFixed);
				break;
				default:
				break;
			}
			value = orderOptions(value);
			setState({ value: value });
		}

		return (
			<Select
				value={state.value}
				isMulti
				styles={styles}
				isClearable={state.value.some((v) => !v.isFixed)}
				name="colors"
				className="basic-multi-select"
				classNamePrefix="select"
				onChange={onChange}
				options={colourOptions}
			/>
		);
	};
	if(_id == '65676f9ffaea227259381017'){
		return (
    	<>
    		<div className="mutyl-content">
		        <h2>Chọn hợp đồng cần xóa</h2>
		        <FixedOptions />
		        <Button
	            	style={{top: '20px', marginBottom: '20px', position: 'relative'}}
					variant='primary'
					onClick={ ()=> onClickNew()}
				>
					Xóa hợp đồng
				</Button>
				 <Button
	            	style={{top: '20px', marginBottom: '20px', marginLeft: '20px' ,position: 'relative'}}
					variant='primary'
					onClick={ ()=> onClickAllNew()}
				>
					Xóa tất cả hợp đồng
				</Button>
		    </div>
		    <RemoveContractModal _id={state.value} />
		    <Toast
				show={show}
				style={{ position: 'fixed', top: '20%', right: '10px', zIndex: '97'}}
				className={`bg-${type} text-white`}
				onClose={setShowToast.bind(this, {
					show: false,
					message: '',
					type: null
				})}
				delay={3000}
				autohide
			>
				<Toast.Body>
					<strong>{message}</strong>
				</Toast.Body>
			</Toast>



    		{/*<div className="mutyl-content">
		        <h2>Multi-Select View</h2>
		        {options.map((option) => (
			        <label key={option.id}>
			          <input
			            type="checkbox"
			            value={option.id}
			            checked={selectedOptions.includes(option.id)}
			            onChange={handleOptionChange}
			          />
			          {option.label}
			        </label>
		        ))}
		        <div className="selected-options">
				  	Selected Options: {selectedOptions.join(', ')}
				</div>
		    </div>*/}
{/*    	{body}
    	<AddDepartmentModal />
    	<UpdateDepartmentModal />
    	<Toast
				show={show}
				style={{ position: 'fixed', top: '20%', right: '10px' }}
				className={`bg-${type} text-white`}
				onClose={setShowToast.bind(this, {
					show: false,
					message: '',
					type: null
				})}
				delay={3000}
				autohide
			>
				<Toast.Body>
					<strong>{message}</strong>
				</Toast.Body>
			</Toast>*/}
    	</>
    )
	}else{
		return (
			<>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h2'>Tài khoản không có quyền truy cập menu này.</Card.Header>
				</Card>
			</>
		)
	}
}

export default RemoveContract
