import playIcon from '../../../assets/play-btn.svg'
import editIcon from '../../../assets/pencil.svg'
import deleteIcon from '../../../assets/trash.svg'
import Button from 'react-bootstrap/Button'
// import { DepartmentContext } from '../../contexts/DepartmentContext'
import { useContext } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { ConfigDepartmentContext } from '../../../contexts/ConfigDepartmentContext'

const ActionButtons_Department = (_id) => {
	const {delete_Department, Data_update, setData_update, setShowUpdateDepartmentModal } = useContext(
		ConfigDepartmentContext
	) 
	const chooseDepartment = _id => {
		//findDepartment(DepartmentId)
		setData_update(_id)
		setShowUpdateDepartmentModal(true)
	}
	function submit () {
		confirmAlert({
		  title: 'Xoá phòng ban',
		  message: '',
		  buttons: [
			{
			  label: 'Có',
			  onClick: () => delete_Department(_id)
			},
			{
			  label: 'Không',
			  onClick: () => closeDialog()
			}
		  ]
		});
	  };
	  const closeDialog = () => {
		setShowUpdateDepartmentModal(false)
	}
	return (
		<>
			
			<Button className='post-button' onClick={chooseDepartment.bind(this, _id)}>
				<img src={editIcon} alt='edit' width='24' height='24' />
			</Button>
			<Button className='post-button' onClick={submit}>
				<img src={deleteIcon} alt='delete' width='24' height='24' />
			</Button>
		</>
	)
}

export default ActionButtons_Department
