import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useContext, useState, useEffect } from 'react'
import { ConfigCustomerContext } from '../../../contexts/ConfigCustomerContext'
import { useParams } from 'react-router-dom'
import * as XLSX from 'xlsx';

const ImportCustomerlModal = () => {
	const [selectedFile, setSelectedFile] = useState(null);
    const handleFileUpload = (event) => {
    	setSelectedFile(event.target.files[0]);
    };
    const params = useParams();
    const {
	    showImportCustomerlModal,
	    setshowImportCustomerlModal,
	    import_Customer,
	    showToast: { show, message, type },
	    setShowToast
	} = useContext(ConfigCustomerContext);

	const closeDialog = () => {
	    setshowImportCustomerlModal(false)
	}

	const handleImport = () => {
		if (selectedFile == null) {
			setShowToast({ show: true, message :'Vui lòng chọn file import', type:'danger'}) 
			return false
		}

		const reader = new FileReader();
	    const rABS = !!reader.readAsBinaryString;
	    reader.onload = async e => {
	        const bstr = e.target.result;
	        const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
	        const wsname = wb.SheetNames[0];
	        const ws = wb.Sheets[wsname];
	        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
	        let flag = false;
	        for (let i = 1; i < data.length; i++) {
		        let value = data[i];
		        if(value != ''){
					let CustomerName = value[1] != undefined ? value[1] : '';
					let TaxCode = value[2] != undefined ? value[2] : '';
					let newCustomer = {
						CustomerName: CustomerName,
						TaxCode: TaxCode
					}
					const newCustomerResult = await import_Customer(newCustomer)
					if(newCustomerResult.success == true){
						flag = true;
					}
		        }
	        }

	        if(flag == true){
	        	setShowToast({ show: true, message :'Nhập file excel thành công', type:'success'})
	        }else{
	            setShowToast({ show: true, message :'Nhập file excel không thành công', type:'danger'})
	        }
	        setTimeout(() => {location.reload();},1000);
	    };

	    if (rABS) reader.readAsBinaryString(selectedFile);
	    else reader.readAsArrayBuffer(selectedFile);
	}

	const findArrID = (Category) => {
		let _value = "";
		element.map(function (value) {
			if (value.Category == Category) {
				_value = value
			}
		})

		return _value
	}

	const findArrContentCostID = (ele, Content) => {
		let _value = "";
		ele.map(function (value) {
			if (value.Content == Content) {
				_value = value
			}
		})

		return _value
	}

	function isNumericString(str) {
	    // Kiểm tra nếu chuỗi không tồn tại hoặc rỗng
	    if (!str || str.length === 0) {
	    	return 0;
	    }
	    // Kiểm tra xem chuỗi có chỉ chứa số hay không
	    let regrex = /^\d+$/.test(str);
	    if(regrex == true){
	    	return str;
	    }else{
	    	return 0;
	    }
	}


	return (
		<Modal show={showImportCustomerlModal} onHide={() => setshowImportCustomerlModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Nhập từ file excel</Modal.Title>
            </Modal.Header>
            <Form>
	            <Modal.Body>
	                <Form.Group style={{top: "10px", position: "inherit"}}>
		                <div>
		                    <input type="file" id="csvFileInput" style={{ display: 'none' }} onChange={handleFileUpload} />
		                    <label htmlFor="csvFileInput">
		                    <input type="file" accept=".xlsx" onChange={handleFileUpload} />
		                    </label>
		                </div>
	                </Form.Group>
	            </Modal.Body>
                <Modal.Footer>
		           <Button variant='secondary' onClick={closeDialog}>Hủy</Button>
           			<Button variant='primary' onClick={handleImport}>Import</Button>
           		</Modal.Footer>
            </Form>
        </Modal>
	)
}

export default ImportCustomerlModal