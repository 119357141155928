import {
	LOADED_SUCCESS,
	LOADED_FAIL,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from '../contexts/constants'

export const UserManagerReducer = (state, action) => {
	const { type, payload } = action
	switch (type) {
		case LOADED_SUCCESS:
			return {
				...state,
				UserManagers: payload,
				UserManagersLoading: false
			}

		case LOADED_FAIL:
			return {
				...state,
				UserManagers: [],
				UserManagersLoading: false
			}

		case ADD:
			return {
				...state,
				UserManagers: [...state.UserManagers, payload]
			}

		case DELETE:
			return {
				...state,
				UserManagers: state.UserManagers.filter(UserManager => UserManager._id !== payload)
			}

		case FIND:
			return { ...state, UserManager: payload }

		case UPDATE:
			const newUserManagers = state.UserManagers.map(UserManager =>
				UserManager._id === payload._id ? payload : UserManager
			)

			return {
				...state,
				UserManagers: newUserManagers
			}

		default:
			return state
	}
}
