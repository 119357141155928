import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Select from 'react-select';

import { useContext, useState, useEffect } from 'react'
import { UserContext } from '../../contexts/UserContext'
import { AuthContext } from '../../contexts/AuthContext'
import { MenuContext } from '../../contexts/MenuContext'
import { RoleContext } from '../../contexts/RoleContext'
import { ConfigCenterContext } from '../../contexts/ConfigCenterContext'
import { ConfigDepartmentContext } from '../../contexts/ConfigDepartmentContext'

const AddUserModal = () => {
	// Contexts
	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		UserState: { User, Users, UsersLoading },
		getUsers,
		showAddUserModal,
		setShowAddUserModal,
		addUser,
		setShowToast
	} = useContext(UserContext)

	const {
		RoleState: { Role, Roles, RolesLoading },
		getRoles,
	} = useContext(RoleContext)

	const {
		MenuState: { Menu, Menus, MenusLoading },
		getMenu,
	} = useContext(MenuContext)

	const {
		CenterState: { Centers, CentersLoading },
		get_All_Center
	} = useContext(ConfigCenterContext)

	const {
		DepartmentState: { Departments, DepartmentsLoading },
		getDepartment
	} = useContext(ConfigDepartmentContext)

	useEffect(() => getUsers(), [])
	useEffect(() => getRoles(), [])
	useEffect(() => getMenu(), [])
	useEffect(() => get_All_Center(), [])
	useEffect(() => getDepartment(), [])
	
	// State
	const [newUser, setNewUser] = useState({
		username: '',
		name: '',
		email: '',
		password: '',
		roleusers: '',
		usermanagers: '',
		userpages: '',
		centers: '',
		departments: '',
	})

	const { username, email, name, password, roleusers, usermanagers, userpages, centers, departments } = newUser
	const UserRoleNew = Roles;
	const departmentFillter = (Departments.length > 0 ) ? Departments.filter(x => x.TypeDepartment == "1") : [];
	const [showPhanQuyen, setShowPhanQuyen] = useState(false);
	const [showMenu, setShowMenu] = useState(false);
	const [userSelectData, setUserSelectData] = useState([]);
	const [state, setState] = useState({ value: [] });
	const [stateMenu, setStateMenu] = useState({ value: [] });
	const userAssign = getUserAssign(Users);
	const menuAssign = pushKeyAndVal(Menus);
	const [showDonVi, setShowDonVi] = useState(false);
	const [showPhongBan, setShowPhongBan] = useState(false);
	const infoUserLogin = FindArray(Users, _id)
	const CentersNew = filter_new_centers(Centers, infoUserLogin)
	const [newDepartmentSelect, setNewDepartmentSelect] = useState(departmentFillter);
	// const DepartmentNew = (roleName == "Admin") ? departmentFillter : filter_department_by_center(departmentFillter, (typeof infoUserLogin != "string") ? infoUserLogin.centers[0] : "")
	// console.log("DepartmentNew", DepartmentNew)

	const handleTypeSelect = (e) => {
		setSelectedOption(e.value);
	};

	function pushKeyAndVal(customers) {
		var customer = [];
		customers.map(function (e, i) {
			customer.push({ value: e._id, label: e.name});
		})
		return customer;
	}

	function setDefaultValueSelectTemp(customers, id) {
		if (typeof id == "undefined") return { value: "", label: "-----" };
		var customer = [];
		customers.map(function (e, i) {
			if (e._id == id) customer = { value: e._id, label: e.CustomerName};
		})
		return customer;
	}

	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	function filter_new_centers(array, user) {
		let rs
		if (array.length > 0 && user != "") {
			let centers_id = (user.centers != null ) ? user.centers[0]: ''
			if (centers_id != "") {
				rs = array.filter(x => x._id === centers_id)
			} else {
				rs = array
			}
		}

		return rs
	}

	function filter_department_by_center(array, center_id) {
		let rs = []
		if (array.length > 0 && center_id != "") {
			array.map(function (e, i) {
				if (e.centers.length > 0 ) {
					if (e.centers[0] == center_id) rs.push(e)
				}
			})
		}

		return rs
	}

	const onChangeNewUserForm = event => {
		if (event.target.name == "roleusers") {
			if (event.target.value != "") {
				let nameRole = FindArray(Roles, event.target.value).name;
				switch (nameRole) {
					case "Admin":
					case "BTGD":
					case "BGD":
					case "KTT":
					case "AM":
						setShowPhanQuyen(false)
						setUserSelectData([])
						setShowMenu(true)
						break;

					case "TPKD":
						setShowPhanQuyen(true)
						setUserSelectData([])
						setShowMenu(true)
						break;
				}

				switch (nameRole) {
					case "BGD":
					case "KTT":
						setShowDonVi(true)
						setShowPhongBan(false)
						break;
					case "TPKD":
					case "AM":
						setShowDonVi(true)
						setShowPhongBan(true)
						break;
					case "BTGD":
					case "Admin":
						setShowDonVi(false)
						setShowPhongBan(false)
						break;
				}
			} else {
				setShowPhanQuyen(false)
				setUserSelectData([])
				setShowMenu(false)
				setShowDonVi(false)
				setShowPhongBan(false)
			}
		}

		if (event.target.name == "centers") {
			let nameRole = FindArray(Roles, newUser.roleusers).name;
			if (nameRole == "KTT" || nameRole == "BGD") {
				let userByRole = filterByRolesCenters(Users, newUser.roleusers, event.target.value)
				setUserSelectData(userByRole)
			}
			let departmentFillters = filter_department_by_center(departmentFillter, event.target.value)
			setNewDepartmentSelect(departmentFillters)
			// console.log("departmentFillterNew", departmentFillterNew)
		}


		if (event.target.name == "departments") {
			let userByRole = filterByRolesCentersDepartments(Users, newUser.roleusers, newUser.centers, event.target.value)
			setUserSelectData(userByRole)
		}
		setNewUser({ ...newUser, [event.target.name]: (event.target.name == "name") ? event.target.value : event.target.value.toLowerCase() })
	}

	const closeDialog = () => {
		resetAddUserData()
	}

	const onSubmit = async event => {
		event.preventDefault()
		if (stateMenu.value.length == 0) {
			setShowToast({ show: true, message: "Vui lòng cấu hình 1 menu", type: 'danger' })
			return false;
		}

		if (newUser.centers != "649bb33b5f819d033f8535a0") {
			let nameRole = FindArray(Roles, newUser.roleusers).name;
			if(nameRole == "KTT" || nameRole == "BGD" || nameRole == "BTGD") {
			}else {
				if (newUser.departments == "") {
					setShowToast({ show: true, message: "Vui lòng chọn phòng ban", type: 'danger' })
					return false;
				}
			}
		}

		newUser.usermanagers = (state.value.length > 0) ? mapRoleWithUser(newUser.roleusers, state.value) : [];
		newUser.userpages = (stateMenu.value.length > 0) ? mapUserWithMenu(stateMenu.value) : [];
		newUser.roles = newUser.roleusers
		
		const { success, message } = await addUser(newUser)
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
		resetAddUserData()
	}

	const resetAddUserData = () => {
		setNewUser({
			username: '',
			name: '',
			email: '',
			password: '',
			roleusers: '',
			usermanagers: '',
			userpages: '',
			centers: '',
			departments: '',
		})
		setShowAddUserModal(false)
		setShowPhanQuyen(false)
		setUserSelectData([])
		setState({ value: [] })
		setStateMenu({ value: [] })
		setShowMenu(false)
		setShowDonVi(false)
		setShowPhongBan(false)
	}

	function getUserAssign(data) {
		var userAssigns = [];
		if (data.length>0) {
			data.map(function (e, i){
				if (e.manager_details.length > 0) {
					e.manager_details.map(function (em, im) {
						userAssigns.push(em.user_id[0]);
					})
				}
			})
		}
		return userAssigns;
	}

	//
	function filterByRoles(data, role_id) {
		if (typeof role_id == "undefined") return [];
			var customer = [];
			data.map(function (e, i) {
				if (userAssign.length > 0 && userAssign.includes(e._id)) {
				}else {
					let nameRole = FindArray(Roles, role_id).name;
					switch (nameRole) {
						case "TPKD":
							if (e.role_details[0].name == "AM") customer.push({ value: e._id, label: e.username});
							break;
						case "KTT":
							if (e.role_details[0].name == "TPKD") customer.push({ value: e._id, label: e.username});
							break;
						case "BGD":
							if (e.role_details[0].name == "KTT") customer.push({ value: e._id, label: e.username});
							break;
						// case "BTGD":
						// 	if (e.role_details[0].name == "BGD") customer.push({ value: e._id, label: e.username});
						// 	break;
					}
				}
			})
		return customer;
	}

	function filterByRolesCentersDepartments(data, role_id, center_id, department_id) {
		if (typeof role_id == "undefined") return [];
		if (typeof center_id == "undefined") return [];
		if (typeof department_id == "undefined") return [];
		var customer = [];
		data.map(function (e, i) {
			if (userAssign.length > 0 && userAssign.includes(e._id)) {
			}else {
				let nameRole = FindArray(Roles, role_id).name;
				switch (nameRole) {
					case "TPKD":
						if (e.center_details != "" && e.department_details != "") {
							if (e.role_details[0].name == "AM" && e.center_details[0]._id == center_id && e.department_details[0]._id == department_id) customer.push({ value: e._id, label: e.username});
						}
						break;
					case "KTT":
						if (e.center_details != "" && e.department_details != "") {
							if (e.role_details[0].name == "TPKD" && e.center_details[0]._id == center_id && e.department_details[0]._id == department_id) customer.push({ value: e._id, label: e.username});
						}
						// if (e.role_details[0].name == "TPKD" ) customer.push({ value: e._id, label: e.username});
						break;
					case "BGD":
						if (e.center_details != "" && e.department_details != "") {
							if (e.role_details[0].name == "KTT" && e.center_details[0]._id == center_id && e.department_details[0]._id == department_id) customer.push({ value: e._id, label: e.username});
						}
						// if (e.role_details[0].name == "KTT") customer.push({ value: e._id, label: e.username});
						break;
					// case "BTGD":
					// 	if (e.role_details[0].name == "BGD") customer.push({ value: e._id, label: e.username});
					// 	break;
				}
			}
		})
		return customer;
	}

	function filterByRolesCenters(data, role_id, center_id) {
		if (typeof role_id == "undefined") return [];
		if (typeof center_id == "undefined") return [];
		var customer = [];
		data.map(function (e, i) {
			if (userAssign.length > 0 && userAssign.includes(e._id)) {
			}else {
				let nameRole = FindArray(Roles, role_id).name;
				switch (nameRole) {
					case "TPKD":
						if (e.center_details != "" && e.department_details != "") {
							if (e.role_details[0].name == "AM" && e.center_details[0]._id == center_id) customer.push({ value: e._id, label: e.username});
						}
						break;
					case "KTT":
						if (e.center_details != "") {
							if (e.role_details[0].name == "TPKD" && e.center_details[0]._id == center_id) customer.push({ value: e._id, label: e.username});
						}
						break;
					case "BGD":
						if (e.center_details != "") {
							if (e.role_details[0].name == "KTT" && e.center_details[0]._id == center_id) customer.push({ value: e._id, label: e.username});
						}
						break;
					// case "BTGD":
					// 	if (e.role_details[0].name == "BGD") customer.push({ value: e._id, label: e.username});
					// 	break;
				}
			}
		})
		return customer;
	}

	function mapRoleWithUser(role_id, array_user) {
		var usermanagers = [];
		array_user.map(function (e, i) {
			usermanagers.push({ user_id: e.value, manager_id: role_id})
		})
		return usermanagers;
	}

	function mapUserWithMenu(array_user) {
		var userpages = [];
		array_user.map(function (e, i) {
			userpages.push({ user_id: "", page_id: e.value})
		})
		return userpages;
	}

	///////
	var colourOptions = userSelectData;

	const styles = {
		valueContainer: (base) => ({
			...base,
			maxHeight: 50,
			overflowY: "auto"
		}),
		multiValue: (base, state) => {
			return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
		},
		multiValueLabel: (base, state) => {
			return state.data.isFixed
			? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
			: base;
			},
		multiValueRemove: (base, state) => {
			return state.data.isFixed ? { ...base, display: "none" } : base;
		}
	};

	const orderOptions = (values) => {
		return values
		.filter((v) => v.isFixed)
		.concat(values.filter((v) => !v.isFixed));
	};

	const FixedOptions = () => {
		// const [state, setState] = useState({ value: [] });

		function onChange(value, { action, removedValue }) {
			switch (action) {
				case "remove-value":
				case "pop-value":
					if (removedValue.isFixed) {
					  return;
					}
				break;
				case "clear":
					value = colourOptions.filter((v) => v.isFixed);
				break;
				default:
				break;
			}
			value = orderOptions(value);
			setState({ value: value });
		}

		return (
			<Select
				value={state.value}
				isMulti
				styles={styles}
				isClearable={state.value.some((v) => !v.isFixed)}
				name="colors"
				className="basic-multi-select"
				classNamePrefix="select"
				onChange={onChange}
				options={colourOptions}
				closeMenuOnSelect={false}
			/>
		);
	};

	//////menu
	var menuOptions = menuAssign;

	const stylesMenu = {
		valueContainer: (base) => ({
			...base,
			maxHeight: 50,
			overflowY: "auto"
		}),
		multiValue: (base, stateMenu) => {
			return stateMenu.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
		},
		multiValueLabel: (base, stateMenu) => {
			return stateMenu.data.isFixed
			? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
			: base;
			},
		multiValueRemove: (base, stateMenu) => {
			return stateMenu.data.isFixed ? { ...base, display: "none" } : base;
		}
	};

	const orderMenuOptions = (values) => {
		return values
		.filter((v) => v.isFixed)
		.concat(values.filter((v) => !v.isFixed));
	};

	const FixedMenuOptions = () => {

		function onChangeMenu(value, { action, removedValue }) {
			switch (action) {
				case "remove-value":
				case "pop-value":
					if (removedValue.isFixed) {
					  return;
					}
				break;
				case "clear":
					value = menuOptions.filter((v) => v.isFixed);
				break;
				default:
				break;
			}
			value = orderMenuOptions(value);
			setStateMenu({ value: value });
		}

		return (
			<Select
				value={stateMenu.value}
				isMulti
				styles={stylesMenu}
				isClearable={stateMenu.value.some((v) => !v.isFixed)}
				name="menus"
				className="basic-multi-select"
				classNamePrefix="select"
				onChange={onChangeMenu}
				options={menuOptions}
			/>
		);
	};
	/////

	return (
		<Modal show={showAddUserModal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>Bạn muốn thêm người dùng mới?</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<Form.Group>
						<Form.Text id='username-help' muted as="h6">
							Tên đăng nhập 
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Tên đăng nhập'
							name='username'
							required
							aria-describedby='username-help'
							value={username}
							autoComplete="new-username"
							onChange={onChangeNewUserForm}
						/>						
					</Form.Group>
					<Form.Group>
						<Form.Text id='name-help' muted as="h6">
							Họ và tên
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Họ và tên'
							name='name'
							aria-describedby='name-help'
							value={name}
							onChange={onChangeNewUserForm}
						/>						
					</Form.Group>
					<Form.Group>
						<Form.Text id='Email-help' muted  as="h6">
							Email
						</Form.Text>
						<Form.Control
							type='email'
							placeholder='Email'
							name='email'
							value={email} /* tạo ràn buộc số */
							onChange={onChangeNewUserForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='Password-help' muted  as="h6">
							Mật khẩu
						</Form.Text>
						<Form.Control
							type='password'
							placeholder='Mật khẩu'
							name='password'
							required
							value={password} /* tạo ràn buộc số */
							autoComplete="new-password"
							onChange={onChangeNewUserForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='roleId-help' muted as="h6">
							Vai trò
						</Form.Text>
						<Form.Control
							id = 'roleId-help'
							as="select"
							name='roleusers'
							aria-describedby='roleId-help'
							value={roleusers}
							required
							onChange={onChangeNewUserForm}>
							<option value=''>-----</option>
							{
								typeof Roles != "undefined" ? 
								Roles.map((obj => {
									return (
										<>
											<option value={obj._id}>{obj.name}</option>
										</>
									)
								}))
								:
								<></>
							}
							
					    </Form.Control>
					</Form.Group>

					{
						showDonVi ? 
							<>
							<Form.Group>
								<Form.Text id='trungtam-help' muted as="h6">
									Đơn vị
								</Form.Text>
								<Form.Control
									id = 'trungtam-help'
									as="select"
									name='centers'
									aria-describedby='trungtam-help'
									value={centers}
									required
									onChange={onChangeNewUserForm}>
									<option value=''>-----</option>
									{
										typeof Centers != "undefined" ? 
										Centers.map((obj => {
											return (
												<>
													<option value={obj._id}>{obj.CenterName}</option>
												</>
											)
										}))
										:
										<></>
									}
									
								</Form.Control>
							</Form.Group>
							</>
						:
							<></>
					}

					{
						showPhongBan ? 
							<>
								<Form.Group>
									<Form.Text id='phongban-help' muted as="h6">
										Phòng ban
									</Form.Text>
									<Form.Control
										id = 'phongban-help'
										as="select"
										name='departments'
										aria-describedby='phongban-help'
										value={departments}
										onChange={onChangeNewUserForm}>
										<option value=''>-----</option>
										{
											newDepartmentSelect.length > 0 ? 
											newDepartmentSelect.map((obj => {
												return (
													<>
														<option value={obj._id}>{obj.CodeDepartment}</option>
													</>
												)
											}))
											:
											<></>
										}
										
									</Form.Control>
								</Form.Group>
							</>
						:
							<></>
					}

					{
						showPhanQuyen ? 
							<>
								<Form.Group>
									<Form.Text id='roleId-help' muted as="h6">
										Phân quyền quản lý người dùng
									</Form.Text>
									<FixedOptions />
								</Form.Group>
							</>
						:
							<></>
					}

					{
						showMenu ? 
							<>
								<Form.Group>
									<Form.Text id='menuId-help' muted as="h6">
										Phân quyền quản lý menu
									</Form.Text>
									<FixedMenuOptions />
								</Form.Group>
							</>
						:
							<></>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='info' type='submit'>
						Thêm
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}

export default AddUserModal