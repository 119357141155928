import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useContext,useEffect, useState } from 'react'
import Select from 'react-select';

import { AuthContext } from '../../contexts/AuthContext'
import { ContractContext } from '../../contexts/ContractContext'
import { ConfigDepartmentContext } from '../../contexts/ConfigDepartmentContext'
import { ConfigCustomerContext } from '../../contexts/ConfigCustomerContext'
import { ConfigCenterContext } from '../../contexts/ConfigCenterContext'
import { UserContext } from '../../contexts/UserContext'
import { RoleContext } from '../../contexts/RoleContext'

const AddContractModal = () => {
	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)
	let UserID = _id;
	
	// Contexts
	const {
		ContractState,
		showAddContractModal,
		setShowAddContractModal,
		addContract,
		setShowToast
	} = useContext(ContractContext)
	const {
		DepartmentState: { Department, Departments, DepartmentsLoading },
		getDepartment
	} = useContext(ConfigDepartmentContext)

	const {
		CustomerState: { Customer, Customers, CustomersLoading },
		get_All_Customer
	} = useContext(ConfigCustomerContext)

	const {
		CenterState: { Centers, CentersLoading },
		get_All_Center
	} = useContext(ConfigCenterContext)

	const {
		UserState: { User, Users, UsersLoading },
		getUsers
	} = useContext(UserContext)

	const {
		RoleState: { Role, Roles, RolesLoading },
		getRoles,
	} = useContext(RoleContext)
	useEffect(() => getRoles(), [])
	let role_new = roles[0];
	let roleName = FindArray(Roles, role_new).name;
	// console.log("roleName", roleName)
	// console.log(Users,UserID,'===============');
	let CentersNew = '';
	let DepartmentsNew = '';
	if(roleName == 'BTGD' || roleName == 'Admin'){
		// console.log('sdsad')
		CentersNew = Centers;
		DepartmentsNew = Departments;
		// console.log(CentersNew,DepartmentsNew,'===')
	}else if(roleName == 'KTT' || roleName == 'BGD'){
		CentersNew = FindArray(Users, UserID).center_details;
		DepartmentsNew = Departments;
		if(CentersNew != undefined){
			let Department_by_center = filter_department_by_center(Departments,CentersNew[0]._id)
			DepartmentsNew = Department_by_center;
		}
	}else{
		// console.log('11111')
		CentersNew = FindArray(Users, UserID).center_details;
		DepartmentsNew = FindArray(Users, UserID).department_details;
	}

	useEffect(() => getDepartment(1), [])
	useEffect(() => get_All_Customer(), [])
	useEffect(() => get_All_Center(), [])
	useEffect(() => getUsers(), [])
	
	// State
	const [newContract, setNewContract] = useState({
		CenterID: '',
		DepartmentID: '',
		CustomerID: '',
		ContractID: '',
		RatioUSD: 24000,
		VAT: 10,
		Datetime: convert_datetime()
	})
	const { 
		CenterID,
        DepartmentID,
        CustomerID,
        ContractID,
		RatioUSD,
		VAT,
        Datetime
	} = newContract
	// console.log(newContract['Datetime'],'===============')
	const onChangeNewContractForm = event =>
		setNewContract({ ...newContract, [event.target.name]: event.target.value })
	

	const closeDialog = () => {
		resetAddContractData()
	}

	const onSubmit = async event => {
		event.preventDefault()
		newContract.CustomerID = selectedOption;
		newContract.RatioUSD = (newContract.RatioUSD != '') ? newContract.RatioUSD : 24000
		newContract.VAT = (newContract.VAT != '') ? newContract.VAT : 10
		if(newContract.Datetime != ''){
			newContract.Date = newContract['Datetime'] + " " + convert_time();
		}else{
			newContract.Date = convert_datetime() + " " + convert_time();
		}
		newContract.CenterID = (typeof CentersNew != "undefined" && CentersNew.length == 1) ? CentersNew[0]._id : newContract.CenterID
		newContract.DepartmentID = (typeof DepartmentsNew != "undefined" && DepartmentsNew.length == 1) ? DepartmentsNew[0]._id : newContract.DepartmentID
		delete newContract.Datetime;
		const { success, message } = await addContract(newContract) 
		// console.log(success, message, 'success, messagesuccess, messagesuccess, message')
		resetAddContractData()
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
		location.reload();
	}

	const resetAddContractData = () => {
		setNewContract({ 
			CenterID: '',
			DepartmentID: '',
			CustomerID: '',
			ContractID: '',
			RatioUSD: 24000,
			VAT: 10,
			Datetime: convert_datetime()
	 	})
		setShowAddContractModal(false)
	}

	let [selectedValue, setSelectedValue] = useState('');
	function handleSelectChange(event) {
		let value = event.target.value;
		let name = event.target.name;
		if (name == "CenterID") {
			let newDepartment = filter_department_by_center(departmentFillter, value)
			setNewDepartmentSelect(newDepartment)
		}
		setSelectedValue(value);
		setNewContract({ ...newContract, [event.target.name]: value })
	}

	function convert_time(){
		var date = new Date(); // Replace this with your actual date object
		var year = date.getFullYear();
		var month = ('0' + (date.getMonth() + 1)).slice(-2);
		var day = ('0' + date.getDate()).slice(-2);
		var hours = ('0' + date.getHours()).slice(-2);
		var minutes = ('0' + date.getMinutes()).slice(-2);
		var seconds = ('0' + date.getSeconds()).slice(-2);
		var formattedDate = `${hours}:${minutes}:${seconds}`;
		return formattedDate;
	}

	function convert_datetime(){
		var date = new Date(); // Replace this with your actual date object
		var year = date.getFullYear();
		var month = ('0' + (date.getMonth() + 1)).slice(-2);
		var day = ('0' + date.getDate()).slice(-2);
		var hours = ('0' + date.getHours()).slice(-2);
		var minutes = ('0' + date.getMinutes()).slice(-2);
		var seconds = ('0' + date.getSeconds()).slice(-2);
		var formattedDate = `${year}-${month}-${day}`;
		return formattedDate;
	}

	const customersSelect = typeof Customers != "undefined" ? pushKeyAndVal(Customers) : { value: "", label: "-----" };
	const defaultValueSelectTemp = { value: "", label: "-----" };
	const [selectedOption, setSelectedOption] = useState(defaultValueSelectTemp.value);

	const handleTypeSelect = (e) => {
		setSelectedOption(e.value);
	};

	function pushKeyAndVal(customers) {
		var customer = [{ value: "", label: "-----" }];
		customers.map(function (e, i) {
			customer.push({ value: e._id, label: e.CustomerName});
		})
		return customer;
	}

	function setDefaultValueSelectTemp(customers, id) {
		if (typeof id == "undefined") return { value: "", label: "-----" };
		var customer = [];
		customers.map(function (e, i) {
			if (e._id == id) customer = { value: e._id, label: e.CustomerName};
		})
		return customer;
	}

	function FindArray(array, ID) {
		let returnObject = "";
		// console.log(array, ID,'sdsdsds')
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	function filter_new_centers(array, user) {
		let rs
		if (array.length > 0 && user != "") {
			let centers_id = (user.centers != null ) ? user.centers[0]: ''
			if (centers_id != "") {
				rs = array.filter(x => x._id === centers_id)
			} else {
				rs = array
			}
		}

		return rs
	}

	function filter_department_by_center(array, center_id) {
		let rs = []
		if (array.length > 0 && center_id != "") {
			array.map(function (e, i) {
				if (e.centers.length > 0 ) {
					if (e.centers[0] == center_id) rs.push(e)
				}
			})
		}

		return rs
	}

	const departmentFillter = (Departments.length > 0 ) ? Departments.filter(x => x.TypeDepartment == "1") : []
	const [newDepartmentSelect, setNewDepartmentSelect] = useState(null);
	if(newDepartmentSelect != null){
		DepartmentsNew = newDepartmentSelect;

	}
	// console.log(newDepartmentSelect,'DepartmentsNewDepartmentsNew')

	return (
		<Modal show={showAddContractModal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>Thêm Hợp đồng mới</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<Form.Group>
						<Form.Text id='ContentCost-help' muted as="h6">
							Trung tâm phụ trách hợp đồng
						</Form.Text>
						<Form.Control
							id = 'noidung-help'
							as="select"
							name='CenterID'
							aria-describedby='noidung-help'
							value={CenterID}
							onChange={handleSelectChange}
							disabled={ (typeof CentersNew != "undefined" && CentersNew.length == 1) ? true : null}
							>
							{
								(typeof CentersNew != "undefined" && CentersNew.length == 1) ?
								<>
									<option value={CentersNew[0]._id}>{CentersNew[0].CenterName}</option>
								</>
								: 
								<>
									<option value=''>-----</option>
									{
										typeof CentersNew != "undefined" ? 
										CentersNew.map((obj => {
											return (
												<>
													<option value={obj._id}>{obj.CenterName}</option>
												</>
											)
										}))
										:
										<></>
									}
								</>
							}							
					    </Form.Control>
					</Form.Group>

					<Form.Group>
						<Form.Text id='ContentCost-help' muted as="h6">
							Phòng ban phụ trách hợp đồng 
						</Form.Text>
						<Form.Control
							id = 'noidung-help'
							as="select"
							name='DepartmentID'
							aria-describedby='noidung-help'
							value={DepartmentID}
							disabled={ (typeof DepartmentsNew != "undefined" && DepartmentsNew.length == 1) ? true : null}
							onChange={handleSelectChange}>
							{
								(typeof DepartmentsNew != "undefined" && DepartmentsNew.length == 1) ?
								<>
									<option value={DepartmentsNew[0]._id}>{DepartmentsNew[0].DepartmentName}</option>
								</>
								: 
								<>
									<option value=''>-----</option>
									{
										typeof DepartmentsNew != "undefined" ? 
										DepartmentsNew.map((obj => {
											return (
												<>
													<option value={obj._id}>{obj.DepartmentName}</option>
												</>
											)
										}))
										:
										<></>
									}
								</>
							}
					    </Form.Control>
					</Form.Group>

					<Form.Group>
						<Form.Text id='ContentCost-help' muted as="h6">
							Thông tin khách hàng
						</Form.Text>
						<Select
							aria-describedby='noidung-help'
							name='CustomerID'
					        options={customersSelect}
					        onChange={handleTypeSelect}
					        value={customersSelect.filter(function (option) {
					          return option.value === selectedOption;
					        })}
					        placeholder=''
					     />
					</Form.Group>

					<Form.Group>
						<Form.Text id='ContractID-help' muted  as="h6">
							Số hợp đồng/PO 
						</Form.Text>
						<Form.Control
							type='text'
							placeholder=''
							name='ContractID'
							value={ContractID}
							required
							onChange={onChangeNewContractForm}
						/>
					</Form.Group>
					<Form.Row>
						<Form.Group as={Col} controlId="formGridTaxRate">
							<Form.Text muted as="h6">
								Tỷ giá USD
							</Form.Text>
							<Form.Control
								type='text'
								placeholder='24000'
								name='RatioUSD'
								value={RatioUSD}
								onChange={onChangeNewContractForm}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId="formGridTaxRate">
							<Form.Text muted as="h6">
								VAT
							</Form.Text>
							<Form.Control
								type='text'
								placeholder='10'
								name='VAT'
								value={VAT}
								style={{'float':'left'}}
								onChange={onChangeNewContractForm}
							/>
							<Form.Text muted as='h7' style={{'float':'left','marginLeft':'-20px','marginTop':'7px'}}>%</Form.Text>
						</Form.Group>
					</Form.Row>
					<Form.Group>
						<Form.Text id='Date-help' muted  as="h6">
						Ngày… Tháng… Năm…  của hợp đồng 
						</Form.Text>
						<Form.Control
							type='date'
							placeholder=''
							name='Datetime'
							value={Datetime}
							onChange={onChangeNewContractForm}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Thêm
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}

export default AddContractModal
