import { useContext, useEffect, useState} from 'react'
import DataTable , { defaultThemes } from "react-data-table-component";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useParams, Link } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Toast from 'react-bootstrap/Toast'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'

import { AuthContext } from '../contexts/AuthContext'
import { ConfigCenterContext } from '../contexts/ConfigCenterContext'
import { UserContext } from '../contexts/UserContext'
import { UserPageContext } from '../contexts/UserPageContext'
import { MenuContext } from '../contexts/MenuContext'
import { ContractContext } from '../contexts/ContractContext'
import { RoleContext } from '../contexts/RoleContext'
import { UserManagerContext } from '../contexts/UserManagerContext'


import AddCenterModal from '../components/Config/Center/AddCenterModal'//Note
import UpdateCenterModal from '../components/Config/Center/UpdateCenterModal'//Note
import ActionButtons_Center from '../components/Config/Center/ActionButtons_Center'

const ConfigCenter = () => {
	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)

	const params = useParams();
	const {
		CenterState: { Center, Centers, CentersLoading },
		showAddCenterModal,
		setShowAddCenterModal,
		setshowImportCenterlModal,
		get_All_Center,
		showToast: { show, message, type },
		setShowToast
	} = useContext(ConfigCenterContext)
	let UserID = _id;
	useEffect(() => get_All_Center(), [])
	let body = null;
	let i = 1;
	// console.log(Centers,'Centers');

	if (CentersLoading) {
		body = (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	} else if (Centers.length === 0) {
		body = (
			<>
		        <Card className='text-center mx-5 my-5'>
		            <Card.Header as='h1'>Danh sách trung tâm</Card.Header>
		            <Card.Body>
			            <Table responsive="lg" striped bordered hover size="lg" variant="" >
			                <thead>
				                <tr style={{textAlign : 'center'}}>
				                    <th style={{verticalAlign:'inherit'}}>STT</th>
				                    <th style={{verticalAlign:'inherit'}}>Tên trung tâm</th>
				                    <th style={{verticalAlign:'inherit'}}>Thao tác</th>
				                </tr>
			                </thead>
			                <tfoot>
				                <tr>
									<td colSpan={4}>
										<Button
											variant='primary'
											onClick={setShowAddCenterModal.bind(this, true)}
										>
											Thêm trung tâm
										</Button>
									</td>
								</tr>
			                </tfoot>
			            </Table>
			            <a href={`/inputform/${params.id}`}>
			              <Button
			                variant='primary'
			              >
			                Quay lại
			              </Button>
			            </a>
		            </Card.Body>
		        </Card>
		    </>
		)
	}else {
		
		body = (
			<>
		        <Card className='text-center mx-5 my-5'>
		            <Card.Header as='h1'>Danh sách trung tâm</Card.Header>
		            <Card.Body>
			            <Table responsive="lg" striped bordered hover size="lg" variant="" >
			                <thead>
				                <tr style={{textAlign : 'center'}}>
				                    <th style={{verticalAlign:'inherit'}}>STT</th>
				                    <th style={{verticalAlign:'inherit'}}>Tên trung tâm</th>
				                    <th style={{verticalAlign:'inherit'}}>Thao tác</th>
				                </tr>
			                </thead>
			                <tbody>
			                	{
			                		Centers.map(Center => (
			                			<>
						                	<tr key={Center._id}>
							                	<td>{i++}</td>
							                	<td>{Center.CenterName}</td>
							                	<td>
							                		<ActionButtons_Center 
								                		id={Center._id}
								                		CenterName={Center.CenterName}
								                	/>
							                	</td>
							                </tr>
						                </>
					                ))
					            }
					        </tbody>
					        <tfoot>
				                <tr>
									<td colSpan={4}>
										<Button
											variant='primary'
											onClick={setShowAddCenterModal.bind(this, true)}
										>
											Thêm trung tâm
										</Button>
									</td>
								</tr>
			                </tfoot>
			            </Table>
			            <a href={`/summary`}>
			              <Button
			                variant='primary'
			              >
			                Quay lại
			              </Button>
			            </a>
		            </Card.Body>
		        </Card>
		    </>
		)
	}

		const {
		UserPageState: { UserPage, UserPages, UserPagesLoading },
		getAllUserPages
	} = useContext(UserPageContext)

	const {
	    MenuState: { Menu, Menus, MenusLoading },
	    getMenu,
	} = useContext(MenuContext);

	function FindArrayMenu(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return null;
		}
	}

	if (UserPagesLoading && MenusLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	}else {
		const result = UserPages.filter(obj => { return obj.user_id.some(id => id === UserID); });
		let menu_access = false
		if (result.length > 0) {
			for (var j = 0; j < result.length; j++) {
				let menuV1 = FindArrayMenu(Menus, result[j].page_id[0])
				if (menuV1 != null && menuV1.path == location.pathname) {
					// console.debug("menuV1 co quyen truy cap")
					menu_access = true
					break;
				}else {
					// console.debug("menuV1 khong quyen truy cap")
				}
			}
		} else {
			// console.debug("menu khong quyen truy cap")
		}

		if (menu_access) {
			return (
		    	<>
		    	{body}
		    	<AddCenterModal />
		    	<UpdateCenterModal />
		    	
		    	<Toast
						show={show}
						style={{ position: 'fixed', top: '20%', right: '10px' }}
						className={`bg-${type} text-white`}
						onClose={setShowToast.bind(this, {
							show: false,
							message: '',
							type: null
						})}
						delay={3000}
						autohide
					>
						<Toast.Body>
							<strong>{message}</strong>
						</Toast.Body>
					</Toast>
		    	</>
		    )
		}else {
			return (
				<>
					<div id="notfound">
						<div className="notfound">
							<h1>Oops!</h1>
							<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
								<br/>
								<Link style={{textDecoration: "none"}} to="/home">
									Trở về trang chủ
								</Link>
							</h6>
						</div>
					</div>
				</>
			)
		}
	}
}

export default ConfigCenter

