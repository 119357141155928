import { MandayCostContext } from "../contexts/MandayCostContext";
import { AuthContext } from "../contexts/AuthContext";
import { ContractContext } from "../contexts/ContractContext";
import { ConfigCustomerContext } from "../contexts/ConfigCustomerContext";
import { useContext, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
/* import { useState } from 'react' */
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
 import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Col from 'react-bootstrap/Col' 
import addIcon from "../assets/plus_circle_fill.svg";
import iconPlus from "../assets/plus_circle_fill.svg";
import axios from "axios";
import { apiUrl } from "../contexts/constants";

import { UserContext } from "../contexts/UserContext";
import { RoleContext } from "../contexts/RoleContext";
import { HistoryContext } from "../contexts/HistoryContext";
import { EventContext } from "../contexts/EventContext";
import { UserManagerContext } from "../contexts/UserManagerContext";
import { HistoryChangeContext } from "../contexts/HistoryChangeContext";

import AddMandayCostModal from "../components/Mandaykysu/AddMandayCostModal"; //Note
import UpdateMandayCostModal from "../components/Mandaykysu/UpdateMandayCostModal"; //Note
import ActionButtons_MandayCost from "../components/Mandaykysu/ActionButtons_MandayCost";
import ImportMandayCostModal from "../components/Mandaykysu/ImportMandayCostModal"; //Note
import { ConfigDepartmentContext } from "../contexts/ConfigDepartmentContext";

import Table from "react-bootstrap/Table";
import diacritics from "diacritics";
import iconEdit from '../assets/icon-edit.svg'
import iconDelete from '../assets/icon-delete.svg'
import { confirmAlert } from 'react-confirm-alert';
import { InvoiceContext } from '../contexts/InvoiceContext'
//View all MandayCost
export const MandayCost_all = () => {
    // Contexts
    const {
        authState: {
            user: { username },
        },
    } = useContext(AuthContext);

    const {
        MandayCostState: { MandayCost, MandayCosts, MandayCostsLoading },
        getMandayCosts,
        setShowAddMandayCostModal,
        showToast: { show, message, type },
        setShowToast,
    } = useContext(MandayCostContext);

    // hàm tính tổng thành tiền
    function sumArray(mang) {
        let sum = 0;
        mang.map(function (value) {
            sum += value;
        });
        return sum;
    }

    // Start: Get all MandayCosts
    useEffect(() => getMandayCosts(), []);

    let body = null;
    let stt = 1;

    const tong = sumArray(MandayCosts.map((MandayCost) => MandayCost.IntoMoney)); //note

    if (MandayCostsLoading) {
        body = (
            <div className="spinner-container">
                <Spinner animation="border" variant="info" />
            </div>
        );
    } else if (MandayCosts.length === 0) {
        body = (
            <>
                <Card className="text-center mx-5 my-5">
                    <Card.Header as="h5">Form 5: Manday kỹ sư</Card.Header>
                    <Card.Body>
                        <Card.Title>Chưa có dữ liệu !</Card.Title>
                        {/* <Button
							variant='primary'
							onClick={setShowAddMandayCostModal.bind(this, true)}
						>
							Thêm!
						</Button> */}
                    </Card.Body>
                </Card>
            </>
        );
    } else {
        body = (
            <>
                <Card className="text-center mx-5 my-5">
                    <Card.Header as="h1">MANDAY KỸ SƯ</Card.Header>
                    <Card.Body>
                        <Table responsive="lg" striped bordered hover size="lg" variant="">
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Phòng ban </th>
                                    <th>Manday chuẩn</th>
                                    <th width="10%">Số người tham gia dự án</th>
                                    <th width="10%">Số ngày thực hiện dự án</th>
                                    <th>Thành tiền</th>
                                    <th width="20%">Ghi chú</th>
                                </tr>
                            </thead>
                            <tbody>
                                {MandayCosts.map((MandayCost) => (
                                    <tr key={MandayCost._id}>
                                        <td>{stt++} </td>
                                        <td>{MandayCost.Department}</td>
                                        <td>{MandayCost.Cost}</td>
                                        <td>{MandayCost.StaffNumber}</td>
                                        <td>{MandayCost.ImplementationDay}</td>
                                        <td>{(MandayCost.IntoMoney != null) ? MandayCost.IntoMoney.toLocaleString(): ""}</td>
                                        <td>{MandayCost.Note} </td>
                                        <td>
                                            <ActionButtons_MandayCost _id={MandayCost._id} />
                                        </td>
                                    </tr>
                                ))}
                                {/* <tr>
									<td colSpan={5} >Tổng</td>
									<td>{tong.toLocaleString()}</td>
									<td></td>
									<td></td>
									<td></td>
								</tr> */}
                            </tbody>
                        </Table>
                        {/* <Button
							variant='primary'
							onClick={setShowAddMandayCostModal.bind(this, true)}
						>
							Thêm mới
						</Button> */}
                    </Card.Body>
                </Card>
            </>
        );
    }

    return (
        <>
            {body}
            <AddMandayCostModal />
            {MandayCost !== null && <UpdateMandayCostModal />}
            {/* After MandayCost is added, show toast */}
            <Toast
                show={show}
                style={{ position: "fixed", top: "20%", right: "10px" }}
                className={`bg-${type} text-white`}
                onClose={setShowToast.bind(this, {
                    show: false,
                    message: "",
                    type: null,
                })}
                delay={3000}
                autohide
            >
                <Toast.Body>
                    <strong>{message}</strong>
                </Toast.Body>
            </Toast>
        </>
    );
};

//View all MandayCost by idContract
export const MandayCost_idContract = () => {
    const params = useParams();
    // Contexts
    const {
        authState: {
            user: { username, _id, roles },
        },
    } = useContext(AuthContext);

    const {
        RoleState: { Role, Roles, RolesLoading },
        getRoles,
    } = useContext(RoleContext);
    useEffect(() => getRoles(), []);

    const {
        MandayCostState: { MandayCost, MandayCosts, MandayCostsLoading },
        getMandayCost_byidContract,
        getMandayCost_byidContractNew,
        setShowAddMandayCostModal,
        setShowImportMandayCostModal,
        showToast: { show, message, type },
        setShowToast,
        _setRadioUSD,
        _setMandayCost,
    } = useContext(MandayCostContext);

    const {
        ContractState: { Contracts, ContractsLoading },
        getContract_byid,
    } = useContext(ContractContext);
    console.debug(123, MandayCosts)
    const {
        DepartmentState: { Department, Departments, DepartmentsLoading },
        getDepartment,
    } = useContext(ConfigDepartmentContext);
    useEffect(() => getDepartment(2), []);
    const [dataUpload, setDataUpload] = useState(null);
    // State để theo dõi việc loading
    const [loading, setLoading] = useState(true);
    // State để theo dõi lỗi (nếu có)
    const [error, setError] = useState(null);
    useEffect(() => {
        // Hàm fetchData để gọi API
        const fetchData = async () => {
            try {
                // Thực hiện GET request với Axios
                const response = await axios.get(`${apiUrl}/api/upload/manday-cost/${params.id}`);
                // Lưu trữ dữ liệu từ response vào state
                setDataUpload(response.data);
            } catch (error) {
                // Nếu có lỗi, lưu trữ lỗi vào state
                setError(error);
            } finally {
                // Đánh dấu việc loading đã hoàn thành
                setLoading(false);
            }
        };

        // Gọi hàm fetchData
        fetchData();
    }, []);

    // console.log(error,'=====++++++++=');

    // hàm tính tổng thành tiền
    function sumArray(mang) {
        let sum = 0;
        mang.map(function (value) {
            sum += value;
        });
        return sum;
    }

    //=== Get Invoices
    const {
        InvoiceState: { Invoice, Invoices, InvoicesLoading },
        getInvoice_ContractID
    } = useContext(InvoiceContext)
    useEffect(() => getInvoice_ContractID(params.id), [params.id])
    console.log("Invoices===: ", Invoices)
    //=== End Get GuaranteeLetterCost


    useEffect(() => getMandayCost_byidContractNew(params.id), []);
    // Start: Get all MandayCost by idContract
    // useEffect(() => getMandayCost_byidContract(params.id), [])
    useEffect(() => getContract_byid(params.id), []);
    let body = null;
    let stt = 1;

    const tong = sumArray(MandayCosts.map((MandayCost) => typeof MandayCost.IntoMoney != "undefined" ? MandayCost.IntoMoney : 0)); //note
    console.debug("MandayCosts", MandayCosts, tong, MandayCosts.map((MandayCost) => MandayCost.IntoMoney))

    const {
        CustomerState: { Customer, Customers, CustomersLoading },
        get_All_Customer,
    } = useContext(ConfigCustomerContext);
    useEffect(() => get_All_Customer(), []);

    function FindArray(array, ID) {
        let returnObject = "";
        if (ID != undefined) returnObject = array.find((x) => x._id === ID);
        if (returnObject != undefined) {
            return returnObject;
        } else {
            return "";
        }
    }

    let idcontractNew = "";
    let customer_name = "";
    if (Contracts.length > 0) {
        var filterContact = FindArray(Contracts, params.id);
        customer_name = FindArray(Customers, filterContact.CustomerID) != "" ? FindArray(Customers, filterContact.CustomerID).CustomerName : filterContact.CustomerID;
        idcontractNew = filterContact != "" ? filterContact.ContractID : "";
    }

    async function downLoadFile(apiUrl, Document, id) {
        try{
            let DocumentNew = removeVietnameseDiacritics(Document);
            const response = await axios.get(
                `${apiUrl}/files/${DocumentNew}`, //note xem trong server
                { responseType: "arraybuffer" }
            );
             // Tạo Blob từ dữ liệu ArrayBuffer
            const blob = new Blob([response.data], { type: "application/octet-stream" });

            // Tạo URL từ Blob
            const url = window.URL.createObjectURL(blob);

            // Tạo thẻ a để tạo liên kết tải xuống
            const link = document.createElement("a");
            link.href = url;
            let file_name = format_string(Document, id);
            // file_name = encodeURIComponent(file_name); // Mã hoá tên tệp
            // file_name = file_name.replace(/%20/g, "_");
            link.setAttribute("download", file_name); // Thay bằng tên tệp tải xuống
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            // Giải phóng tài nguyên
            window.URL.revokeObjectURL(url);
        }
        catch(error){
            if(error.response.status > 200){
                const response = await axios.get(
                    `${apiUrl}/files/${Document}`, //note xem trong server
                    { responseType: "arraybuffer" }
                );
                 // Tạo Blob từ dữ liệu ArrayBuffer
                const blob = new Blob([response.data], { type: "application/octet-stream" });

                // Tạo URL từ Blob
                const url = window.URL.createObjectURL(blob);

                // Tạo thẻ a để tạo liên kết tải xuống
                const link = document.createElement("a");
                link.href = url;
                let file_name = format_string(Document, id);
                // file_name = encodeURIComponent(file_name); // Mã hoá tên tệp
                // file_name = file_name.replace(/%20/g, "_");
                link.setAttribute("download", file_name); // Thay bằng tên tệp tải xuống
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);

                // Giải phóng tài nguyên
                window.URL.revokeObjectURL(url);
            }
        }
    }

    //function delete file attracted
    async function deleteFileAttached(apiUrl, id, Document) {
        confirmAlert({
          title: 'Xóa file đính kèm Manday kỹ sư ?',
          message: '',
          buttons: [
            {
              label: 'Có',
                onClick: async () => {
                const response = await axios.delete(`${apiUrl}/api/upload/file-upload/delete/${id}`);
                const historyChangeUploadFileMandayCost = {
                    "requester": _id,
                    "contract": params.id,
                    "form": "upload-manday-cost",
                    "action": "delete",
                    "data_change": "delete "+ id
                }
                const { successHis, messageHis } = await addHistoryChange(historyChangeUploadFileMandayCost)
                if (response.data.success) {
                    setShowToast({ show: true, message: response.data.message, type: response.data.success ? 'success' : 'danger' })
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }

                const responsefilesNew = await axios.get(
                    `${apiUrl}/filesNew/${Document}`, //note xem trong server
                    { responseType: "arraybuffer" }
                );
              }
            },
            {
              label: 'Không',
              // onClick: () => closeDialog()
            }
          ]
        });
        // const response = await axios.delete(`${apiUrl}/api/upload/file-upload/delete/${id}`);
        // console.log(response,'====response');
    }

    function removeVietnameseDiacritics(str) {
        const cleanedStr = diacritics.remove(str);
        return cleanedStr.replace(/\s/g, ""); // Loại bỏ khoảng trắng
    }

    function format_string(Document, id) {
        if (Document.includes(id)) {
            Document = Document.replace(id + "_", "");
        }
        return Document;
    }
    function convert_to_array(string) {
        return string.split(",");
    }
    // console.log(dataUpload.Upload);
    let dataUploadMandayCostFile = "";
    if (dataUpload != null) dataUploadMandayCostFile = dataUpload.Upload;

	///////////////////////
	const {
		HistoryState: { History, Historys, HistorysLoading },
		getHistory
	} = useContext(HistoryContext)
	useEffect(() => getHistory(), [])

	///UserManagers
	const {
		UserManagerState: { UserManager, UserManagers, UserManagersLoading },
		getAllUserManagers
	} = useContext(UserManagerContext)
	useEffect(() => getAllUserManagers(), [])

    const {
        historyChangeState: { historyChange, historyChanges },
        getHistoryChanges,
        addHistoryChange
    } = useContext(HistoryChangeContext)


	const {
		UserState: { User, Users, UsersLoading },
		getUsers
	} = useContext(UserContext)

	if (ContractsLoading) {
		return (<></>)
	}else {
		let role_new = roles[0];
		let UserID = _id;
		let roleName = FindArray(Roles, role_new).name;

		let rows;
		let newContract;
		let newContract111 = [];
		if (roleName == "Admin" || roleName == "BTGD") {
		    newContract = Contracts;
		} else if (roleName == "KTT" || roleName == "BGD") {
		    let centerOfUser = filter_Users(Users, UserID).centers;
		    // console.log(centerOfUser,'====1111===')
		    if (centerOfUser != undefined) {
		        if (Contracts.length > 0) {
		            newContract111 = filter_new_contract(Contracts, centerOfUser);
		        }
		    }
		    newContract = newContract111;
		} else {
		    // console.debug(UserManagers,UserID,roleName,get_user_id(UserID, UserManagers),'sdsd')
		    let newContract1 = Contracts.filter((v) => get_user_id(UserID, UserManagers).includes(v.user._id));
		    if (roleName == "AM") {
		        newContract = newContract1;
		    } else {
		        let contract_me = Contracts.filter((v) => v.user._id == UserID);
		        let newContract2 = newContract1.concat(contract_me);
		        newContract = newContract2;
		    }
		}

		      function get_status(array,ContractID){
            let returnObject = "";
            if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
            let lastElement = returnObject.pop();
            if(lastElement != undefined){
                return lastElement;
            }else{
                return '';
            }
        }

        function checkUsersAccessContact(history, roleName, UserID, statusID, filteredHistoryChangesCapTrenEdit, userContract, usersarray, rolearray) {
            let userContractAccess = (typeof userContract[0].user != "undefined" && typeof userContract[0].user != "string") ? userContract[0].user.roles[0] : (typeof userContract[0].user_details != "undefined" && typeof userContract[0].user_details == "object") ? userContract[0].user_details[0].roles[0] : ""
            let nameUserContract = FindArrayTPKD(usersarray, userContract[0].user, rolearray);
            let roleUserContract = userContractAccess
            let nameRoleContract = FindArrayRoleBTGD(Roles,roleUserContract).name;

            let access = false
            let historyContract = '';
            if(filteredHistoryChangesCapTrenEdit != null){
                if(filteredHistoryChangesCapTrenEdit.length > 0){
                    historyContract = filteredHistoryChangesCapTrenEdit.filter(x => x.contract === params.id && x.form != 'contract');
                }
            }

            // console.debug(111, usersarray, userContract, rolearray)
            // usersarray.map(x=> console.debug(UserID, x.user))
            // console.debug(111, {roleName, statusID, nameUserContract})
            // console.debug(1111, history, roleName, UserID, statusID, filteredHistoryChangesCapTrenEdit, userContract, usersarray, rolearray)

            switch(roleName){
                case 'AM':
                    switch(statusID){
                        case "1":
                        case "-1":
                            access = false
                            break;

                        case "2": 
                            access = false
                            break;

                        case "3":
                        case "5":
                        case "7":
                        case "-2":
                        case "-4":
                        case "-6":
                        case "6":
                        case "8":
                        case "9":
                        case "4":
                            if (historyContract != "") {
                                access = true
                            }else {
                                access = false
                            }
                            break;
                        case '10': 
                            access = true
                            break;
                    }
                    break;
                case 'TPKD':
                    switch(statusID){
                        case "1":
                            if (roleName == nameRoleContract) {
                                access = false
                            }else {
                                access = false
                            }
                            break;
                        case "2":
                            access = false
                            break;
                        case "-2":
                            access = false
                            break;
                        case "5":
                        case "7":
                        case "-4":
                        case "-6":
                        case "6":
                        case "8":
                        case "9":
                            if(historyContract != ''){
                                access = true
                            }else{
                                access = false
                            }
                            break;
                        case "10":
                            access = true
                            break;
                        case "3":
                            access = false
                            break;
                        case "-1":
                        case "4": 
                            access = false
                            break;
                    }
                    break;
                case 'KTT':
					switch(statusID){
						case "4": 
							access = false
							break;
						case "1": 
							if (roleName == nameUserContract) {
								access = false
							}else {
								if(nameRoleContract == "BTGD"){
									access = false
								}else{
									access = false
								}
							}
							break;
						case "5":
							access = false
							break;
						case "7":
						case "9":
							if (historyContract != "") {
								access = false // false if page Render_Button_Invoice
							}else {
								access = false // false if page Render_Button_Invoice
							}
							break;
						case "-4":
						case "-6":
						case "8":
							if (historyContract != "") {
								access = false
							}else {
								if(statusID == -4){
									access = false
								}else if(statusID == -6){
									access = false
								}else{
									access = false // false if page Render_Button_Invoice
								}
							}
							break;
						case "-1":
						case "2":
						case "3":
							access = false
							break;
						case "10":
							access = true
							break;
						case "-2":
						case "6":
						case "7":
							access = false
							break;
						}
                    break;
                case 'BGD':
                    switch(statusID){
						case "4":
						case "6":
							access = false
							break;
						case "1": 
							if (roleName == nameRoleContract) {
								access = false
							}else {
								access = false
							}
							break;
						case "7":
							access = false
							break;
						case "8":
							access = false
							break;
						case "-1":
						case "2":
						case "-2":
						case "5":
						case "3":
						case "4":
							access = false
							break;
						case "10":
							access = true
							break;
						case "-6":
							access = false
							break;
						case "9":
							if(historyContract != ''){
									access = true
								}else{
									access = false
								}
							break;
						default: 
							if (statusID < 9) {
								access = false
							}else {
								access = false
							}
							break;
					}
                    break;
                case 'BTGD':
                case 'Admin':
                    switch(statusID){
                        case "1": 
                            if (roleName == nameRoleContract) {
                                access = false
                            }else {
                                access = false
                            }
                            break;
                        case "10":
                            access = true
                            break;
                        default: 
                            access = false
                            break;
                    }
                    break;
            }

            console.debug(roleName, "checkUsersAccessContact:::return access:::", access)
            return access
        }

        function checkEditOrViewDetail(history, roleName, UserID, statusID, filteredHistoryChangesCapTrenEdit, userContract, usersarray, rolearray) {
			let userContractAccess = (typeof userContract[0].user != "undefined" && typeof userContract[0].user != "string") ? userContract[0].user.roles[0] : (typeof userContract[0].user_details != "undefined" && typeof userContract[0].user_details == "object") ? userContract[0].user_details[0].roles[0] : ""
            let nameUserContract = FindArrayTPKD(usersarray, userContract[0].user, rolearray);
            let roleUserContract = userContractAccess
            let nameRoleContract = FindArrayRoleBTGD(Roles,roleUserContract).name;

			let access = "none"
			let historyContract = '';
			if(filteredHistoryChangesCapTrenEdit != null){
				if(filteredHistoryChangesCapTrenEdit.length > 0){
					historyContract = filteredHistoryChangesCapTrenEdit.filter(x => x.contract === params.id && x.form != 'contract');
				}
			}

			switch(roleName){
				case 'AM':			
					switch(statusID){
						case "1":
						case "-1":
							access = "edit"
							break;

						case "2": 
							access = "view"
							break;

						case "3":
						case "5":
						case "7":
						case "-2":
						case "-4":
						case "-6":
						case "6":
						case "8":
						case "9":
						case "4":
							if (historyContract != "") {
								access = "none"
							}else {
								access = "view"
							}
							break;
						case "10": 
							access = "none"
							break;
					}
					break;
				case 'TPKD':
					switch(statusID){
						case "1":
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								access = "none"
							}
							break;
						case "2":
							access = "edit"
							break;
						case "-2":
							access = "edit"
							break;
						case "5":
						case "7":
						case "-4":
						case "-6":
						case "6":
						case "8":
						case "9":
							if(historyContract != ''){
								access = "none"
							}else{
								access = "view"
							}
							break;
						case "10":
							access = "none"
							break;
						case "3":
							access = "edit"
							break;
						case "-1":
						case "4": 
							access = "view"
							break;
					}
					break;
				case 'KTT':
					switch(statusID){
						case "4": 
							access = "edit"
							break;
						case "1": 
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								if(nameRoleContract == "BTGD"){
									access = "edit"
								}else{
									access = "view"
								}
							}
							break;
						case "5":
							access = "edit"
							break;
						case "7":
						case "9":
							if (historyContract != "") {
								access = "view" // false if page Render_Button_Invoice
							}else {
								access = "view" // false if page Render_Button_Invoice
							}
							break;
						case "-4":
						case "-6":
						case "8":
							if (historyContract != "") {
								access = "none"
							}else {
								if(statusID == -4){
									access = "edit"
								}else if(statusID == -6){
									access = "view"
								}else{
									access = "view" // false if page Render_Button_Invoice
								}
							}
							break;
						case "-1":
						case "2":
						case "3":
							access = "view"
							break;
						case "10":
							access = "none"
							break;
						case "-2":
						case "6":
						case "7":
							access = "view"
							break;
						}
					break;
				case 'BGD':
					switch(statusID){
						case "4":
						case "6":
							access = "view"
							break;
						case "1": 
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
						case "7":
							access = "view"
							break;
						case "8":
							access = "view"
							break;
						case "-1":
						case "2":
						case "-2":
						case "5":
						case "3":
						case "4":
							access = "view"
							break;
						case "10":
							access = "none"
							break;
						case "-6":
							access = "edit"
							break;
						case "9":
							if(historyContract != ''){
									access = "none"
								}else{
									access = "view"
								}
							break;
						default: 
							if (statusID < 9) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
					}
					break;
				case 'BTGD':
				case 'Admin':
					switch(statusID){
						case "1": 
							if (roleName == nameRoleContract) {
								access = "edit"
							}else {
								access = "view"
							}
							break;
						case "10":
							access = "none"
							break;
						default: 
							access = "view"
							break;
					}
					break;
			}

			console.debug(roleName, "checkEditOrView:::return access:::", access)
			return access
		}

        function FindArrayTPKD(array, ID, Roles) {
            let returnObject = "";
            if (ID != undefined) returnObject = array.find(x => x._id === ID._id);
            if(returnObject != undefined){
                return returnObject.role_details[0].name;
            }else{
                return '';
            }
        }

        function FindArrayRoleBTGD(array, ID) {
            let returnObject = "";
            if (ID != undefined) returnObject = array.find(x => x._id === ID);
            if(returnObject != undefined){
                return returnObject;
            }else{
                return '';
            }
        }

        function FindArrayUserManagers(array, ID) {
			let returnObject = "";
			if (ID != undefined) returnObject = array.filter(x => x.user_id[0] === ID);
			if(returnObject != undefined){
				return returnObject;
			}else{
				return '';
			}
		}

        function FindArrayUserCapCaoHon(UserID , array_user, roleName) {
            let returnObject = array_user.find(x => x._id == UserID);
            let rs = []
            let filteredArray = [];

            switch(roleName){
                case 'AM':
                    if(returnObject != undefined){
                        filteredArray = array_user.filter(function(item) {
                            if(item.centers != undefined){
                                return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
                            }
                        });
                        let manage_UserId = FindArrayUserManagers(UserManagers,UserID);
                        if(manage_UserId.length > 0){
                            let manager_id_new = manage_UserId[0]['manager_id'];
                            let managerObject = array_user.find(x => x._id == manager_id_new);
                            if(managerObject != undefined){
                                filteredArray.push(managerObject);
                            }
                        }
                    }
                    break;
                case 'TPKD':
                    if(returnObject != undefined){
                        filteredArray = array_user.filter(function(item) {
                            if(item.centers != undefined){
                                return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
                            }else{
                                return item.role_details[0].name == 'BTGD'
                            }
                        });
                    }
                    // console.log('2')
                    break;
                case 'KTT':
                    if(returnObject != undefined){
                        filteredArray = array_user.filter(function(item) {
                            if(item.centers != undefined){
                                return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD' && item.role_details[0].name != 'KTT';
                            } else {
                                return item.role_details[0].name == 'BTGD'
                            }
                        });
                    }
                    break;
                case 'BGD':
                    if(returnObject != undefined){
                        filteredArray = array_user.filter(function(item) {
                            return item.role_details[0].name == 'BTGD';
                        });
                    }
                    // console.log('3')
                    break;
                case 'BTGD':
                case 'Admin':
                    filteredArray = array_user
                    break;
            }

            if (filteredArray.length > 0) {
                return filteredArray.map(item => item._id);
            }

            return filteredArray
        }

        //////// new update 2024-03-16
        let filteredHistoryChangesCapTrenEdit = historyChanges.filter(x => x.contract == params.id && x.form != 'contract' && x.requester != UserID)
        let StatusID = get_status(Historys,params.id) != '' ? get_status(Historys,params.id).StatusID : '0'
        let userContact = Contracts.filter(x => x._id == params.id)
        let kiemTraChoPhepTruyCap = checkUsersAccessContact(Historys, roleName, UserID, StatusID, filteredHistoryChangesCapTrenEdit, userContact, Users, Roles)
        let checkEditOrView = checkEditOrViewDetail(Historys, roleName, UserID, StatusID, filteredHistoryChangesCapTrenEdit, userContact, Users, Roles);

		const contract_me_access = FindArray(newContract, params.id)
		const contract_me_new_access = FindArray(Contracts, params.id)
		// console.debug("newContract", roleName, newContract, newContracts, ContractsLoading)
		// console.debug("contract_me_access", contract_me_access == "", get_user_id(UserID,UserManagers))
		// console.debug("contract_me_new_access", contract_me_new_access == "", get_user_id(UserID,UserManagers))

		//loc cha, loc con
		let user_access = get_user_id(UserID,UserManagers)
        if (roleName != "AM") user_access.push(UserID)
		const userNew = (_id == "65676f9ffaea227259381017") ? Users : findUsers(Users, roleName, UserID); //  || 63c0938fed60f11521b00875
		const uniqueUserNew = (userNew.length > 0 ) ? [...new Set(userNew.map(obj => obj._id))] : []
		let newUserManagers = []
		if (roleName == "AM" || roleName == "TPKD") {
			newUserManagers = user_access
		}else if (roleName == "KTT" || roleName == "BGD") {
			// newUserManagers = (user_access.length > 0 ) ? user_access.concat(uniqueUserNew) : (uniqueUserNew.length > 0 ) ? user_access.concat(uniqueUserNew) : []
            if (roleName == "KTT") {
                let listReceiverCaoHon = FindArrayUserCapCaoHon(UserID, Users, roleName)
                let newUserManagers1 = (user_access.length > 0 ) ? user_access.concat(uniqueUserNew) : (uniqueUserNew.length > 0 ) ? user_access.concat(uniqueUserNew) : []
                newUserManagers = (newUserManagers1.length > 0 ) ? newUserManagers1.concat(listReceiverCaoHon) : (listReceiverCaoHon.length > 0 ) ? listReceiverCaoHon.concat(newUserManagers1) : []
                // console.debug(1111111, newUserManagers) //  || 63c0938fed60f11521b00875)
            }else {
                newUserManagers = (user_access.length > 0 ) ? user_access.concat(uniqueUserNew) : (uniqueUserNew.length > 0 ) ? user_access.concat(uniqueUserNew) : []
            }
		}else if (roleName == "Admin" || roleName == "BTGD") {
			newUserManagers = uniqueUserNew
		}

		//
		function filter_new_contract(array, UserID) {
		    let returnObject = "";
		    // if (UserID != undefined) returnObject = array.filter((element) => element.user.centers != null ? element.user.centers[0] : '' == UserID[0]);
		    if (UserID != undefined) returnObject = array.filter((element) => element.CenterID == UserID[0]);
		    if (returnObject != undefined) {
		        return returnObject;
		    } else {
		        return "";
		    }
		}

		function filter_Users(array, UserID) {
		    let returnObject = "";
		    if (UserID != undefined) returnObject = array.filter((element) => element._id === UserID);
		    let lastElement = returnObject.pop();
		    if (lastElement != undefined) {
		        return lastElement;
		    } else {
		        return "";
		    }
		}

		function filter_history(array, ContractID) {
		    let returnObject = "";
		    if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		    let lastElement = returnObject.pop();
		    if (lastElement != undefined) {
		        return lastElement;
		    } else {
		        return "";
		    }
		}

		function get_user_id(userLogin, userManager) {
		    let managedUsers = [];

		    function findManagedUsers(managerId) {
		        let users = userManager.filter((item) => item.manager_id[0] === managerId);
		        if (users.length > 0) {
		            for (let user of users) {
		                findManagedUsers(user.user_id[0]);
		            }
		        } else {
		            managedUsers.push(managerId);
		        }
		    }

		    findManagedUsers(userLogin);
		    return managedUsers;
		}

		function findUsers(array, roleName, idUser) {
			let usersBelongCenter = []
			let filterListUsers = FindArray(array, _id)
			switch (roleName) {
				case "Admin":
				case "BTGD":
					if (filterListUsers != undefined) {
						usersBelongCenter = array
					}
					break;
					// if (filterListUsers != undefined) {
					// 	usersBelongCenter = filter_new_user(array, null, null, idUser, ["BGD", "KTT", "TPKD", "AM"])
					// }
					// break;
				case "BGD":
					if (filterListUsers != undefined) {
						usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["KTT", "TPKD", "AM"])
					}
					break;
				case "KTT":
					if (filterListUsers != undefined) {
						usersBelongCenter = filter_new_user(array, filterListUsers.centers, null, idUser, ["TPKD", "AM"])
					}
					break;
				case "TPKD":
					if (filterListUsers != undefined) {
						usersBelongCenter = filter_new_user(array, filterListUsers.centers, filterListUsers.departments, idUser, ["AM"])
					}
					break;
			}
			// console.log("usersBelongCenter", usersBelongCenter)
			return usersBelongCenter
		}

		function filter_new_user(array, centers_id, department_id = null, idUser, listRole = null){
			let returnObject = [];
			if (centers_id !== null) {
				array.map(element => {
					if (element.centers) {
						if (element.centers[0] === centers_id[0] && element._id !== idUser && listRole.includes(element.role_details[0].name)) {
							returnObject.push(element)
						}
					}
				})
			}else {
				array.map(element => {
					if (element.centers) {
						if (element._id !== idUser) {
							returnObject.push(element)
						}
					}
				})

			}
			return returnObject
		}

        //update 25/04
        // const {
        //     InvoiceState: { Invoice, Invoices, InvoicesLoading },
        //     getInvoice_ContractID
        // } = useContext(InvoiceContext)
        // useEffect(() => getInvoice_ContractID(params.id), [params.id])
        // console.log(Invoices,'=========================')
        // let checkStatusInvoices = Invoices.find(x => x.contract === _data._id);
        // console.log(checkStatusInvoices,'checkStatusInvoicescheckStatusInvoicescheckStatusInvoices')
	    if (MandayCostsLoading) {
            body = (
                <div className="spinner-container">
                    <Spinner animation="border" variant="info" />
                </div>
            );
        } else if (MandayCosts.length === 0) {
            body = (
                <>
                    <div id="notfound">
                        <div className="notfound">
                            <h1>Oops!</h1>
                            <h6>
                                Rất tiếc, Hợp đồng không được tìm thấy trong hệ thống. Vui lòng kiểm tra lại.
                                <br />
                                <Link style={{ textDecoration: "none" }} to="/home">
                                    Trở về trang chủ
                                </Link>
                            </h6>
                        </div>
                    </div>
                </>
            );
        } else {
            body = (
                <>
                    <Card className="text-center mx-5 my-5">
                        <Card.Header as="h1">MANDAY KỸ SƯ</Card.Header>
                        <Card.Body>
                            <Table responsive="lg" striped bordered hover size="lg" variant="">
                                <thead>
                                    <tr style={{ textAlign: "left" }}>
                                        <th colSpan={8}>Số hợp đồng/PO: {idcontractNew}</th>
                                    </tr>
                                    <tr style={{ textAlign: "left" }}>
                                        <th colSpan={8}>Khách hàng: {customer_name}</th>
                                    </tr>
                                    <tr>
                                        <th colSpan={4}></th>
                                        <th>Tỷ Giá</th>
                                        <th>{Contracts != "" ? Contracts[0].RatioUSD.toLocaleString() : 0}</th>
                                        <th colSpan={2}></th>
                                    </tr>
                                    <tr style={{ textAlign: "center" }}>
                                        <th style={{ verticalAlign: "inherit" }}>STT</th>
                                        <th style={{ verticalAlign: "inherit" }}>Phòng ban </th>
                                        <th style={{ verticalAlign: "inherit" }}>Manday chuẩn</th>
                                        <th width="10%">Số người tham gia dự án</th>
                                        <th width="10%">Số ngày thực hiện dự án</th>
                                        <th style={{ verticalAlign: "inherit" }}>Thành tiền</th>
                                        <th width="20%" style={{ verticalAlign: "inherit" }}>
                                            Ghi chú
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {MandayCosts.map((MandayCost) => (
                                        <tr key={MandayCost._id}>
                                            <td>{stt++} </td>
                                            <td>{FindArray(Departments, MandayCost.DepartmentID) != "" ? FindArray(Departments, MandayCost.DepartmentID).DepartmentName : MandayCost.DepartmentID}</td>
                                            <td>{MandayCost.Cost}</td>
                                            <td>{MandayCost.StaffNumber}</td>
                                            <td>{MandayCost.ImplementationDay}</td>
                                            <td>{(MandayCost.IntoMoney != null) ? MandayCost.IntoMoney.toLocaleString(): ""}</td>
                                            <td>{MandayCost.Note} </td>
                                            <td>
                                                {
                                                    checkEditOrView == "none" || checkEditOrView == "view" ? 
                                                    <>
                                                    </>
                                                    :
                                                    <>
                                                        <ActionButtons_MandayCost _idMandayCost={MandayCost._id} />
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan={5}>Tổng</td>
                                        <td>{!isNaN(tong) ? tong.toLocaleString() : 0}</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={8}>
                                            {
                                                checkEditOrView == "none" || checkEditOrView == "view" ? 
                                                <>
                                                </>
                                                :
                                                <>
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => {
                                                            setShowAddMandayCostModal(true);
                                                            _setRadioUSD(Contracts[0].RatioUSD);
                                                        }}
                                                    >
                                                        Thêm mới
                                                    </Button>
                                                </>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <a href={`/summary/${params.id}`}>
                                <Button variant="primary">Xem PTHĐ</Button>
                            </a>
                            <span> </span>
                            <a href={`/inputform/${params.id}`}>
                                <Button variant="primary">Quay lại</Button>
                            </a>
                        </Card.Body>
                    </Card>
                </>
            );
        }

        console.log(dataUpload,'=======dataUpload===========')
        let body_file_attached = null;
        let stt_upload = 1;
        body_file_attached = (
            <>
                <Card className="text-center mx-5 my-5">
                    <Card.Header as="h4">FILE ĐÍNH KÈM</Card.Header>
                    <Card.Body>
                        <Table responsive="lg" striped bordered hover size="lg" variant="">
                            <thead>
                            	<tr>
                            		<th colSpan={4}></th>
                            		{
                                        roleName != 'BGD' && roleName != 'BTGD' ? 
                                        <>
                                            {
                                                checkEditOrView == "none" || checkEditOrView == "view" ? 
                                                <></>
                                                :
                                                <>
                                                {
                                                    Invoices.length > 0 ?
                                                    <></>
                                                    :
                                                    <>
                                                        <th>File đính kèm</th>
                                                        <th colSpan={1}>
                                                            <div>
                                                                <a
                                                                    className="me-2 mb-2"
                                                                    onClick={() => {
                                                                        setShowImportMandayCostModal(true);
                                                                    }}
                                                                >
                                                                    <img title="Tải File Lên" src={iconPlus} width="20" height="20"></img>
                                                                </a>
                                                            </div>
                                                        </th>
                                                    </>
                                                }
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <th colSpan={2}></th>
                                        </>
                                    }
                            	</tr>
                                <tr style={{ textAlign: "center" }}>
                                    <th>STT</th>
                                    <th>Tên File</th>
                                    <th>Ngày Upload</th>
                                    <th>Người Upload</th>
                                    <th>Download</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataUploadMandayCostFile.length > 0 ?
                                        dataUpload.Upload.map((uploadfile) => (
                                        <tr key={uploadfile._id}>
                                            <td>{stt_upload++} </td>
                                            <td>{format_string(uploadfile.Document,uploadfile.contract)}</td>
                                            <td>{uploadfile.CreateDateUpload}</td>
                                            <td>{uploadfile.UserUpload}</td>
                                            <td>
                                                {
                                                    Invoices.length > 0 ?
                                                    <></>
                                                    :
                                                    <>
                                                        <button
                                                            title="Tải File Xuống"
                                                            style={{ border: "none", background: "none", color: "blue", textDecoration: "underline" }}
                                                            onClick={() => downLoadFile(apiUrl, uploadfile.Document, uploadfile.contract)}
                                                        >
                                                            {format_string(uploadfile.Document, uploadfile.contract)}
                                                        </button>         
                                                    </>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    checkEditOrView == "none" || checkEditOrView == "view" ? 
                                                    <>
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            Invoices.length > 0 ?
                                                            <></>
                                                            :
                                                            <>
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            Xóa
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <Button className='post-button'>
                                                                        <img src={iconDelete} alt='delete' width='24' height='24' onClick={() => deleteFileAttached(apiUrl, uploadfile._id, uploadfile.Document)}/>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                        ))
                                    :
                                    <></>
                                }         
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        );
		///
		let check_access = contract_me_access == ""
		let check_access_new = contract_me_new_access == ""


        if (kiemTraChoPhepTruyCap) {
            return (
                <>
                    <div id="notfound">
                        <div className="notfound">
                            <h1>Oops!</h1>
                            <h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng kiểm tra lại thông tin.
                                <br/>
                                <Link style={{textDecoration: "none"}} to="/home">
                                    Trở về trang chủ
                                </Link>
                            </h6>
                        </div>
                    </div>
                </>
            )
        }else {
    		if ((check_access == true && check_access_new  == false) || (check_access == false && check_access_new  == true) || (check_access == false && check_access_new  == false)) {
    			// console.debug(1, typeof contract_me_new_access.user, contract_me_new_access)
    			if (typeof contract_me_new_access.user == "string") {
    				// console.debug("1 thu gian: cho phep truy cap")
    				if (newUserManagers.includes(contract_me_new_access.user) == true) {
    					// console.debug("11 thu gian:: truy cap duoc", contract_me_new_access.user)
    				    return (
    				        <>
    				            {body}
                                {body_file_attached}
    				            <AddMandayCostModal />
    				            <ImportMandayCostModal />
    				            {MandayCost !== null && <UpdateMandayCostModal />}
    				            {/* After MandayCost is added, show toast */}
    				            <Toast
    				                show={show}
    				                style={{ position: "fixed", top: "20%", right: "10px", zIndex: "99999" }}
    				                className={`bg-${type} text-white`}
    				                onClose={setShowToast.bind(this, {
    				                    show: false,
    				                    message: "",
    				                    type: null,
    				                })}
    				                delay={3000}
    				                autohide
    				            >
    				                <Toast.Body>
    				                    <strong>{message}</strong>
    				                </Toast.Body>
    				            </Toast>
    				        </>
    				    )
    				}else {
    					// console.debug("12 thu gian:: truy cap duoc", contract_me_new_access.user)
    					return (
    						<>
    							<div id="notfound">
    								<div className="notfound">
    									<h1>Oops!</h1>
    									<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
    										<br/>
    										<Link style={{textDecoration: "none"}} to="/home">
    											Trở về trang chủ
    										</Link>
    									</h6>
    								</div>
    							</div>
    						</>
    					)
    				}
    			}else if (typeof contract_me_new_access.user == "object") {
    				// console.debug("2 thu gian:: khong truy cap duoc", contract_me_new_access.user._id)
    				// console.debug(newUserManagers.includes(contract_me_new_access.user._id))
    				if (newUserManagers.includes(contract_me_new_access.user._id) == true) {
    					// console.debug("13 thu gian:: truy cap duoc", contract_me_new_access.user._id)
    				    return (
    				        <>
    				            {body}
                                {body_file_attached}
    				            <AddMandayCostModal />
    				            <ImportMandayCostModal />
    				            {MandayCost !== null && <UpdateMandayCostModal />}
    				            {/* After MandayCost is added, show toast */}
    				            <Toast
    				                show={show}
    				                style={{ position: "fixed", top: "20%", right: "10px", zIndex: "99999" }}
    				                className={`bg-${type} text-white`}
    				                onClose={setShowToast.bind(this, {
    				                    show: false,
    				                    message: "",
    				                    type: null,
    				                })}
    				                delay={3000}
    				                autohide
    				            >
    				                <Toast.Body>
    				                    <strong>{message}</strong>
    				                </Toast.Body>
    				            </Toast>
    				        </>
    				    )
    				}else {
    					// console.debug("14 thu gian:: truy cap duoc", contract_me_new_access.user._id)
    					return (
    						<>
    							<div id="notfound">
    								<div className="notfound">
    									<h1>Oops!</h1>
    									<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
    										<br/>
    										<Link style={{textDecoration: "none"}} to="/home">
    											Trở về trang chủ
    										</Link>
    									</h6>
    								</div>
    							</div>
    						</>
    					)
    				}
    			}
    		}else {
    			return (
    				<>
    					<div id="notfound">
    						<div className="notfound">
    							<h1>Oops!</h1>
    							<h6>Rất tiếc, Hợp đồng không được tìm thấy trong hệ thống. Vui lòng kiểm tra lại.
    								<br/>
    								<Link style={{textDecoration: "none"}} to="/home">
    									Trở về trang chủ
    								</Link>
    							</h6>
    						</div>
    					</div>
    				</>
    			)
    		}
        }
	}

    // return (
    //     <>
    //         {body}
    //         <AddMandayCostModal />
    //         <ImportMandayCostModal />
    //         {MandayCost !== null && <UpdateMandayCostModal />}
    //         {/* After MandayCost is added, show toast */}
    //         <Toast
    //             show={show}
    //             style={{ position: "fixed", top: "20%", right: "10px", zIndex: "99999" }}
    //             className={`bg-${type} text-white`}
    //             onClose={setShowToast.bind(this, {
    //                 show: false,
    //                 message: "",
    //                 type: null,
    //             })}
    //             delay={3000}
    //             autohide
    //         >
    //             <Toast.Body>
    //                 <strong>{message}</strong>
    //             </Toast.Body>
    //         </Toast>
    //     </>
    // )
};
