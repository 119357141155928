import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


import { useContext, useState, useEffect } from 'react'
import { InvoiceContext } from '../../contexts/InvoiceContext'
import { useParams } from 'react-router-dom'

import { ProductCostContext } from '../../contexts/ProductCostContext'
import { AuthContext } from '../../contexts/AuthContext'

import axios from 'axios';
import { FileReaderInput } from 'react-file-reader';
import { apiUrl } from '../../contexts/constants'
import diacritics from 'diacritics';

const AddInvoiceModal = () => {
	// Contexts 
	const {
		InvoiceState: { Invoice, Invoices, InvoicesLoading },
		showAddInvoiceModal,
		setShowAddInvoiceModal,
		addInvoice,
		setShowToast,
		getInvoice_ContractID,
		updateInvoiceNew
	} = useContext(InvoiceContext)
	useEffect(() => getInvoice_ContractID(params.id), [])

	function Sum_Invoice(Invoice) {
		let Total_Invoice = 0;
		Invoice.map(Invoices => {
			Total_Invoice += Invoices.InvoiceVal
		});
		return Total_Invoice;
	}
	const Total_Invoice = Sum_Invoice(Invoices);

	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)

	const params = useParams();
	let id_user = _id;

	const {
		ProductCostState: { ProductCost, ProductCosts, ProductCostsLoading },
		getProductCost_idContract,
		setShowAddProductCostModal
	} = useContext(ProductCostContext)
	useEffect(() => getProductCost_idContract(params.id), [])

	function Sum_OutputIntoMoney(ProductsCost) {
		let Total_OutputIntoMoney = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_OutputIntoMoney += ListProduct.OutputIntoMoney
			)))

		return Total_OutputIntoMoney;
	}
	const TotalOutputIntoMoney = Sum_OutputIntoMoney(ProductCosts);

	const closeDialog = () => {
		resetAddInvoiceData()
	}

	const resetAddInvoiceData = () => {
		setNewInvoice({
			OutputIntoMoney: '',
			InvoiceVal: '',
			DateOfInvoice: '',
			MonthOfInvoice: '',
			CreateDateOfInvoice: '',
			PercentInvoice: '',
			Document: '',
			UserID: '',
			ContractID: '',

		}) //note cần sửa
		setShowAddInvoiceModal(false)
	}

	const [newInvoice, setNewInvoice] = useState({
		OutputIntoMoney: 0,
		InvoiceVal: 0,
		DateOfInvoice: 0,
		MonthOfInvoice: 0,
		CreateDateOfInvoice: '',
		PercentInvoice: 0,
		Document: '',
		UserID: '',
		ContractID: '',
	})

	const [newCheckBox, setCheckBox] = useState({
		checkBoxPercentInvoice: true,
		checkBoxCost: false,
	})

	const {
		checkBoxPercentInvoice,
		checkBoxCost,
	} = newCheckBox

	const {
		OutputIntoMoney,
        InvoiceVal,
        DateOfInvoice, 
        MonthOfInvoice, 
        CreateDateOfInvoice, 
        PercentInvoice,
        Document,
        UserID,
        ContractID
	} = newInvoice

	newInvoice['ContractID'] = params.id;
	newInvoice['OutputIntoMoney'] = TotalOutputIntoMoney;
	newInvoice['CreateDateOfInvoice'] = convert_time();
	newInvoice['DateOfInvoice'] = convert_time();
	newInvoice['UserID'] = id_user;

	const onChangeCheckBox = event => {
		const name = event.target.name;
		const val = event.target.value
		if (name == "checkBoxPercentInvoice" && val == "on") {
			const newInvoiceVal= (val * TotalOutputIntoMoney)/100;
			// console.log(newInvoiceVal,'newInvoiceValnewInvoiceVal');
			setCheckBox({ ...newCheckBox, 'checkBoxPercentInvoice': true, 'checkBoxCost': false})
			// setNewInvoice({ ...newInvoice, 'InvoiceVal': newInvoiceVal, 'PercentInvoice': PercentInvoice})
		}else if (name == "checkBoxCost" && val == "on") {
			setCheckBox({ ...newCheckBox, 'checkBoxPercentInvoice': false, 'checkBoxCost': true })
			// setNewInvoice({ ...newInvoice, 'PercentInvoice': '', 'InvoiceVal': value})
		}
	}

	const onChangeNewInvoiceForm = event =>{
		const name = event.target.name;
		const val = event.target.value;
		if (name == "PercentInvoice") {
			if (!isNumberOrDecimal(val) && val != '') {
				var message = 'Vui lòng nhập tỉ lệ % không quá 2 số thập phận'
				setShowToast({ show: true, message: message, type: 'danger' })
				// event.target.value = validValueFee
				return false;
			}else{
				var value = (event.target.value != "") ? event.target.value : 0;
				if (parseInt(event.target.value) > 100 && parseFloat(event.target.value) > 100) {
					var message = 'Vui lòng nhập tỷ lệ không quá 100%'
					setShowToast({ show: true, message: message, type: 'danger' })
					return false;
				}
				let newInvoiceVal= (val * TotalOutputIntoMoney)/100;
				setNewInvoice({ ...newInvoice, 'InvoiceVal': newInvoiceVal, 'PercentInvoice': val})
			}
		}else if (name == "InvoiceVal") {
			if (!isParentNumber(val) && val != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				// let newInvoiceVal= (val / TotalOutputIntoMoney)*100;
				setNewInvoice({ ...newInvoice, [event.target.name]: event.target.value })
				// setNewInvoice({ ...newInvoice, 'InvoiceVal': val, 'PercentInvoice': newInvoiceVal != 0 ? newInvoiceVal.toFixed(2) : ''})
			}
		}else if(name == 'MonthOfInvoice'){
			if (!isParentNumber(val) && val != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				setNewInvoice({ ...newInvoice, 'MonthOfInvoice': parseInt(val)})
			}
		}else if(name == 'DateOfInvoice'){
			setNewInvoice({ ...newInvoice, 'DateOfInvoice': val})
		}else{
			setNewInvoice({ ...newInvoice, [event.target.name]: event.target.value })
		}
	}

	const isNumberOrDecimal = userInput => {
		// if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		// return /^\d+$/.test(userInput); ok
  		// return /((?:\d+\.\d*)|(?:\d*\.?\d+))$/.test(userInput); ok
  		// return /^\d*\.?\d*$/.test(userInput) //ok
  		return /^\d+(\.\d{0,2})?$/.test(userInput)
	}
	const isParentNumber = userInput => {
		if (typeof userInput !== 'string') return false;
		return /^\d+$/.test(userInput);
	}

	const isPatternNumber = userInput => {
		if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		return /^\d+(\.\d{0,2})?$/.test(userInput);
	}
	function convert_time(){
		var date = new Date(); // Replace this with your actual date object
		var year = date.getFullYear();
		var month = ('0' + (date.getMonth() + 1)).slice(-2);
		var day = ('0' + date.getDate()).slice(-2);
		var hours = ('0' + date.getHours()).slice(-2);
		var minutes = ('0' + date.getMinutes()).slice(-2);
		var seconds = ('0' + date.getSeconds()).slice(-2);
		var formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		return formattedDate;
	}

	const [invalidFiles, setInvalidFiles] = useState([])
	const [selectedFiles, setSelectedFiles] = useState([]);

	const handleFileChange = (event) => {
	    // setSelectedFiles([...selectedFiles, ...event.target.files]);
	    const files = event.target.files;
	    const validFormats = ['jpg', 'jpeg', 'png', 'pdf']; // Định dạng tệp cho phép
	    const validFiles = [];
	    const invalidFiles = [];

	    for (let i = 0; i < files.length; i++) {
	      const file = files[i];
	      const fileExtension = file.name.split('.').pop().toLowerCase();

	      if (validFormats.includes(fileExtension)) {
	        validFiles.push(file);
	      } else {
	        invalidFiles.push(file.name);
	      }
	    }
	    if(invalidFiles.length > 0) setShowToast({ show: true, message:"File "+invalidFiles.join(", ")+" is wrong format", type: 'danger' })
	    setSelectedFiles([...selectedFiles, ...validFiles]);
	    setInvalidFiles([...invalidFiles])
	};

	const handleUpload = async (_invoice) => {
		document.getElementById('btn-submit').style.pointerEvents = "none";
	    if (selectedFiles.length > 0) {
	        // console.log(formData.getAll('file'),'formDataformDataformData')
	        try {
	        	let newInvoice = {
	        		id: _invoice,
	        		Document: '',
	        		DocumentVni: '',
	        		ContractID: params.id
	        	};

	        	const formData = new FormData();
		        let string_file = '';
		        let string_random = makeid(10);
		        selectedFiles.forEach(file=>{
			    	// const allowedFormats = ['jpg', 'jpeg', 'png', 'pdf']; // Định dạng tệp cho phép
			        // const fileExtension = file.name.split('.').pop().toLowerCase();
			        // if (!allowedFormats.includes(fileExtension)) {
				    //     alert('Không cho phép tải lên định dạng này.');
				    //     return;
			        // }
					let isValidUtf8 = checkUtf8Validity(file.name);
					let fileName = '';
					if(isValidUtf8){
						fileName = file.name;
					}else{
						fileName = removeVietnameseDiacritics(file.name);
					}
		        	formData.append('file', file,_invoice+"_"+fileName);
		        	string_file += _invoice+"_"+file.name+",";
		        })
		        string_file = string_file.substring(0,string_file.length -1);
	        	newInvoice.Document = string_file;
	        	const { success, message } = await updateInvoiceNew(newInvoice);
	        	// setTimeout(function(){
	        		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
	        		location.reload()
	        	// },2000);

		        const response = await axios.post(`${apiUrl}/upload/`, formData, {
		           headers: {
		             'Content-Type': 'multipart/form-data',
		           },
		        });
	        // console.log(response,'=======')
	        // if(response.data.message == "Uploaded the file successfully"){
	        	// console.log(string_file,'selectedFilesselectedFiles')

	        // }

	      } catch (error) {
	        console.error('Error uploading file:', error);
	      }
	    }
	};

	function removeVietnameseDiacritics(str) {
	  const cleanedStr = diacritics.remove(str);
	  return cleanedStr.replace(/\s/g, ''); // Loại bỏ khoảng trắng
	}

	function checkUtf8Validity(input) {
	  for (let i = 0; i < input.length; ) {
	    const byte = input.charCodeAt(i);
	    let bytesToRead;

	    if (byte < 0x80) {
	      bytesToRead = 1;
	    } else if (byte < 0xE0) {
	      bytesToRead = 2;
	    } else if (byte < 0xF0) {
	      bytesToRead = 3;
	    } else if (byte < 0xF8) {
	      bytesToRead = 4;
	    } else {
	      // Invalid UTF-8 byte
	      return false;
	    }

	    for (let j = 1; j < bytesToRead; j++) {
	      if (i + j >= input.length || (input.charCodeAt(i + j) & 0xC0) !== 0x80) {
	        // Invalid UTF-8 sequence
	        return false;
	      }
	    }

	    i += bytesToRead;
	  }

	  return true;
	}

	function makeid(length) {
	    let result = '';
	    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	    const charactersLength = characters.length;
	    let counter = 0;
	    while (counter < length) {
	      result += characters.charAt(Math.floor(Math.random() * charactersLength));
	      counter += 1;
	    }
	    return result;
	}

	function convert_date(date_time){
		const date = new Date(date_time); // Replace this with your actual date object
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		const seconds = ('0' + date.getSeconds()).slice(-2);
		const formattedDate = `${year}-${month}-${day}`;
		return formattedDate;
	}
	
	const onSubmit = async event => {
		event.preventDefault()
		if(newInvoice['InvoiceVal'] > 0){ //update 03062024
			let remain_invoive = TotalOutputIntoMoney - Total_Invoice;
			if(newInvoice['InvoiceVal'] <= remain_invoive){
				const { success, message,Invoice } = await addInvoice(newInvoice)
				let idNewInvoice = Invoice._id;
				await handleUpload(idNewInvoice);
				resetAddInvoiceData()
				setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
			}else{
				let message = 'Vượt quá giá trị doanh thu của hợp đồng';
				setShowToast({ show: true, message, type: 'danger'})
			}
		}else{ //update 03062024
			let message = 'Số tiền xuất hóa đơn phải lớn hơn 0';
			setShowToast({ show: true, message, type: 'danger'})
		}
	}

	return (

		<Modal animation={false} show={showAddInvoiceModal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>Xuất hóa đơn</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<Form.Group>
						<Form.Text id='Note-help' muted as='h6'>
							Số tiền
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Số tiền'
							name='InvoiceVal'
							required
							value={InvoiceVal}
							onChange={onChangeNewInvoiceForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='Datetime-help' muted  as="h6">
						Ngày… Tháng… Năm…  xuất hóa đơn
						</Form.Text>
						<Form.Control
							type='date'
							placeholder=''
							name='DateOfInvoice'
							onChange={onChangeNewInvoiceForm}
						/>
					</Form.Group>
					<div>
						<Form.Text id='Datetime-help' muted  as="h6">
							Chứng từ
						</Form.Text>
						<input type="file" onChange={handleFileChange} multiple/>
	      			</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' id='btn-submit' type='submit'>
						Xuất
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}



export default AddInvoiceModal




