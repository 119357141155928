import {
	LOADED_SUCCESS,
	LOADED_FAIL,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from '../contexts/constants'

export const EventReducer = (state, action) => {
	const { type, payload } = action
	switch (type) {
		case LOADED_SUCCESS:
			return {
				...state,
				Events: payload,
				EventsLoading: false
			}

		case LOADED_FAIL:
			return {
				...state,
				Events: [],
				EventsLoading: false
			}

		case ADD:
			return {
				...state,
				Events: [...state.Events, payload]
			}

		case DELETE:
			return {
				...state,
				Events: state.Events.filter(Event => Event._id !== payload)
			}

		case FIND:
			return { ...state, Event: payload }

		case UPDATE:
			
			const newEvents = state.Events.map(Event =>
				Event._id === payload._id ? payload : Event
			)

			return {
				...state,
				Events: newEvents
			}

		default:
			return state
	}
}
