import Button from 'react-bootstrap/Button'
import playIcon from '../../assets/play-btn.svg'
import editIcon from '../../assets/pencil.svg'
import deleteIcon from '../../assets/trash.svg'
import { MiscExpenseCostContext } from '../../contexts/MiscExpenseContext'
import { useContext } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import iconEdit from '../../assets/icon-edit.svg'
import iconDelete from '../../assets/icon-delete.svg'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'; 

import { useParams } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'

const ActionButtons_MiscExpenseCost = ({ _idMiscExpenseCost }) => {
	const params = useParams();
	// Contexts
	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	const { deleteMiscExpenseCost, findMiscExpenseCost, setShowUpdateMiscExpenseCostModal } = useContext(
		MiscExpenseCostContext
	)

	function submit() {
	    confirmAlert({
	        title: "",
	        message: "Xoá chi phí khác",
	        buttons: [
	            {
	                label: "Có",
	                onClick: async () => {
	                	console.log("_idMiscExpenseCost", _idMiscExpenseCost)
	                    deleteMiscExpenseCost(_idMiscExpenseCost)
	                    const historyChangeMiscExpenseCost = {
						    "requester": _id,
						    "contract": params.id,
						    "form": "misc-expense-cost",
						    "action": "delete",
						    "data_change": "delete " + JSON.stringify(_idMiscExpenseCost)
						}

						const { successHis, messageHis } = await addHistoryChange(historyChangeMiscExpenseCost)
	                },
	            },
	            {
	                label: "Không",
	                onClick: () => closeDialog(),
	            },
	        ],
	    });
	}

	const closeDialog = () => {
		setShowUpdateMiscExpenseCostModal(false)
	}

	const chooseMiscExpenseCost = MiscExpenseCostId => {
		findMiscExpenseCost(MiscExpenseCostId)
		setShowUpdateMiscExpenseCostModal(true)
	}

	return (
		<>
			<OverlayTrigger
				overlay={
					<Tooltip id="tooltip">
						Cập nhật
					</Tooltip>
				}
			>
				<Button className='post-button' onClick={chooseMiscExpenseCost.bind(this, _idMiscExpenseCost)}>
					<img src={iconEdit} alt='edit' width='24' height='24' />
				</Button>
			</OverlayTrigger>
			<OverlayTrigger
				overlay={
					<Tooltip id="tooltip">
						Xóa
					</Tooltip>
				}
			>
				<Button className='post-button' onClick={submit}>
					<img src={iconDelete} alt='delete' width='24' height='24' />
				</Button>
			</OverlayTrigger>
		</>
	)
}

export default ActionButtons_MiscExpenseCost
