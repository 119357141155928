import { createContext, useReducer, useState, useContext} from 'react'
import { CapitalExpenditureCostReducer } from '../reducers/CapitalExpenditureCostReducer'
import { ProductCostReducer } from '../reducers/ProductCostReducer'
import { HistoryChangeContext } from '../contexts/HistoryChangeContext'
import { AuthContext } from '../contexts/AuthContext'
import {
	apiUrl,
	LOADED_FAIL,
	LOADED_SUCCESS,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from './constants'//Note
import axios from 'axios'

import { useHistory } from 'react-router-dom'

export const CapitalExpenditureCostContext = createContext()

const CapitalExpenditureCostContextProvider = ({ children }) => {
	// State
	const [CapitalExpenditureCostState,  dispatch] = useReducer(CapitalExpenditureCostReducer, {
		CapitalExpenditureCost: null,
		CapitalExpenditureCosts: [],
		CapitalExpenditureCostsLoading: true
	})
	const [ProductCostState, dispatchProductCost] = useReducer(ProductCostReducer, {
		ProductCost: null,
		ProductCosts: [],
		ProductCostsLoading: true
	}) 

	const [showAddCapitalExpenditureCostModal, setShowAddCapitalExpenditureCostModal] = useState(false)
	const [showUpdateCapitalExpenditureCostModal, setShowUpdateCapitalExpenditureCostModal] = useState(false)
	//
	// const [show_Two_Option_CapitalExpenditureCost_Modal, setshow_Two_Option_CapitalExpenditureCost_Modal] = useState(false)
	let history = useHistory();

	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	})

	// const {
	// 	authState: {
	// 		user: { roles, _id }
	// 	}
	// } = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	// Get all CapitalExpenditureCosts
	const getCapitalExpenditureCosts = async () => {
		try {
			const response = await axios.get(`${apiUrl}/api/forms/capital-expenditure-cost`)
			if (response.data.success) {
				dispatch({ type: LOADED_SUCCESS, payload: response.data.CapitalExpenditureCost  })
				
			}
		} catch (error) {
			dispatch({ type: LOADED_FAIL })
		}

	}

	// Get CapitalExpenditureCosts by idContract
	const getCapitalExpenditureCosts_byidContractNew = async (idContract) => {
		try {
			const response = await axios.get(`${apiUrl}/api/forms/capital-expenditure-cost/contract/${idContract}`)
			if (response.data.success) {
				console.log("getCapitalExpenditureCosts_byidContractNew", response.data)
				if (response.data.message == "Số hợp đồng không tìm thấy!") {
					const newCapitalExpenditureCost = {
						CapitalCost: '', 
				        Revenue: '',
				        CapitalExpense: '',
				        InventoryDays: 15,
				        ImplementationDays: 30,
				        BedtDays: 30,
				        DebtCollectionDays: 15,
				        Deposits: '',
				        DepositsNTP: '',
				        PercentInterest: 10,
				        Note: '',
						ContractID:idContract
					};

					// const historyChangeProductCost = {
					//     "requester": _id,
					//     "contract": idContract,
					//     "form": "capital-expenditure-cost",
					//     "action": "create",
					//     "data_change": "create new capital-expenditure"
					// }
					const capital = await addCapitalExpenditureCost(newCapitalExpenditureCost);
					// const { successHis, messageHis } = await addHistoryChange(historyChangeProductCost)
					getCapitalExpenditureCosts_byidContract(idContract);
				}else{
					dispatch({ type: LOADED_SUCCESS, payload: (response.data.CapitalExpenditureCost) ? response.data.CapitalExpenditureCost : []})
				}
			}
		} catch (error) {
			dispatch({ type: LOADED_FAIL })
		}

	}

	const getCapitalExpenditureCosts_byidContract = async (idContract) => {
		try {
			const response = await axios.get(`${apiUrl}/api/forms/capital-expenditure-cost/contract/${idContract}`)
			if (response.data.success) {
				dispatch({ type: LOADED_SUCCESS, payload: (response.data.CapitalExpenditureCost) ? response.data.CapitalExpenditureCost : []})
			}
		} catch (error) {
			dispatch({ type: LOADED_FAIL })
		}

	}
	
	// Add CapitalExpenditureCost
	const addCapitalExpenditureCost = async newCapitalExpenditureCost => {
		// console.log(newCapitalExpenditureCost,'newCapitalExpenditureCost');
		try {
			const response = await axios.post(`${apiUrl}/api/forms/capital-expenditure-cost/post`, newCapitalExpenditureCost)
			if (response.data.success) {
				dispatch({ type: ADD, payload: response.data.CapitalExpenditureCost })
				return response.data 
			}
			else
				return response.data //Message hop dong kg ton tai
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	// Delete CapitalExpenditureCost
	const deleteCapitalExpenditureCost = async CapitalExpenditureCostId => {
		try {
			const response = await axios.delete(`${apiUrl}/api/forms/capital-expenditure-cost/delete/${CapitalExpenditureCostId}`)
			if (response.data.success)
				dispatch({ type: DELETE, payload: CapitalExpenditureCostId })
		} catch (error) {
			console.log(error)
		}
	}

	// Find CapitalExpenditureCost when user is updating CapitalExpenditureCost
	const findCapitalExpenditureCost = CapitalExpenditureCostId => {
		const CapitalExpenditureCost = CapitalExpenditureCostState.CapitalExpenditureCosts.find(CapitalExpenditureCost => CapitalExpenditureCost._id === CapitalExpenditureCostId)
		dispatch({ type: FIND, payload: CapitalExpenditureCost })
	}

	// Update CapitalExpenditureCost
	const updateCapitalExpenditureCost = async updatedCapitalExpenditureCost => {
		try {
			const response = await axios.put(
				`${apiUrl}/api/forms/capital-expenditure-cost/put/${updatedCapitalExpenditureCost._id}`,
				updatedCapitalExpenditureCost
			)
			if (response.data.success) {
				getCapitalExpenditureCosts_byidContract(updatedCapitalExpenditureCost.ContractID)
				// dispatch({ type: UPDATE, payload: response.data.updatedCapitalExpenditureCost })
				return response.data
			}
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	// CapitalExpenditureCost context data
	const CapitalExpenditureCostContextData = {
		CapitalExpenditureCostState,
		getCapitalExpenditureCosts,
		showAddCapitalExpenditureCostModal,
		setShowAddCapitalExpenditureCostModal,
		showUpdateCapitalExpenditureCostModal,
		setShowUpdateCapitalExpenditureCostModal,
		addCapitalExpenditureCost,
		showToast,
		setShowToast,
		deleteCapitalExpenditureCost,
		findCapitalExpenditureCost,
		updateCapitalExpenditureCost,
		getCapitalExpenditureCosts_byidContract,
		getCapitalExpenditureCosts_byidContractNew
		// Two_Option_CapitalExpenditureCost_Modal,//update
		// show_Two_Option_CapitalExpenditureCost_Modal,
		// setshow_Two_Option_CapitalExpenditureCost_Modal
	}

	return (
		<CapitalExpenditureCostContext.Provider value={CapitalExpenditureCostContextData}>
			{children}
		</CapitalExpenditureCostContext.Provider>
	)
}

export default CapitalExpenditureCostContextProvider
