import { createContext, useReducer, useState } from 'react'
// import { UserReducer } from '../reducers/UserReducer'//Note
// import {
// 	apiUrl,
// 	LOADED_FAIL,
// 	LOADED_SUCCESS,
// 	ADD,
// 	DELETE,
// 	UPDATE,
// 	FIND
// } from './constants'//Note
import axios from 'axios'

export const AboutContext = createContext()

const AboutContextProvider = ({ children }) => {
	// User context data
	const AboutContextProvider = {
	}

	return (
		<AboutContextProvider.Provider value={AboutContextProvider}>
			{children}
		</AboutContextProvider.Provider>
	)
}

export default AboutContextProvider
