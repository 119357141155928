import { useState, useMemo, useEffect } from "react";
import { sortRows, filterRows, paginateRows } from "./helpers";
import { Pagination } from "./PaginationContract";
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import Col from 'react-bootstrap/Col'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import iconViewReport from '../../assets/icon-view-report.svg'
import iconFilterReset from '../../assets/icon-filter-reset.svg'

export const Tables = ({ columns, rows }) => {
    const [activePage, setActivePage] = useState(1);
    const [filters, setFilters] = useState({});
    const [sort, setSort] = useState({ order: "asc", orderBy: "id" });
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // const rowsPerPage = 10;

    const filteredRows = useMemo(() => filterRows(rows, filters), [rows, filters]);
    const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort]);
    const calculatedRows = paginateRows(sortedRows, activePage, rowsPerPage);
    const count = filteredRows.length;
    const totalPages = Math.ceil(count / rowsPerPage);

    const handleSearch = (value, accessor) => {
        setActivePage(1);
        setRowsPerPage(10);
        if (value) {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [accessor]: value,
            }));
        } else {
            setFilters((prevFilters) => {
                const updatedFilters = { ...prevFilters };
                delete updatedFilters[accessor];

                return updatedFilters;
            });
        }
    };

    const handleSort = (accessor) => {
        setActivePage(1);
        setRowsPerPage(10);
        setSort((prevSort) => ({
            order: prevSort.order === "asc" && prevSort.orderBy === accessor ? "desc" : "asc",
            orderBy: accessor,
        }));
    };

    const clearAll = () => {
        setSort({ order: "asc", orderBy: "id" });
        setActivePage(1);
        setRowsPerPage(10);
        setFilters({});
        setNewFilter({
            NameContract,
            Date,
            UserRemove,
            Reason
        })       
    };

    const [newFilter, setNewFilter] = useState({
		NameContract: '',
        Date: '',
        UserRemove: '',
        Reason: '',
	})
	
	const {
		NameContract,
        Date,
        UserRemove,
        Reason
	} = newFilter

    const onChangeNewFilter = (event) => {
        let name = event.target.name
		let val = event.target.value
        setNewFilter({ ...newFilter, [name]: (name == "StatusID") ? val: val.toLowerCase() })
    }

    const handleFilterChange = () => {
        if (newFilter.NameContract) handleSearch(newFilter.NameContract, "NameContract")
        if (newFilter.Date) handleSearch(newFilter.Date, "Date")
        if (newFilter.UserRemove) handleSearch(newFilter.UserRemove, "UserRemove")
        if (newFilter.Reason) handleSearch(newFilter.Reason, "Reason")
    }

    const resetFilter = () => {

    }

    const renderFillterSeach = (data) => {
        return data.map(function (column, i) {
            
            if (column.isHidden && column.isSelect) {
                return (
                    <>
                        <Form.Group style={{top: "35px"}} as={Col} className="form-group-custom">
                            <Form.Label>&nbsp;</Form.Label>
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id="tooltip">
                                        Tìm kiếm
                                    </Tooltip>
                                }
                            >
                            <a onClick={handleFilterChange}>
                                <img src={iconViewReport} width="35" height="35"></img>
                            </a>
                            </OverlayTrigger>
                            &nbsp;&nbsp;&nbsp;
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id="tooltip">
                                        Xóa tìm kiếm
                                    </Tooltip>
                                }
                            >
                            <a onClick={clearAll}>
                                <img src={iconFilterReset} width="35" height="35"></img>
                            </a>
                            </OverlayTrigger>
                        </Form.Group>
                    </>
                )
            }

            if (column.isSearch) {
                if (column.accessor == "Date"){
                    return (
                        <>
                             <Form.Group as={Col} className="form-group-custom">
                                <Form.Label>{column.label}</Form.Label>
                                <Form.Control
                                    type="date" 
                                    placeholder=""
                                    name={`${column.accessor}`}
                                    key={`${column.accessor}-search`} 
                                    value={newFilter[column.accessor]}
                                    onFocus={(e) => (e.target.type = "date")}
                                    onBlur={(e) => (e.target.type = "text")}
                                    // onChange={(event) => handleSearch(event.target.value, column.accessor)}
                                    onChange={(event) => onChangeNewFilter(event)}
                                />
                            </Form.Group>
                        </>
                    )
                }else {
                    if (column.isSelect == "Centers") {
                        return (
                            <>
                                <Form.Group as={Col} className="form-group-custom">
                                    <Form.Label>{column.label}</Form.Label>
                                    <Form.Control
                                        id = 'Center-help'
                                        as="select"
                                        aria-describedby='Center-help'
                                        name={`${column.accessor}`}
                                        key={`${column.accessor}-search`} 
                                        value={newFilter[column.accessor]}
                                        // onChange={(event) => handleSearch(event.target.value, column.accessor)}
                                        onChange={(event) => onChangeNewFilter(event)}
                                        >
                                        <option value=''>--Trung tâm--</option>
                                        {
                                            typeof column.jsonDataCenter != "undefined" ? 
                                            column.jsonDataCenter.map((obj => {
                                                return (
                                                    <>
                                                        <option value={obj.CenterName.trim()}>{obj.CenterName.trim()}</option>
                                                    </>
                                                )
                                            }))
                                            :
                                            <></>
                                        }
                            
                                    </Form.Control>
                                </Form.Group>
                            </>
                        )
                    }

                    if (column.isSelect == "Departments") {
                        return (
                            <>
                                <Form.Group as={Col} className="form-group-custom">
                                    <Form.Label>{column.label}</Form.Label>
                                    <Form.Control
                                        id = 'Department-help'
                                        as="select"
                                        aria-describedby='Department-help'
                                        name={`${column.accessor}`}
                                        key={`${column.accessor}-search`} 
                                        value={newFilter[column.accessor]}
                                        // onChange={(event) => handleSearch(event.target.value, column.accessor)}
                                        onChange={(event) => onChangeNewFilter(event)}
                                        >
                                        <option value=''>--Phòng ban--</option>
                                        {
                                            typeof column.jsonDataDepartment != "undefined" ? 
                                            column.jsonDataDepartment.map((obj => {
                                                return (
                                                    <>
                                                        <option value={obj.DepartmentName.trim()}>{obj.DepartmentName.trim()}</option>
                                                    </>
                                                )
                                            }))
                                            :
                                            <></>
                                        }
                            
                                    </Form.Control>
                                </Form.Group>
                            </>
                        )
                    }

                    if (column.isSelect == "Events") {
                        return (
                            <>
                                <Form.Group as={Col} className="form-group-custom">
                                    <Form.Label>{column.label}</Form.Label>
                                    <Form.Control
                                        id = 'Event-help'
                                        as="select"
                                        aria-describedby='Event-help'
                                        name={`${column.accessor}`}
                                        key={`${column.accessor}-search`} 
                                        value={newFilter[column.accessor]}
                                        // onChange={(event) => handleSearch(event.target.value, column.accessor)}
                                        onChange={(event) => onChangeNewFilter(event)}
                                        >
                                        <option value=''>--Trạng thái--</option>
                                        {
                                            typeof column.jsonDataEvent != "undefined" ? 
                                            column.jsonDataEvent.map((obj => {
                                                return (
                                                    <>
                                                        <option value={obj.StatusID}>{obj.name.trim()}</option>
                                                    </>
                                                )
                                            }))
                                            :
                                            <></>
                                        }
                            
                                    </Form.Control>
                                </Form.Group>
                            </>
                        )
                    }

                    return (
                        <>
                            <Form.Group as={Col} className="form-group-custom">
                                <Form.Label>{column.label}</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder=""
                                    name={`${column.accessor}`}
                                    key={`${column.accessor}-search`} 
                                    value={newFilter[column.accessor]}
                                    onChange={(event) => onChangeNewFilter(event)}
                                />
                            </Form.Group>
                        </>
                    )
                }
            }else{
                return (
                    <></>
                )
            }
        })
    }

    return (
        <>
            <Form>
				<Form.Row className="form-row-custom">
                    {
                        renderFillterSeach(columns)
                    }
                </Form.Row>
			</Form> 
            <Table responsive="lg" striped bordered hover size="lg" variant="" >
                <thead>
                    <tr>
                        {/* {columns.map((column) => {
                            // return (
                            //     <>
                            //         {
                            //             column.isSearch ? 
                            //                 column.accessor == "Date" ? 
                            //                 <th>
                            //                     <input 
                            //                         className="form-control" 
                            //                         key={`${column.accessor}-search`} 
                            //                         type="date" 
                            //                         placeholder={`${column.label}`} 
                            //                         value={filters[column.accessor]}
                            //                         onFocus={(e) => (e.target.type = "date")}
                            //                         onBlur={(e) => (e.target.type = "text")}
                            //                         onChange={(event) => handleSearch(event.target.value, column.accessor)} />
                            //                 </th>
                            //                 :
                            //                 <th>
                            //                     <input 
                            //                         className="form-control" 
                            //                         key={`${column.accessor}-search`} 
                            //                         type="search" 
                            //                         placeholder={`${column.label}`} 
                            //                         value={filters[column.accessor]} 
                            //                         onChange={(event) => handleSearch(event.target.value, column.accessor)} />
                            //                 </th>
                            //             :
                            //             <th></th>
                            //         }
                            //     </>
                                    
                            // );
                        })} */}
                    </tr>

                    <tr>
                        {columns.map((column) => {
                            const sortIcon = () => {
                                if (column.accessor === sort.orderBy) {
                                    if (sort.order === "asc") {
                                        return "▲";
                                    }
                                    return "▼";
                                } else {
                                    return "▲▼";
                                }
                            };

                            return (
                                <>
                                    {
                                        column.isHidden ?
                                        <></>
                                        :
                                        <>
                                            <th key={column.accessor} width={column.width ? column.width : ""}>
                                                <span>{column.label}</span>
                                                {
                                                    column.isSort ?
                                                    <>
                                                        &nbsp;
                                                        <span className="sort-by" onClick={() => handleSort(column.accessor)}>{sortIcon()}</span>
                                                    </> : 
                                                    <></>
                                                }
                                            </th>
                                        </>
                                    }
                                </>
                            )

                            {/* return (
                                <th key={column.accessor} width={column.width ? column.width : ""}>
                                    <span>{column.label}</span>
                                    {
                                        column.isSort ?
                                        <>
                                            &nbsp;
                                            <span className="sort-by" onClick={() => handleSort(column.accessor)}>{sortIcon()}</span>
                                        </> : 
                                        <></>
                                    }
                                </th>
                            ); */}

                        })}
                    </tr>
                </thead>
                <tbody>
                    {calculatedRows.map((row, i) => {
                        return (
                            <tr key={row.id}>
                                {columns.map((column) => {
                                    if (column.isHidden) {
                                        return (<></>)
                                    }else {
                                        if (column.format) {
                                            return <td key={column.accessor}>
                                                    <span className='text' title={row[column.accessor]}>
                                                        {column.format(row[column.accessor])}
                                                    </span>
                                                </td>;
                                        }
                                        if(column.type) {
                                            return <td key={column.accessor}><div style={{display:"flex",alignItems:"center",flexWrap:"wrap",flexDirection:"row",justifyContent:"start",columnGap: "15px",rowGap: "15px"}}>{row[column.accessor]}</div></td>;
                                            {/*return <td key={column.accessor}><div style={{display:"grid",gridTemplateColumns: "25% 20% 30% 10% 10%",columnGap:"10px",alignItems:"center"}}>{row[column.accessor]}</div></td>*/}
                                        }
                                        return <td key={column.accessor}>
                                                <span className='text' title={row[column.accessor]}>
                                                    {
                                                        (column.accessor == "STT")
                                                        ?
                                                            ((activePage - 1) * rowsPerPage + i) + 1  
                                                        :
                                                            row[column.accessor]
                                                    }
                                                </span>
                                            </td>;
                                    }
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            
            {
                count > 0 ? <Pagination 
                    activePage={activePage} 
                    count={count} 
                    rowsPerPage={rowsPerPage} 
                    setRowsPerPage={setRowsPerPage}
                    totalPages={totalPages} 
                    setActivePage={setActivePage}
                /> 
                :
                <p>Không có dữ liệu</p>
            }
           
        </>
    );
};
