import { createContext, useReducer, useState } from 'react'
import { CenterReducer } from '../reducers/CenterReducer'//Note
import {
	apiUrl,
	LOADED_FAIL,
	LOADED_SUCCESS,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from './constants'//Note
import axios from 'axios'

export const ConfigCenterContext = createContext()

const ConfigCenterContextProvider = ({ children }) => {
	//State
	const [CenterState,  dispatch] = useReducer(CenterReducer, {
		Center: [],
		Centers: [],
		CentersLoading: true
	});

	const [showAddCenterModal, setShowAddCenterModal] = useState(false);
	const [showUpdateCenterModal, setShowUpdateCenterModal] = useState(false)
	const [Data_update, setData_update] = useState([])
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	});

		// add Center
	const add_Center = async newCenter => {
		try {
			const response = await axios.post(`${apiUrl}/api/forms/center/post/`, newCenter)
			if (response.data.success) {
				get_All_Center();
				return response.data 
			}
			else
				return response.data //Message hop dong kg ton tai
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	// Update Centers
	const update_Center = async Centers => {
		try {
			const response = await axios.put(
				`${apiUrl}/api/forms/center/put/`, //note xem trong server
				Centers
			)
			if (response.data.success) {
				get_All_Center()
				return response.data
			}
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	const delete_Center = async Centers => {
		try {
			try {
			const response = await axios.delete(`${apiUrl}/api/forms/center/delete/${Centers.id}`)//note
			if (response.data.success){
				// dispatch({ type: DELETE, payload: response.data.deletedProducts })
				get_All_Center()
				return response.data
			}
		} catch (error) {
			console.log(error)
		}
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	// // import Center
	// const import_Center = async newCenter => {
	// 	try {
	// 		const response = await axios.post(`${apiUrl}/api/forms/center/post/`, newCenter)
	// 		if (response.data.success) {
	// 			return response.data 
	// 		}
	// 		else
	// 			return response.data //Message hop dong kg ton tai
	// 	} catch (error) {
	// 		return error.response.data
	// 			? error.response.data
	// 			: { success: false, message: 'Server error' }
	// 	}
	// }

	const get_All_Center = async () => {
		try {
			const response = await axios.get(`${apiUrl}/api/forms/Center`)
			if (response.data.success) {
				dispatch({ type: LOADED_SUCCESS, payload: response.data.Center })
			}
		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	// User context data
	const ConfigCenterContextData = {
		CenterState,
		add_Center,
		update_Center,
		delete_Center,
		// import_Center,
		get_All_Center,

		showAddCenterModal,
		setShowAddCenterModal,
		showUpdateCenterModal,
		setShowUpdateCenterModal,
		Data_update,
		setData_update,
		showToast,
		setShowToast
	}

	return (
		<ConfigCenterContext.Provider value={ConfigCenterContextData}>
			{children}
		</ConfigCenterContext.Provider>
	)
}

export default ConfigCenterContextProvider
