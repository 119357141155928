import {
	LOADED_SUCCESS,
	LOADED_FAIL,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from '../contexts/constants'

export const RemoveContractReducer = (state, action) => {
	const { type, payload } = action
	switch (type) {
		case LOADED_SUCCESS:
			return {
				...state,
				RemoveContracts: payload,
				RemoveContractsLoading: false
			}

		case LOADED_FAIL:
			return {
				...state,
				RemoveContracts: [],
				RemoveContractsLoading: false
			}

		case ADD:
			return {
				...state,
				RemoveContracts: [...state.RemoveContracts, payload]
			}

		case DELETE:
			return {
				...state,
				RemoveContracts: state.RemoveContracts.filter(RemoveContract => RemoveContract._id !== payload)
			}

		case FIND:
			return { ...state, RemoveContract: payload }

		case UPDATE:
			
			const newRemoveContracts = state.RemoveContracts.map(RemoveContract =>
				RemoveContract._id === payload._id ? payload : RemoveContract
			)

			return {
				...state,
				RemoveContracts: newRemoveContracts
			}

		default:
			return state
	}
}
