import Button from 'react-bootstrap/Button'
import playIcon from '../../assets/play-btn.svg'
import editIcon from '../../assets/pencil.svg'
import deleteIcon from '../../assets/trash.svg'
import { AuxiliaryCostContext } from '../../contexts/AuxiliaryCostContext'
import { useContext } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import iconEdit from '../../assets/icon-edit.svg'
import iconDelete from '../../assets/icon-delete.svg'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { useParams } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'

const ActionButtons_AuxiliaryCost = (_idAuxiliaryCost) => {
	
	const params = useParams();
	// Contexts
	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	const {
		
		deleteAuxiliaryCost,
		updateAuxiliaryCost,
		setData_AuxiliaryCost_Cost,
		setshowupdate_AuxiliaryCost_Cost_Modal,
	 } = useContext(
		AuxiliaryCostContext
	) 
	
	const chooseAuxiliaryCost = AuxiliaryCostId => {
		setData_AuxiliaryCost_Cost(_idAuxiliaryCost)
		setshowupdate_AuxiliaryCost_Cost_Modal(true)
	}
	function submit () {
		confirmAlert({
		  title: '',
		  message: 'Xoá chi phí',
		  buttons: [
			{
			  label: 'Có',
			  onClick: async () => {
			  	deleteAuxiliaryCost(_idAuxiliaryCost)
			  	const historyChangeAuxiliaryCost = {
				    "requester": _id,
				    "contract": params.id,
				    "form": "auxiliary-cost",
				    "action": "delete",
				    "data_change": "delete " + JSON.stringify(_idAuxiliaryCost.idCost)
				}

				const { successHis, messageHis } = await addHistoryChange(historyChangeAuxiliaryCost)
			  }
			},
			{
				label: 'Không',
				closeOnClick: true
			}
		  ]
		});
	  };

	return (
		<>
			<OverlayTrigger
				overlay={
					<Tooltip id="tooltip">
						Cập nhật
					</Tooltip>
				}
			>
				<Button className='post-button' variant="link" onClick={chooseAuxiliaryCost.bind(this, _idAuxiliaryCost)}>
					<img src={iconEdit} alt='edit' width='24' height='24' />
				</Button>
			</OverlayTrigger>
			<OverlayTrigger
				overlay={
					<Tooltip id="tooltip">
						Xóa
					</Tooltip>
				}
			>
				<Button className='post-button' variant="link" onClick={submit}>
					<img src={iconDelete} alt='delete' width='24' height='24' />
				</Button>
			</OverlayTrigger>
		</>
	)
}

export default ActionButtons_AuxiliaryCost
