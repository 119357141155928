import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Dropdown from 'react-bootstrap/Dropdown'
import logoutIcon from '../../assets/logout.svg'
/* import NavDropdown from 'react-bootstrap/NavDropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import learnItLogo from '../../assets/logo.svg'*/
import Button from 'react-bootstrap/Button' 
import { Link } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'
import { useContext } from 'react'
import React, { useState, useEffect } from 'react';
const Footer = () => {
	return (
	    <footer className='footer' style={{'zIndex':'1000'}}>
	        <p>Copyright &copy; HPT <span id="current-year"></span>. All rights reserved.</p>
	    </footer>
	);
}

export default Footer
