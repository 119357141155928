import playIcon from '../../../assets/play-btn.svg'
import editIcon from '../../../assets/pencil.svg'
import deleteIcon from '../../../assets/trash.svg'
import Button from 'react-bootstrap/Button'
// import { CenterContext } from '../../contexts/CenterContext'
import { useContext } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { ConfigCenterContext } from '../../../contexts/ConfigCenterContext'

const ActionButtons_Center = (_id) => {
	const {delete_Center, Data_update, setData_update, setShowUpdateCenterModal } = useContext(
		ConfigCenterContext
	) 
	const chooseCenter = _id => {
		setData_update(_id)
		setShowUpdateCenterModal(true)
	}
	function submit () {
		confirmAlert({
		  title: 'Xoá trung tâm',
		  message: '',
		  buttons: [
			{
			  label: 'Có',
			  onClick: () => delete_Center(_id)
			},
			{
			  label: 'Không',
			  onClick: () => closeDialog()
			}
		  ]
		});
	  };
	  const closeDialog = () => {
		setShowUpdateCenterModal(false)
	}
	return (
		<>
			
			<Button className='post-button' onClick={chooseCenter.bind(this, _id)}>
				<img src={editIcon} alt='edit' width='24' height='24' />
			</Button>
			<Button className='post-button' onClick={submit}>
				<img src={deleteIcon} alt='delete' width='24' height='24' />
			</Button>
		</>
	)
}

export default ActionButtons_Center
