import Button from 'react-bootstrap/Button'
import plus_circle_fill from '../../assets/plus_circle_fill.svg'
import editIcon from '../../assets/pencil.svg'

import deleteIcon from '../../assets/trash.svg'
import { ImplementationCostContext } from '../../contexts/ImplementationCostContext'
import { useContext } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import { useParams } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';

import iconEdit from '../../assets/icon-edit.svg'
import iconDelete from '../../assets/icon-delete.svg'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { AuthContext } from '../../contexts/AuthContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'

//Ok
//Nut cap nhat - xoá 1 Category!!!
export const Button_Update_Delete_Category = (Category) => {
	const params = useParams();
	// console.debug('params', Category)
	// Category.idContract = params.id;
	const {
		setData_update_Category,
		setshowUpdate_Category_Modal,
		delete_Category
	} = useContext(ImplementationCostContext)
	
	const click_Button_Update = (Category) => {
		setData_update_Category(Category)
		setshowUpdate_Category_Modal(true)
	}


	function submit() {
		confirmAlert({
			title: '',
			message: 'Xoá hạng mục chi phí',
			buttons: [
				{
					label: 'Có',
					onClick: () => delete_Category(Category, params.id)
				},
				{
					label: 'Không',
					closeOnClick: true
				}
			]
		});
	};
	return (
		<>
			{
				(Category.Category == 'a_chi_phi_chung' || Category.Category == 'b_chi_phi_trien_khai') ? 
				<></>
				:
				<Button className='post-button' onClick={click_Button_Update.bind(this, Category)}>
					<img src={editIcon} alt='edit' width='24' height='24' />
				</Button>
			}
			<Button className='post-button' onClick={submit}>
				<img src={deleteIcon} alt='delete' width='24' height='24' />
			</Button>
		</>
	)
}
//Ok
//Nut Them Giai doan trong Category
export const Button_AddStage = (Category) => {
	const {
		setData_update_Category,
		setshowAddStage_Modal
	} = useContext(ImplementationCostContext)
	
	const click_Button_AddStage = (Category) => {
		setData_update_Category(Category)
		setshowAddStage_Modal(true)
	}
	return (
		<>
			<Button style={{cssFloat:'right', right: '10px', position: 'relative'}} onClick={click_Button_AddStage.bind(this, Category)}>
				{/* <img src={plus_circle_fill} alt='edit' width='24' height='24' /> */}
				Thêm giai đoạn
			</Button>
		</>
	)
}

//Ok
//Nut cap nhat - xoá 1 giai doan chi phi !!!
export const Button_Update_Delete_Stage_Content = (Stage_Content) => {
	const params = useParams();

	const {
		setshowUpdateStage_Modal,
		setData_update_Stage_Content,
		delete_Stage

	} = useContext(ImplementationCostContext)

	// Contexts
	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)
	
	const click_Button_Update_Stage_Content = (Stage_Content) => {
		setData_update_Stage_Content(Stage_Content)
		setshowUpdateStage_Modal(true)
	}

	function submit() {
		console.log('submit delete_Stage Stage_Content', Stage_Content)

		confirmAlert({
			title: '',
			message: 'Xoá giai đoạn chi phí ?',
			buttons: [
				{
					label: 'Có',
					onClick: async () => { 
						delete_Stage(Stage_Content)
						const historyChangeImplementationCostsStage_Content = {
						    "requester": _id,
						    "contract": params.id,
						    "form": "implementation-cost-stage-content",
						    "action": "delete",
						    "data_change": "delete " + Stage_Content.Content
						}
						const { successHis, messageHis } = await addHistoryChange(historyChangeImplementationCostsStage_Content)
					}
				},
				{
					label: 'Không',
					closeOnClick: true
				}
			]
		});
	};
	return (
		<>
			<OverlayTrigger
				overlay={
					<Tooltip id="tooltip">
						Cập nhật
					</Tooltip>
				}
			>
				<Button className='post-button' variant="link" onClick={click_Button_Update_Stage_Content.bind(this, Stage_Content)}>
					<img src={iconEdit} alt='edit' width='24' height='24' />
				</Button>
			</OverlayTrigger>
			<OverlayTrigger
				overlay={
					<Tooltip id="tooltip">
						Xóa
					</Tooltip>
				}
			>
				<Button className='post-button' variant="link" onClick={submit}>
					<img src={iconDelete} alt='delete' width='24' height='24' />
				</Button>
			</OverlayTrigger>
		</>
	)
}
//Ok
//Nut them chi phi trong giai doan chi phi
export const Button_Add_CostDetail = (_id) => {
	const {
		setshowAdd_CostDetail_Modal,     //Goi modal         
		setData_Click_On_Button			 //Get Params truyen cho Url
	} = useContext(ImplementationCostContext)

	const Add_StageCostDetail = ImplementationCostId => {
		setshowAdd_CostDetail_Modal(true)//note
		setData_Click_On_Button(_id)
		// console.log("Button_Add_CostDetail id bat dc", _id)

	}
	return (
		<>
			<Button style={{cssFloat:'right'}} onClick={Add_StageCostDetail.bind(this, _id)}>
				{/* <img src={plus_circle_fill} alt='edit' width='34' height='34' /> */}
				Thêm chi phí
			</Button>
		</>
	)
}

//Nut cap nhat - xoá 1 chi phi trong chi phi trien khai
export const Button_Update_Delete_CostDetail = (_idCostDetail) => {
	const params = useParams();

	const {
		setshowUpdate_CostDetail_Modal,
		setDataUpdate_Click_On_Button,
		delete_CostDetail_Function

		
	} = useContext(ImplementationCostContext)

	// Contexts
	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	const click_Button_Update = (_idCostDetail) => {
		setDataUpdate_Click_On_Button(_idCostDetail)
		setshowUpdate_CostDetail_Modal(true)
	}


	function submit() {
		confirmAlert({
			title: '',
			message: 'Xoá chi phí',
			buttons: [
				{
					label: 'Có',
					onClick: async () => {
						delete_CostDetail_Function(_idCostDetail)
						const historyChangeImplementationCosts = {
						    "requester": _id,
						    "contract": params.id,
						    "form": "implementation-cost",
						    "action": "delete",
						    "data_change": "delete " + _idCostDetail.idCost
						}
						
						const { successHis, messageHis } = await addHistoryChange(historyChangeImplementationCosts)
					}
				},
				{
					label: 'Không',
					closeOnClick: true
				}
			]
		});
	};
	return (
		<>
			<OverlayTrigger
				overlay={
					<Tooltip id="tooltip">
						Cập nhật
					</Tooltip>
				}
			>
				<Button className='post-button' variant="link" onClick={click_Button_Update.bind(this, _idCostDetail)}>
					<img src={iconEdit} alt='edit' width='24' height='24' />
				</Button>
			</OverlayTrigger>
			<OverlayTrigger
				overlay={
					<Tooltip id="tooltip">
						Xóa
					</Tooltip>
				}
			>
				<Button className='post-button' variant="link" onClick={submit}>
					<img src={iconDelete} alt='delete' width='24' height='24' />
				</Button>
			</OverlayTrigger>
		</>
	)
}

