import { createContext, useReducer, useState } from 'react'
import { HistoryReducer } from '../reducers/HistoryReducer'
import {
	apiUrl,
	LOADED_FAIL,
	LOADED_SUCCESS,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from './constants'//Note
import axios from 'axios'

export const HistoryContext = createContext()

const HistoryContextProvider = ({ children }) => {
	const [HistoryState,  dispatch] = useReducer(HistoryReducer, {
		History: null,
		Historys: [],
		HistorysLoading: true
	});

	const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false)
	const [showUpdateStatusModal1, setShowUpdateStatusModal1] = useState(false)
	const [showUpdateStatusModal2, setShowUpdateStatusModal2] = useState(false)

	// Get all History
	const getHistory = async () => {
		try {
			const response = await axios.get(`${apiUrl}/api/forms/history`)
			if (response.data.success) {
				dispatch({ type: LOADED_SUCCESS, payload: response.data.History })	
			}
		} catch (error) {
			dispatch({ type: LOADED_FAIL })
		}
	}

	const updateStatusContract = async Contract => {
		try {
			const response = await axios.post(`${apiUrl}/api/forms/history/post`, Contract)//note Users
			if (response.data.success) {
				// dispatch({ type: ADD, payload: response.data.Contract }) //note Users
				getHistory();
				return response.data
			}

		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}

	const addReasonRefuseContract = async Contract => {
		try {
			const response = await axios.post(`${apiUrl}/api/forms/history/post`, Contract)//note Users
			if (response.data.success) {
				// dispatch({ type: ADD, payload: response.data.Contract }) //note Users
				getHistory();
				return response.data
			}

		} catch (error) {
			return error.response.data
				? error.response.data
				: { success: false, message: 'Server error' }
		}
	}




	// History context data
	const HistoryContextData = {
		HistoryState,
		getHistory,
		updateStatusContract,
		showUpdateStatusModal,
		setShowUpdateStatusModal,
		showUpdateStatusModal1,
		setShowUpdateStatusModal1,
		showUpdateStatusModal2,
		setShowUpdateStatusModal2,
		addReasonRefuseContract
	}

	return (
		<HistoryContext.Provider value={HistoryContextData}>
			{children}
		</HistoryContext.Provider>
	)
}

export default HistoryContextProvider
