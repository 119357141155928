import { useParams, Link } from 'react-router-dom';
import { MenuContext } from '../contexts/MenuContext'//Note GET DELETE
import { useContext, useEffect } from 'react'
import { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Toast from 'react-bootstrap/Toast'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Col from 'react-bootstrap/Col'

// import DateRangePicker from 'react-bootstrap-daterangepicker';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Contexts
import { AuthContext } from '../contexts/AuthContext'
import { RoleContext } from '../contexts/RoleContext'
import { ConfigCenterContext } from '../contexts/ConfigCenterContext'
import { ConfigDepartmentContext } from '../contexts/ConfigDepartmentContext'
import { ConfigCustomerContext } from '../contexts/ConfigCustomerContext'
import { ContractContext } from '../contexts/ContractContext'
import { UserContext } from '../contexts/UserContext'
import { HistoryContext } from '../contexts/HistoryContext'
import { EventContext } from '../contexts/EventContext'
import { ProductCostContext } from '../contexts/ProductCostContext'
import { MandayCostContext } from '../contexts/MandayCostContext'
import { GuaranteeLetterCostContext } from '../contexts/GuaranteeLetterCostContext'
import { MiscExpenseCostContext } from '../contexts/MiscExpenseContext'
import { CapitalExpenditureCostContext } from "../contexts/CapitalExpenditureCostContext"
import { LoanInterestContext } from "../contexts/LoanInterestContext"
import { AuxiliaryCostContext } from '../contexts/AuxiliaryCostContext'
import { ImplementationCostContext } from '../contexts/ImplementationCostContext'
import { InvoiceContext } from '../contexts/InvoiceContext'
import { UserManagerContext } from '../contexts/UserManagerContext'
import { UserPageContext } from '../contexts/UserPageContext'
import iconViewReport from '../assets/icon-view-report.svg'
import iconFilterReset from '../assets/icon-filter-reset.svg'

export const Report = () => {
	//
	const {
		UserState: { User, Users, UsersLoading },
		getUsers
	} = useContext(UserContext)

	const {
		HistoryState: { History, Historys, HistorysLoading },
		getHistory
	} = useContext(HistoryContext)

	const {
		EventState: { Event, Events, EventsLoading },
		getEvent
	} = useContext(EventContext)

	//=== Get All Productcosts
	const {
		ProductCostState: { ProductCost, ProductCosts, ProductCostsLoading },
		getProductCosts,
	} = useContext(ProductCostContext)

	//=== Get MandayCost 
	const {
		MandayCostState: { MandayCost, MandayCosts, MandayCostsLoading },
		getMandayCosts,
	} = useContext(MandayCostContext)

	//=== Get GuaranteeLetterCost
	const {
		GuaranteeLetterCostState: { GuaranteeLetterCost, GuaranteeLetterCosts, GuaranteeLetterCostsLoading },
		getGuaranteeLetterCosts,
	} = useContext(GuaranteeLetterCostContext)

	//=== Get MiscExpenseCosts
	const {
		MiscExpenseCostState: { MiscExpenseCost, MiscExpenseCosts, MiscExpenseCostsLoading },
		getMiscExpenseCosts,
	} = useContext(MiscExpenseCostContext)

	//=== Get data CapitalExpenditureCost chi phi von
	const {
		CapitalExpenditureCostState: { CapitalExpenditureCost, CapitalExpenditureCosts, CapitalExpenditureCostsLoading },
		getCapitalExpenditureCosts,
	} = useContext(CapitalExpenditureCostContext);

	//=== Get data LoanInterest chi phi lãi vay
	const {
	    LoanInterestState: { LoanInterest, LoanInterests, LoanInterestsLoading },
	    getLoanInterest
	} = useContext(LoanInterestContext);
	
	//=== Get data AuxiliaryCost
	const {
		AuxiliaryCostState: { AuxiliaryCosts },
		getAuxiliaryCosts
	} = useContext(AuxiliaryCostContext)

	//=== Get data ImplementationCost
	const {
		ImplementationCostState: { ImplementationCosts },
		getImplementationCost
	} = useContext(ImplementationCostContext)

	//== Get data Invoices
	const {
		InvoiceState: { Invoices },
		getAllInvoice
	} = useContext(InvoiceContext)

	//== Get data Centers
	const {
		CenterState: { Centers, CentersLoading },
		get_All_Center
	} = useContext(ConfigCenterContext)

	//== Get data Departments
	const {
		DepartmentState: { Department, Departments, DepartmentsLoading },
		getDepartment
	} = useContext(ConfigDepartmentContext)

	//== Get data Customers
	const {
		CustomerState: { Customer, Customers, CustomersLoading },
		get_All_Customer
	} = useContext(ConfigCustomerContext)

	//== Get data Contracts
	const {
		ContractState: { Contract, Contracts, ContractsLoading },
		getContracts,
		showToast: { show, message, type },
		setShowToast,
		showToast,
	} = useContext(ContractContext)
	//== Get data auth
	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)
	//== Get data Roles
	const {
		RoleState: { Role, Roles, RolesLoading },
		getRoles,
	} = useContext(RoleContext)

	const {
	    MenuState: { Menu, Menus, MenusLoading },
	    getMenu,
	} = useContext(MenuContext);
	//== Get data UserManager
	const {
		UserManagerState: { UserManager, UserManagers, UserManagersLoading },
		getAllUserManagers
	} = useContext(UserManagerContext)
	
	useEffect(() => get_All_Center(), [])
	useEffect(() => getDepartment(), [])
	useEffect(() => get_All_Customer(), [])
	useEffect(() => getUsers(), [])
	useEffect(() => getHistory(), [])
	useEffect(() => getEvent(), [])
	useEffect(() => getProductCosts(), [])
	useEffect(() => getMandayCosts(), [])
	useEffect(() => getGuaranteeLetterCosts(), [])
	useEffect(() => getMiscExpenseCosts(), [])
	useEffect(() => getCapitalExpenditureCosts(), [])
	useEffect(() => getLoanInterest(), [])
	useEffect(() => getAuxiliaryCosts(), [])
	useEffect(() => getImplementationCost(), [])
	useEffect(() => getAllInvoice(), [])
	useEffect(() => getContracts(), [])
	useEffect(() => getRoles(), [])
	useEffect(() => getAllUserManagers(), [])

	const [newContracts, setNewContracts] = useState([])
	const [isSearch, setIsSearch] = useState(false)
	let role_new = roles[0];
	let UserID = _id;
	let roleName = FindArray(Roles, role_new).name;
	// console.log("Report", role_new, UserID, roleName, MiscExpenseCosts)
	let newContractsss;
	let newContract111 = [];
	if(roleName == 'Admin' || roleName == 'BTGD'){
		newContractsss = Contracts
	}else if(roleName == 'KTT' || roleName == 'BGD'){
		let centerOfUser = filter_Users(Users,UserID).centers;
		if (centerOfUser != undefined) {
			if(Contracts.length > 0){
				newContract111 = filter_new_contract(Contracts,centerOfUser, role_new);
				// console.debug(123, centerOfUser, newContract111)
			}
		}
		newContractsss = newContract111;
	}else{
		// console.log(UserManagers,UserID,roleName,get_user_id(UserID,UserManagers),'sdsd')
		let newContract1 = Contracts.filter((v) => get_user_id(UserID,UserManagers).includes(v.user._id))
		if(roleName == 'AM'){
			newContractsss = newContract1;
		}else{
			let contract_me = Contracts.filter((v) => v.user._id == UserID)

			let newContract2 = newContract1.concat(contract_me);
			newContractsss = newContract2;
			// console.log("newContract1", roleName, newContract1, newContractsss)
		}
	}

	// console.log(111111111111, newContractsss,"==========newContractReport==========")

	function filter_new_contract(array,UserID){
		let returnObject = "";
		// if (UserID != undefined) returnObject = array.filter((element) => element.user.centers != null ? element.user.centers[0] : '' == UserID[0]);
		if (UserID != undefined) returnObject = array.filter((element) => element.CenterID == UserID[0]);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	function get_user_id(userLogin, userManager) {
		let managedUsers = [];
		// managedUsers.push(userLogin)

		function findManagedUsers(managerId) {
			let users = userManager.filter(item => item.manager_id[0] === managerId);
			if (users.length > 0) {
				for (let user of users) {
					findManagedUsers(user.user_id[0]);
				}
			} else {
				managedUsers.push(managerId);
			}
		}

		findManagedUsers(userLogin);
		return managedUsers;
	}

	function filter_Users(array,UserID){
		let returnObject = "";
		if (UserID != undefined) returnObject = array.filter((element) => element._id === UserID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return '';
		}
	}


	function filter_history(array,ContractID){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return '';
		}
	}
	//
	function totalCenter() {
		let totalCenter = 0;
		if (typeof Centers != undefined) totalCenter = Centers.length;
		return totalCenter;
	}

	function totalDepartment() {
		let totalDepartment = 0;
		if (typeof Departments != undefined) totalDepartment = Departments.length;
		return totalDepartment;
	}

	function totalContract() {
		let totalContract = 0;
		if (typeof Contracts != undefined) totalContract = Contracts.length;
		return totalContract;
	}

	function totalUser() {
		let totalUser = 0;
		if (typeof Users != undefined) totalUser = Users.length;
		return totalUser;
	}

	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id == ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	// table ranking
	//Ham tinh tong Phan tử trong kieu mang 
	function Sum_InputIntoMoney(ProductsCost) {
		let Total_InputIntoMoney = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_InputIntoMoney += ListProduct.InputIntoMoney
			)))

		return Total_InputIntoMoney;
	}
	// input into money with check mua nội bộ
	function Sum_InputIntoMoneyTypePurchase(ProductsCost, InternalPurchase) {
		let Total_InputIntoMoney = 0;

		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_InputIntoMoney += (InternalPurchase == 1) 
					? (ListProduct.InternalPurchase == true) ? ListProduct.InputIntoMoney : 0
					: (ListProduct.InternalPurchase == false) ? ListProduct.InputIntoMoney : 0
			)))

		return Total_InputIntoMoney;
	}
	//Ham tinh tong Phan tử trong kieu mang 
	function Sum_OutputIntoMoney(ProductsCost) {
		let Total_OutputIntoMoney = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_OutputIntoMoney += ListProduct.OutputIntoMoney
			)))

		return Total_OutputIntoMoney;
	}

	//Tinh tong thanh tien gia ban voi Insurance = true
	function Sum_Insurance(ProductsCost) {
		let Total_Insurance = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				ListProduct.Insurance === true ? Total_Insurance += ListProduct.OutputIntoMoney : 0
			)))

		return Total_Insurance;
	}

	function Sum_InsuranceHSE(ProductsCost) {
		let Total_InsuranceHSE = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				ListProduct.InsuranceHSE === true ? Total_InsuranceHSE += (ListProduct.OutputIntoMoney*((ListProduct.PercentHSE != null ? ListProduct.PercentHSE : 1) / 100)) : 0
			)))
		return Total_InsuranceHSE;
	}

	// hàm tính tổng thành tiền
	function sumArray(mang) {
		let sum = 0;
		mang.map(function (value) { sum += value; });
		return sum;
	}
	//hàm tìm data theo contract id
	function find_data_by_contract_id(data, idContract, children = null) {
		let rs = [];
		if (children == 1) {
			rs = data.filter(x => (x.contract != null && x.contract._id == idContract))
		}else {
			rs = data.filter(x => x.contract == idContract)
		}
		return rs
	}

	// hàm đổi giá trị chi phí % => giá trị tiền tuyệt đối
	function changeCost(Cost, TotalOutputIntoMoney) {
		let returnValue = Cost;
		if (Cost < 1)
			returnValue = Cost * TotalOutputIntoMoney;
		return returnValue
	}

	function SumListCost(Auxiliary, TotalOutputIntoMoney) {
		let kq = 0;
		Auxiliary.map(AuxiliaryCost =>
			AuxiliaryCost.ListCosts.map(ListCost => (
				kq += changeCost(ListCost.Cost, TotalOutputIntoMoney)
			)))

		return kq;
	}
	
	function FindPlan(Auxiliary) {
		let returnPlan = 0;
		Auxiliary.map(AuxiliaryCost => returnPlan = AuxiliaryCost.Plan
		)
		return returnPlan;
	}

	function totalAuxiliaryCost(AuxiliaryCosts, TotalOutputIntoMoney){
		let sumCostTaxA = 0;
		let sumCostTaxB = 0;
		
		let sumCostA = 0;
		let sumCostB = 0;
		
		let sumTaxA = 0;
		let sumTaxB = 0;
		
		AuxiliaryCosts.map(AuxiliaryCost => (
			AuxiliaryCost.ListCosts.map(function(ListCost, index) {
				var newCost = 0
				var newTax = 0
				var newCostTax = 0
				if (AuxiliaryCost.Plan == 1){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost * ListCost.TaxRate)/100).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}else if (AuxiliaryCost.Plan == 2){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost / (100 - ListCost.TaxRate)) * ListCost.TaxRate).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostA += parseInt(newCost);
				}else if (AuxiliaryCost.Plan == 2){
					sumCostB += parseInt(newCost);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumTaxA += parseInt(newTax);
				}else if (AuxiliaryCost.Plan == 2){
					sumTaxB += parseInt(newTax);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostTaxA += parseInt((newCost+newTax));
				}else if (AuxiliaryCost.Plan == 2){
					sumCostTaxB += parseInt((newCost+newTax));
				}
			})
		))
		let muc2_1 = parseInt((sumCostA + sumCostB)/10);
		let muc2_thue = parseInt((sumTaxA + sumTaxB)/10);
		let muc2 = parseInt(muc2_1 + muc2_thue);
		return {muc2, muc2_1, muc2_thue}
	}

	function totalAuxiliaryCosts(AuxiliaryCosts, TotalOutputIntoMoney){
		let sumCostTaxA = 0;
		let sumCostTaxB = 0;
		
		let sumCostA = 0;
		let sumCostB = 0;
		
		let sumTaxA = 0;
		let sumTaxB = 0;
		
		AuxiliaryCosts.map(AuxiliaryCost => (
			AuxiliaryCost.ListCosts.map(function(ListCost, index) {
				var newCost = 0
				var newTax = 0
				var newCostTax = 0
				if (AuxiliaryCost.Plan == 1){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost * ListCost.TaxRate)/100).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}else if (AuxiliaryCost.Plan == 2){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost / (100 - ListCost.TaxRate)) * ListCost.TaxRate).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostA += parseInt(newCost);
				}else if (AuxiliaryCost.Plan == 2){
					sumCostB += parseInt(newCost);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumTaxA += parseInt(newTax);
				}else if (AuxiliaryCost.Plan == 2){
					sumTaxB += parseInt(newTax);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostTaxA += parseInt((newCost+newTax));
				}else if (AuxiliaryCost.Plan == 2){
					sumCostTaxB += parseInt((newCost+newTax));
				}
			})
		))

		let sumCostAB = parseInt(sumCostA + sumCostB);
		let sumTaxAB = parseInt(sumTaxA + sumTaxB);
		let sumCostTaxAB = parseInt(sumCostTaxA + sumCostTaxB);
		let muc2_1 = parseInt((sumCostA + sumCostB)/10);
		let muc2_thue = parseInt((sumTaxA + sumTaxB)/10);
		let muc2 = parseInt(muc2_1 + muc2_thue);
		return {sumCostAB, sumTaxAB, sumCostTaxAB, muc2, muc2_1, muc2_thue}
	}

	function TotalStageImplementation(ImplementationCosts) {
		let All_Total = 0;
		ImplementationCosts.map((element) => (
			element.StagesImplementation.map((object) => (
				object.Costs.map((Costs) =>
					All_Total += Costs.IntoMoney
				)))))
		return All_Total;
	}

	function TotalStageImplementationAB(ImplementationCosts, type = null) {
		let All_Total = 0;
		let ImplementationCostNew = "";
		ImplementationCostNew = ImplementationCosts.filter(x => x.Category == type);
		ImplementationCostNew.map((element) => (
			element.StagesImplementation.map((object) => (
				object.Costs.map((Costs) =>
					All_Total += Costs.IntoMoney
				)))))
		return All_Total;
	}

	function TotalCostAuxiliaryCosts(AuxiliaryCosts) {
		let All_Total = 0;
		AuxiliaryCosts.map((element) => {
			element.ListCosts.map((Costs) => {
				All_Total += Costs.Cost
			})
		})
		return All_Total;
	}

	function TotalTaxAuxiliaryCosts(AuxiliaryCosts) {
		let All_Total = 0;
		AuxiliaryCosts.map((element) => {
			element.ListCosts.map((Costs) => {
				All_Total += Costs.Tax
			})
		})
		return All_Total;
	}

	function Sum_Incentive(ProductsCost) {
		let Total_Incentive = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_Incentive += ListProduct.Incentive
			)))

		return Total_Incentive;
	}

	function SumPercentInvoice(Invoices) {
		let Total_PercentInvoice = 0;
		Invoices.map(invoice => (
			Total_PercentInvoice += invoice.InvoiceVal
		))

		return Total_PercentInvoice;
	}

	useEffect(() => {
		// let rs = loopReport()
		setNewContracts([])
	}, [Contracts]);
	////////////////////////
	
	function loopReport(newContract = null, isTotal = false) {
		let newContracts = newContract == null ? newContractsss : newContract
		let array_result = []

		//
		let Doanh_thu = 0
		let Gia_von_ben_ngoai = 0
		let Gia_von_noi_bo = 0
		let Incentive = 0
		let CP_bao_hanh_HSC = 0
		let CP_ho_tro_ky_thuat_HSE = 0
		let CP_trien_khai = 0
		let CP_von = 0
		let CP_manday = 0
		let CP_bao_lanh = 0
		let CP_khac = 0
		let CP_vat_tu_phu = 0
		let Thue = 0
		let Tong_CP = 0
		let Lai_gop_chua_co_incentive = 0
		let Lai_gop_da_bao_gom_incentive = 0
		let Doanh_thu_con_lai_chua_xuat_HD = 0
		//

		newContracts.map(function (Contract, i){
			let ContractID = Contract.ContractID
			let RatioUSD = Contract.RatioUSD
			let VAT = Contract.VAT
			let STT = parseInt(i+1);
			let findCenters = (findArrayItem(Centers, Contract.CenterID) != undefined) ? findArrayItem(Centers, Contract.CenterID) : findArrayItem(Centers, Contract.CenterID)
			let findDepartments = (findArrayItem(Departments, Contract.DepartmentID) != undefined) ? findArrayItem(Departments, Contract.DepartmentID): findArrayItem(Departments, Contract.DepartmentID)
			let findCustomers = (findArrayItem(Customers, Contract.CustomerID) != undefined) ? findArrayItem(Customers, Contract.CustomerID): findArrayItem(Customers, Contract.CustomerID)
	
			let CenterID = findCenters != undefined ? findCenters.CenterName.trim() : Contract.CenterID
			let DepartmentID = findDepartments != undefined ? findDepartments.DepartmentName : Contract.DepartmentID
			let CustomerID = findCustomers != undefined ? findCustomers.CustomerName : Contract.CustomerID
			let user = newContract == null ? Contract.user.username : Contract.user
			let DateTemp = convert_time(Contract.Date != null ? Contract.Date : getCurrentDate("-"))
			// let StatusID = get_status(Historys,Contract._id) != '' ? get_status(Historys,Contract._id).StatusID : '0'
			// let StatusName = get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys,Contract._id,Events) : ''

			//ProductCost
			let ProductCost_idContract = find_data_by_contract_id(ProductCosts, Contract._id); // contract
			const TotalInsurance = 0.006 * Sum_Insurance(ProductCost_idContract)
			const TotalInsuranceHSE = Sum_InsuranceHSE(ProductCost_idContract)
			const TotalInputIntoMoney = Sum_InputIntoMoney(ProductCost_idContract)
			const TotalOutputIntoMoney = Sum_OutputIntoMoney(ProductCost_idContract)
			let TotalIncentive = 0;
			if(ProductCost_idContract[0] !== undefined){
				TotalIncentive = sumArray(ProductCost_idContract[0].ListProducts.map((ProductCost) => ProductCost.Incentive))//note
			}

			// MandayCost
			let MandayCost_idContract = find_data_by_contract_id(MandayCosts, Contract._id); // contract
			const TotalMandayCost = sumArray(MandayCost_idContract.map((MandayCost) => MandayCost.IntoMoney))
			//

			//GuaranteeLetterCost
			let GuaranteeLetterCost_idContract = find_data_by_contract_id(GuaranteeLetterCosts, Contract._id); // contract
			const TotalGuaranteeLetterCost = sumArray(GuaranteeLetterCost_idContract.map((GuaranteeLetterCost) => GuaranteeLetterCost.IntoMoney))

			//MiscExpenseCosts
			let MiscExpenseCost_idContract = find_data_by_contract_id(MiscExpenseCosts, Contract._id, 1);
			const TotalMiscExpenseCost = sumArray(MiscExpenseCost_idContract.map((MiscExpenseCost) => MiscExpenseCost.Cost))

			//CapitalExpenditureCosts
			let CapitalExpenditureCost_idContract = find_data_by_contract_id(CapitalExpenditureCosts, Contract._id);
			// let newCapitalExpenditureCost = sumArray(CapitalExpenditureCost_idContract.map((CapitalExpenditureCost) => CapitalExpenditureCost.CapitalExpense))//ch
			// let TotalCapitalExpense = ((CapitalExpenditureCost_idContract.InventoryDays + CapitalExpenditureCost_idContract.ImplementationDays - CapitalExpenditureCost_idContract.BedtDays) * CapitalExpenditureCost_idContract.CapitalCost + CapitalExpenditureCost_idContract.DebtCollectionDays * (CapitalExpenditureCost_idContract.Revenue - CapitalExpenditureCost_idContract.Deposits + CapitalExpenditureCost_idContract.DepositsNTP)) * (CapitalExpenditureCost_idContract.PercentInterest /100) / 365;
			let newCapitalExpenditureCost = {
				CapitalCost: 0, 
			    Revenue: 0,
			    CapitalExpense: 0,
			    InventoryDays: 15,
			    ImplementationDays: 30,
			    BedtDays: 30,
			    DebtCollectionDays: 15,
			    Deposits: 0,
			    DepositsNTP: 0,
			    PercentInterest: 10,
			    Note: '',
				ContractID: Contract._id
			};

			// Load data tu Form 1: Lay tong gia von va Doanh thu
	        newCapitalExpenditureCost.CapitalCost = TotalInputIntoMoney;
	        newCapitalExpenditureCost.Revenue = TotalOutputIntoMoney;
	        //Tinh Dat coc cua khach hang
			newCapitalExpenditureCost.Deposits = newCapitalExpenditureCost.Revenue * 0.2
			//Tinh Dat coc NTP
			newCapitalExpenditureCost.DepositsNTP = newCapitalExpenditureCost.CapitalCost * 0.3
			//Tinh chi phi von
		    newCapitalExpenditureCost.CapitalExpense = (typeof CapitalExpenditureCost_idContract[0] != "undefined") ? ((CapitalExpenditureCost_idContract[0].InventoryDays + CapitalExpenditureCost_idContract[0].ImplementationDays - CapitalExpenditureCost_idContract[0].BedtDays) * newCapitalExpenditureCost.CapitalCost + newCapitalExpenditureCost.DebtCollectionDays * (newCapitalExpenditureCost.Revenue - newCapitalExpenditureCost.Deposits + newCapitalExpenditureCost.DepositsNTP)) * (CapitalExpenditureCost_idContract[0].PercentInterest /100) / 365 : 0 ;
		    let TotalCapitalExpense = newCapitalExpenditureCost.CapitalExpense
			// console.debug("CapitalExpenditureCosts newCapitalExpenditureCost::::::", newCapitalExpenditureCost)

			//LoanInterests
			let LoanInterests_idContract = find_data_by_contract_id(LoanInterests, Contract._id);
			const TotalLoanInterest = sumArray(LoanInterests_idContract.map((LoanInterest ) => LoanInterest.InterestLoan))

			let chiPhi = 0;
			if(TotalCapitalExpense != 0){
				chiPhi = TotalCapitalExpense;
			}else{
				if(TotalLoanInterest != 0){
					chiPhi = TotalLoanInterest;
				}
			}

			//AuxiliaryCosts
			let AuxiliaryCost_idContract = find_data_by_contract_id(AuxiliaryCosts, Contract._id, 1);
			const TotalCost = SumListCost(AuxiliaryCost_idContract, TotalOutputIntoMoney);
			const rsTotalAuxiliaryCost = totalAuxiliaryCosts(AuxiliaryCost_idContract, TotalOutputIntoMoney)
			//ImplementationCost
			let ImplementationCost_idContract = find_data_by_contract_id(ImplementationCosts, Contract._id);
			const ImplementationCost = TotalStageImplementation(ImplementationCost_idContract);

			// 1.1 Chi phí triển khai hợp đồng + Chi phi Bảo hiểm
			const TotalImplementationCost = ImplementationCost + TotalInsurance + TotalInsuranceHSE;
			// 1. chi phi phat sinh khi thuc hien du an
			const ExtraCost = (TotalImplementationCost + chiPhi + TotalMandayCost + TotalGuaranteeLetterCost + TotalMiscExpenseCost)
			// 2.
			// 3.
			const projectEfficiency = (TotalOutputIntoMoney - TotalInputIntoMoney - ExtraCost - (rsTotalAuxiliaryCost.muc2*10))
			// console.log("rsTotalAuxiliaryCost", rsTotalAuxiliaryCost, "projectEfficiency", projectEfficiency)
			// Contracts[i].TotalInputIntoMoney = TotalInputIntoMoney //Chi phí
			// Contracts[i].projectEfficiency = Math.round(projectEfficiency) //Hiệu quả dự án
			// Contracts[i].TotalOutputIntoMoney = TotalOutputIntoMoney //Tổng doanh thu (8)

			//report 
			let ExternalCapitalCosts = Sum_InputIntoMoneyTypePurchase(ProductCost_idContract, false) //Giá vốn bên ngoài (9)
			let InternalCapitalCosts = Sum_InputIntoMoneyTypePurchase(ProductCost_idContract, true) //Giá vốn bên ngoài (10)
			let TotalSumIncentive = Sum_Incentive(ProductCost_idContract) //Incentive (11)
			let TotalSumInsurance = TotalInsurance //CP bảo hành (HSC) (12)
			// let TotalInsuranceHSE = TotalInsuranceHSE; //CP hỗ trợ kỹ thuật (HSE) (13)

			// CP triển khai (14)
			let GeneralExpenses = TotalStageImplementationAB(ImplementationCost_idContract, "a_chi_phi_chung")
			let DeploymentCosts = TotalStageImplementationAB(ImplementationCost_idContract, "b_chi_phi_trien_khai")
			// Contracts[i].GeneralExpenses = GeneralExpenses; //Giá trị thành tiền của Tổng A (Chi phí chung)
			// Contracts[i].DeploymentCosts = DeploymentCosts; //Giá trị thành tiền Tổng B (Chi phí triển khai)
			let TotalCostAB = (GeneralExpenses + DeploymentCosts); //Giá trị thành tiền Tổng của A và B

			// Contracts[i].TotalInputIntoMoney = TotalInputIntoMoney; //CP vốn (15)
			// Contracts[i].TotalMandayCost = TotalMandayCost; //CP manday (16)
			// Contracts[i].TotalGuaranteeLetterCost = Math.round(TotalGuaranteeLetterCost) //CP bảo lãnh (17)
			// Contracts[i].TotalMiscExpenseCost = Math.round(TotalMiscExpenseCost) // CP khác (18)

			let SumAuxiliaryCost = rsTotalAuxiliaryCost.muc2 //sumCostAB //CP tiền A+B (19)
			let SumAuxiliaryTax = rsTotalAuxiliaryCost.muc2_thue //sumTaxAB //CP Thuế A+B: (20)
			let SumAuxiliaryCostTax = rsTotalAuxiliaryCost.muc2_1 //sumCostTaxAB //CP tiền và thuế AB
			let TotalAuxiliaryAndMiscExpense = 0 //parseInt(TotalMiscExpenseCost + rsTotalAuxiliaryCost.sumCostAB) //Tổng CP: (21) = (18)+(19)

			//Lãi gộp (chưa có incentive): (22) = (8) - SUM(9-18) - (21)
			let GrossProfitNoIncentive = TotalOutputIntoMoney -
										(
											ExternalCapitalCosts +
											DeploymentCosts + 
											TotalSumIncentive + 
											TotalSumInsurance + 
											TotalInsuranceHSE + 
											(GeneralExpenses+DeploymentCosts) + 
											TotalInputIntoMoney + 
											TotalMandayCost + 
											Math.round(TotalGuaranteeLetterCost) + 
											Math.round(TotalMiscExpenseCost)
										)
										- parseInt(TotalMiscExpenseCost + rsTotalAuxiliaryCost.sumCostAB)
			// Contracts[i].GrossProfitNoIncentive = GrossProfitNoIncentive // (22)
			let RatioGrossProfitNoIncentiveAboveRevenue = !isNaN(projectEfficiency/TotalOutputIntoMoney) && TotalOutputIntoMoney != 0 ? ((projectEfficiency/TotalOutputIntoMoney)* 100).toFixed(2): 0 //Tỷ lệ LG/DT: (23) = (22)/(8)
			// let RatioGrossProfitNoIncentiveAboveRevenue = !isNaN(GrossProfitNoIncentive/TotalOutputIntoMoney) && TotalOutputIntoMoney != 0 ? GrossProfitNoIncentive/TotalOutputIntoMoney : 0 //Tỷ lệ LG/DT: (23) = (22)/(8)
			// Lãi gộp (đã bao gồm incentive): (25) = (22)+(24)
			let GrossProfitIncentive = parseInt(projectEfficiency) + parseInt(TotalSumIncentive)
			// let GrossProfitIncentive = parseInt(GrossProfitNoIncentive + Sum_Incentive(ProductCost_idContract)) 
			// Contracts[i].GrossProfitIncentive = GrossProfitIncentive //Tỷ lệ LG/DT: (25) = (22)+(24)
			// let RatioGrossProfitIncentiveAboveRevenue = !isNaN(GrossProfitIncentive/TotalOutputIntoMoney) && TotalOutputIntoMoney != 0 ? GrossProfitIncentive/TotalOutputIntoMoney : 0 //Tỷ lệ LG/DT: (26) = (25)/(8)
			let RatioGrossProfitIncentiveAboveRevenue = !isNaN(((projectEfficiency + TotalSumIncentive) / TotalOutputIntoMoney)) && isFinite(((projectEfficiency + TotalSumIncentive) / TotalOutputIntoMoney)) ? (((projectEfficiency + TotalIncentive) / TotalOutputIntoMoney) * 100).toFixed(2): 0

			//Invoices
			let Invoices_idContract = find_data_by_contract_id(Invoices, Contract._id)
			let Invoices_idContracts = find_data_by_contract_id(Invoices, Contract._id)
			// console.log(Invoices_idContract.length, 111111, Contract.ContractID)
			let PercentInvoices = SumPercentInvoice(Invoices_idContract)
			let SumPercentInvoices = !isNaN(PercentInvoices/TotalOutputIntoMoney) ? PercentInvoices/TotalOutputIntoMoney == 1 ? 100 : ((PercentInvoices/TotalOutputIntoMoney)*100) : 0
			let lastInvoices_idContract = Invoices_idContract.length > 0 ? Invoices_idContract.pop() : ""
			let InvoiceVal = lastInvoices_idContract != "" ? !isNaN(SumPercentInvoices/TotalOutputIntoMoney) ? (SumPercentInvoices/TotalOutputIntoMoney).toFixed(2): 0 : 0
			let DateOfInvoice = lastInvoices_idContract != "" ? convert_time(lastInvoices_idContract.DateOfInvoice) : "" //Ngày xuất hóa đơn (27)
			let PercentInvoice = lastInvoices_idContract != "" ? SumPercentInvoices == 100 ? "100%" : SumPercentInvoices.toFixed(2) + "%" : "0%" //Tỷ lệ % xuất HĐ (28)
			let OutputIntoMoney = lastInvoices_idContract != "" ? SumPercentInvoices == 100 ? 0 : TotalOutputIntoMoney - PercentInvoices : "0" //Doanh thu còn lại chưa xuất HĐ (29)
			let NoteInvoices = Invoices_idContracts.length > 1 ? "Xuất hóa đơn nhiều đợt": "" //Ghi chú (30)
			
			Doanh_thu = Doanh_thu + TotalOutputIntoMoney
			Gia_von_ben_ngoai = Gia_von_ben_ngoai + ExternalCapitalCosts
			Gia_von_noi_bo = Gia_von_noi_bo + InternalCapitalCosts
			Incentive = Incentive + TotalSumIncentive
			CP_bao_hanh_HSC = CP_bao_hanh_HSC + TotalSumInsurance
			CP_ho_tro_ky_thuat_HSE = CP_ho_tro_ky_thuat_HSE + TotalInsuranceHSE
			CP_trien_khai = CP_trien_khai + TotalCostAB
			CP_manday = CP_manday + TotalMandayCost
			CP_bao_lanh = CP_bao_lanh + TotalGuaranteeLetterCost
			CP_khac = CP_khac + TotalMiscExpenseCost
			CP_vat_tu_phu = CP_vat_tu_phu + SumAuxiliaryCost
			Thue = Thue + SumAuxiliaryTax
			Tong_CP = Tong_CP + parseInt(SumAuxiliaryCostTax)
			Lai_gop_chua_co_incentive = Lai_gop_chua_co_incentive + Math.round(projectEfficiency)
			Lai_gop_da_bao_gom_incentive = Lai_gop_da_bao_gom_incentive + Math.round(GrossProfitIncentive)
			Doanh_thu_con_lai_chua_xuat_HD = Doanh_thu_con_lai_chua_xuat_HD + parseInt(OutputIntoMoney)
			CP_von = CP_von + chiPhi

			array_result = [...array_result, {
				_id: Contract._id,
				STT,
				CenterID,
				DepartmentID,
				CustomerID,
				user,
				ContractID,
				RatioUSD,
				VAT,
				Date: DateTemp,
				TotalInputIntoMoney,
				projectEfficiency,
				TotalOutputIntoMoney,
				GeneralExpenses,
				DeploymentCosts,
				TotalCostAB,
				TotalMandayCost,
				TotalGuaranteeLetterCost,
				SumAuxiliaryCost,
				SumAuxiliaryTax,
				SumAuxiliaryCostTax,
				TotalAuxiliaryAndMiscExpense,
				GrossProfitNoIncentive,
				RatioGrossProfitNoIncentiveAboveRevenue,
				GrossProfitIncentive,
				RatioGrossProfitIncentiveAboveRevenue,
				DateOfInvoice,
				PercentInvoice,
				OutputIntoMoney,
				NoteInvoices,
				SumPercentInvoices,
				TotalSumIncentive,
				TotalSumInsurance,
				ExternalCapitalCosts,
				InternalCapitalCosts,
				TotalInsuranceHSE,
				TotalMiscExpenseCost,
				chiPhi
			}];
		})

		if (isTotal && array_result.length > 0) {
			array_result = [...array_result, {
				_id: Contract._id,
				STT: "Tổng",
				CenterID: "",
				DepartmentID: "",
				CustomerID: "",
				user: "",
				ContractID: "",
				RatioUSD: "",
				VAT: "",
				Date: "",
				TotalInputIntoMoney: "",
				projectEfficiency: Lai_gop_chua_co_incentive,
				TotalOutputIntoMoney: Doanh_thu,
				GeneralExpenses: "",
				DeploymentCosts: "",
				TotalCostAB: CP_trien_khai,
				TotalMandayCost: CP_manday,
				TotalGuaranteeLetterCost: CP_bao_lanh,
				SumAuxiliaryCost: CP_vat_tu_phu,
				SumAuxiliaryTax: Thue,
				SumAuxiliaryCostTax: Tong_CP,
				TotalAuxiliaryAndMiscExpense: "",
				GrossProfitNoIncentive: Lai_gop_chua_co_incentive,
				RatioGrossProfitNoIncentiveAboveRevenue: "",
				GrossProfitIncentive: Lai_gop_da_bao_gom_incentive,
				RatioGrossProfitIncentiveAboveRevenue: "",
				DateOfInvoice: "",
				PercentInvoice: "",
				OutputIntoMoney: Doanh_thu_con_lai_chua_xuat_HD,
				NoteInvoices: "",
				SumPercentInvoices: "",
				TotalSumIncentive: Incentive,
				TotalSumInsurance: CP_bao_hanh_HSC,
				ExternalCapitalCosts: Gia_von_ben_ngoai,
				InternalCapitalCosts: Gia_von_noi_bo,
				TotalInsuranceHSE: CP_ho_tro_ky_thuat_HSE,
				TotalMiscExpenseCost: CP_khac,
				chiPhi: CP_von
			}];
		}

		return array_result
	}

	function findArrayItem(array, id) {
		return array.find(item => item._id == id); 
	}	
	// hàm convert to date time
	function convert_time(date_time){
		const date = new Date(date_time); // Replace this with your actual date object
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		const seconds = ('0' + date.getSeconds()).slice(-2);
		const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		return formattedDate;
	}

	function getCurrentDate(separator){
	    let newDate = new Date()
	    let date = newDate.getDate();
	    let month = newDate.getMonth() + 1;
	    let year = newDate.getFullYear();
	    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
	}

	function get_status_name(array,ContractID,event_array){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			let statusID = lastElement.StatusID;
			const rs_name = event_array.find(obj => obj.StatusID == statusID);
			return (rs_name != undefined) ? rs_name.name: "";
		}else{
			return '';
		}
	}

	function get_status(array,ContractID){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return '';
		}
	}



	const [newFilter, setNewFilter] = useState({
		fromDate:"",
		toDate: "",
		statusInvoice: "",
		DepartmentID: "",
		CenterID: "",
		staffName: "",
		grossProfitVaule: "",
		grossProfitPercentage: ""
	})
	
	const {
		fromDate,
		toDate,
		statusInvoice,
		DepartmentID,
		CenterID,
		staffName,
		grossProfitVaule,
		grossProfitPercentage
	} = newFilter

	const onChangeNewFilter = event => {
		let name = event.target.name;
		let val = event.target.value
		
		if (name == "fromDate" || name == "toDate") {
			setNewFilter({ ...newFilter, [name]: val.split("-").reverse().join("-") })
		}else if (name == "grossProfitPercentage") {
			if (!isNumberOrDecimal(val) && val != '') {
				var message = 'Vui lòng nhập tỉ lệ % không quá 2 số thập phận'
				setShowToast({ show: true, message: message, type: 'danger' })
				event.target.value = val
				return false;
			}else{
				var value = (val != "") ? val : 0;
				if (parseInt(val) > 100 && parseFloat(val) > 100) {
					var message = 'Vui lòng nhập tỷ lệ không quá 100%'
					setShowToast({ show: true, message: message, type: 'danger' })
					event.target.value = val
					return false;
				}else if (val == "00" && parseInt(val) == 0 && parseFloat(val) == 0) {
					var message = 'Vui lòng nhập tỷ lệ lớn hơn 0%'
					setShowToast({ show: true, message: message, type: 'danger' })
					event.target.value = val
					return false;
				}
				setNewFilter({ ...newFilter, [name]: val })
			}
		}
		setNewFilter({ ...newFilter, [name]: val.trim() })
	}

	const isNumberOrDecimal = userInput => {
		// if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		// return /^\d+$/.test(userInput); ok
  		// return /((?:\d+\.\d*)|(?:\d*\.?\d+))$/.test(userInput); ok
  		// return /^\d*\.?\d*$/.test(userInput) //ok
  		return /^\d+(\.\d{0,2})?$/.test(userInput)
	}

	const isParentNumber = userInput => {
		if (typeof userInput !== 'string') return false;
		return /^\d+$/.test(userInput);
	}

	function approximatelyEqual(a, b) {
	    return Math.abs(a - b) < 0.01;
	}

	const handleFilterChange = () => {
		let condition = newFilter
		condition.fromDate = (startDate != null) ? formatDate(startDate) : ''
		condition.toDate = (endDate != null) ? formatDate(endDate) : ''
		condition.invoicingTime = (invoicingTime != null) ? formatDate(invoicingTime) : ''
		let rs = loopReport()
		console.log(condition, rs)
		// setNewContracts(rs);
		const filteredData = rs.filter((item) => {
		    // Date range filtering
		    let inDateRange = true;

		    if (condition.fromDate) {
		        const fromDate = new Date(condition.fromDate+ " 00:00:00");
		        const date = new Date(item.Date);
		        inDateRange = date >= fromDate;
		    }

		    if (condition.toDate) {
		        const toDate = new Date(condition.toDate + " 23:59:59");
		        const date = new Date(item.Date);
		        inDateRange = inDateRange && date <= toDate;
		    }

		    // Invoice status filtering
		    let statusCondition;
		    if (condition.statusInvoice == "<100") {
		        statusCondition = item.SumPercentInvoices < 100;
		    } else if (condition.statusInvoice == "100") {
		        statusCondition = item.SumPercentInvoices == 100;
		    } else {
		        statusCondition = true; // no condition
		    }

		    // Department filtering
		    // let departmentCondition;
		    // if (condition.DepartmentID) {
		    //     departmentCondition = item.DepartmentID == condition.DepartmentID;
		    // } else {
		    //     departmentCondition = true; // no condition
		    // }

			// Centers filtering
		    let centerCondition;
		    if (condition.CenterID) {
		        centerCondition = item.CenterID == condition.CenterID;
		    } else {
		        centerCondition = true; // no condition
		    }

			// Users filtering
			let userCondition;
		    if (condition.staffName) {
		        userCondition = item.user == condition.staffName;
		    } else {
		        userCondition = true; // no condition
		    }

			//
			let inDateOfInvoice = true
			if (condition.invoicingTime) {
		        const invoicingTimeDateStart = new Date(condition.invoicingTime+ " 00:00:00");
		        const invoicingTimeDateEnd = new Date(condition.invoicingTime+ " 23:59:59");
		        const date = new Date(item.DateOfInvoice);
		        inDateOfInvoice = inDateOfInvoice && date <= invoicingTimeDateEnd && date >= invoicingTimeDateStart;
		    }

		    let grossProfitVauleCondition = true
			if (condition.grossProfitVaule) {
		        switch (condition.grossProfitVaule) {
				    case "<500M":
				        grossProfitVauleCondition = item.projectEfficiency < 499999999
				        break;
				    case "500M-1000M":
				        grossProfitVauleCondition = item.projectEfficiency <= 999999999 && item.projectEfficiency >= 500000000
				        break;
				    case "1000M-1500M":
				        grossProfitVauleCondition = item.projectEfficiency <= 1499999999 && item.projectEfficiency >= 1000000000
				        break;
				    case "1500M-2000M":
				        grossProfitVauleCondition = item.projectEfficiency <= 1999999999 && item.projectEfficiency >= 1500000000
				        break;
				    case ">2000M":
				    	grossProfitVauleCondition = item.projectEfficiency >= 2000000000
				    	break;
				}
		    }

		    let grossProfitPercentageCondition = true
			if (condition.grossProfitPercentage) {
		        grossProfitPercentageCondition = approximatelyEqual(condition.grossProfitPercentage, item.RatioGrossProfitNoIncentiveAboveRevenue)
		    }

		    // Filter by all conditions
		    return  inDateRange &&
		    		statusCondition &&
		    		centerCondition &&
		    		userCondition &&
		    		inDateOfInvoice &&
		    		grossProfitVauleCondition &&
		    		grossProfitPercentageCondition;
		});

		let rsfilteredData = loopReport(filteredData, true)
		setNewContracts(rsfilteredData);
		console.log("rsfilteredData", rsfilteredData)
		setIsSearch(true)
	}

	function sumTotalReport(data) {

	}

	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
	
		if (month.length < 2) 
			month = '0' + month;
		if (day.length < 2) 
			day = '0' + day;
	
		return [year, month, day].join('-');
	}

	const resetFilter = () => {
		setNewFilter({ 
			fromDate:"",
			toDate: "",
			statusInvoice: "",
			DepartmentID: "",
			CenterID: "",
			staffName: "",
			grossProfitVaule: "",
			grossProfitPercentage: "",
	 	})
		setDateRange([null, null])
		setInvoicingTime(null)
		// let rs = loopReport()
		setNewContracts([])
		setIsSearch(false)
	}

	let newFilterCenters;
	if (typeof Centers != "undefined") {
		switch(roleName){
			case 'AM':
			case 'TPKD':
				newFilterCenters = filter_Users(Users, UserID).center_details;
				break;
			case 'KTT':
			case 'BGD':
				newFilterCenters = filter_Users(Users, UserID).center_details;
				break;
			case 'BTGD':
			case 'Admin':
				newFilterCenters = Centers;
				break;
			default: 
				newFilterCenters = []
		}
	}

	// console.log("123123132", newFilterCenterssss, roleName, Users, UserID)
	// const newFilterCenters = (typeof Centers != "undefined") ? Centers : [];
	// const newFilterCenters = (typeof Centers != "undefined") ? Centers.filter((element) => element.CenterName !== "HO") : [];
	
	const Filter = () => {
		return (
			<>
			</>
		)
	}

	function FindArrayMenu(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return null;
		}
	}

	const [invoicingTime, setInvoicingTime] = useState(null);
	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;

	let body = null
	let stt = 1

	const {
		UserPageState: { UserPage, UserPages, UserPagesLoading },
		getAllUserPages
	} = useContext(UserPageContext)

	// console.debug("newContracts", newContracts, isSearch)

	if (ContractsLoading) {
		body = (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	}else if (Contracts.length === 0) {
		body = (
			<>
				<Card className='text-center'>
					<Card.Header as='h2'>Báo cáo Tổng hợp</Card.Header>
					<Card.Body>
						<Form.Row className="form-row-custom">
							<Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>Ngày lập PTHĐ</Form.Label>
								<br></br>
								<DatePicker
									className='form-control fix-select-date-ranger'
									selectsRange={true}
									startDate={startDate}
									endDate={endDate}
									onChange={(update) => {
										setDateRange(update);
									}}
									isClearable={true}
									dateFormat="yyyy/MM/dd"
									maxDate={new Date()}
								/>
							</Form.Group>

							{/* <Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>đến ngày</Form.Label>
								<Form.Control
									type='date'
									placeholder="Đến ngày"
									name='toDate'
									value={toDate}
									onChange={onChangeNewFilter}
								/>
							</Form.Group> */}

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tình trạng hóa đơn</Form.Label>
								<Form.Control
									as="select"
									value={statusInvoice}
									name='statusInvoice'
									onChange={onChangeNewFilter}
									>
									<option value=''>-- chọn tình trạng --</option>
									<option value="100">Xuất hóa đơn đủ 100%</option>
									<option value="<100">Chưa hóa đơn đủ 100%</option>
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom fix-select-center" as="Col" controlId="formGridPassword">
								<Form.Label>Trung tâm</Form.Label>
								<Form.Control
									id = 'noidung-help'
									as="select"
									name='CenterID'
									aria-describedby='noidung-help'
									value={CenterID}
									onChange={onChangeNewFilter}>
									<option value=''>-----</option>
									{
										typeof newFilterCenters != "undefined" ? 
										newFilterCenters.map((obj => {
											return (
												<>
													<option value={obj.CenterID}>{obj.CenterName}</option>
												</>
											)
										}))
										:
										<></>
									}
									
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tài khoản nhân viên</Form.Label>
								<Form.Control
									type='text'
									placeholder=""
									name='staffName'
									value={staffName}
									onChange={onChangeNewFilter}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>Thời gian xuất hóa đơn</Form.Label>
								<br></br>
								<DatePicker
									selected={invoicingTime}
									onChange={(date) => setInvoicingTime(date)}
									className='form-control'
									dateFormat='yyyy/MM/dd'
									maxDate={new Date()}
									isClearable={true}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Giá trị lãi gộp</Form.Label>
								<Form.Control
									as="select"
									value={grossProfitVaule}
									name='grossProfitVaule'
									onChange={onChangeNewFilter}
									>
									<option value=''>-- giá trị --</option>
									<option value='<500M'>Dưới 500 triệu</option>
									<option value='500M-1000M'>500 triệu - 1 tỷ</option>
									<option value='1000M-1500M'>1 tỷ - 1 tỷ 500 triệu</option>
									<option value='1500M-2000M'>1 tỷ 500 triệu - dưới 2 tỷ</option>
									<option value='>2000M'>Trên 2 tỷ</option>
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tỷ lệ % lãi gộp</Form.Label>
								<Form.Control
									type='text'
									placeholder=""
									name='grossProfitPercentage'
									value={grossProfitPercentage}
									onChange={onChangeNewFilter}
								/>
							</Form.Group>
						</Form.Row>

						<Form.Row className="form-row-custom">
							<Form.Group as="Col" controlId="formGridPassword">
								<Form.Label>&nbsp;</Form.Label>
								<Col xs="auto">
									<OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Xóa lọc báo cáo
											</Tooltip>
										}
									>
									<a onClick={resetFilter}>
										<img src={iconFilterReset} width="35" height="35"></img>
									</a>
									</OverlayTrigger>
								</Col>
							</Form.Group>

							<Form.Group as="Col" controlId="formGridPassword">
								<Form.Label>&nbsp;</Form.Label>
								<Col xs="auto">
									{/* <OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Lọc báo cáo
											</Tooltip>
										}
									>
									<a onClick={handleFilterChange}>
										<img src={iconViewReport} width="35" height="35"></img>
									</a>
									</OverlayTrigger> */}

									<Button
										variant='primary'
										onClick={handleFilterChange}
									>
										Lọc báo cáo
									</Button>
								</Col>
							</Form.Group>
						</Form.Row>

						<div id="table-scroll" className="table-scroll table-class-data-0">
							<table id="main-table" className="main-table table table-lg table-hover">
								<thead>
									<tr>
										<th scope="col" className='z-index-th header-table'>STT</th>
										<th scope="col" className='z-index-th header-table'>Ngày lập PTHD</th>
										<th scope="col" className='z-index-th header-table'>Số hợp đồng</th>
										<th scope="col" className='z-index-th header-table no-data-1'>Tên khách hàng</th>
										<th scope="col" className='z-index-th header-table no-data-2'>Tên trung tâm</th>
										<th scope="col" className='z-index-th header-table no-data-3'>Tên phòng ban</th>
										<th scope="col" className='header-table'>Tên NV</th>
										<th scope="col" className='header-table'>Doanh thu</th>
										<th scope="col" className='header-table'>Giá vốn bên ngoài</th>
										<th scope="col" className='header-table'>Giá vốn nội bộ</th>
										<th scope="col" className='header-table'>Incentive</th>
										<th scope="col" className='header-table'>CP bảo hành (HSC)</th>
										<th scope="col" className='header-table'>CP hỗ trợ ký thuật (HSE)</th>
										<th scope="col" className='header-table'>CP triển khai</th>
										<th scope="col" className='header-table'>CP vốn</th>
										<th scope="col" className='header-table'>CP manday</th>
										<th scope="col" className='header-table'>CP bảo lãnh</th>
										<th scope="col" className='header-table'>CP khác</th>
										<th scope="col" className='header-table'>CP vật tư phụ</th>
										<th scope="col" className='header-table'>Thuế</th>
										<th scope="col" className='header-table'>Tổng CP</th>
										<th scope="col" className='header-table'>Lãi gộp(chưa có incentive)</th>
										<th scope="col" className='header-table'>Tỷ lệ LG/DT</th>
										<th scope="col" className='header-table'>Lãi gộp(đã bao gồm incentive)</th>
										<th scope="col" className='header-table'>Tỷ lệ LG (gồm incentive)/DT</th>
										<th scope="col" className='header-table'>Ngày xuất hóa đơn</th>
										{/*<th scope="col" className='header-table'>Tình trạng hóa đơn</th>*/}
										{/* <th scope="col" className='header-table'>Tỷ lệ % xuất HĐ</th> */}
										{/* <th scope="col" className='header-table'>Doanh thu còn lại chưa xuất HĐ</th> */}
										<th scope="col" className='header-table'>Ghi chú</th> 
									</tr>
								</thead>
								<tbody>
									
								</tbody>
							</table>
						</div>
					</Card.Body>
				</Card>
			</>
		)
	} else {
		body = (
			<>
				<Card className='text-center'>
					<Card.Header as='h2'>Báo cáo Tổng hợp</Card.Header>
					<Card.Body>
						<Form.Row className="form-row-custom">
							<Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>Ngày lập PTHĐ</Form.Label>
								<br></br>
								<DatePicker
									className='form-control fix-select-date-ranger'
									selectsRange={true}
									startDate={startDate}
									endDate={endDate}
									onChange={(update) => {
										setDateRange(update);
									}}
									isClearable={true}
									dateFormat="yyyy/MM/dd"
									maxDate={new Date()}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tình trạng hóa đơn</Form.Label>
								<Form.Control
									as="select"
									value={statusInvoice}
									name='statusInvoice'
									onChange={onChangeNewFilter}
									>
									<option value=''>-- chọn tình trạng --</option>
									<option value="100">Xuất hóa đơn đủ 100%</option>
									<option value="<100">Chưa hóa đơn đủ 100%</option>
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom fix-select-center" as="Col" controlId="formGridPassword">
								<Form.Label>Trung tâm</Form.Label>
								<Form.Control
									id = 'noidung-help'
									as="select"
									name='CenterID'
									aria-describedby='noidung-help'
									value={CenterID}
									onChange={onChangeNewFilter}>
									<option value=''>-----</option>
									{
										typeof newFilterCenters != "undefined" ? 
										newFilterCenters.map((obj => {
											return (
												<>
													<option key={obj._id} value={obj.CenterName.trim()}>{obj.CenterName.trim()}</option>
												</>
											)
										}))
										:
										<></>
									}
									
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tài khoản nhân viên</Form.Label>
								<Form.Control
									type='text'
									placeholder=""
									name='staffName'
									value={staffName}
									onChange={onChangeNewFilter}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>Thời gian xuất hóa đơn</Form.Label>
								<br></br>
								<DatePicker
									selected={invoicingTime}
									onChange={(date) => setInvoicingTime(date)}
									className='form-control'
									dateFormat='yyyy/MM/dd'
									maxDate={new Date()}
									isClearable={true}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Giá trị lãi gộp</Form.Label>
								<Form.Control
									as="select"
									value={grossProfitVaule}
									name='grossProfitVaule'
									onChange={onChangeNewFilter}
									>
									<option value=''>-- giá trị --</option>
									<option value='<500M'>Dưới 500 triệu</option>
									<option value='500M-1000M'>500 triệu - 1 tỷ</option>
									<option value='1000M-1500M'>1 tỷ - 1 tỷ 500 triệu</option>
									<option value='1500M-2000M'>1 tỷ 500 triệu - dưới 2 tỷ</option>
									<option value='>2000M'>Trên 2 tỷ</option>
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tỷ lệ % lãi gộp</Form.Label>
								<Form.Control
									type='text'
									placeholder=""
									name='grossProfitPercentage'
									value={grossProfitPercentage}
									onChange={onChangeNewFilter}
								/>
							</Form.Group>
						</Form.Row>

						<Form.Row className="form-row-custom">
							<Form.Group as="Col" controlId="formGridPassword">
								<Form.Label>&nbsp;</Form.Label>
								<Col xs="auto">
									<OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Xóa lọc báo cáo
											</Tooltip>
										}
									>
									<a onClick={resetFilter}>
										<img src={iconFilterReset} width="35" height="35"></img>
									</a>
									</OverlayTrigger>
								</Col>
							</Form.Group>

							<Form.Group as="Col" controlId="formGridPassword">
								<Form.Label>&nbsp;</Form.Label>
								<Col xs="auto">
									{/* <OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Lọc báo cáo
											</Tooltip>
										}
									>
									<a onClick={handleFilterChange}>
										<img src={iconViewReport} width="35" height="35"></img>
									</a>
									</OverlayTrigger> */}

									<Button
										variant='primary'
										onClick={handleFilterChange}
									>
										Lọc báo cáo
									</Button>
								</Col>
							</Form.Group>
						</Form.Row>

						<div id="table-scroll" className={newContracts.length > 15 ? "table-scroll": "table-scroll table-scroll-fit-content"}>
							<table id="main-table" className="main-table table table-lg table-hover">
								<thead>
									<tr>
										<th scope="col" className='z-index-th header-table'>STT</th>
										<th scope="col" className='z-index-th header-table'>Ngày lập PTHD</th>
										<th scope="col" className='z-index-th header-table'>Số hợp đồng</th>
										<th scope="col" className='z-index-th header-table'>Tên khách hàng</th>
										<th scope="col" className='z-index-th header-table'>Tên trung tâm</th>
										<th scope="col" className='z-index-th header-table'>Tên phòng ban</th>
										<th scope="col" className='header-table'>Tên NV</th>
										<th scope="col" className='header-table'>Doanh thu</th>
										<th scope="col" className='header-table'>Giá vốn bên ngoài</th>
										<th scope="col" className='header-table'>Giá vốn nội bộ</th>
										<th scope="col" className='header-table'>Incentive</th>
										<th scope="col" className='header-table'>CP bảo hành (HSC)</th>
										<th scope="col" className='header-table'>CP hỗ trợ kỹ thuật (HSE)</th>
										<th scope="col" className='header-table'>CP triển khai</th>
										<th scope="col" className='header-table'>CP vốn</th>
										<th scope="col" className='header-table'>CP manday</th>
										<th scope="col" className='header-table'>CP bảo lãnh</th>
										<th scope="col" className='header-table'>CP khác</th>
										<th scope="col" className='header-table'>CP vật tư phụ</th>
										<th scope="col" className='header-table'>Thuế</th>
										<th scope="col" className='header-table'>Tổng CP</th>
										<th scope="col" className='header-table'>Lãi gộp(chưa có incentive)</th>
										<th scope="col" className='header-table'>Tỷ lệ LG/DT</th>
										<th scope="col" className='header-table'>Lãi gộp(đã bao gồm incentive)</th>
										<th scope="col" className='header-table'>Tỷ lệ LG (gồm incentive)/DT</th>
										<th scope="col" className='header-table'>Ngày xuất hóa đơn</th>
										{/*<th scope="col" className='header-table'>Tình trạng hóa đơn</th>*/}
										{/* <th scope="col" className='header-table'>Tỷ lệ % xuất HĐ</th> */}
										{/* <th scope="col" className='header-table'>Doanh thu còn lại chưa xuất HĐ</th> */}
										<th scope="col" className='header-table'>Ghi chú</th> 
									</tr>
								</thead>
								<tbody>
									{
										isSearch ? 
										<>
										{
											newContracts.length ? 
											<>
											{
												newContracts.map(Contract => (
													<tr className={(Contract.STT == "Tổng") ? "classNameTotal": ""} >
														<th scope="col" className="custom-font-w">{(Contract.STT == "Tổng") ? "Tổng": stt++}</th>
														<th scope="col" className="custom-font-w fm-col-string">{Contract.Date}</th>
														<th scope="col" className="custom-font-w fm-col-string">{Contract.ContractID}</th>
														<th scope="col" className="custom-font-w fm-col-string">
															<span
															className='text'
															title={Contract.CustomerID}
															>
																{Contract.CustomerID}
															</span>
														</th>
														<th scope="col" className="custom-font-w fm-col-string">
															<span
															className='text'
															title={Contract.CenterID}
															>
																{Contract.CenterID}
															</span>
														</th>
														<th scope="col" className="custom-font-w fm-col-string">
															<span
															className='text'
															title={Contract.DepartmentID}
															>
																{Contract.DepartmentID}
															</span>
														</th>
														<td scope="col" className="fm-col-string">{Contract.user}</td>
														<td scope="col" className="fm-col-number">{Contract.TotalOutputIntoMoney.toLocaleString()}</td>
														<td scope="col" className="fm-col-number">{Contract.ExternalCapitalCosts.toLocaleString()}</td>
														<td scope="col" className="fm-col-number">{Contract.InternalCapitalCosts.toLocaleString()}</td>
														<td scope="col" className="fm-col-number">{Contract.TotalSumIncentive.toLocaleString()}</td>
														<td scope="col" className="fm-col-number">{Contract.TotalSumInsurance.toLocaleString()}</td>
														<td scope="col" className="fm-col-number">{Contract.TotalInsuranceHSE.toLocaleString()}</td>
														<td scope="col" className="fm-col-number">{Contract.TotalCostAB.toLocaleString()}</td>
														<td scope="col" className="fm-col-number">{Math.round(Contract.chiPhi).toLocaleString()}</td>
														<td scope="col" className="fm-col-number">{Contract.TotalMandayCost.toLocaleString()}</td>
														<td scope="col" className="fm-col-number">{Contract.TotalGuaranteeLetterCost.toLocaleString()}</td>
														<td scope="col" className="fm-col-number">{Contract.TotalMiscExpenseCost.toLocaleString()}</td>
														<td scope="col" className="fm-col-number">{Contract.SumAuxiliaryCost.toLocaleString()}</td>
														<td scope="col" className="fm-col-number">{Contract.SumAuxiliaryTax.toLocaleString()}</td>
														<td scope="col" className="fm-col-number">{Contract.SumAuxiliaryCostTax.toLocaleString()}</td>
														<td scope="col" className="fm-col-number">{Math.round(Contract.projectEfficiency).toLocaleString()}</td>
														<td scope="col" className="fm-col-number">
															{
																Contract.STT == "Tổng" ? 
																	"" 
																	:
																	Contract.RatioGrossProfitNoIncentiveAboveRevenue.toLocaleString() + "%"
															}
														</td>
														<td scope="col" className="fm-col-number">{Math.round(Contract.GrossProfitIncentive).toLocaleString()}</td>
														<td scope="col" className="fm-col-number">
															{
																Contract.STT == "Tổng" ? 
																	"" 
																	:
																	Contract.RatioGrossProfitIncentiveAboveRevenue.toLocaleString() + "%"
															}
														</td>
														<td scope="col">
															{
																Contract.STT == "Tổng" ? 
																	"" 
																	:
																	Contract.DateOfInvoice != "" ? 
																	Contract.DateOfInvoice.toLocaleString()
																	: 
																	"Chưa xuất hóa đơn"		
															}
														</td>
														{/*<td scope="col">
															{
																Contract.STT == "Tổng" ? 
																	"" 
																	:
																	Contract.PercentInvoice == "100%" ? 
																		"Đã hoàn tất thanh toán"
																	: 
																	Contract.PercentInvoice == "0%" ? 
																		"Chưa thanh toán"		
																	:
																		"Đã thanh toán 1 phần"
															}
														</td>*/}
														{/* <td scope="col">{Contract.PercentInvoice.toLocaleString()}</td> */}
														{/* <td scope="col">{Contract.OutputIntoMoney.toLocaleString()}</td> */}
														<td scope="col">
															{
																Contract.STT == "Tổng" ? "" : Contract.NoteInvoices
															}
														</td>
													</tr>
												))
											}
											</>
											:
											<>
												<tr>
													<th scope="col" colSpan={7} className="custom-font-w">Không tìm thấy dữ liệu phù hợp</th>
													<td scope="col" colSpan={21}></td>
												</tr>
											</>
										}
										</>
										:
										<>
											<tr>
												<th scope="col" colSpan={7} className="custom-font-w">Chọn điều kiện để lọc báo cáo</th>
												<td scope="col" colSpan={21}></td>
											</tr>
										</>
									}
								</tbody>
							</table>
						</div>
					</Card.Body>
				</Card>
			</>
		)
	}

	if (UserPagesLoading && MenusLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	}else {
		const result = UserPages.filter(obj => { return obj.user_id.some(id => id === UserID); });
		let menu_access = false
		if (result.length > 0) {
			for (var i = 0; i < result.length; i++) {
				let menuV1 = FindArrayMenu(Menus, result[i].page_id[0])
				if (menuV1 != null && menuV1.path == window.location.pathname) {
					// console.debug("menuV1 co quyen truy cap")
					menu_access = true
					break;
				}else {
					// console.debug("menuV1 khong quyen truy cap")
				}
			}
		} else {
			// console.debug("menu khong quyen truy cap")
		}

		if (menu_access) {
			return (
				<>
					{body}
					<Toast
						show={show}
						style={{ position: 'fixed', top: '20%', right: '10px', zIndex: '999999'}}
						className={`bg-${type} text-white`}
						onClose={setShowToast.bind(this, {
							show: false,
							message: '',
							type: null
						})}
						delay={3000}
						autohide
					>
						<Toast.Body>
							<strong>{message}</strong>
						</Toast.Body>
					</Toast>
				</>
			)

		}else {
			return (
				<>
					<div id="notfound">
						<div className="notfound">
							<h1>Oops!</h1>
							<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
								<br/>
								<Link style={{textDecoration: "none"}} to="/home">
									Trở về trang chủ
								</Link>
							</h6>
						</div>
					</div>
				</>
			)
		}
	}
}

export const Report_Contract_Revenue = () => {
	//
	const {
		UserState: { User, Users, UsersLoading },
		getUsers
	} = useContext(UserContext)

	const {
		HistoryState: { History, Historys, HistorysLoading },
		getHistory
	} = useContext(HistoryContext)

	const {
		EventState: { Event, Events, EventsLoading },
		getEvent
	} = useContext(EventContext)

	//=== Get All Productcosts
	const {
		ProductCostState: { ProductCost, ProductCosts, ProductCostsLoading },
		getProductCosts,
	} = useContext(ProductCostContext)

	//=== Get MandayCost 
	const {
		MandayCostState: { MandayCost, MandayCosts, MandayCostsLoading },
		getMandayCosts,
	} = useContext(MandayCostContext)

	//=== Get GuaranteeLetterCost
	const {
		GuaranteeLetterCostState: { GuaranteeLetterCost, GuaranteeLetterCosts, GuaranteeLetterCostsLoading },
		getGuaranteeLetterCosts,
	} = useContext(GuaranteeLetterCostContext)

	//=== Get MiscExpenseCosts
	const {
		MiscExpenseCostState: { MiscExpenseCost, MiscExpenseCosts, MiscExpenseCostsLoading },
		getMiscExpenseCosts,
	} = useContext(MiscExpenseCostContext)

	//=== Get data CapitalExpenditureCost chi phi von
	const {
		CapitalExpenditureCostState: { CapitalExpenditureCost, CapitalExpenditureCosts, CapitalExpenditureCostsLoading },
		getCapitalExpenditureCosts,
	} = useContext(CapitalExpenditureCostContext);

	//=== Get data LoanInterest chi phi lãi vay
	const {
	    LoanInterestState: { LoanInterest, LoanInterests, LoanInterestsLoading },
	    getLoanInterest
	} = useContext(LoanInterestContext);
	
	//=== Get data AuxiliaryCost
	const {
		AuxiliaryCostState: { AuxiliaryCosts },
		getAuxiliaryCosts
	} = useContext(AuxiliaryCostContext)

	//=== Get data ImplementationCost
	const {
		ImplementationCostState: { ImplementationCosts },
		getImplementationCost
	} = useContext(ImplementationCostContext)

	//== Get data Invoices
	const {
		InvoiceState: { Invoices },
		getAllInvoice
	} = useContext(InvoiceContext)

	//== Get data Centers
	const {
		CenterState: { Centers, CentersLoading },
		get_All_Center
	} = useContext(ConfigCenterContext)

	//== Get data Departments
	const {
		DepartmentState: { Department, Departments, DepartmentsLoading },
		getDepartment
	} = useContext(ConfigDepartmentContext)

	//== Get data Customers
	const {
		CustomerState: { Customer, Customers, CustomersLoading },
		get_All_Customer
	} = useContext(ConfigCustomerContext)

	//== Get data Contracts
	const {
		ContractState: { Contract, Contracts, ContractsLoading },
		getContracts,
		showToast: { show, message, type },
		setShowToast,
		showToast,
	} = useContext(ContractContext)
	//== Get data auth
	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)
	//== Get data Roles
	const {
		RoleState: { Role, Roles, RolesLoading },
		getRoles,
	} = useContext(RoleContext)

	// const {
	// 	UserPageState: { UserPage, UserPages, UserPagesLoading },
	// 	getAllUserPages
	// } = useContext(UserPageContext)

	// console.debug("UserPages", UserPages)

	//== Get data UserManager
	const {
		UserManagerState: { UserManager, UserManagers, UserManagersLoading },
		getAllUserManagers
	} = useContext(UserManagerContext)
	
	useEffect(() => get_All_Center(), [])
	useEffect(() => getDepartment(), [])
	useEffect(() => get_All_Customer(), [])
	useEffect(() => getUsers(), [])
	useEffect(() => getHistory(), [])
	useEffect(() => getEvent(), [])
	useEffect(() => getProductCosts(), [])
	useEffect(() => getMandayCosts(), [])
	useEffect(() => getGuaranteeLetterCosts(), [])
	useEffect(() => getMiscExpenseCosts(), [])
	useEffect(() => getCapitalExpenditureCosts(), [])
	useEffect(() => getLoanInterest(), [])
	useEffect(() => getAuxiliaryCosts(), [])
	useEffect(() => getImplementationCost(), [])
	useEffect(() => getAllInvoice(), [])
	useEffect(() => getContracts(), [])
	useEffect(() => getRoles(), [])
	useEffect(() => getAllUserManagers(), [])

	const [newContracts, setNewContracts] = useState([])
	const [isSearch, setIsSearch] = useState(false)
	let role_new = roles[0];
	let UserID = _id;
	let roleName = FindArray(Roles, role_new).name;
	// console.log("Report", role_new, UserID, roleName, MiscExpenseCosts)

	//
	function filter_new_contract(array,UserID){
		let returnObject = "";
		// if (UserID != undefined) returnObject = array.filter((element) => element.user.centers != null ? element.user.centers[0] : '' == UserID[0]);
		if (UserID != undefined) returnObject = array.filter((element) => element.CenterID == UserID[0]);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	let newContractsss;
	let newContract111 = [];
	if(roleName == 'Admin' || roleName == 'BTGD'){
		newContractsss = Contracts
	}else if(roleName == 'KTT' || roleName == 'BGD'){
		let centerOfUser = filter_Users(Users,UserID).centers;
		// console.debug(centerOfUser);
		if (centerOfUser != undefined) {
			if(Contracts.length > 0){
				newContract111 = filter_new_contract(Contracts,centerOfUser);
			}
		}
		newContractsss = newContract111;
	}else{
		let newContract1 = Contracts.filter((v) => get_user_id(UserID,UserManagers).includes(v.user._id))
		if(roleName == 'AM'){
			newContractsss = newContract1;
		}else{
			let contract_me = Contracts.filter((v) => v.user._id == UserID)

			let newContract2 = newContract1.concat(contract_me);
			newContractsss = newContract2;
		}
	}

	// return false
	// console.log(123,roleName, newContractsss,'====================')

	function get_user_id(userLogin, userManager) {
		let managedUsers = [];
		// managedUsers.push(userLogin)

		function findManagedUsers(managerId) {
			let users = userManager.filter(item => item.manager_id[0] === managerId);
			if (users.length > 0) {
				for (let user of users) {
					findManagedUsers(user.user_id[0]);
				}
			} else {
				managedUsers.push(managerId);
			}
		}

		findManagedUsers(userLogin);
		return managedUsers;
	}

	function filter_Users(array,UserID){
		let returnObject = "";
		if (UserID != undefined) returnObject = array.filter((element) => element._id === UserID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return '';
		}
	}


	function filter_history(array,ContractID){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return '';
		}
	}
	//
	function totalCenter() {
		let totalCenter = 0;
		if (typeof Centers != undefined) totalCenter = Centers.length;
		return totalCenter;
	}

	function totalDepartment() {
		let totalDepartment = 0;
		if (typeof Departments != undefined) totalDepartment = Departments.length;
		return totalDepartment;
	}

	function totalContract() {
		let totalContract = 0;
		if (typeof Contracts != undefined) totalContract = Contracts.length;
		return totalContract;
	}

	function totalUser() {
		let totalUser = 0;
		if (typeof Users != undefined) totalUser = Users.length;
		return totalUser;
	}

	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id == ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	// table ranking
	//Ham tinh tong Phan tử trong kieu mang 
	function Sum_InputIntoMoney(ProductsCost) {
		let Total_InputIntoMoney = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_InputIntoMoney += ListProduct.InputIntoMoney
			)))

		return Total_InputIntoMoney;
	}
	// input into money with check mua nội bộ
	function Sum_InputIntoMoneyTypePurchase(ProductsCost, InternalPurchase) {
		let Total_InputIntoMoney = 0;

		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_InputIntoMoney += (InternalPurchase == 1) 
					? (ListProduct.InternalPurchase == true) ? ListProduct.InputIntoMoney : 0
					: (ListProduct.InternalPurchase == false) ? ListProduct.InputIntoMoney : 0
			)))

		return Total_InputIntoMoney;
	}
	//Ham tinh tong Phan tử trong kieu mang 
	function Sum_OutputIntoMoney(ProductsCost) {
		let Total_OutputIntoMoney = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_OutputIntoMoney += ListProduct.OutputIntoMoney
			)))

		return Total_OutputIntoMoney;
	}

	//Tinh tong thanh tien gia ban voi Insurance = true
	function Sum_Insurance(ProductsCost) {
		let Total_Insurance = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				ListProduct.Insurance === true ? Total_Insurance += ListProduct.OutputIntoMoney : 0
			)))

		return Total_Insurance;
	}

	function Sum_InsuranceHSE(ProductsCost) {
		let Total_InsuranceHSE = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				ListProduct.InsuranceHSE === true ? Total_InsuranceHSE += (ListProduct.OutputIntoMoney*((ListProduct.PercentHSE != null ? ListProduct.PercentHSE : 1) / 100)) : 0
			)))
		return Total_InsuranceHSE;
	}

	// hàm tính tổng thành tiền
	function sumArray(mang) {
		let sum = 0;
		mang.map(function (value) { sum += value; });
		return sum;
	}
	//hàm tìm data theo contract id
	function find_data_by_contract_id(data, idContract, children = null) {
		let rs = [];
		if (children == 1) {
			rs = data.filter(x => (x.contract != null && x.contract._id == idContract))
		}else {
			rs = data.filter(x => x.contract == idContract)
		}
		return rs
	}

	// hàm đổi giá trị chi phí % => giá trị tiền tuyệt đối
	function changeCost(Cost, TotalOutputIntoMoney) {
		let returnValue = Cost;
		if (Cost < 1)
			returnValue = Cost * TotalOutputIntoMoney;
		return returnValue
	}

	function SumListCost(Auxiliary, TotalOutputIntoMoney) {
		let kq = 0;
		Auxiliary.map(AuxiliaryCost =>
			AuxiliaryCost.ListCosts.map(ListCost => (
				kq += changeCost(ListCost.Cost, TotalOutputIntoMoney)
			)))

		return kq;
	}
	
	function FindPlan(Auxiliary) {
		let returnPlan = 0;
		Auxiliary.map(AuxiliaryCost => returnPlan = AuxiliaryCost.Plan
		)
		return returnPlan;
	}

	function totalAuxiliaryCost(AuxiliaryCosts, TotalOutputIntoMoney){
		let sumCostTaxA = 0;
		let sumCostTaxB = 0;
		
		let sumCostA = 0;
		let sumCostB = 0;
		
		let sumTaxA = 0;
		let sumTaxB = 0;
		
		AuxiliaryCosts.map(AuxiliaryCost => (
			AuxiliaryCost.ListCosts.map(function(ListCost, index) {
				var newCost = 0
				var newTax = 0
				var newCostTax = 0
				if (AuxiliaryCost.Plan == 1){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost * ListCost.TaxRate)/100).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}else if (AuxiliaryCost.Plan == 2){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost / (100 - ListCost.TaxRate)) * ListCost.TaxRate).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostA += parseInt(newCost);
				}else if (AuxiliaryCost.Plan == 2){
					sumCostB += parseInt(newCost);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumTaxA += parseInt(newTax);
				}else if (AuxiliaryCost.Plan == 2){
					sumTaxB += parseInt(newTax);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostTaxA += parseInt((newCost+newTax));
				}else if (AuxiliaryCost.Plan == 2){
					sumCostTaxB += parseInt((newCost+newTax));
				}
			})
		))
		let muc2_1 = parseInt((sumCostA + sumCostB)/10);
		let muc2_thue = parseInt((sumTaxA + sumTaxB)/10);
		let muc2 = parseInt(muc2_1 + muc2_thue);
		return {muc2, muc2_1, muc2_thue}
	}

	function totalAuxiliaryCosts(AuxiliaryCosts, TotalOutputIntoMoney){
		let sumCostTaxA = 0;
		let sumCostTaxB = 0;
		
		let sumCostA = 0;
		let sumCostB = 0;
		
		let sumTaxA = 0;
		let sumTaxB = 0;
		
		AuxiliaryCosts.map(AuxiliaryCost => (
			AuxiliaryCost.ListCosts.map(function(ListCost, index) {
				var newCost = 0
				var newTax = 0
				var newCostTax = 0
				if (AuxiliaryCost.Plan == 1){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost * ListCost.TaxRate)/100).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}else if (AuxiliaryCost.Plan == 2){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost / (100 - ListCost.TaxRate)) * ListCost.TaxRate).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostA += parseInt(newCost);
				}else if (AuxiliaryCost.Plan == 2){
					sumCostB += parseInt(newCost);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumTaxA += parseInt(newTax);
				}else if (AuxiliaryCost.Plan == 2){
					sumTaxB += parseInt(newTax);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostTaxA += parseInt((newCost+newTax));
				}else if (AuxiliaryCost.Plan == 2){
					sumCostTaxB += parseInt((newCost+newTax));
				}
			})
		))

		let sumCostAB = parseInt(sumCostA + sumCostB);
		let sumTaxAB = parseInt(sumTaxA + sumTaxB);
		let sumCostTaxAB = parseInt(sumCostTaxA + sumCostTaxB);
		let muc2_1 = parseInt((sumCostA + sumCostB)/10);
		let muc2_thue = parseInt((sumTaxA + sumTaxB)/10);
		let muc2 = parseInt(muc2_1 + muc2_thue);
		return {sumCostAB, sumTaxAB, sumCostTaxAB, muc2, muc2_1, muc2_thue}
	}

	function TotalStageImplementation(ImplementationCosts) {
		let All_Total = 0;
		ImplementationCosts.map((element) => (
			element.StagesImplementation.map((object) => (
				object.Costs.map((Costs) =>
					All_Total += Costs.IntoMoney
				)))))
		return All_Total;
	}

	function TotalStageImplementationAB(ImplementationCosts, type = null) {
		let All_Total = 0;
		let ImplementationCostNew = "";
		ImplementationCostNew = ImplementationCosts.filter(x => x.Category == type);
		ImplementationCostNew.map((element) => (
			element.StagesImplementation.map((object) => (
				object.Costs.map((Costs) =>
					All_Total += Costs.IntoMoney
				)))))
		return All_Total;
	}

	function TotalCostAuxiliaryCosts(AuxiliaryCosts) {
		let All_Total = 0;
		AuxiliaryCosts.map((element) => {
			element.ListCosts.map((Costs) => {
				All_Total += Costs.Cost
			})
		})
		return All_Total;
	}

	function TotalTaxAuxiliaryCosts(AuxiliaryCosts) {
		let All_Total = 0;
		AuxiliaryCosts.map((element) => {
			element.ListCosts.map((Costs) => {
				All_Total += Costs.Tax
			})
		})
		return All_Total;
	}

	function Sum_Incentive(ProductsCost) {
		let Total_Incentive = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_Incentive += ListProduct.Incentive
			)))

		return Total_Incentive;
	}

	function SumPercentInvoice(Invoices) {
		let Total_PercentInvoice = 0;
		Invoices.map(invoice => (
			Total_PercentInvoice += invoice.InvoiceVal
		))

		return Total_PercentInvoice;
	}

	useEffect(() => {
		// let rs = loopReport()
		setNewContracts([])
	}, [Contracts]);
	////////////////////////
	
	function loopReport(newContract = null, isTotal = false) {
		let newContracts = newContract == null ? newContractsss : newContract
		let array_result = []

		//
		let Doanh_thu = 0
		let Gia_von_ben_ngoai = 0
		let Gia_von_noi_bo = 0
		let Incentive = 0
		let CP_bao_hanh_HSC = 0
		let CP_ho_tro_ky_thuat_HSE = 0
		let CP_trien_khai = 0
		let CP_von = 0
		let CP_manday = 0
		let CP_bao_lanh = 0
		let CP_khac = 0
		let CP_vat_tu_phu = 0
		let Thue = 0
		let Tong_CP = 0
		let Lai_gop_chua_co_incentive = 0
		let Lai_gop_da_bao_gom_incentive = 0
		let Doanh_thu_con_lai_chua_xuat_HD = 0
		//

		newContracts.map(function (Contract, i){
			console.debug(Contract, i)
			let ContractID = Contract.ContractID
			let RatioUSD = Contract.RatioUSD
			let VAT = Contract.VAT
			let STT = parseInt(i+1);
			let findCenters = (findArrayItem(Centers, Contract.CenterID) != undefined) ? findArrayItem(Centers, Contract.CenterID) : findArrayItem(Centers, Contract.CenterID)
			let findDepartments = (findArrayItem(Departments, Contract.DepartmentID) != undefined) ? findArrayItem(Departments, Contract.DepartmentID): findArrayItem(Departments, Contract.DepartmentID)
			let findCustomers = (findArrayItem(Customers, Contract.CustomerID) != undefined) ? findArrayItem(Customers, Contract.CustomerID): findArrayItem(Customers, Contract.CustomerID)
	
			let CenterID = findCenters != undefined ? findCenters.CenterName.trim() : Contract.CenterID
			let DepartmentID = findDepartments != undefined ? findDepartments.DepartmentName : Contract.DepartmentID
			let CustomerID = findCustomers != undefined ? findCustomers.CustomerName : Contract.CustomerID
			let user = newContract == null ? Contract.user.username : Contract.user
			let DateTemp = convert_time(Contract.Date != null ? Contract.Date : getCurrentDate("-"))
			// let StatusID = get_status(Historys,Contract._id) != '' ? get_status(Historys,Contract._id).StatusID : '0'
			// let StatusName = get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys,Contract._id,Events) : ''

			//ProductCost
			let ProductCost_idContract = find_data_by_contract_id(ProductCosts, Contract._id); // contract
			const TotalInsurance = 0.006 * Sum_Insurance(ProductCost_idContract)
			const TotalInsuranceHSE = Sum_InsuranceHSE(ProductCost_idContract)
			const TotalInputIntoMoney = Sum_InputIntoMoney(ProductCost_idContract)
			const TotalOutputIntoMoney = Sum_OutputIntoMoney(ProductCost_idContract)
			let TotalIncentive = 0;
			if(ProductCost_idContract[0] !== undefined){
				TotalIncentive = sumArray(ProductCost_idContract[0].ListProducts.map((ProductCost) => ProductCost.Incentive))//note
			}

			// MandayCost
			let MandayCost_idContract = find_data_by_contract_id(MandayCosts, Contract._id); // contract
			const TotalMandayCost = sumArray(MandayCost_idContract.map((MandayCost) => MandayCost.IntoMoney))
			//

			//GuaranteeLetterCost
			let GuaranteeLetterCost_idContract = find_data_by_contract_id(GuaranteeLetterCosts, Contract._id); // contract
			const TotalGuaranteeLetterCost = sumArray(GuaranteeLetterCost_idContract.map((GuaranteeLetterCost) => GuaranteeLetterCost.IntoMoney))

			//MiscExpenseCosts
			let MiscExpenseCost_idContract = find_data_by_contract_id(MiscExpenseCosts, Contract._id, 1);
			const TotalMiscExpenseCost = sumArray(MiscExpenseCost_idContract.map((MiscExpenseCost) => MiscExpenseCost.Cost))

			//CapitalExpenditureCosts
			let CapitalExpenditureCost_idContract = find_data_by_contract_id(CapitalExpenditureCosts, Contract._id);
			// let newCapitalExpenditureCost = sumArray(CapitalExpenditureCost_idContract.map((CapitalExpenditureCost) => CapitalExpenditureCost.CapitalExpense))//ch
			// let TotalCapitalExpense = ((CapitalExpenditureCost_idContract.InventoryDays + CapitalExpenditureCost_idContract.ImplementationDays - CapitalExpenditureCost_idContract.BedtDays) * CapitalExpenditureCost_idContract.CapitalCost + CapitalExpenditureCost_idContract.DebtCollectionDays * (CapitalExpenditureCost_idContract.Revenue - CapitalExpenditureCost_idContract.Deposits + CapitalExpenditureCost_idContract.DepositsNTP)) * (CapitalExpenditureCost_idContract.PercentInterest /100) / 365;
			let newCapitalExpenditureCost = {
				CapitalCost: 0, 
			    Revenue: 0,
			    CapitalExpense: 0,
			    InventoryDays: 15,
			    ImplementationDays: 30,
			    BedtDays: 30,
			    DebtCollectionDays: 15,
			    Deposits: 0,
			    DepositsNTP: 0,
			    PercentInterest: 10,
			    Note: '',
				ContractID: Contract._id
			};

			// Load data tu Form 1: Lay tong gia von va Doanh thu
	        newCapitalExpenditureCost.CapitalCost = TotalInputIntoMoney;
	        newCapitalExpenditureCost.Revenue = TotalOutputIntoMoney;
	        //Tinh Dat coc cua khach hang
			newCapitalExpenditureCost.Deposits = newCapitalExpenditureCost.Revenue * 0.2
			//Tinh Dat coc NTP
			newCapitalExpenditureCost.DepositsNTP = newCapitalExpenditureCost.CapitalCost * 0.3
			//Tinh chi phi von
		    newCapitalExpenditureCost.CapitalExpense = (typeof CapitalExpenditureCost_idContract[0] != "undefined") ? ((CapitalExpenditureCost_idContract[0].InventoryDays + CapitalExpenditureCost_idContract[0].ImplementationDays - CapitalExpenditureCost_idContract[0].BedtDays) * newCapitalExpenditureCost.CapitalCost + newCapitalExpenditureCost.DebtCollectionDays * (newCapitalExpenditureCost.Revenue - newCapitalExpenditureCost.Deposits + newCapitalExpenditureCost.DepositsNTP)) * (CapitalExpenditureCost_idContract[0].PercentInterest /100) / 365 : 0 ;
		    let TotalCapitalExpense = newCapitalExpenditureCost.CapitalExpense
			// console.debug("CapitalExpenditureCosts newCapitalExpenditureCost::::::", newCapitalExpenditureCost)

			//LoanInterests
			let LoanInterests_idContract = find_data_by_contract_id(LoanInterests, Contract._id);
			const TotalLoanInterest = sumArray(LoanInterests_idContract.map((LoanInterest ) => LoanInterest.InterestLoan))

			let chiPhi = 0;
			if(TotalCapitalExpense != 0){
				chiPhi = TotalCapitalExpense;
			}else{
				if(TotalLoanInterest != 0){
					chiPhi = TotalLoanInterest;
				}
			}

			//AuxiliaryCosts
			let AuxiliaryCost_idContract = find_data_by_contract_id(AuxiliaryCosts, Contract._id, 1);
			const TotalCost = SumListCost(AuxiliaryCost_idContract, TotalOutputIntoMoney);
			const rsTotalAuxiliaryCost = totalAuxiliaryCosts(AuxiliaryCost_idContract, TotalOutputIntoMoney)
			//ImplementationCost
			let ImplementationCost_idContract = find_data_by_contract_id(ImplementationCosts, Contract._id);
			const ImplementationCost = TotalStageImplementation(ImplementationCost_idContract);

			// 1.1 Chi phí triển khai hợp đồng + Chi phi Bảo hiểm
			const TotalImplementationCost = ImplementationCost + TotalInsurance + TotalInsuranceHSE;
			// 1. chi phi phat sinh khi thuc hien du an
			const ExtraCost = (TotalImplementationCost + chiPhi + TotalMandayCost + TotalGuaranteeLetterCost + TotalMiscExpenseCost)
			// 2.
			// 3.
			const projectEfficiency = (TotalOutputIntoMoney - TotalInputIntoMoney - ExtraCost - (rsTotalAuxiliaryCost.muc2*10))
			// console.log("rsTotalAuxiliaryCost", rsTotalAuxiliaryCost, "projectEfficiency", projectEfficiency)
			// Contracts[i].TotalInputIntoMoney = TotalInputIntoMoney //Chi phí
			// Contracts[i].projectEfficiency = Math.round(projectEfficiency) //Hiệu quả dự án
			// Contracts[i].TotalOutputIntoMoney = TotalOutputIntoMoney //Tổng doanh thu (8)

			//report 
			let ExternalCapitalCosts = Sum_InputIntoMoneyTypePurchase(ProductCost_idContract, false) //Giá vốn bên ngoài (9)
			let InternalCapitalCosts = Sum_InputIntoMoneyTypePurchase(ProductCost_idContract, true) //Giá vốn bên ngoài (10)
			let TotalSumIncentive = Sum_Incentive(ProductCost_idContract) //Incentive (11)
			let TotalSumInsurance = TotalInsurance //CP bảo hành (HSC) (12)
			// let TotalInsuranceHSE = TotalInsuranceHSE; //CP hỗ trợ kỹ thuật (HSE) (13)

			// CP triển khai (14)
			let GeneralExpenses = TotalStageImplementationAB(ImplementationCost_idContract, "a_chi_phi_chung")
			let DeploymentCosts = TotalStageImplementationAB(ImplementationCost_idContract, "b_chi_phi_trien_khai")
			// Contracts[i].GeneralExpenses = GeneralExpenses; //Giá trị thành tiền của Tổng A (Chi phí chung)
			// Contracts[i].DeploymentCosts = DeploymentCosts; //Giá trị thành tiền Tổng B (Chi phí triển khai)
			let TotalCostAB = (GeneralExpenses + DeploymentCosts); //Giá trị thành tiền Tổng của A và B

			// Contracts[i].TotalInputIntoMoney = TotalInputIntoMoney; //CP vốn (15)
			// Contracts[i].TotalMandayCost = TotalMandayCost; //CP manday (16)
			// Contracts[i].TotalGuaranteeLetterCost = Math.round(TotalGuaranteeLetterCost) //CP bảo lãnh (17)
			// Contracts[i].TotalMiscExpenseCost = Math.round(TotalMiscExpenseCost) // CP khác (18)

			let SumAuxiliaryCost = rsTotalAuxiliaryCost.muc2 //sumCostAB //CP tiền A+B (19)
			let SumAuxiliaryTax = rsTotalAuxiliaryCost.muc2_thue //sumTaxAB //CP Thuế A+B: (20)
			let SumAuxiliaryCostTax = rsTotalAuxiliaryCost.muc2_1 //sumCostTaxAB //CP tiền và thuế AB
			let TotalAuxiliaryAndMiscExpense = 0 //parseInt(TotalMiscExpenseCost + rsTotalAuxiliaryCost.sumCostAB) //Tổng CP: (21) = (18)+(19)

			//Lãi gộp (chưa có incentive): (22) = (8) - SUM(9-18) - (21)
			let GrossProfitNoIncentive = TotalOutputIntoMoney -
										(
											ExternalCapitalCosts +
											DeploymentCosts + 
											TotalSumIncentive + 
											TotalSumInsurance + 
											TotalInsuranceHSE + 
											(GeneralExpenses+DeploymentCosts) + 
											TotalInputIntoMoney + 
											TotalMandayCost + 
											Math.round(TotalGuaranteeLetterCost) + 
											Math.round(TotalMiscExpenseCost)
										)
										- parseInt(TotalMiscExpenseCost + rsTotalAuxiliaryCost.sumCostAB)
			// Contracts[i].GrossProfitNoIncentive = GrossProfitNoIncentive // (22)
			let RatioGrossProfitNoIncentiveAboveRevenue = !isNaN(projectEfficiency/TotalOutputIntoMoney) && TotalOutputIntoMoney != 0 ? ((projectEfficiency/TotalOutputIntoMoney)* 100).toFixed(2): 0 //Tỷ lệ LG/DT: (23) = (22)/(8)
			// let RatioGrossProfitNoIncentiveAboveRevenue = !isNaN(GrossProfitNoIncentive/TotalOutputIntoMoney) && TotalOutputIntoMoney != 0 ? GrossProfitNoIncentive/TotalOutputIntoMoney : 0 //Tỷ lệ LG/DT: (23) = (22)/(8)
			// Lãi gộp (đã bao gồm incentive): (25) = (22)+(24)
			let GrossProfitIncentive = parseInt(projectEfficiency) + parseInt(TotalSumIncentive)
			// let GrossProfitIncentive = parseInt(GrossProfitNoIncentive + Sum_Incentive(ProductCost_idContract)) 
			// Contracts[i].GrossProfitIncentive = GrossProfitIncentive //Tỷ lệ LG/DT: (25) = (22)+(24)
			// let RatioGrossProfitIncentiveAboveRevenue = !isNaN(GrossProfitIncentive/TotalOutputIntoMoney) && TotalOutputIntoMoney != 0 ? GrossProfitIncentive/TotalOutputIntoMoney : 0 //Tỷ lệ LG/DT: (26) = (25)/(8)
			let RatioGrossProfitIncentiveAboveRevenue = !isNaN(((projectEfficiency + TotalSumIncentive) / TotalOutputIntoMoney)) && isFinite(((projectEfficiency + TotalSumIncentive) / TotalOutputIntoMoney)) ? (((projectEfficiency + TotalIncentive) / TotalOutputIntoMoney) * 100).toFixed(2): 0

			//Invoices
			let Invoices_idContract = find_data_by_contract_id(Invoices, Contract._id)
			let Invoices_idContracts = find_data_by_contract_id(Invoices, Contract._id)
			let PercentInvoices = SumPercentInvoice(Invoices_idContract)
			let SumPercentInvoices = !isNaN(PercentInvoices/TotalOutputIntoMoney) ? PercentInvoices/TotalOutputIntoMoney == 1 ? 100 : ((PercentInvoices/TotalOutputIntoMoney)*100) : 0
			let lastInvoices_idContract = Invoices_idContract.length > 0 ? Invoices_idContract.pop() : ""
			let InvoiceVal = lastInvoices_idContract != "" ? !isNaN(SumPercentInvoices/TotalOutputIntoMoney) ? (SumPercentInvoices/TotalOutputIntoMoney).toFixed(2): 0 : 0
			let DateOfInvoice = lastInvoices_idContract != "" ? convert_time(lastInvoices_idContract.DateOfInvoice) : "" //Ngày xuất hóa đơn (27)
			let PercentInvoice = lastInvoices_idContract != "" ? SumPercentInvoices == 100 ? "100%" : SumPercentInvoices.toFixed(2) + "%" : "0%" //Tỷ lệ % xuất HĐ (28)
			let OutputIntoMoney = lastInvoices_idContract != "" ? SumPercentInvoices == 100 ? 0 : TotalOutputIntoMoney - PercentInvoices : "0" //Doanh thu còn lại chưa xuất HĐ (29)
			let NoteInvoices = Invoices_idContracts.length > 1 ? "Xuất hóa đơn nhiều đợt": "" //Ghi chú (30)
			
			Doanh_thu = Doanh_thu + TotalOutputIntoMoney
			Gia_von_ben_ngoai = Gia_von_ben_ngoai + ExternalCapitalCosts
			Gia_von_noi_bo = Gia_von_noi_bo + InternalCapitalCosts
			Incentive = Incentive + TotalSumIncentive
			CP_bao_hanh_HSC = CP_bao_hanh_HSC + TotalSumInsurance
			CP_ho_tro_ky_thuat_HSE = CP_ho_tro_ky_thuat_HSE + TotalInsuranceHSE
			CP_trien_khai = CP_trien_khai + TotalCostAB
			CP_manday = CP_manday + TotalMandayCost
			CP_bao_lanh = CP_bao_lanh + TotalGuaranteeLetterCost
			CP_khac = CP_khac + TotalMiscExpenseCost
			CP_vat_tu_phu = CP_vat_tu_phu + SumAuxiliaryCost
			Thue = Thue + SumAuxiliaryTax
			Tong_CP = Tong_CP + parseInt(SumAuxiliaryCostTax)
			Lai_gop_chua_co_incentive = Lai_gop_chua_co_incentive + Math.round(projectEfficiency)
			Lai_gop_da_bao_gom_incentive = Lai_gop_da_bao_gom_incentive + Math.round(GrossProfitIncentive)
			Doanh_thu_con_lai_chua_xuat_HD = Doanh_thu_con_lai_chua_xuat_HD + parseInt(OutputIntoMoney)
			CP_von = CP_von + chiPhi

			array_result = [...array_result, {
				_id: Contract._id,
				STT,
				CenterID,
				DepartmentID,
				CustomerID,
				user,
				ContractID,
				RatioUSD,
				VAT,
				Date: DateTemp,
				TotalInputIntoMoney,
				projectEfficiency,
				TotalOutputIntoMoney,
				GeneralExpenses,
				DeploymentCosts,
				TotalCostAB,
				TotalMandayCost,
				TotalGuaranteeLetterCost,
				SumAuxiliaryCost,
				SumAuxiliaryTax,
				SumAuxiliaryCostTax,
				TotalAuxiliaryAndMiscExpense,
				GrossProfitNoIncentive,
				RatioGrossProfitNoIncentiveAboveRevenue,
				GrossProfitIncentive,
				RatioGrossProfitIncentiveAboveRevenue,
				DateOfInvoice,
				PercentInvoice,
				OutputIntoMoney,
				NoteInvoices,
				SumPercentInvoices,
				TotalSumIncentive,
				TotalSumInsurance,
				ExternalCapitalCosts,
				InternalCapitalCosts,
				TotalInsuranceHSE,
				TotalMiscExpenseCost,
				chiPhi
			}];
		})

		if (isTotal && array_result.length > 0) {
			array_result = [...array_result, {
			_id: Contract._id,
			STT: "Tổng",
			CenterID: "",
			DepartmentID: "",
			CustomerID: "",
			user: "",
			ContractID: "",
			RatioUSD: "",
			VAT: "",
			Date: "",
			TotalInputIntoMoney: "",
			projectEfficiency: Lai_gop_chua_co_incentive,
			TotalOutputIntoMoney: Doanh_thu,
			GeneralExpenses: "",
			DeploymentCosts: "",
			TotalCostAB: CP_trien_khai,
			TotalMandayCost: CP_manday,
			TotalGuaranteeLetterCost: CP_bao_lanh,
			SumAuxiliaryCost: CP_vat_tu_phu,
			SumAuxiliaryTax: Thue,
			SumAuxiliaryCostTax: Tong_CP,
			TotalAuxiliaryAndMiscExpense: "",
			GrossProfitNoIncentive: Lai_gop_chua_co_incentive,
			RatioGrossProfitNoIncentiveAboveRevenue: "",
			GrossProfitIncentive: Lai_gop_da_bao_gom_incentive,
			RatioGrossProfitIncentiveAboveRevenue: "",
			DateOfInvoice: "",
			PercentInvoice: "",
			OutputIntoMoney: Doanh_thu_con_lai_chua_xuat_HD,
			NoteInvoices: "",
			SumPercentInvoices: "",
			TotalSumIncentive: Incentive,
			TotalSumInsurance: CP_bao_hanh_HSC,
			ExternalCapitalCosts: Gia_von_ben_ngoai,
			InternalCapitalCosts: Gia_von_noi_bo,
			TotalInsuranceHSE: CP_ho_tro_ky_thuat_HSE,
			TotalMiscExpenseCost: CP_khac,
			chiPhi: CP_von
		}];
		}

		return array_result
	}

	function findArrayItem(array, id) {
		return array.find(item => item._id == id); 
	}	
	// hàm convert to date time
	function convert_time(date_time){
		const date = new Date(date_time); // Replace this with your actual date object
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		const seconds = ('0' + date.getSeconds()).slice(-2);
		const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		return formattedDate;
	}

	function getCurrentDate(separator){
	    let newDate = new Date()
	    let date = newDate.getDate();
	    let month = newDate.getMonth() + 1;
	    let year = newDate.getFullYear();
	    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
	}

	function get_status_name(array,ContractID,event_array){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			let statusID = lastElement.StatusID;
			for (var i = 0; i < event_array.length; i++) {
				let value = event_array[i];
				if (value.statusID == statusID) {
					// return value.name+" ("+statusID+")";
					return value.name;
				}
			}
		}else{
			return '';
		}
	}

	function get_status(array,ContractID){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return '';
		}
	}


	const [newFilter, setNewFilter] = useState({
		fromDate:"",
		toDate: "",
		statusInvoice: "",
		DepartmentID: "",
		CenterID: "",
		staffName: "",
		grossProfitVaule: "",
		grossProfitPercentage: ""
	})
	
	const {
		fromDate,
		toDate,
		statusInvoice,
		DepartmentID,
		CenterID,
		staffName,
		grossProfitVaule,
		grossProfitPercentage
	} = newFilter

	const onChangeNewFilter = event => {
		let name = event.target.name;
		let val = event.target.value
		
		if (name == "fromDate" || name == "toDate") {
			setNewFilter({ ...newFilter, [name]: val.split("-").reverse().join("-") })
		}else if (name == "grossProfitPercentage") {
			if (!isNumberOrDecimal(val) && val != '') {
				var message = 'Vui lòng nhập tỉ lệ % không quá 2 số thập phận'
				setShowToast({ show: true, message: message, type: 'danger' })
				event.target.value = val
				return false;
			}else{
				var value = (val != "") ? val : 0;
				if (parseInt(val) > 100 && parseFloat(val) > 100) {
					var message = 'Vui lòng nhập tỷ lệ không quá 100%'
					setShowToast({ show: true, message: message, type: 'danger' })
					event.target.value = val
					return false;
				}else if (val == "00" && parseInt(val) == 0 && parseFloat(val) == 0) {
					var message = 'Vui lòng nhập tỷ lệ lớn hơn 0%'
					setShowToast({ show: true, message: message, type: 'danger' })
					event.target.value = val
					return false;
				}
				setNewFilter({ ...newFilter, [name]: val })
			}
		}
		setNewFilter({ ...newFilter, [name]: val.trim() })
	}

	const isNumberOrDecimal = userInput => {
		// if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		// return /^\d+$/.test(userInput); ok
  		// return /((?:\d+\.\d*)|(?:\d*\.?\d+))$/.test(userInput); ok
  		// return /^\d*\.?\d*$/.test(userInput) //ok
  		return /^\d+(\.\d{0,2})?$/.test(userInput)
	}

	const isParentNumber = userInput => {
		if (typeof userInput !== 'string') return false;
		return /^\d+$/.test(userInput);
	}

	function approximatelyEqual(a, b) {
	    return Math.abs(a - b) < 0.01;
	}

	const handleFilterChange = () => {
		let condition = newFilter
		condition.fromDate = (startDate != null) ? formatDate(startDate) : ''
		condition.toDate = (endDate != null) ? formatDate(endDate) : ''
		condition.invoicingTime = (invoicingTime != null) ? formatDate(invoicingTime) : ''
		let rs = loopReport()
		// console.log(condition, rs, Contracts)
		// setNewContracts(rs);
		const filteredData = rs.filter((item) => {
		    // Date range filtering
		    let inDateRange = true;

		    if (condition.fromDate) {
		        const fromDate = new Date(condition.fromDate+ " 00:00:00");
		        const date = new Date(item.Date);
		        inDateRange = date >= fromDate;
		    }

		    if (condition.toDate) {
		        const toDate = new Date(condition.toDate + " 23:59:59");
		        const date = new Date(item.Date);
		        inDateRange = inDateRange && date <= toDate;
		    }

		    // Invoice status filtering
		    let statusCondition;
		    if (condition.statusInvoice == "<100") {
		        statusCondition = item.SumPercentInvoices < 100;
		    } else if (condition.statusInvoice == "100") {
		        statusCondition = item.SumPercentInvoices == 100;
		    } else {
		        statusCondition = true; // no condition
		    }

		    // Department filtering
		    // let departmentCondition;
		    // if (condition.DepartmentID) {
		    //     departmentCondition = item.DepartmentID == condition.DepartmentID;
		    // } else {
		    //     departmentCondition = true; // no condition
		    // }

			// Centers filtering
		    let centerCondition;
		    if (condition.CenterID) {
		        centerCondition = item.CenterID == condition.CenterID;
		    } else {
		        centerCondition = true; // no condition
		    }

			// Users filtering
			let userCondition;
		    if (condition.staffName) {
		        userCondition = item.user == condition.staffName;
		    } else {
		        userCondition = true; // no condition
		    }

			//
			let inDateOfInvoice = true
			if (condition.invoicingTime) {
		        const invoicingTimeDateStart = new Date(condition.invoicingTime+ " 00:00:00");
		        const invoicingTimeDateEnd = new Date(condition.invoicingTime+ " 23:59:59");
		        const date = new Date(item.DateOfInvoice);
		        inDateOfInvoice = inDateOfInvoice && date <= invoicingTimeDateEnd && date >= invoicingTimeDateStart;
		    }

		    let grossProfitVauleCondition = true
			if (condition.grossProfitVaule) {
		        switch (condition.grossProfitVaule) {
				    case "<500M":
				        grossProfitVauleCondition = item.projectEfficiency < 499999999
				        break;
				    case "500M-1000M":
				        grossProfitVauleCondition = item.projectEfficiency <= 999999999 && item.projectEfficiency >= 500000000
				        break;
				    case "1000M-1500M":
				        grossProfitVauleCondition = item.projectEfficiency <= 1499999999 && item.projectEfficiency >= 1000000000
				        break;
				    case "1500M-2000M":
				        grossProfitVauleCondition = item.projectEfficiency <= 1999999999 && item.projectEfficiency >= 1500000000
				        break;
				    case ">2000M":
				    	grossProfitVauleCondition = item.projectEfficiency >= 2000000000
				    	break;
				}
		    }

		    let grossProfitPercentageCondition = true
			if (condition.grossProfitPercentage) {
		        grossProfitPercentageCondition = approximatelyEqual(condition.grossProfitPercentage, item.RatioGrossProfitNoIncentiveAboveRevenue)
		    }

		    // Filter by all conditions
		    return  inDateRange &&
		    		statusCondition &&
		    		centerCondition &&
		    		userCondition &&
		    		inDateOfInvoice &&
		    		grossProfitVauleCondition &&
		    		grossProfitPercentageCondition;
		});

		// console.log(1111111111111111111, filteredData, "filteredData")
		let rsfilteredData = loopReport(filteredData, true)
		setNewContracts(rsfilteredData);
		setIsSearch(true)
	}

	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
	
		if (month.length < 2) 
			month = '0' + month;
		if (day.length < 2) 
			day = '0' + day;
	
		return [year, month, day].join('-');
	}

	const resetFilter = () => {
		setNewFilter({ 
			fromDate:"",
			toDate: "",
			statusInvoice: "",
			DepartmentID: "",
			CenterID: "",
			staffName: "",
			grossProfitVaule: "",
			grossProfitPercentage: "",
	 	})
		setDateRange([null, null])
		setInvoicingTime(null)
		// let rs = loopReport()
		setNewContracts([])
		setIsSearch(false)
	}

	// const newFilterCenters = (typeof Centers != "undefined") ? Centers.filter((element) => element.CenterName !== "HO") : [];
	let newFilterCenters;
	if (typeof Centers != "undefined") {
		switch(roleName){
			case 'AM':
			case 'TPKD':
				newFilterCenters = filter_Users(Users, UserID).center_details;
				break;
			case 'KTT':
			case 'BGD':
				newFilterCenters = filter_Users(Users, UserID).center_details;
				break;
			case 'BTGD':
			case 'Admin':
				newFilterCenters = Centers;
				break;
			default: 
				newFilterCenters = []
		}
	}
	
	const Filter = () => {
		return (
			<>
			</>
		)
	}

	const [invoicingTime, setInvoicingTime] = useState(null);
	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;

	let body = null
	let stt = 1

	const {
		UserPageState: { UserPage, UserPages, UserPagesLoading },
		getAllUserPages
	} = useContext(UserPageContext)

	const {
	    MenuState: { Menu, Menus, MenusLoading },
	    getMenu,
	} = useContext(MenuContext);

	function FindArrayMenu(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return null;
		}
	}

	// console.debug("newContracts", newContracts, isSearch)

	if (ContractsLoading) {
		body = (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	}else if (Contracts.length === 0) {
		body = (
			<>
				<Card className='text-center'>
					<Card.Header as='h2'>Báo cáo Doanh thu Lãi gộp theo Hợp đồng</Card.Header>
					<Card.Body>
						<Form.Row className="form-row-custom">
							<Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>Ngày lập PTHĐ</Form.Label>
								<br></br>
								<DatePicker
									className='form-control fix-select-date-ranger'
									selectsRange={true}
									startDate={startDate}
									endDate={endDate}
									onChange={(update) => {
										setDateRange(update);
									}}
									isClearable={true}
									dateFormat="yyyy/MM/dd"
									maxDate={new Date()}
								/>
							</Form.Group>

							{/* <Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>đến ngày</Form.Label>
								<Form.Control
									type='date'
									placeholder="Đến ngày"
									name='toDate'
									value={toDate}
									onChange={onChangeNewFilter}
								/>
							</Form.Group> */}

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tình trạng hóa đơn</Form.Label>
								<Form.Control
									as="select"
									value={statusInvoice}
									name='statusInvoice'
									onChange={onChangeNewFilter}
									>
									<option value=''>-- chọn tình trạng --</option>
									<option value="100">Xuất hóa đơn đủ 100%</option>
									<option value="<100">Chưa hóa đơn đủ 100%</option>
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom fix-select-center" as="Col" controlId="formGridPassword">
								<Form.Label>Trung tâm</Form.Label>
								<Form.Control
									id = 'noidung-help'
									as="select"
									name='CenterID'
									aria-describedby='noidung-help'
									value={CenterID}
									onChange={onChangeNewFilter}>
									<option value=''>-----</option>
									{
										typeof newFilterCenters != "undefined" ? 
										newFilterCenters.map((obj => {
											return (
												<>
													<option value={obj.CenterID}>{obj.CenterName}</option>
												</>
											)
										}))
										:
										<></>
									}
									
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tài khoản nhân viên</Form.Label>
								<Form.Control
									type='text'
									placeholder=""
									name='staffName'
									value={staffName}
									onChange={onChangeNewFilter}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>Thời gian xuất hóa đơn</Form.Label>
								<br></br>
								<DatePicker
									selected={invoicingTime}
									onChange={(date) => setInvoicingTime(date)}
									className='form-control'
									dateFormat='yyyy/MM/dd'
									maxDate={new Date()}
									isClearable={true}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Giá trị lãi gộp</Form.Label>
								<Form.Control
									as="select"
									value={grossProfitVaule}
									name='grossProfitVaule'
									onChange={onChangeNewFilter}
									>
									<option value=''>-- giá trị --</option>
									<option value='<500M'>Dưới 500 triệu</option>
									<option value='500M-1000M'>500 triệu - 1 tỷ</option>
									<option value='1000M-1500M'>1 tỷ - 1 tỷ 500 triệu</option>
									<option value='1500M-2000M'>1 tỷ 500 triệu - dưới 2 tỷ</option>
									<option value='>2000M'>Trên 2 tỷ</option>
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tỷ lệ % lãi gộp</Form.Label>
								<Form.Control
									type='text'
									placeholder=""
									name='grossProfitPercentage'
									value={grossProfitPercentage}
									onChange={onChangeNewFilter}
								/>
							</Form.Group>
						</Form.Row>

						<Form.Row className="form-row-custom">
							<Form.Group as="Col" controlId="formGridPassword">
								<Form.Label>&nbsp;</Form.Label>
								<Col xs="auto">
									<OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Xóa lọc báo cáo
											</Tooltip>
										}
									>
									<a onClick={resetFilter}>
										<img src={iconFilterReset} width="35" height="35"></img>
									</a>
									</OverlayTrigger>
								</Col>
							</Form.Group>

							<Form.Group as="Col" controlId="formGridPassword">
								<Form.Label>&nbsp;</Form.Label>
								<Col xs="auto">
									{/* <OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Lọc báo cáo
											</Tooltip>
										}
									>
									<a onClick={handleFilterChange}>
										<img src={iconViewReport} width="35" height="35"></img>
									</a>
									</OverlayTrigger> */}

									<Button
										variant='primary'
										onClick={handleFilterChange}
									>
										Lọc báo cáo
									</Button>
								</Col>
							</Form.Group>
						</Form.Row>

						<div id="table-scroll" className="table-scroll table-class-data-0">
							<table id="main-table" className="main-table table table-lg table-hover">
								<thead>
									<tr>
										<th scope="col" className='z-index-th header-table'>STT</th>
										<th scope="col" className='z-index-th header-table'>Ngày lập PTHD</th>
										<th scope="col" className='z-index-th header-table'>Số hợp đồng</th>
										<th scope="col" className='z-index-th header-table'>Tên khách hàng</th>
										<th scope="col" className='z-index-th header-table'>Tên trung tâm</th>
										<th scope="col" className='z-index-th header-table'>Tên phòng ban</th>
										<th scope="col" className='header-table'>Tên NV</th>
										<th scope="col" className='header-table'>Doanh thu</th>
										<th scope="col" className='header-table'>Lãi gộp(chưa có incentive)</th>
										<th scope="col" className='header-table'>Tỷ lệ LG/DT</th>
										<th scope="col" className='header-table'>Lãi gộp(đã bao gồm incentive)</th>
										<th scope="col" className='header-table'>Tỷ lệ LG (gồm incentive)/DT</th>
										<th scope="col" className='header-table'>Ngày xuất hóa đơn</th>
										<th scope="col" className='header-table'>Tình trạng hóa đơn</th>
									</tr>
								</thead>
								<tbody>
									
								</tbody>
							</table>
						</div>
					</Card.Body>
				</Card>
			</>
		)
	} else {
		body = (
			<>
				<Card className='text-center'>
					<Card.Header as='h2'>Báo cáo Doanh thu Lãi gộp theo Hợp đồng</Card.Header>
					<Card.Body>
						<Form.Row className="form-row-custom">
							<Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>Ngày lập PTHĐ</Form.Label>
								<br></br>
								<DatePicker
									className='form-control fix-select-date-ranger'
									selectsRange={true}
									startDate={startDate}
									endDate={endDate}
									onChange={(update) => {
										setDateRange(update);
									}}
									isClearable={true}
									dateFormat="yyyy/MM/dd"
									maxDate={new Date()}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tình trạng hóa đơn</Form.Label>
								<Form.Control
									as="select"
									value={statusInvoice}
									name='statusInvoice'
									onChange={onChangeNewFilter}
									>
									<option value=''>-- chọn tình trạng --</option>
									<option value="100">Xuất hóa đơn đủ 100%</option>
									<option value="<100">Chưa hóa đơn đủ 100%</option>
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom fix-select-center" as="Col" controlId="formGridPassword">
								<Form.Label>Trung tâm</Form.Label>
								<Form.Control
									id = 'noidung-help'
									as="select"
									name='CenterID'
									aria-describedby='noidung-help'
									value={CenterID}
									onChange={onChangeNewFilter}>
									<option value=''>-----</option>
									{
										typeof newFilterCenters != "undefined" ? 
										newFilterCenters.map((obj => {
											return (
												<>
													<option value={obj.CenterName.trim()}>{obj.CenterName.trim()}</option>
												</>
											)
										}))
										:
										<></>
									}
									
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tài khoản nhân viên</Form.Label>
								<Form.Control
									type='text'
									placeholder=""
									name='staffName'
									value={staffName}
									onChange={onChangeNewFilter}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>Thời gian xuất hóa đơn</Form.Label>
								<br></br>
								<DatePicker
									selected={invoicingTime}
									onChange={(date) => setInvoicingTime(date)}
									className='form-control'
									dateFormat='yyyy/MM/dd'
									maxDate={new Date()}
									isClearable={true}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Giá trị lãi gộp</Form.Label>
								<Form.Control
									as="select"
									value={grossProfitVaule}
									name='grossProfitVaule'
									onChange={onChangeNewFilter}
									>
									<option value=''>-- giá trị --</option>
									<option value='<500M'>Dưới 500 triệu</option>
									<option value='500M-1000M'>500 triệu - 1 tỷ</option>
									<option value='1000M-1500M'>1 tỷ - 1 tỷ 500 triệu</option>
									<option value='1500M-2000M'>1 tỷ 500 triệu - dưới 2 tỷ</option>
									<option value='>2000M'>Trên 2 tỷ</option>
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tỷ lệ % lãi gộp</Form.Label>
								<Form.Control
									type='text'
									placeholder=""
									name='grossProfitPercentage'
									value={grossProfitPercentage}
									onChange={onChangeNewFilter}
								/>
							</Form.Group>
						</Form.Row>

						<Form.Row className="form-row-custom">
							<Form.Group as="Col" controlId="formGridPassword">
								<Form.Label>&nbsp;</Form.Label>
								<Col xs="auto">
									<OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Xóa lọc báo cáo
											</Tooltip>
										}
									>
									<a onClick={resetFilter}>
										<img src={iconFilterReset} width="35" height="35"></img>
									</a>
									</OverlayTrigger>
								</Col>
							</Form.Group>

							<Form.Group as="Col" controlId="formGridPassword">
								<Form.Label>&nbsp;</Form.Label>
								<Col xs="auto">
									{/* <OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Lọc báo cáo
											</Tooltip>
										}
									>
									<a onClick={handleFilterChange}>
										<img src={iconViewReport} width="35" height="35"></img>
									</a>
									</OverlayTrigger> */}

									<Button
										variant='primary'
										onClick={handleFilterChange}
									>
										Lọc báo cáo
									</Button>
								</Col>
							</Form.Group>
						</Form.Row>

						<div id="table-scroll" className={newContracts.length > 15 ? "table-scroll": "table-scroll table-scroll-fit-content"}>
							<table id="main-table" className="main-table table table-lg table-hover">
								<thead>
									<tr>
										<th scope="col" className='z-index-th header-table'>STT</th>
										<th scope="col" className='z-index-th header-table'>Ngày lập PTHD</th>
										<th scope="col" className='z-index-th header-table'>Số hợp đồng</th>
										<th scope="col" className='z-index-th header-table'>Tên khách hàng</th>
										<th scope="col" className='z-index-th header-table'>Tên trung tâm</th>
										<th scope="col" className='z-index-th header-table'>Tên phòng ban</th>
										<th scope="col" className='header-table'>Tên NV</th>
										<th scope="col" className='header-table'>Doanh thu</th>
										<th scope="col" className='header-table'>Lãi gộp(chưa có incentive)</th>
										<th scope="col" className='header-table'>Tỷ lệ LG/DT</th>
										<th scope="col" className='header-table'>Lãi gộp(đã bao gồm incentive)</th>
										<th scope="col" className='header-table'>Tỷ lệ LG (gồm incentive)/DT</th>
										<th scope="col" className='header-table'>Ngày xuất hóa đơn</th>
										<th scope="col" className='header-table'>Ghi chú</th>
									</tr>
								</thead>
								<tbody>
									{
										isSearch ? 
										<>
										{
											newContracts.length ? 
											<>
											{
												newContracts.map(Contract => (
													<tr className={(Contract.STT == "Tổng") ? "classNameTotal": ""} >
														<th scope="col" className="custom-font-w">{(Contract.STT == "Tổng") ? "Tổng": stt++}</th>
														<th scope="col" className="custom-font-w fm-col-string">{Contract.Date}</th>
														<th scope="col" className="custom-font-w fm-col-string">{Contract.ContractID}</th>
														<th scope="col" className="custom-font-w fm-col-string">
															<span
															className='text'
															title={Contract.CustomerID}
															>
																{Contract.CustomerID}
															</span>
														</th>
														<th scope="col" className="custom-font-w fm-col-string">
															<span
															className='text'
															title={Contract.CenterID}
															>
																{Contract.CenterID}
															</span>
														</th>
														<th scope="col" className="custom-font-w fm-col-string">
															<span
															className='text'
															title={Contract.DepartmentID}
															>
																{Contract.DepartmentID}
															</span>
														</th>
														<td scope="col" className="fm-col-string">{Contract.user}</td>
														<td scope="col" className="fm-col-number">{Contract.TotalOutputIntoMoney.toLocaleString()}</td>
														<td scope="col" className="fm-col-number">{Math.round(Contract.projectEfficiency).toLocaleString()}</td>
														<td scope="col" className="fm-col-number">
															{
																Contract.STT == "Tổng" ? 
																	"" 
																	:
																	Contract.RatioGrossProfitNoIncentiveAboveRevenue.toLocaleString() + "%"
															}
														</td>
														<td scope="col" className="fm-col-number">{Math.round(Contract.GrossProfitIncentive).toLocaleString()}</td>
														<td scope="col" className="fm-col-number">
															{
																Contract.STT == "Tổng" ? 
																	"" 
																	:
																	Contract.RatioGrossProfitIncentiveAboveRevenue.toLocaleString() + "%"
															}
														</td>
														<td scope="col">
															{
																Contract.STT == "Tổng" ? 
																	"" 
																	:
																	Contract.DateOfInvoice != "" ? 
																	Contract.DateOfInvoice.toLocaleString()
																	: 
																	"Chưa xuất hóa đơn"		
															}
														</td>
														<td scope="col">
															{
																Contract.STT == "Tổng" ? "" : Contract.NoteInvoices
															}
														</td>
													</tr>
												))
											}
											</>
											:
											<>
												<tr>
													<th scope="col" colSpan={7} className="custom-font-w">Không tìm thấy dữ liệu phù hợp</th>
													<td scope="col" colSpan={21}></td>
												</tr>
											</>
										}
										</>
										:
										<>
											<tr>
												<th scope="col" colSpan={7} className="custom-font-w">Chọn điều kiện để lọc báo cáo</th>
												<td scope="col" colSpan={21}></td>
											</tr>
										</>
									}
								</tbody>
							</table>
						</div>
					</Card.Body>
				</Card>
			</>
		)
	}

	if (UserPagesLoading && MenusLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	}else {
		const result = UserPages.filter(obj => { return obj.user_id.some(id => id === UserID); });
		let menu_access = false
		if (result.length > 0) {
			for (var i = 0; i < result.length; i++) {
				let menuV1 = FindArrayMenu(Menus, result[i].page_id[0])
				if (menuV1 != null && menuV1.path == window.location.pathname) {
					// console.debug("menuV1 co quyen truy cap")
					menu_access = true
					break;
				}else {
					// console.debug("menuV1 khong quyen truy cap")
				}
			}
		} else {
			// console.debug("menu khong quyen truy cap")
		}

		if (menu_access) {
			return (
				<>
					{body}
					<Toast
						show={show}
						style={{ position: 'fixed', top: '20%', right: '10px', zIndex: '999999'}}
						className={`bg-${type} text-white`}
						onClose={setShowToast.bind(this, {
							show: false,
							message: '',
							type: null
						})}
						delay={3000}
						autohide
					>
						<Toast.Body>
							<strong>{message}</strong>
						</Toast.Body>
					</Toast>
				</>
			)
		}else {
			return (
				<>
					<div id="notfound">
						<div className="notfound">
							<h1>Oops!</h1>
							<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
								<br/>
								<Link style={{textDecoration: "none"}} to="/home">
									Trở về trang chủ
								</Link>
							</h6>
						</div>
					</div>
				</>
			)
		}
	}
}

export const Report_Manday_Costs = () => {
	//
	const {
		UserState: { User, Users, UsersLoading },
		getUsers
	} = useContext(UserContext)

	const {
		HistoryState: { History, Historys, HistorysLoading },
		getHistory
	} = useContext(HistoryContext)

	const {
		EventState: { Event, Events, EventsLoading },
		getEvent
	} = useContext(EventContext)

	//=== Get All Productcosts
	const {
		ProductCostState: { ProductCost, ProductCosts, ProductCostsLoading },
		getProductCosts,
	} = useContext(ProductCostContext)

	//=== Get MandayCost 
	const {
		MandayCostState: { MandayCost, MandayCosts, MandayCostsLoading },
		getMandayCosts,
	} = useContext(MandayCostContext)

	//=== Get GuaranteeLetterCost
	const {
		GuaranteeLetterCostState: { GuaranteeLetterCost, GuaranteeLetterCosts, GuaranteeLetterCostsLoading },
		getGuaranteeLetterCosts,
	} = useContext(GuaranteeLetterCostContext)

	//=== Get MiscExpenseCosts
	const {
		MiscExpenseCostState: { MiscExpenseCost, MiscExpenseCosts, MiscExpenseCostsLoading },
		getMiscExpenseCosts,
	} = useContext(MiscExpenseCostContext)

	//=== Get data CapitalExpenditureCost chi phi von
	const {
		CapitalExpenditureCostState: { CapitalExpenditureCost, CapitalExpenditureCosts, CapitalExpenditureCostsLoading },
		getCapitalExpenditureCosts,
	} = useContext(CapitalExpenditureCostContext);

	//=== Get data LoanInterest chi phi lãi vay
	const {
	    LoanInterestState: { LoanInterest, LoanInterests, LoanInterestsLoading },
	    getLoanInterest
	} = useContext(LoanInterestContext);
	
	//=== Get data AuxiliaryCost
	const {
		AuxiliaryCostState: { AuxiliaryCosts },
		getAuxiliaryCosts
	} = useContext(AuxiliaryCostContext)

	//=== Get data ImplementationCost
	const {
		ImplementationCostState: { ImplementationCosts },
		getImplementationCost
	} = useContext(ImplementationCostContext)

	//== Get data Invoices
	const {
		InvoiceState: { Invoices },
		getAllInvoice
	} = useContext(InvoiceContext)

	//== Get data Centers
	const {
		CenterState: { Centers, CentersLoading },
		get_All_Center
	} = useContext(ConfigCenterContext)

	//== Get data Departments
	const {
		DepartmentState: { Department, Departments, DepartmentsLoading },
		getDepartment
	} = useContext(ConfigDepartmentContext)

	//== Get data Customers
	const {
		CustomerState: { Customer, Customers, CustomersLoading },
		get_All_Customer
	} = useContext(ConfigCustomerContext)

	//== Get data Contracts
	const {
		ContractState: { Contract, Contracts, ContractsLoading },
		getContracts,
		showToast: { show, message, type },
		setShowToast,
		showToast,
	} = useContext(ContractContext)
	//== Get data auth
	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)
	//== Get data Roles
	const {
		RoleState: { Role, Roles, RolesLoading },
		getRoles,
	} = useContext(RoleContext)
	//== Get data UserManager
	const {
		UserManagerState: { UserManager, UserManagers, UserManagersLoading },
		getAllUserManagers
	} = useContext(UserManagerContext)
	
	useEffect(() => get_All_Center(), [])
	useEffect(() => getDepartment(), [])
	useEffect(() => get_All_Customer(), [])
	useEffect(() => getUsers(), [])
	useEffect(() => getHistory(), [])
	useEffect(() => getEvent(), [])
	useEffect(() => getProductCosts(), [])
	useEffect(() => getMandayCosts(), [])
	useEffect(() => getGuaranteeLetterCosts(), [])
	useEffect(() => getMiscExpenseCosts(), [])
	useEffect(() => getCapitalExpenditureCosts(), [])
	useEffect(() => getLoanInterest(), [])
	useEffect(() => getAuxiliaryCosts(), [])
	useEffect(() => getImplementationCost(), [])
	useEffect(() => getAllInvoice(), [])
	useEffect(() => getContracts(), [])
	useEffect(() => getRoles(), [])
	useEffect(() => getAllUserManagers(), [])

	const [newContracts, setNewContracts] = useState([])
	const [isSearch, setIsSearch] = useState(false)
	let role_new = roles[0];
	let UserID = _id;
	let roleName = FindArray(Roles, role_new).name;
	// console.log("Report", role_new, UserID, roleName, MiscExpenseCosts)

	//
	function filter_new_contract(array,UserID){
		let returnObject = "";
		// if (UserID != undefined) returnObject = array.filter((element) => element.user.centers != null ? element.user.centers[0] : '' == UserID[0]);
		if (UserID != undefined) returnObject = array.filter((element) => element.CenterID == UserID[0]);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	let newContractsss;
	let newContract111 = [];
	if(roleName == 'Admin' || roleName == 'BTGD'){
		newContractsss = Contracts
	}else if(roleName == 'KTT' || roleName == 'BGD'){
		let centerOfUser = filter_Users(Users,UserID).centers;
		if (centerOfUser != undefined) {
			if(Contracts.length > 0){
				newContract111 = filter_new_contract(Contracts,centerOfUser);
			}
		}
		newContractsss = newContract111;
	}else{
		let newContract1 = Contracts.filter((v) => get_user_id(UserID,UserManagers).includes(v.user._id))
		if(roleName == 'AM'){
			newContractsss = newContract1;
		}else{
			let contract_me = Contracts.filter((v) => v.user._id == UserID)

			let newContract2 = newContract1.concat(contract_me);
			newContractsss = newContract2;
		}
	}

	function get_user_id(userLogin, userManager) {
		let managedUsers = [];
		// managedUsers.push(userLogin)

		function findManagedUsers(managerId) {
			let users = userManager.filter(item => item.manager_id[0] === managerId);
			if (users.length > 0) {
				for (let user of users) {
					findManagedUsers(user.user_id[0]);
				}
			} else {
				managedUsers.push(managerId);
			}
		}

		findManagedUsers(userLogin);
		return managedUsers;
	}

	function filter_Users(array,UserID){
		let returnObject = "";
		if (UserID != undefined) returnObject = array.filter((element) => element._id === UserID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return '';
		}
	}


	function filter_history(array,ContractID){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return '';
		}
	}
	//
	function totalCenter() {
		let totalCenter = 0;
		if (typeof Centers != undefined) totalCenter = Centers.length;
		return totalCenter;
	}

	function totalDepartment() {
		let totalDepartment = 0;
		if (typeof Departments != undefined) totalDepartment = Departments.length;
		return totalDepartment;
	}

	function totalContract() {
		let totalContract = 0;
		if (typeof Contracts != undefined) totalContract = Contracts.length;
		return totalContract;
	}

	function totalUser() {
		let totalUser = 0;
		if (typeof Users != undefined) totalUser = Users.length;
		return totalUser;
	}

	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id == ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	// table ranking
	//Ham tinh tong Phan tử trong kieu mang 
	function Sum_InputIntoMoney(ProductsCost) {
		let Total_InputIntoMoney = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_InputIntoMoney += ListProduct.InputIntoMoney
			)))

		return Total_InputIntoMoney;
	}
	// input into money with check mua nội bộ
	function Sum_InputIntoMoneyTypePurchase(ProductsCost, InternalPurchase) {
		let Total_InputIntoMoney = 0;

		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_InputIntoMoney += (InternalPurchase == 1) 
					? (ListProduct.InternalPurchase == true) ? ListProduct.InputIntoMoney : 0
					: (ListProduct.InternalPurchase == false) ? ListProduct.InputIntoMoney : 0
			)))

		return Total_InputIntoMoney;
	}
	//Ham tinh tong Phan tử trong kieu mang 
	function Sum_OutputIntoMoney(ProductsCost) {
		let Total_OutputIntoMoney = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_OutputIntoMoney += ListProduct.OutputIntoMoney
			)))

		return Total_OutputIntoMoney;
	}

	//Tinh tong thanh tien gia ban voi Insurance = true
	function Sum_Insurance(ProductsCost) {
		let Total_Insurance = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				ListProduct.Insurance === true ? Total_Insurance += ListProduct.OutputIntoMoney : 0
			)))

		return Total_Insurance;
	}

	function Sum_InsuranceHSE(ProductsCost) {
		let Total_InsuranceHSE = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				ListProduct.InsuranceHSE === true ? Total_InsuranceHSE += (ListProduct.OutputIntoMoney*((ListProduct.PercentHSE != null ? ListProduct.PercentHSE : 1) / 100)) : 0
			)))
		return Total_InsuranceHSE;
	}

	// hàm tính tổng thành tiền
	function sumArray(mang) {
		let sum = 0;
		mang.map(function (value) { sum += value; });
		return sum;
	}
	//hàm tìm data theo contract id
	function find_data_by_contract_id(data, idContract, children = null) {
		let rs = [];
		if (children == 1) {
			rs = data.filter(x => (x.contract != null && x.contract._id == idContract))
		}else {
			rs = data.filter(x => x.contract == idContract)
		}
		return rs
	}

	// hàm đổi giá trị chi phí % => giá trị tiền tuyệt đối
	function changeCost(Cost, TotalOutputIntoMoney) {
		let returnValue = Cost;
		if (Cost < 1)
			returnValue = Cost * TotalOutputIntoMoney;
		return returnValue
	}

	function SumListCost(Auxiliary, TotalOutputIntoMoney) {
		let kq = 0;
		Auxiliary.map(AuxiliaryCost =>
			AuxiliaryCost.ListCosts.map(ListCost => (
				kq += changeCost(ListCost.Cost, TotalOutputIntoMoney)
			)))

		return kq;
	}
	
	function FindPlan(Auxiliary) {
		let returnPlan = 0;
		Auxiliary.map(AuxiliaryCost => returnPlan = AuxiliaryCost.Plan
		)
		return returnPlan;
	}

	function totalAuxiliaryCost(AuxiliaryCosts, TotalOutputIntoMoney){
		let sumCostTaxA = 0;
		let sumCostTaxB = 0;
		
		let sumCostA = 0;
		let sumCostB = 0;
		
		let sumTaxA = 0;
		let sumTaxB = 0;
		
		AuxiliaryCosts.map(AuxiliaryCost => (
			AuxiliaryCost.ListCosts.map(function(ListCost, index) {
				var newCost = 0
				var newTax = 0
				var newCostTax = 0
				if (AuxiliaryCost.Plan == 1){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost * ListCost.TaxRate)/100).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}else if (AuxiliaryCost.Plan == 2){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost / (100 - ListCost.TaxRate)) * ListCost.TaxRate).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostA += parseInt(newCost);
				}else if (AuxiliaryCost.Plan == 2){
					sumCostB += parseInt(newCost);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumTaxA += parseInt(newTax);
				}else if (AuxiliaryCost.Plan == 2){
					sumTaxB += parseInt(newTax);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostTaxA += parseInt((newCost+newTax));
				}else if (AuxiliaryCost.Plan == 2){
					sumCostTaxB += parseInt((newCost+newTax));
				}
			})
		))
		let muc2_1 = parseInt((sumCostA + sumCostB)/10);
		let muc2_thue = parseInt((sumTaxA + sumTaxB)/10);
		let muc2 = parseInt(muc2_1 + muc2_thue);
		return {muc2, muc2_1, muc2_thue}
	}

	function totalAuxiliaryCosts(AuxiliaryCosts, TotalOutputIntoMoney){
		let sumCostTaxA = 0;
		let sumCostTaxB = 0;
		
		let sumCostA = 0;
		let sumCostB = 0;
		
		let sumTaxA = 0;
		let sumTaxB = 0;
		
		AuxiliaryCosts.map(AuxiliaryCost => (
			AuxiliaryCost.ListCosts.map(function(ListCost, index) {
				var newCost = 0
				var newTax = 0
				var newCostTax = 0
				if (AuxiliaryCost.Plan == 1){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost * ListCost.TaxRate)/100).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}else if (AuxiliaryCost.Plan == 2){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost / (100 - ListCost.TaxRate)) * ListCost.TaxRate).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostA += parseInt(newCost);
				}else if (AuxiliaryCost.Plan == 2){
					sumCostB += parseInt(newCost);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumTaxA += parseInt(newTax);
				}else if (AuxiliaryCost.Plan == 2){
					sumTaxB += parseInt(newTax);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostTaxA += parseInt((newCost+newTax));
				}else if (AuxiliaryCost.Plan == 2){
					sumCostTaxB += parseInt((newCost+newTax));
				}
			})
		))

		let sumCostAB = parseInt(sumCostA + sumCostB);
		let sumTaxAB = parseInt(sumTaxA + sumTaxB);
		let sumCostTaxAB = parseInt(sumCostTaxA + sumCostTaxB);
		let muc2_1 = parseInt((sumCostA + sumCostB)/10);
		let muc2_thue = parseInt((sumTaxA + sumTaxB)/10);
		let muc2 = parseInt(muc2_1 + muc2_thue);
		return {sumCostAB, sumTaxAB, sumCostTaxAB, muc2, muc2_1, muc2_thue}
	}

	function TotalStageImplementation(ImplementationCosts) {
		let All_Total = 0;
		ImplementationCosts.map((element) => (
			element.StagesImplementation.map((object) => (
				object.Costs.map((Costs) =>
					All_Total += Costs.IntoMoney
				)))))
		return All_Total;
	}

	function TotalStageImplementationAB(ImplementationCosts, type = null) {
		let All_Total = 0;
		let ImplementationCostNew = "";
		ImplementationCostNew = ImplementationCosts.filter(x => x.Category == type);
		ImplementationCostNew.map((element) => (
			element.StagesImplementation.map((object) => (
				object.Costs.map((Costs) =>
					All_Total += Costs.IntoMoney
				)))))
		return All_Total;
	}

	function TotalCostAuxiliaryCosts(AuxiliaryCosts) {
		let All_Total = 0;
		AuxiliaryCosts.map((element) => {
			element.ListCosts.map((Costs) => {
				All_Total += Costs.Cost
			})
		})
		return All_Total;
	}

	function TotalTaxAuxiliaryCosts(AuxiliaryCosts) {
		let All_Total = 0;
		AuxiliaryCosts.map((element) => {
			element.ListCosts.map((Costs) => {
				All_Total += Costs.Tax
			})
		})
		return All_Total;
	}

	function Sum_Incentive(ProductsCost) {
		let Total_Incentive = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_Incentive += ListProduct.Incentive
			)))

		return Total_Incentive;
	}

	function SumPercentInvoice(Invoices) {
		let Total_PercentInvoice = 0;
		Invoices.map(invoice => (
			Total_PercentInvoice += invoice.InvoiceVal
		))

		return Total_PercentInvoice;
	}

	useEffect(() => {
		// let rs = loopReport()
		setNewContracts([])
	}, [Contracts]);
	////////////////////////
	
	function loopReport(newContract = null, isTotal = false) {
		let newContracts = newContract == null ? newContractsss : newContract
		let array_result = []

		//
		let Doanh_thu = 0
		let Gia_von_ben_ngoai = 0
		let Gia_von_noi_bo = 0
		let Incentive = 0
		let CP_bao_hanh_HSC = 0
		let CP_ho_tro_ky_thuat_HSE = 0
		let CP_trien_khai = 0
		let CP_von = 0
		let CP_manday = 0
		let CP_bao_lanh = 0
		let CP_khac = 0
		let CP_vat_tu_phu = 0
		let Thue = 0
		let Tong_CP = 0
		let Lai_gop_chua_co_incentive = 0
		let Lai_gop_da_bao_gom_incentive = 0
		let Doanh_thu_con_lai_chua_xuat_HD = 0
		//

		newContracts.map(function (Contract, i){
			console.debug(Contract, i)
			let ContractID = Contract.ContractID
			let RatioUSD = Contract.RatioUSD
			let VAT = Contract.VAT
			let STT = parseInt(i+1);
			let findCenters = (findArrayItem(Centers, Contract.CenterID) != undefined) ? findArrayItem(Centers, Contract.CenterID) : findArrayItem(Centers, Contract.CenterID)
			let findDepartments = (findArrayItem(Departments, Contract.DepartmentID) != undefined) ? findArrayItem(Departments, Contract.DepartmentID): findArrayItem(Departments, Contract.DepartmentID)
			let findCustomers = (findArrayItem(Customers, Contract.CustomerID) != undefined) ? findArrayItem(Customers, Contract.CustomerID): findArrayItem(Customers, Contract.CustomerID)
	
			let CenterID = findCenters != undefined ? findCenters.CenterName.trim() : Contract.CenterID
			let DepartmentID = findDepartments != undefined ? findDepartments.DepartmentName : Contract.DepartmentID
			let CustomerID = findCustomers != undefined ? findCustomers.CustomerName : Contract.CustomerID
			let user = newContract == null ? Contract.user.username : Contract.user
			let DateTemp = convert_time(Contract.Date != null ? Contract.Date : getCurrentDate("-"))
			// let StatusID = get_status(Historys,Contract._id) != '' ? get_status(Historys,Contract._id).StatusID : '0'
			// let StatusName = get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys,Contract._id,Events) : ''

			//ProductCost
			let ProductCost_idContract = find_data_by_contract_id(ProductCosts, Contract._id); // contract
			const TotalInsurance = 0.006 * Sum_Insurance(ProductCost_idContract)
			const TotalInsuranceHSE = Sum_InsuranceHSE(ProductCost_idContract)
			const TotalInputIntoMoney = Sum_InputIntoMoney(ProductCost_idContract)
			const TotalOutputIntoMoney = Sum_OutputIntoMoney(ProductCost_idContract)
			let TotalIncentive = 0;
			if(ProductCost_idContract[0] !== undefined){
				TotalIncentive = sumArray(ProductCost_idContract[0].ListProducts.map((ProductCost) => ProductCost.Incentive))//note
			}

			// MandayCost
			let MandayCost_idContract = find_data_by_contract_id(MandayCosts, Contract._id); // contract
			const TotalMandayCost = sumArray(MandayCost_idContract.map((MandayCost) => MandayCost.IntoMoney))
			//

			//GuaranteeLetterCost
			let GuaranteeLetterCost_idContract = find_data_by_contract_id(GuaranteeLetterCosts, Contract._id); // contract
			const TotalGuaranteeLetterCost = sumArray(GuaranteeLetterCost_idContract.map((GuaranteeLetterCost) => GuaranteeLetterCost.IntoMoney))

			//MiscExpenseCosts
			let MiscExpenseCost_idContract = find_data_by_contract_id(MiscExpenseCosts, Contract._id, 1);
			const TotalMiscExpenseCost = sumArray(MiscExpenseCost_idContract.map((MiscExpenseCost) => MiscExpenseCost.Cost))

			//CapitalExpenditureCosts
			let CapitalExpenditureCost_idContract = find_data_by_contract_id(CapitalExpenditureCosts, Contract._id);
			// let newCapitalExpenditureCost = sumArray(CapitalExpenditureCost_idContract.map((CapitalExpenditureCost) => CapitalExpenditureCost.CapitalExpense))//ch
			// let TotalCapitalExpense = ((CapitalExpenditureCost_idContract.InventoryDays + CapitalExpenditureCost_idContract.ImplementationDays - CapitalExpenditureCost_idContract.BedtDays) * CapitalExpenditureCost_idContract.CapitalCost + CapitalExpenditureCost_idContract.DebtCollectionDays * (CapitalExpenditureCost_idContract.Revenue - CapitalExpenditureCost_idContract.Deposits + CapitalExpenditureCost_idContract.DepositsNTP)) * (CapitalExpenditureCost_idContract.PercentInterest /100) / 365;
			let newCapitalExpenditureCost = {
				CapitalCost: 0, 
			    Revenue: 0,
			    CapitalExpense: 0,
			    InventoryDays: 15,
			    ImplementationDays: 30,
			    BedtDays: 30,
			    DebtCollectionDays: 15,
			    Deposits: 0,
			    DepositsNTP: 0,
			    PercentInterest: 10,
			    Note: '',
				ContractID: Contract._id
			};

			// Load data tu Form 1: Lay tong gia von va Doanh thu
	        newCapitalExpenditureCost.CapitalCost = TotalInputIntoMoney;
	        newCapitalExpenditureCost.Revenue = TotalOutputIntoMoney;
	        //Tinh Dat coc cua khach hang
			newCapitalExpenditureCost.Deposits = newCapitalExpenditureCost.Revenue * 0.2
			//Tinh Dat coc NTP
			newCapitalExpenditureCost.DepositsNTP = newCapitalExpenditureCost.CapitalCost * 0.3
			//Tinh chi phi von
		    newCapitalExpenditureCost.CapitalExpense = (typeof CapitalExpenditureCost_idContract[0] != "undefined") ? ((CapitalExpenditureCost_idContract[0].InventoryDays + CapitalExpenditureCost_idContract[0].ImplementationDays - CapitalExpenditureCost_idContract[0].BedtDays) * newCapitalExpenditureCost.CapitalCost + newCapitalExpenditureCost.DebtCollectionDays * (newCapitalExpenditureCost.Revenue - newCapitalExpenditureCost.Deposits + newCapitalExpenditureCost.DepositsNTP)) * (CapitalExpenditureCost_idContract[0].PercentInterest /100) / 365 : 0 ;
		    let TotalCapitalExpense = newCapitalExpenditureCost.CapitalExpense
			// console.debug("CapitalExpenditureCosts newCapitalExpenditureCost::::::", newCapitalExpenditureCost)

			//LoanInterests
			let LoanInterests_idContract = find_data_by_contract_id(LoanInterests, Contract._id);
			const TotalLoanInterest = sumArray(LoanInterests_idContract.map((LoanInterest ) => LoanInterest.InterestLoan))

			let chiPhi = 0;
			if(TotalCapitalExpense != 0){
				chiPhi = TotalCapitalExpense;
			}else{
				if(TotalLoanInterest != 0){
					chiPhi = TotalLoanInterest;
				}
			}

			//AuxiliaryCosts
			let AuxiliaryCost_idContract = find_data_by_contract_id(AuxiliaryCosts, Contract._id, 1);
			const TotalCost = SumListCost(AuxiliaryCost_idContract, TotalOutputIntoMoney);
			const rsTotalAuxiliaryCost = totalAuxiliaryCosts(AuxiliaryCost_idContract, TotalOutputIntoMoney)
			//ImplementationCost
			let ImplementationCost_idContract = find_data_by_contract_id(ImplementationCosts, Contract._id);
			const ImplementationCost = TotalStageImplementation(ImplementationCost_idContract);

			// 1.1 Chi phí triển khai hợp đồng + Chi phi Bảo hiểm
			const TotalImplementationCost = ImplementationCost + TotalInsurance + TotalInsuranceHSE;
			// 1. chi phi phat sinh khi thuc hien du an
			const ExtraCost = (TotalImplementationCost + chiPhi + TotalMandayCost + TotalGuaranteeLetterCost + TotalMiscExpenseCost)
			// 2.
			// 3.
			const projectEfficiency = (TotalOutputIntoMoney - TotalInputIntoMoney - ExtraCost - (rsTotalAuxiliaryCost.muc2*10))
			// console.log("rsTotalAuxiliaryCost", rsTotalAuxiliaryCost, "projectEfficiency", projectEfficiency)
			// Contracts[i].TotalInputIntoMoney = TotalInputIntoMoney //Chi phí
			// Contracts[i].projectEfficiency = Math.round(projectEfficiency) //Hiệu quả dự án
			// Contracts[i].TotalOutputIntoMoney = TotalOutputIntoMoney //Tổng doanh thu (8)

			//report 
			let ExternalCapitalCosts = Sum_InputIntoMoneyTypePurchase(ProductCost_idContract, false) //Giá vốn bên ngoài (9)
			let InternalCapitalCosts = Sum_InputIntoMoneyTypePurchase(ProductCost_idContract, true) //Giá vốn bên ngoài (10)
			let TotalSumIncentive = Sum_Incentive(ProductCost_idContract) //Incentive (11)
			let TotalSumInsurance = TotalInsurance //CP bảo hành (HSC) (12)
			// let TotalInsuranceHSE = TotalInsuranceHSE; //CP hỗ trợ kỹ thuật (HSE) (13)

			// CP triển khai (14)
			let GeneralExpenses = TotalStageImplementationAB(ImplementationCost_idContract, "a_chi_phi_chung")
			let DeploymentCosts = TotalStageImplementationAB(ImplementationCost_idContract, "b_chi_phi_trien_khai")
			// Contracts[i].GeneralExpenses = GeneralExpenses; //Giá trị thành tiền của Tổng A (Chi phí chung)
			// Contracts[i].DeploymentCosts = DeploymentCosts; //Giá trị thành tiền Tổng B (Chi phí triển khai)
			let TotalCostAB = (GeneralExpenses + DeploymentCosts); //Giá trị thành tiền Tổng của A và B

			// Contracts[i].TotalInputIntoMoney = TotalInputIntoMoney; //CP vốn (15)
			// Contracts[i].TotalMandayCost = TotalMandayCost; //CP manday (16)
			// Contracts[i].TotalGuaranteeLetterCost = Math.round(TotalGuaranteeLetterCost) //CP bảo lãnh (17)
			// Contracts[i].TotalMiscExpenseCost = Math.round(TotalMiscExpenseCost) // CP khác (18)

			let SumAuxiliaryCost = rsTotalAuxiliaryCost.muc2 //sumCostAB //CP tiền A+B (19)
			let SumAuxiliaryTax = rsTotalAuxiliaryCost.muc2_thue //sumTaxAB //CP Thuế A+B: (20)
			let SumAuxiliaryCostTax = rsTotalAuxiliaryCost.muc2_1 //sumCostTaxAB //CP tiền và thuế AB
			let TotalAuxiliaryAndMiscExpense = 0 //parseInt(TotalMiscExpenseCost + rsTotalAuxiliaryCost.sumCostAB) //Tổng CP: (21) = (18)+(19)

			//Lãi gộp (chưa có incentive): (22) = (8) - SUM(9-18) - (21)
			let GrossProfitNoIncentive = TotalOutputIntoMoney -
										(
											ExternalCapitalCosts +
											DeploymentCosts + 
											TotalSumIncentive + 
											TotalSumInsurance + 
											TotalInsuranceHSE + 
											(GeneralExpenses+DeploymentCosts) + 
											TotalInputIntoMoney + 
											TotalMandayCost + 
											Math.round(TotalGuaranteeLetterCost) + 
											Math.round(TotalMiscExpenseCost)
										)
										- parseInt(TotalMiscExpenseCost + rsTotalAuxiliaryCost.sumCostAB)
			// Contracts[i].GrossProfitNoIncentive = GrossProfitNoIncentive // (22)
			let RatioGrossProfitNoIncentiveAboveRevenue = !isNaN(projectEfficiency/TotalOutputIntoMoney) && TotalOutputIntoMoney != 0 ? ((projectEfficiency/TotalOutputIntoMoney)* 100).toFixed(2): 0 //Tỷ lệ LG/DT: (23) = (22)/(8)
			// let RatioGrossProfitNoIncentiveAboveRevenue = !isNaN(GrossProfitNoIncentive/TotalOutputIntoMoney) && TotalOutputIntoMoney != 0 ? GrossProfitNoIncentive/TotalOutputIntoMoney : 0 //Tỷ lệ LG/DT: (23) = (22)/(8)
			// Lãi gộp (đã bao gồm incentive): (25) = (22)+(24)
			let GrossProfitIncentive = parseInt(projectEfficiency) + parseInt(TotalSumIncentive)
			// let GrossProfitIncentive = parseInt(GrossProfitNoIncentive + Sum_Incentive(ProductCost_idContract)) 
			// Contracts[i].GrossProfitIncentive = GrossProfitIncentive //Tỷ lệ LG/DT: (25) = (22)+(24)
			// let RatioGrossProfitIncentiveAboveRevenue = !isNaN(GrossProfitIncentive/TotalOutputIntoMoney) && TotalOutputIntoMoney != 0 ? GrossProfitIncentive/TotalOutputIntoMoney : 0 //Tỷ lệ LG/DT: (26) = (25)/(8)
			let RatioGrossProfitIncentiveAboveRevenue = !isNaN(((projectEfficiency + TotalSumIncentive) / TotalOutputIntoMoney)) && isFinite(((projectEfficiency + TotalSumIncentive) / TotalOutputIntoMoney)) ? (((projectEfficiency + TotalIncentive) / TotalOutputIntoMoney) * 100).toFixed(2): 0

			//Invoices
			let Invoices_idContract = find_data_by_contract_id(Invoices, Contract._id)
			let Invoices_idContracts = find_data_by_contract_id(Invoices, Contract._id)
			let PercentInvoices = SumPercentInvoice(Invoices_idContract)
			let SumPercentInvoices = !isNaN(PercentInvoices/TotalOutputIntoMoney) ? PercentInvoices/TotalOutputIntoMoney == 1 ? 100 : ((PercentInvoices/TotalOutputIntoMoney)*100) : 0
			let lastInvoices_idContract = Invoices_idContract.length > 0 ? Invoices_idContract.pop() : ""
			let InvoiceVal = lastInvoices_idContract != "" ? !isNaN(SumPercentInvoices/TotalOutputIntoMoney) ? (SumPercentInvoices/TotalOutputIntoMoney).toFixed(2): 0 : 0
			let DateOfInvoice = lastInvoices_idContract != "" ? convert_time(lastInvoices_idContract.DateOfInvoice) : "" //Ngày xuất hóa đơn (27)
			let PercentInvoice = lastInvoices_idContract != "" ? SumPercentInvoices == 100 ? "100%" : SumPercentInvoices.toFixed(2) + "%" : "0%" //Tỷ lệ % xuất HĐ (28)
			let OutputIntoMoney = lastInvoices_idContract != "" ? SumPercentInvoices == 100 ? 0 : TotalOutputIntoMoney - PercentInvoices : "0" //Doanh thu còn lại chưa xuất HĐ (29)
			let NoteInvoices = Invoices_idContracts.length > 1 ? "Xuất hóa đơn nhiều đợt": "" //Ghi chú (30)
			
			Doanh_thu = Doanh_thu + TotalOutputIntoMoney
			Gia_von_ben_ngoai = Gia_von_ben_ngoai + ExternalCapitalCosts
			Gia_von_noi_bo = Gia_von_noi_bo + InternalCapitalCosts
			Incentive = Incentive + TotalSumIncentive
			CP_bao_hanh_HSC = CP_bao_hanh_HSC + TotalSumInsurance
			CP_ho_tro_ky_thuat_HSE = CP_ho_tro_ky_thuat_HSE + TotalInsuranceHSE
			CP_trien_khai = CP_trien_khai + TotalCostAB
			CP_manday = CP_manday + TotalMandayCost
			CP_bao_lanh = CP_bao_lanh + TotalGuaranteeLetterCost
			CP_khac = CP_khac + TotalMiscExpenseCost
			CP_vat_tu_phu = CP_vat_tu_phu + SumAuxiliaryCost
			Thue = Thue + SumAuxiliaryTax
			Tong_CP = Tong_CP + parseInt(SumAuxiliaryCostTax)
			Lai_gop_chua_co_incentive = Lai_gop_chua_co_incentive + Math.round(projectEfficiency)
			Lai_gop_da_bao_gom_incentive = Lai_gop_da_bao_gom_incentive + Math.round(GrossProfitIncentive)
			Doanh_thu_con_lai_chua_xuat_HD = Doanh_thu_con_lai_chua_xuat_HD + parseInt(OutputIntoMoney)
			CP_von = CP_von + chiPhi

			array_result = [...array_result, {
				_id: Contract._id,
				STT,
				CenterID,
				DepartmentID,
				CustomerID,
				user,
				ContractID,
				RatioUSD,
				VAT,
				Date: DateTemp,
				TotalInputIntoMoney,
				projectEfficiency,
				TotalOutputIntoMoney,
				GeneralExpenses,
				DeploymentCosts,
				TotalCostAB,
				TotalMandayCost,
				TotalGuaranteeLetterCost,
				SumAuxiliaryCost,
				SumAuxiliaryTax,
				SumAuxiliaryCostTax,
				TotalAuxiliaryAndMiscExpense,
				GrossProfitNoIncentive,
				RatioGrossProfitNoIncentiveAboveRevenue,
				GrossProfitIncentive,
				RatioGrossProfitIncentiveAboveRevenue,
				DateOfInvoice,
				PercentInvoice,
				OutputIntoMoney,
				NoteInvoices,
				SumPercentInvoices,
				TotalSumIncentive,
				TotalSumInsurance,
				ExternalCapitalCosts,
				InternalCapitalCosts,
				TotalInsuranceHSE,
				TotalMiscExpenseCost,
				chiPhi
			}];
		})

		if (isTotal && array_result.length > 0) {
			array_result = [...array_result, {
			_id: Contract._id,
			STT: "Tổng",
			CenterID: "",
			DepartmentID: "",
			CustomerID: "",
			user: "",
			ContractID: "",
			RatioUSD: "",
			VAT: "",
			Date: "",
			TotalInputIntoMoney: "",
			projectEfficiency: Lai_gop_chua_co_incentive,
			TotalOutputIntoMoney: Doanh_thu,
			GeneralExpenses: "",
			DeploymentCosts: "",
			TotalCostAB: CP_trien_khai,
			TotalMandayCost: CP_manday,
			TotalGuaranteeLetterCost: CP_bao_lanh,
			SumAuxiliaryCost: CP_vat_tu_phu,
			SumAuxiliaryTax: Thue,
			SumAuxiliaryCostTax: Tong_CP,
			TotalAuxiliaryAndMiscExpense: "",
			GrossProfitNoIncentive: Lai_gop_chua_co_incentive,
			RatioGrossProfitNoIncentiveAboveRevenue: "",
			GrossProfitIncentive: Lai_gop_da_bao_gom_incentive,
			RatioGrossProfitIncentiveAboveRevenue: "",
			DateOfInvoice: "",
			PercentInvoice: "",
			OutputIntoMoney: Doanh_thu_con_lai_chua_xuat_HD,
			NoteInvoices: "",
			SumPercentInvoices: "",
			TotalSumIncentive: Incentive,
			TotalSumInsurance: CP_bao_hanh_HSC,
			ExternalCapitalCosts: Gia_von_ben_ngoai,
			InternalCapitalCosts: Gia_von_noi_bo,
			TotalInsuranceHSE: CP_ho_tro_ky_thuat_HSE,
			TotalMiscExpenseCost: CP_khac,
			chiPhi: CP_von
		}];
		}

		return array_result
	}

	function findArrayItem(array, id) {
		return array.find(item => item._id == id); 
	}	
	// hàm convert to date time
	function convert_time(date_time){
		const date = new Date(date_time); // Replace this with your actual date object
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		const seconds = ('0' + date.getSeconds()).slice(-2);
		const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		return formattedDate;
	}

	function getCurrentDate(separator){
	    let newDate = new Date()
	    let date = newDate.getDate();
	    let month = newDate.getMonth() + 1;
	    let year = newDate.getFullYear();
	    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
	}

	function get_status_name(array,ContractID,event_array){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			let statusID = lastElement.StatusID;
			for (var i = 0; i < event_array.length; i++) {
				let value = event_array[i];
				if (value.statusID == statusID) {
					// return value.name+" ("+statusID+")";
					return value.name;
				}
			}
		}else{
			return '';
		}
	}

	function get_status(array,ContractID){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return '';
		}
	}


	const [newFilter, setNewFilter] = useState({
		fromDate:"",
		toDate: "",
		statusInvoice: "",
		DepartmentID: "",
		CenterID: "",
		staffName: "",
		grossProfitVaule: "",
		grossProfitPercentage: ""
	})
	
	const {
		fromDate,
		toDate,
		statusInvoice,
		DepartmentID,
		CenterID,
		staffName,
		grossProfitVaule,
		grossProfitPercentage
	} = newFilter

	const onChangeNewFilter = event => {
		let name = event.target.name;
		let val = event.target.value
		
		if (name == "fromDate" || name == "toDate") {
			setNewFilter({ ...newFilter, [name]: val.split("-").reverse().join("-") })
		}else if (name == "grossProfitPercentage") {
			if (!isNumberOrDecimal(val) && val != '') {
				var message = 'Vui lòng nhập tỉ lệ % không quá 2 số thập phận'
				setShowToast({ show: true, message: message, type: 'danger' })
				event.target.value = val
				return false;
			}else{
				var value = (val != "") ? val : 0;
				if (parseInt(val) > 100 && parseFloat(val) > 100) {
					var message = 'Vui lòng nhập tỷ lệ không quá 100%'
					setShowToast({ show: true, message: message, type: 'danger' })
					event.target.value = val
					return false;
				}else if (val == "00" && parseInt(val) == 0 && parseFloat(val) == 0) {
					var message = 'Vui lòng nhập tỷ lệ lớn hơn 0%'
					setShowToast({ show: true, message: message, type: 'danger' })
					event.target.value = val
					return false;
				}
				setNewFilter({ ...newFilter, [name]: val })
			}
		}
		setNewFilter({ ...newFilter, [name]: val.trim() })
	}

	const isNumberOrDecimal = userInput => {
		// if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		// return /^\d+$/.test(userInput); ok
  		// return /((?:\d+\.\d*)|(?:\d*\.?\d+))$/.test(userInput); ok
  		// return /^\d*\.?\d*$/.test(userInput) //ok
  		return /^\d+(\.\d{0,2})?$/.test(userInput)
	}

	const isParentNumber = userInput => {
		if (typeof userInput !== 'string') return false;
		return /^\d+$/.test(userInput);
	}

	function approximatelyEqual(a, b) {
	    return Math.abs(a - b) < 0.01;
	}

	const handleFilterChange = () => {
		let condition = newFilter
		condition.fromDate = (startDate != null) ? formatDate(startDate) : ''
		condition.toDate = (endDate != null) ? formatDate(endDate) : ''
		condition.invoicingTime = (invoicingTime != null) ? formatDate(invoicingTime) : ''
		let rs = loopReport()
		// console.log(condition, rs)
		// setNewContracts(rs);
		const filteredData = rs.filter((item) => {
		    // Date range filtering
		    let inDateRange = true;

		    if (condition.fromDate) {
		        const fromDate = new Date(condition.fromDate+ " 00:00:00");
		        const date = new Date(item.Date);
		        inDateRange = date >= fromDate;
		    }

		    if (condition.toDate) {
		        const toDate = new Date(condition.toDate + " 23:59:59");
		        const date = new Date(item.Date);
		        inDateRange = inDateRange && date <= toDate;
		    }

		    // Invoice status filtering
		    let statusCondition;
		    if (condition.statusInvoice == "<100") {
		        statusCondition = item.SumPercentInvoices < 100;
		    } else if (condition.statusInvoice == "100") {
		        statusCondition = item.SumPercentInvoices == 100;
		    } else {
		        statusCondition = true; // no condition
		    }

		    // Department filtering
		    // let departmentCondition;
		    // if (condition.DepartmentID) {
		    //     departmentCondition = item.DepartmentID == condition.DepartmentID;
		    // } else {
		    //     departmentCondition = true; // no condition
		    // }

			// Centers filtering
		    let centerCondition;
		    if (condition.CenterID) {
		        centerCondition = item.CenterID == condition.CenterID;
		    } else {
		        centerCondition = true; // no condition
		    }

			// Users filtering
			let userCondition;
		    if (condition.staffName) {
		        userCondition = item.user == condition.staffName;
		    } else {
		        userCondition = true; // no condition
		    }

			//
			let inDateOfInvoice = true
			if (condition.invoicingTime) {
		        const invoicingTimeDateStart = new Date(condition.invoicingTime+ " 00:00:00");
		        const invoicingTimeDateEnd = new Date(condition.invoicingTime+ " 23:59:59");
		        const date = new Date(item.DateOfInvoice);
		        inDateOfInvoice = inDateOfInvoice && date <= invoicingTimeDateEnd && date >= invoicingTimeDateStart;
		    }

		    let grossProfitVauleCondition = true
			if (condition.grossProfitVaule) {
		        switch (condition.grossProfitVaule) {
				    case "<500M":
				        grossProfitVauleCondition = item.projectEfficiency < 499999999
				        break;
				    case "500M-1000M":
				        grossProfitVauleCondition = item.projectEfficiency <= 999999999 && item.projectEfficiency >= 500000000
				        break;
				    case "1000M-1500M":
				        grossProfitVauleCondition = item.projectEfficiency <= 1499999999 && item.projectEfficiency >= 1000000000
				        break;
				    case "1500M-2000M":
				        grossProfitVauleCondition = item.projectEfficiency <= 1999999999 && item.projectEfficiency >= 1500000000
				        break;
				    case ">2000M":
				    	grossProfitVauleCondition = item.projectEfficiency >= 2000000000
				    	break;
				}
		    }

		    let grossProfitPercentageCondition = true
			if (condition.grossProfitPercentage) {
		        grossProfitPercentageCondition = approximatelyEqual(condition.grossProfitPercentage, item.RatioGrossProfitNoIncentiveAboveRevenue)
		    }

		    // Filter by all conditions
		    return  inDateRange &&
		    		statusCondition &&
		    		centerCondition &&
		    		userCondition &&
		    		inDateOfInvoice &&
		    		grossProfitVauleCondition &&
		    		grossProfitPercentageCondition;
		});

		// console.log(filteredData, "filteredData")
		let rsfilteredData = loopReport(filteredData, true)
		setNewContracts(rsfilteredData);
		setIsSearch(true)
	}

	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
	
		if (month.length < 2) 
			month = '0' + month;
		if (day.length < 2) 
			day = '0' + day;
	
		return [year, month, day].join('-');
	}

	const resetFilter = () => {
		setNewFilter({ 
			fromDate:"",
			toDate: "",
			statusInvoice: "",
			DepartmentID: "",
			CenterID: "",
			staffName: "",
			grossProfitVaule: "",
			grossProfitPercentage: "",
	 	})
		setDateRange([null, null])
		setInvoicingTime(null)
		// let rs = loopReport()
		setNewContracts([])
		setIsSearch(false)
	}

	// const newFilterCenters = (typeof Centers != "undefined") ? Centers.filter((element) => element.CenterName !== "HO") : [];
	let newFilterCenters;
	if (typeof Centers != "undefined") {
		switch(roleName){
			case 'AM':
			case 'TPKD':
				newFilterCenters = filter_Users(Users, UserID).center_details;
				break;
			case 'KTT':
			case 'BGD':
				newFilterCenters = filter_Users(Users, UserID).center_details;
				break;
			case 'BTGD':
			case 'Admin':
				newFilterCenters = Centers;
				break;
			default: 
				newFilterCenters = []
		}
	}
	
	const Filter = () => {
		return (
			<>
			</>
		)
	}

	const [invoicingTime, setInvoicingTime] = useState(null);
	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;

	let body = null
	let stt = 1

	console.debug("newContracts", newContracts, isSearch)

	if (ContractsLoading) {
		body = (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	}else if (Contracts.length === 0) {
		body = (
			<>
				<Card className='text-center'>
					<Card.Header as='h2'>Báo cáo Chi phí Manday</Card.Header>
					<Card.Body>
						<Form.Row className="form-row-custom">
							<Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>Ngày lập PTHĐ</Form.Label>
								<br></br>
								<DatePicker
									className='form-control fix-select-date-ranger'
									selectsRange={true}
									startDate={startDate}
									endDate={endDate}
									onChange={(update) => {
										setDateRange(update);
									}}
									isClearable={true}
									dateFormat="yyyy/MM/dd"
									maxDate={new Date()}
								/>
							</Form.Group>

							{/* <Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>đến ngày</Form.Label>
								<Form.Control
									type='date'
									placeholder="Đến ngày"
									name='toDate'
									value={toDate}
									onChange={onChangeNewFilter}
								/>
							</Form.Group> */}

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tình trạng hóa đơn</Form.Label>
								<Form.Control
									as="select"
									value={statusInvoice}
									name='statusInvoice'
									onChange={onChangeNewFilter}
									>
									<option value=''>-- chọn tình trạng --</option>
									<option value="100">Xuất hóa đơn đủ 100%</option>
									<option value="<100">Chưa hóa đơn đủ 100%</option>
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom fix-select-center" as="Col" controlId="formGridPassword">
								<Form.Label>Trung tâm</Form.Label>
								<Form.Control
									id = 'noidung-help'
									as="select"
									name='CenterID'
									aria-describedby='noidung-help'
									value={CenterID}
									onChange={onChangeNewFilter}>
									<option value=''>-----</option>
									{
										typeof newFilterCenters != "undefined" ? 
										newFilterCenters.map((obj => {
											return (
												<>
													<option value={obj.CenterID}>{obj.CenterName}</option>
												</>
											)
										}))
										:
										<></>
									}
									
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tài khoản nhân viên</Form.Label>
								<Form.Control
									type='text'
									placeholder=""
									name='staffName'
									value={staffName}
									onChange={onChangeNewFilter}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>Thời gian xuất hóa đơn</Form.Label>
								<br></br>
								<DatePicker
									selected={invoicingTime}
									onChange={(date) => setInvoicingTime(date)}
									className='form-control'
									dateFormat='yyyy/MM/dd'
									maxDate={new Date()}
									isClearable={true}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Giá trị lãi gộp</Form.Label>
								<Form.Control
									as="select"
									value={grossProfitVaule}
									name='grossProfitVaule'
									onChange={onChangeNewFilter}
									>
									<option value=''>-- giá trị --</option>
									<option value='<500M'>Dưới 500 triệu</option>
									<option value='500M-1000M'>500 triệu - 1 tỷ</option>
									<option value='1000M-1500M'>1 tỷ - 1 tỷ 500 triệu</option>
									<option value='1500M-2000M'>1 tỷ 500 triệu - dưới 2 tỷ</option>
									<option value='>2000M'>Trên 2 tỷ</option>
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tỷ lệ % lãi gộp</Form.Label>
								<Form.Control
									type='text'
									placeholder=""
									name='grossProfitPercentage'
									value={grossProfitPercentage}
									onChange={onChangeNewFilter}
								/>
							</Form.Group>
						</Form.Row>

						<Form.Row className="form-row-custom">
							<Form.Group as="Col" controlId="formGridPassword">
								<Form.Label>&nbsp;</Form.Label>
								<Col xs="auto">
									<OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Xóa lọc báo cáo
											</Tooltip>
										}
									>
									<a onClick={resetFilter}>
										<img src={iconFilterReset} width="35" height="35"></img>
									</a>
									</OverlayTrigger>
								</Col>
							</Form.Group>

							<Form.Group as="Col" controlId="formGridPassword">
								<Form.Label>&nbsp;</Form.Label>
								<Col xs="auto">
									{/* <OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Lọc báo cáo
											</Tooltip>
										}
									>
									<a onClick={handleFilterChange}>
										<img src={iconViewReport} width="35" height="35"></img>
									</a>
									</OverlayTrigger> */}

									<Button
										variant='primary'
										onClick={handleFilterChange}
									>
										Lọc báo cáo
									</Button>
								</Col>
							</Form.Group>
						</Form.Row>

						<div id="table-scroll" className="table-scroll table-class-data-0">
							<table id="main-table" className="main-table table table-lg table-hover">
								<thead>
									<tr>
										<th scope="col" className='z-index-th header-table'>STT</th>
										<th scope="col" className='z-index-th header-table'>Ngày lập PTHD</th>
										<th scope="col" className='z-index-th header-table'>Số hợp đồng</th>
										<th scope="col" className='z-index-th header-table'>Tên khách hàng</th>
										<th scope="col" className='z-index-th header-table'>Tên trung tâm</th>
										<th scope="col" className='z-index-th header-table'>Tên phòng ban</th>
										<th scope="col" className='header-table'>Tên NV</th>
										<th scope="col" className='header-table'>Doanh thu</th>
										<th scope="col" className='header-table'>CP Manday</th>
										{/*<th scope="col" className='header-table'>Lãi gộp(chưa có incentive)</th>
										<th scope="col" className='header-table'>Tỷ lệ LG/DT</th>*/}
										<th scope="col" className='header-table'>Lãi gộp(đã bao gồm incentive)</th>
										<th scope="col" className='header-table'>Tỷ lệ LG (gồm incentive)/DT</th>
										<th scope="col" className='header-table'>Ngày xuất hóa đơn</th>
										<th scope="col" className='header-table'>Ghi chú</th>
										{/* <th scope="col" className='header-table'>Ghi chú</th> */}
									</tr>
								</thead>
								<tbody>
									
								</tbody>
							</table>
						</div>
					</Card.Body>
				</Card>
			</>
		)
	} else {
		body = (
			<>
				<Card className='text-center'>
					<Card.Header as='h2'>Báo cáo Chi phí Manday</Card.Header>
					<Card.Body>
						<Form.Row className="form-row-custom">
							<Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>Ngày lập PTHĐ</Form.Label>
								<br></br>
								<DatePicker
									className='form-control fix-select-date-ranger'
									selectsRange={true}
									startDate={startDate}
									endDate={endDate}
									onChange={(update) => {
										setDateRange(update);
									}}
									isClearable={true}
									dateFormat="yyyy/MM/dd"
									maxDate={new Date()}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tình trạng hóa đơn</Form.Label>
								<Form.Control
									as="select"
									value={statusInvoice}
									name='statusInvoice'
									onChange={onChangeNewFilter}
									>
									<option value=''>-- chọn tình trạng --</option>
									<option value="100">Xuất hóa đơn đủ 100%</option>
									<option value="<100">Chưa hóa đơn đủ 100%</option>
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom fix-select-center" as="Col" controlId="formGridPassword">
								<Form.Label>Trung tâm</Form.Label>
								<Form.Control
									id = 'noidung-help'
									as="select"
									name='CenterID'
									aria-describedby='noidung-help'
									value={CenterID}
									onChange={onChangeNewFilter}>
									<option value=''>-----</option>
									{
										typeof newFilterCenters != "undefined" ? 
										newFilterCenters.map((obj => {
											return (
												<>
													<option value={obj.CenterName.trim()}>{obj.CenterName.trim()}</option>
												</>
											)
										}))
										:
										<></>
									}
									
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tài khoản nhân viên</Form.Label>
								<Form.Control
									type='text'
									placeholder=""
									name='staffName'
									value={staffName}
									onChange={onChangeNewFilter}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>Thời gian xuất hóa đơn</Form.Label>
								<br></br>
								<DatePicker
									selected={invoicingTime}
									onChange={(date) => setInvoicingTime(date)}
									className='form-control'
									dateFormat='yyyy/MM/dd'
									maxDate={new Date()}
									isClearable={true}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Giá trị lãi gộp</Form.Label>
								<Form.Control
									as="select"
									value={grossProfitVaule}
									name='grossProfitVaule'
									onChange={onChangeNewFilter}
									>
									<option value=''>-- giá trị --</option>
									<option value='<500M'>Dưới 500 triệu</option>
									<option value='500M-1000M'>500 triệu - 1 tỷ</option>
									<option value='1000M-1500M'>1 tỷ - 1 tỷ 500 triệu</option>
									<option value='1500M-2000M'>1 tỷ 500 triệu - dưới 2 tỷ</option>
									<option value='>2000M'>Trên 2 tỷ</option>
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tỷ lệ % lãi gộp</Form.Label>
								<Form.Control
									type='text'
									placeholder=""
									name='grossProfitPercentage'
									value={grossProfitPercentage}
									onChange={onChangeNewFilter}
								/>
							</Form.Group>
						</Form.Row>

						<Form.Row className="form-row-custom">
							<Form.Group as="Col" controlId="formGridPassword">
								<Form.Label>&nbsp;</Form.Label>
								<Col xs="auto">
									<OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Xóa lọc báo cáo
											</Tooltip>
										}
									>
									<a onClick={resetFilter}>
										<img src={iconFilterReset} width="35" height="35"></img>
									</a>
									</OverlayTrigger>
								</Col>
							</Form.Group>

							<Form.Group as="Col" controlId="formGridPassword">
								<Form.Label>&nbsp;</Form.Label>
								<Col xs="auto">
									{/* <OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Lọc báo cáo
											</Tooltip>
										}
									>
									<a onClick={handleFilterChange}>
										<img src={iconViewReport} width="35" height="35"></img>
									</a>
									</OverlayTrigger> */}

									<Button
										variant='primary'
										onClick={handleFilterChange}
									>
										Lọc báo cáo
									</Button>
								</Col>
							</Form.Group>
						</Form.Row>

						<div id="table-scroll" className={newContracts.length > 15 ? "table-scroll": "table-scroll table-scroll-fit-content"}>
							<table id="main-table" className="main-table table table-lg table-hover">
								<thead>
									<tr>
										<th scope="col" className='z-index-th header-table'>STT</th>
										<th scope="col" className='z-index-th header-table'>Ngày lập PTHD</th>
										<th scope="col" className='z-index-th header-table'>Số hợp đồng</th>
										<th scope="col" className='z-index-th header-table'>Tên khách hàng</th>
										<th scope="col" className='z-index-th header-table'>Tên trung tâm</th>
										<th scope="col" className='z-index-th header-table'>Tên phòng ban</th>
										<th scope="col" className='header-table'>Tên NV</th>
										<th scope="col" className='header-table'>Doanh thu</th>
										<th scope="col" className='header-table'>CP Manday</th>
										{/*<th scope="col" className='header-table'>Lãi gộp(chưa có incentive)</th>
										<th scope="col" className='header-table'>Tỷ lệ LG/DT</th>*/}
										<th scope="col" className='header-table'>Lãi gộp(đã bao gồm incentive)</th>
										<th scope="col" className='header-table'>Tỷ lệ LG (gồm incentive)/DT</th>
										<th scope="col" className='header-table'>Ngày xuất hóa đơn</th>
										<th scope="col" className='header-table'>Ghi chú</th>
									</tr>
								</thead>
								<tbody>
									{
										isSearch ? 
										<>
										{
											newContracts.length ? 
											<>
											{
												newContracts.map(Contract => (
													<tr className={(Contract.STT == "Tổng") ? "classNameTotal": ""} >
														<th scope="col" className="custom-font-w">{(Contract.STT == "Tổng") ? "Tổng": stt++}</th>
														<th scope="col" className="custom-font-w fm-col-string">{Contract.Date}</th>
														<th scope="col" className="custom-font-w fm-col-string">{Contract.ContractID}</th>
														<th scope="col" className="custom-font-w fm-col-string">
															<span
															className='text'
															title={Contract.CustomerID}
															>
																{Contract.CustomerID}
															</span>
														</th>
														<th scope="col" className="custom-font-w fm-col-string">
															<span
															className='text'
															title={Contract.CenterID}
															>
																{Contract.CenterID}
															</span>
														</th>
														<th scope="col" className="custom-font-w fm-col-string">
															<span
															className='text'
															title={Contract.DepartmentID}
															>
																{Contract.DepartmentID}
															</span>
														</th>
														<td scope="col" className="fm-col-string">{Contract.user}</td>
														<td scope="col" className="fm-col-number">{Contract.TotalOutputIntoMoney.toLocaleString()}</td>
														<td scope="col" className="fm-col-number">{Contract.TotalMandayCost.toLocaleString()}</td>
{/*														<td scope="col" className="fm-col-number">{Math.round(Contract.projectEfficiency).toLocaleString()}</td>
														<td scope="col" className="fm-col-number">
															{
																Contract.STT == "Tổng" ? 
																	"" 
																	:
																	Contract.RatioGrossProfitNoIncentiveAboveRevenue.toLocaleString() + "%"
															}
														</td>*/}
														<td scope="col" className="fm-col-number">{Math.round(Contract.GrossProfitIncentive).toLocaleString()}</td>
														<td scope="col" className="fm-col-number">
															{
																Contract.STT == "Tổng" ? 
																	"" 
																	:
																	Contract.RatioGrossProfitIncentiveAboveRevenue.toLocaleString() + "%"
															}
														</td>
														<td scope="col">
															{
																Contract.STT == "Tổng" ? 
																	"" 
																	:
																	Contract.DateOfInvoice != "" ? 
																	Contract.DateOfInvoice.toLocaleString()
																	: 
																	"Chưa xuất hóa đơn"		
															}
														</td>
														<td scope="col">
															{
																Contract.STT == "Tổng" ? "" : Contract.NoteInvoices
															}
														</td>
													</tr>
												))
											}
											</>
											:
											<>
												<tr>
													<th scope="col" colSpan={7} className="custom-font-w">Không tìm thấy dữ liệu phù hợp</th>
													<td scope="col" colSpan={21}></td>
												</tr>
											</>
										}
										</>
										:
										<>
											<tr>
												<th scope="col" colSpan={7} className="custom-font-w">Chọn điều kiện để lọc báo cáo</th>
												<td scope="col" colSpan={21}></td>
											</tr>
										</>
									}
								</tbody>
							</table>
						</div>
					</Card.Body>
				</Card>
			</>
		)
	}

		const {
		UserPageState: { UserPage, UserPages, UserPagesLoading },
		getAllUserPages
	} = useContext(UserPageContext)

	const {
	    MenuState: { Menu, Menus, MenusLoading },
	    getMenu,
	} = useContext(MenuContext);

	function FindArrayMenu(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return null;
		}
	}

	if (UserPagesLoading && MenusLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	}else {
		const result = UserPages.filter(obj => { return obj.user_id.some(id => id === UserID); });
		let menu_access = false
		if (result.length > 0) {
			for (var i = 0; i < result.length; i++) {
				let menuV1 = FindArrayMenu(Menus, result[i].page_id[0])
				if (menuV1 != null && menuV1.path == window.location.pathname) {
					// console.debug("menuV1 co quyen truy cap")
					menu_access = true
					break;
				}else {
					// console.debug("menuV1 khong quyen truy cap")
				}
			}
		} else {
			// console.debug("menu khong quyen truy cap")
		}

		if (menu_access) {
			return (
				<>
					{body}
					<Toast
						show={show}
						style={{ position: 'fixed', top: '20%', right: '10px', zIndex: '999999'}}
						className={`bg-${type} text-white`}
						onClose={setShowToast.bind(this, {
							show: false,
							message: '',
							type: null
						})}
						delay={3000}
						autohide
					>
						<Toast.Body>
							<strong>{message}</strong>
						</Toast.Body>
					</Toast>
				</>
			)
		}else {
			return (
				<>
					<div id="notfound">
						<div className="notfound">
							<h1>Oops!</h1>
							<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
								<br/>
								<Link style={{textDecoration: "none"}} to="/home">
									Trở về trang chủ
								</Link>
							</h6>
						</div>
					</div>
				</>
			)
		}
	}
}

export const Report_Internal_Revenue = () => {
	//
	const {
		UserState: { User, Users, UsersLoading },
		getUsers
	} = useContext(UserContext)

	const {
		HistoryState: { History, Historys, HistorysLoading },
		getHistory
	} = useContext(HistoryContext)

	const {
		EventState: { Event, Events, EventsLoading },
		getEvent
	} = useContext(EventContext)

	//=== Get All Productcosts
	const {
		ProductCostState: { ProductCost, ProductCosts, ProductCostsLoading },
		getProductCosts,
	} = useContext(ProductCostContext)

	//=== Get MandayCost 
	const {
		MandayCostState: { MandayCost, MandayCosts, MandayCostsLoading },
		getMandayCosts,
	} = useContext(MandayCostContext)

	//=== Get GuaranteeLetterCost
	const {
		GuaranteeLetterCostState: { GuaranteeLetterCost, GuaranteeLetterCosts, GuaranteeLetterCostsLoading },
		getGuaranteeLetterCosts,
	} = useContext(GuaranteeLetterCostContext)

	//=== Get MiscExpenseCosts
	const {
		MiscExpenseCostState: { MiscExpenseCost, MiscExpenseCosts, MiscExpenseCostsLoading },
		getMiscExpenseCosts,
	} = useContext(MiscExpenseCostContext)

	//=== Get data CapitalExpenditureCost chi phi von
	const {
		CapitalExpenditureCostState: { CapitalExpenditureCost, CapitalExpenditureCosts, CapitalExpenditureCostsLoading },
		getCapitalExpenditureCosts,
	} = useContext(CapitalExpenditureCostContext);

	//=== Get data LoanInterest chi phi lãi vay
	const {
	    LoanInterestState: { LoanInterest, LoanInterests, LoanInterestsLoading },
	    getLoanInterest
	} = useContext(LoanInterestContext);
	
	//=== Get data AuxiliaryCost
	const {
		AuxiliaryCostState: { AuxiliaryCosts },
		getAuxiliaryCosts
	} = useContext(AuxiliaryCostContext)

	//=== Get data ImplementationCost
	const {
		ImplementationCostState: { ImplementationCosts },
		getImplementationCost
	} = useContext(ImplementationCostContext)

	//== Get data Invoices
	const {
		InvoiceState: { Invoices },
		getAllInvoice
	} = useContext(InvoiceContext)

	//== Get data Centers
	const {
		CenterState: { Centers, CentersLoading },
		get_All_Center
	} = useContext(ConfigCenterContext)

	//== Get data Departments
	const {
		DepartmentState: { Department, Departments, DepartmentsLoading },
		getDepartment
	} = useContext(ConfigDepartmentContext)

	//== Get data Customers
	const {
		CustomerState: { Customer, Customers, CustomersLoading },
		get_All_Customer
	} = useContext(ConfigCustomerContext)

	//== Get data Contracts
	const {
		ContractState: { Contract, Contracts, ContractsLoading },
		getContracts,
		showToast: { show, message, type },
		setShowToast,
		showToast,
	} = useContext(ContractContext)
	//== Get data auth
	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)
	//== Get data Roles
	const {
		RoleState: { Role, Roles, RolesLoading },
		getRoles,
	} = useContext(RoleContext)
	//== Get data UserManager
	const {
		UserManagerState: { UserManager, UserManagers, UserManagersLoading },
		getAllUserManagers
	} = useContext(UserManagerContext)
	
	useEffect(() => get_All_Center(), [])
	useEffect(() => getDepartment(), [])
	useEffect(() => get_All_Customer(), [])
	useEffect(() => getUsers(), [])
	useEffect(() => getHistory(), [])
	useEffect(() => getEvent(), [])
	useEffect(() => getProductCosts(), [])
	useEffect(() => getMandayCosts(), [])
	useEffect(() => getGuaranteeLetterCosts(), [])
	useEffect(() => getMiscExpenseCosts(), [])
	useEffect(() => getCapitalExpenditureCosts(), [])
	useEffect(() => getLoanInterest(), [])
	useEffect(() => getAuxiliaryCosts(), [])
	useEffect(() => getImplementationCost(), [])
	useEffect(() => getAllInvoice(), [])
	useEffect(() => getContracts(), [])
	useEffect(() => getRoles(), [])
	useEffect(() => getAllUserManagers(), [])

	const [newContracts, setNewContracts] = useState([])
	const [isSearch, setIsSearch] = useState(false)
	let role_new = roles[0];
	let UserID = _id;
	let roleName = FindArray(Roles, role_new).name;
	// console.log("Report", role_new, UserID, roleName, MiscExpenseCosts)

	//
	function filter_new_contract(array,UserID){
		let returnObject = "";
		// if (UserID != undefined) returnObject = array.filter((element) => element.user.centers != null ? element.user.centers[0] : '' == UserID[0]);
		if (UserID != undefined) returnObject = array.filter((element) => element.CenterID == UserID[0]);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	let newContractsss;
	let newContract111 = [];
	if(roleName == 'Admin' || roleName == 'BTGD'){
		newContractsss = Contracts
	}else if(roleName == 'KTT' || roleName == 'BGD'){
		let centerOfUser = filter_Users(Users,UserID).centers;
		if (centerOfUser != undefined) {
			if(Contracts.length > 0){
				newContract111 = filter_new_contract(Contracts,centerOfUser);
			}
		}
		newContractsss = newContract111;
	}else{
		let newContract1 = Contracts.filter((v) => get_user_id(UserID,UserManagers).includes(v.user._id))
		if(roleName == 'AM'){
			newContractsss = newContract1;
		}else{
			let contract_me = Contracts.filter((v) => v.user._id == UserID)

			let newContract2 = newContract1.concat(contract_me);
			newContractsss = newContract2;
		}
	}

	function get_user_id(userLogin, userManager) {
		let managedUsers = [];
		// managedUsers.push(userLogin)

		function findManagedUsers(managerId) {
			let users = userManager.filter(item => item.manager_id[0] === managerId);
			if (users.length > 0) {
				for (let user of users) {
					findManagedUsers(user.user_id[0]);
				}
			} else {
				managedUsers.push(managerId);
			}
		}

		findManagedUsers(userLogin);
		return managedUsers;
	}

	function filter_Users(array,UserID){
		let returnObject = "";
		if (UserID != undefined) returnObject = array.filter((element) => element._id === UserID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return '';
		}
	}


	function filter_history(array,ContractID){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return '';
		}
	}
	//
	function totalCenter() {
		let totalCenter = 0;
		if (typeof Centers != undefined) totalCenter = Centers.length;
		return totalCenter;
	}

	function totalDepartment() {
		let totalDepartment = 0;
		if (typeof Departments != undefined) totalDepartment = Departments.length;
		return totalDepartment;
	}

	function totalContract() {
		let totalContract = 0;
		if (typeof Contracts != undefined) totalContract = Contracts.length;
		return totalContract;
	}

	function totalUser() {
		let totalUser = 0;
		if (typeof Users != undefined) totalUser = Users.length;
		return totalUser;
	}

	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id == ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	// table ranking
	//Ham tinh tong Phan tử trong kieu mang 
	function Sum_InputIntoMoney(ProductsCost) {
		let Total_InputIntoMoney = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_InputIntoMoney += ListProduct.InputIntoMoney
			)))

		return Total_InputIntoMoney;
	}
	// input into money with check mua nội bộ
	function Sum_InputIntoMoneyTypePurchase(ProductsCost, InternalPurchase) {
		let Total_InputIntoMoney = 0;

		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_InputIntoMoney += (InternalPurchase == 1) 
					? (ListProduct.InternalPurchase == true) ? ListProduct.InputIntoMoney : 0
					: (ListProduct.InternalPurchase == false) ? ListProduct.InputIntoMoney : 0
			)))

		return Total_InputIntoMoney;
	}
	//Ham tinh tong Phan tử trong kieu mang 
	function Sum_OutputIntoMoney(ProductsCost) {
		let Total_OutputIntoMoney = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_OutputIntoMoney += ListProduct.OutputIntoMoney
			)))

		return Total_OutputIntoMoney;
	}

	//Tinh tong thanh tien gia ban voi Insurance = true
	function Sum_Insurance(ProductsCost) {
		let Total_Insurance = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				ListProduct.Insurance === true ? Total_Insurance += ListProduct.OutputIntoMoney : 0
			)))

		return Total_Insurance;
	}

	function Sum_InsuranceHSE(ProductsCost) {
		let Total_InsuranceHSE = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				ListProduct.InsuranceHSE === true ? Total_InsuranceHSE += (ListProduct.OutputIntoMoney*((ListProduct.PercentHSE != null ? ListProduct.PercentHSE : 1) / 100)) : 0
			)))
		return Total_InsuranceHSE;
	}

	// hàm tính tổng thành tiền
	function sumArray(mang) {
		let sum = 0;
		mang.map(function (value) { sum += value; });
		return sum;
	}
	//hàm tìm data theo contract id
	function find_data_by_contract_id(data, idContract, children = null) {
		let rs = [];
		if (children == 1) {
			rs = data.filter(x => (x.contract != null && x.contract._id == idContract))
		}else {
			rs = data.filter(x => x.contract == idContract)
		}
		return rs
	}

	// hàm đổi giá trị chi phí % => giá trị tiền tuyệt đối
	function changeCost(Cost, TotalOutputIntoMoney) {
		let returnValue = Cost;
		if (Cost < 1)
			returnValue = Cost * TotalOutputIntoMoney;
		return returnValue
	}

	function SumListCost(Auxiliary, TotalOutputIntoMoney) {
		let kq = 0;
		Auxiliary.map(AuxiliaryCost =>
			AuxiliaryCost.ListCosts.map(ListCost => (
				kq += changeCost(ListCost.Cost, TotalOutputIntoMoney)
			)))

		return kq;
	}
	
	function FindPlan(Auxiliary) {
		let returnPlan = 0;
		Auxiliary.map(AuxiliaryCost => returnPlan = AuxiliaryCost.Plan
		)
		return returnPlan;
	}

	function totalAuxiliaryCost(AuxiliaryCosts, TotalOutputIntoMoney){
		let sumCostTaxA = 0;
		let sumCostTaxB = 0;
		
		let sumCostA = 0;
		let sumCostB = 0;
		
		let sumTaxA = 0;
		let sumTaxB = 0;
		
		AuxiliaryCosts.map(AuxiliaryCost => (
			AuxiliaryCost.ListCosts.map(function(ListCost, index) {
				var newCost = 0
				var newTax = 0
				var newCostTax = 0
				if (AuxiliaryCost.Plan == 1){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost * ListCost.TaxRate)/100).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}else if (AuxiliaryCost.Plan == 2){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost / (100 - ListCost.TaxRate)) * ListCost.TaxRate).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostA += parseInt(newCost);
				}else if (AuxiliaryCost.Plan == 2){
					sumCostB += parseInt(newCost);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumTaxA += parseInt(newTax);
				}else if (AuxiliaryCost.Plan == 2){
					sumTaxB += parseInt(newTax);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostTaxA += parseInt((newCost+newTax));
				}else if (AuxiliaryCost.Plan == 2){
					sumCostTaxB += parseInt((newCost+newTax));
				}
			})
		))
		let muc2_1 = parseInt((sumCostA + sumCostB)/10);
		let muc2_thue = parseInt((sumTaxA + sumTaxB)/10);
		let muc2 = parseInt(muc2_1 + muc2_thue);
		return {muc2, muc2_1, muc2_thue}
	}

	function totalAuxiliaryCosts(AuxiliaryCosts, TotalOutputIntoMoney){
		let sumCostTaxA = 0;
		let sumCostTaxB = 0;
		
		let sumCostA = 0;
		let sumCostB = 0;
		
		let sumTaxA = 0;
		let sumTaxB = 0;
		
		AuxiliaryCosts.map(AuxiliaryCost => (
			AuxiliaryCost.ListCosts.map(function(ListCost, index) {
				var newCost = 0
				var newTax = 0
				var newCostTax = 0
				if (AuxiliaryCost.Plan == 1){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost * ListCost.TaxRate)/100).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}else if (AuxiliaryCost.Plan == 2){
					newCost = (ListCost.Fee != null) ? parseInt(((TotalOutputIntoMoney * ListCost.Fee)/100).toFixed(0)): ListCost.Cost;
					newTax = (ListCost.TaxRate != null) ? parseInt(((newCost / (100 - ListCost.TaxRate)) * ListCost.TaxRate).toFixed(0)): ListCost.Tax;
					newCostTax = (newCost != null && newTax != null) ? (newCost+newTax) : "";
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostA += parseInt(newCost);
				}else if (AuxiliaryCost.Plan == 2){
					sumCostB += parseInt(newCost);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumTaxA += parseInt(newTax);
				}else if (AuxiliaryCost.Plan == 2){
					sumTaxB += parseInt(newTax);
				}
				
				if (AuxiliaryCost.Plan == 1){
					sumCostTaxA += parseInt((newCost+newTax));
				}else if (AuxiliaryCost.Plan == 2){
					sumCostTaxB += parseInt((newCost+newTax));
				}
			})
		))

		let sumCostAB = parseInt(sumCostA + sumCostB);
		let sumTaxAB = parseInt(sumTaxA + sumTaxB);
		let sumCostTaxAB = parseInt(sumCostTaxA + sumCostTaxB);
		let muc2_1 = parseInt((sumCostA + sumCostB)/10);
		let muc2_thue = parseInt((sumTaxA + sumTaxB)/10);
		let muc2 = parseInt(muc2_1 + muc2_thue);
		return {sumCostAB, sumTaxAB, sumCostTaxAB, muc2, muc2_1, muc2_thue}
	}

	function TotalStageImplementation(ImplementationCosts) {
		let All_Total = 0;
		ImplementationCosts.map((element) => (
			element.StagesImplementation.map((object) => (
				object.Costs.map((Costs) =>
					All_Total += Costs.IntoMoney
				)))))
		return All_Total;
	}

	function TotalStageImplementationAB(ImplementationCosts, type = null) {
		let All_Total = 0;
		let ImplementationCostNew = "";
		ImplementationCostNew = ImplementationCosts.filter(x => x.Category == type);
		ImplementationCostNew.map((element) => (
			element.StagesImplementation.map((object) => (
				object.Costs.map((Costs) =>
					All_Total += Costs.IntoMoney
				)))))
		return All_Total;
	}

	function TotalCostAuxiliaryCosts(AuxiliaryCosts) {
		let All_Total = 0;
		AuxiliaryCosts.map((element) => {
			element.ListCosts.map((Costs) => {
				All_Total += Costs.Cost
			})
		})
		return All_Total;
	}

	function TotalTaxAuxiliaryCosts(AuxiliaryCosts) {
		let All_Total = 0;
		AuxiliaryCosts.map((element) => {
			element.ListCosts.map((Costs) => {
				All_Total += Costs.Tax
			})
		})
		return All_Total;
	}

	function Sum_Incentive(ProductsCost) {
		let Total_Incentive = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_Incentive += ListProduct.Incentive
			)))

		return Total_Incentive;
	}

	function SumPercentInvoice(Invoices) {
		let Total_PercentInvoice = 0;
		Invoices.map(invoice => (
			Total_PercentInvoice += invoice.InvoiceVal
		))

		return Total_PercentInvoice;
	}

	useEffect(() => {
		// let rs = loopReport()
		setNewContracts([])
	}, [Contracts]);
	////////////////////////
	
	function loopReport(newContract = null, isTotal = false) {
		let newContracts = newContract == null ? newContractsss : newContract
		let array_result = []

		//
		let Doanh_thu = 0
		let Gia_von_ben_ngoai = 0
		let Gia_von_noi_bo = 0
		let Incentive = 0
		let CP_bao_hanh_HSC = 0
		let CP_ho_tro_ky_thuat_HSE = 0
		let CP_trien_khai = 0
		let CP_von = 0
		let CP_manday = 0
		let CP_bao_lanh = 0
		let CP_khac = 0
		let CP_vat_tu_phu = 0
		let Thue = 0
		let Tong_CP = 0
		let Lai_gop_chua_co_incentive = 0
		let Lai_gop_da_bao_gom_incentive = 0
		let Doanh_thu_con_lai_chua_xuat_HD = 0
		//

		newContracts.map(function (Contract, i){
			console.debug(Contract, i)
			let ContractID = Contract.ContractID
			let RatioUSD = Contract.RatioUSD
			let VAT = Contract.VAT
			let STT = parseInt(i+1);
			let findCenters = (findArrayItem(Centers, Contract.CenterID) != undefined) ? findArrayItem(Centers, Contract.CenterID) : findArrayItem(Centers, Contract.CenterID)
			let findDepartments = (findArrayItem(Departments, Contract.DepartmentID) != undefined) ? findArrayItem(Departments, Contract.DepartmentID): findArrayItem(Departments, Contract.DepartmentID)
			let findCustomers = (findArrayItem(Customers, Contract.CustomerID) != undefined) ? findArrayItem(Customers, Contract.CustomerID): findArrayItem(Customers, Contract.CustomerID)
	
			let CenterID = findCenters != undefined ? findCenters.CenterName.trim() : Contract.CenterID
			let DepartmentID = findDepartments != undefined ? findDepartments.DepartmentName : Contract.DepartmentID
			let CustomerID = findCustomers != undefined ? findCustomers.CustomerName : Contract.CustomerID
			let user = newContract == null ? Contract.user.username : Contract.user
			let DateTemp = convert_time(Contract.Date != null ? Contract.Date : getCurrentDate("-"))
			// let StatusID = get_status(Historys,Contract._id) != '' ? get_status(Historys,Contract._id).StatusID : '0'
			// let StatusName = get_status_name(Historys,Contract._id,Events) != '' ? get_status_name(Historys,Contract._id,Events) : ''

			//ProductCost
			let ProductCost_idContract = find_data_by_contract_id(ProductCosts, Contract._id); // contract
			const TotalInsurance = 0.006 * Sum_Insurance(ProductCost_idContract)
			const TotalInsuranceHSE = Sum_InsuranceHSE(ProductCost_idContract)
			const TotalInputIntoMoney = Sum_InputIntoMoney(ProductCost_idContract)
			const TotalOutputIntoMoney = Sum_OutputIntoMoney(ProductCost_idContract)
			let TotalIncentive = 0;
			if(ProductCost_idContract[0] !== undefined){
				TotalIncentive = sumArray(ProductCost_idContract[0].ListProducts.map((ProductCost) => ProductCost.Incentive))//note
			}

			// MandayCost
			let MandayCost_idContract = find_data_by_contract_id(MandayCosts, Contract._id); // contract
			const TotalMandayCost = sumArray(MandayCost_idContract.map((MandayCost) => MandayCost.IntoMoney))
			//

			//GuaranteeLetterCost
			let GuaranteeLetterCost_idContract = find_data_by_contract_id(GuaranteeLetterCosts, Contract._id); // contract
			const TotalGuaranteeLetterCost = sumArray(GuaranteeLetterCost_idContract.map((GuaranteeLetterCost) => GuaranteeLetterCost.IntoMoney))

			//MiscExpenseCosts
			let MiscExpenseCost_idContract = find_data_by_contract_id(MiscExpenseCosts, Contract._id, 1);
			const TotalMiscExpenseCost = sumArray(MiscExpenseCost_idContract.map((MiscExpenseCost) => MiscExpenseCost.Cost))

			//CapitalExpenditureCosts
			let CapitalExpenditureCost_idContract = find_data_by_contract_id(CapitalExpenditureCosts, Contract._id);
			// let newCapitalExpenditureCost = sumArray(CapitalExpenditureCost_idContract.map((CapitalExpenditureCost) => CapitalExpenditureCost.CapitalExpense))//ch
			// let TotalCapitalExpense = ((CapitalExpenditureCost_idContract.InventoryDays + CapitalExpenditureCost_idContract.ImplementationDays - CapitalExpenditureCost_idContract.BedtDays) * CapitalExpenditureCost_idContract.CapitalCost + CapitalExpenditureCost_idContract.DebtCollectionDays * (CapitalExpenditureCost_idContract.Revenue - CapitalExpenditureCost_idContract.Deposits + CapitalExpenditureCost_idContract.DepositsNTP)) * (CapitalExpenditureCost_idContract.PercentInterest /100) / 365;
			let newCapitalExpenditureCost = {
				CapitalCost: 0, 
			    Revenue: 0,
			    CapitalExpense: 0,
			    InventoryDays: 15,
			    ImplementationDays: 30,
			    BedtDays: 30,
			    DebtCollectionDays: 15,
			    Deposits: 0,
			    DepositsNTP: 0,
			    PercentInterest: 10,
			    Note: '',
				ContractID: Contract._id
			};

			// Load data tu Form 1: Lay tong gia von va Doanh thu
	        newCapitalExpenditureCost.CapitalCost = TotalInputIntoMoney;
	        newCapitalExpenditureCost.Revenue = TotalOutputIntoMoney;
	        //Tinh Dat coc cua khach hang
			newCapitalExpenditureCost.Deposits = newCapitalExpenditureCost.Revenue * 0.2
			//Tinh Dat coc NTP
			newCapitalExpenditureCost.DepositsNTP = newCapitalExpenditureCost.CapitalCost * 0.3
			//Tinh chi phi von
		    newCapitalExpenditureCost.CapitalExpense = (typeof CapitalExpenditureCost_idContract[0] != "undefined") ? ((CapitalExpenditureCost_idContract[0].InventoryDays + CapitalExpenditureCost_idContract[0].ImplementationDays - CapitalExpenditureCost_idContract[0].BedtDays) * newCapitalExpenditureCost.CapitalCost + newCapitalExpenditureCost.DebtCollectionDays * (newCapitalExpenditureCost.Revenue - newCapitalExpenditureCost.Deposits + newCapitalExpenditureCost.DepositsNTP)) * (CapitalExpenditureCost_idContract[0].PercentInterest /100) / 365 : 0 ;
		    let TotalCapitalExpense = newCapitalExpenditureCost.CapitalExpense
			// console.debug("CapitalExpenditureCosts newCapitalExpenditureCost::::::", newCapitalExpenditureCost)

			//LoanInterests
			let LoanInterests_idContract = find_data_by_contract_id(LoanInterests, Contract._id);
			const TotalLoanInterest = sumArray(LoanInterests_idContract.map((LoanInterest ) => LoanInterest.InterestLoan))

			let chiPhi = 0;
			if(TotalCapitalExpense != 0){
				chiPhi = TotalCapitalExpense;
			}else{
				if(TotalLoanInterest != 0){
					chiPhi = TotalLoanInterest;
				}
			}

			//AuxiliaryCosts
			let AuxiliaryCost_idContract = find_data_by_contract_id(AuxiliaryCosts, Contract._id, 1);
			const TotalCost = SumListCost(AuxiliaryCost_idContract, TotalOutputIntoMoney);
			const rsTotalAuxiliaryCost = totalAuxiliaryCosts(AuxiliaryCost_idContract, TotalOutputIntoMoney)
			//ImplementationCost
			let ImplementationCost_idContract = find_data_by_contract_id(ImplementationCosts, Contract._id);
			const ImplementationCost = TotalStageImplementation(ImplementationCost_idContract);

			// 1.1 Chi phí triển khai hợp đồng + Chi phi Bảo hiểm
			const TotalImplementationCost = ImplementationCost + TotalInsurance + TotalInsuranceHSE;
			// 1. chi phi phat sinh khi thuc hien du an
			const ExtraCost = (TotalImplementationCost + chiPhi + TotalMandayCost + TotalGuaranteeLetterCost + TotalMiscExpenseCost)
			// 2.
			// 3.
			const projectEfficiency = (TotalOutputIntoMoney - TotalInputIntoMoney - ExtraCost - (rsTotalAuxiliaryCost.muc2*10))
			// console.log("rsTotalAuxiliaryCost", rsTotalAuxiliaryCost, "projectEfficiency", projectEfficiency)
			// Contracts[i].TotalInputIntoMoney = TotalInputIntoMoney //Chi phí
			// Contracts[i].projectEfficiency = Math.round(projectEfficiency) //Hiệu quả dự án
			// Contracts[i].TotalOutputIntoMoney = TotalOutputIntoMoney //Tổng doanh thu (8)

			//report 
			let ExternalCapitalCosts = Sum_InputIntoMoneyTypePurchase(ProductCost_idContract, false) //Giá vốn bên ngoài (9)
			let InternalCapitalCosts = Sum_InputIntoMoneyTypePurchase(ProductCost_idContract, true) //Giá vốn bên ngoài (10)
			let TotalSumIncentive = Sum_Incentive(ProductCost_idContract) //Incentive (11)
			let TotalSumInsurance = TotalInsurance //CP bảo hành (HSC) (12)
			// let TotalInsuranceHSE = TotalInsuranceHSE; //CP hỗ trợ kỹ thuật (HSE) (13)

			// CP triển khai (14)
			let GeneralExpenses = TotalStageImplementationAB(ImplementationCost_idContract, "a_chi_phi_chung")
			let DeploymentCosts = TotalStageImplementationAB(ImplementationCost_idContract, "b_chi_phi_trien_khai")
			// Contracts[i].GeneralExpenses = GeneralExpenses; //Giá trị thành tiền của Tổng A (Chi phí chung)
			// Contracts[i].DeploymentCosts = DeploymentCosts; //Giá trị thành tiền Tổng B (Chi phí triển khai)
			let TotalCostAB = (GeneralExpenses + DeploymentCosts); //Giá trị thành tiền Tổng của A và B

			// Contracts[i].TotalInputIntoMoney = TotalInputIntoMoney; //CP vốn (15)
			// Contracts[i].TotalMandayCost = TotalMandayCost; //CP manday (16)
			// Contracts[i].TotalGuaranteeLetterCost = Math.round(TotalGuaranteeLetterCost) //CP bảo lãnh (17)
			// Contracts[i].TotalMiscExpenseCost = Math.round(TotalMiscExpenseCost) // CP khác (18)

			let SumAuxiliaryCost = rsTotalAuxiliaryCost.muc2 //sumCostAB //CP tiền A+B (19)
			let SumAuxiliaryTax = rsTotalAuxiliaryCost.muc2_thue //sumTaxAB //CP Thuế A+B: (20)
			let SumAuxiliaryCostTax = rsTotalAuxiliaryCost.muc2_1 //sumCostTaxAB //CP tiền và thuế AB
			let TotalAuxiliaryAndMiscExpense = 0 //parseInt(TotalMiscExpenseCost + rsTotalAuxiliaryCost.sumCostAB) //Tổng CP: (21) = (18)+(19)

			//Lãi gộp (chưa có incentive): (22) = (8) - SUM(9-18) - (21)
			let GrossProfitNoIncentive = TotalOutputIntoMoney -
										(
											ExternalCapitalCosts +
											DeploymentCosts + 
											TotalSumIncentive + 
											TotalSumInsurance + 
											TotalInsuranceHSE + 
											(GeneralExpenses+DeploymentCosts) + 
											TotalInputIntoMoney + 
											TotalMandayCost + 
											Math.round(TotalGuaranteeLetterCost) + 
											Math.round(TotalMiscExpenseCost)
										)
										- parseInt(TotalMiscExpenseCost + rsTotalAuxiliaryCost.sumCostAB)
			// Contracts[i].GrossProfitNoIncentive = GrossProfitNoIncentive // (22)
			let RatioGrossProfitNoIncentiveAboveRevenue = !isNaN(projectEfficiency/TotalOutputIntoMoney) && TotalOutputIntoMoney != 0 ? ((projectEfficiency/TotalOutputIntoMoney)* 100).toFixed(2): 0 //Tỷ lệ LG/DT: (23) = (22)/(8)
			// let RatioGrossProfitNoIncentiveAboveRevenue = !isNaN(GrossProfitNoIncentive/TotalOutputIntoMoney) && TotalOutputIntoMoney != 0 ? GrossProfitNoIncentive/TotalOutputIntoMoney : 0 //Tỷ lệ LG/DT: (23) = (22)/(8)
			// Lãi gộp (đã bao gồm incentive): (25) = (22)+(24)
			let GrossProfitIncentive = parseInt(projectEfficiency) + parseInt(TotalSumIncentive)
			// let GrossProfitIncentive = parseInt(GrossProfitNoIncentive + Sum_Incentive(ProductCost_idContract)) 
			// Contracts[i].GrossProfitIncentive = GrossProfitIncentive //Tỷ lệ LG/DT: (25) = (22)+(24)
			// let RatioGrossProfitIncentiveAboveRevenue = !isNaN(GrossProfitIncentive/TotalOutputIntoMoney) && TotalOutputIntoMoney != 0 ? GrossProfitIncentive/TotalOutputIntoMoney : 0 //Tỷ lệ LG/DT: (26) = (25)/(8)
			let RatioGrossProfitIncentiveAboveRevenue = !isNaN(((projectEfficiency + TotalSumIncentive) / TotalOutputIntoMoney)) && isFinite(((projectEfficiency + TotalSumIncentive) / TotalOutputIntoMoney)) ? (((projectEfficiency + TotalIncentive) / TotalOutputIntoMoney) * 100).toFixed(2): 0

			//Invoices
			let Invoices_idContract = find_data_by_contract_id(Invoices, Contract._id)
			let Invoices_idContracts = find_data_by_contract_id(Invoices, Contract._id)
			let PercentInvoices = SumPercentInvoice(Invoices_idContract)
			let SumPercentInvoices = !isNaN(PercentInvoices/TotalOutputIntoMoney) ? PercentInvoices/TotalOutputIntoMoney == 1 ? 100 : ((PercentInvoices/TotalOutputIntoMoney)*100) : 0
			let lastInvoices_idContract = Invoices_idContract.length > 0 ? Invoices_idContract.pop() : ""
			let InvoiceVal = lastInvoices_idContract != "" ? !isNaN(SumPercentInvoices/TotalOutputIntoMoney) ? (SumPercentInvoices/TotalOutputIntoMoney).toFixed(2): 0 : 0
			let DateOfInvoice = lastInvoices_idContract != "" ? convert_time(lastInvoices_idContract.DateOfInvoice) : "" //Ngày xuất hóa đơn (27)
			let PercentInvoice = lastInvoices_idContract != "" ? SumPercentInvoices == 100 ? "100%" : SumPercentInvoices.toFixed(2) + "%" : "0%" //Tỷ lệ % xuất HĐ (28)
			let OutputIntoMoney = lastInvoices_idContract != "" ? SumPercentInvoices == 100 ? 0 : TotalOutputIntoMoney - PercentInvoices : "0" //Doanh thu còn lại chưa xuất HĐ (29)
			let NoteInvoices = Invoices_idContracts.length > 1 ? "Xuất hóa đơn nhiều đợt": "" //Ghi chú (30)
			
			Doanh_thu = Doanh_thu + TotalOutputIntoMoney
			Gia_von_ben_ngoai = Gia_von_ben_ngoai + ExternalCapitalCosts
			Gia_von_noi_bo = Gia_von_noi_bo + InternalCapitalCosts
			Incentive = Incentive + TotalSumIncentive
			CP_bao_hanh_HSC = CP_bao_hanh_HSC + TotalSumInsurance
			CP_ho_tro_ky_thuat_HSE = CP_ho_tro_ky_thuat_HSE + TotalInsuranceHSE
			CP_trien_khai = CP_trien_khai + TotalCostAB
			CP_manday = CP_manday + TotalMandayCost
			CP_bao_lanh = CP_bao_lanh + TotalGuaranteeLetterCost
			CP_khac = CP_khac + TotalMiscExpenseCost
			CP_vat_tu_phu = CP_vat_tu_phu + SumAuxiliaryCost
			Thue = Thue + SumAuxiliaryTax
			Tong_CP = Tong_CP + parseInt(SumAuxiliaryCostTax)
			Lai_gop_chua_co_incentive = Lai_gop_chua_co_incentive + Math.round(projectEfficiency)
			Lai_gop_da_bao_gom_incentive = Lai_gop_da_bao_gom_incentive + Math.round(GrossProfitIncentive)
			Doanh_thu_con_lai_chua_xuat_HD = Doanh_thu_con_lai_chua_xuat_HD + parseInt(OutputIntoMoney)
			CP_von = CP_von + chiPhi

			array_result = [...array_result, {
				_id: Contract._id,
				STT,
				CenterID,
				DepartmentID,
				CustomerID,
				user,
				ContractID,
				RatioUSD,
				VAT,
				Date: DateTemp,
				TotalInputIntoMoney,
				projectEfficiency,
				TotalOutputIntoMoney,
				GeneralExpenses,
				DeploymentCosts,
				TotalCostAB,
				TotalMandayCost,
				TotalGuaranteeLetterCost,
				SumAuxiliaryCost,
				SumAuxiliaryTax,
				SumAuxiliaryCostTax,
				TotalAuxiliaryAndMiscExpense,
				GrossProfitNoIncentive,
				RatioGrossProfitNoIncentiveAboveRevenue,
				GrossProfitIncentive,
				RatioGrossProfitIncentiveAboveRevenue,
				DateOfInvoice,
				PercentInvoice,
				OutputIntoMoney,
				NoteInvoices,
				SumPercentInvoices,
				TotalSumIncentive,
				TotalSumInsurance,
				ExternalCapitalCosts,
				InternalCapitalCosts,
				TotalInsuranceHSE,
				TotalMiscExpenseCost,
				chiPhi
			}];
		})

		if (isTotal && array_result.length > 0) {
			array_result = [...array_result, {
			_id: Contract._id,
			STT: "Tổng",
			CenterID: "",
			DepartmentID: "",
			CustomerID: "",
			user: "",
			ContractID: "",
			RatioUSD: "",
			VAT: "",
			Date: "",
			TotalInputIntoMoney: "",
			projectEfficiency: Lai_gop_chua_co_incentive,
			TotalOutputIntoMoney: Doanh_thu,
			GeneralExpenses: "",
			DeploymentCosts: "",
			TotalCostAB: CP_trien_khai,
			TotalMandayCost: CP_manday,
			TotalGuaranteeLetterCost: CP_bao_lanh,
			SumAuxiliaryCost: CP_vat_tu_phu,
			SumAuxiliaryTax: Thue,
			SumAuxiliaryCostTax: Tong_CP,
			TotalAuxiliaryAndMiscExpense: "",
			GrossProfitNoIncentive: Lai_gop_chua_co_incentive,
			RatioGrossProfitNoIncentiveAboveRevenue: "",
			GrossProfitIncentive: Lai_gop_da_bao_gom_incentive,
			RatioGrossProfitIncentiveAboveRevenue: "",
			DateOfInvoice: "",
			PercentInvoice: "",
			OutputIntoMoney: Doanh_thu_con_lai_chua_xuat_HD,
			NoteInvoices: "",
			SumPercentInvoices: "",
			TotalSumIncentive: Incentive,
			TotalSumInsurance: CP_bao_hanh_HSC,
			ExternalCapitalCosts: Gia_von_ben_ngoai,
			InternalCapitalCosts: Gia_von_noi_bo,
			TotalInsuranceHSE: CP_ho_tro_ky_thuat_HSE,
			TotalMiscExpenseCost: CP_khac,
			chiPhi: CP_von
		}];
		}

		return array_result
	}

	function findArrayItem(array, id) {
		return array.find(item => item._id == id); 
	}	
	// hàm convert to date time
	function convert_time(date_time){
		const date = new Date(date_time); // Replace this with your actual date object
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		const seconds = ('0' + date.getSeconds()).slice(-2);
		const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		return formattedDate;
	}

	function getCurrentDate(separator){
	    let newDate = new Date()
	    let date = newDate.getDate();
	    let month = newDate.getMonth() + 1;
	    let year = newDate.getFullYear();
	    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
	}

	function get_status_name(array,ContractID,event_array){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			let statusID = lastElement.StatusID;
			for (var i = 0; i < event_array.length; i++) {
				let value = event_array[i];
				if (value.statusID == statusID) {
					// return value.name+" ("+statusID+")";
					return value.name;
				}
			}
		}else{
			return '';
		}
	}

	function get_status(array,ContractID){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return '';
		}
	}


	const [newFilter, setNewFilter] = useState({
		fromDate:"",
		toDate: "",
		statusInvoice: "",
		DepartmentID: "",
		CenterID: "",
		staffName: "",
		grossProfitVaule: "",
		grossProfitPercentage: ""
	})
	
	const {
		fromDate,
		toDate,
		statusInvoice,
		DepartmentID,
		CenterID,
		staffName,
		grossProfitVaule,
		grossProfitPercentage
	} = newFilter

	const onChangeNewFilter = event => {
		let name = event.target.name;
		let val = event.target.value
		
		if (name == "fromDate" || name == "toDate") {
			setNewFilter({ ...newFilter, [name]: val.split("-").reverse().join("-") })
		}else if (name == "grossProfitPercentage") {
			if (!isNumberOrDecimal(val) && val != '') {
				var message = 'Vui lòng nhập tỉ lệ % không quá 2 số thập phận'
				setShowToast({ show: true, message: message, type: 'danger' })
				event.target.value = val
				return false;
			}else{
				var value = (val != "") ? val : 0;
				if (parseInt(val) > 100 && parseFloat(val) > 100) {
					var message = 'Vui lòng nhập tỷ lệ không quá 100%'
					setShowToast({ show: true, message: message, type: 'danger' })
					event.target.value = val
					return false;
				}else if (val == "00" && parseInt(val) == 0 && parseFloat(val) == 0) {
					var message = 'Vui lòng nhập tỷ lệ lớn hơn 0%'
					setShowToast({ show: true, message: message, type: 'danger' })
					event.target.value = val
					return false;
				}
				setNewFilter({ ...newFilter, [name]: val })
			}
		}
		setNewFilter({ ...newFilter, [name]: val.trim() })
	}

	const isNumberOrDecimal = userInput => {
		// if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		// return /^\d+$/.test(userInput); ok
  		// return /((?:\d+\.\d*)|(?:\d*\.?\d+))$/.test(userInput); ok
  		// return /^\d*\.?\d*$/.test(userInput) //ok
  		return /^\d+(\.\d{0,2})?$/.test(userInput)
	}

	const isParentNumber = userInput => {
		if (typeof userInput !== 'string') return false;
		return /^\d+$/.test(userInput);
	}

	function approximatelyEqual(a, b) {
	    return Math.abs(a - b) < 0.01;
	}

	const handleFilterChange = () => {
		let condition = newFilter
		condition.fromDate = (startDate != null) ? formatDate(startDate) : ''
		condition.toDate = (endDate != null) ? formatDate(endDate) : ''
		condition.invoicingTime = (invoicingTime != null) ? formatDate(invoicingTime) : ''
		let rs = loopReport()
		console.log(condition, rs)
		// setNewContracts(rs);
		const filteredData = rs.filter((item) => {
		    // Date range filtering
		    let inDateRange = true;

		    if (condition.fromDate) {
		        const fromDate = new Date(condition.fromDate+ " 00:00:00");
		        const date = new Date(item.Date);
		        inDateRange = date >= fromDate;
		    }

		    if (condition.toDate) {
		        const toDate = new Date(condition.toDate + " 23:59:59");
		        const date = new Date(item.Date);
		        inDateRange = inDateRange && date <= toDate;
		    }

		    // Invoice status filtering
		    let statusCondition;
		    if (condition.statusInvoice == "<100") {
		        statusCondition = item.SumPercentInvoices < 100;
		    } else if (condition.statusInvoice == "100") {
		        statusCondition = item.SumPercentInvoices == 100;
		    } else {
		        statusCondition = true; // no condition
		    }

		    // Department filtering
		    // let departmentCondition;
		    // if (condition.DepartmentID) {
		    //     departmentCondition = item.DepartmentID == condition.DepartmentID;
		    // } else {
		    //     departmentCondition = true; // no condition
		    // }

			// Centers filtering
		    let centerCondition;
		    if (condition.CenterID) {
		        centerCondition = item.CenterID == condition.CenterID;
		    } else {
		        centerCondition = true; // no condition
		    }

			// Users filtering
			let userCondition;
		    if (condition.staffName) {
		        userCondition = item.user == condition.staffName;
		    } else {
		        userCondition = true; // no condition
		    }

			//
			let inDateOfInvoice = true
			if (condition.invoicingTime) {
		        const invoicingTimeDateStart = new Date(condition.invoicingTime+ " 00:00:00");
		        const invoicingTimeDateEnd = new Date(condition.invoicingTime+ " 23:59:59");
		        const date = new Date(item.DateOfInvoice);
		        inDateOfInvoice = inDateOfInvoice && date <= invoicingTimeDateEnd && date >= invoicingTimeDateStart;
		    }

		    let grossProfitVauleCondition = true
			if (condition.grossProfitVaule) {
		        switch (condition.grossProfitVaule) {
				    case "<500M":
				        grossProfitVauleCondition = item.projectEfficiency < 499999999
				        break;
				    case "500M-1000M":
				        grossProfitVauleCondition = item.projectEfficiency <= 999999999 && item.projectEfficiency >= 500000000
				        break;
				    case "1000M-1500M":
				        grossProfitVauleCondition = item.projectEfficiency <= 1499999999 && item.projectEfficiency >= 1000000000
				        break;
				    case "1500M-2000M":
				        grossProfitVauleCondition = item.projectEfficiency <= 1999999999 && item.projectEfficiency >= 1500000000
				        break;
				    case ">2000M":
				    	grossProfitVauleCondition = item.projectEfficiency >= 2000000000
				    	break;
				}
		    }

		    let grossProfitPercentageCondition = true
			if (condition.grossProfitPercentage) {
		        grossProfitPercentageCondition = approximatelyEqual(condition.grossProfitPercentage, item.RatioGrossProfitNoIncentiveAboveRevenue)
		    }

		    // Filter by all conditions
		    return  inDateRange &&
		    		statusCondition &&
		    		centerCondition &&
		    		userCondition &&
		    		inDateOfInvoice &&
		    		grossProfitVauleCondition &&
		    		grossProfitPercentageCondition;
		});

		// console.log(filteredData, "filteredData")
		let rsfilteredData = loopReport(filteredData, true)
		setNewContracts(rsfilteredData);
		setIsSearch(true)
	}

	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
	
		if (month.length < 2) 
			month = '0' + month;
		if (day.length < 2) 
			day = '0' + day;
	
		return [year, month, day].join('-');
	}

	const resetFilter = () => {
		setNewFilter({ 
			fromDate:"",
			toDate: "",
			statusInvoice: "",
			DepartmentID: "",
			CenterID: "",
			staffName: "",
			grossProfitVaule: "",
			grossProfitPercentage: "",
	 	})
		setDateRange([null, null])
		setInvoicingTime(null)
		// let rs = loopReport()
		setNewContracts([])
		setIsSearch(false)
	}

	// const newFilterCenters = (typeof Centers != "undefined") ? Centers.filter((element) => element.CenterName !== "HO") : [];
	let newFilterCenters;
	if (typeof Centers != "undefined") {
		switch(roleName){
			case 'AM':
			case 'TPKD':
				newFilterCenters = filter_Users(Users, UserID).center_details;
				break;
			case 'KTT':
			case 'BGD':
				newFilterCenters = filter_Users(Users, UserID).center_details;
				break;
			case 'BTGD':
			case 'Admin':
				newFilterCenters = Centers;
				break;
			default: 
				newFilterCenters = []
		}
	}
		
	const Filter = () => {
		return (
			<>
			</>
		)
	}

	const [invoicingTime, setInvoicingTime] = useState(null);
	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;

	let body = null
	let stt = 1

	console.debug("newContracts", newContracts, isSearch)

	if (ContractsLoading) {
		body = (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	}else if (Contracts.length === 0) {
		body = (
			<>
				<Card className='text-center'>
					<Card.Header as='h2'>Báo cáo Doanh thu nội bộ</Card.Header>
					<Card.Body>
						<Form.Row className="form-row-custom">
							<Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>Ngày lập PTHĐ</Form.Label>
								<br></br>
								<DatePicker
									className='form-control fix-select-date-ranger'
									selectsRange={true}
									startDate={startDate}
									endDate={endDate}
									onChange={(update) => {
										setDateRange(update);
									}}
									isClearable={true}
									dateFormat="yyyy/MM/dd"
									maxDate={new Date()}
								/>
							</Form.Group>

							{/* <Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>đến ngày</Form.Label>
								<Form.Control
									type='date'
									placeholder="Đến ngày"
									name='toDate'
									value={toDate}
									onChange={onChangeNewFilter}
								/>
							</Form.Group> */}

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tình trạng hóa đơn</Form.Label>
								<Form.Control
									as="select"
									value={statusInvoice}
									name='statusInvoice'
									onChange={onChangeNewFilter}
									>
									<option value=''>-- chọn tình trạng --</option>
									<option value="100">Xuất hóa đơn đủ 100%</option>
									<option value="<100">Chưa hóa đơn đủ 100%</option>
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom fix-select-center" as="Col" controlId="formGridPassword">
								<Form.Label>Trung tâm</Form.Label>
								<Form.Control
									id = 'noidung-help'
									as="select"
									name='CenterID'
									aria-describedby='noidung-help'
									value={CenterID}
									onChange={onChangeNewFilter}>
									<option value=''>-----</option>
									{
										typeof newFilterCenters != "undefined" ? 
										newFilterCenters.map((obj => {
											return (
												<>
													<option value={obj.CenterID}>{obj.CenterName}</option>
												</>
											)
										}))
										:
										<></>
									}
									
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tài khoản nhân viên</Form.Label>
								<Form.Control
									type='text'
									placeholder=""
									name='staffName'
									value={staffName}
									onChange={onChangeNewFilter}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>Thời gian xuất hóa đơn</Form.Label>
								<br></br>
								<DatePicker
									selected={invoicingTime}
									onChange={(date) => setInvoicingTime(date)}
									className='form-control'
									dateFormat='yyyy/MM/dd'
									maxDate={new Date()}
									isClearable={true}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Giá trị lãi gộp</Form.Label>
								<Form.Control
									as="select"
									value={grossProfitVaule}
									name='grossProfitVaule'
									onChange={onChangeNewFilter}
									>
									<option value=''>-- giá trị --</option>
									<option value='<500M'>Dưới 500 triệu</option>
									<option value='500M-1000M'>500 triệu - 1 tỷ</option>
									<option value='1000M-1500M'>1 tỷ - 1 tỷ 500 triệu</option>
									<option value='1500M-2000M'>1 tỷ 500 triệu - dưới 2 tỷ</option>
									<option value='>2000M'>Trên 2 tỷ</option>
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tỷ lệ % lãi gộp</Form.Label>
								<Form.Control
									type='text'
									placeholder=""
									name='grossProfitPercentage'
									value={grossProfitPercentage}
									onChange={onChangeNewFilter}
								/>
							</Form.Group>
						</Form.Row>

						<Form.Row className="form-row-custom">
							<Form.Group as="Col" controlId="formGridPassword">
								<Form.Label>&nbsp;</Form.Label>
								<Col xs="auto">
									<OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Xóa lọc báo cáo
											</Tooltip>
										}
									>
									<a onClick={resetFilter}>
										<img src={iconFilterReset} width="35" height="35"></img>
									</a>
									</OverlayTrigger>
								</Col>
							</Form.Group>

							<Form.Group as="Col" controlId="formGridPassword">
								<Form.Label>&nbsp;</Form.Label>
								<Col xs="auto">
									{/* <OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Lọc báo cáo
											</Tooltip>
										}
									>
									<a onClick={handleFilterChange}>
										<img src={iconViewReport} width="35" height="35"></img>
									</a>
									</OverlayTrigger> */}

									<Button
										variant='primary'
										onClick={handleFilterChange}
									>
										Lọc báo cáo
									</Button>
								</Col>
							</Form.Group>
						</Form.Row>

						<div id="table-scroll" className="table-scroll table-class-data-0">
							<table id="main-table" className="main-table table table-lg table-hover">
								<thead>
									<tr>
										<th scope="col" className='z-index-th header-table'>STT</th>
										<th scope="col" className='z-index-th header-table'>Ngày lập PTHD</th>
										<th scope="col" className='z-index-th header-table'>Số hợp đồng</th>
										<th scope="col" className='z-index-th header-table'>Tên khách hàng</th>
										<th scope="col" className='z-index-th header-table'>Tên trung tâm</th>
										<th scope="col" className='z-index-th header-table'>Tên phòng ban</th>
										<th scope="col" className='header-table'>Tên NV</th>
										<th scope="col" className='header-table'>Doanh thu</th>
										<th scope="col" className='header-table'>Giá vốn nội bộ</th>
										<th scope="col" className='header-table'>Lãi gộp(đã bao gồm incentive)</th>
										<th scope="col" className='header-table'>Tỷ lệ LG (gồm incentive)/DT</th>
										<th scope="col" className='header-table'>Ngày xuất hóa đơn</th>
										<th scope="col" className='header-table'>Ghi chú</th>
									</tr>
								</thead>
								<tbody>
									
								</tbody>
							</table>
						</div>
					</Card.Body>
				</Card>
			</>
		)
	} else {
		body = (
			<>
				<Card className='text-center'>
					<Card.Header as='h2'>Báo cáo Doanh thu nội bộ</Card.Header>
					<Card.Body>
						<Form.Row className="form-row-custom">
							<Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>Ngày lập PTHĐ</Form.Label>
								<br></br>
								<DatePicker
									className='form-control fix-select-date-ranger'
									selectsRange={true}
									startDate={startDate}
									endDate={endDate}
									onChange={(update) => {
										setDateRange(update);
									}}
									isClearable={true}
									dateFormat="yyyy/MM/dd"
									maxDate={new Date()}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tình trạng hóa đơn</Form.Label>
								<Form.Control
									as="select"
									value={statusInvoice}
									name='statusInvoice'
									onChange={onChangeNewFilter}
									>
									<option value=''>-- chọn tình trạng --</option>
									<option value="100">Xuất hóa đơn đủ 100%</option>
									<option value="<100">Chưa hóa đơn đủ 100%</option>
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom fix-select-center" as="Col" controlId="formGridPassword">
								<Form.Label>Trung tâm</Form.Label>
								<Form.Control
									id = 'noidung-help'
									as="select"
									name='CenterID'
									aria-describedby='noidung-help'
									value={CenterID}
									onChange={onChangeNewFilter}>
									<option value=''>-----</option>
									{
										typeof newFilterCenters != "undefined" ? 
										newFilterCenters.map((obj => {
											return (
												<>
													<option value={obj.CenterName.trim()}>{obj.CenterName.trim()}</option>
												</>
											)
										}))
										:
										<></>
									}
									
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tài khoản nhân viên</Form.Label>
								<Form.Control
									type='text'
									placeholder=""
									name='staffName'
									value={staffName}
									onChange={onChangeNewFilter}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridEmail">
								<Form.Label>Thời gian xuất hóa đơn</Form.Label>
								<br></br>
								<DatePicker
									selected={invoicingTime}
									onChange={(date) => setInvoicingTime(date)}
									className='form-control'
									dateFormat='yyyy/MM/dd'
									maxDate={new Date()}
									isClearable={true}
								/>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Giá trị lãi gộp</Form.Label>
								<Form.Control
									as="select"
									value={grossProfitVaule}
									name='grossProfitVaule'
									onChange={onChangeNewFilter}
									>
									<option value=''>-- giá trị --</option>
									<option value='<500M'>Dưới 500 triệu</option>
									<option value='500M-1000M'>500 triệu - 1 tỷ</option>
									<option value='1000M-1500M'>1 tỷ - 1 tỷ 500 triệu</option>
									<option value='1500M-2000M'>1 tỷ 500 triệu - dưới 2 tỷ</option>
									<option value='>2000M'>Trên 2 tỷ</option>
								</Form.Control>
							</Form.Group>

							<Form.Group className="form-group-custom" as="Col" controlId="formGridPassword">
								<Form.Label>Tỷ lệ % lãi gộp</Form.Label>
								<Form.Control
									type='text'
									placeholder=""
									name='grossProfitPercentage'
									value={grossProfitPercentage}
									onChange={onChangeNewFilter}
								/>
							</Form.Group>
						</Form.Row>

						<Form.Row className="form-row-custom">
							<Form.Group as="Col" controlId="formGridPassword">
								<Form.Label>&nbsp;</Form.Label>
								<Col xs="auto">
									<OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Xóa lọc báo cáo
											</Tooltip>
										}
									>
									<a onClick={resetFilter}>
										<img src={iconFilterReset} width="35" height="35"></img>
									</a>
									</OverlayTrigger>
								</Col>
							</Form.Group>

							<Form.Group as="Col" controlId="formGridPassword">
								<Form.Label>&nbsp;</Form.Label>
								<Col xs="auto">
									{/* <OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Lọc báo cáo
											</Tooltip>
										}
									>
									<a onClick={handleFilterChange}>
										<img src={iconViewReport} width="35" height="35"></img>
									</a>
									</OverlayTrigger> */}

									<Button
										variant='primary'
										onClick={handleFilterChange}
									>
										Lọc báo cáo
									</Button>
								</Col>
							</Form.Group>
						</Form.Row>

						<div id="table-scroll" className={newContracts.length > 15 ? "table-scroll": "table-scroll table-scroll-fit-content"}>
							<table id="main-table" className="main-table table table-lg table-hover">
								<thead>
									<tr>
										<th scope="col" className='z-index-th header-table'>STT</th>
										<th scope="col" className='z-index-th header-table'>Ngày lập PTHD</th>
										<th scope="col" className='z-index-th header-table'>Số hợp đồng</th>
										<th scope="col" className='z-index-th header-table'>Tên khách hàng</th>
										<th scope="col" className='z-index-th header-table'>Tên trung tâm</th>
										<th scope="col" className='z-index-th header-table'>Tên phòng ban</th>
										<th scope="col" className='header-table'>Tên NV</th>
										<th scope="col" className='header-table'>Doanh thu</th>
										<th scope="col" className='header-table'>Giá vốn nội bộ</th>
										<th scope="col" className='header-table'>Lãi gộp(đã bao gồm incentive)</th>
										<th scope="col" className='header-table'>Tỷ lệ LG (gồm incentive)/DT</th>
										<th scope="col" className='header-table'>Ngày xuất hóa đơn</th>
										<th scope="col" className='header-table'>Ghi chú</th>
									</tr>
								</thead>
								<tbody>
									{
										isSearch ? 
										<>
										{
											newContracts.length ? 
											<>
											{
												newContracts.map(Contract => (
													<tr className={(Contract.STT == "Tổng") ? "classNameTotal": ""} >
														<th scope="col" className="custom-font-w">{(Contract.STT == "Tổng") ? "Tổng": stt++}</th>
														<th scope="col" className="custom-font-w fm-col-string">{Contract.Date}</th>
														<th scope="col" className="custom-font-w fm-col-string">{Contract.ContractID}</th>
														<th scope="col" className="custom-font-w fm-col-string">
															<span
															className='text'
															title={Contract.CustomerID}
															>
																{Contract.CustomerID}
															</span>
														</th>
														<th scope="col" className="custom-font-w fm-col-string">
															<span
															className='text'
															title={Contract.CenterID}
															>
																{Contract.CenterID}
															</span>
														</th>
														<th scope="col" className="custom-font-w fm-col-string">
															<span
															className='text'
															title={Contract.DepartmentID}
															>
																{Contract.DepartmentID}
															</span>
														</th>
														<td scope="col" className="fm-col-string">{Contract.user}</td>
														<td scope="col" className="fm-col-number">{Contract.TotalOutputIntoMoney.toLocaleString()}</td>
														<td scope="col" className="fm-col-number">{Contract.InternalCapitalCosts.toLocaleString()}</td>
														<td scope="col" className="fm-col-number">{Math.round(Contract.GrossProfitIncentive).toLocaleString()}</td>
														<td scope="col" className="fm-col-number">
															{
																Contract.STT == "Tổng" ? 
																	"" 
																	:
																	Contract.RatioGrossProfitIncentiveAboveRevenue.toLocaleString() + "%"
															}
														</td>
														<td scope="col">
															{
																Contract.STT == "Tổng" ? 
																	"" 
																	:
																	Contract.DateOfInvoice != "" ? 
																	Contract.DateOfInvoice.toLocaleString()
																	: 
																	"Chưa xuất hóa đơn"		
															}
														</td>
														<td scope="col">
															{
																Contract.STT == "Tổng" ? "" : Contract.NoteInvoices
															}
														</td>
													</tr>
												))
											}
											</>
											:
											<>
												<tr>
													<th scope="col" colSpan={7} className="custom-font-w">Không tìm thấy dữ liệu phù hợp</th>
													<td scope="col" colSpan={21}></td>
												</tr>
											</>
										}
										</>
										:
										<>
											<tr>
												<th scope="col" colSpan={7} className="custom-font-w">Chọn điều kiện để lọc báo cáo</th>
												<td scope="col" colSpan={21}></td>
											</tr>
										</>
									}
								</tbody>
							</table>
						</div>
					</Card.Body>
				</Card>
			</>
		)
	}

	const {
		UserPageState: { UserPage, UserPages, UserPagesLoading },
		getAllUserPages
	} = useContext(UserPageContext)

	const {
	    MenuState: { Menu, Menus, MenusLoading },
	    getMenu,
	} = useContext(MenuContext);

	function FindArrayMenu(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return null;
		}
	}

	if (UserPagesLoading && MenusLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	}else {
		const result = UserPages.filter(obj => { return obj.user_id.some(id => id === UserID); });
		let menu_access = false
		if (result.length > 0) {
			for (var i = 0; i < result.length; i++) {
				let menuV1 = FindArrayMenu(Menus, result[i].page_id[0])
				if (menuV1 != null && menuV1.path == window.location.pathname) {
					// console.debug("menuV1 co quyen truy cap")
					menu_access = true
					break;
				}else {
					// console.debug("menuV1 khong quyen truy cap")
				}
			}
		} else {
			// console.debug("menu khong quyen truy cap")
		}

		if (menu_access) {
			return (
				<>
					{body}
					<Toast
						show={show}
						style={{ position: 'fixed', top: '20%', right: '10px', zIndex: '999999'}}
						className={`bg-${type} text-white`}
						onClose={setShowToast.bind(this, {
							show: false,
							message: '',
							type: null
						})}
						delay={3000}
						autohide
					>
						<Toast.Body>
							<strong>{message}</strong>
						</Toast.Body>
					</Toast>
				</>
			)
		}else {
			return (
				<>
					<div id="notfound">
						<div className="notfound">
							<h1>Oops!</h1>
							<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
								<br/>
								<Link style={{textDecoration: "none"}} to="/home">
									Trở về trang chủ
								</Link>
							</h6>
						</div>
					</div>
				</>
			)
		}
	}
}