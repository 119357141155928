import { useContext, useEffect, useState } from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useParams, Link } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Toast from 'react-bootstrap/Toast'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'

import { AuthContext } from '../contexts/AuthContext'
import { ConfigCenterContext } from '../contexts/ConfigCenterContext'
import { ConfigDepartmentContext } from '../contexts/ConfigDepartmentContext'
import { UserContext } from '../contexts/UserContext'
import { UserPageContext } from '../contexts/UserPageContext'
import { MenuContext } from '../contexts/MenuContext'
import { ContractContext } from '../contexts/ContractContext'
import { RoleContext } from '../contexts/RoleContext'
import { UserManagerContext } from '../contexts/UserManagerContext'

import AddDepartmentModal from '../components/Config/Department/AddDepartmentModal'//Note
import UpdateDepartmentModal from '../components/Config/Department/UpdateDepartmentModal'//Note
import ActionButtons_Department from '../components/Config/Department/ActionButtons_Department'
const ConfigDepartment = () => {
	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)

	const params = useParams();

	const {
		CenterState: { Centers, CentersLoading },
		get_All_Center
	} = useContext(ConfigCenterContext)

	const {
		DepartmentState: { Department, Departments, DepartmentsLoading },
		showAddDepartmentModal,
		setShowAddDepartmentModal,
		getDepartment,
		showToast: { show, message, type },
		setShowToast
	} = useContext(ConfigDepartmentContext)


	useEffect(() => getDepartment(), [])
	useEffect(() => get_All_Center(), [])

	let body = null;
	let i = 1;
	let UserID = _id;
	
	// Departments.sort((a, b) => (b.TypeDepartment === "2") - (a.TypeDepartment === "2"));
	//
	const centersOrder = ["649bb33b5f819d033f8535a0", "649bb3405f819d033f8535a3", "649bb3445f819d033f8535a6", "649bb3495f819d033f8535a9", "649bb34f5f819d033f8535ac"];

	Departments.sort((a, b) => {

		// So sánh TypeDepartment
		if (a.TypeDepartment === "2" && b.TypeDepartment === "1") return -1;
		if (a.TypeDepartment === "1" && b.TypeDepartment === "2") return 1;

		// So sánh centers
		const aIndex = centersOrder.indexOf(a.centers[0]); 
		const bIndex = centersOrder.indexOf(b.centers[0]);

		if(aIndex !== bIndex) {
			return aIndex - bIndex;  
		}

		// So sánh _id 
		return centersOrder.indexOf(a._id) - centersOrder.indexOf(b._id);
	});
	//
	// console.log("Departments:::", Departments, Centers)
	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	body = (
		<>
	        <Card className='text-center mx-5 my-5'>
	            <Card.Header as='h1'>Danh sách phòng ban</Card.Header>
	            <Card.Body>
		            <Table responsive="lg" striped bordered hover size="lg" variant="" >
		                <thead>
			                <tr style={{textAlign : 'center'}}>
			                    <th style={{verticalAlign:'inherit'}}>STT</th>
			                    <th style={{verticalAlign:'inherit'}}>Mã Phòng</th>
			                    <th style={{verticalAlign:'inherit'}}>Tên trung tâm</th>
			                    <th style={{verticalAlign:'inherit'}}>Tên phòng KD</th>
			                    <th style={{verticalAlign:'inherit'}}>Loại</th>
			                    <th style={{verticalAlign:'inherit'}}>MandayCost</th>
			                    <th style={{verticalAlign:'inherit'}}>Thao tác</th>
			                </tr>
		                </thead>
		                <tbody>
		                	{

		                		Departments.map(Department => (
			                	<tr>
				                	<td>{i++}</td>
				                	<td>{Department.CodeDepartment}</td>
				                	<td>{(Department.centers[0] != null) ? FindArray(Centers, Department.centers[0]).CenterName: ""}</td>
				                	<td>{Department.DepartmentName}</td>
				                	<td>{Department.TypeDepartment == 1 ? '' : 'Manday'}</td>
				                	<td>{Department.MandayCost}</td>
				                	<td><ActionButtons_Department 
				                		id={Department._id}
				                		CodeDepartment={Department.CodeDepartment}
				                		DepartmentName={Department.DepartmentName}
				                		TypeDepartment={Department.TypeDepartment}
				                		MandayCost={Department.MandayCost}
				                		CentersID={(Department.centers[0] != null) ? Department.centers[0] : ""}
				                	/></td>
				                </tr>
				                ))
				            }
			                <tr>
								<td colSpan={7}>
									<Button
										variant='primary'
										onClick={setShowAddDepartmentModal.bind(this, true)}
									>
										Thêm phòng ban
									</Button>
								</td>
							</tr>
		                </tbody>
		            </Table>
		           {/* <a href={`/summary/${params.id}`}>
		              <Button
		                variant='primary'
		              >
		                Xem PTHĐ
		              </Button>
		            </a>
		            <span> </span>
		            <a href={`/inputform/${params.id}`}>
		              <Button
		                variant='primary'
		              >
		                Quay lại
		              </Button>
		            </a>*/}
	            </Card.Body>
	        </Card>
	    </>
	)

    const {
		UserPageState: { UserPage, UserPages, UserPagesLoading },
		getAllUserPages
	} = useContext(UserPageContext)

	const {
	    MenuState: { Menu, Menus, MenusLoading },
	    getMenu,
	} = useContext(MenuContext);

	function FindArrayMenu(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return null;
		}
	}

	if (UserPagesLoading && MenusLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	}else {
		const result = UserPages.filter(obj => { return obj.user_id.some(id => id === UserID); });
		let menu_access = false
		if (result.length > 0) {
			for (var j = 0; j < result.length; j++) {
				let menuV1 = FindArrayMenu(Menus, result[j].page_id[0])
				if (menuV1 != null && menuV1.path == location.pathname) {
					// console.debug("menuV1 co quyen truy cap")
					menu_access = true
					break;
				}else {
					// console.debug("menuV1 khong quyen truy cap")
				}
			}
		} else {
			// console.debug("menu khong quyen truy cap")
		}

		if (menu_access) {
			return (
		    	<>
		    	{body}
		    	<AddDepartmentModal />
		    	<UpdateDepartmentModal />
		    	<Toast
						show={show}
						style={{ position: 'fixed', top: '20%', right: '10px' }}
						className={`bg-${type} text-white`}
						onClose={setShowToast.bind(this, {
							show: false,
							message: '',
							type: null
						})}
						delay={3000}
						autohide
					>
						<Toast.Body>
							<strong>{message}</strong>
						</Toast.Body>
					</Toast>
		    	</>
		    )
		}else {
			return (
				<>
					<div id="notfound">
						<div className="notfound">
							<h1>Oops!</h1>
							<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
								<br/>
								<Link style={{textDecoration: "none"}} to="/home">
									Trở về trang chủ
								</Link>
							</h6>
						</div>
					</div>
				</>
			)
		}
	}
}

export default ConfigDepartment
