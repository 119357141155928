import playIcon from '../../assets/play-btn.svg'
import editIcon from '../../assets/pencil.svg'
import deleteIcon from '../../assets/trash.svg'
import Button from 'react-bootstrap/Button'
// import { InvoiceContext } from '../../contexts/InvoiceContext'
import { useContext } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { InvoiceContext } from '../../contexts/InvoiceContext'

import iconEdit from '../../assets/icon-edit.svg'
import iconDelete from '../../assets/icon-delete.svg'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ActionButtons_Invoice = (_id) => {
	const {deleteInvoice, Data_update, setData_update, setShowUpdateInvoiceModal, editInvoiveVal, seteditInvoiveVal } = useContext(
		InvoiceContext
	) 
	const chooseInvoice = _id => {
		//findInvoice(InvoiceId)
		setData_update(_id)
		seteditInvoiveVal(_id.InvoiceVal)
		setShowUpdateInvoiceModal(true)
	}
	function submit () {
		confirmAlert({
		  title: 'Xoá dữ liệu',
		  message: '',
		  buttons: [
			{
			  label: 'Có',
			  onClick: () => deleteInvoice(_id)
			},
			{
			  label: 'Không',
			  onClick: () => closeDialog()
			}
		  ]
		});
	  };
	  const closeDialog = () => {
		setShowUpdateInvoiceModal(false)
	}
	return (
		<>
			<OverlayTrigger
				overlay={
					<Tooltip id="tooltip">
						Cập nhật
					</Tooltip>
				}
			>
				<Button className='post-button' onClick={chooseInvoice.bind(this, _id)}>
					<img src={iconEdit} alt='edit' width='24' height='24' />
				</Button>
			</OverlayTrigger>
			<OverlayTrigger
				overlay={
					<Tooltip id="tooltip">
						Xóa
					</Tooltip>
				}
			>
				<Button className='post-button' onClick={submit}>
					<img src={iconDelete} alt='delete' width='24' height='24' />
				</Button>
			</OverlayTrigger>
		</>
	)
}

export default ActionButtons_Invoice
