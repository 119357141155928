
import * as React from 'react';
import { BrowserRouter as Routes, Route, useParams } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Button from 'react-bootstrap/Button'
import editIcon from '../../assets/pencil.svg'
import deleteIcon from '../../assets/trash.svg'
import listIcon from '../../assets/list.svg'
import { ContractContext } from '../../contexts/ContractContext'
import { HistoryContext } from '../../contexts/HistoryContext'
import { useContext,useState,useEffect } from 'react'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import iconSendRequest from '../../assets/send-request.svg'
import iconEdit from '../../assets/icon-edit.svg'
import iconDelete from '../../assets/icon-delete.svg'
import iconAccept from '../../assets/icon-accept.svg'
import iconDeny from '../../assets/icon-deny.svg'
import iconInvoice from '../../assets/icon-invoice.svg'

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { AuthContext } from '../../contexts/AuthContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'
import { NotificationContext } from '../../contexts/NotificationContext'
import { InvoiceContext } from '../../contexts/InvoiceContext'
import { UserContext } from '../../contexts/UserContext'
import { RoleContext } from '../../contexts/RoleContext'
// import { UserManagerContext } from '../../contexts/UserManagerContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

import { RemoveContractContext } from '../../contexts/RemoveContractContext'

export const ActionButtons_Contract = ({ _id }) => {
	console.log("_id", _id)
	const { deleteContract, findContract, setShowUpdateContractModal } = useContext(
		ContractContext
	)

	const chooseContract = ContractId => {
		findContract(ContractId)
		setShowUpdateContractModal(true)
	}
	const chooseListForm = ContractId => {
		findContract(ContractId)
		setShowAddContractModal(true)
	}


	return (
		<>
			<OverlayTrigger
				overlay={
					<Tooltip>
						Cập nhật thông tin hơp đồng
					</Tooltip>
				}
			>
				<Button className='post-button' onClick={chooseContract.bind(this, _id)}>
					<img src={editIcon} alt='edit' width='24' height='24' />
				</Button>
			</OverlayTrigger>

			<OverlayTrigger
				overlay={
					<Tooltip>
						Nhập dữ liệu hợp đồng
					</Tooltip>
				}
			>
				<Button className='post-button' onClick={chooseListForm.bind(this, _id)}>
					<img src={listIcon} alt='delete' width='24' height='24' />
				</Button>
			</OverlayTrigger>
		</>
	)
}

export const ActionButtons_Update_Delete = ({ _idContract, StatusDB, checkUpdateSoHopDong, nameRoleContract, role, historyContractNew, checkStatusInvoices,}) => {
	const params = useParams();
	// Contexts
	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)
	const { deleteContract, findContract, setShowUpdateContractModal } = useContext(
		ContractContext
	) //goi ActionButtons cho component khác đc kg ???

	//remove all relate to contract
	const {
		delete_RemoveContract,
		setShowToast
	} = useContext(RemoveContractContext)

	const chooseContract = ContractId => {
		findContract(ContractId)
		setShowUpdateContractModal(true)
	}
	function submit () {
		confirmAlert({
		  title: '',
		  message: 'Xoá hợp đồng',
		  buttons: [
			{
			  label: 'Có',
			  onClick: async () => {
			  	// deleteContract(_idContract)
			  	// console.log("ActionButtons_Update_Delete", _idContract)
			  	const historyChangeContract = {
				    "requester": _id,
				    "contract": _idContract,
				    "form": "contract-cost",
				    "action": "delete",
				    "data_change": "delete " + JSON.stringify(_idContract)
				}

				const { successHis, messageHis } = await addHistoryChange(historyChangeContract)
				await delete_RemoveContract(_idContract);
				location.reload();
			  }
			},
			{
			  label: 'Không',
			  onClick: () => closeDialog()
			}
		  ]
		});
	  };
	const closeDialog = () => {
		setShowUpdateContractModal(false)
	}
	function check_role_delete(StatusDB,role,checkStatusInvoices){

		if(checkStatusInvoices != undefined){
			return false;
		}else{
			switch (StatusDB) {
				case "1":
					return true;
				break;
				case "5":
				case "7":
				case "9":
					if(role == 'KTT' || role == 'BGD' || role == 'BTGD'){
						return true;
					}else{
						return false;
					}
				break;
				default:
					return false;
				break;
			}
		}
	}
	console.log(nameRoleContract,role,checkUpdateSoHopDong,checkStatusInvoices,'----------------------')
	return (
		<>
			{
				historyContractNew != ''
				?
				<>
					{
						checkStatusInvoices != undefined
						?
						<></>
						:
						<>
							<OverlayTrigger
								id="tooltip"
								overlay={
									<Tooltip id="tooltip">
										Cập nhật
									</Tooltip>
								}
							>
								<a onClick={chooseContract.bind(this, _idContract)}>
									<img src={iconEdit} alt='edit' width='36' height='28' />
								</a>
							</OverlayTrigger>
						</>
					}	
				</>
				:
				<>
					{
						checkStatusInvoices != undefined
						?
						<></>
						:
						<>
							{
								checkUpdateSoHopDong != undefined && checkUpdateSoHopDong.length > 0
								?
								<></>
								:
								<>
									{
										nameRoleContract == role
										?
										<>
											<OverlayTrigger
												id="tooltip"
												overlay={
													<Tooltip id="tooltip">
														Cập nhật
													</Tooltip>
												}
											>
												<a onClick={chooseContract.bind(this, _idContract)}>
													<img src={iconEdit} alt='edit' width='36' height='28' />
												</a>
											</OverlayTrigger>
										</>
										:
										<></>
									}
								</>
							}
						</>
					}
				</>
			}
			{
				check_role_delete(StatusDB,role,checkStatusInvoices) == true 
				? 
				<>
					<OverlayTrigger
						overlay={
							<Tooltip id="tooltip">
								Xoá hợp đồng
							</Tooltip>
						}
					>
						<a onClick={submit}>
							<img src={iconDelete} alt='edit' width='36' height='28' />
						</a>
					</OverlayTrigger>
				</>
				:
				<></>
			}
				
		</>
	)
}

export const ActionButtons_Update_Status = ({ _data, _id, UserID, role, StatusDB, historyContractNew, checkUpdateSoHopDong}) => { 
	const { updateStatusContract, setShowUpdateStatusModal, setShowUpdateStatusModal1, setShowUpdateStatusModal2, setShowUpdateStatusModal3, addReasonRefuseContract} = useContext(
		HistoryContext
	)
	// const {
	// 	authState: {
	// 		user: { roles, _id }
	// 	}
	// } = useContext(AuthContext)
	// console.log("ActionButtons_Update_Status", { _id, UserID, role, StatusDB })
	const {
		historyChangeState: { historyChange, historyChanges, historyChangesLoading },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	const {
		notificationState: { Notification, Notifications },
		addNotification
	} = useContext(NotificationContext)

	const {
		UserState: { User, Users, UsersLoading },
		getUsers
	} = useContext(UserContext)


	//update 29/03/2024
	const {
		RoleState: { Role, Roles, RolesLoading },
		getRoles,
	} = useContext(RoleContext)

	let nameRoleContract = "";
	if(_data._UserContract.roles[0] != undefined){
		let roleUserContract =  _data._UserContract.roles[0];
		nameRoleContract = FindArrayRoleUserContract(Roles,roleUserContract).name;
	}	
	// console.log(nameRoleContract,'nameRoleContractnameRoleContract')
	function FindArrayRoleUserContract(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}
	
	// const {
	// 	UserManagerState: { UserManager, UserManagers, UserManagersLoading },
	// 	getAllUserManagers
	// } = useContext(UserManagerContext)

	let newArray = Users

	///
	// if (historyChanges.length > 0) {
	// 	let filteredHistoryChanges = historyChanges.filter(x => x.contract == "65e3541e5f7c6c066f289201")
	// 	let requesterList = FindArrayHistoryCapCaoHon(UserID , Users, role)
		
	// 	let resultCapCaoHon = filteredHistoryChanges.filter(item => {
	// 		return requesterList.includes(item.requester); 
	// 	});
	// 	console.debug("historyChanges", historyChanges, '65e3541e5f7c6c066f289201', filteredHistoryChanges, result)
	// }

	//

	// Function to find manager ID by user ID
	function getManagerId(users, userId, role, child) {
		let managerId;
		switch (role) {
			case "AM": //AM tim TPKD
				if (child == "TPKD") {
					const tpkd01 = users.find((u) => u._id === userId);

					const kttInSameCenter = users.filter((u) => {
						return u.role_details.some((r) => r.name === child) && u.center_details.some((c) => c._id === tpkd01.center_details[0]._id);
					});
					if (typeof kttInSameCenter != "undefined") {
						managerId = kttInSameCenter.map(u => u._id)
					} else {
						managerId = ""
					}
					
					// managerId = typeof kttInSameCenter != "undefined" ? kttInSameCenter[0]._id : []
					// console.debug(managerId)
					return managerId
				}
				break;
			case "TPKD": //TPKD tim AM và KTT
				if (child == "AM") {
					const childUsers = [];

					for (let user of users) {
						for (let manager of user.manager_details) {
							if (manager.manager_id.includes(userId)) {
								childUsers.push(manager.user_id[0]);
							}
						}
					}
	
					console.debug("userIds", childUsers)
					managerId = childUsers
					return managerId
				}

				if (child == "KTT") {
					const tpkd01 = users.find((u) => u._id === userId);

					const kttInSameCenter = users.filter((u) => {
						return u.role_details.some((r) => r.name === child) && u.center_details.some((c) => c._id === tpkd01.center_details[0]._id);
					});
					if (typeof kttInSameCenter != "undefined") {
						managerId = kttInSameCenter.map(u => u._id)
					} else {
						managerId = ""
					}
					
					// managerId = typeof kttInSameCenter != "undefined" ? kttInSameCenter[0]._id : []
					console.debug(managerId)
					return managerId
				}
				break;
			case "KTT":
				if (child == "BGD") {
					const tpkd01 = users.find((u) => u._id === userId);

					const kttInSameCenter = users.filter((u) => {
						return u.role_details.some((r) => r.name === child) && u.center_details.some((c) => c._id === tpkd01.center_details[0]._id);
					});
					if (typeof kttInSameCenter != "undefined") {
						managerId = kttInSameCenter.map(u => u._id)
					} else {
						managerId = ""
					}
					
					// managerId = typeof kttInSameCenter != "undefined" ? kttInSameCenter[0]._id : []
					console.debug(managerId)
					return managerId
				}
				break;
			case "BGD":
				if (child == "BTGD") {
					const tpkd01 = users.find((u) => u._id === userId);

					const kttInSameCenter = users.filter((u) => {
						return u.role_details.some((r) => r.name === child);
						// return u.role_details.some((r) => r.name === child) && u.center_details.some((c) => c._id === tpkd01.center_details[0]._id);
					});
					if (typeof kttInSameCenter != "undefined") {
						managerId = kttInSameCenter.map(u => u._id)
					} else {
						managerId = ""
					}
					
					// managerId = typeof kttInSameCenter != "undefined" ? kttInSameCenter[0]._id : []
					console.debug(managerId)
					return managerId
				}
				break;
		}	
		return managerId;
	}

	function findParentRole(role){
		let roleParent = ""
		switch (role) {
			case "AM":
				roleParent = "TPKD"
				break;
			case "TPKD":
				roleParent = "KTT"
				break;
			case "KTT":
				roleParent = "BGD"
				break;
			case "BGD":
				roleParent = "BTGD"
				break;
		}
		return roleParent
	}

	function submitRequest () {
		let arr = {
			"ContractID":_id,
			"UserID":UserID
		}
		let StatusID = check_role_request(role);
		arr.StatusID = StatusID;
		confirmAlert({
		  title: '',
		  message: 'Gửi yêu cầu',
		  buttons: [
			{
			  label: 'Có',
			  onClick: async () => {
			  	updateStatusContract(arr)
			  	const historyContract = {
				    "requester": UserID,
				    "contract": _id,
				    "form": "contract",
				    "action": "submit-request",
				    "data_change": "Submit a request to TPKD " + JSON.stringify(arr)
				}

				const { successHis, messageHis } = await addHistoryChange(historyContract)
				
				let listReceiver = getManagerId(newArray, UserID, role, findParentRole(role))
				console.debug("listReceiver::submitRequest::", listReceiver)
				if (listReceiver.length > 0) {
					listReceiver.forEach( async (element) => {
						var notiSubmitRequest = {
							sender: UserID,
							receiver: element, // gui len cap cao hon
							contract: _id,
							is_read: false,
							message: "gửi HĐ lên",
						};
						console.debug("addNotification", notiSubmitRequest)
						const { successNotiSuccess, messageNotiSuccess } = await addNotification(notiSubmitRequest);
					});
				}else {
					var notiSubmitRequest = {
						sender: UserID,
						receiver: UserID, // gui len cap cao hon
						contract: _id,
						is_read: false,
						message: `lỗi gửi HĐ lên role::${role}`
					};
					console.debug("addNotification", notiSubmitRequest, listReceiver)
					const { successNotiErr, messageNotiErr } = await addNotification(notiSubmitRequest);
				}
			  }
			},
			{
			  label: 'Không',
			  onClick: () => closeDialog()
			}
		  ]
		});
	};

	const closeDialog = () => {
		setShowUpdateStatusModal(false)
	}

	function submitConfirm () {
		let arr = {
			"ContractID":_id,
			"UserID":UserID
		}
		let StatusID = check_role_confirm(role);
		arr.StatusID = StatusID;
		confirmAlert({
		    title: '',
		    message: 'Duyệt',
		    buttons: [
				{
				  label: 'Có',
				  onClick: async () => {
				  	updateStatusContract(arr)
				  	const historyContract = {
					    "requester": UserID,
					    "contract": _id,
					    "form": "contract",
					    "action": "submit-confirm",
					    "data_change": "Submit a confirm " + JSON.stringify(arr)
					}

					const { successHis, messageHis } = await addHistoryChange(historyContract)
					
					//AM 
					if (role == "TPKD") {
						var notiSubmitConfirmAM = {
							sender: UserID,
							receiver: _data._UserContract._id, // tra ve cấp thấp hơn
							contract: _id,
							is_read: false,
							message: "duyệt HĐ",
						};
						const { successNoti, messageNoti } = await addNotification(notiSubmitConfirmAM);
					}
					//AM and TPKD
					if (role == "KTT") {
						// var notiSubmitConfirmAM = {
						// 	sender: UserID,
						// 	receiver:  _data._UserContract._id, //AM 
						// 	contract: _id,
						// 	is_read: false,
						// 	message: "duyệt HĐ",
						// };

						// console.debug("notiSubmitConfirmAM", notiSubmitConfirmAM)
						// const { successNoti, messageNoti } = await addNotification(notiSubmitConfirmAM);

						let listReceiverTPKD = getManagerId(newArray, _data._UserContract._id, "AM", findParentRole("AM"))
						console.debug("listReceiverTPKD", listReceiverTPKD)
						if (listReceiverTPKD.length > 0) {
							listReceiverTPKD.forEach( async (element) => {
								var notiSubmitConfirmTPKD = {
									sender: UserID,
									receiver: element, //TPKD
									contract: _id,
									is_read: false,
									message: "duyệt HĐ",
								};
								console.debug("notiSubmitConfirmTPKD", notiSubmitConfirmTPKD)
								const { successNotiSuccess, messageNotiSuccess } = await addNotification(notiSubmitConfirmTPKD);
							});
						}else {
							var notiSubmitRequest = {
								sender: UserID,
								receiver: _data._UserContract._id, // gui len cap cao hon
								contract: _id,
								is_read: false,
								message: "lỗi TPKD:: Role::KTT"
							};
							console.debug("notiSubmitRequest", notiSubmitRequest)
							const { successNotiErr, messageNotiErr } = await addNotification(notiSubmitRequest);
						}
					}

					//AM and TPKD and KTT
					if (role == "BGD") {
						// var notiSubmitConfirmAM = {
						// 	sender: UserID,
						// 	receiver: _data._UserContract._id, //AM 
						// 	contract: _id,
						// 	is_read: false,
						// 	message: "duyệt HĐ",
						// };

						// console.debug("notiSubmitConfirmAM", notiSubmitConfirmAM)
						// const { successNoti, messageNoti } = await addNotification(notiSubmitConfirmAM);

						// let listReceiverTPKD = getManagerId(newArray, _data._UserContract._id, "AM", findParentRole("AM"))
						// console.debug("listReceiverTPKD", listReceiverTPKD)

						// if (listReceiverTPKD.length > 0) {
						// 	listReceiverTPKD.forEach( async (TPKD) => {
						// 		var notiSubmitConfirmTKPD = {
						// 			sender: UserID,
						// 			receiver: TPKD, //TKPD
						// 			contract: _id,
						// 			is_read: false,
						// 			message: "duyệt HĐ",
						// 		};
						// 		console.debug("notiSubmitConfirmTKPD", notiSubmitConfirmTKPD)
						// 		const { successNotiSuccessTPKD, messageNotiSuccessTPKD } = await addNotification(notiSubmitConfirmTKPD);
						// 	});
						// }else {
						// 	var notiSubmitRequest = {
						// 		sender: UserID,
						// 		receiver: _data._UserContract._id, // gui len cap cao hon
						// 		contract: _id,
						// 		is_read: false,
						// 		message: "lỗi TKPD:: Role::BGD"
						// 	};
							
						// 	const { successNotiErr, messageNotiErr } = await addNotification(notiSubmitRequest);
						// }

						//KTT
						let listReceiverKTT = getManagerId(newArray, _data._UserContract._id, "TPKD", findParentRole("TPKD"))
						console.debug("listReceiverKTT", listReceiverKTT)
						if (listReceiverKTT.length > 0 ) {
							listReceiverKTT.forEach( async (KTT) => {
								var notiSubmitConfirmKTT = {
									sender: UserID,
									receiver: KTT, //KTT
									contract: _id,
									is_read: false,
									message: "duyệt HĐ",
								};
								console.debug("notiSubmitConfirmKTT", notiSubmitConfirmKTT)
								const { successNotiSuccessKTT, messageNotiSuccessKTT } = await addNotification(notiSubmitConfirmKTT);
							})
						} else {
							var notiSubmitRequest = {
								sender: UserID,
								receiver: _data._UserContract._id, // gui len cap cao hon
								contract: _id,
								is_read: false,
								message: "lỗi KTT:: Role::BGD"
							};
							
							const { successNotiErrKTT, messageNotiErrKTT } = await addNotification(notiSubmitRequest);
						}
					}

					//AM and TPKD and KTT and BGD
					if (role == "BTGD") {
						//AM
						// var notiSubmitConfirmAM = {
						// 	sender: UserID,
						// 	receiver:  _data._UserContract._id, //AM 
						// 	contract: _id,
						// 	is_read: false,
						// 	message: "duyệt HĐ",
						// };

						// console.debug("notiSubmitConfirmAM", notiSubmitConfirmAM)
						// const { successNoti, messageNoti } = await addNotification(notiSubmitConfirmAM);
						// //TPKD
						// let listReceiverTKPD = getManagerId(newArray, _data._UserContract._id, "AM", findParentRole("AM"))
						// console.debug("listReceiverTKPD", listReceiverTKPD, _data._UserContract._id)
													
						// if (listReceiverTKPD.length > 0) {
						// 	listReceiverTKPD.forEach( async (TKPD) => {
						// 		var notiSubmitConfirmTKPD = {
						// 			sender: UserID,
						// 			receiver: TKPD, //TKPD
						// 			contract: _id,
						// 			is_read: false,
						// 			message: "duyệt HĐ",
						// 		};
						// 		console.debug("notiSubmitConfirmTKPD", notiSubmitConfirmTKPD)
						// 		const { successNotiSuccessTPKD, messageNotiSuccessTPKD } = await addNotification(notiSubmitConfirmTKPD); 
								
						// 	})
						// }else {
						// 	var notiSubmitRequest = {
						// 		sender: UserID,
						// 		receiver: _data._UserContract._id, // gui len cap cao hon
						// 		contract: _id,
						// 		is_read: false,
						// 		message: "lỗi TPKD:: Role::BTGD"
						// 	};
						// 	console.debug("notiSubmitRequest", notiSubmitRequest)
						// 	const { successNotiErr, messageNotiErr } = await addNotification(notiSubmitRequest);
						// }

						// //KTT
						// let listReceiverKTT = getManagerId(newArray,  _data._UserContract._id, "TPKD", findParentRole("TPKD"))
    					// console.debug("listReceiverKTT", listReceiverKTT)
						// if (listReceiverKTT.length > 0 ) {
						// 	listReceiverKTT.forEach( async (KTT) => {
						// 		var notiSubmitConfirmKTT = {
						// 			sender: UserID,
						// 			receiver: KTT, //KTT
						// 			contract: _id,
						// 			is_read: false,
						// 			message: "duyệt HĐ",
						// 		};
						// 		console.debug("notiSubmitConfirmKTT", notiSubmitConfirmKTT)
						// 		const { successNotiSuccessKTT, messageNotiSuccessKTT } = await addNotification(notiSubmitConfirmKTT);
						// 	})
						// } else {
						// 	var notiSubmitRequest = {
						// 		sender: UserID,
						// 		receiver: _data._UserContract._id, // gui len cap cao hon
						// 		contract: _id,
						// 		is_read: false,
						// 		message: "lỗi KTT:: Role::BTGD"
						// 	};
						// 	console.debug("notiSubmitRequest", notiSubmitRequest)
						// 	const { successNotiErrKTT, messageNotiErrKTT } = await addNotification(notiSubmitRequest);
						// }
						
						//BGD
						let listReceiverBGD = getManagerId(newArray, _data._UserContract._id, "KTT", findParentRole("KTT"))
						console.debug("listReceiverBGD", listReceiverBGD)
		
						if (listReceiverBGD.length > 0) {
							listReceiverBGD.forEach( async (BGD) => {
								var notiSubmitConfirmBGD = {
									sender: UserID,
									receiver: BGD, //BGD
									contract: _id,
									is_read: false,
									message: "duyệt HĐ",
								};
								console.debug("notiSubmitConfirmBGD", notiSubmitConfirmBGD)
								const { successNotiSuccessBGD, smessageNotiSuccessBGD } = await addNotification(notiSubmitConfirmBGD);
							})
						} else {
							var notiSubmitRequest = {
								sender: UserID,
								receiver: _data._UserContract._id, // gui len cap cao hon
								contract: _id,
								is_read: false,
								message: "lỗi BGD:: Role::BTGD"
							};
							console.debug("notiSubmitRequest", notiSubmitRequest)
							const { successNotiErrBGD, messageNotiErrBGD } = await addNotification(notiSubmitRequest);
						}
					}
				  }
				},
				{
				  label: 'Không',
				  onClick: () => closeDialog1()
				}
		    ]
		});
	};

	const closeDialog1 = () => {
		setShowUpdateStatusModal1(false)
	}

	function submitRefuse () {
		let arr = {
			"ContractID":_id,
			"UserID":UserID
		}
		let StatusID = check_role_refuse(role);
		arr.StatusID = StatusID;
		confirmAlert({
		    title: '',
		    message: 'Từ chối',
		    buttons: [
				{
				  label: 'Có',
				  onClick: async () => {
				  	updateStatusContract(arr)
				  }
				},
				{
				  label: 'Không',
				  onClick: () => closeDialog2()
				}
		    ]
		});
	  };

	const closeDialog2 = () => {
		setShowUpdateStatusModal2(false)
	}

	function submitRemove () {
		let arr = {
			"ContractID":_id,
			"UserID":UserID
		}
		let StatusID = 10;
		arr.StatusID = StatusID;
		confirmAlert({
		    title: '',
		    message: 'Hủy HĐ',
		    buttons: [
				{
				  	label: 'Có',
				  	onClick: async () => {
				  		updateStatusContract(arr);
				  		const historyContract = {
						    "requester": UserID,
						    "contract": _id,
						    "form": "contract",
						    "action": "submit-remove",
						    "data_change": "Submit a remove " + JSON.stringify(arr)
						}

						const { successHis, messageHis } = await addHistoryChange(historyContract)
						
						//AM 
						if (role == "TPKD") {
							var notiSubmitConfirmAM = {
								sender: UserID,
								receiver: _data._UserContract._id, // tra ve cấp thấp hơn
								contract: _id,
								is_read: false,
								message: "hủy HĐ"
							};
							console.debug("notiSubmitConfirmAM", notiSubmitConfirmAM)
							const { successNoti, messageNoti } = await addNotification(notiSubmitConfirmAM);
						}
						//AM and TPKD
						if (role == "KTT") {
							var notiSubmitConfirmAM = {
								sender: UserID,
								receiver:  _data._UserContract._id, //AM 
								contract: _id,
								is_read: false,
								message: "hủy HĐ"
							};

							console.debug("notiSubmitConfirmAM", notiSubmitConfirmAM)
							const { successNoti, messageNoti } = await addNotification(notiSubmitConfirmAM);

							let listReceiver = getManagerId(newArray, _data._UserContract._id, "AM", findParentRole("AM"))
							console.debug("TKPD listReceiver", listReceiver)
							if (listReceiver.length > 0) {
								listReceiver.forEach( async (element) => {
									var notiSubmitConfirmTKPD = {
										sender: UserID,
										receiver: element, //TKPD
										contract: _id,
										is_read: false,
										message: "hủy HĐ"
									};
									console.debug("notiSubmitConfirmTKPD", notiSubmitConfirmTKPD)
									const { successNotiSuccess, messageNotiSuccess } = await addNotification(notiSubmitConfirmTKPD);
								});
							}else {
								var notiSubmitRequest = {
									sender: UserID,
									receiver: _data._UserContract._id, // gui len cap cao hon
									contract: _id,
									is_read: false,
									message: "lỗi TPKD:: Role::KTT"
								};
								
								const { successNotiErr, messageNotiErr } = await addNotification(notiSubmitRequest);
							}
						}

						//AM and TPKD and KTT
						if (role == "BGD") {
							var notiSubmitConfirmAM = {
								sender: UserID,
								receiver:  _data._UserContract._id, //AM 
								contract: _id,
								is_read: false,
								message: "hủy HĐ"
							};

							console.debug("notiSubmitConfirmAM", notiSubmitConfirmAM)
							const { successNoti, messageNoti } = await addNotification(notiSubmitConfirmAM);

							let listReceiver = getManagerId(newArray, _data._UserContract._id, "AM", findParentRole("AM"))
							
							console.debug("TKPD listReceiver", listReceiver)
							if (listReceiver.length > 0) {
								listReceiver.forEach( async (element) => {
									var notiSubmitConfirmTKPD = {
										sender: UserID,
										receiver: element, //TKPD
										contract: _id,
										is_read: false,
										message: "hủy HĐ"
									};
									console.debug("notiSubmitConfirmTKPD", notiSubmitConfirmTKPD)
									const { successNotiSuccessTPKD, messageNotiSuccessTPKD } = await addNotification(notiSubmitConfirmTKPD);

									let listReceiverKTT = getManagerId(newArray, element, "TPKD", findParentRole("TPKD"))
									console.debug("listReceiverKTT", listReceiverKTT)
									if (listReceiverKTT.length > 0 ) {
										listReceiverKTT.forEach( async (element1) => {
											var notiSubmitConfirmKTT = {
												sender: UserID,
												receiver: element1, //KTT
												contract: _id,
												is_read: false,
												message: "hủy HĐ"
											};
											console.debug("notiSubmitConfirmKTT", notiSubmitConfirmKTT)
											const { successNotiSuccessKTT, messageNotiSuccessKTT } = await addNotification(notiSubmitConfirmKTT);
										})
									} else {
										var notiSubmitRequest = {
											sender: UserID,
											receiver: _data._UserContract._id, // gui len cap cao hon
											contract: _id,
											is_read: false,
											message: "lỗi KTT:: Role::BGD"
										};
										
										const { successNotiErrKTT, messageNotiErrKTT } = await addNotification(notiSubmitRequest);
									}
								});
							}else {
								var notiSubmitRequest = {
									sender: UserID,
									receiver: _data._UserContract._id, // gui len cap cao hon
									contract: _id,
									is_read: false,
									message: "lỗi TKPD:: Role::BGD"
								};
								
								const { successNotiErr, messageNotiErr } = await addNotification(notiSubmitRequest);
							}
						}

						//AM and TPKD and KTT and BGD
						if (role == "BTGD") {
							//AM
							var notiSubmitConfirmAM = {
								sender: UserID,
								receiver:  _data._UserContract._id, //AM 
								contract: _id,
								is_read: false,
								message: "hủy HĐ"
							};

							console.debug("notiSubmitConfirmAM", notiSubmitConfirmAM)
							const { successNoti, messageNoti } = await addNotification(notiSubmitConfirmAM);
							//TPKD
							let listReceiverTKPD = getManagerId(newArray, _data._UserContract._id, "AM", findParentRole("AM"))
							console.debug("listReceiverTKPD", listReceiverTKPD, _data._UserContract._id)
														
							if (listReceiverTKPD.length > 0) {
								listReceiverTKPD.forEach( async (TKPD) => {
									var notiSubmitConfirmTKPD = {
										sender: UserID,
										receiver: TKPD, //TKPD
										contract: _id,
										is_read: false,
										message: "hủy HĐ"
									};
									console.debug("notiSubmitConfirmTKPD", notiSubmitConfirmTKPD)
									const { successNotiSuccessTPKD, messageNotiSuccessTPKD } = await addNotification(notiSubmitConfirmTKPD); 
									
								})
							}else {
								var notiSubmitRequest = {
									sender: UserID,
									receiver: _data._UserContract._id, // gui len cap cao hon
									contract: _id,
									is_read: false,
									message: "lỗi TPKD:: Role::BTGD"
								};
								console.debug("notiSubmitRequest", notiSubmitRequest)
								const { successNotiErr, messageNotiErr } = await addNotification(notiSubmitRequest);
							}

							//KTT
							let listReceiverKTT = getManagerId(newArray,  _data._UserContract._id, "TPKD", findParentRole("TPKD"))
        					console.debug("listReceiverKTT", listReceiverKTT)
							if (listReceiverKTT.length > 0 ) {
								listReceiverKTT.forEach( async (KTT) => {
									var notiSubmitConfirmKTT = {
										sender: UserID,
										receiver: KTT, //KTT
										contract: _id,
										is_read: false,
										message: "hủy HĐ"
									};
									console.debug("notiSubmitConfirmKTT", notiSubmitConfirmKTT)
									const { successNotiSuccessKTT, messageNotiSuccessKTT } = await addNotification(notiSubmitConfirmKTT);
								})
							} else {
								var notiSubmitRequest = {
									sender: UserID,
									receiver: _data._UserContract._id, // gui len cap cao hon
									contract: _id,
									is_read: false,
									message: "lỗi KTT:: Role::BTGD"
								};
								console.debug("notiSubmitRequest", notiSubmitRequest)
								const { successNotiErrKTT, messageNotiErrKTT } = await addNotification(notiSubmitRequest);
							}
							
							//BGD
							let listReceiverBGD = getManagerId(newArray, _data._UserContract._id, "KTT", findParentRole("KTT"))
							console.debug("listReceiverBGD", listReceiverBGD)
			
							if (listReceiverBGD.length > 0) {
								listReceiverBGD.forEach( async (BGD) => {
									var notiSubmitConfirmBGD = {
										sender: UserID,
										receiver: BGD, //BGD
										contract: _id,
										is_read: false,
										message: "hủy HĐ"
									};
									console.debug("notiSubmitConfirmBGD", notiSubmitConfirmBGD)
									const { successNotiSuccessBGD, smessageNotiSuccessBGD } = await addNotification(notiSubmitConfirmBGD);
								})
							} else {
								var notiSubmitRequest = {
									sender: UserID,
									receiver: _data._UserContract._id, // gui len cap cao hon
									contract: _id,
									is_read: false,
									message: "lỗi BGD:: Role::BTGD"
								};
								console.debug("notiSubmitRequest", notiSubmitRequest)
								const { successNotiErrBGD, messageNotiErrBGD } = await addNotification(notiSubmitRequest);
							}
						}
				  	}
				},
				{
				  label: 'Không',
				  onClick: () => closeDialog3()
				}
		    ]
		});
	};

	const closeDialog3 = () => {
		setShowUpdateStatusModal3(false)
	}

	function check_role_request(role){
		switch(role){
			case 'AM':
				return 2;
				break;
			case 'TPKD':
				return 4;
				break;
			case 'KTT':
				return 6;
				break;
			case 'BGD':
				return 8;
				break;
			case 'BTGD':
				return '';
				break;
		}
	}

	function check_role_confirm(role){
		switch(role){
			case 'AM':
				return '';
				break;
			case 'TPKD':
				return 3;
				break;
			case 'KTT':
				return 5;
				break;
			case 'BGD':
				return 7;
				break;
			case 'BTGD':
				return 9;
				break;
		}
	}

	function check_role_refuse(role){
		switch(role){
			case 'AM':
				return '';
				break;
			case 'TPKD':
				return -1;
				break;
			case 'KTT':
				return -2;
				break;
			case 'BGD':
				return -4;
				break;
			case 'BTGD':
				return -6;
				break;
		}
	}

	//submit refuse
	const [showModalRefuse, setShowModalRefuse] = useState(false);
	const [showModalRemove, setShowModalRemove] = useState(false);

	const Render_Button_View_Contract = (_data) =>{
		_data = _data._data;
		return (
			<>
				{/*<a href={`/summary/${_data._id}`} style={{marginTop:"10px"}}>
					<Button >
						Xem PTHĐ
					</Button>
				</a>*/}
				<OverlayTrigger
					overlay={
						<Tooltip id="tooltip">
							Xem PTHĐ
						</Tooltip>
					}
				>
					<a href={`/summary/${_data._id}`}>
						<img src={iconViewContract}  width="36" height="28"></img>
					</a>
				</OverlayTrigger>
			</>
		)
	}

	const Render_Modal_Refuse = () => {

		const submitRefuseNew = () => {
		    setShowModalRefuse(true);
		};

		const closeModalRefuse = () => {
		    setShowModalRefuse(false);
		};

		const [newReasonRefuse, setNewReasonRefuse] = useState({
			Reason: ''
		})

		const { 
			Reason
		} = newReasonRefuse
		newReasonRefuse['ContractID'] = _id;
		newReasonRefuse['UserID'] = UserID;
		newReasonRefuse['StatusID'] = check_role_refuse(role);

		const onChangeReasonRefuseContractForm = event =>
			setNewReasonRefuse({ ...newReasonRefuse, [event.target.name]: event.target.value })
		
		const onSubmitRefuseContract = async event => {
			event.preventDefault()
			const { success, message } = await addReasonRefuseContract(newReasonRefuse) 

			const historyContract = {
			    "requester": UserID,
			    "contract": _id,
			    "form": "contract",
			    "action": "submit-refuse",
			    "data_change": "Submit a refuse " + JSON.stringify(newReasonRefuse)
			}

			const { successHis, messageHis } = await addHistoryChange(historyContract)
			
			//AM 
			if (role == "TPKD") {
				var notiSubmitConfirmAM = {
					sender: UserID,
					receiver: _data._UserContract._id, // tra ve cấp thấp hơn
					contract: _id,
					is_read: false,
					message: "từ chối duyệt HĐ. Lý do: " + newReasonRefuse['Reason']
				};
				console.debug("notiSubmitConfirmAM", notiSubmitConfirmAM)
				const { successNoti, messageNoti } = await addNotification(notiSubmitConfirmAM);
			}
			
			//AM and TPKD
			if (role == "KTT") {
				//AM
				// var notiSubmitConfirmAM = {
				// 	sender: UserID,
				// 	receiver:  _data._UserContract._id, //AM 
				// 	contract: _id,
				// 	is_read: false,
				// 	message: "từ chối duyệt HĐ. Lý do: " + newReasonRefuse['Reason']
				// };

				// console.debug("notiSubmitConfirmAM", notiSubmitConfirmAM)
				// const { successNoti, messageNoti } = await addNotification(notiSubmitConfirmAM);
				//TKPD
				let listReceiver = getManagerId(newArray, _data._UserContract._id, "AM", findParentRole("AM"))
				console.debug("TKPD listReceiver", listReceiver)
				if (listReceiver.length > 0) {
					listReceiver.forEach( async (element) => {
						var notiSubmitConfirmTKPD = {
							sender: UserID,
							receiver: element, //TKPD
							contract: _id,
							is_read: false,
							message: "từ chối duyệt HĐ. Lý do: " + newReasonRefuse['Reason']
						};
						console.debug("notiSubmitConfirmTKPD", notiSubmitConfirmTKPD)
						const { successNotiSuccess, messageNotiSuccess } = await addNotification(notiSubmitConfirmTKPD);
					});
				}else {
					var notiSubmitRequest = {
						sender: UserID,
						receiver: _data._UserContract._id, // gui len cap cao hon
						contract: _id,
						is_read: false,
						message: "lỗi TPKD:: Role::KTT"
					};
					
					const { successNotiErr, messageNotiErr } = await addNotification(notiSubmitRequest);
				}
			}

			//AM and TPKD and KTT
			if (role == "BGD") {
				//AM
				// var notiSubmitConfirmAM = {
				// 	sender: UserID,
				// 	receiver:  _data._UserContract._id, //AM 
				// 	contract: _id,
				// 	is_read: false,
				// 	message: "từ chối duyệt HĐ. Lý do: " + newReasonRefuse['Reason']
				// };

				// console.debug("notiSubmitConfirmAM", notiSubmitConfirmAM)
				// const { successNoti, messageNoti } = await addNotification(notiSubmitConfirmAM);

				//TPKD
				// let listReceiverTPKD = getManagerId(newArray, _data._UserContract._id, "AM", findParentRole("AM"))
				// console.debug("listReceiverTPKD", listReceiverTPKD)

				// if (listReceiverTPKD.length > 0) {
				// 	listReceiverTPKD.forEach( async (TPKD) => {
				// 		var notiSubmitConfirmTKPD = {
				// 			sender: UserID,
				// 			receiver: TPKD, //TKPD
				// 			contract: _id,
				// 			is_read: false,
				// 			message: "từ chối duyệt HĐ. Lý do: " + newReasonRefuse['Reason']
				// 		};
				// 		console.debug("notiSubmitConfirmTKPD", notiSubmitConfirmTKPD)
				// 		const { successNotiSuccessTPKD, messageNotiSuccessTPKD } = await addNotification(notiSubmitConfirmTKPD);
				// 	});
				// }else {
				// 	var notiSubmitRequest = {
				// 		sender: UserID,
				// 		receiver: _data._UserContract._id, // gui len cap cao hon
				// 		contract: _id,
				// 		is_read: false,
				// 		message: "lỗi TKPD:: Role::BGD"
				// 	};
				// 	console.debug("notiSubmitRequest", notiSubmitRequest)
				// 	const { successNotiErr, messageNotiErr } = await addNotification(notiSubmitRequest);
				// }

				//KTT
				let listReceiverKTT = getManagerId(newArray, _data._UserContract._id, "TPKD", findParentRole("TPKD"))
				console.debug("listReceiverKTT", listReceiverKTT)

				if (listReceiverKTT.length > 0 ) {
					listReceiverKTT.forEach( async (KTT) => {
						var notiSubmitConfirmKTT = {
							sender: UserID,
							receiver: KTT, //KTT
							contract: _id,
							is_read: false,
							message: "từ chối duyệt HĐ. Lý do: " + newReasonRefuse['Reason']
						};
						console.debug("notiSubmitConfirmKTT", notiSubmitConfirmKTT)
						const { successNotiSuccessKTT, messageNotiSuccessKTT } = await addNotification(notiSubmitConfirmKTT);
					})
				} else {
					var notiSubmitRequest = {
						sender: UserID,
						receiver: _data._UserContract._id, // gui len cap cao hon
						contract: _id,
						is_read: false,
						message: "lỗi KTT:: Role::BGD"
					};
					console.debug("notiSubmitRequest", notiSubmitRequest)
					const { successNotiErrKTT, messageNotiErrKTT } = await addNotification(notiSubmitRequest);
				}
			}

			//AM and TPKD and KTT and BGD
			if (role == "BTGD") {
				//AM
				// var notiSubmitConfirmAM = {
				// 	sender: UserID,
				// 	receiver:  _data._UserContract._id, //AM 
				// 	contract: _id,
				// 	is_read: false,
				// 	message: "từ chối duyệt HĐ. Lý do: " + newReasonRefuse['Reason']
				// };

				// console.debug("notiSubmitConfirmAM", notiSubmitConfirmAM)
				// const { successNoti, messageNoti } = await addNotification(notiSubmitConfirmAM);
				
				//TPKD
				// let listReceiverTKPD = getManagerId(newArray, _data._UserContract._id, "AM", findParentRole("AM"))
				// console.debug("listReceiverTKPD", listReceiverTKPD, _data._UserContract._id)
											
				// if (listReceiverTKPD.length > 0) {
				// 	listReceiverTKPD.forEach( async (TKPD) => {
				// 		var notiSubmitConfirmTKPD = {
				// 			sender: UserID,
				// 			receiver: TKPD, //TKPD
				// 			contract: _id,
				// 			is_read: false,
				// 			message: "từ chối duyệt HĐ. Lý do: " + newReasonRefuse['Reason']
				// 		};
				// 		console.debug("notiSubmitConfirmTKPD", notiSubmitConfirmTKPD)
				// 		const { successNotiSuccessTPKD, messageNotiSuccessTPKD } = await addNotification(notiSubmitConfirmTKPD); 
						
				// 	})
				// }else {
				// 	var notiSubmitRequest = {
				// 		sender: UserID,
				// 		receiver: _data._UserContract._id, // gui len cap cao hon
				// 		contract: _id,
				// 		is_read: false,
				// 		message: "lỗi TPKD:: Role::BTGD"
				// 	};
				// 	console.debug("notiSubmitRequest", notiSubmitRequest)
				// 	const { successNotiErr, messageNotiErr } = await addNotification(notiSubmitRequest);
				// }

				//KTT
				// let listReceiverKTT = getManagerId(newArray,  _data._UserContract._id, "TPKD", findParentRole("TPKD"))
				// console.debug("listReceiverKTT", listReceiverKTT)
				// if (listReceiverKTT.length > 0 ) {
				// 	listReceiverKTT.forEach( async (KTT) => {
				// 		var notiSubmitConfirmKTT = {
				// 			sender: UserID,
				// 			receiver: KTT, //KTT
				// 			contract: _id,
				// 			is_read: false,
				// 			message: "từ chối duyệt HĐ. Lý do: " + newReasonRefuse['Reason']
				// 		};
				// 		console.debug("notiSubmitConfirmKTT", notiSubmitConfirmKTT)
				// 		const { successNotiSuccessKTT, messageNotiSuccessKTT } = await addNotification(notiSubmitConfirmKTT);
				// 	})
				// } else {
				// 	var notiSubmitRequest = {
				// 		sender: UserID,
				// 		receiver: _data._UserContract._id, // gui len cap cao hon
				// 		contract: _id,
				// 		is_read: false,
				// 		message: "lỗi KTT:: Role::BTGD"
				// 	};
				// 	console.debug("notiSubmitRequest", notiSubmitRequest)
				// 	const { successNotiErrKTT, messageNotiErrKTT } = await addNotification(notiSubmitRequest);
				// }
				
				//BGD
				let listReceiverBGD = getManagerId(newArray, _data._UserContract._id, "KTT", findParentRole("KTT"))
				console.debug("listReceiverBGD", listReceiverBGD)

				if (listReceiverBGD.length > 0) {
					listReceiverBGD.forEach( async (BGD) => {
						var notiSubmitConfirmBGD = {
							sender: UserID,
							receiver: BGD, //BGD
							contract: _id,
							is_read: false,
							message: "từ chối duyệt HĐ. Lý do: " + newReasonRefuse['Reason']
						};
						console.debug("notiSubmitConfirmBGD", notiSubmitConfirmBGD)
						const { successNotiSuccessBGD, smessageNotiSuccessBGD } = await addNotification(notiSubmitConfirmBGD);
					})
				} else {
					var notiSubmitRequest = {
						sender: UserID,
						receiver: _data._UserContract._id, // gui len cap cao hon
						contract: _id,
						is_read: false,
						message: "lỗi BGD:: Role::BTGD"
					};
					console.debug("notiSubmitRequest", notiSubmitRequest)
					const { successNotiErrBGD, messageNotiErrBGD } = await addNotification(notiSubmitRequest);
				}
			}
			
			// resetAddContractData()
			// setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
		}

		return (
			<Modal show={showModalRefuse} onHide={closeModalRefuse}>
				<Modal.Header closeButton>
					<Modal.Title>Từ chối hợp đồng</Modal.Title>
				</Modal.Header>
				<Form onSubmit={onSubmitRefuseContract}>
					<Modal.Body>
						<Form.Group>
							<Form.Text id='Center-help' muted as="h6">
							 	Lý do
							</Form.Text>
							<Form.Control
								type='text'
								placeholder=''
								name='Reason'
								required
								aria-describedby='Center-help'
								value={Reason}
								onChange={onChangeReasonRefuseContractForm}
							/>						
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary' onClick={closeModalRefuse}>
							Hủy
						</Button>
						<Button variant='info' type='submit'>
							Đồng ý
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		);
	}

	const Render_Modal_Remove = () => {
		// console.debug(historyChangesLoading)
		// useEffect(() => getHistoryChanges(), [_id])

		function FindArrayUser(array_history, UserID , array_user, roleName) {
			let returnObject = array_user.find(x => x._id == UserID);
			let filteredArray = [];
			switch(roleName){
				case 'AM':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
						  	}
						});
						let manage_UserId = FindArrayUserManagers(UserManagers,UserID);
						if(manage_UserId.length > 0){
							let manager_id_new = manage_UserId[0]['manager_id'];
							let managerObject = array_user.find(x => x._id == manager_id_new);
							if(managerObject != undefined){
								filteredArray.push(managerObject);
							}
						}
					}
					break;
				case 'TPKD':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
						  	}
						});
					}
					// console.log('2')
					break;
				case 'KTT':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD' && item.role_details[0].name != 'KTT';
						  	}
						});
					}
					break;
				case 'BGD':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name == 'TPKD' && item.role_details[0].name == 'KTT' && item.role_details[0].name == 'BTGD';
						  	}
						});
					}
					// console.log('3')
					break;
				case 'BTGD':
				case 'Admin':
					break;
			}
			// console.debug("filteredArray", filteredArray)
			if(filteredArray.length > 0){
				let array_historyChange = null;
				if(historyChanges != undefined){
					if(historyChanges.length > 0){
						array_historyChange = FindArrayHistoryChange(historyChanges,filteredArray);
						return array_historyChange;
					}
				}
			}else{
				return null;
			}
		}

		function FindArrayUserCapCaoHon(UserID , array_user, roleName) {
			let returnObject = array_user.find(x => x._id == UserID);
			let rs = []
			let filteredArray = [];

			switch(roleName){
				case 'AM':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
						  	}
						});
						let manage_UserId = FindArrayUserManagers(UserManagers,UserID);
						if(manage_UserId.length > 0){
							let manager_id_new = manage_UserId[0]['manager_id'];
							let managerObject = array_user.find(x => x._id == manager_id_new);
							if(managerObject != undefined){
								filteredArray.push(managerObject);
							}
						}
					}
					break;
				case 'TPKD':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
						  	}else{
						  		return item.role_details[0].name == 'BTGD'
						  	}
						});
					}
					// console.log('2')
					break;
				case 'KTT':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD' && item.role_details[0].name != 'KTT';
						  	} else {
						  		return item.role_details[0].name == 'BTGD'
						  	}
						});
					}
					break;
				case 'BGD':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
						  	return item.role_details[0].name == 'BTGD';
						});
					}
					// console.log('3')
					break;
				case 'BTGD':
				case 'Admin':
					filteredArray = array_user
					break;
			}

			return filteredArray
		}

		function FindArrayHistoryChange(array, array_filter) {
			let array_id = [];
			for (let i = 0; i < array_filter.length; i++) {
				let value = array_filter[i];
				array_id.push(value._id);
			}
			let result = array.filter(function(element) {
				if(element != undefined){
			  		return array_id.includes(element.requester);
			  	}
			});
			return result;
		}

		//get cha chinh sua hd
		function FindArrayHistoryCapCaoHon(UserID , array_user, roleName) {
			let returnObject = array_user.find(x => x._id == UserID);
			let rs = []
			let filteredArray = [];

			switch(roleName){
				case 'AM':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';
						  	}
						});
						let manage_UserId = FindArrayUserManagers(UserManagers,UserID);
						if(manage_UserId.length > 0){
							let manager_id_new = manage_UserId[0]['manager_id'];
							let managerObject = array_user.find(x => x._id == manager_id_new);
							if(managerObject != undefined){
								filteredArray.push(managerObject);
							}
						}
					}
					break;
				case 'TPKD':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM'; //&& item.role_details[0].name != 'TPKD';
						  	}else{
						  		return item.role_details[0].name == 'BTGD'
						  	}
						});
					}
					// console.log('2')
					break;
				case 'KTT':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
							if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM';/// && item.role_details[0].name != 'TPKD';// && item.role_details[0].name != 'KTT';
						  	} else {
						  		return item.role_details[0].name == 'BTGD'
						  	}
						});
					}
					break;
				case 'BGD':
					if(returnObject != undefined){
						filteredArray = array_user.filter(function(item) {
						  	if(item.centers != undefined){
						  		return item.centers.includes(returnObject.centers[0]) && item.role_details[0].name != 'AM' && item.role_details[0].name != 'TPKD';// && item.role_details[0].name != 'KTT';
						  	} else {
						  		return item.role_details[0].name == 'BTGD'
						  	}
						});
					}
					// console.log('3')
					break;
				case 'BTGD':
				case 'Admin':
					filteredArray = array_user
					break;
			}

			if (filteredArray.length > 0) {
				return filteredArray.map(item => item._id);
			}

			return filteredArray
		}

		function FindArrayUserManagers(array, ID) {
			let returnObject = "";
			if (ID != undefined) returnObject = array.filter(x => x.user_id[0] === ID);
			if(returnObject != undefined){
				return returnObject;
			}else{
				return '';
			}
		}

		const submitRemoveNew = () => {
		    setShowModalRemove(true);
		};

		const closeModalRemove = () => {
		    setShowModalRemove(false);
		};

		const [newReasonRemove, setNewReasonRemove] = useState({
			Reason: ''
		})

		const { 
			Reason
		} = newReasonRemove
		newReasonRemove['ContractID'] = _id;
		newReasonRemove['UserID'] = UserID;
		newReasonRemove['StatusID'] = 10;

		const onChangeReasonRemoveContractForm = event =>
		setNewReasonRemove({ ...newReasonRemove, [event.target.name]: event.target.value })

		const onSubmitRemoveContract = async event => {
			event.preventDefault()
			const { success, message } = await addReasonRefuseContract(newReasonRemove)
			const historyChangeContract = {
			    "requester": UserID,
			    "contract": _id,
			    "form": "contract",
			    "action": "remove",
			    "data_change": "remove " + JSON.stringify(_id)
			}
			const { successHis, messageHis } = await addHistoryChange(historyChangeContract)
			
			//AM 
			if (role == "TPKD") {
				var notiSubmitConfirmAM = {
					sender: UserID,
					receiver: _data._UserContract._id, // thong báo xuống cấp thấp hơn
					contract: _id,
					is_read: false,
					message: "hủy HĐ. Lý do: " + newReasonRemove['Reason']
				};
				console.debug("notiSubmitConfirmAM", notiSubmitConfirmAM)
				const { successNoti, messageNoti } = await addNotification(notiSubmitConfirmAM);

				//thong bao cho cap cao hon
				let listReceiverCaoHon = FindArrayUserCapCaoHon(UserID, Users, role)
				if (listReceiverCaoHon.length > 0) {
					listReceiverCaoHon.forEach( async (element) => {
						var notiSubmitConfirmCapCao = {
							sender: UserID,
							receiver: element._id, // gui len cap cao hon
							contract: _id,
							is_read: false,
							message: "hủy HĐ. Lý do: " + newReasonRemove['Reason']
						};
						console.debug("notiSubmitConfirmCapCao", notiSubmitConfirmCapCao)
						const { successNotiSuccess, messageNotiSuccess } = await addNotification(notiSubmitConfirmCapCao);
					});
				}else {
					var notiSubmitRequest = {
						sender: UserID,
						receiver: _data._UserContract._id, // gui len cap cao hon
						contract: _id,
						is_read: false,
						message: "lỗi hủy TPKD:: Role::KTT-BGD-BTGD"
					};
					console.debug("notiSubmitConfirmCapCao lỗi hủy TPKD", notiSubmitRequest)
					const { successNotiErr, messageNotiErr } = await addNotification(notiSubmitRequest);
				}
			}

			//AM and TPKD
			if (role == "KTT") {
				let filteredHistoryChanges = historyChanges.filter(x => x.contract == _id && x.form != 'contract')
				let requesterList = FindArrayHistoryCapCaoHon(UserID , Users, role)
		
				let filteredHistoryChangesCapCaoHon = filteredHistoryChanges.filter(item => {
					console.debug(item)
					return requesterList.includes(item.requester); 
				});

				// console.debug("historyChanges",UserID , Users, role, filteredHistoryChanges, requesterList)
				console.debug("filteredHistoryChanges", role, filteredHistoryChanges, filteredHistoryChangesCapCaoHon, requesterList)
				// ///
				if (filteredHistoryChangesCapCaoHon.length > 0 ) {
				} else {
					var notiSubmitConfirmAM = {
						sender: UserID,
						receiver:  _data._UserContract._id, //AM 
						contract: _id,
						is_read: false,
						message: "hủy HĐ. Lý do: " + newReasonRemove['Reason']
					};

					console.debug("notiSubmitConfirmAM", notiSubmitConfirmAM)
					const { successNoti, messageNoti } = await addNotification(notiSubmitConfirmAM);
				}

				let listReceiver = getManagerId(newArray, _data._UserContract._id, "AM", findParentRole("AM"))
				console.debug("TKPD listReceiver", listReceiver)
				if (listReceiver.length > 0) {
					listReceiver.forEach( async (element) => {
						var notiSubmitConfirmTKPD = {
							sender: UserID,
							receiver: element, //TKPD
							contract: _id,
							is_read: false,
							message: "hủy HĐ. Lý do: " + newReasonRemove['Reason']
						};
						console.debug("notiSubmitConfirmTKPD", notiSubmitConfirmTKPD)
						const { successNotiSuccess, messageNotiSuccess } = await addNotification(notiSubmitConfirmTKPD);
					});
				}else {
					var notiSubmitRequest = {
						sender: UserID,
						receiver: _data._UserContract._id, // gui len cap cao hon
						contract: _id,
						is_read: false,
						message: "lỗi hủy TPKD:: Role::KTT"
					};
					const { successNotiErr, messageNotiErr } = await addNotification(notiSubmitRequest);
				}

				//thong bao cho cap cao hon
				let listReceiverCaoHon = FindArrayUserCapCaoHon(UserID, Users, role)
				console.debug("listReceiverCaoHon", listReceiverCaoHon)
				if (listReceiverCaoHon.length > 0) {
					listReceiverCaoHon.forEach( async (element) => {
						var notiSubmitConfirmCapCao = {
							sender: UserID,
							receiver: element._id, // gui len cap cao hon
							contract: _id,
							is_read: false,
							message: "hủy HĐ. Lý do: " + newReasonRemove['Reason']
						};
						console.debug("notiSubmitConfirmCapCao", notiSubmitConfirmCapCao)
						const { successNotiSuccessCaoHon, messageNotiSuccessCaoHon } = await addNotification(notiSubmitConfirmCapCao);
					});
				}else {
					var notiSubmitRequest = {
						sender: UserID,
						receiver: _data._UserContract._id, // gui len cap cao hon
						contract: _id,
						is_read: false,
						message: "lỗi hủy TPKD:: Role::KTT-BGD-BTGD"
					};
					console.debug("notiSubmitConfirmCapCao lỗi hủy TPKD", notiSubmitRequest)
					const { successNotiErrCaoHon, messageNotiErrCaoHon } = await addNotification(notiSubmitRequest);
				}
			}

			//AM and TPKD and KTT
			if (role == "BGD") {
				let filteredHistoryChanges = historyChanges.filter(x => x.contract == _id && x.form != 'contract'); //(x.action == "create" || x.action == "update" || x.action == "remove")
				let requesterList = FindArrayHistoryCapCaoHon(UserID , Users, role)
		
				let filteredHistoryChangesCapCaoHon = filteredHistoryChanges.filter(item => {
					return requesterList.includes(item.requester); 
				});

				historyChanges.map(x=> {
					if (x.contract == "65e6a2675f7c6c066f28f60a") console.debug(111, x)
				})

				console.debug("filteredHistoryChanges", role, filteredHistoryChanges, filteredHistoryChangesCapCaoHon, requesterList)

				if (filteredHistoryChangesCapCaoHon.length > 0 ) {
				} else {
					var notiSubmitConfirmAM = {
						sender: UserID,
						receiver:  _data._UserContract._id, //AM 
						contract: _id,
						is_read: false,
						message: "hủy HĐ. Lý do: " + newReasonRemove['Reason']
					};

					console.debug("notiSubmitConfirmAM", notiSubmitConfirmAM)
					const { successNoti, messageNoti } = await addNotification(notiSubmitConfirmAM);

					let listReceiverTPKD = getManagerId(newArray, _data._UserContract._id, "AM", findParentRole("AM"))
					console.debug("listReceiverTPKD", listReceiverTPKD)

					if (listReceiverTPKD.length > 0) {
						listReceiverTPKD.forEach( async (TPKD) => {
							var notiSubmitConfirmTKPD = {
								sender: UserID,
								receiver: TPKD, //TKPD
								contract: _id,
								is_read: false,
								message: "hủy HĐ. Lý do: " + newReasonRemove['Reason']
							};
							console.debug("notiSubmitConfirmTKPD", notiSubmitConfirmTKPD)
							// const { successNotiSuccessTPKD, messageNotiSuccessTPKD } = await addNotification(notiSubmitConfirmTKPD);
						});
					}else {
						var notiSubmitRequest = {
							sender: UserID,
							receiver: _data._UserContract._id, // gui len cap cao hon
							contract: _id,
							is_read: false,
							message: "lỗi hủy TKPD:: Role::BGD"
						};
						console.debug("notiSubmitRequest", notiSubmitRequest)
						const { successNotiErr, messageNotiErr } = await addNotification(notiSubmitRequest);
					}

				}

				let listReceiverKTT = getManagerId(newArray, _data._UserContract._id, "TPKD", findParentRole("TPKD"))
				console.debug("listReceiverKTT", listReceiverKTT)

				if (listReceiverKTT.length > 0 ) {
					listReceiverKTT.forEach( async (KTT) => {
						var notiSubmitConfirmKTT = {
							sender: UserID,
							receiver: KTT, //KTT
							contract: _id,
							is_read: false,
							message: "hủy HĐ. Lý do: " + newReasonRemove['Reason']
						};
						console.debug("notiSubmitConfirmKTT", notiSubmitConfirmKTT)
						const { successNotiSuccessKTT, messageNotiSuccessKTT } = await addNotification(notiSubmitConfirmKTT);
					})
				} else {
					var notiSubmitRequest = {
						sender: UserID,
						receiver: _data._UserContract._id, // gui len cap cao hon
						contract: _id,
						is_read: false,
						message: "lỗi hủy KTT:: Role::BGD"
					};
					console.debug("notiSubmitRequest", notiSubmitRequest)
					const { successNotiErrKTT, messageNotiErrKTT } = await addNotification(notiSubmitRequest);
				}

				//thong bao cho cap cao hon
				let listReceiverCaoHon = FindArrayUserCapCaoHon(UserID, Users, role)
				console.debug("listReceiverCaoHon", listReceiverCaoHon)
				if (listReceiverCaoHon.length > 0) {
					listReceiverCaoHon.forEach( async (element) => {
						var notiSubmitConfirmCapCao = {
							sender: UserID,
							receiver: element._id, // gui len cap cao hon
							contract: _id,
							is_read: false,
							message: "hủy HĐ. Lý do: " + newReasonRemove['Reason']
						};
						console.debug("notiSubmitConfirmCapCao", notiSubmitConfirmCapCao)
						const { successNotiSuccessCapCao, messageNotiSuccessCapCao } = await addNotification(notiSubmitConfirmCapCao);
					});
				}else {
					var notiSubmitRequest = {
						sender: UserID,
						receiver: _data._UserContract._id, // gui len cap cao hon
						contract: _id,
						is_read: false,
						message: "lỗi hủy TPKD:: Role::KTT-BGD-BTGD"
					};
					console.debug("notiSubmitConfirmCapCao lỗi hủy TPKD", notiSubmitRequest)
					const { successNotiErrCapCao, messageNotiErrCapCao } = await addNotification(notiSubmitRequest);
				}
			}

			//AM and TPKD and KTT and BGD
			if (role == "BTGD") {
				let filteredHistoryChanges = historyChanges.filter(x => x.contract == _id && x.form != 'contract' && (x.action == "update" || x.action == "remove"));
				//
				if (filteredHistoryChanges.length > 0 ) {
				} else {
					//AM
					var notiSubmitConfirmAM = {
						sender: UserID,
						receiver:  _data._UserContract._id, //AM 
						contract: _id,
						is_read: false,
						message: "hủy HĐ. Lý do: " + newReasonRemove['Reason']
					};

					console.debug("notiSubmitConfirmAM", notiSubmitConfirmAM)
					const { successNoti, messageNoti } = await addNotification(notiSubmitConfirmAM);
					//TPKD
					let listReceiverTKPD = getManagerId(newArray, _data._UserContract._id, "AM", findParentRole("AM"))
					console.debug("listReceiverTKPD", listReceiverTKPD, _data._UserContract._id)
												
					if (listReceiverTKPD.length > 0) {
						listReceiverTKPD.forEach( async (TKPD) => {
							var notiSubmitConfirmTKPD = {
								sender: UserID,
								receiver: TKPD, //TKPD
								contract: _id,
								is_read: false,
								message: "hủy HĐ. Lý do: " + newReasonRemove['Reason']
							};
							console.debug("notiSubmitConfirmTKPD", notiSubmitConfirmTKPD)
							const { successNotiSuccessTPKD, messageNotiSuccessTPKD } = await addNotification(notiSubmitConfirmTKPD); 
							
						})
					}else {
						var notiSubmitRequest = {
							sender: UserID,
							receiver: _data._UserContract._id, // gui len cap cao hon
							contract: _id,
							is_read: false,
							message: "lỗi hủy TPKD:: Role::BTGD"
						};
						console.debug("notiSubmitRequest", notiSubmitRequest)
						const { successNotiErr, messageNotiErr } = await addNotification(notiSubmitRequest);
					}

					//KTT
					let listReceiverKTT = getManagerId(newArray,  _data._UserContract._id, "TPKD", findParentRole("TPKD"))
					console.debug("listReceiverKTT", listReceiverKTT)
					if (listReceiverKTT.length > 0 ) {
						listReceiverKTT.forEach( async (KTT) => {
							var notiSubmitConfirmKTT = {
								sender: UserID,
								receiver: KTT, //KTT
								contract: _id,
								is_read: false,
								message: "hủy HĐ. Lý do: " + newReasonRemove['Reason']
							};
							console.debug("notiSubmitConfirmKTT", notiSubmitConfirmKTT)
							const { successNotiSuccessKTT, messageNotiSuccessKTT } = await addNotification(notiSubmitConfirmKTT);
						})
					} else {
						var notiSubmitRequest = {
							sender: UserID,
							receiver: _data._UserContract._id, // gui len cap cao hon
							contract: _id,
							is_read: false,
							message: "lỗi hủy KTT:: Role::BTGD"
						};
						console.debug("notiSubmitRequest", notiSubmitRequest)
						const { successNotiErrKTT, messageNotiErrKTT } = await addNotification(notiSubmitRequest);
					}
				}
				
				//BGD
				let listReceiverBGD = getManagerId(newArray, _data._UserContract._id, "KTT", findParentRole("KTT"))
				console.debug("listReceiverBGD", listReceiverBGD)

				if (listReceiverBGD.length > 0) {
					listReceiverBGD.forEach( async (BGD) => {
						var notiSubmitConfirmBGD = {
							sender: UserID,
							receiver: BGD, //BGD
							contract: _id,
							is_read: false,
							message: "hủy HĐ. Lý do: " + newReasonRemove['Reason']
						};
						console.debug("notiSubmitConfirmBGD", notiSubmitConfirmBGD)
						const { successNotiSuccessBGD, smessageNotiSuccessBGD } = await addNotification(notiSubmitConfirmBGD);
					})
				} else {
					var notiSubmitRequest = {
						sender: UserID,
						receiver: _data._UserContract._id, // gui len cap cao hon
						contract: _id,
						is_read: false,
						message: "lỗi hủy BGD:: Role::BTGD"
					};
					console.debug("notiSubmitRequest", notiSubmitRequest)
					const { successNotiErrBGD, messageNotiErrBGD } = await addNotification(notiSubmitRequest);
				}
			}

			// resetAddContractData()
			// setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
		}
		return (
			<Modal show={showModalRemove} onHide={closeModalRemove}>
				<Modal.Header closeButton>
					<Modal.Title>Hủy hợp đồng</Modal.Title>
				</Modal.Header>
				<Form onSubmit={onSubmitRemoveContract}>
					<Modal.Body>
						<Form.Group>
							<Form.Text id='Center-help' muted as="h6">
							 	Lý do
							</Form.Text>
							<Form.Control
								type='text'
								placeholder=''
								name='Reason'
								required
								aria-describedby='Center-help'
								value={Reason}
								onChange={onChangeReasonRemoveContractForm}
							/>						
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary' onClick={closeModalRemove}>
							Hủy
						</Button>
						<Button variant='info' type='submit'>
							Đồng ý
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		);
	}

	switch(role){
		case 'AM':
			return (
				<>
					<OverlayTrigger
						overlay={
							<Tooltip id="tooltip">
								Gửi yêu cầu
							</Tooltip>
						}
					>
						{/*<Button onClick={submitRequest} style={{marginTop:"10px"}}>
							Gửi yêu cầu
						</Button>*/}
						<a onClick={submitRequest} style={{'cursor':'pointer'}}>
							<img src={iconSendRequest}  width="36" height="36"></img>
						</a>
					</OverlayTrigger>
				</>
			)
			break;
		case 'TPKD':
			if(StatusDB == 3 || StatusDB == -1 || StatusDB == 1 || StatusDB == -2){
				if(StatusDB == 3 || StatusDB == -2){
					return (
						<>
							{
								historyContractNew != '' ?
								<>
								</>
								:
								<>
									{
										nameRoleContract == 'AM' ?
										<>
											<OverlayTrigger
													overlay={
														<Tooltip id="tooltip">
															Từ chối
														</Tooltip>
													}
												>
													<a onClick={setShowModalRefuse.bind(this, true)} style={{'cursor':'pointer'}}>
														<img src={iconDeny}  width="36" height="36"></img>
													</a>
											</OverlayTrigger>
											<>
												<Render_Modal_Refuse/>
											</>
										</>
										:
										<></>
									}
								</>
							}
							<OverlayTrigger
									overlay={
										<Tooltip id="tooltip">
											Gửi yêu cầu
									</Tooltip>
								}
							>
								{/*<Button onClick={submitRequest} style={{marginTop:"10px"}}>
									Gửi yêu cầu
								</Button>*/}
								<a onClick={submitRequest} style={{'cursor':'pointer'}}>
									<img src={iconSendRequest}  width="36" height="36"></img>
								</a>
							</OverlayTrigger>
						</>
					)
				}else{
					return (
						<>
							<OverlayTrigger
								overlay={
									<Tooltip id="tooltip">
										Gửi yêu cầu
								</Tooltip>
							}
						>
							{/*<Button onClick={submitRequest} style={{marginTop:"10px"}}>
								Gửi yêu cầu
							</Button>*/}
							<a onClick={submitRequest} style={{'cursor':'pointer'}}>
								<img src={iconSendRequest}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>
						</>
					)
				}
			}else if(StatusDB == -4){
				return (
						<></>
					) 

			}else{
				if(StatusDB >= 4){
					return (
						<></>
					)
				}else if(StatusDB == 2){
					return (
						<>
							<OverlayTrigger
								overlay={
									<Tooltip id="tooltip">
										Duyệt
									</Tooltip>
								}
								>
								{/*<Button onClick={submitConfirm} style={{marginTop:"10px"}}>
									Duyệt
								</Button>*/}
								<a onClick={submitConfirm} style={{'cursor':'pointer'}}>
									<img src={iconAccept}  width="36" height="36"></img>
								</a>
							</OverlayTrigger>
							{
								historyContractNew != '' &&  historyContractNew != undefined
								?
								<>
								</>
								:
								<>
									<OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Từ chối
											</Tooltip>
										}
									>
										{/*<Button onClick={submitRefuse} style={{marginTop:"10px"}}>
											Từ chối
										</Button>*/}
										<a onClick={setShowModalRefuse.bind(this, true)} style={{'cursor':'pointer'}}>
											<img src={iconDeny}  width="36" height="36"></img>
										</a>
									</OverlayTrigger>
								</>
							}
							
							<OverlayTrigger
								overlay={
									<Tooltip id="tooltip">
										Hủy HĐ
									</Tooltip>
								}
							>
								{/*<Button onClick={submitRefuse} style={{marginTop:"10px"}}>
									Từ chối
								</Button>*/}
						{/*		<a onClick={submitRemove} style={{'cursor':'pointer'}}>
									<img src={iconDeny}  width="36" height="36"></img>
								</a>*/}
								<a onClick={setShowModalRemove.bind(this, true)} style={{'cursor':'pointer'}}>
									{/*<img src={iconDeny}  width="36" height="36"></img>*/}
									<FontAwesomeIcon icon={faBan} style={{color:'rgb(220, 53, 69)',width:'36px',height:'28px'}}/>
								</a>
							</OverlayTrigger>
							<>
								<Render_Modal_Refuse/>
								<Render_Modal_Remove/>
							</>
						</>
					)
				}
			}
			break;
		case 'KTT':
			if(StatusDB == 5 || StatusDB == -2){
				if(StatusDB == 5){
					return (
						<>
							{
								historyContractNew != '' ?
								<>
								</>
								:
								<>
									{
										nameRoleContract == 'AM' || nameRoleContract == 'TPKD'  ?
										<>
											<OverlayTrigger
													overlay={
														<Tooltip id="tooltip">
															Từ chối
														</Tooltip>
													}
												>
													{/*<Button onClick={submitRefuse} style={{marginTop:"10px"}}>
														Từ chối
													</Button>*/}
													<a onClick={setShowModalRefuse.bind(this, true)} style={{'cursor':'pointer'}}>
														<img src={iconDeny}  width="36" height="36"></img>
													</a>
											</OverlayTrigger>
											<>
												<Render_Modal_Refuse/>
											</>
										</>
										:
										<></>
									}
								</>
							}
							<>
								<OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Gửi yêu cầu
										</Tooltip>
									}
								>
									{/*<Button onClick={submitRequest} style={{marginTop:"10px"}}>
										Gửi yêu cầu
									</Button>*/}
									<a onClick={submitRequest} style={{'cursor':'pointer'}}>
										<img src={iconSendRequest}  width="36" height="36"></img>
									</a>
								</OverlayTrigger>
							</>
						</>
					)
				}else{
					return (
						<>
							<OverlayTrigger
									overlay={
										<Tooltip id="tooltip">
											Gửi yêu cầu
									</Tooltip>
								}
							>
								{/*<Button onClick={submitRequest} style={{marginTop:"10px"}}>
									Gửi yêu cầu
								</Button>*/}
								<a onClick={submitRequest} style={{'cursor':'pointer'}}>
									<img src={iconSendRequest}  width="36" height="36"></img>
								</a>
							</OverlayTrigger>
						</>
					)
				}
			}else if(StatusDB == -6 || StatusDB == -4){
				if(StatusDB == -4){
					return (
						<>
						{/*	<OverlayTrigger
								overlay={
									<Tooltip id="tooltip">
										Duyệt
									</Tooltip>
								}
								>
								<a onClick={submitConfirm} style={{'cursor':'pointer'}}>
									<img src={iconAccept}  width="36" height="36"></img>
								</a>
							</OverlayTrigger>*/}
							{
								nameRoleContract == 'AM' || nameRoleContract == 'TPKD' ?
								<>
									{
										historyContractNew != '' && historyContractNew != undefined
										?
										<></>
										:
										<>
											<OverlayTrigger
												overlay={
													<Tooltip id="tooltip">
														Từ chối
													</Tooltip>
												}
											>
												{/*<Button onClick={submitRefuse} style={{marginTop:"10px"}}>
													Từ chối
												</Button>*/}
												<a onClick={setShowModalRefuse.bind(this, true)} style={{'cursor':'pointer'}}>
													<img src={iconDeny}  width="36" height="36"></img>
												</a>
											</OverlayTrigger>
											<>
												<Render_Modal_Refuse/>
											</>
										</>
									}
								</>
								:
								<></>
							}
							<OverlayTrigger
									overlay={
										<Tooltip id="tooltip">
											Gửi yêu cầu
									</Tooltip>
								}
							>
								{/*<Button onClick={submitRequest} style={{marginTop:"10px"}}>
									Gửi yêu cầu
								</Button>*/}
								<a onClick={submitRequest} style={{'cursor':'pointer'}}>
									<img src={iconSendRequest}  width="36" height="36"></img>
								</a>
							</OverlayTrigger>
						</>
					)
				}else{
					return (
						<>
							<OverlayTrigger
								overlay={
									<Tooltip id="tooltip">
										Duyệt
									</Tooltip>
								}
								>
								<a onClick={submitConfirm} style={{'cursor':'pointer'}}>
									<img src={iconAccept}  width="36" height="36"></img>
								</a>
							</OverlayTrigger>
							<OverlayTrigger
									overlay={
										<Tooltip id="tooltip">
											Gửi yêu cầu
									</Tooltip>
								}
							>
								{/*<Button onClick={submitRequest} style={{marginTop:"10px"}}>
									Gửi yêu cầu
								</Button>*/}
								<a onClick={submitRequest} style={{'cursor':'pointer'}}>
									<img src={iconSendRequest}  width="36" height="36"></img>
								</a>
							</OverlayTrigger>
						</>
					)
				}
			}else if(StatusDB == 1){
				return (
					<>
						{/*<OverlayTrigger
							overlay={
								<Tooltip id="tooltip">
									Duyệt
								</Tooltip>
							}
							>
							<a onClick={submitConfirm} style={{'cursor':'pointer'}}>
								<img src={iconAccept}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>*/}
						<>
							<OverlayTrigger
							overlay={
								<Tooltip id="tooltip">
									Gửi yêu cầu
								</Tooltip>
							}
						>
							<a onClick={submitRequest} style={{'cursor':'pointer'}}>
								<img src={iconSendRequest}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>
						</>
					</>
				)
			}else{
				if(StatusDB >= 6){
					return (
						<></>
					)
				}else if(StatusDB == 4){
					return (
						<>
							<OverlayTrigger
								overlay={
									<Tooltip id="tooltip">
										Duyệt
									</Tooltip>
								}
								>
								{/*<Button onClick={submitConfirm} style={{marginTop:"10px"}}>
									Duyệt
								</Button>*/}
								<a onClick={submitConfirm} style={{'cursor':'pointer'}}>
									<img src={iconAccept}  width="36" height="36"></img>
								</a>
							</OverlayTrigger>
							{
								historyContractNew != '' && historyContractNew != undefined
								?
								<>
								</>
								:
								<>
									<OverlayTrigger
										overlay={
											<Tooltip id="tooltip">
												Từ chối
											</Tooltip>
										}
									>
										{/*<Button onClick={submitRefuse} style={{marginTop:"10px"}}>
											Từ chối
										</Button>*/}
										<a onClick={setShowModalRefuse.bind(this, true)} style={{'cursor':'pointer'}}>
											<img src={iconDeny}  width="36" height="36"></img>
										</a>
									</OverlayTrigger>
								</>
							}
							
							<OverlayTrigger
								overlay={
									<Tooltip id="tooltip">
										Hủy HĐ
									</Tooltip>
								}
							>
								{/*<Button onClick={submitRefuse} style={{marginTop:"10px"}}>
									Từ chối
								</Button>*/}
								{/*<a onClick={submitRemove} style={{'cursor':'pointer'}}>
									<img src={iconDeny}  width="36" height="36"></img>
								</a>*/}
								<a onClick={setShowModalRemove.bind(this, true)} style={{'cursor':'pointer'}}>
									{/*<img src={iconDeny}  width="36" height="36"></img>*/}
									<FontAwesomeIcon icon={faBan} style={{color:'rgb(220, 53, 69)',width:'36px',height:'28px'}}/>
								</a>
							</OverlayTrigger>
							<>
								<Render_Modal_Refuse/>
								<Render_Modal_Remove/>
								{/*<Modal show={showModalRefuse} onHide={closeModalRefuse}>
									<Modal.Header closeButton>
										<Modal.Title>Từ chối hợp đồng</Modal.Title>
									</Modal.Header>
									<Form onSubmit={onSubmitRefuseContract}>
										<Modal.Body>
											<Form.Group>
												<Form.Text id='Center-help' muted as="h6">
												 	Lý do
												</Form.Text>
												<Form.Control
													type='text'
													placeholder=''
													name='Reason'
													required
													aria-describedby='Center-help'
													value={Reason}
													onChange={onChangeReasonRefuseContractForm}
												/>						
											</Form.Group>
										</Modal.Body>
										<Modal.Footer>
											<Button variant='secondary' onClick={closeModalRefuse}>
												Hủy
											</Button>
											<Button variant='info' type='submit'>
												Đồng ý
											</Button>
										</Modal.Footer>
									</Form>
								</Modal>*/}
							</>
						</>
					)
				}else{
					return (
					<>
						<OverlayTrigger
							overlay={
								<Tooltip id="tooltip">
									Duyệt
								</Tooltip>
							}
							>
							{/*<Button onClick={submitConfirm} style={{marginTop:"10px"}}>
								Duyệt
							</Button>*/}
							<a onClick={submitConfirm} style={{'cursor':'pointer'}}>
								<img src={iconAccept}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>
						<OverlayTrigger
							overlay={
								<Tooltip id="tooltip">
									Từ chối
								</Tooltip>
							}
						>
							{/*<Button onClick={submitRefuse} style={{marginTop:"10px"}}>
								Từ chối
							</Button>*/}
							<a onClick={submitRefuse} style={{'cursor':'pointer'}}>
								<img src={iconDeny}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>
						<OverlayTrigger
							overlay={
								<Tooltip id="tooltip">
									Gửi yêu cầu
								</Tooltip>
							}
						>
							{/*<Button onClick={submitRequest} style={{marginTop:"10px"}}>
								Gửi yêu cầu
							</Button>*/}
							<a onClick={submitRequest} style={{'cursor':'pointer'}}>
								<img src={iconSendRequest}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>
					</>
				)
				}
			}
			break;
		case 'BGD':
			if(StatusDB == 7){
				return (
					<>
						{
							nameRoleContract == 'AM' || nameRoleContract == 'TPKD' || nameRoleContract == 'KTT' ?
							<>
								<OverlayTrigger
									overlay={
										<Tooltip id="tooltip">
											Từ chối
										</Tooltip>
									}
								>
									{/*<Button onClick={submitRefuse} style={{marginTop:"10px"}}>
										Từ chối
									</Button>*/}
									<a onClick={setShowModalRefuse.bind(this, true)} style={{'cursor':'pointer'}}>
										<img src={iconDeny}  width="36" height="36"></img>
									</a>
								</OverlayTrigger>
								<>
									<Render_Modal_Refuse/>
								</>
							</>
							:
							<></>
						}
						<OverlayTrigger
								overlay={
									<Tooltip id="tooltip">
										Gửi yêu cầu
								</Tooltip>
							}
						>
							{/*<Button onClick={submitRequest} style={{marginTop:"10px"}}>
								Gửi yêu cầu
							</Button>*/}
							<a onClick={submitRequest} style={{'cursor':'pointer'}}>
								<img src={iconSendRequest}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>

					</>
				)
			}else if(StatusDB == 1){
				return (
					<>
						{/*<OverlayTrigger
							overlay={
								<Tooltip id="tooltip">
									Duyệt
								</Tooltip>
							}
							>
							<a onClick={submitConfirm} style={{'cursor':'pointer'}}>
								<img src={iconAccept}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>*/}
						<OverlayTrigger
							overlay={
								<Tooltip id="tooltip">
									Gửi yêu cầu
								</Tooltip>
							}
						>
							<a onClick={submitRequest} style={{'cursor':'pointer'}}>
								<img src={iconSendRequest}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>
					</>

				)
			}else if(StatusDB == -6){
				return (
					<>
						{
							nameRoleContract == 'AM' || nameRoleContract == 'TPKD' || nameRoleContract == 'KTT' ?
							<>
								<OverlayTrigger
									overlay={
										<Tooltip id="tooltip">
											Từ chối
										</Tooltip>
									}
								>
									{/*<Button onClick={submitRefuse} style={{marginTop:"10px"}}>
										Từ chối
									</Button>*/}
									<a onClick={setShowModalRefuse.bind(this, true)} style={{'cursor':'pointer'}}>
										<img src={iconDeny}  width="36" height="36"></img>
									</a>
								</OverlayTrigger>
								<>
									<Render_Modal_Refuse/>
								</>
							</>
							:
							<></>
						}
						{/*<OverlayTrigger
							overlay={
								<Tooltip id="tooltip">
									Duyệt
								</Tooltip>
							}
							>
							<a onClick={submitConfirm} style={{'cursor':'pointer'}}>
								<img src={iconAccept}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>*/}
						<OverlayTrigger
								overlay={
									<Tooltip id="tooltip">
										Gửi yêu cầu
								</Tooltip>
							}
						>
							<a onClick={submitRequest} style={{'cursor':'pointer'}}>
								<img src={iconSendRequest}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>
					</>
				)
			}else if(StatusDB == -4){
				return (<></>)
			}else if(StatusDB == 6){
				return (
					<>
						<OverlayTrigger
							overlay={
								<Tooltip id="tooltip">
									Duyệt
								</Tooltip>
							}
							>
							{/*<Button onClick={submitConfirm} style={{marginTop:"10px"}}>
								Duyệt
							</Button>*/}
							<a onClick={submitConfirm} style={{'cursor':'pointer'}}>
								<img src={iconAccept}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>
						<OverlayTrigger
							overlay={
								<Tooltip id="tooltip">
									Từ chối
								</Tooltip>
							}
						>
							{/*<Button onClick={submitRefuse} style={{marginTop:"10px"}}>
								Từ chối
							</Button>*/}
							<a onClick={setShowModalRefuse.bind(this, true)} style={{'cursor':'pointer'}}>
								<img src={iconDeny}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>
						<OverlayTrigger
								overlay={
									<Tooltip id="tooltip">
										Hủy HĐ
									</Tooltip>
								}
							>
								{/*<Button onClick={submitRefuse} style={{marginTop:"10px"}}>
									Từ chối
								</Button>*/}
{/*								<a onClick={submitRemove} style={{'cursor':'pointer'}}>
									<img src={iconDeny}  width="36" height="36"></img>
								</a>*/}
								<a onClick={setShowModalRemove.bind(this, true)} style={{'cursor':'pointer'}}>
									{/*<img src={iconDeny}  width="36" height="36"></img>*/}
									<FontAwesomeIcon icon={faBan} style={{color:'rgb(220, 53, 69)',width:'36px',height:'28px'}}/>
								</a>
						</OverlayTrigger>
						<>
							<Render_Modal_Refuse/>
							<Render_Modal_Remove/>
							{/*<Modal show={showModalRefuse} onHide={closeModalRefuse}>
								<Modal.Header closeButton>
									<Modal.Title>Từ chối hợp đồng</Modal.Title>
								</Modal.Header>
								<Form onSubmit={onSubmitRefuseContract}>
									<Modal.Body>
										<Form.Group>
											<Form.Text id='Center-help' muted as="h6">
											 	Lý do
											</Form.Text>
											<Form.Control
												type='text'
												placeholder=''
												name='Reason'
												required
												aria-describedby='Center-help'
												value={Reason}
												onChange={onChangeReasonRefuseContractForm}
											/>						
										</Form.Group>
									</Modal.Body>
									<Modal.Footer>
										<Button variant='secondary' onClick={closeModalRefuse}>
											Hủy
										</Button>
										<Button variant='info' type='submit'>
											Đồng ý
										</Button>
									</Modal.Footer>
								</Form>
							</Modal>*/}
						</>
					</>
				)
			}else{
				if(StatusDB == 8){
					return (
						<></>
					)
				}else{
					return (
					<>
						<OverlayTrigger
							overlay={
								<Tooltip id="tooltip">
									Duyệt
								</Tooltip>
							}
							>
							{/*<Button onClick={submitConfirm} style={{marginTop:"10px"}}>
								Duyệt
							</Button>*/}
							<a onClick={submitConfirm} style={{'cursor':'pointer'}}>
								<img src={iconAccept}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>
						<OverlayTrigger
							overlay={
								<Tooltip id="tooltip">
									Từ chối
								</Tooltip>
							}
						>
							{/*<Button onClick={submitRefuse} style={{marginTop:"10px"}}>
								Từ chối
							</Button>*/}
							<a onClick={setShowModalRefuse.bind(this, true)} style={{'cursor':'pointer'}}>
								<img src={iconDeny}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>
						<OverlayTrigger
							overlay={
								<Tooltip id="tooltip">
									Gửi yêu cầu
								</Tooltip>
							}
						>
							{/*<Button onClick={submitRequest} style={{marginTop:"10px"}}>
								Gửi yêu cầu
							</Button>*/}
							<a onClick={submitRequest} style={{'cursor':'pointer'}}>
								<img src={iconSendRequest}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>
					</>
				)
				}
			}
			break;
		case 'BTGD':
			if(StatusDB == 8){
				return (
					<>
						<OverlayTrigger
							overlay={
								<Tooltip id="tooltip">
									Duyệt
								</Tooltip>
							}
							>
							{/*<Button onClick={submitConfirm} style={{marginTop:"10px"}}>
								Duyệt
							</Button>*/}
							<a onClick={submitConfirm} style={{'cursor':'pointer'}}>
								<img src={iconAccept}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>
						<OverlayTrigger
							overlay={
								<Tooltip id="tooltip">
									Từ chối
								</Tooltip>
							}
						>
							{/*<Button onClick={submitRefuse} style={{marginTop:"10px"}}>
								Từ chối
							</Button>*/}
							<a onClick={setShowModalRefuse.bind(this, true)} style={{'cursor':'pointer'}}>
								<img src={iconDeny}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>
						<OverlayTrigger
								overlay={
									<Tooltip id="tooltip">
										Hủy HĐ
									</Tooltip>
								}
							>
								{/*<Button onClick={submitRefuse} style={{marginTop:"10px"}}>
									Từ chối
								</Button>*/}
								{/*<a onClick={submitRemove} style={{'cursor':'pointer'}}>
									<img src={iconDeny}  width="36" height="36"></img>
								</a>*/}
								<a onClick={setShowModalRemove.bind(this, true)} style={{'cursor':'pointer'}}>
									{/*<img src={iconDeny}  width="36" height="36"></img>*/}
									<FontAwesomeIcon icon={faBan} style={{color:'rgb(220, 53, 69)',width:'36px',height:'28px'}}/>
								</a>
						</OverlayTrigger>
						<>
							<Render_Modal_Refuse/>
							<Render_Modal_Remove/>
							{/*<Modal show={showModalRefuse} onHide={closeModalRefuse}>
								<Modal.Header closeButton>
									<Modal.Title>Từ chối hợp đồng</Modal.Title>
								</Modal.Header>
								<Form onSubmit={onSubmitRefuseContract}>
									<Modal.Body>
										<Form.Group>
											<Form.Text id='Center-help' muted as="h6">
											 	Lý do
											</Form.Text>
											<Form.Control
												type='text'
												placeholder=''
												name='Reason'
												required
												aria-describedby='Center-help'
												value={Reason}
												onChange={onChangeReasonRefuseContractForm}
											/>						
										</Form.Group>
									</Modal.Body>
									<Modal.Footer>
										<Button variant='secondary' onClick={closeModalRefuse}>
											Hủy
										</Button>
										<Button variant='info' type='submit'>
											Đồng ý
										</Button>
									</Modal.Footer>
								</Form>
							</Modal>*/}
						</>
					</>
				)
			}else if(StatusDB == 9){
				// return (<></>)
				return(
					<>
						<OverlayTrigger
							overlay={
								<Tooltip id="tooltip">
									Từ chối
								</Tooltip>
							}
						>
							<a onClick={setShowModalRefuse.bind(this, true)} style={{'cursor':'pointer'}}>
								<img src={iconDeny}  width="36" height="36"></img>
							</a>
						</OverlayTrigger>
						<>
							<Render_Modal_Refuse/>
						</>
					</>
				)
			}else if(StatusDB == 1){
				// return (<></>)
				return(
					<>

					</>
				)
			}else{
				return (<></>)
			}
			
			break;
	}

	// function findArrayRole(role,roleUserContract){
	// 	switch (role){
	// 		case 'AM':
	// 			break;
	// 		case 'TPKD':
	// 			break;
	// 		case 'KTT':
	// 			break;
	// 		case 'BGD':
	// 			if(roleUserContract == 'KTT' || roleUserContract == 'TPKD' || roleUserContract == 'AM'){
	// 				return 1;
	// 			}else{
	// 				return 0;
	// 			}
	// 			break;
	// 		case 'BTGD':
	// 			break;
	// 	}
	// }
}
