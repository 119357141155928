import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../App.css'
import { useContext, useState,useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { AuxiliaryCostContext } from '../../contexts/AuxiliaryCostContext'
import { AuthContext } from '../../contexts/AuthContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'

const Update_AuxiliaryCost_Cost_Modal = () => {
	const params = useParams();
	// Contexts
	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	const {
		updateAuxiliary_Cost,
		Data_AuxiliaryCost_Cost,
		showupdate_AuxiliaryCost_Cost_Modal, setshowupdate_AuxiliaryCost_Cost_Modal,
		setShowToast
		
	} = useContext(AuxiliaryCostContext);

	const [updateAuxiliaryCost_Cost, setupdateAuxiliaryCost_Cost] = useState('')
	const [newCheckBox, setCheckBox] = useState(Data_AuxiliaryCost_Cost)
	const [validValueFee, setValidValueFee] = useState(Data_AuxiliaryCost_Cost.Fee);
	const [validValueTaxRate, setValidValueTaxRate] = useState(Data_AuxiliaryCost_Cost.TaxRate);
	const [valNewCost, setNewCost] = useState(Data_AuxiliaryCost_Cost.Cost);
	const [valNewTax, setNewTax] = useState(Data_AuxiliaryCost_Cost.Tax);
	const [Renevue, setRenevue] = useState(Data_AuxiliaryCost_Cost.Renevue);

	const [beforeAuxiliaryCost, setBeforeAuxiliaryCost] = useState(Data_AuxiliaryCost_Cost)
	useEffect(() => setBeforeAuxiliaryCost(Data_AuxiliaryCost_Cost),[Data_AuxiliaryCost_Cost])

	useEffect(() => setupdateAuxiliaryCost_Cost(Data_AuxiliaryCost_Cost), [Data_AuxiliaryCost_Cost])
	useEffect(() => setCheckBox(Data_AuxiliaryCost_Cost), [Data_AuxiliaryCost_Cost])
	// useEffect(() => setValidValueFee(Data_AuxiliaryCost_Cost.Fee), [Data_AuxiliaryCost_Cost.Fee])
	// useEffect(() => setValidValueTaxRate(Data_AuxiliaryCost_Cost.TaxRate), [Data_AuxiliaryCost_Cost.TaxRate])
	useEffect(() => setNewCost(Data_AuxiliaryCost_Cost.Cost), [Data_AuxiliaryCost_Cost.Cost])
	useEffect(() => setNewTax(Data_AuxiliaryCost_Cost.Tax), [Data_AuxiliaryCost_Cost.Tax])
	useEffect(() => setRenevue(Data_AuxiliaryCost_Cost.Renevue), [Data_AuxiliaryCost_Cost.Renevue])

	let {
		checkBoxFee,
		checkBoxCost,
		checkBoxTaxRate,
		checkBoxTax,
	} = newCheckBox

	let {
		idcontract,
		Content,
		Cost,
		Note,
		Fee,
		TaxRate,
		CostTax,
		Tax,
	} = updateAuxiliaryCost_Cost
	// console.log(updateAuxiliaryCost_Cost,'=============')
	// load idcontract
	// const params = useParams();
	// updateAuxiliaryCost_Cost.contractID = params.id;
	Fee = (validValueFee != undefined) ? validValueFee: Data_AuxiliaryCost_Cost.Fee;
	TaxRate = (validValueTaxRate != undefined) ? validValueTaxRate : Data_AuxiliaryCost_Cost.TaxRate;
	//console.log(Data_AuxiliaryCost_Cost)

	const onChangeNewAuxiliaryCostForm = event =>{
		const name = event.target.name;
		const val = event.target.value
		console.log('name', name, "val", val)

		if (name == "Fee") {
			if (!isNumberOrDecimal(val) && val != '') {
				var message = 'Vui lòng nhập tỉ lệ % không quá 2 số thập phận'
				setShowToast({ show: true, message: message, type: 'danger' })
				event.target.value = validValueFee
				return false;
			}else{
				var value = (event.target.value != "") ? event.target.value : 0;
				if (parseInt(event.target.value) > 100 && parseFloat(event.target.value) > 100) {
					var message = 'Vui lòng nhập tỷ lệ không quá 100%'
					setShowToast({ show: true, message: message, type: 'danger' })
					event.target.value = validValueFee
					return false;
				}
				event.target.value = event.target.value
				const newCost = ((Renevue * value)/100).toFixed(0);
				setNewCost(newCost)
				var newCostTax = parseInt(newCost) + parseInt(valNewTax);
				let newTax = 0;
				if (updateAuxiliaryCost_Cost.TaxRate != null && updateAuxiliaryCost_Cost.TaxRate != "") {
					switch (Data_AuxiliaryCost_Cost.PLan) {
						case 1:
							newTax = ((newCost * updateAuxiliaryCost_Cost.TaxRate)/100).toFixed(0);
							break;
						case 2:
							newTax = ((newCost / (100 - updateAuxiliaryCost_Cost.TaxRate)) * updateAuxiliaryCost_Cost.TaxRate).toFixed(0);
							break;
					}
					setNewTax(newTax)
				}
				setupdateAuxiliaryCost_Cost({ ...updateAuxiliaryCost_Cost, 'Cost': newCost, 'Fee': event.target.value, 'Tax': newTax, 'CostTax': newCostTax})
			}
			setValidValueFee(event.target.value);
		}else if (name == "Cost") { 
			if (!isParentNumber(val) && val != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				setNewCost(event.target.value)
				var newCostTax = parseInt(event.target.value) + parseInt(valNewTax);
				//070623
				let newTax = valNewTax;
				if (updateAuxiliaryCost_Cost.TaxRate != null && updateAuxiliaryCost_Cost.TaxRate != "") {
					switch (Data_AuxiliaryCost_Cost.PLan) {
						case 1:
							newTax = ((event.target.value * updateAuxiliaryCost_Cost.TaxRate)/100).toFixed(0);
							break;
						case 2:
							newTax = ((event.target.value / (100 - updateAuxiliaryCost_Cost.TaxRate)) * updateAuxiliaryCost_Cost.TaxRate).toFixed(0);
							break;
					}
					setNewTax(newTax)
				}
				//
				setupdateAuxiliaryCost_Cost({ ...updateAuxiliaryCost_Cost, 'Cost': event.target.value, 'Tax': newTax, 'CostTax': newCostTax})
				//setupdateAuxiliaryCost_Cost({ ...updateAuxiliaryCost_Cost, [event.target.name]: event.target.value, 'CostTax': newCostTax})
			}
		}else if (name == "TaxRate") {
			if (!isNumberOrDecimal(val) && val != '') {
				var message = 'Vui lòng nhập tỉ lệ % không quá 2 số thập phận'
				setShowToast({ show: true, message: message, type: 'danger' })
				event.target.value = validValueTaxRate
				return false;
			}else{
				var value = (event.target.value != "") ? event.target.value : 0;
				if (parseInt(event.target.value) > 100 && parseFloat(event.target.value) > 100) {
					var message = 'Vui lòng nhập tỷ lệ không quá 100%'
					setShowToast({ show: true, message: message, type: 'danger' })
					event.target.value = validValueTaxRate
					return false;
				}
				event.target.value = event.target.value
				//const newTax = ((valNewCost * value)/100).toFixed(0);
				//setNewTax(newTax)
				//070623
				let newTax = 0;
				if (updateAuxiliaryCost_Cost.TaxRate != null && updateAuxiliaryCost_Cost.TaxRate != "") {
					switch (Data_AuxiliaryCost_Cost.PLan) {
						case 1:
							newTax = ((valNewCost * event.target.value)/100).toFixed(0);
							break;
						case 2:
							newTax = ((valNewCost / (100 - event.target.value)) * event.target.value).toFixed(0);
							break;
					}
					setNewTax(newTax)
				}
				var newCostTax = parseInt(valNewCost) + parseInt(newTax);
				setupdateAuxiliaryCost_Cost({ ...updateAuxiliaryCost_Cost, 'Tax': newTax, 'TaxRate': event.target.value, 'CostTax': newCostTax})
			}
			setValidValueTaxRate(event.target.value);
		}else if (name == "Tax") {
			if (!isParentNumber(val) && val != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				setNewTax(event.target.value)
				var newCostTax = parseInt(valNewCost) + parseInt(event.target.value);
				setupdateAuxiliaryCost_Cost({ ...updateAuxiliaryCost_Cost, [event.target.name]: event.target.value, 'CostTax': newCostTax})
			}
		}else {
			setupdateAuxiliaryCost_Cost({ ...updateAuxiliaryCost_Cost, [event.target.name]: event.target.value })
		}
	}

	const closeDialog = () => {
		setupdateAuxiliaryCost_Cost(updateAuxiliaryCost_Cost)
		setshowupdate_AuxiliaryCost_Cost_Modal(false)
		setCheckBox(newCheckBox)
		setValidValueFee(undefined)
		setValidValueTaxRate(undefined)
		setNewCost(valNewCost)
		setNewTax(valNewTax)
		setRenevue(Renevue)
	}

	const onSubmit = async event => {
		event.preventDefault()
		const { success, message } = await updateAuxiliary_Cost(updateAuxiliaryCost_Cost, Data_AuxiliaryCost_Cost)//newAuxiliaryCost
		const diff = findDiff(beforeAuxiliaryCost, updateAuxiliaryCost_Cost);
		const historyChangeAuxiliaryCost = {
		    "requester": _id,
		    "contract": params.id,
		    "form": "auxiliary-cost",
		    "action": "update",
		    "data_change": "change " + JSON.stringify(diff)
		}

		const { successHis, messageHis } = await addHistoryChange(historyChangeAuxiliaryCost)
		setshowupdate_AuxiliaryCost_Cost_Modal(false)
		setValidValueFee(undefined)
		setValidValueTaxRate(undefined)
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
	}

	function findDiff(obj1, obj2) {
	    const diff = {};

	    Object.keys(obj1).forEach((key) => {
	        if (obj1[key] !== obj2[key]) {
	            diff[key] = obj2[key];
	        }
	    });

	    return diff;
	}

	const onChangeCheckBox = event => {
		const name = event.target.name;
		const val = event.target.value

		if (name == "checkBoxFee" && val == "on") {
			var newCost = (validValueFee == null) ? valNewCost : ((Renevue * validValueFee)/100).toFixed(0);
			setCheckBox({ ...newCheckBox, 'checkBoxFee': true, 'checkBoxCost': false})
			setupdateAuxiliaryCost_Cost({ ...updateAuxiliaryCost_Cost, 'Fee': validValueFee, 'Cost': newCost })
		}else if (name == "checkBoxCost" && val == "on") {
			setCheckBox({ ...newCheckBox, 'checkBoxFee': false, 'checkBoxCost': true, })
			setupdateAuxiliaryCost_Cost({ ...updateAuxiliaryCost_Cost, 'Fee': '', 'Cost': valNewCost })
		}else if (name == "checkBoxTaxRate" && val == "on") {
			var newTax = (validValueTaxRate == null) ? valNewTax : ((valNewCost * validValueTaxRate)/100).toFixed(0);
			setCheckBox({ ...newCheckBox, 'checkBoxTaxRate': true, 'checkBoxTax': false})
			setupdateAuxiliaryCost_Cost({ ...updateAuxiliaryCost_Cost, 'TaxRate': validValueTaxRate, 'Tax': newTax })
		}else if (name == "checkBoxTax" && val == "on") {
			setCheckBox({ ...newCheckBox, 'checkBoxTaxRate': false, 'checkBoxTax': true })
			setupdateAuxiliaryCost_Cost({ ...updateAuxiliaryCost_Cost, 'TaxRate': '', 'Tax': valNewTax})
		}
	}

	const isNumberOrDecimal = userInput => {
  		return /^\d+(\.\d{0,2})?$/.test(userInput)
	}

	const isParentNumber = userInput => {
		if (typeof userInput !== 'string') return false;
		return /^\d+$/.test(userInput);
	}

	return (
		<Modal show={showupdate_AuxiliaryCost_Cost_Modal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>Cập nhật chi phí</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<Form.Group>
						<Form.Control
							type='text'
							placeholder=''
							name='idcontract'
							value={idcontract}
							hidden
							onChange={onChangeNewAuxiliaryCostForm}
						/>						
					</Form.Group>

					<Form.Group>
						<Form.Text id='noidung-help' muted as="h6">
							Nội dung chi phí
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Nhập chuỗi'
							name='Content'
							required
							aria-describedby='noidung-help'
							value={Content}
							onChange={onChangeNewAuxiliaryCostForm}
						/>
					</Form.Group>

					<Form.Row>
						<Form.Group as={Col} controlId="formGridFee">
							<Form.Check
								type="radio"
								label="Phí(%)"
								name="checkBoxFee"
								id="formHorizontalRadios1"
								checked={checkBoxFee}
								onChange={onChangeCheckBox}
							/>
							<Form.Control
								tpye='text'
								placeholder='Nhập tỉ lệ %'
								name='Fee'
								value={Fee}
								readOnly={checkBoxCost}
								onChange={onChangeNewAuxiliaryCostForm}
							/>
						</Form.Group>

						<Form.Group as={Col} controlId="formGridCost">
							<Form.Check
								type="radio"
								label="Số tiền"
								name="checkBoxCost"
								id="formHorizontalRadios2"
								checked={checkBoxCost}
								onChange={onChangeCheckBox}
							/>
							<Form.Control
								tpye='text'
								placeholder=''
								name='Cost'
								value={Cost}
								readOnly={checkBoxFee}
								onChange={onChangeNewAuxiliaryCostForm}
							/>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} controlId="formGridTaxRate">
							<Form.Check
								type="radio"
								label="Tỷ lệ(%)"
								name="checkBoxTaxRate"
								id="formHorizontalRadios3"
								checked={checkBoxTaxRate}
								onChange={onChangeCheckBox}
							/>
							<Form.Control
								tpye='text'
								placeholder='Nhập tỷ lệ %'
								name='TaxRate'
								value={TaxRate}
								readOnly={checkBoxTax}
								onChange={onChangeNewAuxiliaryCostForm}
							/>
						</Form.Group>

						<Form.Group as={Col} controlId="formGridTax">
							<Form.Check
								type="radio"
								label="Thuế"
								name="checkBoxTax"
								id="formHorizontalRadios4"
								checked={checkBoxTax}
								onChange={onChangeCheckBox}
							/>
							<Form.Control
								tpye='text'
								placeholder=''
								name='Tax'
								value={Tax}
								readOnly={checkBoxTaxRate}
								onChange={onChangeNewAuxiliaryCostForm}
							/>
						</Form.Group>
					</Form.Row>

					<Form.Group>
						<Form.Text id='sotien-help' muted as="h6">
							Tổng
						</Form.Text>
						<Form.Control
							tpye='text'
							placeholder=''
							name='CostTax'
							value={CostTax}
							readOnly
							onChange={onChangeNewAuxiliaryCostForm}
						/>
					</Form.Group>

					<Form.Group>
						<Form.Text id='ghichu-help' muted as="h6">
							Ghi chú
						</Form.Text>
						<Form.Control
							as='textarea'
							rows={2}
							placeholder='Nhập chuỗi'
							name='Note'
							value={Note}
							onChange={onChangeNewAuxiliaryCostForm}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Cập nhật
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}

export default Update_AuxiliaryCost_Cost_Modal
