import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


import { useContext, useState, useEffect } from 'react'
import { InvoiceContext } from '../../contexts/InvoiceContext'
import { useParams } from 'react-router-dom'

import { ProductCostContext } from '../../contexts/ProductCostContext'
import { AuthContext } from '../../contexts/AuthContext'

import axios from 'axios';
import { FileReaderInput } from 'react-file-reader';
import { apiUrl } from '../../contexts/constants'
import diacritics from 'diacritics';
const ImportInvoiceModal = () => {
	const params = useParams();
	// Contexts 
	const {
		InvoiceState: { Invoice, Invoices, InvoicesLoading },
		showImportInvoiceModal,
		setShowImportInvoiceModal,
		addInvoice,
		setShowToast,
		getInvoice_ContractID,
		_invoice, 
		updateInvoiceNew
	} = useContext(InvoiceContext)
	useEffect(() => getInvoice_ContractID(params.id), [])
	const [invalidFiles, setInvalidFiles] = useState([])
	const [selectedFiles, setSelectedFiles] = useState([]);

	const handleFileChangeNew = (event) => {
	    // setSelectedFiles([...selectedFiles, ...event.target.files]);
	    const files = event.target.files;
	    const validFormats = ['jpg', 'jpeg', 'png', 'pdf']; // Định dạng tệp cho phép
	    const validFiles = [];
	    const invalidFiles = [];

	    for (let i = 0; i < files.length; i++) {
	      const file = files[i];
	      const fileExtension = file.name.split('.').pop().toLowerCase();

	      if (validFormats.includes(fileExtension)) {
	        validFiles.push(file);
	      } else {
	        invalidFiles.push(file.name);
	      }
	    }
	    if(invalidFiles.length > 0) setShowToast({ show: true, message:"File "+invalidFiles.join(", ")+" is wrong format", type: 'danger' })
	    setSelectedFiles([...selectedFiles, ...validFiles]);
	    setInvalidFiles([...invalidFiles])
	};

	const handleUploadNew = async () => {
	    if (selectedFiles.length > 0) {
	        const formData = new FormData();
	        let string_file = '';
	        selectedFiles.forEach(file=>{
	        	let isValidUtf8 = checkUtf8Validity(file.name);
				let fileName = '';
				if(isValidUtf8){
					fileName = file.name;
				}else{
					fileName = removeVietnameseDiacritics(file.name);
				}
	        	formData.append('file', file,_invoice+"_"+fileName);
	        	string_file += _invoice+"_"+file.name+",";
	        })
	        string_file = string_file.substring(0,string_file.length -1);
	        try {
	        	let newImportInvoice = {
	        		id: _invoice,
	        		Document: string_file,
	        		ContractID: params.id
	        	};
	        	// console.log(newImportInvoice,'+++++++++++++')
	        	const { success, message } = await updateInvoiceNew(newImportInvoice);
	        	setTimeout(function(){
	        		setShowToast({ show: true, message, type: success ? 'success' : 'danger' });
	        		location.reload();
	        	},2000);

		        const response = await axios.post(`${apiUrl}/upload/`, formData, {
		           headers: {
		             'Content-Type': 'multipart/form-data',
		           },
		        });
	        // console.log(response,'=======')
	        // if(response.data.message == "Uploaded the file successfully"){
	        	// console.log(string_file,'selectedFilesselectedFiles')

	        // }

	      } catch (error) {
	        console.error('Error uploading file:', error);
	      }
	    }
	};

	function removeVietnameseDiacritics(str) {
	  const cleanedStr = diacritics.remove(str);
	  return cleanedStr.replace(/\s/g, ''); // Loại bỏ khoảng trắng
	}

	function checkUtf8Validity(input) {
	  for (let i = 0; i < input.length; ) {
	    const byte = input.charCodeAt(i);
	    let bytesToRead;

	    if (byte < 0x80) {
	      bytesToRead = 1;
	    } else if (byte < 0xE0) {
	      bytesToRead = 2;
	    } else if (byte < 0xF0) {
	      bytesToRead = 3;
	    } else if (byte < 0xF8) {
	      bytesToRead = 4;
	    } else {
	      // Invalid UTF-8 byte
	      return false;
	    }

	    for (let j = 1; j < bytesToRead; j++) {
	      if (i + j >= input.length || (input.charCodeAt(i + j) & 0xC0) !== 0x80) {
	        // Invalid UTF-8 sequence
	        return false;
	      }
	    }

	    i += bytesToRead;
	  }

	  return true;
	}

	const closeDialog = () => {
	    resetImportInvoiceData()
	}
    const resetImportInvoiceData = () => {
	    setShowImportInvoiceModal(false)
    }
	return (

		<Modal animation={false} show={showImportInvoiceModal} onHide={() => setShowImportInvoiceModal(false)}>
			<Modal.Header closeButton>
				<Modal.Title>Nhập chứng từ</Modal.Title>
			</Modal.Header>
			<Form>
				<Modal.Body>
					<div>
						<Form.Text id='Datetime-help' muted  as="h6">
							Chỉ chấp nhận file có định dạng .JPEG, .PNG, .JPG, .PDF
						</Form.Text>
						<input type="file" onChange={handleFileChangeNew} multiple/>
	      			</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' onClick={handleUploadNew}>
						Cập nhật
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}



export default ImportInvoiceModal




