import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { InvoiceContext } from '../../contexts/InvoiceContext'
import { ProductCostContext } from '../../contexts/ProductCostContext'
import { AuthContext } from '../../contexts/AuthContext'
import { useParams } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'

const UpdateInvoicesModal = () => {
	const params = useParams();
	const {
		InvoiceState: { Invoice, Invoices, InvoicesLoading },
		showUpdateInvoiceModal,
		setShowUpdateInvoiceModal,
		setShowToast,
		Data_update,
		getInvoice_ContractID,
		updateInvoice,
		editInvoiveVal
	} = useContext(InvoiceContext)
	useEffect(() => getInvoice_ContractID(params.id), [])
	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)
	let id_user = _id;

	const {
		ProductCostState: { ProductCost, ProductCosts, ProductCostsLoading },
		getProductCost_idContract,
		setShowAddProductCostModal
	} = useContext(ProductCostContext)
	useEffect(() => getProductCost_idContract(params.id), [])

	function Sum_OutputIntoMoney(ProductsCost) {
		let Total_OutputIntoMoney = 0;
		ProductsCost.map(Products =>
			Products.ListProducts.map(ListProduct => (
				Total_OutputIntoMoney += ListProduct.OutputIntoMoney
			)))

		return Total_OutputIntoMoney;
	}
	const TotalOutputIntoMoney = Sum_OutputIntoMoney(ProductCosts);
	// const [UpdateInvoice, setUpdateInvoice] = useState({
	// 	OutputIntoMoney: 0,
	// 	InvoiceVal: 0,
	// 	MonthOfInvoice: 0,
	// 	CreateDateOfInvoice: '',
	// 	PercentInvoice: 0,
	// 	Document: '',
	// 	UserID: '',
	// 	ContractID: '',
	// })
	// State
	const [UpdateInvoice, setUpdateInvoice] = useState(Data_update)
	const [newCheckBox, setCheckBox] = useState('')
	useEffect(() => setUpdateInvoice(Data_update),[Data_update])
	useEffect(() => setCheckBox(Data_update),[Data_update])
	let {
		OutputIntoMoney,
        InvoiceVal,
        DateOfInvoice, 
        MonthOfInvoice, 
        CreateDateOfInvoice, 
        PercentInvoice,
        Document,
        UserID,
        ContractID
	} = UpdateInvoice
	console.log(newCheckBox,'newCheckBox')
	function Sum_Invoice(Invoice,val) {
		let Total_Invoice = 0;
		Invoice.map(Invoices => {
			Total_Invoice += Invoices.InvoiceVal
		});
		if(val != undefined) return Total_Invoice - val;
	}
	const Total_Invoice = Sum_Invoice(Invoices,editInvoiveVal);

	// const [newCheckBox, setCheckBox] = useState({
	// 	checkBoxPercentInvoice: true,
	// 	checkBoxCost: false,
	// })

	const {
		checkBoxPercentInvoice,
		checkBoxCost,
	} = newCheckBox
	// console.log(newCheckBox,'newCheckBoxnewCheckBox')
	const closeDialog = () => {
		setUpdateInvoice(UpdateInvoice)
		setShowUpdateInvoiceModal(false)
	}

	const resetUpdateInvoiceData = () => {
		setUpdateInvoice({
			OutputIntoMoney: '',
			InvoiceVal: '',
			DateOfInvoice: '',
			MonthOfInvoice: '',
			CreateDateOfInvoice: '',
			PercentInvoice: '',
			Document: '',
			UserID: '',
			ContractID: '',

		}) //note cần sửa
		setShowUpdateInvoiceModal(false)
	}
	console.log(newCheckBox,'newCheckBoxnewCheckBox')
	const onChangeCheckBox = event => {
		const name = event.target.name;
		const val = event.target.value
		if (name == "checkBoxPercentInvoice" && val == "on") {
			const UpdateInvoiceVal= (val * TotalOutputIntoMoney)/100;
			// console.log(UpdateInvoiceVal,'UpdateInvoiceValUpdateInvoiceVal');
			setCheckBox({ ...newCheckBox, 'checkBoxPercentInvoice': true, 'checkBoxCost': false})
			// setUpdateInvoice({ ...UpdateInvoice, 'InvoiceVal': UpdateInvoiceVal, 'PercentInvoice': PercentInvoice})
		}else if (name == "checkBoxCost" && val == "on") {
			setCheckBox({ ...newCheckBox, 'checkBoxPercentInvoice': false, 'checkBoxCost': true })
			// setUpdateInvoice({ ...UpdateInvoice, 'PercentInvoice': '', 'InvoiceVal': value})
		}
	}

	const onChangeUpdateInvoiceForm = event =>{
		const name = event.target.name;
		const val = event.target.value;
		if (name == "PercentInvoice") {
			if (!isNumberOrDecimal(val) && val != '') {
				var message = 'Vui lòng nhập tỉ lệ % không quá 2 số thập phận'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				var value = (event.target.value != "") ? event.target.value : 0;
				if (parseInt(event.target.value) > 100 && parseFloat(event.target.value) > 100) {
					var message = 'Vui lòng nhập tỷ lệ không quá 100%'
					setShowToast({ show: true, message: message, type: 'danger' })
					return false;
				}
				let UpdateInvoiceVal= (val * TotalOutputIntoMoney)/100;
				setUpdateInvoice({ ...UpdateInvoice, 'InvoiceVal': UpdateInvoiceVal, 'PercentInvoice': val})
			}
		}else if (name == "InvoiceVal") {
			if (!isParentNumber(val) && val != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				setUpdateInvoice({ ...UpdateInvoice, [event.target.name]: event.target.value })
				// let UpdateInvoiceVal= (val / TotalOutputIntoMoney)*100;
				// setUpdateInvoice({ ...UpdateInvoice, 'InvoiceVal': val, 'PercentInvoice': UpdateInvoiceVal != 0 ? UpdateInvoiceVal.toFixed(2) : ''})
			}
		}else if(name == 'MonthOfInvoice'){
			if (!isParentNumber(val) && val != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				setUpdateInvoice({ ...UpdateInvoice, 'MonthOfInvoice': parseInt(val)})
			}
		}else if(name == 'DateOfInvoice'){
			setUpdateInvoice({ ...UpdateInvoice, 'DateOfInvoice': val})
		}else{
			setUpdateInvoice({ ...UpdateInvoice, [event.target.name]: event.target.value })
		}	
	}

	const onSubmit = async event => {
		event.preventDefault()
		let remain_invoive = TotalOutputIntoMoney - Total_Invoice;
		if(UpdateInvoice['InvoiceVal'] <= remain_invoive){
			const { success, message } = await updateInvoice(UpdateInvoice)
			resetUpdateInvoiceData()
			setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
		}else{
			let message = 'Vượt quá giá trị doanh thu của hợp đồng';
			setShowToast({ show: true, message, type: 'danger'})
		}
	}
	const isNumberOrDecimal = userInput => {
		// if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		// return /^\d+$/.test(userInput); ok
  		// return /((?:\d+\.\d*)|(?:\d*\.?\d+))$/.test(userInput); ok
  		// return /^\d*\.?\d*$/.test(userInput) //ok
  		return /^\d+(\.\d{0,2})?$/.test(userInput)
	}
	const isParentNumber = userInput => {
		if (typeof userInput !== 'string') return false;
		return /^\d+$/.test(userInput);
	}

	const isPatternNumber = userInput => {
		if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		return /^\d+(\.\d{0,2})?$/.test(userInput);
	}
	function convert_time(){
		var date = new Date(); // Replace this with your actual date object
		var year = date.getFullYear();
		var month = ('0' + (date.getMonth() + 1)).slice(-2);
		var day = ('0' + date.getDate()).slice(-2);
		var hours = ('0' + date.getHours()).slice(-2);
		var minutes = ('0' + date.getMinutes()).slice(-2);
		var seconds = ('0' + date.getSeconds()).slice(-2);
		var formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		return formattedDate;
	}

	function convert_date(date_time){
		const date = new Date(date_time); // Replace this with your actual date object
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		const seconds = ('0' + date.getSeconds()).slice(-2);
		const formattedDate = `${year}-${month}-${day}`;
		return formattedDate;
	}
	return (
		<Modal animation={false} show={showUpdateInvoiceModal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>Chỉnh sửa hóa đơn</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<Form.Group>
						<Form.Text id='Note-help' muted as='h6'>
							Số tiền
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Số tiền'
							name='InvoiceVal'
							required
							value={InvoiceVal}
							onChange={onChangeUpdateInvoiceForm}
						/>
					</Form.Group>
					

					{/*<Form.Group>
						<Form.Text id='noidung-help' muted as="h6">
							Tháng xuất hóa đơn
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Nhập số'
							name='MonthOfInvoice'
							required
							value={MonthOfInvoice}
							aria-describedby='noidung-help'
							onChange={onChangeUpdateInvoiceForm}
						/>
					</Form.Group>*/}
					<Form.Group>
						<Form.Text id='Datetime-help' muted  as="h6">
						Ngày… Tháng… Năm…  xuất hóa đơn 
						</Form.Text>
						<Form.Control
							type='date'
							placeholder=''
							name='DateOfInvoice'
							value={convert_date(DateOfInvoice)}
							onChange={onChangeUpdateInvoiceForm}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Cập nhật
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}



export default UpdateInvoicesModal




