import React from "react";
import "./notification.css";

export const NotificationModal = ({ onClose, show, data }) => {
	console.log( onClose, show, data )
    if (!show) {
        return null;
    }
    return (
        <div className="modal-notification">
            <div className="content">
                <h4>Modal title</h4>
                <div>Modal content here...</div>
                <div className="footer">
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
};
