import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Landing from './components/layout/Landing'
import ProtectedRoute from './components/routing/ProtectedRoute'
//====== Components

import Auth from './views/Auth'
import { User, UserInfo, ChangePassword} from './views/User'
import About from './views/About'
import Config from './views/Config'
import UserGuide from './views/UserGuide'
import Contact from './views/Contact'
import { Dashboard } from './views/Dashboard'
import {Summary,Summary_id} from './views/SummaryView'
import {InputForm} from './views/InputForm'
import {Invoice} from './views/Invoice'
import { Report, Report_Contract_Revenue, Report_Manday_Costs, Report_Internal_Revenue} from './views/Report'
import { ContractView,Inputforms,Contract_id} from './views/ContractView'
import Customer from './views/ConfigCustomer'
import Department from './views/ConfigDepartment'
import RemoveContract from './views/RemoveContract'
import CancelContract from './views/CancelContract'
import Center from './views/ConfigCenter'
import Home from './views/Home'
import NotFound from './views/NotFound'

import 'bootswatch/dist/minty/bootstrap.min.css'; // Added this :boom:


//12-3
import {ProductCost_all,ProductCost_idContract,Products_idContract} from './views/ProductCostView'


import {MandayCost_all,MandayCost_idContract} from './views/MandayCostView'
import {LoanInterest_idContract} from './views/LoanInterestView'
import {GuaranteeLetterCost_all, GuaranteeLetterCost_idContract} from './views/GuaranteeLetterCostView'
import {MiscExpenseCost_all,MiscExpenseCost_byidContract} from './views/MiscExpenseCostView'
import {CapitalExpenditureCost_all,CapitalExpenditureCost_byidContract} from './views/CapitalExpenditureCostView'

import {Implementation_Cost_all, Implementation_Cost_byidContract} from './views/ImplementationCostView'

import {AuxiliaryCost_all,AuxiliaryCost_byidContract,AuxiliaryCost_Contract} from './views/AuxiliaryCostView'
//=====contexts
import AuthContextProvider from './contexts/AuthContext'
import ContractContextProvider from './contexts/ContractContext'
import MiscExpenseCostContextProvider from './contexts/MiscExpenseContext'
import GuaranteeLetterCostContextProvider from './contexts/GuaranteeLetterCostContext'
import MandayCostContextProvider from './contexts/MandayCostContext'
import UserContextProvider from './contexts/UserContext'
import ProductCostContextProvider from './contexts/ProductCostContext'
import ImplementationCostContextProvider from './contexts/ImplementationCostContext'
import AuxiliaryCostContextProvider from './contexts/AuxiliaryCostContext'
import CapitalExpenditureContextProvider from './contexts/CapitalExpenditureCostContext'
import TwoOptionCostContextProvider from './contexts/TwoOptionCostContext'
import LoanInterestContextProvider from './contexts/LoanInterestContext'
import ConfigDepartmentContextProvider from './contexts/ConfigDepartmentContext'
import ConfigCustomerContextProvider from './contexts/ConfigCustomerContext'
import ConfigCenterContextProvider from './contexts/ConfigCenterContext'
import MenuContextProvider from './contexts/MenuContext'
import RoleContextProvider from './contexts/RoleContext'
import HistoryContextProvider from './contexts/HistoryContext'
import EventContextProvider from './contexts/EventContext'
import UserManagerContextProvider from './contexts/UserManagerContext'
import UserPageContextProvider from './contexts/UserPageContext'

import AboutContextProvider from './contexts/AboutContextProvider'
import UserGuideContextProvider from './contexts/UserGuideContextProvider'
import ConfigContextProvider from './contexts/ConfigContextProvider'
import RemoveContractContextProvider from './contexts/RemoveContractContext'
// import CancelContractContextProvider from './contexts/CancelContractContext'
import InvoiceContextProvider from './contexts/InvoiceContext'
import HistoryChangeContextProvider from './contexts/HistoryChangeContext'
import NotificationContextProvider from './contexts/NotificationContext'
import AccessDenied from './views/AccessDenied'

function App() {
	return (
		<>
		
		<AuthContextProvider>
			<MenuContextProvider>
			<UserPageContextProvider>
			<UserManagerContextProvider>
			<RoleContextProvider>
				<HistoryChangeContextProvider>
					<ContractContextProvider>
						<NotificationContextProvider>
							<UserContextProvider>
								<HistoryContextProvider>
								<Router>
									<Switch>
										<Route exact path='/' component={Landing} />
										<Route
											exact
											path='/login'
											render={props => <Auth {...props} authRoute='login' />}
										/>
										<Route
											exact
											path='/register'
											render={props => <Auth {...props} authRoute='register' />}
										/>

										<>
										<ProductCostContextProvider>
											<ConfigCustomerContextProvider>
												<RemoveContractContextProvider>
													<ContractContextProvider>
															<ProtectedRoute exact path='/product-cost' component={ProductCost_all} />
															{/* <ProtectedRoute exact path='/product-cost/contract/:id' component={ProductCost_idContract} /> */}
															<ProtectedRoute exact path='/product-cost/contract/:idcontract' component={Products_idContract} />
													</ContractContextProvider>
												</RemoveContractContextProvider>
											</ConfigCustomerContextProvider>
										</ProductCostContextProvider>
										
										<ContractContextProvider>
											<ConfigCustomerContextProvider>
												<ImplementationCostContextProvider>
													<ProtectedRoute exact path='/implementation-cost/' component={Implementation_Cost_all} />
													<ProtectedRoute exact path='/implementation-cost/contract/:id' component={Implementation_Cost_byidContract} />
												</ImplementationCostContextProvider>
											</ConfigCustomerContextProvider>
										</ContractContextProvider>

										<ContractContextProvider>
											<ConfigCenterContextProvider>
												<ConfigDepartmentContextProvider>
													<UserContextProvider>
														<ProtectedRoute exact path='/user' component={User} />
														<ProtectedRoute exact path='/user-info' component={UserInfo} />
														<ProtectedRoute exact path='/change-password' component={ChangePassword} />
													</UserContextProvider>
												</ConfigDepartmentContextProvider>
											</ConfigCenterContextProvider>
										</ContractContextProvider>
										
										
										<ContractContextProvider>
											<ProtectedRoute exact path='/contract' component={ContractView} />
											<ProtectedRoute exact path='/contract/forms' component={Inputforms} />
											<ProtectedRoute exact path='/contract/forms/:id' component={Contract_id}/>
										</ContractContextProvider>

										
										<ProductCostContextProvider>
											<ConfigCustomerContextProvider>
												<ContractContextProvider>
													<CapitalExpenditureContextProvider>
														<ProtectedRoute exact path='/CapitalExpenditureCost' component={CapitalExpenditureCost_all} />
														<ProtectedRoute exact path='/CapitalExpenditureCost/contract/:id' component={CapitalExpenditureCost_byidContract} />
													</CapitalExpenditureContextProvider>
													<LoanInterestContextProvider>
														<ProtectedRoute exact path='/LoanInterest/contract/:id' component={LoanInterest_idContract}/>
													</LoanInterestContextProvider>
												</ContractContextProvider>
											</ConfigCustomerContextProvider>
										</ProductCostContextProvider>

										<ContractContextProvider>
											<InvoiceContextProvider>
												<ConfigCustomerContextProvider>
													<ImplementationCostContextProvider>
														<LoanInterestContextProvider>
															<CapitalExpenditureContextProvider>
																<MiscExpenseCostContextProvider>
																	<GuaranteeLetterCostContextProvider>
																		<MandayCostContextProvider>
																				<ProductCostContextProvider>
																					<AuxiliaryCostContextProvider>
																						<ProtectedRoute exact path='/AuxiliaryCost' component={AuxiliaryCost_Contract} />
																						{/* <ProtectedRoute exact path='/AuxiliaryCost/contract/:id' component={AuxiliaryCost_Plan} /> */}
																						<ProtectedRoute exact path='/AuxiliaryCost/contract/:id' component={AuxiliaryCost_byidContract} />
																					</AuxiliaryCostContextProvider>
																				</ProductCostContextProvider>
																		</MandayCostContextProvider>
																	</GuaranteeLetterCostContextProvider>
																</MiscExpenseCostContextProvider>
															</CapitalExpenditureContextProvider>
														</LoanInterestContextProvider>
													</ImplementationCostContextProvider>
												</ConfigCustomerContextProvider>
											</InvoiceContextProvider>
										</ContractContextProvider>

										<ContractContextProvider>
											<InvoiceContextProvider>
												<ConfigCustomerContextProvider>
													<ConfigDepartmentContextProvider>
														<MandayCostContextProvider>
															<ProtectedRoute exact path='/manday-cost' component={MandayCost_all} />
															<ProtectedRoute exact path='/manday-cost/contract/:id' component={MandayCost_idContract}/>
														</MandayCostContextProvider>
													</ConfigDepartmentContextProvider>
													<MiscExpenseCostContextProvider>
														<ProtectedRoute exact path='/MiscExpenseCost' component={MiscExpenseCost_all} /> 
														<ProtectedRoute exact path='/MiscExpenseCost/contract/:id' component={MiscExpenseCost_byidContract} />
													</MiscExpenseCostContextProvider>
													<GuaranteeLetterCostContextProvider>
														<ProtectedRoute exact path='/guarantee-letter-cost' component={GuaranteeLetterCost_all} />
														<ProtectedRoute exact path='/guarantee-letter-cost/contract/:id' component={GuaranteeLetterCost_idContract} />
													</GuaranteeLetterCostContextProvider>
												</ConfigCustomerContextProvider>
											</InvoiceContextProvider>
										</ContractContextProvider>

										<RemoveContractContextProvider>
											<InvoiceContextProvider>
												<HistoryContextProvider>
													<EventContextProvider>
														<UserManagerContextProvider>
															<UserPageContextProvider>
																<TwoOptionCostContextProvider>
																	<ConfigDepartmentContextProvider>
																		<ConfigCustomerContextProvider>
																			<ConfigCenterContextProvider>
																				<ImplementationCostContextProvider>
																					<AuxiliaryCostContextProvider>
																					<CapitalExpenditureContextProvider>
																					<LoanInterestContextProvider>
																					<MiscExpenseCostContextProvider>
																					<GuaranteeLetterCostContextProvider>
																					<MandayCostContextProvider>
																					<UserContextProvider>
																					<ContractContextProvider>
																					<ProductCostContextProvider>
																					<InvoiceContextProvider>
																						<ProtectedRoute exact path='/summary' component={Summary}/>
																						<ProtectedRoute exact path='/summary/:id' component={Summary_id}/>
																						<ProtectedRoute exact path='/inputform/:id' component={InputForm}/>
																					</InvoiceContextProvider>
																					</ProductCostContextProvider>
																					</ContractContextProvider>
																					</UserContextProvider>
																					</MandayCostContextProvider>
																					</GuaranteeLetterCostContextProvider>
																					</MiscExpenseCostContextProvider>
																					</LoanInterestContextProvider>
																					</CapitalExpenditureContextProvider>
																					</AuxiliaryCostContextProvider>
																				</ImplementationCostContextProvider>
																			</ConfigCenterContextProvider>
																		</ConfigCustomerContextProvider>
																	</ConfigDepartmentContextProvider>
																</TwoOptionCostContextProvider>
															</UserPageContextProvider>
														</UserManagerContextProvider>
													</EventContextProvider>
												</HistoryContextProvider>
											</InvoiceContextProvider>
										</RemoveContractContextProvider>
										
										<HistoryContextProvider>
											<EventContextProvider>
												<UserManagerContextProvider>
													<ConfigDepartmentContextProvider>
														<ConfigCenterContextProvider>
															<ImplementationCostContextProvider>
																<AuxiliaryCostContextProvider>
																<CapitalExpenditureContextProvider>
																<LoanInterestContextProvider>
																<MiscExpenseCostContextProvider>
																<GuaranteeLetterCostContextProvider>
																<MandayCostContextProvider>
																<UserContextProvider>
																<ContractContextProvider>
																<ProductCostContextProvider>
																	<ProtectedRoute exact path='/dashboard' component={Dashboard} />
																</ProductCostContextProvider>
																</ContractContextProvider>
																</UserContextProvider>
																</MandayCostContextProvider>
																</GuaranteeLetterCostContextProvider>
																</MiscExpenseCostContextProvider>
																</LoanInterestContextProvider>
																</CapitalExpenditureContextProvider>
																</AuxiliaryCostContextProvider>
															</ImplementationCostContextProvider>
														</ConfigCenterContextProvider>
													</ConfigDepartmentContextProvider>
												</UserManagerContextProvider>
											</EventContextProvider>
										</HistoryContextProvider>

										<HistoryContextProvider>
											<EventContextProvider>
												<ConfigCustomerContextProvider>
													<ConfigDepartmentContextProvider>
														<ConfigCenterContextProvider>
															<ImplementationCostContextProvider>
																<AuxiliaryCostContextProvider>
																<CapitalExpenditureContextProvider>
																<LoanInterestContextProvider>
																<MiscExpenseCostContextProvider>
																<GuaranteeLetterCostContextProvider>
																<MandayCostContextProvider>
																<UserContextProvider>
																<ContractContextProvider>
																<ProductCostContextProvider>
																<InvoiceContextProvider>
																	<ProtectedRoute exact path='/report/synthetic' component={Report}/>
																	<ProtectedRoute exact path='/report/contract_revenue' component={Report_Contract_Revenue}/>
																	<ProtectedRoute exact path='/report/manday_costs' component={Report_Manday_Costs}/>
																	<ProtectedRoute exact path='/report/internal_revenue' component={Report_Internal_Revenue}/>
																</InvoiceContextProvider>
																</ProductCostContextProvider>
																</ContractContextProvider>
																</UserContextProvider>
																</MandayCostContextProvider>
																</GuaranteeLetterCostContextProvider>
																</MiscExpenseCostContextProvider>
																</LoanInterestContextProvider>
																</CapitalExpenditureContextProvider>
																</AuxiliaryCostContextProvider>
															</ImplementationCostContextProvider>
														</ConfigCenterContextProvider>
													</ConfigDepartmentContextProvider>
												</ConfigCustomerContextProvider>
											</EventContextProvider>
										</HistoryContextProvider>
																	
										<ProtectedRoute exact path='/about' component={About} />
										<ProtectedRoute exact path='/config' component={Config} />
										<ProtectedRoute exact path='/userguide' component={UserGuide} />
										<ProtectedRoute exact path='/contact' component={Contact} />
										<ProtectedRoute exact path='/home' component={Home} />

										<UserContextProvider>
											<ContractContextProvider>
												<ProductCostContextProvider>
													<ConfigCustomerContextProvider>
														<ConfigCenterContextProvider>
															<InvoiceContextProvider>
																<ProtectedRoute exact path='/invoiceForm/:id' component={Invoice}/>
															</InvoiceContextProvider>
														</ConfigCenterContextProvider>
													</ConfigCustomerContextProvider>
												</ProductCostContextProvider>
											</ContractContextProvider>
										</UserContextProvider>
								
										<ConfigCustomerContextProvider>
											<ProtectedRoute exact path='/config/customer' component={Customer} />
										</ConfigCustomerContextProvider>
									

										<ConfigDepartmentContextProvider>
											<ConfigCenterContextProvider>
												<ProtectedRoute exact path='/config/department' component={Department} />
											</ConfigCenterContextProvider>
										</ConfigDepartmentContextProvider>

										<ConfigCenterContextProvider>
											<ProtectedRoute exact path='/config/center' component={Center} />
										</ConfigCenterContextProvider>

										<ContractContextProvider>
											<RemoveContractContextProvider>
												<ProtectedRoute exact path='/removeContract' component={RemoveContract} />
											</RemoveContractContextProvider>
										</ContractContextProvider>

										<UserContextProvider>
											<HistoryContextProvider>
										{/*<ContractContextProvider>
											<CancelContractContextProvider>*/}
												<ProtectedRoute exact path='/cancelContract' component={CancelContract} />
											{/*</CancelContractContextProvider>
										</ContractContextProvider>*/}
											</HistoryContextProvider>
										</UserContextProvider>


										<ProtectedRoute exact path='/access-denied' component={AccessDenied} />
										</>
									</Switch>
								</Router>
								</HistoryContextProvider>
							</UserContextProvider>
						</NotificationContextProvider>
					</ContractContextProvider>
				</HistoryChangeContextProvider>
			</RoleContextProvider>
			</UserManagerContextProvider>
			</UserPageContextProvider>
		</MenuContextProvider>
		</AuthContextProvider>
		</>
	)
}
export default App
