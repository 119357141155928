import {
	LOADED_SUCCESS,
	LOADED_FAIL,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from '../contexts/constants'

export const LoanInterestReducer = (state, action) => {
	const { type, payload } = action
	switch (type) {
		case LOADED_SUCCESS:
			return {
				...state,
				LoanInterests: payload,
				LoanInterestsLoading: false
			}

		case LOADED_FAIL:
			return {
				...state,
				LoanInterests: [],
				LoanInterestsLoading: false
			}

		case ADD:
			return {
				...state,
				LoanInterests: [...state.LoanInterests, payload]
			}

		case DELETE:
			return {
				...state,
				LoanInterests: state.LoanInterests.filter(LoanInterest => LoanInterest._id !== payload)
			}

		case FIND:
			return { ...state, LoanInterest: payload }

		case UPDATE:
			
			const newLoanInterests = state.LoanInterests.map(LoanInterest =>
				LoanInterest._id === payload._id ? payload : LoanInterest
			)

			return {
				...state,
				LoanInterests: newLoanInterests
			}

		default:
			return state
	}
}
