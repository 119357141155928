import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { useParams, Link } from 'react-router-dom'
import { useContext, useEffect, useState} from 'react'
import Spinner from 'react-bootstrap/Spinner'

import { AuthContext } from '../contexts/AuthContext'
import { UserContext } from '../contexts/UserContext'
import { UserPageContext } from '../contexts/UserPageContext'
import { MenuContext } from '../contexts/MenuContext'
import { UserManagerContext } from '../contexts/UserManagerContext'

const UserGuide = () => {

	const {
		authState: {
			user: { username,roles,_id }
		}
	} = useContext(AuthContext)

	let UserID = _id;
				// <div style={{marginTop:'20px'}}>
				// 	QLHĐ cung cấp một nền tảng tổ chức, quản lý và theo dõi hợp đồng hiệu quả, giúp tăng cường hiệu suất làm việc, giảm thiểu rủi ro pháp lý và cải thiện quy trình quản lý hợp đồng.
				// </div>
	const {
		UserPageState: { UserPage, UserPages, UserPagesLoading },
		getAllUserPages
	} = useContext(UserPageContext)

	const {
	    MenuState: { Menu, Menus, MenusLoading },
	    getMenu,
	} = useContext(MenuContext);

	function FindArrayMenu(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return null;
		}
	}

	if (UserPagesLoading && MenusLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	}else {
		const result = UserPages.filter(obj => { return obj.user_id.some(id => id === UserID); });
		let menu_access = false
		if (result.length > 0) {
			for (var j = 0; j < result.length; j++) {
				let menuV1 = FindArrayMenu(Menus, result[j].page_id[0])
				if (menuV1 != null && menuV1.path == location.pathname) {
					// console.debug("menuV1 co quyen truy cap")
					menu_access = true
					break;
				}else {
					// console.debug("menuV1 khong quyen truy cap")
				}
			}
		} else {
			// console.debug("menu khong quyen truy cap")
		}

		if (menu_access) {
			return (
				<Row className='mt-5' style={{ marginRight: 0 }}>
					<Col className='text-center'>
						<h3 className = 'headerAbout'>Hướng dẫn sử dụng</h3>
					</Col>
				</Row>
			)
		}else {
			return (
				<>
					<div id="notfound">
						<div className="notfound">
							<h1>Oops!</h1>
							<h6>Rất tiếc, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ với Quản trị viên.
								<br/>
								<Link style={{textDecoration: "none"}} to="/home">
									Trở về trang chủ
								</Link>
							</h6>
						</div>
					</div>
				</>
			)
		}
	}

	

}

export default UserGuide
