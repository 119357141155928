import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useContext, useState, useEffect } from 'react'
import { ImplementationCostContext } from '../../contexts/ImplementationCostContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'
import { AuthContext } from '../../contexts/AuthContext'
import { useParams } from 'react-router-dom'
import '../App.css'
import * as XLSX from 'xlsx';

const ImportCostDetailModal = () => {
	const [selectedFile, setSelectedFile] = useState(null);
    const handleFileUpload = (event) => {
    	setSelectedFile(event.target.files[0]);
    };
    const params = useParams();
    const {
	    ImplementationCostState: { ImplementationCosts, ImplementationCostsLoading },
	    getImplementation_Costs_byidContract,
	    showImportCostDetailModal,
	    setshowImportCostDetailModal,
	    Data_Click_Import_On_Button,
	    import_CostDetail_Function,
	    showToast: { show, message, type },
	    setShowToast
	} = useContext(ImplementationCostContext);

	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	const element = Data_Click_Import_On_Button;
	// console.log(Data_Click_Import_On_Button, "Data_Click_Import_On_Button")
	// if (element.length > 0 ) element.map((obj => {console.log(obj)}))
	let [selectedValue, setSelectedValue] = useState('');
	let [showChild, setShowChild] = useState('');
	let [showDataChild, setDataShowChild] = useState({});
	let [showContentCostId, setContentCostId] = useState('');

	// const [newStageImplementation, setnewStageImplementation] = useState({
	// 	NameCost: '',
	// 	Units: '',
	// 	UnitPrice: '',
	// 	Quantity_days: '',
	// 	Quantity_times: '',
	// 	IntoMoney: '',
	// 	Note: '',
	// 	ImplementationCost_Id: '',
	// 	ContentCostId: '',
	// 	ContentCost:''
	// })
	// const { 
	// 	NameCost,
	// 	Units,
	// 	UnitPrice,
	// 	Quantity_days,
	// 	Quantity_times,
	// 	IntoMoney,
	// 	Note,
	// 	Implementation_Cost_Id,
	// 	ContentCostId,
	// 	ContentCost
	// } = newStageImplementation

	// const [selectedFile, setSelectedFile] = useState();
	// const [isFilePicked, setIsFilePicked] = useState(false);

	// const changeHandler = (event) => {
	// 	setSelectedFile(event.target.files[0]);
	// 	setIsSelected(true);
	// };

	function handleSelectChange(event) {
		let name = event.target.name;
		let value = event.target.value;
		var dataSelect = findArrID(value).StagesImplementation;
		console.log("handleSelectChange", name, value, dataSelect)

		if (name == "ContentCostId") {
			setContentCostId(value)
		}else {
			

			if (value != "") {
				if (value == "a_chi_phi_chung") {
					setShowChild(1);
				}else {
					setShowChild(2);
				}
			}else {
				setShowChild('');
			}
			setSelectedValue(value);
			setDataShowChild(dataSelect);
		}
	}

	const closeDialog = () => {
	    setshowImportCostDetailModal(false)
	    setShowChild('');
	    setSelectedValue('')
	    setDataShowChild({})
	    setContentCostId('')
	}

	const handleImport = () => {
		if (selectedValue == "") {
			setShowToast({ show: true, message :'Vui lòng chọn chi phí', type:'danger'}) 
			return false
		}

		if (selectedFile == null) {
			setShowToast({ show: true, message :'Vui lòng chọn file import', type:'danger'}) 
			return false
		}

		var Implementation_Cost_Id = findArrID(selectedValue)._id;
		var ContentCost = selectedValue;
		var ContentCostId = findArrContentCostID(showDataChild , showContentCostId)._id;
		var newImplementation = {
		    "NameCost": "",
		    "Units": "",
		    "UnitPrice": "",
		    "Quantity_days": "",
		    "Quantity_times": "",
		    "IntoMoney": "",
		    "Note": "",
		    "ContentCostId": ContentCostId,
		    "ContentCost": ContentCost,
		    "Implementation_Cost_Id": Implementation_Cost_Id
		}

		const reader = new FileReader();
	    const rABS = !!reader.readAsBinaryString;
	    reader.onload = async e => {
	        const bstr = e.target.result;
	        const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
	        const wsname = wb.SheetNames[0];
	        const ws = wb.Sheets[wsname];
	        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
	        let flag = false;
	        for (let i = 2; i < data.length; i++) {
		        let value = data[i];
		        if(value != ''){
					let NameCost = value[0] != undefined ? value[0] : '';
					let Units = value[1] != undefined ? value[1] : '';
					let UnitPrice = value[2] != undefined ? isNumericString(value[2]) : 0;
					let Quantity_times = value[3] != undefined ? isNumericString(value[3]) : 0;
					let Quantity_days = value[4] != undefined ? isNumericString(value[4]) : 0;
					let Note = value[5];
					newImplementation.NameCost = NameCost;
					newImplementation.Units = Units;
					newImplementation.UnitPrice = UnitPrice;
					newImplementation.Quantity_times = Quantity_times;
					newImplementation.Quantity_days = Quantity_days;
					newImplementation.Note = Note;
					const implementationCostResult = await import_CostDetail_Function(newImplementation, params.id)
					if(implementationCostResult.success == true){
						flag = true;
					}
		        }
	        }

	        if(flag == true){
	        	setShowToast({ show: true, message :'Nhập file excel thành công', type:'success'})
	        }else{
	            setShowToast({ show: true, message :'Nhập file excel không thành công', type:'danger'})
	        }
	        const historyChangeProductCost = {
			    "requester": _id,
			    "contract": params.id,
			    "form": "implementation-cost",
			    "action": "import",
			    "data_change": "import implementation"
			}
			const { successHis, messageHis } = await addHistoryChange(historyChangeProductCost)
	        setTimeout(() => {location.reload();},1000);
	    };

	    if (rABS) reader.readAsBinaryString(selectedFile);
	    else reader.readAsArrayBuffer(selectedFile);
	}

	const findArrID = (Category) => {
		let _value = "";
		element.map(function (value) {
			if (value.Category == Category) {
				_value = value
			}
		})

		return _value
	}

	const findArrContentCostID = (ele, Content) => {
		let _value = "";
		ele.map(function (value) {
			if (value.Content == Content) {
				_value = value
			}
		})

		return _value
	}

	function isNumericString(str) {
	    // Kiểm tra nếu chuỗi không tồn tại hoặc rỗng
	    if (!str || str.length === 0) {
	    	return 0;
	    }
	    // Kiểm tra xem chuỗi có chỉ chứa số hay không
	    let regrex = /^\d+$/.test(str);
	    if(regrex == true){
	    	return str;
	    }else{
	    	return 0;
	    }
	}


	return (
		<Modal show={showImportCostDetailModal} onHide={() => setshowImportCostDetailModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Nhập từ file excel</Modal.Title>
            </Modal.Header>
            <Form>
	            <Modal.Body>
	            	<Form.Group>
						<Form.Label id='CategoryID-help'>Chọn chi phí</Form.Label>
						<Form.Control 
							id = 'chiphi-help'
							as="select" custom
							name='CategoryID'
							aria-describedby='chiphi-help'
							value={selectedValue}
							onChange={handleSelectChange}
							require
							>
		{/*					<option>---chọn---</option>
							<option>A. CHI PHÍ CHUNG</option>
							<option>B. CHI PHI TRIỂN KHAI</option>*/}
						<option value="">---chọn---</option>
						{
							element.length > 0 ? 
							element.map((obj => {
								return (
									<>
										<option value={obj.Category}>{obj.Category != '' ? (obj.Category == "a_chi_phi_chung") ? "A. CHI PHÍ CHUNG" : "B. CHI PHI TRIỂN KHAI" : '-----'}</option>
									</>
								)
							}))
							:
							<></>
						}
						</Form.Control>
					</Form.Group>
					{(showChild != "") && (
				    	<Form.Group id='ContentCostId-help' style={{left: "30px", position: "relative", width: "calc(100% - 30px)"}}>
							<Form.Label>Chọn giai đoạn:</Form.Label>
							<Form.Control 
								as="select"
								custom
								id = 'giaidoan-help'
								name='ContentCostId'
								aria-describedby='giaidoan-help'
								value={showContentCostId}
								onChange={handleSelectChange}
								>
								{
									showDataChild.length > 0 ? 
									showDataChild.map((obj => {
										return (
											<>
												<option value={obj.Content}>{obj.Content != '' ? obj.Content : showChild == 1 ? "A. CHI PHÍ CHUNG" : "B. CHI PHI TRIỂN KHAI"}</option>
											</>
										)
									}))
									:
									<></>
								}
							</Form.Control>
						</Form.Group>
				    )}
	                <Form.Group style={{top: "10px", position: "inherit"}}>
		                <div>
		                    <input type="file" id="csvFileInput" style={{ display: 'none' }} onChange={handleFileUpload} />
		                    <label htmlFor="csvFileInput">
		                    <input type="file" accept=".xlsx" onChange={handleFileUpload} />
		                    </label>
		                </div>
	                </Form.Group>
	            </Modal.Body>
                <Modal.Footer>
		           <Button variant='secondary' onClick={closeDialog}>Hủy</Button>
           			<Button variant='primary' onClick={handleImport}>Import</Button>
           		</Modal.Footer>
            </Form>
        </Modal>
	)
}

export default ImportCostDetailModal