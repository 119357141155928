import Button from 'react-bootstrap/Button'
import playIcon from '../../assets/play-btn.svg'
import editIcon from '../../assets/pencil.svg'
import deleteIcon from '../../assets/trash.svg'
import { LoanInterestContext } from '../../contexts/LoanInterestContext'
import { useContext } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { useParams } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'

const ActionButtons_LoanInterest = ({ _idLoanInterest }) => {
	const params = useParams();
	// Contexts
	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	const { deleteLoanInterest, findLoanInterest, setShowUpdateLoanInterestModal } = useContext(
		LoanInterestContext
	) //goi ActionButtons cho component khác đc kg ???
	function submit () {
		confirmAlert({
		  title: 'Xoá chi phí lãi vay',
		  message: '',
		  buttons: [
			{
			  label: 'Có',
			  onClick: async () => {
			  	deleteLoanInterest(_idLoanInterest)
			  	const historyChangeLoanInterest = {
				    "requester": _id,
				    "contract": params.id,
				    "form": "loan-interest",
				    "action": "delete",
				    "data_change": "delete " + JSON.stringify(_idLoanInterest)
				}

				const { successHis, messageHis } = await addHistoryChange(historyChangeLoanInterest)
			  }
			},
			{
			  label: 'Không',
			  onClick: () => closeDialog()
			}
		  ]
		});
	  };
	  const closeDialog = () => {
		setShowUpdateLoanInterestModal(false)
	}
	
	return (
		<>
			<Button variant="primary" onClick={submit}>
				Xoá dữ liệu
			</Button>
		</>
	)
}

export default ActionButtons_LoanInterest
