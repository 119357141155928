import {
	LOADED_SUCCESS,
	LOADED_FAIL,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from '../contexts/constants'

export const RoleReducer = (state, action) => {
	const { type, payload } = action
	switch (type) {
		case LOADED_SUCCESS:
			return {
				...state,
				Roles: payload,
				RolesLoading: false
			}

		case LOADED_FAIL:
			return {
				...state,
				Roles: [],
				RolesLoading: false
			}

		case ADD:
			return {
				...state,
				Roles: [...state.Roles, payload]
			}

		case DELETE:
			return {
				...state,
				Roles: state.Roles.filter(Rols => Rols._id !== payload)
			}

		case FIND:
			return { ...state, Rols: payload }

		case UPDATE:
			
			const newRoles = state.Roles.map(Rols =>
				Rols._id === payload._id ? payload : Rols
			)

			return {
				...state,
				Roles: newRoles
			}

		default:
			return state
	}
}
