import { Route, Redirect, useLocation  } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { UserContext } from '../../contexts/UserContext'
import { UserPageContext } from '../../contexts/UserPageContext'
import { MenuContext } from '../../contexts/MenuContext'
import { ContractContext } from '../../contexts/ContractContext'
import { RoleContext } from '../../contexts/RoleContext'
import { UserManagerContext } from '../../contexts/UserManagerContext'

import Spinner from 'react-bootstrap/Spinner'
import NavbarMenu from '../layout/NavbarMenu'
import Footer from '../layout/Footer'
import AccessDenied from '../../views/AccessDenied'


const ProtectedRoute = ({ component: Component, ...rest }) => {
	const {
		authState: { 
			authLoading, isAuthenticated, user
		}
	} = useContext(AuthContext)

	const {
		UserState: { User, Users, UsersLoading },
		getUsers
	} = useContext(UserContext)

	const {
		UserPageState: { UserPage, UserPages, UserPagesLoading },
		getAllUserPages
	} = useContext(UserPageContext)

	const {
	    MenuState: { Menu, Menus, MenusLoading },
	    getMenu,
	} = useContext(MenuContext);

	const {
		RoleState: { Role, Roles, RolesLoading },
		getRoles,
	} = useContext(RoleContext)

	const {
		ContractState: { Contract, Contracts, ContractsLoading },
		getContracts
	} = useContext(ContractContext)

	const {
		UserManagerState: { UserManager, UserManagers, UserManagersLoading },
		getAllUserManagers
	} = useContext(UserManagerContext)

	useEffect(() => getAllUserPages(), [])

	const location = useLocation();
	let checkAllowDeny = true
	// useEffect(() => getContracts(), [])
	// console.debug("ProtectedRoute::: Contracts:::", UserPages)
	let rows;
	let newContract;
	let newContract111 = [];

	if (user != null) {
		// console.debug("Contracts:::", Contracts, ContractsLoading)
		// if (Contracts.length == 0 && ContractsLoading == false) {
		// 	useEffect(() => getContracts(), [])
		// }

		let role_new = user.roles[0];
		let UserID = user._id;
		let roleName = FindArray(Roles, role_new).name;

		checkAllowDeny = (roleName == 'Admin' || roleName == 'BTGD') ? true : checkAllowDenyMenu(UserPages, user)
		
		// checkAllowDenyContact = checkAllowDenyContact(user)
		// console.debug("checkAllowDeny", checkAllowDeny)
	}

	function filter_new_contract(array,UserID){
		let returnObject = "";
		// if (UserID != undefined) returnObject = array.filter((element) => element.user.centers != null ? element.user.centers[0] : '' == UserID[0]);
		if (UserID != undefined) returnObject = array.filter((element) => element.CenterID == UserID[0]);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}

	function filter_Users(array,UserID){
		let returnObject = "";
		if (UserID != undefined) returnObject = array.filter((element) => element._id === UserID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return '';
		}
	}


	function filter_history(array,ContractID){
		let returnObject = "";
		if (ContractID != undefined) returnObject = array.filter((element) => element.ContractID === ContractID);
		let lastElement = returnObject.pop();
		if(lastElement != undefined){
			return lastElement;
		}else{
			return '';
		}
	}

	function get_user_id(userLogin, userManager) {
		let managedUsers = [];

		function findManagedUsers(managerId) {
			let users = userManager.filter(item => item.manager_id[0] === managerId);
			if (users.length > 0) {
				for (let user of users) {
					findManagedUsers(user.user_id[0]);
				}
			} else {
			managedUsers.push(managerId);
			}
		}

		findManagedUsers(userLogin);
		return managedUsers;
	}

	function checkAllowDenyMenu(newUserPages, user) {
		let rs = false
		// console.debug("checkAllowDenyMenu", location.pathname)
		let urlAllow = ['/home', '/inputform', '/contract', '/home/', '/inputform/', '/contract/', '/user-info', '/user-info/', '/change-password', '/change-password/', '/access-denied']
		const allowed = urlAllow.some(prefix => { return location.pathname.startsWith(prefix); });

		if (allowed) {
			rs = true
		}else {
			const result = newUserPages.filter(obj => { return obj.user_id.some(id => id === user._id); });
			// console.debug(newUserPages, result, "result")
			let urlAllowContract = ['/contract','/contract/', '/summary']
			const allowedContract = urlAllowContract.some(s => location.pathname.includes(s))
			// console.debug("1 không cho phép qua", location.pathname, allowedContract)
			if (allowedContract) {
				// console.debug("cho phép qua kế", location.pathname, "ContractsLoading:::", Contracts, user._id)
				// let str = location.pathname
				// let al = str.split("/")
				// let idContact = al[al.length-1]
				// const contract_me = FindArray(newContract, idContact)
				// console.debug("contract_me", contract_me, ContractsLoading)
				// // const contract_me_access = contract_me.filter((v) => v._id == idContact)
				// if (contract_me != "" && ContractsLoading == false) {
				// 	if (contract_me.user_details != undefined) {
				// 		// console.debug("contract_me.user_details", contract_me)
				// 		if (contract_me.user == user._id) {
				// 			// console.debug("contract_me.user_details rs:::", true)
				// 			rs = true
				// 		}else {
				// 			// console.debug("contract_me.user_details rs:::", false)
				// 			rs = false
				// 		}
				// 	}else {
				// 		if (contract_me.user != undefined) {
				// 			// console.debug("contract_me.user",contract_me.user._id)
				// 			if (contract_me.user._id == user._id) {
				// 				// console.debug("!contract_me.user :::rs", true)
				// 				rs = true
				// 			}else {
				// 				// console.debug("!contract_me.user :::rs", false)
				// 				rs = false
				// 			}
				// 			// console.debug(1, contract_me.user, contract_me.user_details, user.name, "::::::::::::::")
				// 		}
				// 	}
				// }else {
				// 	if (idContact == "summary") {
				// 		// console.debug("! contract_me summary rs:::", true, idContact, allowedContract)
				// 		rs = true
				// 	}else {
				// 		const contract_me = FindArray(newContract, idContact)
				// 		console.debug("! contract_me rs:::", false, idContact, Contracts, contract_me)
				// 		rs = true
				// 	} 
				// }
				rs = true
			} else {
				let urlAllowReport = ['/report','/report/']
				const allowedReport = urlAllowReport.some(s => location.pathname.includes(s))
				// console.debug("allowedReport", allowedReport)

				if (urlAllowReport) {
					rs = true
				} else {
					for (var i = 0; i < result.length; i++) {
						// console.debug(123123, location.pathname.includes(FindArrayMenu(Menus, result[i].page_id[0])))
						if (location.pathname.includes(FindArrayMenu(Menus, result[i].page_id[0]).path)) {
							// console.debug("2 cho phép qua",  location.pathname, result)
							// return true
							rs = true
							break;
						}else{
							// console.debug("3 không cho phép qua",  location.pathname, result)
							// return false
							rs = false
						}
					}
				}
				// rs = true
			}
		}
		
		return rs
	}

	function FindArrayPath(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x.path === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return null;
		}
	}

	function FindArrayMenu(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return null;
		}
	}

	function FindArray(array, ID) {
		let returnObject = "";
		if (ID != undefined) returnObject = array.find(x => x._id === ID);
		if(returnObject != undefined){
			return returnObject;
		}else{
			return '';
		}
	}


	if (authLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info' />
			</div>
		)
	} else {
		if (checkAllowDeny) {
			// console.debug("cho phep truy cap::::")
			return (
				<Route
					{...rest}
					render={props =>
						isAuthenticated ? (
							<>
								<header>
									<NavbarMenu />
								</header>
								<main>
									<Component {...rest} {...props} />
								</main>
								<Footer />
							</>
						) : (
							<Redirect to='/login' />
						)
					}
				/>
			)
		}else {
			// console.debug("không đc phep truy cap::::")
			return (
				<Route
					{...rest}
					render={props =>
						isAuthenticated ? (
							// <>
							// 	<header>
							// 		<NavbarMenu />
							// 	</header>
							// 	<main>
							// 		<AccessDenied />
							// 	</main>
							// 	<Footer />
							// </>
							<Redirect to='/access-denied' />
						) : (
							<Redirect to='/login' />
						)
					}
				/>
			)
		}
	}


	// return (
	// 	<Route
	// 		{...rest}
	// 		render={props =>
	// 			isAuthenticated ? (
	// 				<>
	// 					<header>
	// 						<NavbarMenu />
	// 					</header>
	// 					<main>
	// 						<NotFound />
	// 					</main>
	// 					<Footer />
	// 				</>
	// 			) : (
	// 				<Redirect to='/login' />
	// 			)
	// 		}
	// 	/>
	// )

	// return (
	// 	<Route
	// 		{...rest}
	// 		render={props =>
	// 			isAuthenticated ? (
	// 				<>
	// 					<header>
	// 						<NavbarMenu />
	// 					</header>
	// 					<main>
	// 						<Component {...rest} {...props} />
	// 					</main>
	// 					<Footer />
	// 				</>
	// 			) : (
	// 				<Redirect to='/login' />
	// 			)
	// 		}
	// 	/>
	// )
}

export default ProtectedRoute
