import { createContext, useReducer, useState } from 'react'
// import { UserReducer } from '../reducers/UserReducer'//Note
// import {
// 	apiUrl,
// 	LOADED_FAIL,
// 	LOADED_SUCCESS,
// 	ADD,
// 	DELETE,
// 	UPDATE,
// 	FIND
// } from './constants'//Note
import axios from 'axios'

export const UserGuideContext = createContext()

const UserGuideContextProvider = ({ children }) => {
	// User context data
	const UserGuideContextProvider = {
	}

	return (
		<UserGuideContextProvider.Provider value={UserGuideContextProvider}>
			{children}
		</UserGuideContextProvider.Provider>
	)
}

export default UserGuideContextProvider
