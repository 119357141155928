import { createContext, useReducer, useState } from 'react'
import { MenuReducer } from '../reducers/MenuReducer'
import {
	apiUrl,
	LOADED_FAIL,
	LOADED_SUCCESS,
	ADD,
	DELETE,
	UPDATE,
	FIND
} from './constants'//Note
import axios from 'axios'

export const MenuContext = createContext()

const MenuContextProvider = ({ children }) => {
	const [MenuState,  dispatch] = useReducer(MenuReducer, {
		Menu: null,
		Menus: [],
		MenusLoading: true
	});

	// const [showAddMenuModal, setShowAddMenuModal] = useState(false);
	// const [showUpdateMenuModal, setShowUpdateMenuModal] = useState(false);
	// const [showToast, setShowToast] = useState({
	// 	show: false,
	// 	message: '',
	// 	type: null
	// });

	// Get all Menu
	const getMenu = async () => {
		try {
			const response = await axios.get(`${apiUrl}/api/forms/menu`)
			if (response.data.success) {
				dispatch({ type: LOADED_SUCCESS, payload: response.data.Menu })	
			}
		} catch (error) {
			dispatch({ type: LOADED_FAIL })
		}
	}

	// const addMenu = async newMenu => {
	// 	try {
	// 		const response = await axios.post(`${apiUrl}/api/forms/loan-interest/post/`, newMenu)
	// 		if (response.data.success) {
	// 			// dispatch({ type: ADD, payload: response.data.Menu })
	// 			getMenu_byidContract(newMenu['contract'])
	// 			return response.data 
	// 		}
	// 		else
	// 			return response.data //Message hop dong kg ton tai
	// 	} catch (error) {
	// 		return error.response.data
	// 			? error.response.data
	// 			: { success: false, message: 'Server error' }
	// 	}
	// }

	// const deleteMenu = async MenuId => {
	// 	try {
	// 		console.log(MenuId,'MenuId')
	// 		const response = await axios.delete(`${apiUrl}/api/forms/loan-interest/delete/${MenuId}`)
	// 		if (response.data.success)
	// 			// dispatch({ type: DELETE, payload: MenuId })
	// 			getMenu_byidContract(MenuId)
	// 	} catch (error) {
	// 		console.log(error)
	// 	}
	// }

	// const findMenu = MenuId => {
	// 	const Menu = MenuState.Menus.find(Menu => Menu._id === MenuId)
	// 	dispatch({ type: FIND, payload: Menu })
	// }

	// Menu context data
	const MenuContextData = {
		// Two_Option_Menu_Modal,//update
		// show_Two_Option_Menu_Modal,
		// setshow_Two_Option_Menu_Modal
		MenuState,
		getMenu,
		// showAddMenuModal,
		// setShowAddMenuModal,
		// showUpdateMenuModal,
		// setShowUpdateMenuModal,
		// addMenu,
		// showToast,
		// setShowToast,
		// deleteMenu,
		// findMenu,
		// updateMenu,
		// getMenu_byidContract,
	}

	return (
		<MenuContext.Provider value={MenuContextData}>
			{children}
		</MenuContext.Provider>
	)
}

export default MenuContextProvider
