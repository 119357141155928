import { createContext, useReducer, useState } from 'react'
// import { UserReducer } from '../reducers/UserReducer'//Note
// import {
// 	apiUrl,
// 	LOADED_FAIL,
// 	LOADED_SUCCESS,
// 	ADD,
// 	DELETE,
// 	UPDATE,
// 	FIND
// } from './constants'//Note
import axios from 'axios'

export const ConfigContext = createContext()

const ConfigContextProvider = ({ children }) => {
	// User context data
	const ConfigContextProvider = {
	}

	return (
		<ConfigContextProvider.Provider value={ConfigContextProvider}>
			{children}
		</ConfigContextProvider.Provider>
	)
}

export default ConfigContextProvider
