import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useContext, useState, useEffect } from 'react'
import { GuaranteeLetterCostContext } from '../../contexts/GuaranteeLetterCostContext'
import { useParams } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'

const UpdateGuaranteeLetterCostModal = () => {
	const params = useParams();
	// Contexts
	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	// Contexts
	const {
		GuaranteeLetterCostState: { GuaranteeLetterCost },
		showUpdateGuaranteeLetterCostModal,
		setShowUpdateGuaranteeLetterCostModal,
		updateGuaranteeLetterCost,
		setShowToast
	} = useContext(GuaranteeLetterCostContext)

	// State
	const [updatedGuaranteeLetterCost, setUpdatedGuaranteeLetterCost] = useState(GuaranteeLetterCost)
	useEffect(() => setUpdatedGuaranteeLetterCost(GuaranteeLetterCost), [GuaranteeLetterCost])

	const [beforeGuaranteeLetterCost, setBeforeGuaranteeLetterCost] = useState(GuaranteeLetterCost)
	useEffect(() => setBeforeGuaranteeLetterCost(GuaranteeLetterCost),[GuaranteeLetterCost])
	console.log(updatedGuaranteeLetterCost, "aaaa")
	const { 
		Content,
		Cost,
		QuantityMonths,
		RatioCost,
		IntoMoney,
		Note
	} = updatedGuaranteeLetterCost



	const onChangeUpdatedGuaranteeLetterCostForm = event =>{
		if(event.target.name != 'Content' && event.target.name != 'Note'){
			if (!isPatternNumber(event.target.value) &&  event.target.value != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				setUpdatedGuaranteeLetterCost({ ...updatedGuaranteeLetterCost, [event.target.name]: event.target.value })
			}
		}else{
			setUpdatedGuaranteeLetterCost({ ...updatedGuaranteeLetterCost, [event.target.name]: event.target.value })
		}
	}

	const closeDialog = () => {
		setUpdatedGuaranteeLetterCost(GuaranteeLetterCost)
		setShowUpdateGuaranteeLetterCostModal(false)
	}

	const onSubmit = async event => {
		event.preventDefault()
		const { success, message } = await updateGuaranteeLetterCost(updatedGuaranteeLetterCost)
		const diff = findDiff(beforeGuaranteeLetterCost, updatedGuaranteeLetterCost);
		const historyChangeGuaranteeLetterCost = {
		    "requester": _id,
		    "contract": params.id,
		    "form": "guarantee-letter-cost",
		    "action": "update",
		    "data_change": "change " + JSON.stringify(diff)
		}
		const { successHis, messageHis } = await addHistoryChange(historyChangeGuaranteeLetterCost)
		setShowUpdateGuaranteeLetterCostModal(false)
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
	}

	function findDiff(obj1, obj2) {
	    const diff = {};

	    Object.keys(obj1).forEach((key) => {
	        if (obj1[key] !== obj2[key]) {
	            diff[key] = obj2[key];
	        }
	    });

	    return diff;
	}

	const isPatternNumber = userInput => {
		if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		return /^\d+$/.test(userInput);
	}

	return (
		<Modal show={showUpdateGuaranteeLetterCostModal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>Cập nhật chi phí thư bảo lãnh?</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
				<Form.Group>
						<Form.Text id='title-help' muted as='h6'>
							Nội dung chi phí
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Nhập chuỗi'
							name='Content'
							required
							aria-describedby='noidung-help'
							value={Content}
							onChange={onChangeUpdatedGuaranteeLetterCostForm}
						/>						
					</Form.Group>
					<Form.Group>
						<Form.Text id='gttbl-help' muted as='h6'>
							Giá trị thư bảo lãnh
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Nhập số'
							name='Cost'
							required
							aria-describedby='gttbl-help'
							value={Cost}
							onChange={onChangeUpdatedGuaranteeLetterCostForm}
						/>						
					</Form.Group>
					<Form.Group>
						<Form.Text id='gttbl-help' muted as='h6'>
							Số tháng
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Nhập số'
							name='QuantityMonths'
							required
							aria-describedby='sothang-help'
							value={QuantityMonths}
							onChange={onChangeUpdatedGuaranteeLetterCostForm}
						/>						
					</Form.Group>
					<Form.Group>
						<Form.Text id='tilephi-help' muted as='h6'>
							Tỉ lệ phí
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='2%'
							name='RatioCost'
							required
							aria-describedby='tilephi-help'
							value={RatioCost}
							onChange={onChangeUpdatedGuaranteeLetterCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='ghichu-help' muted as='h6'>
							Ghi chú
						</Form.Text>
						<Form.Control
							as='textarea'
							rows={3}
							placeholder='Ghi chú'
							name='Note'
							value={Note}
							onChange={onChangeUpdatedGuaranteeLetterCostForm}
						/>						
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Cập nhật
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}

export default UpdateGuaranteeLetterCostModal
