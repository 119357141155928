import { createContext, useReducer, useState } from 'react'
// import { UserReducer } from '../reducers/UserReducer'//Note
// import {
// 	apiUrl,
// 	LOADED_FAIL,
// 	LOADED_SUCCESS,
// 	ADD,
// 	DELETE,
// 	UPDATE,
// 	FIND
// } from './constants'//Note
import axios from 'axios'

export const CancelContractContext = createContext()

const CancelContractContextProvider = ({ children }) => {
	// User context data
	const CancelContractContextProvider = {
	}

	return (
		<CancelContractContextProvider.Provider value={CancelContractContextProvider}>
			{children}
		</CancelContractContextProvider.Provider>
	)
}

export default CancelContractContextProvider
