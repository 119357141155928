import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { MiscExpenseCostContext } from '../../contexts/MiscExpenseContext'
import { AuthContext } from '../../contexts/AuthContext'
import { HistoryChangeContext } from '../../contexts/HistoryChangeContext'

const UpdateMiscExpenseCostModal = () => {
	const params = useParams();
	// Contexts
	const {
		authState: {
			user: { roles, _id }
		}
	} = useContext(AuthContext)

	const {
		historyChangeState: { historyChange, historyChanges },
		getHistoryChanges,
		addHistoryChange
	} = useContext(HistoryChangeContext)

	const {
		MiscExpenseCostState: { MiscExpenseCost },
		showUpdateMiscExpenseCostModal,
		setShowUpdateMiscExpenseCostModal,
		updateMiscExpenseCost,
		setShowToast
	} = useContext(MiscExpenseCostContext)

	// State
	const [updatedMiscExpenseCost, setUpdatedMiscExpenseCost] = useState(MiscExpenseCost)
	useEffect(() => setUpdatedMiscExpenseCost(MiscExpenseCost), [MiscExpenseCost])

	const [beforeMiscExpenseCost, setBeforeMiscExpenseCost] = useState(MiscExpenseCost)
	useEffect(() => setBeforeMiscExpenseCost(MiscExpenseCost),[MiscExpenseCost])

	const { 
		Content, Cost, Note
	 } = updatedMiscExpenseCost //note

	const onChangeUpdatedMiscExpenseCostForm = event =>{
		if(event.target.name != 'Content' && event.target.name != 'Note'){
			if (!isPatternNumber(event.target.value) &&  event.target.value != '') {
				var message = 'Trường dữ liệu chỉ cho phép nhập số! Vui lòng kiểm tra lại.'
				setShowToast({ show: true, message: message, type: 'danger' })
				return false;
			}else{
				setUpdatedMiscExpenseCost({ ...updatedMiscExpenseCost, [event.target.name]: event.target.value })
			}
		}else{
			setUpdatedMiscExpenseCost({ ...updatedMiscExpenseCost, [event.target.name]: event.target.value })
		}
	}

	const closeDialog = () => {
		setUpdatedMiscExpenseCost(MiscExpenseCost)
		setShowUpdateMiscExpenseCostModal(false)
	}

	const onSubmit = async event => {
		event.preventDefault()

		if (updatedMiscExpenseCost.Content.trim() == '') {
			var message1 = 'Dữ liệu nhập vào sai định dạng! Vui lòng kiểm tra lại.'
			setShowToast({ show: true, message: message1, type: 'danger' })
			return false;
		}

		if (updatedMiscExpenseCost.Cost.trim() == '') {
			var message2 = 'Dữ liệu không được để trống! Vui lòng kiểm tra lại.'
			setShowToast({ show: true, message: message2, type: 'danger' })
			return false;
		}


		updatedMiscExpenseCost.Content = updatedMiscExpenseCost.Content.trim()

		const { success, message } = await updateMiscExpenseCost(updatedMiscExpenseCost)
		const diff = findDiff(beforeMiscExpenseCost, updatedMiscExpenseCost);
		const historyChangeMiscExpenseCost = {
		    "requester": _id,
		    "contract": params.id,
		    "form": "misc-expense-cost",
		    "action": "update",
		    "data_change": "change " + JSON.stringify(diff)
		}

		const { successHis, messageHis } = await addHistoryChange(historyChangeMiscExpenseCost)
		setShowUpdateMiscExpenseCostModal(false)
		setShowToast({ show: true, message, type: success ? 'success' : 'danger' })
	}

	function findDiff(obj1, obj2) {
	    const diff = {};

	    Object.keys(obj1).forEach((key) => {
	        if (obj1[key] !== obj2[key]) {
	            diff[key] = obj2[key];
	        }
	    });

	    return diff;
	}

	// const resetAddMiscExpenseCostData = () => {
	// 	setNewMiscExpenseCost({ title: '', description: '', url: '', status: 'TO LEARN' })
	// 	setShowAddMiscExpenseCostModal(false)
	// }

	const isPatternNumber = userInput => {
		if (typeof userInput !== 'string') return false; // Kiểm tra giá trị đầu vào có phải là chuỗi không
  		return /^\d+$/.test(userInput);
	}

	return (
		<Modal show={showUpdateMiscExpenseCostModal} onHide={closeDialog}>
			<Modal.Header closeButton>
				<Modal.Title>Cập nhật Chi phí:  {Content}</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
			<Modal.Body>
					<Form.Group>
						<Form.Text id='noidung-help' muted as="h6">
							Nội dung chi phí
						</Form.Text>
						<Form.Control
							type='text'
							placeholder='Nhập chuỗi'
							name='Content'
							required
							aria-describedby='noidung-help'
							value={Content}
							onChange={onChangeUpdatedMiscExpenseCostForm}
						/>						
					</Form.Group>
					<Form.Group>
						<Form.Text id='sotien-help' muted  as="h6">
							Số tiền
						</Form.Text>
						<Form.Control
							tpye='text'
							placeholder='Nhập số'
							name='Cost'
							value={Cost} /* tạo ràn buộc số */
							onChange={onChangeUpdatedMiscExpenseCostForm}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Text id='ghichu-help' muted as="h6">
							Ghi chú
						</Form.Text>
						<Form.Control
							as='textarea'
							rows={3}
							placeholder='Nhập chuỗi'
							name='Note'
							value={Note}
							onChange={onChangeUpdatedMiscExpenseCostForm}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={closeDialog}>
						Hủy
					</Button>
					<Button variant='primary' type='submit'>
						Cập nhật
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}

export default UpdateMiscExpenseCostModal
